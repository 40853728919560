import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'jn-order-error-dialog',
    templateUrl: './order-error-dialog.component.html',
    styleUrls: ['./order-error-dialog.component.scss']
})

export class OrderErrorDialogComponent {

    constructor(private readonly matDialogRef: MatDialogRef<OrderErrorDialogComponent>) {}

    public close(): void {
        this.matDialogRef.close();
    }
}