import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { OrderService } from '../../../checkout/services/order.service';
import { TranslationService, CmsSessionService, LocaleService, WebStorageService } from '@jeunesse/angular';
import { SubSink } from 'subsink';
import { MerchantInstallmentConfigModel, MerchantInstallmentFormModel, OrderModel } from '../../../checkout/public-api';
import { of, Subject, Observable, BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { InstallmentsDialogComponent } from './dialog/installments-dialog.component';
import { map, mergeMap, tap } from 'rxjs/operators';
import { error } from 'console';
import { Direction } from '@angular/cdk/bidi';

@Component({
    selector: 'jn-payment-installments',
    templateUrl: './installments.component.html',
    styleUrls: ['./installments.component.scss']
})
export class InstallmentsComponent implements OnInit, OnDestroy {
    private creditCardNum: string;
    private creditCardFK: number;
    private creditCardType: string;

    protected merchantInstallmentConfigModel: MerchantInstallmentConfigModel;
    protected selectedInstallmentsPlan: string;
    protected orderModel: OrderModel;
    protected installmentsForm: FormGroup;
    public translations: any = {
        chkInstallments: '',
        numberOfInstallments: ''
    };
    public isInstallmentsAvailable: boolean;
    public showInstallmentsPlan: boolean;
    public showPromotion: boolean;
    public isLoading: boolean;
    public showNewAmount: boolean;
    public newPaymentAmount: string;
    public newPaymentAmountLocal: string;
    public processingFee: number;

    public creditCardTypeValue: Subject<string>;
    public creditCardNumValue: Subject<string>;
    public creditCardFkValue: Subject<number>;
    public splitPaymentValue: Subject<boolean>;
    @Input() public paymentAmountValue: BehaviorSubject<number>;
    private directionality: Direction = 'ltr';
    private subSink = new SubSink();

    constructor(
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService,
        private readonly dialog: MatDialog,
        private readonly webStorageService: WebStorageService,
    ) { }

    ngOnInit() {
        this.creditCardNum = '';
        this.creditCardType = '';
        this.creditCardTypeValue = new Subject<string>();
        this.creditCardNumValue = new Subject<string>();
        this.creditCardFkValue = new Subject<number>();
        this.splitPaymentValue = new Subject<boolean>();
        this.isInstallmentsAvailable = false;
        this.showInstallmentsPlan = false;
        this.showPromotion = false;
        this.isLoading = false;

        this.subSink.sink = this.orderService.getOrderModel().subscribe((orderModel: OrderModel) => {
            this.orderModel = orderModel;
            this.initInstallments();
        });

        this.subSink.sink = this.splitPaymentValue.subscribe((isSplit: boolean) => {
            if (!isSplit) {
                this.initInstallments();
            }
        });

        this.subSink.sink = this.creditCardTypeValue.subscribe((ccType: string) => {
            if ((!ccType || ccType.trim().length > 0)){
                this.creditCardType = ccType;
                this.fillInstallments();
            }
        });
        this.subSink.sink = this.creditCardNumValue.subscribe((ccNum: string) => {
            this.creditCardFK = null;
            this.creditCardNum = ccNum || '';
        });
        this.subSink.sink = this.creditCardFkValue.subscribe((ccPk: number) => {
            this.creditCardFK = ccPk;
            this.creditCardNum = null;
        });
        this.subSink.sink = this.paymentAmountValue.subscribe((paymentAmount: number) => {
            this.fillInstallments();
        });

        this.installmentsForm = this.formBuilder.group({
            chkInstallments: [''],
            numberOfInstallments: ['']
        });
        this.subSink.sink = this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
        this.getTranslations();
    }

    ngOnDestroy() {
        this.subSink.unsubscribe();
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.chkInstallments = this.translationService.translate('checkout', 'chkInstallments', 'Installment Payment?') || 'Installment Payment?';
        this.translations.numberOfInstallments = this.translationService.translate('checkout', 'numberOfInstallments', 'Number of payments') || 'Number of payments'
    }

    private initInstallments(): void {
        this.isLoading = true;
        this.showPromotion = false;
        let splitPaymentSession: string = this.webStorageService.getSession('splitPayments');
        let amountToPayValue = this.paymentAmountValue.getValue();
        let form: MerchantInstallmentFormModel = {
            mainOrdersFK: this.orderModel.mainOrdersFk,
            splitAvailableAndChecked: this.orderModel.splitPayment && !splitPaymentSession, //true for 2nd split
            countryCode: this.orderModel.countryCode,
            creditCardType: this.creditCardType,
            mainOrderTypeFK: this.orderModel.mainOrderTypeFk,
            amountToPay: amountToPayValue,
            noOfInstallments: this.getNoOfInstallments(),
            creditCardNumber: this.creditCardNum,
            creditCardFK: this.creditCardFK
        };
        this.orderService.initInstallments(form).subscribe((installments: MerchantInstallmentConfigModel) => {
            this.merchantInstallmentConfigModel = installments;
            this.isInstallmentsAvailable = installments.available;
            if (installments.available && installments.minOrderTotal && installments.minOrderTotal > 0 && installments.minOrderTotal > amountToPayValue) {
                this.isInstallmentsAvailable = false;
            }
            if (this.isInstallmentsAvailable && form.noOfInstallments === 0)
                this.selectedInstallmentsPlan = installments.minInstallments.toString();
            if (this.merchantInstallmentConfigModel.viewModel && this.merchantInstallmentConfigModel.viewModel.promotionFee) {
                this.showPromotion = true;
            }
            else if (this.merchantInstallmentConfigModel.viewModel && this.merchantInstallmentConfigModel.viewModel.installmentFee) {
                this.showNewAmount = true;
                this.newPaymentAmount = this.merchantInstallmentConfigModel.viewModel.paymentAmount;
                this.newPaymentAmountLocal = this.merchantInstallmentConfigModel.viewModel.paymentTotalLocal;
                this.processingFee = +this.merchantInstallmentConfigModel.viewModel.installmentFee
            }
            this.isLoading = false;
        }, error => { this.isLoading = false; });
    }

    private setChkInstallmentsValue(value: boolean): void {
        this.showInstallmentsPlan = value;
        this.installmentsForm.get('chkInstallments').setValue(value);
    }

    private fillInstallments(): void {
        if (this.isInstallmentsAvailable) {
            if (this.merchantInstallmentConfigModel.extensionAvailable) {
                this.initInstallments();
            }
            else {
                this.isLoading = true;
                this.orderService.getInstallments(this.orderModel.countryCode, this.creditCardType, this.orderModel.mainOrderTypeFk).subscribe((installments: MerchantInstallmentConfigModel) => {
                    this.setChkInstallmentsValue(false);
                    let temp = this.merchantInstallmentConfigModel.viewModel;
                    this.merchantInstallmentConfigModel = installments;
                    this.merchantInstallmentConfigModel.viewModel = temp;
                    this.selectedInstallmentsPlan = installments.minInstallments.toString();
                    this.isLoading = false;
                }, error => { this.isLoading = false; });
            }
        }
    }

    public validateInstallments(): Observable<boolean> {
        if (this.isInstallmentsAvailable && this.showInstallmentsPlan && this.merchantInstallmentConfigModel.extensionAvailable){
            let form: MerchantInstallmentFormModel = {
                mainOrdersFK: this.orderModel.mainOrdersFk,
                splitAvailableAndChecked: this.orderModel.splitPayment,
                countryCode: this.orderModel.countryCode,
                creditCardType: this.creditCardType,
                mainOrderTypeFK: this.orderModel.mainOrderTypeFk,
                amountToPay: this.paymentAmountValue.getValue(),
                noOfInstallments: this.getNoOfInstallments(),
                creditCardNumber: this.creditCardNum,
                creditCardFK: this.creditCardFK
            };
            return this.orderService.isInstallmentExtensionValid(form);
        }
        else {
            return of(true);
        }
    }

    public saveInstallments(): Observable<boolean> {
        let noOfInstallments = this.getNoOfInstallments();
        if (noOfInstallments > 0)
        {
            const config: MatDialogConfig<any> = {
                hasBackdrop: true,
                disableClose: false,
                width: '500px',
                maxHeight: '99%',
                panelClass: 'InstallmentsDialogComponentPanel',
                autoFocus: false,
                direction: this.directionality,
                data: {
                    noOfInstallments: noOfInstallments,
                    minSumInstallment: this.merchantInstallmentConfigModel.minSumInstallment,
                    minOrderTotal: this.merchantInstallmentConfigModel.minOrderTotal,
                    amountToPay: this.paymentAmountValue.getValue(),
                    showNewAmount: this.showNewAmount,
                    newPaymentAmount : this.newPaymentAmount
                }
            };
            const dialogRef: MatDialogRef<InstallmentsDialogComponent> = this.dialog.open(InstallmentsDialogComponent, config);

            return dialogRef.afterClosed().pipe(mergeMap((res: any) => {
                if (res) {
                    let form: MerchantInstallmentFormModel = {
                        mainOrdersFK: this.orderModel.mainOrdersFk,
                        splitAvailableAndChecked: this.orderModel.splitPayment,
                        countryCode: this.orderModel.countryCode,
                        creditCardType: this.creditCardType,
                        mainOrderTypeFK: this.orderModel.mainOrderTypeFk,
                        amountToPay: this.paymentAmountValue.getValue(),
                        noOfInstallments: noOfInstallments,
                        creditCardNumber: this.creditCardNum,
                        creditCardFK: this.creditCardFK
                    };
                    return this.orderService.SaveInstallments(form).pipe(map(() => true));
                }
                return of(false);
            }));
        }
        else {
            return of(true);
        }
    }

    public toggleInstallments(): void {
        this.showInstallmentsPlan = !this.showInstallmentsPlan;
        if (this.showInstallmentsPlan)
            this.installmentsForm.get('numberOfInstallments').setValue(this.selectedInstallmentsPlan);
    }

    public installmentChange(): void {
        this.initInstallments();
    }

    public getNoOfInstallments(): number
    {
        return (this.isInstallmentsAvailable && this.showInstallmentsPlan && Number(this.selectedInstallmentsPlan)) || 0;
    }

}
