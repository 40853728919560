import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './layout/404/page-not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RepGuard } from './rep-guard.service';
import { RepSiteResolver } from './rep-site.resolver';
import { CompanyCountryResolver } from './company-country.resolver';
import { TranslationImageResolver, TranslationResolver, TranslationVideoResolver } from '@jeunesse/angular';
import { LocaleGuard } from './locale-guard.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'en-US/default',
        pathMatch: 'full'
    },
    {
        path: ':locale/:siteUrl',
        canActivate: [RepGuard, LocaleGuard],
        resolve: {
            repInfo: RepSiteResolver,
            companyCountry: CompanyCountryResolver,
            translations: TranslationResolver,
            images: TranslationImageResolver,
            videos: TranslationVideoResolver
        },
        children: [
        {
            path: '',
            loadChildren: () => import('./+home/home.module').then(m => m.HomeModule)
        },
        {
            path: 'catalog',
            loadChildren: () => import('./+products/products.module').then(m => m.ProductsModule)
        }]
    },
    {
        path: '**', component: PageNotFoundComponent
    }
];


@NgModule({
    imports: [
        FlexLayoutModule,
        RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', initialNavigation: true })
    ],
    exports: [
        RouterModule
    ],
    providers: [
    ],
    declarations: [
        PageNotFoundComponent
    ]
})

export class AppRoutingModule {

}
