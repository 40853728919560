import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';

import { TranslationService } from './translation.service';


/**
 * Loads the translations for the page into cache.
 */
@Injectable()
export class TranslationResolver implements Resolve<boolean> {
    constructor(private readonly translationService: TranslationService) {
     }

    /**
     * @ignore
     */
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        if (route.data['states']) {
            return this.translationService.loadTranslations(route.data['states']);
        }

        return of(false);
    }
}