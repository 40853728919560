import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { TranslationService } from '@jeunesse/angular';
import { CurrencyNumberModel } from '../../checkout/models/currency-number.model';
import { OrderService } from '../../checkout/services/order.service';

@Directive({
    selector: '[jnValidateAmount][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS, useExisting: PaymentAmountValidatorDirective,
            multi: true,
        },
    ],
})
export class PaymentAmountValidatorDirective implements Validator {
    @Input('currencyNumberModel') set currencyNumberModel(res: CurrencyNumberModel) {
        if (res.regexFormat) {
            this.regexValidator = new RegExp(res.regexFormat);
        }
        this.precision = res.precision ? res.precision : 0;
    }
    @Input() public remainingSplitBalance: number;
    @Input() public currencyTotal: number;
    public isAmountValid: boolean = true;
    public isLessMinimalAmount: boolean = false;
    public isRegexValid: boolean = true;
    public precision: number = 0;
    public minimumAllowedPaymentAmount: number;
    public regexValidator: RegExp = null;

    constructor(private translationService: TranslationService,
                private orderService: OrderService) {
        this.regexValidator = new RegExp('^([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$');
        this.orderService.getMinimumAllowedPayment()
            .subscribe(value => this.minimumAllowedPaymentAmount = value);
    }

    public validate(c: FormControl): any {
        let val: string = c.value;
        if (val != null || val !== undefined) {
            let value: number = Number(val);
            this.isAmountValid = !(value > this.remainingSplitBalance || value > this.currencyTotal);
            this.isLessMinimalAmount = value < this.minimumAllowedPaymentAmount && this.remainingSplitBalance > this.minimumAllowedPaymentAmount;
            this.isRegexValid = this.regexValidator.test(val);
            if (this.precision < 0) {
                let roundOffVal: number = Math.pow(10, Math.abs(this.precision));
                let factor: number = 2;
                let roundVal: number = Math.round(value * factor) / factor;
                this.isAmountValid = roundVal % roundOffVal === 0;
            }
        } else {
            this.isAmountValid = this.isRegexValid = true;
            this.isLessMinimalAmount = false;
        }

        if (this.isAmountValid && !this.isLessMinimalAmount && this.isRegexValid) {
            return null;
        } else {
            return {
                jnValidateAmount: {
                    isAmountValid: this.isAmountValid,
                    isLessMinimalAmount: this.isLessMinimalAmount,
                    isRegexValid: this.isRegexValid,
                },
            };
        }
    }
}
