import { ProductPriceModel } from "../../products/public-api";

/**
 * Model to display current smartdelivery items.
 */
export class SmartDeliveryItemModel {
    public autoshipOrderItemsPK: number;
    public productPK: number;
    public productName: string;
    public unitPrice: number;
    public currencyCode: string;
    public currencySymbol: string;
    public currencyRatesFK: number;
    public formattedUnitPrice: string;
    public quantity: number;
    public maxLimit: number;
    public imageUrl: string;
    public configs?: SmartDeliveryItemConfigModel[];
    public validationMessages: string[];
    public cv: number = 0;
    public price: number;
    public priceNote: string;
    public pricing: ProductPriceModel[] = [];

    private readonly configLimit: number = 3;

    constructor(obj: any = {}) {
        if (obj) {
            Object.assign(this, obj);

        }
    }
}

export class SmartDeliveryItemConfigModel {
    public autoshipOrderItemsFK: number;
    public autoshipOrderFK: number;
    public productFK: number;
    public configProductFK: number;
    public packageFK: number;
    public productName: string;
    public imageUrl: string;

    constructor(obj: any = {}) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
