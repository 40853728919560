import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingCartService } from './services/public-api';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
    ]
})
export class ShoppingCartModule { }
