import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CartSummaryItem } from '../../../models/public-api';
import { URLService } from '../../../../menu/services/url.service';
import { ClientInfo, ClientInfoService } from '@jeunesse/angular';

@Component({
    selector: 'jn-cart-item',
    templateUrl: './cart-item.component.html',
    styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {
    @Input() public item: CartSummaryItem;
    @Input() public cartType: string;
    @Input() public isRedemptionMode: boolean;
    @Input() public isLoggedIn: boolean;
    @Input() public mainType: number;
    @Input() public cartHasAutoShipItems: boolean;
    @Input() public lockPage: boolean;
    @Input() public repInfo: any;
    @Input() public currencySymbol: string;
    @Input() public orderModel: any;
    @Input() public hasLoyaltyProgram: boolean;
    @Input() public isVolumeBasedRSB: boolean;
    @Input() public offerPreferredCust: boolean;
    @Input() public isGuestAccount: boolean;
    @Input() public isConfirmationPage: boolean;
    @Input() public editCartEnabled: boolean = true;
    @Input() public showSDCheckboxes: boolean = false;
    @Output() public deletedItem: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public updatedItem: EventEmitter<CartSummaryItem> = new EventEmitter<CartSummaryItem>();
    public clientInfo: ClientInfo;
    constructor(public readonly urlService: URLService,
        public readonly clientInfoService: ClientInfoService) { }

    public ngOnInit(): void {
        this.clientInfo = this.clientInfoService.getClientInfo();
        if (this.item && !Number.isInteger(this.item.points)) {
            this.item.points = 0;
        }
    }

    public deleteItem(item): void {
        this.deletedItem.emit(item);
    }

    public log(n: string ,s: string): string {
        console.log(n, s);
        return '';
    }

    public checkSD(): void {
        this.updatedItem.emit(this.item);
    }

}
