import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CartsummaryComponent } from './components/cart-summary/cartsummary.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CmsModule } from '@jeunesse/angular';
import { FormsModule } from '@angular/forms';
import { DisclosureComponent } from './components/cart-summary/disclosure/disclosure.component';
import { SmartdeliveryItemComponent } from './components/cart-summary/smartdelivery-item/smartdelivery-item.component';
import { CartItemComponent } from './components/cart-summary/cart-item/cart-item.component';
import { CartTotalComponent } from './components/cart-summary/cart-total/cart-total.component';
import { SplitPaymentDetailsComponent } from '../checkout/components/cart-summary/split-payment-details/split-payment-details.component';
import { CartInfoComponent } from './components/cart-summary/cart-info/cart-info.component';
import { DiscountComponent } from './discount/discount.component';
import { OrderService } from './services/order.service';
import { ConfirmationDocumentsComponent } from './components/confirmation-documents/confirmation-documents.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [
        CartsummaryComponent,
        DisclosureComponent,
        SmartdeliveryItemComponent,
        CartItemComponent,
        CartTotalComponent,
        SplitPaymentDetailsComponent,
        CartInfoComponent,
        DiscountComponent,
        ConfirmationDocumentsComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatDividerModule,
        MatInputModule,
        MatRadioModule,
        MatButtonModule,
        MatCheckboxModule,
        FlexLayoutModule,
        FormsModule,
        CmsModule,
        RouterModule
    ],
    exports: [
        CartsummaryComponent,
        DisclosureComponent,
        SmartdeliveryItemComponent,
        CartItemComponent,
        CartTotalComponent,
        SplitPaymentDetailsComponent,
        CartInfoComponent,
        DiscountComponent,
        ConfirmationDocumentsComponent,
    ],
    entryComponents: [
        CartsummaryComponent, CartInfoComponent, CartItemComponent, CartTotalComponent, DisclosureComponent, SmartdeliveryItemComponent, SplitPaymentDetailsComponent
    ]
})

export class CheckoutLibModule {
    /**
 * This is required so the same provider is injected into the load on demand modules.
 *
 * More Details: http://angularfirst.com/the-ngmodule-forroot-convention/
 */
    public static forRoot(): ModuleWithProviders<CheckoutLibModule> {
        return {
            ngModule: CheckoutLibModule,
            providers: [
                OrderService
            ]
        };
    }
}
