import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslationService } from '../../cms/src/translation.service';
import { CmsSessionService } from '../../cms/src/cms-session.service';
import { LocaleService } from '../../cms/src/locale.service';

/**
 * Handles translations for the MatPagination for tables.
 */
@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
    private rangeZeroTemplate: string = '0 of {Total}';
    private rangeTemplate: string = '{Start} - {End} of {Total}';

    /**
     * A label for the range of items within the current page and the length of the whole list.
     */
    public getRangeLabel: (page: number, pageSize: number, length: number) => string = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) { return this.rangeZeroTemplate.replace(/{Total}/gi, length.toString()); }

        length = Math.max(length, 0);

        const startIndex: number = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex: number = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return this.rangeTemplate.replace(/{Start}/gi, (startIndex + 1).toString())
            .replace(/{End}/gi, endIndex.toString()).replace(/{Total}/gi, length.toString());
    }

    constructor(private readonly translationService: TranslationService,
        private readonly localeService: LocaleService,
        private readonly cmsSessionService: CmsSessionService) {
        super();

        this.translationService.loadTranslations('mat-paginator').subscribe(() => {
            let culture: string = this.cmsSessionService.getCulture();
            this.localeService.isCultureSupported(culture, this.cmsSessionService.getCmsCountry(culture)).subscribe((returnedCulture) => {
                this.translationService.setTranslationCulture(returnedCulture);
                this.firstPageLabel = this.translationService.translate('mat-paginator', 'first-page', 'First page');
                this.itemsPerPageLabel = this.translationService.translate('mat-paginator', 'items-per-page', 'Items per page:');
                this.lastPageLabel = this.translationService.translate('mat-paginator', 'last-page', 'Last page');
                this.nextPageLabel = this.translationService.translate('mat-paginator', 'next-page', 'Next page');
                this.previousPageLabel = this.translationService.translate('mat-paginator', 'previous-page', 'Previous page');

                this.rangeZeroTemplate = this.translationService.translate('mat-paginator', 'zero-range', '0 of {Total}');
                this.rangeTemplate = this.translationService.translate('mat-paginator', 'range', '{Start} - {End} of {Total}');

                this.changes.next();
            });
        });

    }
}