import { GroupMemberTicketModel } from "..";

export class GroupMemberInfoModel {
    public primaryMemberID?: number;
    public isDistributor?: boolean;
    public eventMemberID?: number;
    public siteUrl?: string;
    public mainFK?: number;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public isGuest?: boolean;
    public inviteResponse?: number;
    public lastDateInviteSent?: Date | string;
    public lastDateQRcodeSent?: Date | string;
    public eventID?: number;
    public eventName?: string;
    public contactEmail?: string;
    public eventAgendaID?: number;
    public agendaTicketName?: string;
    public agendaTickets?: GroupMemberTicketModel[];

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}