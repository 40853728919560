import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './remove-cart-item-dialog.component.html',
    styleUrls: ['./remove-cart-item-dialog.component.scss']
})
export class RemoveCartItemDialogComponent implements OnInit {

    constructor(private readonly matDialogRef: MatDialogRef<RemoveCartItemDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    public ngOnInit(): void {
    }
    public close(): void {
        this.matDialogRef.close(false);
    }
    public submit(): void {
        this.matDialogRef.close(true);
    }
}