import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { CmsSessionService, CompanyCountry, LocaleService } from "@jeunesse/angular";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CompanyCountryResolver implements Resolve<CompanyCountry> {
  constructor(private readonly cmsSessionService: CmsSessionService,
    private readonly localeService: LocaleService){}

  public resolve(route: ActivatedRouteSnapshot): Observable<CompanyCountry> {
    return this.cmsSessionService.LoadContryInfoAsync(this.localeService.getCountryCode());
  }
}
