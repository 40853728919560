import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PolicyModel } from './models/policy.model';

/**
 * Policy state service used to keep state for policy items on
 * the application and thous preventing api traffic for policy look ups.
 */
@Injectable()
export class PolicyStateService {
    private policyState$ = new BehaviorSubject<PolicyModel[]>([]);

    /*
     * @returns policy array
     */
    public getPolicyValues(): PolicyModel[] {
        return this.policyState$.getValue() ? this.policyState$.getValue() : [];
    }
    /*
     * interface to add single policy
     * will not add if it already exists
     * @param PolicyModel to add to state array.
     * @returns one if added.
     */
    public addPolicy(policy: PolicyModel): number {
        let polCount: number = 0;
        if (!this.policyExists(policy.code)) {
            const policies: PolicyModel[] = this.policyState$.getValue();
            this.policyState$.next([...policies, policy]);
            polCount++;
        }
        return polCount;
    }
    /*
     * interface to get single policy via jet code path
     * @param codePath The full path to the policy or the last part of the code path.
     * @returns PolicyModel
     */
    public getPolicy(codePath: string): PolicyModel {
        return this.policyState$.getValue().find((data) => data.code === codePath);
    }
    /*
     * interface to check if a policy exists in the state array
     * @param codePath The full path to the policy or the last part of the code path.
     * @returns boolean true if policy exists in state array
     */
    public policyExists(codePath: string) {
        const policies: PolicyModel[] = this.policyState$.getValue();
        if (
            policies.some((data: PolicyModel) => {
                return data.code === codePath;
            })
        ) {
            return true;
        }
        return false;
    }
    /*
     * interface to append policies via an array.
     * @param newPolicies an array of PolicyModel to append to state array.
     * @returns number of policies actually added since duplicates are dropped.
     */
    public appendPolicies(newPolicies: PolicyModel[]): number {
        let polCount: number = 0;
        newPolicies.forEach((policy: PolicyModel) => {
            this.addPolicy(policy);
            polCount++;
        });
        return polCount;
    }
    /*
     * interface to clear state array
     */
    public clearPolicy(): any {
        this.policyState$.next([]);
    }
}
