import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CmsModule } from '@jeunesse/angular';

import { CategoryProductItemComponent } from './components/category/productitem/category-product-item.component';
import { ConfigModalComponent } from './components/configuration/config-modal.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ProductComponent } from './components/product/product.component';
import { ShadeFormComponent } from './components/nvshadematch/shade-form.component';
import { ShadeMatchComponent } from './components/nvshadematch/shade-match.component';
import { SmartDeliveryConfirmationComponent } from './components/smartdelivery/sd-confirmation.component';
import { CreateAPackComponent } from './components/create-a-pack/create-a-pack.component';
import { CreateAPackDetailsComponent } from './components/create-a-pack/create-a-pack-details.component';
import { ProductImageComponent } from './components/product/product-image/product-image.component';
import { ProductDescriptionComponent } from './components/product/product-description/product-description.component';
import { ProductStateService } from './services/product-state.service';
import { ProductService } from './services/public-api';
import { TruncateTextPipe } from './services/truncateText.pipe';
import { SimpleProductComponent } from './components/product/simple-product/simple-product.component';

@NgModule({
    declarations: [CategoryProductItemComponent, ConfigModalComponent, ConfigurationComponent,
        ProductComponent, SimpleProductComponent, ShadeFormComponent, TruncateTextPipe, ShadeMatchComponent, SmartDeliveryConfirmationComponent, CreateAPackComponent, CreateAPackDetailsComponent, ProductImageComponent, ProductDescriptionComponent],
    imports: [
        CommonModule,
        CmsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatDividerModule,
        MatExpansionModule,
        MatInputModule,
        MatDialogModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatToolbarModule,
        FlexLayoutModule,
        RouterModule,
        MatIconModule,
        MatCardModule,
        MatTabsModule
    ],
    providers: [
    ],
    exports: [CategoryProductItemComponent, ProductComponent, ConfigModalComponent, ConfigurationComponent, TruncateTextPipe,
        ShadeFormComponent, ShadeMatchComponent, SmartDeliveryConfirmationComponent, CreateAPackComponent],
    entryComponents: [ConfigModalComponent, ShadeFormComponent, ShadeMatchComponent, SmartDeliveryConfirmationComponent, SimpleProductComponent, CreateAPackDetailsComponent]
})
export class ProductsLibModule { }
