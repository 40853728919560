import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogVideoModel } from './models/public-api';

@Component({
    templateUrl: './dialog-video.component.html',
    styleUrls: ['./dialog-video.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('overlayState', [
            state('show', style({ opacity: 1 })),
            state('hide', style({ opacity: 0 })),
            transition('show => hide', animate('600ms ease')),
            transition('hide => show', animate('800ms ease'))
        ])
    ]
})
export class DialogVideoComponent implements OnInit {
    public show: boolean = false;
    public zIndex: number = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogVideoModel, private dialogRef: MatDialogRef<DialogVideoComponent>) {}

    public ngOnInit(): void {}

    /**
     * CHECK IF VIDEO IS END
     *
     * @author Alex Togo
     * @date 2019-11-14
     * @memberof DialogVideoComponent
     */
    public checkVideoEnded(): void {
        this.data.videoStatus !== 'LastVideo' ? this.dialogRef.close() : this.getCover();
    }

    /**
     * CLICK ON TOP BUTTON
     *
     * @author Alex Togo
     * @date 2019-11-14
     * @memberof DialogVideoComponent
     */
    public onCloseClick(): void {
        this.dialogRef.close({ event: 'Close' });
    }

    /**
     * CLICK ON BOTTOM BUTTON 'SKIP'
     *
     * @author Alex Togo
     * @date 2019-11-14
     * @param {HTMLVideoElement} videoCont
     * @memberof DialogVideoComponent
     */
    public onSkipClick(videoCont: HTMLVideoElement): void {
        if (this.data.videoStatus === 'LastVideo') {
            videoCont.pause();
            this.getCover();
        }

        if (this.data.videoStatus !== 'LastVideo') {
            this.dialogRef.close({ event: 'Skip' });
        }
    }

    /**
     * CLICK ON CUSTOM BUTTON LEARN MORE
     *
     * @author Alex Togo
     * @date 2019-11-14
     * @memberof DialogVideoComponent
     */
    public onLearnMoreClick(): void {
        this.dialogRef.close({ event: 'Redirect' });
    }

    /**
     * TOGGLE FOR THE STATE
     *
     * @readonly
     * @type {string}
     * @memberof DialogVideoComponent
     */
    public get stateName(): string {
        return this.show ? 'show' : 'hide';
    }

    /**
     * CUSTOM BACKGROUND COVER
     *
     * @author Alex Togo
     * @date 2019-11-14
     * @memberof DialogVideoComponent
     */
    public getCover(): void {
        this.zIndex = 1;
        this.show = true;
    }
}
