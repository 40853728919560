import { Component, OnInit, Inject } from '@angular/core';
import * as lodash from 'lodash';
import { ProductModel, ProductConfigModel } from '../../../products/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainCartService } from '@jeunesse/slide-cart';
import { ClientInfo, ClientInfoService } from '@jeunesse/angular';

const _ = lodash;

@Component({
    selector: 'jn-create-a-pack-details',
    templateUrl: 'create-a-pack-details.component.html',
    styleUrls: ['create-a-pack-details.component.scss']
})
export class CreateAPackDetailsComponent implements OnInit {
    public product: ProductModel;
    public groupedConfigurations: Array<Array<ProductConfigModel>> = new Array<Array<ProductConfigModel>>();
    public disableSelection: boolean = false;
    public isComingSoon: boolean = false;
    public clientInfo: ClientInfo;
    constructor(protected readonly dialogRef: MatDialogRef<CreateAPackDetailsComponent>,
        protected readonly shoppingCartService: MainCartService,
        protected readonly clientInfoService: ClientInfoService,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    public ngOnInit() {
        this.clientInfo = this.clientInfoService.getClientInfo();
        this.product = this.data.product;
        let groupedConfigs: any =  _(this.product.configurations)
                                    .groupBy(item => item.configurableProductPK)
                                    .sortBy(group => this.product.configurations.indexOf(group[0]))
                                    .value();
        this.groupedConfigurations = _.map(groupedConfigs, (configs, key) => {
            return configs;
        });
        this.disableSelection = this.product.configurations.some( x => x.productPK === 0 );
        this.isComingSoon = this.product.isComingSoon;
    }

    public closeDialog(): void {
            this.product.quantity = 0;
            this.dialogRef.close(this.product);
    }

    public addToCart(): void {
        if (!this.product.quantity)
            this.product.quantity = 1;
        this.dialogRef.close(this.product);
    }

    public checkIntValue(product: ProductModel): void {
        this.product.quantity = this.shoppingCartService.checkIntValue(product);
    }

    public onSelected(config: ProductConfigModel): void {
        _.remove(this.product.selectedConfig, c => {
            return c.configurableProductPK === config.configurableProductPK;
        });
        if (!this.product.selectedConfig) {
            this.product.selectedConfig = [];
        }
        this.product.selectedConfig.push(config);

        this.isComingSoon = this.product.isComingSoon || this.product.selectedConfig.some( x => x.isComingSoon);
    }
}
