export class EventEmailMemberPost {
    public firstName: string;
    public lastName: string;
    public email: string;
    public eventMemberID: number;
    constructor(obj: any = {}) {
        this.firstName = obj.firstName;
        this.lastName = obj.lastName;
        this.email = obj.email;
        this.eventMemberID = obj.eventMemberID;
    }
}
export class EventEmailPostModel {
    public primaryMemberID?: number;
    public groupPrimarySiteUrl: string;
    public groupPrimaryEmail: string;
    public eventName: string;
    public isQRRequest: boolean;
    public eventDate?: Date | string;
    public eventID: number | string;
    public members: EventEmailMemberPost[];

    constructor(obj: any = {}) {
        this.primaryMemberID = obj.primaryMemberID;
        this.groupPrimarySiteUrl = obj.groupPrimarySiteUrl;
        this.groupPrimaryEmail = obj.groupPrimaryEmail;
        this.eventName = obj.eventName;
        this.isQRRequest = obj.isQRRequest;
        this.eventDate = obj.eventDate;
        this.eventID = obj.eventID;
        this.members = obj.members ? obj.members.map((x: any) => new EventEmailMemberPost(x)) : [];
    }
}

