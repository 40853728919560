import { Injectable } from '@angular/core';
import { AuthService, CmsSessionService, LocaleService, SettingsService, WebStorageService } from '@jeunesse/angular';
import { MainCartService } from '@jeunesse/slide-cart';
import { Observable } from 'rxjs';
import { MenuAlertModel } from 'shared/models/menuAlert.model';

import { CategoryModel } from '../../products/models/category/category.model';
import { BrandModel } from '../../products/public-api';
import { JetMenuItem } from '../models/jet-menu-item.model';
import { MenuItem, MenuLayoutType } from '../models/menu-item.model';
import { MenuType } from '../models/menu-type.enum';
import { MenuVersion } from '../models/menu-version.enum';
import { Menus } from '../models/menus.model';
import { MenuApiService } from './menu-api.service';
import { MenuStateService } from './menu-state.service';

/**
 * This class is the in between for components make calls to the state and api services.
 */
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public menuVersion: typeof MenuVersion = MenuVersion;
  private preProdEnvs: string[] = ['dev', 'build', 'qa', 'stage'];
  private menus: Menus = {
    userMenu: {},
    backofficeMenu: {},
    categoryMenu: {},
    version: this.getMenuVersion(),
  };
  private cartType: string = 'R';
  private categoryMenuQuery: any;

  constructor(
    private readonly menuApiService: MenuApiService,
    private readonly menuStateService: MenuStateService,
    private readonly authService: AuthService,
    private readonly webStorage: WebStorageService,
    private readonly mainCartService: MainCartService,
    private readonly settings: SettingsService,
    private readonly localeService: LocaleService,
    private readonly cmsSessionService: CmsSessionService
  ) {
    if (this.authService.isLoggedIn && [1].includes(this.authService.getMainTypeId())) this.cartType = 'W';

    this.authService.tokenChanged.subscribe((loggedIn) => {
      if (this.cartType === 'R' && this.authService.isLoggedIn && [1].includes(this.authService.getMainTypeId())) this.cartType = 'W';

      this.reloadMenus();
    });

    this.mainCartService.getMainCart$().subscribe((cart) => {
      // Reload categories when switching cart types
      if (this.cartType && cart.cartType && this.cartType !== cart.cartType) {
        this.cartType = cart.cartType;
        this.getCategoryMenuItems(true);
      }
    });

    this.loadMenuAlert();
    this.loadUnreadNotificationsCount();
    this.checkIsBonusCreditsGoingToExpire();
  }

  public getUserMenu(): Observable<MenuItem[]> {
    return this.menuStateService.getUserMenu();
  }
  public getBackofficeMenu(): Observable<MenuItem[]> {
    return this.menuStateService.getBackOfficeMenu();
  }
  public getCategoryMenu(): Observable<CategoryModel[]> {
    return this.menuStateService.getCategoryMenu();
  }

  /**
   * use this on jeunnes URLS to transform your link and keep you in the environment your in.
   * */
  public toEnvLink(url: string): string {
    let localEnv = this.getEnv();
    let envs: string[] = this.preProdEnvs.filter((env) => env === localEnv);
    if (envs.length == 0) {
      // prod goes here
      return url;
    }
    let env = envs[0];
    if (env === 'build') {
      if (url.toLowerCase().indexOf('dev.com') > -1 || url.toLowerCase().indexOf('build.com') > -1) {
        url = url.replace('dev.com', '.com');
        url = url.replace('build.com', '.com');
      }
    }
    if (url.toLowerCase().indexOf(env + '.com') === -1) {
      if (env === 'build' && (url.toLowerCase().indexOf('joffice2.') > -1 || url.toLowerCase().indexOf('shop.') > -1)) {
        let port: number = 4000;
        if (url.toLowerCase().indexOf('shop.') > -1) {
          port = 4001;
        }
        url = url.replace('https://', 'http://');
        return url.replace('.com', env + '.com:' + port);
      } else {
        return url.replace('.com', env + '.com');
      }
    } else {
      return url;
    }
  }

  /**
   * will return current environment
   * */
  public getEnv(): string {
    if (location.hostname.toLowerCase().indexOf('dev.com') > -1) {
      return 'dev';
    }
    if (location.hostname.toLowerCase().indexOf('build.com') > -1) {
      return 'build';
    }
    if (location.hostname.toLowerCase().indexOf('qa.com') > -1) {
      return 'qa';
    }
    if (location.hostname.toLowerCase().indexOf('stage.com') > -1) {
      return 'stage';
    }
    return 'prod';
  }

  public loadMenus(hardReload: boolean = false): void {
    this.getCategoryMenuItems();
    this.getUserMenuItems();
    this.getBackofficeMenuItems(hardReload);
  }

  public loadMenuAlert(): void {
    if (this.authService.isLoggedIn) {
      this.menuApiService.getMenuAlert().subscribe((data) => {
        this.checkAndUpdateNotificationCookie(data);
        this.menuStateService.setMenuAlert(data);
      });
    }
  }

  public checkIsBonusCreditsGoingToExpire(): void {
    if (this.authService.isLoggedIn) {
      const bonusCreditsExpiresInDays: number = 14;
      this.menuApiService.checkIsBonusCreditsGoingToExpire(bonusCreditsExpiresInDays).subscribe((data) => {
        this.menuStateService.setShowBonusCreditsExpirationWarn(data);
      });
    }
  }

  public loadUnreadNotificationsCount(): void {
    if (this.authService.isLoggedIn) {
      this.menuApiService.getMenuUnreadCounts().subscribe((data) => {
        this.checkAndUpdateNotificationCookie(data);
        this.menuStateService.setMenuUnreadCount(data);
      });
    }
  }

  public reloadMenus(hardReload: boolean = false): void {
    this.webStorage.removeSession('JOFFICE');
    this.menus.categoryMenu = {};
    this.loadMenus(hardReload);
  }
  private checkAndUpdateNotificationCookie(data: MenuAlertModel[]): void {
    if (this.webStorage.getCookie('NOTIFICATIONS')) {
      let notifications: MenuAlertModel[] = JSON.parse(this.webStorage.getCookie('NOTIFICATIONS'));
      data.forEach((apiNotification, i) => {
        let index: number = notifications.findIndex(
          (el) => el.pageName === apiNotification.pageName && el.subpageName === apiNotification.subpageName
        );
        if (index !== -1) {
          notifications[index] = apiNotification;
        } else {
          notifications.push(apiNotification);
        }
      });
      this.webStorage.setCookie('NOTIFICATIONS', JSON.stringify(notifications), 1 / 96);
    } else {
      this.webStorage.setCookie('NOTIFICATIONS', JSON.stringify(data), 1 / 96);
    }
  }

  private saveMenus(): void {
    this.webStorage.setSession('JOFFICE', JSON.stringify(this.menus), false);
  }

  private getMenus(): void {
    try {
      let menu = JSON.parse(this.webStorage.getSession('JOFFICE', false));
      if (this.validateMenu(menu)) {
        this.menus = menu;
      }
    } catch {}
  }

  private validateMenu(menu: Menus): boolean {
    if (menu && menu.version === this.getMenuVersion()) {
      return true;
    }
    return false;
  }

  private saveMenu(menu: MenuItem[], menuType: MenuType): void {
    switch (menuType) {
      case MenuType.user:
        this.menus.userMenu = menu;
        break;
      case MenuType.backoffice:
        this.menus.backofficeMenu = menu;
        break;
      case MenuType.category:
        this.menus.categoryMenu = menu;
        break;
      default:
    }
    this.saveMenus();
  }

  private getMenu(menuType: MenuType): any {
    this.getMenus();
    switch (menuType) {
      case MenuType.user:
        return this.menus.userMenu;
      case MenuType.backoffice:
        return this.menus.backofficeMenu;
      case MenuType.category:
        return this.menus.categoryMenu;
      default:
        return null;
    }
  }

  private isMenuSaved(menuType: MenuType): boolean {
    let menu: any = this.getMenu(menuType);
    if (!menu || menu.length == 0 || !menu[0] || !menu[0].id) {
      return false;
    }
    return true;
  }

  private getCategoryMenuItems(hardReload: boolean = false): void {
    if (!hardReload && this.isMenuSaved(MenuType.category)) {
      this.menuStateService.addCategoryMenu(this.getMenu(MenuType.category));
      return;
    }
    // No categories are available for Loyalty Rewards.
    // Only retail users can redeem products currently.
    let cartType = this.cartType;
    if (cartType === 'LR') {
      cartType = 'R';
    }
    if (cartType === 'R' && this.authService.isLoggedIn && this.authService.getMainTypeId() === 1) {
      cartType = 'W';
    }

    const newCategoryMenuQuery: any = {
      country: this.localeService.getCountryCode() || 'US',
      culture: this.cmsSessionService.getCulture() || 'en',
      cartType,
    };

    if (!hardReload && JSON.stringify(newCategoryMenuQuery) === JSON.stringify(this.categoryMenuQuery)) {
      return;
    } else this.categoryMenuQuery = newCategoryMenuQuery;

    this.menuApiService.getCategoryMenus(newCategoryMenuQuery.country, newCategoryMenuQuery.culture, cartType, true).subscribe(
      (categories: CategoryModel[]) => {
        if (categories) {
          let categoryItems: CategoryModel[] = categories.sort((a, b) => b.seqNo + a.seqNo);
          let categoryWithBrands: CategoryModel[] = [];

          categoryItems.forEach((item: CategoryModel) => {
            let newMenus: any[] = [];
            if (item.hasSubMenu) {
              item.menus.forEach((menu) => {
                let menuBrand: BrandModel[] = item.brands.filter((brand) => brand.name === menu.productMenu);
                if (menuBrand.length > 0) {
                  menu.brandId = menuBrand[0].id;
                } else {
                  menu.brandId = '';
                }
                newMenus.push(menu);
              });
            }
            item.menus = newMenus;
            categoryWithBrands.push(item);
          });

          // Updates state that is then fired out to components.
          this.saveMenu(categoryItems, MenuType.category);
          this.menuStateService.addCategoryMenu(categoryItems);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  /**
   * Pulls user items based on user.
   */
  private getUserMenuItems(): void {
    if (this.isMenuSaved(MenuType.user)) {
      this.menuStateService.addUserMenu(this.getMenu(MenuType.user));
      return;
    }

    this.menuApiService.getUserMenu().subscribe(
      (jetItems: any) => {
        const menuItems: MenuItem[] = [];
        if (jetItems.menuItems) {
          jetItems.menuItems[0].submenu.forEach((jetItem: JetMenuItem) => {
            menuItems.push(this.mapJetItem(jetItem));
          });
        }
        // Updates state that is then fired out to components.
        this.saveMenu(menuItems, MenuType.user);
        this.menuStateService.addUserMenu(menuItems);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * Pulls backoffice items based on user.
   */
  private getBackofficeMenuItems(hardReload: boolean = false): void {
    if (this.isMenuSaved(MenuType.backoffice) && !hardReload) {
      this.menuStateService.addBackOfficeMenu(this.getMenu(MenuType.backoffice));
      return;
    }

    this.menuApiService.getBackofficeMenu().subscribe(
      (jetItems: JetMenuItem) => {
        const menuItems: MenuItem[] = [];
        if (jetItems.menuItems) {
          jetItems.menuItems.forEach((jetItem) => {
            menuItems.push(this.mapJetItem(jetItem));
          });
        }
        // Updates state that is then fired out to components.
        this.saveMenu(menuItems, MenuType.backoffice);
        this.menuStateService.addBackOfficeMenu(menuItems);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private mapJetItem(jetItem: JetMenuItem): MenuItem {
    let layoutType: MenuLayoutType = MenuLayoutType.List;
    if (jetItem.action.length === 0 && this.hasItems(jetItem)) {
      layoutType = MenuLayoutType.Title;
    }
    let menu: MenuItem = {
      imageUrl: jetItem.icon || 'fa-info-circle',
      isExternalLink: jetItem.isWebsiteLink,
      navigationLink: this.toEnvLink(jetItem.action),
      subItemLayout: layoutType,
      title: jetItem.content,
      translationKey: jetItem.translationKey || this.convertToKey(jetItem.content) || '#',
      subItems: this.mapJetItems(jetItem.submenu),
      id: jetItem.id,
      inNewTab: jetItem.inNewTab,
    };
    return menu;
  }

  private convertToKey(content) {
    if (!content) {
      return '#';
    }
    let result = content.replace(/<[^>]*>?/gm, '');
    result = result.replace('(', '-');
    result = result.replace(')', '');
    result = result.replace('™', '');
    return result.replace(/ /g, '');
  }
  private mapJetItems(jetItems: JetMenuItem[]): MenuItem[] {
    let menus: MenuItem[] = [];
    if (jetItems) {
      jetItems.forEach((jetItem) => {
        menus.push(this.mapJetItem(jetItem));
      });
    }

    return menus;
  }

  private hasItems(menuItem: JetMenuItem): boolean {
    if (menuItem.submenu) {
      if (menuItem.submenu.length > 0) {
        return true;
      }
    }
    return false;
  }
  /**
    used to replace repeated code in project files.
    */
  public getMenuVersion(): MenuVersion {
    if (this.authService.isLoggedIn) {
      if ([1, 2, 9, 13, 21, 22].find((type) => this.authService.getAuthToken().mainTypeId === type)) {
        switch (true) {
          case this.authService.getAuthToken().mainTypeId === 1:
            return MenuVersion.DISTRIBUTOR;
          case this.authService.getAuthToken().mainTypeId === 2:
            return MenuVersion.RETAIL;
          case this.authService.getAuthToken().mainTypeId === 9 ||
            this.authService.getAuthToken().mainTypeId === 13 ||
            this.authService.getAuthToken().mainTypeId === 21 ||
            this.authService.getAuthToken().mainTypeId === 22:
            return MenuVersion.RETAIL;
          case this.authService.hasClaim('CMS Admin') || this.authService.hasClaim('Jet Admin'):
            return MenuVersion.ADMIN;
          default:
            return MenuVersion.OTHER;
        }
      }
    }
    return MenuVersion.OTHER;
  }

  /**
   * Checks if link is outside of joffice2 or shop.
   * @param url The URL to check.
   * @return true if it is an http link.
   */
  public isExternal(link: string): boolean {
    if (link === '#') {
      return false;
    }
    let linkParts: string[] = link.split('?');
    let url: string = linkParts[0];
    /*
        look for local urls
        */
    if (url.indexOf('joffice2.jeunesseglobal') != -1 && this.settings.siteName == 'JOffice2') {
      return false;
    }
    if (url.indexOf('shop.jeunesseglobal') != -1 && this.settings.siteName == 'Shop') {
      return false;
    }
    if (url.indexOf('.') != -1 || this.settings.siteName == 'Shop' || this.hasHttp(url)) {
      return true;
    }
    return false;
  }

  /**
   * Checks if it is an http link.
   * @param url The URL to check.
   * @return true if it is an http link.
   */
  public hasHttp(url: string): boolean {
    if (url.indexOf('http:') > -1 || url.indexOf('https:') > -1 || url.indexOf('http/') > -1 || url.indexOf('https/') > -1) {
      return true;
    }
    return false;
  }

  /**
   * Converts the URL to a route.
   * @param url The URL to convert.
   * @return Local path.
   */
  public convertLinkToRoute(url: string): string {
    const parseUrl: RegExp = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;

    const result: RegExpExecArray = parseUrl.exec(url);
    if (result == null) {
      return url;
    }
    if (result.length > 4) {
      return `/${result[5]}`;
    } else {
      return url;
    }
  }

  public addQueryParamsToRoute(url: string): object {
    let params: string = url.split('?')[1];
    let queryParams: object;
    if (params) queryParams = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

    return queryParams;
  }

  /**
   * Converts the URL to a jback link.
   * @param url The URL to convert.
   * @return new jback url.
   */
  public toClassicUrl(url: string): string {
    return this.settings.classicSiteUrl + url;
  }

  /**
   * Converts the URL to a jback link as needed.
   * @param url The URL to convert.
   * @return new jback url.
   */
  public toExternalUrl(url: string): string {
    // KLK - Task: 46232
    if (url.includes('jeunesseglobal') && url.split('.').length > 2) {
      let domain: string = url.split('.')[1];
      url = url.replace(domain, this.settings.cookieDomain.split('.')[0]);
    }
    if (!this.hasHttp(url)) {
      let linkParts: string[] = url.split('?');
      let link: string = linkParts[0];
      if (link.indexOf('.') != -1) {
        return this.toClassicUrl(url);
      } else {
        return this.settings.jOffice + url;
      }
    } else {
      return url;
    }
  }

  /**
   * Checks if link is local.
   * @param url The URL to check.
   * @return true if link is local.
   */
  public isLocal(url: string): boolean {
    let here = location.pathname;
    if (url.length < 1 || here.length < 2) {
      return false;
    }
    if (url.indexOf(here) > -1) {
      return true;
    }
    return false;
  }
}
