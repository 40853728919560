import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CmsModule } from "@jeunesse/angular/lib/cms";

import { RewardYourselfBannerComponent } from "./components/public-api";

@NgModule({
    imports: [
        CommonModule,
        CmsModule,
        FlexLayoutModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule
    ],
    declarations: [
        RewardYourselfBannerComponent
    ],
    exports: [
        RewardYourselfBannerComponent
    ],
    providers: []
})
export class BannersModule { }
