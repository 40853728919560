import { Directive, ElementRef, Input, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslationImageService } from './translation-image.service';

/**
 * Returns the cms translation image.
 */
@Directive({ selector: '[cmsImage]' })
export class TranslationImageDirective implements OnInit, OnDestroy {

    /**
     * The state of the translation.
     */
    @Input()
    public state: string;

    /**
     * The name of the image.
     */
    @Input()
    public cmsImage: string;

    /**
     * the image source
     */
    @Input()
    public src: string;

    /**
     * the alt text for the image
     */
    @Input()
    public alt: string;

    @Input()
    public background: boolean;

    private toggleSubscription: Subscription;
    private sessionSubscription: Subscription;
    private oldFile;
    private fileList: FileList;

    constructor(
        private readonly el: ElementRef,
        private readonly translationService: TranslationImageService,
        private readonly renderer: Renderer2) {
    }

    public ngOnInit(): void {
        this.renderer.addClass(this.el.nativeElement, 'cms');
        if (this.background) {
            this.renderer.addClass(this.el.nativeElement, 'cms-background-image');
            this.renderer.addClass(this.el.nativeElement, 'fixed-background');
            this.renderer.addClass(this.el.nativeElement, 'media-container');
            this.renderer.addClass(this.el.nativeElement, 'overlay-container');
            this.renderer.addClass(this.el.nativeElement, 'fullscreen-image');
        }
        else {
            this.renderer.addClass(this.el.nativeElement, 'cms-image');
        }

        // add the buttons to the element
        this.addElements();
        this.updateImageSrc(this.src);

        const translation: string = this.translationService.translate(this.state, this.cmsImage, this.src);

        if (translation) {
            if (this.el.nativeElement.tagName === 'IMG') {
                this.el.nativeElement.src = translation;
            }
            else if (this.background) {
                this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${translation}`);
                this.renderer.addClass(this.el.nativeElement.children[1], 'hide'); // if this is a background image, hide the img tag
            }
            else {
                this.updateImageSrc(translation);
            }
        }

        if (this.translationService.isTranslator()) {
            // if translator, show upload button
            this.renderer.removeClass(this.el.nativeElement.children[0].children[0], 'hide');
        }

        if (this.translationService.checkHighlight() && this.el.nativeElement.tagName !== 'IMG') {
            this.renderer.addClass(this.el.nativeElement, 'cms-highlight');
        }

        this.toggleSubscription = this.translationService.getHighlightedObservable().subscribe((highlighted: boolean) => {
            if (highlighted && this.el.nativeElement.tagName !== 'IMG') {
                this.renderer.addClass(this.el.nativeElement, 'cms-highlight');
            }
            else {
                this.renderer.removeClass(this.el.nativeElement, 'cms-highlight');
            }
        });

        this.sessionSubscription = this.translationService.getSessionObservable().subscribe((session: any) => {
            const translation: string = this.translationService.translate(this.state, this.cmsImage, this.src);

            if (translation) {
                if (this.el.nativeElement.tagName === 'IMG') {
                    this.el.nativeElement.src = translation;
                }
                else {
                    this.updateImageSrc(translation);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.sessionSubscription.unsubscribe();
        this.toggleSubscription.unsubscribe();
    }

    public changeImage(): void {
        this.el.nativeElement.children[3].click();
    }

    public saveChange(): void {
        this.translationService.saveImage(this.fileList[0], this.cmsImage, 'image').subscribe((result) => {
            if (result) {
                if (this.background) {
                    this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${result}`);
                    this.renderer.addClass(this.el.nativeElement.children[1], 'hide'); // if this is a background image, hide the img tag
                }
                else {
                    this.updateImageSrc(result);
                }
            } else {
                this.cancelChange();
            }
        });

        this.renderer.addClass(this.el.nativeElement.children[0].children[1], 'hide');
        this.renderer.addClass(this.el.nativeElement.children[0].children[2], 'hide');
    }

    public cancelChange(): void {
        this.src = this.oldFile;
        if (this.background) {
            this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${this.src}`);
        }
        else {
            this.updateImageSrc(this.src);
        }
        this.renderer.removeChild(this.el.nativeElement, this.el.nativeElement.children[3]);
        this.renderer.addClass(this.el.nativeElement.children[0].children[1], 'hide');
        this.renderer.addClass(this.el.nativeElement.children[0].children[2], 'hide');
        this.addHiddenInput();
    }

    public updateImageSrc(url): void {
        if (!this.background) {
            if (url) {
                this.renderer.setAttribute(this.el.nativeElement.children[1], 'src', url);
                this.renderer.removeClass(this.el.nativeElement.children[1], 'hide');
                this.renderer.addClass(this.el.nativeElement.children[4], 'hide');
            } else { //show alt text if no url provided
                this.renderer.addClass(this.el.nativeElement.children[1], 'hide');
                this.renderer.removeClass(this.el.nativeElement.children[4], 'hide');
            }
        }
    }

    public addElements(): void {

        let paperclip: any = this.renderer.createElement('i');
        this.renderer.addClass(paperclip, 'fa');
        this.renderer.addClass(paperclip, 'fa-paperclip');
        this.renderer.addClass(paperclip, 'fa-fw');
        this.renderer.addClass(paperclip, 'wig-upload');

        let upload: any = this.renderer.createElement('div');
        this.renderer.addClass(upload, 'btn');
        this.renderer.addClass(upload, 'btn-primary');
        this.renderer.addClass(upload, 'btn-outline');
        this.renderer.addClass(upload, 'form-control');
        this.renderer.addClass(upload, 'wig-action');
        this.renderer.addClass(upload, 'wig-upload');
        this.renderer.addClass(upload, 'hide');
        this.renderer.listen(upload, 'click', (event) => {
            this.changeImage();
        });

        this.renderer.appendChild(upload, paperclip);

        let iupload: any = this.renderer.createElement('i');
        this.renderer.addClass(iupload, 'fa');
        this.renderer.addClass(iupload, 'fa-upload');
        this.renderer.addClass(iupload, 'fa-fw');
        this.renderer.addClass(iupload, 'wig-upload');

        let publish: any = this.renderer.createElement('div');
        this.renderer.addClass(publish, 'btn');
        this.renderer.addClass(publish, 'btn-primary');
        this.renderer.addClass(publish, 'btn-outline');
        this.renderer.addClass(publish, 'form-control');
        this.renderer.addClass(publish, 'wig-action');
        this.renderer.addClass(publish, 'wig-publish');
        this.renderer.addClass(publish, 'hide');
        this.renderer.listen(publish, 'click', (event) => {
            this.saveChange();
        });

        this.renderer.appendChild(publish, iupload);

        let close: any = this.renderer.createElement('i');
        this.renderer.addClass(close, 'fa');
        this.renderer.addClass(close, 'fa-close');
        this.renderer.addClass(close, 'fa-fw');

        let cancel: any = this.renderer.createElement('div');
        this.renderer.addClass(cancel, 'btn');
        this.renderer.addClass(cancel, 'btn-primary');
        this.renderer.addClass(cancel, 'btn-outline');
        this.renderer.addClass(cancel, 'form-control');
        this.renderer.addClass(cancel, 'wig-action');
        this.renderer.addClass(cancel, 'wig-cancel');
        this.renderer.addClass(cancel, 'hide');
        this.renderer.listen(cancel, 'click', (event) => {
            this.cancelChange();
        });

        this.renderer.appendChild(cancel, close);

        let media: any = this.renderer.createElement('div');
        this.renderer.addClass(media, 'media-controls-container');

        this.renderer.appendChild(media, upload);
        this.renderer.appendChild(media, publish);
        this.renderer.appendChild(media, cancel);
        this.el.nativeElement.appendChild(media);

        let image: any = this.renderer.createElement('img');
        this.renderer.setAttribute(image, 'alt', this.alt);
        this.renderer.setAttribute(image, 'data-name', this.cmsImage);
        
        

        this.el.nativeElement.appendChild(image);

        let sronly: any = this.renderer.createElement('span');
        this.renderer.addClass(sronly, 'sr-only');
        let completeText: any = this.renderer.createText('0% Complete');
        this.renderer.appendChild(sronly, completeText);

        let progressbar: any = this.renderer.createElement('div');
        this.renderer.addClass(progressbar, 'progress-bar');
        this.renderer.addClass(progressbar, 'progress-bar-striped');
        this.renderer.addClass(progressbar, 'active');
        this.renderer.setAttribute(progressbar, 'role', 'progressbar');
        this.renderer.setAttribute(progressbar, 'aria-valuenow', '0');
        this.renderer.setAttribute(progressbar, 'aria-valuemin', '0');
        this.renderer.setAttribute(progressbar, 'aria-valuemax', '100');
        this.renderer.setStyle(progressbar, 'width', '0%');

        this.renderer.appendChild(progressbar, sronly);
        let progress: any = this.renderer.createElement('div');
        this.renderer.addClass(progress, 'progress');
        this.renderer.addClass(progress, 'hide');

        this.renderer.appendChild(progress, progressbar);
        this.el.nativeElement.appendChild(progress);

        this.addHiddenInput();

        let imgalttext: any = this.renderer.createElement('span');
        let alttext: any = this.renderer.createText(this.alt);
        this.renderer.appendChild(imgalttext, alttext);
        this.el.nativeElement.appendChild(imgalttext);
        
    }

    public updateSelected(event): void {
        this.fileList = event.target.files;
        if (this.fileList.length > 0) {
            this.oldFile = this.src;

            let reader: FileReader = new FileReader();
            reader.readAsDataURL(this.fileList[0]);
            reader.onload = (evt) => {
                if (this.background) {
                    this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${reader.result}`);
                    this.renderer.addClass(this.el.nativeElement.children[1], 'hide'); // if this is a background image, hide the img tag
                }
                else {
                    this.updateImageSrc(reader.result as string);
                }
                this.renderer.removeClass(this.el.nativeElement.children[0].children[1], 'hide');
                this.renderer.removeClass(this.el.nativeElement.children[0].children[2], 'hide');
            };
        }
    }

    private addHiddenInput(): void {
        let hiddenInput: any = this.renderer.createElement('input');
        this.renderer.setAttribute(hiddenInput, 'type', 'file');
        this.renderer.setStyle(hiddenInput, 'visibility', 'hidden');
        this.renderer.setStyle(hiddenInput, 'position', 'absolute');
        this.renderer.setStyle(hiddenInput, 'overflow', 'hidden');
        this.renderer.setStyle(hiddenInput, 'width', '0px');
        this.renderer.setStyle(hiddenInput, 'height', '0px');
        this.renderer.setStyle(hiddenInput, 'border', 'none');
        this.renderer.setStyle(hiddenInput, 'margin', '0px');
        this.renderer.setStyle(hiddenInput, 'padding', '0px');
        this.renderer.listen(hiddenInput, 'change', (event) => {
            this.updateSelected(event);
        });

        this.el.nativeElement.appendChild(hiddenInput);
    }
}