export class AgendaQueryModel {
    public eventId?: number;
    public startDate?: string;
    public endDate?: string;
    public allowRegistration?: boolean;
    public sessionId?: string;
    public mainFk?: string;

    constructor(options: any) {
        this.eventId = options.eventId;
        this.startDate = options.startDate ? options.startDate : '';
        this.endDate = options.endDate ? options.endDate : '';
        this.allowRegistration = options.allowRegistration ? options.allowRegistration : '';
        this.sessionId = options.sessionId ? options.sessionId : '';
        this.mainFk = options.mainFk ? options.mainFk : '';
    }
}