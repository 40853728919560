/**
 * Product Price Model
 */
export class ProductPriceModel {
    public priceType: string;
    public currencySymbol: string;
    public price: number;
    public formattedPrice: string;
    public priceWarning: string;

    constructor(obj: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}