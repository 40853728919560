import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface LoadingState {
    [key: string]: boolean;
}
@Injectable({ providedIn: 'root' })
export class LoadingEventsService {
    public loadingState: LoadingState = {};
    public loadingState$ = new BehaviorSubject<LoadingState>(this.loadingState);

    public changeLoadingState(name: string, state: boolean): void {
        this.loadingState[name] = state;
        this.loadingState$.next(this.loadingState);
    }
    public getSpecificLoader(apiCallName: string): Observable<boolean> {
        return this.loadingState$.asObservable().pipe(
            map((data) => {
                return data[apiCallName];
            })
        );
    }
    // pass in array of api calls u want to watch, if one of those calls still pending it will return u true,
    // otherwise false. If api call/calls has not been made return false as well.
    public getSpecificLoaders(apiCallNames: string[]): Observable<boolean> {
        return this.loadingState$.asObservable().pipe(
            map((data) => {
                for (let index: number = 0; index < apiCallNames.length; index++) {
                    const apiCall: string = apiCallNames[index];
                    if (data[apiCall] !== undefined && data[apiCall]) { return true; }
                }
                return false;
            })
        );
    }
}
