import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { CmsModule } from '../../cms/src/cms.module';
import { AgreeToTermsComponent } from './components';

@NgModule({
    imports: [
        CommonModule,
        CmsModule,
        MatCheckboxModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRadioModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        AgreeToTermsComponent
    ],
    exports: [
        AgreeToTermsComponent
    ]
})
export class AgreeToTermsModule {}
