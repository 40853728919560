import { Component, Input, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MerchantRedirectModel, MerchantRedirectScriptModel, MerchantRedirectModelStep2 } from '../../models/merchant-redirect.interface';
import { MerchantRedirectPaymentService } from '../../services/merchant-redirect-payment.service';
import { MerchantRedirectFormModelStep2 } from '../../models/merchant-redirect-form-model';
import { OrderService } from '../../../checkout/services/order.service';
import { LoaderService } from '../../../loader/public-api';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { QRCodePaymentStatus } from '../../models/qrcode-payment-status.model';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
    selector: 'app-merchant-dynamic-form',
    templateUrl: './merchant-dynamic-form.component.html',
    styleUrls: ['./merchant-dynamic-form.component.scss'],
    providers: [MerchantRedirectPaymentService]
})
export class MerchantDynamicFormComponent implements OnInit, AfterViewInit {
    @Input() public merchantModel: MerchantRedirectModel;
    @Input() public submitButtonID: string;
    @Input() public sourceScripts: string[];
    public form: FormGroup;
    public payLoad = '';
    public groupedParams: any[];
    public iframeScript = '';
    public scriptError = '';
    public iframeProcessing: boolean;
    public step2QRCodeModel: MerchantRedirectModelStep2;
    public isCheckingProcess: boolean = false;
    @Output() public errorReceived: EventEmitter<string> = new EventEmitter();
    @Output() public qrCodeOrderPayed: EventEmitter<QRCodePaymentStatus> = new EventEmitter<QRCodePaymentStatus>();
    @Output() public isLoadingScript: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    private _checkStatusInterval: any;
    private _checkStatusIntervalTime: number = 5000;
    private _qrCodeExpireTime: number = 3600;
    private _checkCount: number = 0;
    private isDesktop: boolean;
    private isTablet: boolean;
    private isMobile: boolean;

    constructor(
        private merchantRedirectPaymentService: MerchantRedirectPaymentService,
        private loaderService: LoaderService,
        private orderService: OrderService,
        private sanitizer: DomSanitizer,
        private deviceService: DeviceDetectorService
    ) { }

    public ngOnInit(): void {
        let group: any = {};
        this.iframeProcessing = false;
        this.merchantModel.merchantParams.forEach(question => {
            let validators = [];
            if (question.required) {
                validators.push(Validators.required);
            }
            if (question.validatorType === 'pattern') {
                validators.push(Validators.pattern(question.regexPattern));
            }
            if (question.validatorType === 'email') {
                validators.push(Validators.email);
            }
            group[question.key] = new FormControl(question.value || '', validators);
        });
        this.form = new FormGroup(group);
        this.groupedParams = Object.values(this.groupBy(this.merchantModel.merchantParams, 'order'));
        this.isDesktop = this.deviceService.isDesktop();
        this.isTablet = this.deviceService.isTablet();
        this.isMobile = this.deviceService.isMobile();
    }

    public ngAfterViewInit(): void {
        if (this.merchantModel.initScriptModel) {
            this.loadScriptModel();
            this.isLoadingScript.next(true);
        }
    }

    onInitForm() {
        this.iframeProcessing = false;
    }

    checkValidForm(): boolean {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
        }
        return this.form.valid;
    }

    onSubmit(): void {
        this.loaderService.show();
        if (this.form.valid) {
            this.payLoad = JSON.stringify(this.form.value);
            if (this.merchantModel.redirectType === 'IFrame' || this.merchantModel.redirectType === 'SubmitForm') {
                this.iframeProcessing = true;
                this.loadScriptModel();
            }
            else {
                this.loaderService.hide();
            }
        } else {
            this.form.markAllAsTouched();
            this.loaderService.hide();
        }
    }

    loadScriptModel(): void {
        if (this.merchantModel.scriptModel) {
            this.initScriptModel(this.merchantModel.scriptModel);
        } else {
            let model: MerchantRedirectFormModelStep2 = {
                formName: 'merchantDynamicForm',
                paymentMethodCode: this.merchantModel.paymentMethodCode,
                mainOrdersFK: this.merchantModel.mainOrdersFK,
                formParameters_json: this.payLoad,
                placeHolderID: 'iframeDiv',
                submitButtonID: this.submitButtonID,
                amount: this.merchantModel.amount,
                pendingPlaceHolderID: 'merchantPending',
                errorPlaceHolderID: 'merchantError',
                autoSubmitScript: this.merchantModel.autoSubmitScript
            };
            this.merchantRedirectPaymentService.getMerchantModelStep2(model).subscribe((step2Model) => {
                this.loaderService.hide();
                if (step2Model) {
                    this.orderService.getOrderDetails(this.merchantModel.mainOrdersFK).subscribe((result) => { });
                    if (step2Model.scriptModel || step2Model.qrCodeModel) {
                        if (step2Model.scriptModel) {
                            this.initScriptModel(step2Model.scriptModel);
                        }
                        else {
                            this.initQRCodeModel(step2Model);
                        }
                    }
                    else {
                        this.scriptError = 'Error getting payment form.';
                        this.errorReceived.emit(this.scriptError);
                    }
                }
            });
        }
    }

    initScriptModel(scriptModel: MerchantRedirectScriptModel) {
        if (scriptModel) {
            if (scriptModel.errorMessage) {
                this.scriptError = scriptModel.errorMessage;
                this.errorReceived.emit(scriptModel.errorMessage);
            }
            else {
                const sourceEL: HTMLElement = document.getElementById('iframeDiv');
                if (sourceEL) {
                    sourceEL.innerHTML = '';
                    if (scriptModel.sourceScript && this.sourceScripts.indexOf(scriptModel.sourceScript) === -1) {
                        this.loadScript(sourceEL, scriptModel.sourceScript).then(
                            () => this.loadTextScript(sourceEL, scriptModel.initScript)
                        );
                        this.sourceScripts.push(scriptModel.sourceScript);
                    }
                    else {
                        this.loadTextScript(sourceEL, scriptModel.initScript);
                    }
                }
            }
        }
        else {
            this.scriptError = 'Error getting payment form.';
            this.errorReceived.emit(this.scriptError);
        }
    }

    initQRCodeModel(step2QRCodeModel: MerchantRedirectModelStep2) {
        if (step2QRCodeModel.qrCodeModel) {
            if (step2QRCodeModel.qrCodeModel.errorMessage) {
                this.scriptError = step2QRCodeModel.qrCodeModel.errorMessage;
                this.errorReceived.emit(step2QRCodeModel.qrCodeModel.errorMessage);
            }
            else {
                this.step2QRCodeModel = step2QRCodeModel;
                this._clearCheckInterval();
                this._checkStatusInterval = setInterval(() => {
                    this.checkPaymentStatus(step2QRCodeModel.mainOrdersFK, step2QRCodeModel.paymentMethodCode, step2QRCodeModel.qrCodeModel.paymentReference);
                }, this._checkStatusIntervalTime);
            }
        }
        else {
            this.scriptError = 'Error getting payment form.';
            this.errorReceived.emit(this.scriptError);
        }
    }

    loadTextScript(sourceEL: HTMLElement, text: string) {
        return new Promise(resolve => {
            const script: HTMLScriptElement = document.createElement('script');
            script.text = text;
            sourceEL.appendChild(script);
            resolve();
        });
    }

    loadScript(sourceEL: HTMLElement, url: string) {
        return new Promise((resolve, reject) => {
            const script: HTMLScriptElement = document.createElement('script');
            script.src = url;
            script.onload = resolve;
            script.onerror = reject;
            sourceEL.appendChild(script);
        });
    }

    groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {})
    }

    getSafeUrl(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public copyQRCodeText(): void {
        let selBox: HTMLTextAreaElement = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.step2QRCodeModel.qrCodeModel.qrCodeText;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    private _clearCheckInterval(): void {
        if (this._checkStatusInterval) clearInterval(this._checkStatusInterval);
    }

    private checkPaymentStatus(mainOrdersFK: number, paymentMethodCode: string, paymentReference: string): void {
        if (!this.isCheckingProcess) {
            this._checkCount = this._checkCount + 1;
            this.isCheckingProcess = true;
            this.orderService.isQRCodeOrderStatusUpdated(mainOrdersFK, paymentMethodCode, paymentReference).subscribe(
                (isUpdated: boolean) => {
                    const qrCodePaymentStatus: QRCodePaymentStatus = {
                        isUpdated: isUpdated,
                        paymentMethodCode: paymentMethodCode,
                        paymentReference: paymentReference
                    }
                    if (this._checkStatusIntervalTime / 1000 * this._checkCount > this._qrCodeExpireTime) {
                        isUpdated = true;
                    }
                    if (isUpdated) {
                        this._clearCheckInterval();
                        this.qrCodeOrderPayed.emit(qrCodePaymentStatus);
                    }
                    this.isCheckingProcess = false;
                },
                (err: any) => {
                    this.isCheckingProcess = false;
                    this.errorReceived.emit(err);
                }
            );
        }
    }
}
