import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import {
    AuthService,
    CmsSessionService,
    LocaleService,
    SeoService,
    SettingsService,
    WebStorageService,
} from '@jeunesse/angular';
import { MainCartItem, MainCartService, ShoppingCart, ShoppingCartItem } from '@jeunesse/slide-cart';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SubSink } from 'subsink';

import { OrderService } from '../../../checkout/services/public-api';
import { MemberService } from '../../../member/services/member.service';
import { PaymentStateService } from '../../../payment/public-api';
import { RepSiteInfo, RepSiteService } from '../../../repsite/public-api';
import { CartSummaryItem, OrderModel } from '../../models/public-api';
import { SmartDeliveryItemModel, SmartDeliveryModel, SmartDeliveryService } from '../../../member/public-api';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'jn-cartsummary',
    templateUrl: './cartsummary.component.html',
    styleUrls: ['./cartsummary.component.scss'],
})
export class CartsummaryComponent implements OnInit, OnDestroy {
    @Input() public cartObservable$: Observable<ShoppingCart>;
    @Input() public showCartBlock: boolean = true;
    @Input() public orderConfirmationPage: boolean;
    @Input() public smartDeliveryModel$: Observable<SmartDeliveryModel>;
    @Input() public siteUrl: string;
    @Input() public isGuestAccount: boolean;
    @Input() public editCartEnabled: boolean = true;
    @Input() public showShippingTaxCountries: string[];
    protected cartItemsSubject$: BehaviorSubject<CartSummaryItem[]> = new BehaviorSubject<CartSummaryItem[]>([]);
    public cartItemsObservable$: Observable<CartSummaryItem[]> = this.cartItemsSubject$.asObservable();
    protected smartDeliveryItemsSubject$: BehaviorSubject<CartSummaryItem[]> = new BehaviorSubject<CartSummaryItem[]>([]);
    public smartDeliveryItemsObservable$: Observable<CartSummaryItem[]> = this.smartDeliveryItemsSubject$.asObservable();
    public cartHasAutoShipItems: boolean = false;
    public showSDCheckboxes: boolean = false;
    public repFrontUrl: string;
    public isRedemptionMode: boolean;
    public mainType: number;
    public isLoggedIn: boolean;
    public isTranslator: boolean = false;
    public orderModel: any = {};
    public jfrontCart: string;
    public cartType: string;
    public culture: string;
    public isConfirmationPage: boolean = false;
    public isShippingPage: boolean = false;
    public loadCartInfo: boolean;
    public confirmationCartItems: any;
    public smartDeliveryActive: boolean = false;
    public isThisSignUp: boolean = false;
    public currencySymbol: string;
    public isCountryBR: boolean = false;
    public subscriptions: Subscription = new Subscription();
    public isNoPersonaluseonly: number;
    public showCartInfoWarning: boolean = false;
    public cart: ShoppingCart;
    public repInfo: RepSiteInfo;
    public orderModelSubject$ = new BehaviorSubject<OrderModel>(this.orderModel);
    public isShippable: boolean = true;
    public showContinueButton: boolean = false;
    private smartDeliveryModel: SmartDeliveryModel;
    private subs = new SubSink();
    private cartChanged: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly seoService: SeoService,
        private readonly mainCartService: MainCartService,
        private readonly memberService: MemberService,
        private readonly authService: AuthService,
        private readonly repSiteService: RepSiteService,
        private readonly orderService: OrderService,
        private readonly localService: LocaleService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly paymentStateService: PaymentStateService,
        private readonly webStorage: WebStorageService,
        private readonly settings: SettingsService,
        private readonly smartDeliveryService: SmartDeliveryService
    ) {
        this.isLoggedIn = this.authService.isLoggedIn;
        this.isRedemptionMode = this.mainCartService.isStoreInRedemptionMode;
        this.memberService.loadMemberInfoFromLogin(true);
        this.mainType = this.memberService.memberModel.mainTypeFk;
    }

    public ngOnInit(): void {
        this.isTranslator = this.cmsSessionService.isTranslator();
        this.culture = this.localService.getLocaleCookie();

        this.subs.sink = this.repSiteService.repInfoObservable$.subscribe((repInfo: RepSiteInfo) => {
            this.repInfo = repInfo;
            this.jfrontCart = `/${this.repInfo.alias}/edit-cart`;
        });

        this.subs.sink = this.cartObservable$.subscribe((cart: ShoppingCart) => {
            this.cart = cart;
            if (this.cart && this.cart.cartKey) {
                if (this.jfrontCart.includes('undefined')) {
                    this.jfrontCart = `/${this.cart.referrerSiteUrl}/edit-cart`;
                }
                this.isThisSignUp = this.isSignup();
                if (cart.countryCode && this.orderModel) {
                    this.orderModel.countryCode = cart.countryCode;
                }
                this.loadCart();
            }
            if (this.orderModel && this.orderModel.mainOrdersFk > 0 && this.cartChanged) {
                this.orderService.getOrderDetails(this.orderModel.mainOrdersFk).subscribe((res) => {
                    this.cartChanged = false;
                });
            }
        });

        this.subs.sink = this.router.events.subscribe((nav) => {
            if (nav instanceof NavigationEnd) {
                if (nav.url.includes('/checkout/confirmation')) {
                    this.isConfirmationPage = true;
                } else {
                    this.isConfirmationPage = false;
                }
                if (nav.url.includes('/checkout/discounted-products')) {
                    this.showContinueButton = true;
                } else {
                    this.showContinueButton = false;
                }
                if (nav.url.includes('/checkout/shipping')) {
                    this.isShippingPage = true;
                } else {
                    this.isShippingPage = false;
                }
            }
        });

        if (this.router.url.includes('/checkout/confirmation')) {
            this.isConfirmationPage = true;
        } else {
            this.isConfirmationPage = false;
        }

        if (this.router.url.includes('/checkout/shipping')) {
            this.isShippingPage = true;
        } else {
            this.isShippingPage = false;
        }

        if (this.router.url.includes('/checkout/discounted-products')) {
            this.showContinueButton = true;
        } else {
            this.showContinueButton = false;
        }

        this.subs.sink = this.route.queryParams.subscribe((params: Params) => {
            if (params['cartKey']) {
                this.loadCartInfo = true;
            } else {
                this.loadCartInfo = false;
            }
        });

        this.subs.sink = this.paymentStateService.tokenState.subscribe((tokenAmount) => {
            if (tokenAmount) {
                this.orderModel.bonusToken = tokenAmount;
                this.orderModel.discount = this.orderModel.discount + tokenAmount;
                this.orderService.setOrderModel(this.orderModel);
                this.orderService.getOrderDetails(this.orderModel.mainOrdersFk).subscribe((res) => {
                    if (res) {
                        this.orderService.updateSplitRemainingOrder(res.total);
                        this.orderService.setOrderModel(this.orderModel);
                    }
                });
            }
        });

        // Sidorov, sometimes we get outdated mainOrdersFk, so we have to subscribe to orderModel change to get updated data
        this.subs.sink = this.orderService.getOrderModel().subscribe((res: OrderModel) => {
            if (!res) return;

            if (!this.loadCartInfo) {
                this.orderModel = res;

                let cartItems: CartSummaryItem[] = this.convertConfirmationItems(res.items);
                if (cartItems.length > 0) {
                    this.cartItemsSubject$.next(cartItems);
                }
                this.currencySymbol = res.currencySymbol;
                this.confirmationCartItems = res.items;
                if (res.mainOrderTypeFk === 28) {
                    this.orderModel.discount = 0;
                    this.orderModel.subTotal = 0;
                }
                this.orderModel.mainOrderTypeFk = res.mainOrderTypeFk;
                this.orderModel.currencySymbol = this.currencySymbol;
                this.orderModelSubject$.next(this.orderModel);
                this.loadSmartDeliveryInfo(this.smartDeliveryModel);
            }

            if (!this.isShippingPage) {
                // KLK - Hide Personal Use prompt display if we have an order number
                this.orderService.setPersonalUsePromptDisplay({
                    showPersonalUse: false,
                    showPersonalUseWarning: false,
                    isPersonalResaleOn: false
                });
            }
        });

        if (this.smartDeliveryModel$) {
            this.subs.sink = this.smartDeliveryModel$.subscribe((model: SmartDeliveryModel) => {
                this.loadSmartDeliveryInfo(model);
            });
        }

        // Checks to see if we should hide the side cart section.
        this.subs.sink = this.orderService.hideCart.subscribe((res: boolean) => {
            this.showCartBlock = res;
        });

        this.subs.sink = this.memberService.memberInfoState.subscribe((res: any) => {
            this.mainType = res.mainTypeFk;
        });

        this.subs.sink = this.smartDeliveryService.getSettings$().subscribe((res: SmartDeliveryModel) => {
            if (res && res.active) {
                this.smartDeliveryActive = res.active;
            }
        });
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public setPersonalUseOnly(isPersonalUse: boolean) {
        this.orderService.setPersonalUseFlag(isPersonalUse);
    }

    public sendGAInfo(action: string, label: string): void {
        let category: string = 'Address & Shipping';

        if (this.router.url.includes('/checkout/billing')) {
            category = 'Payment & Billing';
        } else if (this.router.url.includes('/checkout/review')) {
            category = 'Review & Submit';
        } else if (this.router.url.includes('/checkout/confirmation')) {
            category = 'Confirmation';
        }
        this.seoService.trackClick(label, action, category);
    }

    public deleteCartItem(value: any): void {
        if (value) {
            let cart = this.mainCartService.getShoppingCart();
            let selected = cart.cartItems.find((cartItem) => cartItem.productFk === value.productPk);
            if (selected) {
                this.mainCartService.removeCartItem(selected.id);
                this.cartChanged = true;
            }
        }
    }

    public updateCartItem(value: any): void {
        if (value) {
            let cart = this.mainCartService.getShoppingCart();
            let selected = cart.cartItems.find((cartItem) => cartItem.productFk === value.productPk);

            if (selected) {
                let model: MainCartItem = {
                    mainCartFk: cart.cartKey,
                    setupForAs: value.setupForAs,
                    mainCartItemsPk: selected.id,
                    productFk: selected.productFk,
                    discount: selected.discount,
                    quantity: selected.quantity,
                    extraFee: selected.extraFee,
                    configFk: selected.configFk,
                    priceListFk: selected.priceListFk,
                };
                this.mainCartService.updateCartItem(model.mainCartItemsPk, model, false);
                this.cartChanged = true;
            }
        }
    }

    public onContinueClick(): void {
        this.router.navigate(['checkout', 'shipping'], { queryParams: { cartKey: this.cart.cartKey } });
    }

    public onSetupSDChange(val: MatCheckboxChange): void {
        if (!val.checked) {
            console.log(this.cart);
            if (this.cart && this.cart.cartItems.length) {
                this.cart.cartItems.forEach((item) => {
                    if (item.setupForAs) {
                        let model: MainCartItem = {
                            mainCartFk: this.cart.cartKey,
                            setupForAs: false,
                            mainCartItemsPk: item.id,
                            productFk: item.productFk,
                            discount: item.discount,
                            quantity: item.quantity,
                            extraFee: item.extraFee,
                            configFk: item.configFk,
                            priceListFk: item.priceListFk,
                        };
                        this.mainCartService.updateCartItem(model.mainCartItemsPk, model, false);
                    }
                });
            }
        }
    }

    private isSignup(): boolean {
        try {
            if (this.webStorage.getCookie('signupKey')) {
                return true;
            }
        } catch (e) {}
        return false;
    }

    private getProductName(item: ShoppingCartItem): string {
        let productName: string = item.name;
        return productName;
    }

    private getImageUrl(item: ShoppingCartItem): string {
        let productImg: string = item.imageUrl;
        return productImg;
    }

    private getProductPricing(item: ShoppingCartItem): number {
        return item.basePrice;
    }

    private convertCartItems(items: ShoppingCartItem[]): CartSummaryItem[] {
        let returnItems: CartSummaryItem[] = [];
        items.forEach((item: ShoppingCartItem) => {
            const cartItem: CartSummaryItem = {
                productPk: item.productFk,
                name: this.getProductName(item),
                quantity: item.quantity,
                price: this.getProductPricing(item),
                imageUrl: this.getImageUrl(item),
                points: item.points,
                isShippable: item.isShippable,
                showEdit: !this.isConfirmationPage && !item.isPaCItem,
                editUrl:
                    (this.authService.isLoggedIn && this.mainType === 1 && this.authService.getStatus() === 'P') ||
                    item.isStarterKit
                        ? this.settings.joinUrl + (!item.isStarterKit ? '/profile/package' : '/profile/starter-kit')
                        : this.jfrontCart,
                imageUrls: item.imageUrls || null,
                childItems: item.childItems || null,
                isPaCItem: item.isPaCItem,
                cv: item.cv,
                sku: item.sku,
                mainCartItemsFK: item.id,
                currencySymbol: this.currencySymbol,
                formattedPrice: item.formattedBasePrice,
                formattedTotal: item.formattedLineSubTotal,
                showSDCheckbox: item.showSDCheckbox,
                setupForAs: item.setupForAs,
            };
            returnItems.push(cartItem);
        });
        return returnItems;
    }

    private convertConfirmationItems(items: any[]): CartSummaryItem[] {
        let returnItems: CartSummaryItem[] = [];
        items.forEach((item: any) => {
            const cartItem: CartSummaryItem = {
                productPk: item.productPK,
                name: item.productName,
                quantity: item.quantity,
                price: item.basePrice,
                imageUrl: item.imageUrl,
                points: item.points,
                isShippable: item.isShippable,
                showEdit: !this.isConfirmationPage && !this.orderModel.isCreateAndPayOrder,
                editUrl:
                    this.authService.isLoggedIn && this.mainType === 1 && this.authService.getStatus() === 'P'
                        ? this.settings.joinUrl + '/profile/package'
                        : this.jfrontCart,
                imageUrls: null,
                childItems: item.childItems || null,
                isPaCItem: item.isPaCItem,
                cv: item.cv,
                sku: item.sku,
                mainCartItemsFK: item.mainOrderItemsPK,
                currencySymbol: this.currencySymbol,
                formattedPrice: item.formattedBasePrice,
                formattedTotal: item.formattedLineTotal,
            };
            returnItems.push(cartItem);
        });
        return returnItems;
    }

    private loadCart(): void {
        if (this.cart) {
            this.cartType = this.cart.cartType;
            // Check if any items are marked as AS
            this.cartHasAutoShipItems = _.some(this.cart.cartItems, ['setupForAs', true]);
            this.showSDCheckboxes = this.cart.shouldCreateAccount;
            this.orderModel.currencyCode = this.cart.currencyCode;
            if (!this.currencySymbol) {
                if (this.cart.currencySymbol) {
                    this.currencySymbol = this.cart.currencySymbol;
                } else {
                    if (this.cart.cartItems && this.cart.cartItems.length > 0 && this.cart.cartItems[0] !== null) {
                        // set the currency symbol for the cart to display
                        let itemHasCurrencySymbol = this.cart.cartItems.filter((x) => x.currencySymbol != null);
                        if (itemHasCurrencySymbol.length > 0) {
                            this.currencySymbol = itemHasCurrencySymbol[0].currencySymbol;
                        } else {
                            let hasChildItems = this.cart.cartItems.filter((x) => x.childItems.length > 0);
                            if (hasChildItems.length > 0) {
                                this.currencySymbol = hasChildItems[0].childItems[0].currencySymbol;
                            }
                        }
                    }
                }
            }
            if (this.currencySymbol) {
                this.orderModel.currencySymbol = this.currencySymbol;
            }
            let cartItems: CartSummaryItem[];
            if (!this.loadCartInfo && this.confirmationCartItems && this.confirmationCartItems.length > 0) {
                cartItems = this.convertConfirmationItems(this.confirmationCartItems);
                this.cartItemsSubject$.next(cartItems);
                this.orderModelSubject$.next(this.orderModel);
            } else {
                // Set the cart items
                if (this.cart.cartItems.length > 0) {
                    this.cart.cartItems.forEach((x, value, key) => {
                        if (!x.currencySymbol) {
                            x.currencySymbol = this.currencySymbol;
                        }
                        x.childItems.forEach((index) => {
                            if (index) {
                                x.imageUrls = [];
                                x.imageUrls.push({
                                    image: index.imageUrl,
                                    name: index.name,
                                    productPK: index.productFk
                                });
                                index.imageUrls = [];
                                index.imageUrls.push(index.imageUrl);
                            }
                        });
                    });

                    /**
                     * IT AUTOMATICALLY UNSUBSCRIBES AFTER THE FIRST EXECUTION
                     */

                    let shippableItems: any;
                    shippableItems = this.cart.cartItems.filter((x) => x.isShippable);

                    if (shippableItems.length > 0) {
                        this.isShippable = true;
                    } else {
                        // checking if child items are shippable
                        let createPackageIndex: number = this.cart.cartItems.findIndex((item) => item.isPaCItem);
                        if (
                            createPackageIndex >= 0 &&
                            this.cart.cartItems[createPackageIndex].childItems &&
                            this.cart.cartItems[createPackageIndex].childItems.length > 0
                        ) {
                            shippableItems = this.cart.cartItems[createPackageIndex].childItems.filter((x) => x.isShippable);
                        }
                        this.isShippable = shippableItems.length > 0 ? true : false;
                    }
                }
                cartItems = this.convertCartItems(this.cart.cartItems);
                this.cartItemsSubject$.next(cartItems);

                if (this.cart.cartType === 'LR') {
                    this.orderModel.mainOrderTypeFk = 28;
                }
                // Set Totals from Cart Object on orderModel
                this.orderModel.discount = this.cart.discount;
                this.orderModel.formattedDiscount = this.cart.formattedDiscount;
                this.orderModel.subTotal = this.cart.subTotal;
                this.orderModel.formattedSubTotal = this.cart.formattedSubTotal;
                this.orderModel.total = this.cart.total;
                this.orderModel.formattedTotal = this.cart.formattedTotal;
                this.orderModelSubject$.next(this.orderModel);
            }
            // Filter out any SD items for this cart.
            if (this.cartType === 'R' && (this.mainType === 2 || this.mainType === 21)) {
                const smartDeliveryItems: ShoppingCartItem[] = this.cart.cartItems.filter((x) => x.setupForAs);
                this.smartDeliveryItemsSubject$.next(this.convertCartItems(smartDeliveryItems));
            }
        }
    }

    private loadSmartDeliveryInfo(model: SmartDeliveryModel): void {
        this.smartDeliveryModel = model;
        if (
            (this.mainType === 1 &&
                this.authService.isLoggedIn &&
                (this.authService.getStatus() === 'P' || (this.authService.getStatus() === 'A' && this.orderConfirmationPage))) ||
            ((this.mainType === 2 || this.mainType === 21) &&
                this.orderModel &&
                (this.orderModel.mainOrderTypeFk === 1 || this.orderModel.mainOrderTypeFk === 5))
        ) {
            let smartDeliveryItems: CartSummaryItem[] = [];
            if (model && model.smartDeliveryItems.length > 0) {
                model.smartDeliveryItems.forEach((item: SmartDeliveryItemModel) => {
                    const cartItem: CartSummaryItem = {
                        productPk: item.productPK,
                        name: item.productName,
                        quantity: item.quantity,
                        price: item.unitPrice,
                        imageUrl: item.imageUrl,
                        points: 0,
                        isShippable: true,
                        showEdit: false,
                        editUrl: '',
                        imageUrls: null,
                        childItems: null,
                        isPaCItem: false,
                        sku: '',
                    };
                    smartDeliveryItems.push(cartItem);
                });
                this.smartDeliveryItemsSubject$.next(smartDeliveryItems);
            }
        }
    }
}
