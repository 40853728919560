import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthenticationModule } from '../../authentication/src/authentication.module';
import { CommonModule } from '../../common/src/common.module';

import { SecurityGuard } from './security-guard.service';
import { MenuResolver } from './menu.resolver';
import { SecurityDirective } from './security.directive';
import { SecurityResolver } from './security.resolver';
import { SecurityService } from './security.service';
import { PolicyStateService } from './policy-state.service';
import { ReportsSecurityGuard } from './reports-security-guard.service';


@NgModule({
    imports: [
        RouterModule,
        AuthenticationModule,
        CommonModule
    ],
    exports: [
        SecurityDirective
    ],
    declarations: [
        SecurityDirective
    ]
})
export class SecurityModule {
    /**
     * This is required so the same provider is injected into the load on demand modules.
     *
     * More Details: http://angularfirst.com/the-ngmodule-forroot-convention/
     */
    public static forRoot(): ModuleWithProviders<SecurityModule> {
        return {
            ngModule: SecurityModule,
            providers: [
                ReportsSecurityGuard,
                SecurityGuard,
                MenuResolver,
                SecurityResolver,
                SecurityService,
                PolicyStateService
            ]
        };
    }
}
