import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { ReportDownloadComponent } from "./components/report-download.component";

@NgModule({
  imports: [
    FlexLayoutModule,
    MatButtonModule
  ],
  exports: [
    ReportDownloadComponent
  ],
  declarations: [
    ReportDownloadComponent
  ]
})
export class ReportsModule {

}
