import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationService, LocaleService, CmsSessionService } from '@jeunesse/angular';

import {
    ProductModel
} from '../../products/models/public-api';

@Injectable({
    providedIn: 'root'
})

export class ProductTranslationService {

    protected productTranslationSubject$: BehaviorSubject<ProductModel> = new BehaviorSubject<ProductModel>(new ProductModel(null));
    public getTranslationsObservable$: Observable<ProductModel> = this.productTranslationSubject$.asObservable();

    public translations: any = {
        qty: '',
        smartDeliveryWarn: '',
        loyaltyNotActive: '',
        loyaltyNotEnoughPoints: '',
        onlyOnePackage: '',
        copied: '',
        couldNotCopy: ''
    };

    constructor(
        protected readonly translationService: TranslationService,
        protected readonly localeService: LocaleService,
        protected readonly cmsSessionService: CmsSessionService
    ) {

        this.getTranslations();
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        // Load Translations
        this.translations = {
            qty: this.translationService.translate('shoppingCart', 'qty', 'Qty'),
            smartDeliveryWarn: this.translationService.translate('shop', 'smartDeliveryWarn', 'Could not add smart delivery item!'),
            loyaltyNotActive: this.translationService.translate('shop', 'loyalty-not-active', 'Sorry, your loyalty program is not active!'),
            loyaltyNotEnoughPoints: this.translationService.translate('shop', 'loyalty-not-enough-points', 'Sorry, you do not have enough points for this product!'),
            onlyOnePackage: this.translationService.translate('jfrontGlobal', 'onlyOnePackage', 'You can only add one package of the same type at a time!'),
            copied: this.translationService.translate('user-account', 'copied', 'Copied!'),
            couldNotCopy: this.translationService.translate('user-account', 'couldNotCopy', 'Could not copy link!')
        };

        this.productTranslationSubject$.next(this.translations);
    }
}
