import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { CmsModule, NotificationsModule } from '@jeunesse/angular/cms';
import { CommonModule as JCommonModule } from '@jeunesse/angular/common';
import { LoyaltyPointsInfoDialogComponent } from './catalog/loyalty-points-info-dialog/loyalty-points-info-dialog.component';
import { ProductsLibModule, EasyMatchService, MenuLibModule, LoaderModule } from '@jeunesse/cart-lib';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CreateAPackComponent } from './create-a-pack/create-a-pack.component';
import { ProductDescriptionComponent } from './product-description/product-description.component';
import { DistUpgradeModalComponent } from './catalog/dist-upgrade/dist-upgrade-modal.component';
import { ProductsRoutingModule } from './products-routing.module';
import { CategoryComponent } from './category/category.component';
import { CatalogComponent } from './catalog/catalog.compontent';
import { BrandComponent } from './brand/brand.component';

@NgModule({
    imports: [
        CommonModule,
        ProductsRoutingModule,
        ProductsLibModule,
        FormsModule,
        JCommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatInputModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatListModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatDividerModule,
        MatSidenavModule,
        MatExpansionModule,
        MatRadioModule,
        MatSnackBarModule,
        MatToolbarModule,
        MenuLibModule,
        MatTabsModule,
        CmsModule,
        MatPaginatorModule,
        NotificationsModule,
        LoaderModule
    ],
    exports: [],
    declarations: [
        CategoryComponent,
        CatalogComponent,
        BrandComponent,
        LoyaltyPointsInfoDialogComponent,
        ProductDetailComponent,
        CreateAPackComponent,
        ProductDescriptionComponent,
        DistUpgradeModalComponent
    ],
    providers: [EasyMatchService],
    entryComponents: [LoyaltyPointsInfoDialogComponent,
                        DistUpgradeModalComponent]
})
export class ProductsModule { }
