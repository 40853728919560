export const environment = {
	env : 'LOCAL' as const,
	appKey : 'FFDDAEEB-DCA3-4624-A451-5C7478A7A4DA',
	cartApi : 'https://cart-api.jeunesseglobalbuild.com/api',
	cmsApi : 'https://cms-api.jeunesseglobalbuild.com/api',
	profileApi : 'https://profile-api.jeunesseglobalbuild.com/api',
	securityApi : 'https://security-api.jeunesseglobalbuild.com/api',
	reportsApi : 'https://report-api.jeunesseglobalbuild.com/api',
	classicSiteUrl : 'https://joffice.jeunesseglobalbuild.com',
	jOffice : 'https://joffice2.jeunesseglobalbuild.com:4000',
	jFront : 'https://www.jeunesseglobalbuild.com',
	joinUrl : 'https://join.jeunesseglobalbuild.com',
	shop : 'https://shop.jeunesseglobalbuild.com:4001',
	easyMatchApi : 'https://automatchapi.erabeautyusa.com',
	cookieDomain : 'jeunesseglobalbuild.com',
	claim : 'JOFFICE' as const,
	siteName : 'CRM',
	crmUrl : '',
	shareUrl : '',
    events: '',
	crmApi : '',
	jFrontApi : '',
	eventApi : 'https://event-api.jeunesseglobalbuild.com/api',
	sharedApi : '',
	helpDeskApi : '',
	storageApi : '',
	liveChatApi : '',
	liveApi : '',
	client: 'T01'
}
