import { HttpOptions } from "@jeunesse/angular";

export interface ApplicationNotificationQuery extends HttpOptions {
    countryCode: string;
    culture: string;
    application: ApplicationType;
}

export enum ApplicationType {
    JOffice2 = "JOffice2",
    Shop = "Shop",
    Events = "Events",
    Admin2 = "Admin2",
    Signup = "Signup"
}
