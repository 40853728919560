
export class EventMemberModel {
    public siteURL?: string;
    public id?: string;
    public eventAgendaID?: number[];
    public eventID?: number;
    public firstName?: string;
    public fistName2?: string;
    public fistName3?: string;
    public lastName?: string;
    public lastName2?: string;
    public lastName3?: string;
    public prefixId?: string;
    public transponderNeeds?: boolean;
    public email?: string;
    public job?: string;
    public addressLn1?: string;
    public addressLn2?: string;
    public city?: string;
    public state?: string;
    public country?: string;
    public zipCode?: number;
    public cellPhone?: number;
    public hopePhone?: number;
    public fax?: number;
    public isDistributor?: boolean;
    public company?: string;
    public dob?: Date;
    public flight?: string;
    public airline?: string;
    public arrivalTime?: string;
    public departureTime?: string;
    public gender?: string;
    public meal?: string;
    public occupation?: string;
    public passportED?: Date;
    public rank?: string;
    public rubyUpline?: string;
    public shareRoomWith?: string;
    public specialNeeds?: string;
    public tShirtSize?: string;
    public translationNeeded?: boolean;
    public travelVisa?: boolean;
    public isGuest?: boolean;
    public isHost?: number;
    public mainFK?: number;
    public allowCommunication?: boolean;
}
