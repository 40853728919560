import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsSessionService, LocaleService, TranslationService } from '@jeunesse/angular';
import { SeoService, WebStorageService } from '@jeunesse/angular';
import { Observable, Subscription, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { MenuItem } from '../../models/menu-item.model';
import { MenuOverlayRef } from '../../overlays/menu/menu-overlay';
import { MenuOverlayService } from '../../overlays/menu/menu-overlay.service';
import { MenuStateService } from '../../services/menu-state.service';
import { MenuService } from '../../services/menu.service';

@Component({
    selector: 'jn-backoffice-menu',
    templateUrl: './backoffice-menu.component.html',
    styleUrls: ['./backoffice-menu.component.scss']
})

export class BackofficeMenuComponent implements OnInit {

    @ViewChild('menuOverlayOrigin0') public menuOrigin0!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin1') public menuOrigin1!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin2') public menuOrigin2!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin3') public menuOrigin3!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin4') public menuOrigin4!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin5') public menuOrigin5!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin6') public menuOrigin6!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin7') public menuOrigin7!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin8') public menuOrigin8!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin9') public menuOrigin9!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin10') public menuOrigin10!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin11') public menuOrigin11!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin12') public menuOrigin12!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin13') public menuOrigin13!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin14') public menuOrigin14!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin15') public menuOrigin15!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin16') public menuOrigin16!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin17') public menuOrigin17!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin18') public menuOrigin18!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin19') public menuOrigin19!: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin20') public menuOrigin20!: CdkOverlayOrigin;

    public selectedMenuNode: MenuItem | null;
    public menuItems$: Observable<MenuItem[]>;
    public menuItems: MenuItem[];
    public menuOverlay: MenuOverlayRef;
    public menuTimer: any;
    public translations: any = {};
    public showBonusCreditsGoingToExpire: boolean = false;
    private culture: string;

    constructor(
        public readonly menuService: MenuService,
        private readonly menuStateService: MenuStateService,
        private readonly route: ActivatedRoute,
        private readonly menuOverlayService: MenuOverlayService,
        private readonly seoService: SeoService,
        private readonly router: Router,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly webStorage: WebStorageService,
        private readonly localeService: LocaleService) {
    }

    public ngOnInit(): void {
        // Here we just subscribe to the state object that way any changes are pushed via the behaviour subject.
        // debugger;
        this.getMenu();

        this.menuStateService.getShowBonusCreditsExpirationWarn().subscribe((result) => {
            this.showBonusCreditsGoingToExpire = result;
        });
    }
    /**
     * Called when the menu button entered by mouse.
     */
    public onMenuMouseEnter(menu: MenuItem, index: number): Subscription {
        // Add a small delay so overlay doesn't get in the way for moving across link.
        return timer(180).subscribe(_ => {
            this.openMenuOverlay(menu, index);
            try {
                this.sendGAInfo('main menu', 'select menu', this.router.url + ' - ' + menu.title);
            }
            catch (ex) {

            }
        });
    }
    /**
     * Called when the mouse leaves then menu button.
     */
    public onMenuMouseLeave(event: MouseEvent, timer: Subscription): void {
        if (timer) {
            timer.unsubscribe();
        }
        if (this.shouldCloseMenuOverlay(event)) {
            this.closeMenuOverlay();
        }
    }
    /**
     * Called when the mouse leaves menu button.
     */
    public onClick(menu: MenuItem, index: number, event): void {
        // Add a small delay so overlay doesn't get in the way for moving across link.
        try {
            this.menuTimer.unsubscribe();
        }
        catch (e) {}

        event.stopPropagation();
        this.menuTimer = timer(90).subscribe(_ => {
            this.openMenuOverlay(menu, index);

            try {
                this.sendGAInfo('main menu', 'select menu', this.router.url + ' - ' + menu.title);
            }
            catch (ex) {

            }
        });
    }
    /**
     * Opens the menu overlay for displaying a list of brands.
     * @param menu The menu to display.
     */
    public openMenuOverlay(menu: MenuItem, index: number): void {
        if (this.menuOverlay) {
            // Do not reopen overlay if the current overlay is open.
            if (this.menuOverlay.componentInstance.data === menu) return;

            // Close current overlay if opened.
            this.menuOverlay.close();
        }
        // let origin: any = document.getElementById('menu' + menu.id.toString());
        // console.log(this.menuOrigin);
        // console.log(origin);

        // let menuContainer1: any = document.getElementById('menuContainer1');
        // console.log('menuContainer1');
        // console.log(origin);

        const overlay: MenuOverlayRef = this.menuOverlayService.open({
            hasBackdrop: false,
            data: menu,
            flexibleConnectedTo: {
                origin: this.getOrigin(index), // this.menuOrigin,
                positions: [{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }]
            }
        });

        // const appOverlayRef: MenuOverlayRef = this.menuOverlayService.open(this.menuOrigin);

        this.menuOverlay = overlay;

        // Make sure we clear current selected overlay on close.
        const close: Subscription = overlay.beforeClose().subscribe(() => {
            if (this.menuOverlay === overlay) {
                this.menuOverlay = null;
            }
            close.unsubscribe();
        });
    }

    public convertLinkToRoute(url: string): string {
        return this.menuService.convertLinkToRoute(url);
    }

    public toRoute(url: string): string {
        return url;
    }

    public toExternalUrl(url: string): string {
        return this.menuService.toExternalUrl(url);
    }

    public isExternal(link: string): boolean {
        return this.menuService.isExternal(link);
    }
    public sendGAInfo(menu: string, action: string, label?: string): void {
        if (label === undefined) {
            label = this.router.url;
        }
        this.seoService.trackClick(label, action, menu);
    }
    public selectNode(node: MenuItem): void {

        this.selectedMenuNode = node;

        console.group('Selected Tree Node');
        console.log('Label:', node.title);
        console.log('Children:', node.subItems.length);
        console.groupEnd();

    }
    public hasItems(menuItem: MenuItem): boolean {
        try {
            if (menuItem.subItems) {
                if (menuItem.subItems.length > 0) {
                    return true;
                }
            }
        }
        catch (e) {
        }
        return false;
    }

    public closeMenuOverlay(): void {
        if (this.menuOverlay && !this.cmsSessionService.isTranslator()) {
            timer(180).subscribe(() => {
                this.menuOverlay && this.menuOverlay.close();
            });
        }
    }

    /**
     * Listen on click events on a document level.
     * There is a problem with chrome not updating the render on
     * document level clicks, so we use body instead.
     * @param event The event for the mouse press.
     */
    @HostListener('body:click', ['$event'])
    public handleKeydown(event: MouseEvent): void {
        this.closeMenuOverlay();
    }

    private getOrigin(index): CdkOverlayOrigin {
        return eval('this.menuOrigin' + index);
    }

    private hasHttp(url: string): boolean {
        if (url.indexOf('http:') > -1 || url.indexOf('https:') > -1 || url.indexOf('http/') > -1 || url.indexOf('https/') > -1) {
            return true;
        }
        return false;
    }

    private getMenu(): void {
        this.menuItems$ = this.menuService.getBackofficeMenu();
        this.menuItems$.subscribe(menus => {
            if (menus.length > 0) {
                this.menuItems = menus;
                this.translateMenu();
            }
        });
    }
    private translateMenu(): void {
        let cultureName: string = this.getSetKey() || this.localeService.getLocaleCookie();
        this.localeService.isCultureSupported(cultureName, this.localeService.getCountryCode()).subscribe((culture) => {
            this.culture = culture;
            this.translationService.setTranslationCulture(culture);
            this.menuItems.forEach(menuItem => {
                let translated: string = this.translationService.translate('menu-library', menuItem.translationKey, menuItem.title);
                if (translated) {
                    menuItem.title = translated;
                }
            });

            this.translations.bonusCreditsExpirationMessage = this.translationService.translate('joffice-notification-global', 'bonus-credits-expiration-message', 'Use your expiring Bonus Credits now!');
        });
    }

    private getSetKey(): string {
        let culture: string = this.webStorage.getCookie('SetCulture').replace(/"/g, '');
        if (culture) {
            this.webStorage.setCookie('Culture', JSON.stringify(culture));
        }
        return culture;
    }

    private shouldCloseMenuOverlay(event: MouseEvent): boolean {
        if (event && event.relatedTarget) {
            const relatedTargetClassList = Array.from((event.relatedTarget as Element).classList);
            const keepShowing = relatedTargetClassList.indexOf('jn-menu-overlay') > -1 || relatedTargetClassList.indexOf('menu-overlay') > -1;

            return !keepShowing;
        }

        return false;
    }

}
