import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MenuStateService, RepSiteInfo } from "@jeunesse/cart-lib";
import { MainCartService, ShoppingCart } from "@jeunesse/slide-cart";
import { Subscription } from "rxjs";

@Component({
  selector: 'jn-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  public itemCount: number = 0;
  @Input() public repSiteInfo: RepSiteInfo;
  @Input() public locale: string;
  private subs = new Subscription();
  constructor(
    private readonly menuStateService: MenuStateService,
    private readonly mainCartService: MainCartService) {
    }

  ngOnInit(): void {
    this.subs.add(this.mainCartService.getMainCart$().subscribe((cart: ShoppingCart) => {
      if (cart && cart.cartItems) {
        this.itemCount = cart.cartItems.length;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggleCart(): void {
    this.menuStateService.toggleCartOpenState();
  }
}
