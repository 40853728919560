import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LoggerInterface } from '../../common/src/models/logger.interface';
import { LoggerService } from '../../common/src/logger.service';
import { UrlHelperService } from '../../common/src/url-helper.service';
import { WebStorageService } from '../../common/src/web-storage.service';
import { IAppSettings } from '../../common/src/models/app-settings.interface';
import { UrlOptions } from '../../common/src/models/url-options.interface';
import { AuthHttpService } from './auth-http.service';
import { TokenModel, LogoutRedirectEnum, LoginModel, CheckAttemptsModel } from './models/public-api';
import { Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginRedirectComponent } from './components/loginRedirect.component';

/**
 * @ignore
 */
declare const AppSettings: IAppSettings;

/**
 * Handles login, logout and token functions. Like accessing the data from the token.
 */
@Injectable()
export class AuthService {
    /**
     * Emits when the token had be been changed. Returns if the user is currently logged in.
     * This will emit the current value when first subscribed. Will not fire if the token cookie
     * was modified outside of Angular.
     */
    public readonly tokenChanged: BehaviorSubject<boolean>;
    public readonly reportTokenChanged: BehaviorSubject<boolean>;
    // Create an event so authService can communicate with loginOverlayRef
    public readonly loginOverLayAfterClose = new BehaviorSubject<string>('');

    private readonly authHttp: AuthHttpService;

    private loggedIn: boolean = false;
    private _isSecureCheckoutSkipped: boolean = false;
    private token: TokenModel;
    private reportLoggedIn: boolean = false;
    private reportToken: any;
    private refreshingToken: boolean = false;
    private lastTokenRefresh: Date = new Date();

    constructor(
        private readonly http: HttpClient,
        private readonly urlHelper: UrlHelperService,
        private readonly webStorage: WebStorageService,
        private readonly loggerService: LoggerService,
        protected readonly dialog: MatDialog) {

        // There is a cyclic dependency between AuthService and AuthHttpService.
        // To get around this we give AuthService it's own instance of AuthHttpService.
        this.authHttp = new AuthHttpService(this, this.http, this.webStorage, this.loggerService);

        const reg: RegExp = /[?&]token=([A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*)/i;
        const tokenString: RegExpExecArray = reg.exec(window.location.search);

        // Get the Token when the site is loaded.
        // We only try to get from the URL on first load so using "token" query param in "routerLink" will not work.
        const jwtToken: string = (tokenString && tokenString[1]) || this.webStorage.getCookie('token');

        if (jwtToken) {
            this.token = this.readToken(jwtToken);
            this.lastTokenRefresh = this.token.createdDate;
            // Just in case some other site doesn't set the expire correctly.
            this.webStorage.setCookie('token', jwtToken, this.token.expireDate);
            this.loggedIn = true;
        }

        // Report Login Token
        const reg2: RegExp = /[?&]reportToken=([A-Za-z0-9-_=]*)/i;
        const reportTokenString: RegExpExecArray = reg2.exec(window.location.search);
        const reportToken: string = (reportTokenString && reportTokenString[1]) || this.webStorage.getCookie('reportToken');
        if (reportToken) {
            this.reportToken = this.readReportToken(reportToken);
            this.webStorage.setCookie('reportToken', reportToken, new Date(Date.now() + 60 * 60 * 1000));
            this.reportLoggedIn = true;
        }
        this.reportTokenChanged = new BehaviorSubject<boolean>(this.reportLoggedIn);
        this.tokenChanged = new BehaviorSubject<boolean>(this.loggedIn);
    }

    public get isSecureCheckoutSkipped(): boolean {
        return this._isSecureCheckoutSkipped;
    }



    /**
     * Returns `boolean` if the current user is logged in.
     */
    public get isLoggedIn(): boolean {
        if (this.loggedIn && this.isTokenExpired()) {
            this.webStorage.removeCookie('token');
            this.loggedIn = false;
            this.logout(LogoutRedirectEnum.LOGIN);
        }
        if (this.loggedIn) {
            this.checkForToken();
        }

        return this.loggedIn;
    }

    public checkForToken(): void {
        // make sure there is still a token for the user
        let token = this.webStorage.getCookie('token');
        if (!token && this.loggedIn) {
            this.loggedIn = false;
            this.tokenChanged.next(false);
        }
        if (token && !this.loggedIn) {
            this.loggedIn = true;
            this.token = this.readToken(token);
            this.tokenChanged.next(true);
        }
    }

    public get isReportsLoggedIn(): boolean {
        if (this.reportLoggedIn && !this.isReportTokenValid()) {
            this.webStorage.removeCookie('reportToken');
            this.reportLoggedIn = false;
        }
        if (this.reportLoggedIn) {
            this.checkForReportsToken();
        }
        return this.reportLoggedIn;
    }

    public checkForReportsToken(): void {
        let token = this.webStorage.getCookie('reportToken');
        if (!token && this.reportLoggedIn) {
            this.reportLoggedIn = false;
            this.reportTokenChanged.next(false);
        }
        if (token && !this.reportLoggedIn) {
            this.reportLoggedIn = true;
            this.reportToken = this.readReportToken(token);
            this.reportTokenChanged.next(true);
        }
    }

    /**
     * Returns the current {@link TokenModel}.
     */
    public getAuthToken(): TokenModel {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token;
    }

    /**
     * Returns the Country from the current {@link TokenModel}.
     */
    public getCountry(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.country;
    }

    /**
     * Returns the Main Id from the current {@link TokenModel}.
     */
    public getMainId(): number {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.mainId;
    }

    /**
     * Returns the Main Type Id from the current {@link TokenModel}.
     */
    public getMainTypeId(): number {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.mainTypeId;
    }

    /**
     * Returns the Culture from the current {@link TokenModel}.
     */
    public getCulture(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.culture;
    }

    /**
     * Returns the Language Id from the current {@link TokenModel}.
     */
    public getLangId(): number {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.langId;
    }

    /**
     * Returns the SiteUrl from the current {@link TokenModel}.
     */
    public getSiteUrl(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.siteUrl;
    }

    /**
     * Returns the FirstName from the current {@link TokenModel}.
     */
    public getFirstName(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.firstName;
    }

    /**
     * Returns the LastName from the current {@link TokenModel}.
     */
    public getLastName(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.lastName;
    }

    /**
     * Returns the Email from the current {@link TokenModel}.
     */
    public getEmail(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.email;
    }

    /**
     * Returns the Phone from the current {@link TokenModel}.
     */
    public getPhone(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.phone;
    }

    /**
     * Returns the Status of the current {@link TokenModel}.
     */
    public getStatus(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.status;
    }

    /**
     * Returns the Old JOffice Token from the current {@link TokenModel}.
     */
    public getOldJOfficeToken(): string {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.oldToken;
    }

    /**
     * Returns the Session Id from the current {@link TokenModel}.
     */
    public getSessionId(): number {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.sessionId;
    }

    public getShowWelcome(): boolean {
        if (!this.isLoggedIn) {
            throw new Error('NotLogged in!');
        }

        return this.token.showWelcome;
    }

    public getWalletUserName(): string {
        if (!this.isLoggedIn) {
            throw new Error('NotLogged in!');
        }

        return this.token.walletUserName;
    }

    public isAliasLogin(): boolean {
        if (!this.isLoggedIn) {
            throw new Error('NotLogged in!');
        }

        return this.token.aliasLogin;
    }

    /**
     * Checks if the current {@link TokenModel} has the claim.
     * @param claim The claim to check against.
     * @returns A `boolean` value if the claim exists.
     */
    public hasClaim(claim: string): boolean {
        if (!this.isLoggedIn) {
            throw new Error('Not Logged in!');
        }

        return this.token.claims.indexOf(claim) >= 0;
    }

    /**
     * Log in using a login model.
     * @param loginModel LoginModel interface.
     * @returns Returns `true` on successful login. Will throw error on failed login.
     */
    public login(loginModel: LoginModel): Observable<boolean>;

    /**
     * Log in using a username and password.
     * @param userName The username of the user.
     * @param password The password of the user.
     * @returns Returns `true` on successful login. Will throw error on failed login.
     */
    public login(userName: string, password: string): Observable<boolean>;

    /**
    * Log in using a username and password.
    * @param userName The username of the user.
    * @param password The password of the user.
    * @param signupKey User Signup key (SessionId).
    * @returns Returns `true` on successful login. Will throw error on failed login.
    */
    public login(userName: string, password: string, signupKey: string): Observable<boolean>;

    /**
     * @ignore
     */
    public login(userName: any, password?: string, signupKey?: string): Observable<boolean> {
        const model: LoginModel =
            (typeof userName === 'string') ? { userName: userName, password: password, signupKey: signupKey } : userName;
        return this.authHttp.post(this.urlHelper.toSecurityApi('/v2/CheckAttemptsAndLogin'), model).pipe(
            map((res: CheckAttemptsModel) => {
                if (res.jwt !== null) {
                    this.token = this.readToken(res.jwt);
                    this.webStorage.setCookie('token', res.jwt, this.token.expireDate);
                    this.loggedIn = true;
                    this.tokenChanged.next(true);
                    return true;
                } else {
                    let expDate: Date = new Date(Date.now() + 60 * 60 * 1000);
                    const data: any = {
                        liUser: res.liUser,
                        liToken: res.liToken,
                        expDate,
                        url: this.urlHelper.buildUrl(AppSettings.classicSiteUrl, '/v2/NewLogin.aspx')
                    };
                    const dialogRef: MatDialogRef<LoginRedirectComponent> = this.dialog.open(LoginRedirectComponent, { data });
                    dialogRef.afterClosed().subscribe((res) => {
                        this.loginOverLayAfterClose.next('closed');
                    });
                }
            })
        );
    }

    /**
     * @ignore
     */
    public basicLogin(userName: any, password?: string, signupKey?: string): Observable<boolean> {
        const model: LoginModel =
            (typeof userName === 'string') ? { userName: userName, password: password, signupKey: signupKey } : userName;
        return this.authHttp.post(this.urlHelper.toSecurityApi('/v2/token'), model, { responseType: 'text' }).pipe(
            map((jwtToken: string) => {
                this.token = this.readToken(jwtToken);

                this.webStorage.setCookie('token', jwtToken, this.token.expireDate);

                this.loggedIn = true;

                this.tokenChanged.next(true);

                return true;
            }));
    }

    /**
     * Used to authenticate a user for reports access
     * @param userName Username to check
     * @param password Password to check
     * @returns boolean
     */
    public reportsLogin(userName: any, password?: string): Observable<boolean> {
        const model: LoginModel = (typeof userName === 'string') ? { userName: userName, password: password } : userName;
        return this.authHttp.post(this.urlHelper.toSecurityApi('/v2/reports/login'), model).pipe(
            map((res: CheckAttemptsModel) => {
                if (res.jwt) {
                    this.reportToken = this.readReportToken(res.jwt);
                    this.reportLoggedIn = true;
                    this.reportTokenChanged.next(true);
                    console.log('Report Token:', this.readReportToken(res.jwt));
                    this.webStorage.setCookie('reportToken', res.jwt, new Date(Date.now() + 60 * 60 * 1000));
                    return true;
                } else {
                    return false;
                }
            }));
    }

    /**
     * Used to create an account for reports access
     * @param userName Username to use
     * @param password Password to use
     * @returns boolean
     */
    public createReportsLogin(userName: any, password?: string): Observable<boolean> {
        const model: LoginModel = (typeof userName === 'string') ? { userName: userName, password: password } : userName;
        return this.authHttp.post(this.urlHelper.toSecurityApi('/v2/reports/login/create'), model).pipe(
            map((res: CheckAttemptsModel) => {
                if (res.jwt) {
                    this.reportToken = this.readReportToken(res.jwt);
                    this.reportLoggedIn = true;
                    this.reportTokenChanged.next(true);
                    console.log('Report Token:', this.readReportToken(res.jwt));
                    this.webStorage.setCookie('reportToken', res.jwt, new Date(Date.now() + 60 * 60 * 1000));
                    return true;
                } else {
                    return false;
                }
            }));
    }

    /**
     * Log in using a tracking id.
     * @param trackingId The tracking id.
     * @returns Returns `true` on successful login. Will throw error on failed login.
     */
    public loginByTrackingId(trackingId: string): Observable<boolean> {
        return this.authHttp.post(this.urlHelper.toSecurityApi(`/v2/token/tracking/${trackingId}`),
            null, { responseType: 'text' }).pipe(
                map((jwtToken: string) => {
                    this.token = this.readToken(jwtToken);

                    this.webStorage.setCookie('token', jwtToken, this.token.expireDate);

                    this.loggedIn = true;

                    this.tokenChanged.next(true);

                    return true;
                }));
    }

    public updateTokenAfterWalletLoginLogout(jwt: string): void {
        this.token = this.readToken(jwt);
        this.webStorage.setCookie('token', jwt, this.token.expireDate);
        this.loggedIn = true;
        this.tokenChanged.next(true);
    }

    /**
     * Logout the current user.
     * @param redirect Where to redirect the user after log out.
     */
    public logout(redirect: LogoutRedirectEnum = LogoutRedirectEnum.NONE, userIsLoggedOut?: boolean): Observable<void> {
        const subject: Subject<void> = new Subject<void>();
        const logoutFun: () => void = () => {
            this.loggedIn = false;
            this.reportLoggedIn = false;

            this.webStorage.removeCookie('token');
            this.webStorage.removeCookie('reportToken');
            this.webStorage.removeCookie('NOTIFICATIONS');
            this.webStorage.removeCookie('WARNINGS');
            this.webStorage.removeSession(AppSettings.claim);
            this.reportTokenChanged.next(false);

            this.tokenChanged.next(false);
            /**
             * WHEN USER PUSH SIGHT OUT BUTTON - WE STORE THIS INFORMATION IN COOKIE
             */
            if (userIsLoggedOut) {
                this.webStorage.setCookie('userIsLoggedOut', 'true');
            }

            switch (redirect) {
                case LogoutRedirectEnum.FRONTSITE:
                    location.href = this.urlHelper.buildUrl(AppSettings.classicSiteUrl, '/v2/Logout.aspx');
                    break;
                case LogoutRedirectEnum.LOGIN:
                    location.href = this.urlHelper.buildUrl(AppSettings.classicSiteUrl, '/login.asp?l=1');
                    break;
                default:
                    break;
            }
        };

        subject.subscribe(logoutFun, logoutFun);

        this.authHttp.post<void>(this.urlHelper.toSecurityApi('/v2/token/logout')).subscribe(subject);

        return subject;
    }

    /**
     * Redirects the user to the login page. Once the user is finished logging in they should be redirected back to the Url.
     * @param redirectUrl The Url to redirect the user back to after login.
     */
    public requiredLogin(redirectUrl?: string, params?: Params): void {
        const redirectFun: () => void = () => {
            // Check if we come from myEvents app
            if ( redirectUrl && redirectUrl.includes('events.jeunesse')) {
                // let re: RegExp = new RegExp(/([^\/]+$)/g);
                const route: UrlOptions = {
                    path: '/register/login',
                    queryParams: {
                        'eventUrl': params['eventUrl']
                    }
                };
                location.href = this.urlHelper.buildUrl(AppSettings.events, route);
            } else {
                const params: UrlOptions = {
                    path: '/login.asp',
                    queryParams: {
                        'l': '1',
                        'continue': redirectUrl && encodeURIComponent(redirectUrl)
                    }
                };
                /**
                 * CHECKED DO WE HAVE INFORMATION IN COOKIE ABOUT "USER SIGN OUT"
                 */
                if (this.webStorage.getCookie('userIsLoggedOut') !== '') {
                    params.queryParams = { l: '1' };
                    this.webStorage.removeCookie('userIsLoggedOut');
                }
                location.href = this.urlHelper.buildUrl(AppSettings.classicSiteUrl, params);
            }
        };

        if (this.isLoggedIn) {
            this.logout(LogoutRedirectEnum.NONE).subscribe(redirectFun, redirectFun);
        }
        else {
            redirectFun();
        }
    }

    /**
     * Check if time has elapsed from first load or last token refresh then it calls the reissue API and the token is updated.
     * @param refreshInMinutes How many minutes should have elapsed.
     * @returns Will return an observable with a `boolean` if the token was successfully refreshed.
     */
    public refreshTokenIfNeeded(refreshInMinutes: number = 20): Observable<boolean> {
        const now: Date = new Date();
        const elapsedMinutes: number = ((now.valueOf() - this.lastTokenRefresh.valueOf()) / 1000) / 60;

        // Skip API if can.
        if (elapsedMinutes < refreshInMinutes || !this.isLoggedIn || this.refreshingToken) {
            return of(false);
        }

        this.refreshingToken = true;

        const subject: Subject<boolean> = new Subject<boolean>();

        this.authHttp.post(this.urlHelper.toSecurityApi('/v2/token/reissue'), null, { responseType: 'text' }).pipe(
            map((jwt: string) => {
                this.token = this.readToken(jwt);

                this.webStorage.setCookie('token', jwt, this.token.expireDate);

                this.lastTokenRefresh = now;
                this.refreshingToken = false;

                this.tokenChanged.next(true);

                return true;
            }),
            catchError(() => {
                this.refreshingToken = false;
                return of(false);
            }))
            .subscribe(subject);

        subject.subscribe();

        return subject;
    }

    public skipSecureCheckout(): void {
        this._isSecureCheckoutSkipped = true;
    }

    private readToken(jwt: string): TokenModel {
        const base64Url = jwt.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g,'/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return new TokenModel(JSON.parse(jsonPayload));
    }

    private readReportToken(jwt: string): any {
        //const base64Url = jwt.split('.')[1];
        const base64 = jwt.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    private isTokenExpired(): boolean {
        if (this.token!.expireDate <= new Date()) {
            return true;
        }

        return false;
    }

    private isReportTokenValid(): boolean {
        if (!this.reportToken && !this.token) {
            return false;
        }
        if (this.reportToken.customerId === this.token.mainId) {
            return true;
        }

        return false;
    }
}
