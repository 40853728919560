import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SubSink } from 'subsink';
import { PaymentStateService } from '../../services/public-api'
import { OrderService } from '../../../checkout/services/order.service';
import { TranslationService, CmsSessionService, LocaleService } from '@jeunesse/angular';
import { OrderModel, WalletOrderLimit } from '../../../checkout/public-api';

@Component({
    selector: 'app-signup-token',
    templateUrl: './signup-token.component.html',
    styleUrls: ['./signup-token.component.scss']
})
export class SignupTokenComponent implements OnInit {

    public signUpForm: FormGroup;
    public orderModel: any;
    public limitModel: WalletOrderLimit;
    public showNote: boolean = false;
    public limitReached: boolean = false;
    private subs = new SubSink();

    @Output() siteUrl: EventEmitter<string> = new EventEmitter<string>();
    @Output() signUpToken: EventEmitter<string> = new EventEmitter<string>();

    public translations: any = {
        siteUrl: '',
        signupToken: '',
        limitPrompt: '',
        limitReached: ''
    };
    constructor(
        private paymentStateService: PaymentStateService,
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService
    ) { }

    ngOnInit() {
        this.subs.sink = this.orderService.getOrderModel().subscribe((_orderModel: OrderModel) => {
            this.orderModel = _orderModel;
            this.subs.sink = this.orderService.getWalletOrderLimit(this.orderModel.mainOrdersFk).subscribe((limitModel: WalletOrderLimit) => {
                this.limitModel = limitModel;
                if (this.limitModel.allowedPercent > 0)
                    this.showNote = true;
                this.limitReached = this.limitModel.hasLimitReached;
                this.getTranslations();                
            });
        });

        this.signUpForm = this.formBuilder.group({
            siteUrl: [''],
            signUpToken: ['']
        });

        this.signUpForm.get('siteUrl').valueChanges.subscribe((res) => {
            this.siteUrl.emit(res);
        });
        this.signUpForm.get('signUpToken').valueChanges.subscribe((res) => {
            this.signUpToken.emit(res);
        });
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.siteUrl = this.translationService.translate('bonusCredit', 'siteUrl', 'SiteUrl*');
        this.translations.signUpToken = this.translationService.translate('checkout', 'signUpToken', 'Sign Up Token*');
        let limitReachedFormat: string = this.translationService.translate('payment', 'WalletLimitReached',
            'A limit of {0}% of payments in Wallet has been reached.')
        this.translations.limitReached = this.translationService.stringFormat(limitReachedFormat, this.limitModel.allowedPercent);
        let limitPromptFormat: string = this.translationService.translate('checkout', 'WalletLimitTotal',
            'Attention! For Bonus Credit, Wallet payment, and Signup Token payments there is a {0}% limit of the order total.')
        this.translations.limitPrompt = this.translationService.stringFormat(limitPromptFormat, this.limitModel.allowedPercent);
    }
}
