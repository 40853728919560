import { Injectable } from '@angular/core';
import { AuthHttpService, AuthService, TranslationService, UrlHelperService } from '@jeunesse/angular';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { BrandModel, CategoryModel } from '../models/public-api';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for interacting with Fontana category api calls.
 */
export class CategoryService {
    private categoryCache: CategoryModel[] = [];
    private subs = new SubSink();

    private _categoryQuery: { countryCode: string; culture: string; cartType: string };

    constructor(
        private readonly urlHelper: UrlHelperService,
        private readonly authHttp: AuthHttpService,
        private readonly translationService: TranslationService,
        private readonly authService: AuthService
    ) {}

    public ngOnInit(): void {}

    /**
     * Queries for a list of active categories.
     * @param countryCode Country to filter by
     * @param culture Culture to filter by
     */
    public getCategories(
        countryCode: string,
        culture: string,
        cartType: string = 'R',
        hardReload: boolean = false
    ): Observable<CategoryModel[]> {
        if (cartType === 'R' && this.authService.isLoggedIn && this.authService.getMainTypeId() === 1) {
            cartType = 'W';
        }
        const query: { cartType: string; countryCode: string; culture: string } = {
            cartType: cartType,
            countryCode: countryCode,
            culture: culture,
        };

        if (JSON.stringify(this._categoryQuery) === JSON.stringify(query) && !hardReload) {
            return of(null);
        } else {
            this._categoryQuery = query;
        }

        let categories: CategoryModel[];
        this.categoryCache = [];
        return this.authHttp.get<any>(this.urlHelper.toCartApi('/v2/products/categories'), { params: query }).pipe(
            map((res: any) => {
                categories = (res || []).map((value: any) => new CategoryModel(value, this.translationService));
                categories.forEach((category) => {
                    category.brands.sort((a, b) => {
                        if (a.name && b.name) {
                            const nameA: string = a.name.toLowerCase(),
                                nameB: string = b.name.toLowerCase();
                            if (nameA < nameB) return -1;
                            if (nameA > nameB) return 1;
                        }

                        return 0;
                    });
                    category.name = this.translationService.translate('categories', category.cmsName, category.name);
                    category.menus.forEach((element) => {
                        element.description = this.translationService.translate('categories', element.productMenu, element.productMenu);
                    });
                    this.categoryCache.push(category);
                });
                return categories;
            })
        );
    }

    /**
     * Returns a specific Category by ID.
     * @param categoryId Category ID to filter on
     */
    public getCategory(categoryId: number): CategoryModel {
        return this.categoryCache.find((category: CategoryModel) => category.id === categoryId);
    }

    /**
     * Returns a specific Brand by ID.
     * @param brandId Brand ID to filter on
     */
    public getCategoryByBrand(brandId: number): CategoryModel {
        return this.categoryCache.find(
            (category: CategoryModel) => category.brands.filter((brand: BrandModel) => brand.id === brandId).length > 0
        )[0];
    }

    /**
     * Used to get a specific brand from the category list.
     * @param brandId Brand PK to filter by
     * @param categoryId Category PK to filter by
     */
    public getBrand(brandId: number, categoryId: number): BrandModel {
        if (categoryId < 1) {
            return null;
        }
        const category: CategoryModel = this.getCategory(categoryId);
        return category.brands.find((brand: BrandModel) => brand.id === brandId);
    }
}
