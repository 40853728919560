import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ESCAPE } from '@angular/cdk/keycodes';

import { CategoryOverlayComponent } from './category-overlay';

/**
 * The reference to the category overlay when opened.
 */
export class CategoryOverlayRef {

    private beforeClosedSubject: Subject<void> = new Subject<void>();
    private afterClosedSubject: Subject<void> = new Subject<void>();
    private afterOpenedSubject: Subject<void> = new Subject<void>();

    constructor(private readonly overlayRef: OverlayRef,
        public componentInstance: CategoryOverlayComponent) {
        if (this.componentInstance) {
            // Emit when opening animation completes
            this.componentInstance.animationStateChanged.pipe(
                    filter(event => event.phaseName === 'done' && event.toState === 'enter'),
                    take(1)
                )
                .subscribe(() => {
                    this.afterOpenedSubject.next();
                    this.afterOpenedSubject.complete();
                });

            // Dispose overlay when closing animation is complete
            this.componentInstance.animationStateChanged.pipe(
                filter(event => event.phaseName === 'done' && event.toState === 'leave'),
                take(1)
            ).subscribe(() => this.overlayRef.dispose());

            overlayRef.detachments().subscribe(() => {
                this.beforeClosedSubject.next();
                this.beforeClosedSubject.complete();
                this.afterClosedSubject.next();
                this.afterClosedSubject.complete();
                this.componentInstance = null!;
                this.overlayRef.dispose();
            });

            overlayRef.keydownEvents()
                .pipe(filter(event => event.keyCode === ESCAPE))
                .subscribe(() => this.close());
        }
    }

    /**
     * Close the overlay.
     */
    public close(): void {
        if (this.componentInstance) {
            this.componentInstance.animationStateChanged.pipe(
                    filter(event => event.phaseName === 'start'),
                    take(1)
                )
                .subscribe(() => {
                    this.beforeClosedSubject.next();
                    this.beforeClosedSubject.complete();
                    this.overlayRef.detachBackdrop();
                });

            // Start exit animation
            this.componentInstance.startExitAnimation();
        }
    }

    /**
     * Gets an observable that is notified when the overlay is finished closing.
     */
    public afterClosed(): Observable<void> {
        return this.afterClosedSubject.asObservable();
    }

    /**
     * Gets an observable that is notified when the overlay has started closing.
     */
    public beforeClose(): Observable<void> {
        return this.beforeClosedSubject.asObservable();
    }

    /**
     * Gets an observable that is notified when the overlay is finished opening.
     */
    public afterOpened(): Observable<void> {
        return this.afterOpenedSubject.asObservable();
    }
}
