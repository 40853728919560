import { Injectable } from '@angular/core';
import { AuthHttpService, UrlHelperService } from '@jeunesse/angular';
import { Observable } from 'rxjs';
import { ShoppingCartItem, MainCartItem } from '../models/public-api';

@Injectable()
export class MainCartItemApiService {

    constructor(private readonly authHttpService: AuthHttpService,
        private readonly urlHelperService: UrlHelperService) { }

    public getItems(cartKey: string): Observable<ShoppingCartItem[]> {
        return this.authHttpService.get<ShoppingCartItem[]>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/items`));
    }

    public addItem(cartKey: string, item: MainCartItem): Observable<ShoppingCartItem> {
        return this.authHttpService.post<ShoppingCartItem>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/items`), item);
    }

    public addItems(cartKey: string, items: MainCartItem[]): Observable<ShoppingCartItem[]> {
        return this.authHttpService.post<ShoppingCartItem[]>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/items/list`), items);
    }

    public removeItems(cartKey: string): Observable<void> {
        return this.authHttpService.delete(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/items`));
    }

    public updateItem(cartKey: string, id: number, item: MainCartItem): Observable<ShoppingCartItem> {
        return this.authHttpService.put<ShoppingCartItem>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/items/${id}`), item);
    }

    public removeItem(cartKey: string, id: number): Observable<void> {
        return this.authHttpService.delete(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/items/${id}`));
    }

    public addAffiliateItems(cartKey: string, cartType: string, country: string, items: string[]): Observable<any> {
        return this.authHttpService.post<any>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/affiliate-items`), { listItems: items, cartType: cartType, country: country }).pipe();
    }

    public addQuickCheckoutItems(cartKey: string, cartType: string, country: string, items: string[]): Observable<any> {
        return this.authHttpService.post<any>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/quick-checkout`), { listItems: items, cartType: cartType, country: country }).pipe();
    }
}
