import { animate, group, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TranslationService } from '../../cms/src/translation.service';
import { SettingsService } from '../../common/src/settings.service';
import { CmsSessionService } from '../../cms/src/cms-session.service';
import { LocaleService } from '../../cms/src/locale.service';
import { WebStorageService } from '../../common/src/web-storage.service';
import { ContentHeaderNotificationService } from './content-header-notification.service';
import { DiscountMessageComponent } from './messages/discount-message.component';
import { AuthService } from '../../authentication/src/auth.service';
import { DiscountModel } from './models/discounts.model';
import { Direction } from '@angular/cdk/bidi';

@Component({
    selector: 'lib-header-notification',
    templateUrl: './content-header-notification.component.html',
    styleUrls: ['./content-header-notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ opacity: '0', height: '0px', overflow: 'hidden' }),
                group([animate('200ms ease-out', style({ height: '16px', opacity: '!' }))]),
            ]),
            transition(':leave', [
                style({ opacity: '!', height: '16px', overflow: 'hidden' }),
                group([animate('200ms ease', style({ height: '0px', opacity: '0' }))]),
            ]),
        ]),
    ],
})
export class ContentHeaderNotificationComponent implements OnInit, OnDestroy {
    public showDiscount: boolean = false;
    public showInfo: boolean = false; // prepared but not used
    public showWarning: boolean = false;
    public showGeneral: boolean = false;
    public showTempWarning: boolean = false;
    public showpPriceIncrMsg: boolean = false;
    public showPointsArchivingMsg: boolean = false;
    public discountNotifications: DiscountModel.Discount[] = [];
    public notificationId = '';
    public translations: any = {
        maintenanceMessage: '',
        generalMessage: '',
        pointsArchivingRunningMsg: '',
        temporaryWarning: '',
        priceIncrMessage: '',
    };
    public subscriptions: Subscription = new Subscription();
    private directionality: Direction = 'ltr';

    constructor(
        private contentHeaderNotificationService: ContentHeaderNotificationService,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly cookieService: WebStorageService,
        private readonly localeService: LocaleService,
        private readonly auth: AuthService,
        private readonly dialog: MatDialog,
        private readonly settings: SettingsService
    ) {}

    public ngOnInit(): void {
        this.initSession();
        this.getTranslations();
        this.initializeSubscription();
        this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public showMessage(discount: DiscountModel.Discount): void {
        const config: MatDialogConfig = {
            role: 'dialog',
            hasBackdrop: true,
            disableClose: false,
            data: discount,
            direction: this.directionality
        };
        if (discount.mainDiscountTypeFK == 5) {
            this.dialog.open(DiscountMessageComponent, config).afterClosed();
        }
    }

    public initializeSubscription(): void {
        this.subscriptions.add(
            this.auth.tokenChanged.subscribe((loggedIn) => {
                if (!loggedIn) {
                    this.contentHeaderNotificationService.clearSession();
                }
            })
        );
        if (this.cookieService.getCookie('CheckNotification') === 'false' && this.cookieService.getCookie('showWarning') === '') {
            this.checkSessionCookies('showWarning');
        }

        if (
            this.cookieService.getCookie('CheckNotification') === 'false' &&
            this.cookieService.getCookie('showPointsArchivingMsg') === ''
        ) {
            this.checkSessionCookies('showPointsArchivingMsg');
        }

        if (new Date() < new Date('2/10/2022') && this.auth.isLoggedIn && ['CN'].includes(this.auth.getCountry())) {
            this.showGeneral = true;
        }

        if (new Date() < new Date('12/31/2021') && this.auth.isLoggedIn && this.auth.getCountry() === 'IT') {
            this.showTempWarning = true;
        }

        if (
            this.settings.siteName == 'JOffice2' &&
            new Date() < new Date('5/1/2022') &&
            this.auth.isLoggedIn &&
            !['CN', 'HK', 'KR', 'MO', 'MY', 'TW'].includes(this.auth.getCountry())
        ) {
            this.showpPriceIncrMsg = true;
        }

        if (this.cookieService.getCookie('CheckNotification') === '') {
            this.cookieService.setCookie('CheckNotification', 'false');
            if (this.auth.isLoggedIn) {
                this.subscriptions.add(
                    this.contentHeaderNotificationService.getDiscountDetails().subscribe((discountResponse: DiscountModel.Response) => {
                        this.checkDiscountsAPI(discountResponse.discount);
                        this.setUI();
                    })
                );
            }
            this.subscriptions.add(
                this.contentHeaderNotificationService.getWarningDetails().subscribe((maintenanceResponse: boolean) => {
                    this.checkWarningOnAPI(maintenanceResponse);
                })
            );

            this.subscriptions.add(
                this.contentHeaderNotificationService.arePointsArchivingNow().subscribe((isArchiving: boolean) => {
                    this.setShowingOfPointsArchivingRunningMsg(isArchiving);
                })
            );
        } else {
            this.setUI();
        }
    }

    public onCloseWarning(input: string): void {
        this[input] = false;
        this.cookieService.setCookie(input, 'false');
    }

    public checkSessionCookies(vars: string): void {
        this.cookieService.getCookie(vars) === '' ? (this[vars] = true) : (this[vars] = false);
    }

    public getTranslationMessage(discount: DiscountModel.Discount): string {
        switch (discount.mainDiscountTypeFK) {
            case 5:
                return (
                    this.translationService.translate(
                        'joffice-notification-global',
                        'BDcodeMsg',
                        'Enjoy 20% off any order with *coupon code: '
                    ) + discount.discountCode
                );
            default:
                let message = discount.bannerText;//this.translationService.translate('joffice-notification-global', discount.translationKey, '');
                //if (message) {
                //    message = message.replace('[[discountcode]]', discount.discountCode);
                //}
            
                return message;
        }
    }

    private setUI() {
        if (
            this.cookieService.getCookie('CheckNotification') === 'false' &&
            this.cookieService.getCookie('showDiscount') === '' &&
            this.cookieService.getCookie('discountMessages') !== '' &&
            this.auth.isLoggedIn
        ) {
            let discounts = JSON.parse(this.cookieService.getCookie('discountMessages'));
            if (discounts && discounts.length > 0) {
                this.discountNotifications = discounts;
            }
            this.checkSessionCookies('showDiscount');
        }
    }

    private setShowingOfPointsArchivingRunningMsg(isRunning: boolean): void {
        isRunning ? (this.showPointsArchivingMsg = true) : this.cookieService.setCookie('showPointsArchivingMsg', 'false');
    }

    private checkWarningOnAPI(maintenanceResponse: boolean): void {
        maintenanceResponse ? (this.showWarning = true) : this.cookieService.setCookie('showWarning', 'false');
    }

    private checkDiscountsAPI(discounts: DiscountModel.Discount[]): void {
        if (discounts && discounts.length > 0 && this.cookieService.getCookie('showDiscount') === '') {
            this.discountNotifications = discounts.filter((el) => el.translationKey);
            this.cookieService.setCookie('discountMessages', JSON.stringify(this.discountNotifications));
            this.showDiscount = true;
        }
    }

    private initSession(): void {
        if (this.auth.isLoggedIn) {
            let id = this.cookieService.getCookie('notificationId');
            if (id != this.auth.getMainId().toString()) {
                this.contentHeaderNotificationService.clearSession();
            }
            this.notificationId = this.auth.getMainId().toString();
            this.cookieService.setCookie('notificationId', this.notificationId);
        }
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.maintenanceMessage = this.translationService.translate(
            'joffice-notification-global',
            'down-message',
            'Jeunesse systems will be offline for upgrades'
        );
        this.translations.generalMessage = this.translationService.translate(
            'joffice-notification-global',
            'general-message',
            '请注意，通过 JOffice 购买的 中国订单将在农历新年期间继续处理和运送，所有包裹将经过 2 周的强制消毒过程，并在 2022 年 2 月 9 日这一周内物流恢复后继续发货。<br/>我们感谢您的理解和支持！'
        );
        this.translations.pointsArchivingRunningMsg = this.translationService.translate(
            'joffice-notification-global',
            'points-archiving-msg',
            'Cycle generation is delayed temporarily and will resume shortly.'
        );
        this.translations.temporaryWarning = this.translationService.translate(
            'joffice-notification-global',
            'temporary-warning-message',
            'Please be advised that you will be receiving an email from our new tax and accounting support partner. Please reply to the email to provide authorization for commission processing.'
        );

        let langCode: string = this.cmsSessionService.getCulture().substring(0, 2).toUpperCase();
        if (this.auth.isLoggedIn) {
            switch (this.auth.getLangId()) {
                case 2:
                    langCode += '-HANT';
                    break;

                case 3:
                    langCode += '-HANS';
                    break;

                case 11:
                    langCode += '-BR';
                    break;
            }
        } else {
            langCode = 'Default';
        }

        this.translations.priceIncrMessage = this.translationService
            .translate(
                'joffice-notification-global',
                'price-increase-message',
                '<a href="https://ls1.jeunessemail.com/TemplatePreview.aspx?id=eN2pB4GQyU%2fdwOPSnOYoeQ%3d%3d&lang={0}" target="_blank">NEW PRODUCT PRICING - Click here</a>'.replace(
                    '{0}',
                    'Default'
                )
            )
            .replace('{0}', langCode);
    }
}
