export class EditCartAmountModel {
    public subTotal: any;
    public discount?: any;
    public shipping?: any;
    public tax?: any;
    public total?: any;
    constructor() {
        this.subTotal = '';
        this.discount = '';
        this.shipping = '';;
        this.tax = '';
        this.total = '';
    }
}