export class NLevelsFormModel {
    public topMainPk: number;
    public level: number;
    public limit: number;
    public insideSiteUrl: string;
    public insideMainPk: number;

    constructor(options: any = {}) {
        this.topMainPk = options.topMainPk;
        this.level = options.level;
        this.limit = options.limit;
        this.insideSiteUrl = options.insideSiteUrl;
        this.insideMainPk = options.insideMainPk;
    }
}