/**
 * User Model for JET users.
 */
export class UserSimpleModel {
    public id: number;
    public mainFk: number;
    public siteUrl: string;
    public fullName: string;
    public email: string;
    public phone: string;
    public active: boolean;

    constructor(options: any = {}) {
        this.id = options.id;
        this.mainFk = options.mainFk;
        this.siteUrl = options.siteUrl;
        this.fullName = options.fullName;
        this.email = options.email;
        this.phone = options.phone;
        this.active = options.active;
    }
}