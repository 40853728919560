/**
 * Model to send settings to API
 */
export class SmartDeliverySettingsFormModel {
    public isPickup: boolean;
    public shipMethod: number;
    public signatureReq: boolean;
    public walletFirst: boolean;
    public noPersonalUseOnly: boolean;
    public paymentType: string;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}

