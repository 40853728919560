import { NgModule } from '@angular/core';
import { BdayMessageComponent } from './messages/bday-message.component';
import { DiscountMessageComponent } from './messages/discount-message.component';
import { ContentHeaderNotificationComponent } from './content-header-notification.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsModule } from '../../cms/src/cms.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderNotificationComponent } from './components/header-notification.component';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        CmsModule
    ],
    exports: [
        BdayMessageComponent,
        DiscountMessageComponent,
        ContentHeaderNotificationComponent,
        HeaderNotificationComponent
    ],
    declarations: [
        BdayMessageComponent,
        DiscountMessageComponent,
        ContentHeaderNotificationComponent,
        HeaderNotificationComponent
    ],
    entryComponents:[DiscountMessageComponent],
    providers: [
    ]
})
export class NotificationsModule {
}
