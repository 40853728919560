import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Language, LocaleService } from '@jeunesse/angular/cms';
import { LoaderService, ProductDescriptionModel, ProductService } from '@jeunesse/cart-lib';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'jn-product-description',
    templateUrl: './product-description.component.html',
    styleUrls: ['./product-description.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductDescriptionComponent implements OnInit {
    public productDescription: ProductDescriptionModel = new ProductDescriptionModel();
    public languages: Language[];
    public defaultLanguage: Language;
    public languageControl: FormControl = new FormControl();

    private productSKU: string = '';
    private culture: string = '';

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly productService: ProductService,
        private readonly localeService: LocaleService,
        private readonly loadingService: LoaderService,
        private readonly router: Router
    ) {}

    public ngOnInit(): void {
        this.loadingService.show();

        this.activatedRoute.paramMap
            .pipe(
                switchMap((params) => {
                    this.culture = params.get('culture');
                    this.productSKU = params.get('sku');
                    return this.localeService.getLanguagesForGuest();
                })
            )
            .pipe(
                switchMap((items: Language[]) => {
                    this.languages = items.filter((item) => item.active === true);
                    this.defaultLanguage = items.find((item) => item.cultureName === this.culture);
                    this.languageControl.setValue(this.defaultLanguage);
                    return this.productService.getProductBySKU({ SKU: this.productSKU, culture: this.culture });
                })
            )
            .subscribe(
                (product: ProductDescriptionModel) => {
                    if (!product) this.router.navigate(['']);
                    this.productDescription = product;
                    this.loadingService.hide();
                },
                (error) => {
                    console.log(error);
                    this.router.navigate(['']);
                }
            );

        this.languageControl.valueChanges.subscribe((value: Language) => {
            if (value) this.router.navigate([`jhqsocial/catalog/product-description/${value.cultureName}/${this.productSKU}`]);
        });
    }
}
