import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { UrlHelperService } from '../../common/src/url-helper.service';
import { AuthHttpService } from '../../authentication/src/auth-http.service';
import { CmsSessionService } from './cms-session.service';

/**
 * This has readonly functionality at this time
 */
@Injectable()
export class TranslationImageService implements OnDestroy {
    private imageCache: any[] = [];
    private highlighted: boolean = false;
    private highlightSubject = new Subject<any>();
    private cmsSessionSubscription: Subscription;
    private sessionSubject = new Subject<any>();

    constructor(
        private readonly urlHelper: UrlHelperService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly authHttp: AuthHttpService) {
        this.cmsSessionSubscription = this.cmsSessionService.getSessionObservable().subscribe((session: any) => {
            this.loadCmsSession(session);
        });
    }

    public ngOnDestroy(): void {
        this.cmsSessionSubscription.unsubscribe();
    }

    public translate(state: string, name: string, content: string = ''): string {
        const cultureName: string = this.cmsSessionService.getCulture();
        // Try to get by the current culture
        let image: any = this.imageCache.find(el => el.name.toLowerCase() === name.toLowerCase() && el.culture === cultureName);
        // If null try to get en-US version
        if (!image) {
            image = this.imageCache.find(el => el.name.toLowerCase() === name.toLowerCase() && el.culture === 'en-US');
        }
        if (image) {
            return image.url;
        } else {
            if (this.cmsSessionService.isTranslator() && cultureName === 'en-US' && content) {
                this.addImage(name, cultureName, content).subscribe();
                console.warn(`Adding New Image: State: ${state} Name: ${name}`);
                return content;
            } else {
                console.warn(`Image Not Found: State: ${state} Name: ${name}`);
                return content;
            }
        }
    }

    public addImage(name: string, culture: string, url: string): Observable<any> {
        let parts: string[] = url.split('/');
        let filename: string = parts[parts.length - 1];

        return this.authHttp.post<any>(this.urlHelper.toCmsApi(`v1/cms/add-initial-media?name=${name}&filename=${filename}&culture=${culture}&mediaType=image&url=${url}`)).pipe(
            map((data: any) => {
                if (data) {
                    this.imageCache.push(data);
                    return data.url;
                } else {
                    return '';
                }
            }),
            catchError(() => {
                return of('');
            }));
    }

    public loadImages(states: string | string[], culture?: string): Observable<boolean> {
        let cultureName: string = culture || this.cmsSessionService.getCulture();
        cultureName = encodeURIComponent(cultureName);

        return this.authHttp.get<any>(this.urlHelper.toCmsApi(`v1/cms/images/${cultureName}`)).pipe(
            map((data: any) => {
                this.imageCache.push(...data.images);
                let session: any = this.cmsSessionService.getSession();
                this.loadCmsSession(session);
                return true;
            }), catchError(error => of(false))
        );
    }

    public loadCmsSession(session: any): void {
        let culture: string = this.cmsSessionService.getCulture();
        session.images.forEach(element => {
            if (element.culture === culture) {
                let index: number = this.imageCache.findIndex(x => x.name === element.name);
                if (index >= 0) {
                    this.imageCache.splice(index, 1);
                }
                this.imageCache.push(element);
            }
        });

        this.sessionSubject.next(session);
    }

    public findImage(state: string, name: string): any {
        return this.imageCache.find(x => x.name === name);
    }

    public saveImage(file: File, mediaKey: string, mediaType: string): Observable<any> {
        let ext: string = '';
        let culture: string = this.cmsSessionService.getCulture();
        if (file.name.indexOf('.') > -1) {
            let parts: string[] = file.name.split('.');
            ext = '.' + parts[parts.length - 1];
        }
        let filename: string = mediaKey + '_' + culture + ext;
        let data: FormData = new FormData();
        data.append(mediaKey, file);

        let options: any = { headers: { 'Culture': culture } };
        return this.authHttp.post<any>(
            this.urlHelper.toCmsApi(`/v1/cms/upload-media/?name=${mediaKey}&filename=${filename}&culture=${culture}&mediaType=${mediaType}`), data, options).pipe(
                map((data: any) => {
                    if (data) {
                        let index: number = this.imageCache.findIndex(x => x.name === data[0].name);
                        this.imageCache[index] = data[0];
                        return data[0].url;
                    } else {
                        return '';
                    }
                }),
                catchError(() => {
                    return of('');
                }));
    }

    public toggleHighlight(): void {
        this.highlighted = !this.highlighted;
        this.highlightSubject.next(this.highlighted);
    }

    public checkHighlight(): boolean {
        return this.highlighted;
    }

    public getHighlightedObservable(): Observable<any> {
        return this.highlightSubject.asObservable();
    }

    public getSessionObservable(): Observable<any> {
        return this.sessionSubject.asObservable();
    }

    public isTranslator(): boolean {
        return this.cmsSessionService.isTranslator();
    }
}
