/**
 * Address model
 */
export class AddressFormModel {
    public firstName: string;
    public lastName: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public postalCode: string;
    public country: string;
    public isUseShippingAddress: boolean;
    public isOTG: boolean;

    constructor(options: any = {}) {
        this.firstName = options.shipFName;
        this.lastName = options.shipLName;
        this.address1 = options.address1;
        this.address2 = options.address2;
        this.address3 = options.address3;
        this.city = options.city;
        this.state = options.state;
        this.postalCode = options.postalCode;
        this.country = options.country;
        this.isUseShippingAddress = options.isUseShippingAddress;
        this.isOTG = options.isOTG;
    }
}
