import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeoService } from '@jeunesse/angular';

import {
    ProductModel
} from '../models/public-api';

@Injectable({
    providedIn: 'root'
})

export class ProductSeoService {



    constructor(
        protected readonly seoService: SeoService
    ) {

    }

    /**
     * Sends Google Analytic track clicking information.
     */
    public sendGAInfo(product: ProductModel, action: string): void {
        this.seoService.trackClick(product.name, action);
    }
}