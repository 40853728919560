import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import * as moment from 'moment';
import { CommonModule as JCommonModule, IAppSettings } from '@jeunesse/angular/common';
import { AuthenticationModule, AuthService } from '@jeunesse/angular/authentication';
import { CmsModule } from '@jeunesse/angular/cms';
import { SecurityModule } from '@jeunesse/angular/security';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SeoService } from '@jeunesse/angular/common';
import { LayoutModule } from './layout/layout.module';
import { ProductsLibModule, CheckoutLibModule, PaymentModule, LoaderModule, BannersModule } from '@jeunesse/cart-lib';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';

import localeAe from '@angular/common/locales/ar';
import localeCz from '@angular/common/locales/cs';
import localeDk from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeAu from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeMx from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIl from '@angular/common/locales/he';
import localeHu from '@angular/common/locales/hu';
import localeId from '@angular/common/locales/id';
import localeIt from '@angular/common/locales/it';
import localeJp from '@angular/common/locales/ja';
import localeKh from '@angular/common/locales/km';
import localeKr from '@angular/common/locales/ko';
import localeMy from '@angular/common/locales/ms';
import localeNo from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeBr from '@angular/common/locales/pt';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeUa from '@angular/common/locales/uk';
import localeRu from '@angular/common/locales/ru';
import localeSl from '@angular/common/locales/sl';
import localeSk from '@angular/common/locales/sk';
import localeSe from '@angular/common/locales/sv';
import localeTh from '@angular/common/locales/th';
import localeTr from '@angular/common/locales/tr';
import localeVn from '@angular/common/locales/vi';
import localeCn from '@angular/common/locales/zh';
import localeHk from '@angular/common/locales/zh';
import localeTw from '@angular/common/locales/zh';
import { RepGuard } from './rep-guard.service';
import { SlideCartModule } from '@jeunesse/slide-cart';
import { AddressLibModule } from 'libs/address-lib/src/public-api';
import { GlobalErrorHandler } from './gobal-error.handler';
import { DialogVideoModule } from '@jeunesse/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BidiModule } from '@angular/cdk/bidi';
import { SharedModule } from './shared/shared.module';
import { SnackBarModule } from './shared/snack-bar/snack-bar.module';
import { MatSidenavModule } from '@angular/material/sidenav';


declare const AppSettings: IAppSettings;

@NgModule({
    imports: [
        BrowserModule,
        FontAwesomeModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatToolbarModule,
        MatInputModule,
        MatCardModule,
        MatMenuModule,
        MatDialogModule,
        MatTabsModule,
        MatCheckboxModule,
        MatSidenavModule,
        FlexLayoutModule,
        JCommonModule.forRoot(),
        AuthenticationModule.forRoot(),
        SecurityModule.forRoot(),
        CmsModule.forRoot(),
        ProductsLibModule,
        SlideCartModule.forRoot(),
        AddressLibModule,
        CheckoutLibModule,
        PaymentModule,
        SharedModule.forRoot(),
        LoaderModule.forRoot(),
        BannersModule,
        SnackBarModule.forRoot(),
        LayoutModule,
        DialogVideoModule,
        BidiModule,
        // nothing after this routing module
        AppRoutingModule,
    ],
    exports: [],
    declarations: [
        AppComponent,
    ],
    providers: [
        RepGuard,
        SeoService,
        DatePipe,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],
    entryComponents: [],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private readonly matIconRegistry: MatIconRegistry,
        private readonly authService: AuthService
    ) {
        matIconRegistry.registerFontClassAlias('fa');
        matIconRegistry.registerFontClassAlias('jn');
        matIconRegistry.setDefaultFontSetClass('fa');

        // Set moment global locale
        moment.locale(authService.isLoggedIn ? authService.getCulture() : null);
        registerLocaleData(localeAe);
        registerLocaleData(localeAu);
        registerLocaleData(localeBr);
        registerLocaleData(localeCn);
        registerLocaleData(localeCz);
        registerLocaleData(localeDe);
        registerLocaleData(localeDk);
        registerLocaleData(localeEs);
        registerLocaleData(localeFr);
        registerLocaleData(localeHk);
        registerLocaleData(localeHu);
        registerLocaleData(localeId);
        registerLocaleData(localeIl);
        registerLocaleData(localeIt);
        registerLocaleData(localeJp);
        registerLocaleData(localeKh);
        registerLocaleData(localeKr);
        registerLocaleData(localeMx);
        registerLocaleData(localeMy);
        registerLocaleData(localeNl);
        registerLocaleData(localeNo);
        registerLocaleData(localePl);
        registerLocaleData(localePt);
        registerLocaleData(localeRo);
        registerLocaleData(localeUa);
        registerLocaleData(localeRu);
        registerLocaleData(localeSe);
        registerLocaleData(localeSk);
        registerLocaleData(localeSl);
        registerLocaleData(localeTh);
        registerLocaleData(localeTr);
        registerLocaleData(localeTw);
        registerLocaleData(localeVn);
    }
}
