import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CustomerServiceComponent } from "./components/customer-service.component";
import { CustomerService } from "./services/public-api";
import { CmsModule } from "@jeunesse/angular/lib/cms";

@NgModule({
    imports: [
        CommonModule,
        CmsModule
    ],
    declarations: [CustomerServiceComponent],
    exports: [CustomerServiceComponent],
    providers: [CustomerService]
})
export class CustomerServiceModule { }