import {
    Component,
    OnInit,
    Input,
    Output,
    ChangeDetectionStrategy,
    EventEmitter,
    forwardRef,
    ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { FONTAWESOMEICONLIST, JEUNESSEICONLIST } from './data/public-api';
import { IconBoxModel, IconModel, IconDataModel } from './models/public-api';
import * as _ from 'lodash';

import * as ListSelectorAccessor from '../../list-selector/src/models/list-selector-accessor';


/**
 * Shows a list of selectable icons from Jeunesse
 * and Font Awesome libraries.
 */
@Component({
    selector: 'jn-icon-selection',
    templateUrl: './icon-selection.component.html',
    styleUrls: ['./icon-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ListSelectorAccessor.LIST_SELECTOR_VALUE_ACCESSOR]
})
export class IconSelectionComponent implements OnInit, ControlValueAccessor {

    /**
     * Emmits the current icon if single or current list of icons selected if multiple on each click.
     */
    @Output()
    public readonly selected: EventEmitter<IconModel | IconModel[]> = new EventEmitter<IconModel | IconModel[]>();

    /**
     * @ignore
     */
    public selectedIcon: IconBoxModel;

    /**
     * @ignore
     */
    public selectedIconList: IconBoxModel[] = [];

    /**
     * @ignore
     */
    public filteredIconList: IconBoxModel[] = [];

    public iconList: IconBoxModel[] = [];

    private _isMultiple: boolean;
    private _filterText: string;
    private _keptIcons: string[] = [];
    private _keptFontSets: string[] = [];
    private _excludedIcons: string[] = [];
    private _excludedFontSets: string[] = [];
    private _icons: IconModel | IconModel[] = [];


    private innerValue: IconModel[];

    private changed: (value: IconModel | IconModel[]) => void;
    private touched: () => void;

    /**
     * Sets the type of selection, multiple or single selection.
     * The default is 'false' meaning single selection.
     */
    @Input()
    public set multiple(multiple: boolean) {
        this._isMultiple = multiple || false;
        // this.resetSelected();
    }

    /**
     * Text input to filter down the lists by icon name.
     */
    @Input()
    public set filter(filter: string) {
        if (filter !== undefined && !_.isEqual(filter, this._filterText)) {
            this._filterText = filter;
            // this.filteredIconList = this.filterAll();
            // this.rePositionSelected();
        }
    }

    /**
     * String Array to exclude icons from being shown and / or selected.
     * Ex: (['crown', 'address-change'])
     */
    @Input()
    public set excludeIcons(excludeIcons: string[]) {
        if (excludeIcons !== undefined && !_.isEqual(excludeIcons, this._excludedIcons)) {
            this._excludedIcons = excludeIcons;
            // this.filteredIconList = this.filterAll();
        }
    }

    /**
     * String Array to exclude complete font sets from being shown and / or selected.
     * Ex: (['fa', 'jn'])
     */
    @Input()
    public set excludeFontSets(excludeFontSets: string[]) {
        if (excludeFontSets !== undefined && !_.isEqual(excludeFontSets, this._excludedFontSets)) {
            this._excludedFontSets = excludeFontSets;
            // this.filteredIconList = this.filterAll();
        }
    }

    /**
     * String Array to 'keep' only this list of icons the be shown and / or selected.
     * Ex: (['crown', 'address-change'])
     */
    @Input()
    public set keepIcons(keepIcons: string[]) {
        if (keepIcons !== undefined && !_.isEqual(keepIcons, this._keptIcons)) {
            this._keptIcons = keepIcons;
            // this.filteredIconList = this.filterAll();
        }
    }

    /**
     * String Array to 'keep' only this list of font sets from being shown and / or selected.
     * Ex: (['fa', 'jn'])
     */
    @Input()
    public set keepFontSets(keepFontSets: string[]) {
        if (keepFontSets !== undefined && !_.isEqual(keepFontSets, this._keptFontSets)) {
            this._keptFontSets = keepFontSets;
            // this.filteredIconList = this.filterAll();
        }
    }

    /**
     * IconModel list of pre-selected icons.
     */
    @Input()
    public set icons(icons: IconModel | IconModel[]) {
        if (icons !== undefined && !_.isEqual(icons, this._icons)) {
            this._icons = icons;
            // this.setSelectedIcons();
        }
    }

    constructor(private readonly ref: ChangeDetectorRef) { }

    /**
     * @ignore
     */
    public ngOnInit(): void {
        // Get the list of icons.
        this.iconList = this.buildIconList();
    }

    public select(icon: IconBoxModel): void {
        if (!this.value.includes(icon)) {
            this.value.push(icon);
            this.value = this.value.slice();
        }

        this.selected.emit(this._isMultiple ? this.value : this.value[0]);
    }

    /**
     * Icon Model to be presented in the box.
     * If the component is used independently only use IconModel.
     */
    public get value(): IconModel[] {
        return this.innerValue;
    }

    /**
     * Icon Model to be presented in the box.
     * If the component is used independently only use IconModel.
     */
    public set value(value: IconModel[]) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.changed(this._isMultiple ? value : value[0]);
        }
    }

    /**
     * @ignore
     */
    public touch(): void {
        this.touched();
    }

    /**
     * @ignore
     */
    public writeValue(value: IconModel | IconModel[]): void {
        this.innerValue = value instanceof Array ? value : [value];
        this.repositionSelected();
        this.ref.markForCheck();
    }

    /**
     * @ignore
     */
    public registerOnChange(fn: (value: IconModel | IconModel[]) => void): void {
        this.changed = fn;
    }

    /**
     * @ignore
     */
    public registerOnTouched(fn: () => void): void {
        this.touched = fn;
    }

    /**
     * @ignore
     */
    public setDisabledState(isDisabled: boolean): void { }

    /**
     * Get the list of available icons.
     */
    private buildIconList(): IconBoxModel[] {
        const icons: IconDataModel[] = [
            ...this.getSortedIconList(JEUNESSEICONLIST), ...this.getSortedIconList(FONTAWESOMEICONLIST)
        ];

        return icons.map((item: IconDataModel) => {
            return {
                set: item.fontSet,
                icon: `${item.fontSet}-${item.id}`,
                filtered: true,
                filterTerm: item.filter,
                active: true
            };
        });
    }

    /**
     * This reorders the list so that selected items are at the top. This should keep the items in the original sort order.
     */
    private repositionSelected(): void {
        if (!this.value)
            return;

        const selected: IconBoxModel[] =
            this.iconList.filter(x => this.value.some(y => y.set === x.set && y.icon === x.icon));
        const nonSelected: IconBoxModel[] =
            this.iconList.filter(x => !this.value.some(y => y.set === x.set && y.icon === x.icon));

        this.iconList = [...selected, ...nonSelected];
    }

    private getSortedIconList(iconList: IconDataModel[]): IconDataModel[] {
        return iconList.sort((a, b) => {
            const first: string = a.id.toLowerCase();
            const second: string = b.id.toLowerCase();

            if (first < second) {
                return -1;
            }
            if (first > second) {
                return 1;
            }

            return 0;
        });
    }
}
