import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ESignatureModalComponant, ESignatureViewModel } from "../../public-api";
import { Direction } from "@angular/cdk/bidi";
import { LocaleService } from '../../../../cms/src/locale.service';



@Component({
  selector: 'jn-eSignature-prompt',
  templateUrl: './eSignature-prompt.component.html',
  styleUrls: ['./eSignature-prompt.component.scss']
})
export class ESignaturePromptComponent implements OnInit {
  @Input() public info$: Observable<ESignatureViewModel>;
  @Output() public infoChanged: EventEmitter<ESignatureViewModel> = new EventEmitter<ESignatureViewModel>();
  public info: ESignatureViewModel;
  private directionality: Direction = 'ltr';
  constructor(public dialog: MatDialog,
    private readonly localeService: LocaleService){
  }

  public ngOnInit(): void {
    console.log('eSignature Prompt Loaded:', this.info$);
    this.info$.subscribe(data => { console.log(data); this.info=data });
    this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
  }

  public showDialog(): void {
    const dialogRef = this.dialog.open(ESignatureModalComponant, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: this.info,
      direction: this.directionality
    });

    dialogRef.afterClosed().subscribe((info: ESignatureViewModel) => {
      this.infoChanged.emit(info);
    });
  }
}
