import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * This class is the inbetween for components make calls to the state and api services.
 */
@Directive()
@Injectable({
    providedIn: 'root'
})
export class BackofficeMenuService {

    @Output() toggleEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() openEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

    public toggle(): void {
        console.log('BackofficeMenuService:toggle');
        this.toggleEvent.emit(true);
    }

    public open(): void {
        this.openEvent.emit(true);
    }

    public close(): void {
        this.closeEvent.emit(true);
    }
}
