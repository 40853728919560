import { Component, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { AnimationEvent } from '@angular/animations';
import { Router } from '@angular/router';
import { TranslationService, LocaleService, CmsSessionService } from '@jeunesse/angular';
import { LoginOverlayRef } from './login-overlay';
import { ANIMATIONS } from '../../components/animations';
import { UrlHelperService, SettingsService } from '@jeunesse/angular';
import { SeoService } from '@jeunesse/angular';
import { MenuService } from '../../services/menu.service';
import { MenuItem } from '../../models/menu-item.model';
import { MemberService, SmartDeliveryService } from '../../../member/public-api';
import { RepSiteInfo, RepSiteService } from '../../../repsite/public-api';
import { AuthService, LogoutRedirectEnum, TokenModel } from '@jeunesse/angular';
import { Subscription, timer } from 'rxjs';
import { WebStorageService } from '@jeunesse/angular';
import { MainCartService } from '@jeunesse/slide-cart';
import { MenuAlertModel } from '../../../shared/models/menuAlert.model';
import { MenuStateService } from '../../services/public-api';

/**
 * This is for showing the login screen in an overlay.
 */
@Component({
    templateUrl: './login-overlay.component.html',
    styleUrls: ['./login-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ANIMATIONS.transformOverlay],
    host: {
        '[@transformOverlay]': 'animationState',
        '(@transformOverlay.start)': 'onAnimationStart($event)',
        '(@transformOverlay.done)': 'onAnimationDone($event)',
    },
})
export class LoginOverlayComponent implements OnInit, OnDestroy {
    protected timer1: Subscription;
    /**
     * This is the remote for controlling the overlay.
     * This needs to be set after the overlay has been created.
     */
    public loginOverlayRef: LoginOverlayRef;
    /**
     * Returns if currently logging in.
     */
    public isLoggingIn: boolean = false;
    public isLoggedIn: boolean = false;
    public isCRM: boolean = false;
    public menuAlerts: MenuAlertModel[] = [];
    public menuUnreadCounts: MenuAlertModel[] = [];
    /**
     * The login FormGroup.
     */
    public loginForm: FormGroup;
    /**
     * The link to take the user to reset their password.
     */
    public resetPasswordLink: string;
    public signupLink: string;
    public jofficeSignin: string = '';
    public clientName: string;
    public menuTopItems: MenuItem[] = [];
    public menuIconItems: MenuItem[] = [];
    public repInfo: RepSiteInfo;
    public subscriptions: Subscription = new Subscription();

    /**
     * Changed state of the animations.
     */
    public animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

    /**
     * The current state of the animation.
     */
    public animationState: 'void' | 'enter' | 'leave' = 'enter';

    /**
     * @ignore
     */
    public translations: any = {
        placeholderUsername: '',
        placeholderPassword: '',
        welcome: '',
        requiredField: '',
        passwordInvalid: '',
        userNameInvalid: '',
        signIn: '',
        forgotPassword: '',
        reset: '',
        hi: '',
        support: '',
    };

    public isRTL: boolean = false;

    constructor(
        private readonly menuService: MenuService,
        private readonly menuStateService: MenuStateService,
        private readonly authService: AuthService,
        private readonly urlHelper: UrlHelperService,
        private readonly translationService: TranslationService,
        private readonly seoService: SeoService,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly localeService: LocaleService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly memberService: MemberService,
        private readonly repSiteService: RepSiteService,
        private readonly mainCartService: MainCartService,
        private readonly webStorageService: WebStorageService,
        private readonly settings: SettingsService,
        private readonly smartDeliveryService: SmartDeliveryService
    ) {
        this.isLoggedIn = this.authService.isLoggedIn;
        this.setName();

        this.setRTLDirection();
    }

    public ngOnInit(): void {
        this.isLoggedIn = this.authService.isLoggedIn;
        this.setName();
        this.menuService.getUserMenu().subscribe((menus) => {
            this.menuTopItems = menus.filter((menu) => menu.isExternalLink === false);
            this.menuIconItems = menus.filter((menu) => menu.isExternalLink === true);
        });
        this.loginForm = new FormGroup({
            userName: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        });

        // Get Password Reset URL.
        this.resetPasswordLink = this.urlHelper.buildUrl(this.settings.classicSiteUrl, '/v2/NewLogin.aspx?purpose=reset');

        // get signup url
        this.signupLink = this.urlHelper.buildUrl(this.settings.classicSiteUrl, '/signup.asp');
        this.jofficeSignin = this.urlHelper.buildUrl(this.settings.classicSiteUrl, '/login.asp');

        this.getTranslations();
        this.isCRM =
            location.hostname.toLowerCase().indexOf('crm.jeunesseglobal') > -1 ||
            location.hostname.toLowerCase().indexOf('crm2.jeunesseglobal') > -1;
        this.subscriptions.add(
            this.menuStateService.getMenuAlert().subscribe((data) => {
                this.menuAlerts = data;
            })
        );
        this.subscriptions.add(
            this.menuStateService.getMenuUnreadCount().subscribe((data) => {
                this.menuUnreadCounts = data;
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public jofficeLogin(): void {
        location.href = this.jofficeSignin;
    }

    public isConfirmedSignup(): boolean {
        if (this.webStorageService.getCookie('signupKey')) {
            if (this.webStorageService.getCookie('ca') === 'true') {
                return true;
            }
        }
        return false;
    }

    public getUnreadNumber(menu: MenuItem): number {
        let res: number = 0;
        if (this.menuUnreadCounts !== null && this.menuUnreadCounts.length > 0) {
            const menuAlert = this.menuUnreadCounts.find((m) => m.pageName.toLowerCase() === menu.translationKey.toLowerCase());
            if (menuAlert !== undefined) {
                if (parseInt(menuAlert.warningText, 10) > 0) {
                    res = parseInt(menuAlert.warningText, 10);
                }
            }
        }
        return res;
    }

    /**
     * Login Action.
     */
    public login(): void {
        if (!this.loginForm.valid) return;
        this.isLoggingIn = true;
        this.loginForm.clearValidators();
        // KLK - Task: 44869
        const userName: AbstractControl = this.loginForm.get('userName');
        if (userName.value.toLowerCase().startsWith('guest_')) {
            userName.setErrors({ invalid: true });
            return;
        }
        this.subscriptions.add(
            this.authService.loginOverLayAfterClose.subscribe((res) => {
                if (res === 'closed') {
                    this.loginOverlayRef.close();
                }
            })
        );
        this.authService.login(this.loginForm.value).subscribe(
            (loggedIn: boolean) => {
                if (loggedIn) {
                    this.isLoggedIn = true;
                    this.loginOverlayRef.close(loggedIn);
                    //#46234
                    if (this.settings.siteName === 'Shop') {
                        this.mainCartService.init('', this.smartDeliveryService, '').subscribe();
                    }
                    this.smartDeliveryService.loadSmartDeliveryInfo(true);
                }
            },
            (_err) => {
                this.isLoggingIn = false;
                this.loginForm.get('password').setErrors({ invalid: true });
                this.ref.markForCheck();
            }
        );
    }
    /**
     * Logout action.
     */
    public logout(): void {
        this.authService.logout(LogoutRedirectEnum.NONE, true).subscribe(() => {
            sessionStorage.clear();
            // Clear Rep info
            this.repSiteService.clearRepStorage();
            this.repInfo = undefined;
            this.memberService.clearLocalInfo();
            this.webStorageService.removeCookie('needAgree');
            this.webStorageService.setCookie('showDocUploadNotification', 'true');
            // Clear the cart and the session objects.
            if (this.settings.siteName === 'Shop') {
                this.mainCartService.clearCart().subscribe((resp) => {
                    location.href = '/';
                });
            } else {
                if (this.isCRM) {
                    location.href = this.menuService.toEnvLink(this.settings.classicSiteUrl + '/v2/Logout.aspx');
                } else {
                    location.href = this.settings.classicSiteUrl + '/v2/Logout.aspx';
                }
            }
        });
    }

    public getFontGroup(fontName: string): string {
        return fontName.replace('.', '').substring(0, 2);
    }

    public getFontName(fontName: string): string {
        return fontName.replace('.', '');
    }

    /**
     * Converts the URL to a route.
     * @param url The URL to convert.
     * @return Local path.
     */
    public convertLinkToRoute(url: string): string {
        return this.menuService.convertLinkToRoute(url);
    }

    /**
     * This is called when the animation starts.
     * @param event The event.
     */
    public onAnimationStart(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * This is called when the animation ends.
     * @param event The event.
     */
    public onAnimationDone(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * Start the exist animation.
     */
    public startExitAnimation(): void {
        this.animationState = 'leave';

        this.ref.markForCheck();
    }

    public sendGAInfo(category: string, action: string): void {
        let label: string = this.router.url;
        this.seoService.trackClick(label, action, category);
    }

    public toRoute(url: string): string {
        return this.menuService.convertLinkToRoute(url);
    }

    public buildQueryParams(url: string) {
        return this.menuService.addQueryParamsToRoute(url);
    }

    public toExternalUrl(url: string): string {
        return this.menuService.toExternalUrl(url);
    }

    public isExternal(link: string): boolean {
        return this.menuService.isExternal(link);
    }

    public closeMe(): void {
        if (this.loginOverlayRef) {
            this.timer1 = timer(180).subscribe((_) => {
                this.loginOverlayRef.close();
            });
        }
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.placeholderUsername = this.translationService.translate('menu-library', 'placeholder-username', 'Username');
        this.translations.placeholderPassword = this.translationService.translate('menu-library', 'password', 'Password');
        this.translations.welcome = this.translationService.translate('menu-library', 'welcome', 'Welcome');
        this.translations.requiredField = this.translationService.translate('menu-library', 'requiredField', 'Field is required.');
        this.translations.passwordInvalid = this.translationService.translate(
            'menu-library',
            'passwordInvalid',
            'Please enter the correct password.'
        );
        this.translations.userNameInvalid = this.translationService.translate('menu-library', 'userNameInvalid', 'User name is invalid.');
        this.translations.signIn = this.translationService.translate('menu-library', 'signIn', 'SIGN IN');
        this.translations.forgotPassword = this.translationService.translate('menu-library', 'forgotPassword', 'Forgot your Password?');
        this.translations.reset = this.translationService.translate('menu-library', 'reset', 'Reset');
        this.translations.hi = this.translationService.translate('menu-library', 'hi', 'Hi');
    }

    private setName(): void {
        if (this.isLoggedIn) {
            let token: TokenModel = this.authService.getAuthToken();
            this.clientName = token.firstName + ' ' + token.lastName;
        }
    }

    private setRTLDirection(): void {
        this.localeService.directionalityObservable$.subscribe((direction: string) => {
            this.isRTL = direction === 'rtl';
        });
    }
}
