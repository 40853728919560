import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { MenuOverlayComponent } from './menu-overlay.component';
import { MenuOverlayService } from './menu-overlay.service';
import { CmsModule } from '@jeunesse/angular';



/**
 * Displays a list of brands under an Category as an overlay.
 */
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatInputModule,
        OverlayModule,
        PortalModule,
        FlexLayoutModule,
        CmsModule
    ],
    exports: [

    ],
    declarations: [
        MenuOverlayComponent
    ],
    providers: [
        MenuOverlayService
    ],
    entryComponents: [
        MenuOverlayComponent
    ]
})

export class MenuOverlayModule {

}