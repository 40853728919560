import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreadcrumbsService } from './breadcrumbs.service';
import { BreadcrumbsResolver } from './breadcrumbs.resolver';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsModule } from '../../cms/src/cms.module';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
     imports: [
        CommonModule,
        BrowserModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        FlexLayoutModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        CmsModule
     ],
     declarations: [],
     exports: []
})
export class BreadcrumbsModule {
    /**
     * This is required so the same provider is injected into the load on demand modules.
     *
     * More Details: http://angularfirst.com/the-ngmodule-forroot-convention/
     */
    public static forRoot(): ModuleWithProviders<BreadcrumbsModule> {
        return {
            ngModule: BreadcrumbsModule,
            providers: [
                BreadcrumbsService,
                BreadcrumbsResolver
            ]
        };
    }
}
