
/**
 * Member Editable model
 */
export class MemberFormModel {
    public userId: number;
    public courtesyTitle: string;
    public firstName2: string;
    public lastName2: string;
    public firstName3: string;
    public lastName3: string;
    public homePhone: string;
    public secondPhone: string;
    public faxNumber: string;
    public emailAddress: string;
    public dateOfBirth: string;
    public languageFk: string;
    public receiveEmails: string;
    public receiveSms: string;
    public smsInEnglish: string;
    public accptSmsCharges: boolean;
    public accptSmsNotReq: boolean;
    public lockPlacementLra: string;
    public allowInsidePlacement: boolean;
    public insidePlacementLevel: number;
    public insideTopMainFk: number;
    public insideSignupSponsorSide: string;
    public showContactInfo: string;
    public nameOnWebsite: string;
    public displayName: string;
    public sponsorType: string;
    public taxIdNumber: string;
    public company: string;
    public itAgreedToTermsDate?: string;
    public facebookUrl: string;
    public skypeAccount: string;
    public twitterAccount: string;
    public instagramAccount: string;
    public tiktokAccount: string;


    constructor(options: any = {}) {
        this.userId = options.mainPk;
        this.courtesyTitle = options.courtesyTitle;
        this.firstName2 = options.firstName2;
        this.lastName2 = options.lastName2;
        this.firstName3 = options.firstName3;
        this.lastName3 = options.lastName3;
        this.homePhone = options.homePhone;
        this.secondPhone = options.secondPhone;
        this.faxNumber = options.faxNumber;
        this.emailAddress = options.emailAddress;
        this.dateOfBirth = options.dateOfBirth;
        this.languageFk = options.languageFk;
        this.receiveEmails = options.receiveEmails;
        this.receiveSms = options.receiveSms;
        this.smsInEnglish = options.smsInEnglish;
        this.accptSmsCharges = options.accptSmsCharges;
        this.accptSmsNotReq = options.accptSmsNotReq;
        this.lockPlacementLra = options.lockPlacementLra;
        this.allowInsidePlacement = options.allowInsidePlacement;
        this.insidePlacementLevel = options.insidePlacementLevel;
        this.insideTopMainFk = options.insideTopMainFk;
        this.insideSignupSponsorSide = options.insideSignupSponsorSide;
        this.showContactInfo = options.showContactInfo;
        this.nameOnWebsite = options.nameOnWebsite;
        this.displayName = options.displayName;
        this.sponsorType = options.sponsorType;
        this.taxIdNumber = options.taxIdNumber;
        this.company = options.company;
        this.itAgreedToTermsDate = options.itAgreedToTermsDate;
        this.facebookUrl = options.facebookUrl;
        this.skypeAccount = options.skypeAccount;
        this.twitterAccount = options.twitterAccount;
        this.instagramAccount = options.instagramAccount;
        this.tiktokAccount = options.tiktokAccount;

    }
}