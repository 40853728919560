import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { TranslationVideoService } from './translation-video.service';

/**
 * This does not work at this time.
 */
@Injectable()
export class TranslationVideoResolver implements Resolve<any> {
    constructor(private readonly translationVideoService: TranslationVideoService) {
     }

     public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

          return this.translationVideoService.loadVideos(route.data['states']);
     }
}
