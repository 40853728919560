import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ExportDataSourceButtonSettings, PagesToExportEnum } from '../../src/public-api';

@Component({
    selector: 'jn-export-data-source-button',
    templateUrl: 'export-data-source-button.component.html',
    styleUrls: ['export-data-source-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExportDataSourceButtonComponent implements OnInit {
    @Input()
    set settings(value: ExportDataSourceButtonSettings) {
        this._settings = value;
    }
    get settings(): ExportDataSourceButtonSettings {
        return this._settings;
    }

    @Input()
    set translations(value: any) {
        this._translations = value;
    }
    get translations(): any {
        return this._translations;
    }

    @Output()
    public getDataSourceToExport: EventEmitter<string> = new EventEmitter<string>();

    public pagesToExport = PagesToExportEnum;

    private _settings: ExportDataSourceButtonSettings;
    private _translations: any;

    constructor() {}

    public ngOnInit(): void {}

    public emitDataGetting(exportDataSize: string): void {
        this.getDataSourceToExport.emit(exportDataSize);
    }

    public downloadDataToExport(dataToExport: any[]): Observable<boolean> {
        try {
            if (this.settings.columnsToExport && this.settings.columnsToExport.length) {
                dataToExport = dataToExport.filter((props: any) => {
                    for (const propName in props) {
                        if (!this.settings.columnsToExport.find((columnName) => columnName.toLowerCase() === propName.toLowerCase())) {
                            delete props[propName];
                        }
                    }

                    return true;
                });
            }

            const csvData: any = this._convertToCSV(dataToExport);
            const universalBom: any = '\uFEFF';
            const a: any = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBom + csvData));
            a.setAttribute(
                'download',
                `${this.translations.exportedFileName || 'DataExported'}_${new DatePipe('en-US').transform(new Date(), 'yMMdd_hhmmss')}.csv`
            );
            window.document.body.appendChild(a);
            a.click();
        } catch {
            return of(false);
        }

        return of(true);
    }

    private _convertToCSV(dataToExport: any[]): string {
        let str: string = '',
            headerRow: string = '';

        for (const propName in dataToExport[0]) {
            headerRow += (this.translations[propName] || propName) + ',';
        }

        headerRow = headerRow.slice(0, -1);
        // append Label row with line break
        str += headerRow + '\r\n';

        dataToExport.forEach((dataRow: any) => {
            let line: string = '';

            for (const propName in dataRow) {
                line += (dataRow[propName] || '') + ',';
            }

            line = line.slice(0, -1);
            str += line + '\r\n';
        });

        return str;
    }
}
