import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, NgModule, OnChanges, SimpleChanges} from '@angular/core';
import { ManualPayment } from '../../models/manual-payment.model';
//import { ManualPaymentType } from '../../models/manual-payment-type.model';
import { PaymentInfo } from '../../models/payment-info.model';
import { Observable, Subscription } from 'rxjs';
import { ManualPaymentService } from '../../services/manual-payment.service';

@Component({
    selector: 'jn-manual-payment',
    templateUrl: './manual-payment.component.html',
    styleUrls: ['./manual-payment.component.scss']
})
export class ManualPaymentComponent implements OnInit {

    @Input() public paymentMethodTypePK: number;
    @Input() public orderNumber: number;
    @Input() public amount: number;
    @Input() public checkNumber: number;
    @Output() manualPayment: EventEmitter<ManualPayment> = new EventEmitter();

    public subscriptions: Subscription = new Subscription();
    public loading: boolean = true;
    public infos: PaymentInfo[] = [];
    public manualTypeValue: number = 0;

    constructor(public manualPaymentService: ManualPaymentService) {

    }

    ngOnInit() {
    }
    // garbage collection
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    ngOnChanges(changes: SimpleChanges) {
        this.showInfo();   
    }
    private showInfo() {
        if (isNaN(this.paymentMethodTypePK)) return;
        if (this.manualPaymentService.isManual(+this.paymentMethodTypePK)) {
            this.subscriptions.add(this.manualPaymentService.getOrderPaymentInfo(isNaN(+this.orderNumber) ? 0 : this.orderNumber).subscribe((data) => {
                this.infos = data.filter(info => +info.merchantManualTypeFK !== 0 && info.key !== 'PayType');
                this.manualPayment.emit(this.manualPaymentService.paymentTypeToManualPayment(this.paymentMethodTypePK, this.amount, this.checkNumber));
                this.loading = false;
            }));
        }
    }
}
