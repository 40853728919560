export class AddCCPaymentFormModel {
    public mainOrdersFK: number;
    public mainFK: number;
    public cardFullName: string;
    public cardNumber: string;
    public cardType: string;
    public cvv: string;
    public expireMonth: string;
    public expireYear: string;
    public saveCardInfo: boolean;
    public installment: number;
    public fname: string;
    public lname: string;
    public city: string;
    public state: string;
    public zip: string;
    public phone: string;
    public country: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public email: string
    public useBillingAddress: boolean;

    public constructor(options: any) {
        this.mainOrdersFK = options.mainOrdersFK;
        this.mainFK = options.mainFK;
        this.cardFullName = options.cardFullName;
        this.cardNumber = options.cardNumber;
        this.cardType = options.cardType;
        this.cvv = options.cvv;
        this.expireMonth = options.expireMonth;
        this.expireYear = options.expireYear;
        this.saveCardInfo = options.saveCardInfo;
        this.installment = options.installment;
        this.fname = options.fname;
        this.lname = options.lname;
        this.city = options.city;
        this.state = options.state;
        this.zip = options.zip;
        this.phone = options.phone;
        this.country = options.country;
        this.address1 = options.address1;
        this.address2 = options.address2;
        this.address3 = options.address3;
        this.email = options.email;
        this.useBillingAddress = options.useBillingAddress;
    }
}