import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { LoginOverlayComponent } from './login-overlay.component';
import { LoginOverlayConfig } from './login-overlay-config.interface';
import { LoginOverlayRef } from './login-overlay-ref';


/** Default settings of overlay. */
const defaultConfig: LoginOverlayConfig = {
    hasBackdrop: true,
    panelClass: 'jn-login-overlay'
};

/** Default set of positions for the overlay. Follows the behavior of a dropdown. */
const defaultPositionList: ConnectedPosition[] = [
    {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
    },
    {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
    },
    {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom'
    },
    {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
    }
];


/**
 * Controls the overlay of the login screen.
 */
@Injectable()
export class LoginOverlayService {
    constructor(
        private readonly injector: Injector,
        private readonly overlay: Overlay) {
    }

    /**
     * Opens the overlay.
     * @param config Config options to override the defaults.
     */
    public open(config: LoginOverlayConfig = {} ): LoginOverlayRef {
        const dialogConfig: LoginOverlayConfig = { ...defaultConfig, ...config };

        // Returns an OverlayRef which is a PortalHost
        const overlayRef: OverlayRef = this.createOverlay(dialogConfig);

        const overlayComponent: LoginOverlayComponent = this.attachDialogContainer(overlayRef, dialogConfig);

        // Instantiate remote control
        const loginOverlayRef: LoginOverlayRef = new LoginOverlayRef(overlayRef, overlayComponent);

        // Set remote to component
        overlayComponent.loginOverlayRef = loginOverlayRef;

        overlayRef.backdropClick().subscribe(_ => loginOverlayRef.close());

        return loginOverlayRef;
    }

    private createOverlay(config: LoginOverlayConfig): OverlayRef {
        // Returns an OverlayConfig
        const overlayConfig: OverlayConfig = this.getOverlayConfig(config);

        // Returns an OverlayRef
        return this.overlay.create(overlayConfig);
    }

    private getOverlayConfig(config: LoginOverlayConfig): OverlayConfig {
        let positionStrategy: PositionStrategy;

        if (config.flexibleConnectedTo) {
            positionStrategy = this.overlay.position()
                .flexibleConnectedTo(config.flexibleConnectedTo.origin.elementRef)
                .withPositions(config.flexibleConnectedTo.positions || defaultPositionList)
                .withFlexibleDimensions(config.flexibleConnectedTo.dimensions)
                .withPush(config.flexibleConnectedTo.push)
                .withGrowAfterOpen(config.flexibleConnectedTo.growAfterOpen)
                .withViewportMargin(config.flexibleConnectedTo.viewportMargin || 0)
                .withLockedPosition(config.flexibleConnectedTo.lockPosition);
        }
        else {
            positionStrategy = this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically();
        }

        const overlayConfig: OverlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }

    private createInjector(config: LoginOverlayConfig): PortalInjector {
        // Instantiate new WeakMap for our custom injection tokens
        const injectionTokens: WeakMap<object, any> = new WeakMap();

        // Set custom injection tokens

        // Instantiate new PortalInjector
        return new PortalInjector(this.injector, injectionTokens);
    }

    private attachDialogContainer(overlayRef: OverlayRef, config: LoginOverlayConfig): LoginOverlayComponent {
        const injector: PortalInjector = this.createInjector(config);

        const containerPortal: ComponentPortal<LoginOverlayComponent> = new ComponentPortal(LoginOverlayComponent, null, injector);
        const containerRef: ComponentRef<LoginOverlayComponent> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }
}
