import { Injectable } from '@angular/core';
import { SettingsService } from '../../common/src/settings.service';

/**
 * Service used to handl SEO tracking events.
 */
@Injectable()
export class SeoService {
    // To  be change only for development
    private get isDevSite(): boolean {
        return this.settings.env === 'LOCAL' || this.settings.env === 'DEV';
    }

    private gaCategory;
    constructor(private readonly settings: SettingsService) {}

    // GA Enhanced Link Attribution
    /**
     * Creates a Google Analytics custom tracking call.
     * @param  id
     * @param  action?
     * @param  category?
     * @returns void
     */
    public trackClick(id: string, action?: string, category?: string): void {
        if (category) {
            this.gaCategory = category;
        }
        if (!this.isDevSite) {
            if (id !== undefined) {
                const event: any = {
                    eventCategory: this.gaCategory || 'customTracking',
                    eventAction: action || 'click',
                    eventLabel: id
                };
                if ((<any>window).ga)
                    (<any>window).ga('send', 'event', event);
            }
        }
    }

    /**
     * Creates a Google Analytics outbound tracking call.
     * @param category
     * @param action
     * @param label
     */
    public trackOutboundClick(category: string, action: string, label: string): void {
        if (!this.isDevSite) {
            const event: any = {
                eventCategory: category || 'customTracking',
                eventAction: action || 'click',
                eventLabel: label,
                transport: 'beacon'
            };
            if ((<any>window).ga)
                (<any>window).ga('send', 'event', event);
        }
    }

    /**
     * Sets the category for any GA click tracking events.
     * @param category
     */
    public setGACategory(category: string): void {
        this.gaCategory = category;
    }

    /**
     * Sets the tracking type for Google Analytics
     * @param type Type could be equal to Replicated Site, CCAP, Influencer
     * @param name Name: SiteURL, Influencer, etc.
     */
    public setTrackingType(type: string, name: string): void {
        if (!this.isDevSite && (<any>window).ga) {
            (<any>window).ga('set', 'dimension1', type);
            (<any>window).ga('set', 'dimension2', name);
        }
    }

    // AW Conversion Tracker
    /**
     * Makes the Google Analytics conversion tracking call.
     * @returns void
     */
    public trackAWConversion(id: string, label: string, total: number): void {
        if (!this.isDevSite) {
            // AW New Conversion Tracker
            (<any>window).gtag('event', 'conversion', { send_to: `AW-${id}/${label}`, currency: 'USD', value: total });
        }
    }

    // Facebook Pixel Page View Tracker
    /**
     * Makes the Facebook pixel page view tracking call.
     * @param  label
     * @param  url
     * @param  model?
     * @returns void
     */
    public sendFBPixelPageView(label: string, url: string, model?: any): void {
        if (!this.isDevSite) {
            const trackObj: any = { Page: url };
            if (model) {
                if (url.toLowerCase().indexOf('confirmation') !== -1) {
                    trackObj['Conversion'] = 1;
                }
                trackObj['Name'] = model.name;
                trackObj['OrderNumber'] = model.mainOrderPrimaryKey;
                trackObj['MainPK'] = model.mainPrimaryKey;
            }

            (<any>window).fbq('track', 'PageView');
            (<any>window).fbq('trackCustom', label, trackObj);
        }
    }

    public sendSiftPageView() {
        (<any>window)._sift.push(['_trackPageview']);
    }
}
