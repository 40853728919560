/**
 * Product Document Model
 */
export class ProductDocumentModel {
    public name: string;
    public url: string;

    constructor(obj: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}