export class MenuItemModel {
     public id: number;
     public translationKey: string;
     public name: string;
     public content: string;
     public parentId?: number;
     public sortId?: number;
     public action: string;
     public submenu: MenuItemModel[];
     public icon: string;
     public isOpened?: boolean;
}