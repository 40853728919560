import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'jn-cart-items-removed-dialog',
    templateUrl: './cart-items-removed-dialog.component.html',
    styleUrls: ['./cart-items-removed-dialog.component.scss']
})

export class CartItemsRemovedDialogComponent {

    constructor(private readonly matDialogRef: MatDialogRef<CartItemsRemovedDialogComponent>) {}

    public close(): void {
        this.matDialogRef.close();
    }
}