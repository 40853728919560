import { KeyValueState } from './key-value.model';
/**
 * Existing CeditCard model
 */
export class PaymentViewModel {
    public sequencePk: number;
    public ccType: string;
    public ccNumberLastFour: number;
    public ccExpireMonth: string;
    public ccExpireYear: string;
    public ccCvv: string;
    public useOrder: number;
    public oldUseOrder: number;
    public ccBillingAddr1: string;
    public ccBillingAddr2: string;
    public ccBillingAddr3: string;
    public ccBillingCity: string;
    public ccBillingState: string;
    public ccBillingCountry: string;
    public ccBillingZip: string;
    public ccStates: KeyValueState[];
    public showCvv: boolean
    public ccHolderName: string;
    public recurringToken: string;

    constructor(options: any = {}) {
        this.sequencePk = options.sequencePk;
        this.ccType = options.ccType;
        this.ccNumberLastFour = options.ccNumberLastFour;
        this.ccExpireMonth = options.ccExpireMonth;
        this.ccExpireYear = options.ccExpireYear;
        this.ccCvv = options.ccCvv;
        this.useOrder = options.useOrder;
        this.oldUseOrder = options.useOrder;
        this.ccBillingAddr1 = options.ccBillingAddr1;
        this.ccBillingAddr2 = options.ccBillingAddr2;
        this.ccBillingAddr3 = options.ccBillingAddr3;
        this.ccBillingCity = options.ccBillingCity;
        this.ccBillingState = options.ccBillingState;
        this.ccBillingCountry = options.ccBillingCountry;
        this.ccBillingZip = options.ccBillingZip;
        this.ccStates = options.ccStates;
        this.showCvv = options.showCvv;
        this.ccHolderName = options.ccHolderName;
        this.recurringToken = options.recurringToken;
    }
}