// tslint:disable: typedef
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventAgendaState } from '../models/event-agenda-state.model';
import { EventInfoState } from '../models/event-info-state.model';
import * as fromPostAgendas from './reducers/event-post-agendas.reducer';
export interface FeatureState {
    eventInfo: EventInfoState;
    eventAgenda: EventAgendaState;
    eventAgendasPost: fromPostAgendas.AgendasPostState;
}
export interface State {
    event: FeatureState;
}
export const selectEventInfo = createFeatureSelector<State, FeatureState>('event');
export const selectFeature = (state: State) => state.event.eventAgendasPost;
export const selectEntities = (state: State) => state.event.eventAgendasPost.entities;
// get eventInfo
export const getEventInfo = createSelector(selectEventInfo, (state: FeatureState) => state.eventInfo.eventInfo);
// get eventAgendaInfo
export const getEventAgendas = createSelector(selectEventInfo, (state: FeatureState) => state.eventAgenda);
// get the an array of Agendas
export const getAgendasPost = createSelector(selectFeature, fromPostAgendas.selectAllAgendas);
// get a dictionary of agenda entities
export const getEventMemberEntities = createSelector(selectFeature, fromPostAgendas.selectAgendaPostEntities);
// get all id's of agendaPostEntities
export const getAgendasPostIds = createSelector(selectFeature, fromPostAgendas.selectAgendaIds);
// get agendaPostEntity by id
export const getAgendasPostEntityById = createSelector(selectEntities, (entities, id) => entities[id]);

