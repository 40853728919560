import { Component, Input, OnInit } from '@angular/core';

import { ContentHeaderNotificationService } from '../content-header-notification.service';
import { ApplicationType } from '../models/application-notification-query.interface';
import { ApplicationNotification } from '../models/application-notification.interface';

@Component({
    selector: 'jn-header-notification',
    templateUrl: './header-notification.component.html',
    styleUrls: ['./header-notification.component.scss'],
})
export class HeaderNotificationComponent implements OnInit {
    public messages: ApplicationNotification[] = [];
    public showMsg: boolean = true;
    @Input() public countryCode: string;
    @Input() public culture: string;
    @Input() public application: ApplicationType = ApplicationType.JOffice2; // Shop, JOffice2, Events, Admin2, Signup

    constructor(private contentHeaderNotificationService: ContentHeaderNotificationService) {}

    public ngOnInit(): void {
        this.loadMessages();
    }

    public loadMessages(): void {
        if (this.countryCode && this.culture && this.application) {
            this.contentHeaderNotificationService.getApplicationNotificationsQuery({
                countryCode: this.countryCode,
                culture: this.culture,
                application: this.application,
            });
        }
        this.contentHeaderNotificationService.getApplicationNotifications().subscribe((response) => {
            this.messages = response;
        });
    }
}
