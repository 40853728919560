import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  templateUrl: './dist-upgrade-modal.component.html',
  styleUrls: ['./dist-upgrade-modal.component.scss']
})
export class DistUpgradeModalComponent {
  constructor(private readonly dialogRef: MatDialogRef<DistUpgradeModalComponent>) {

  }

  upgradeClick(): void {
    this.dialogRef.close(true);
  }
}
