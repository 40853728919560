import { NgModule } from '@angular/core';
import { DynamicAddressComponent } from './components/dynamic-address/dynamic-address.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CmsModule } from '@jeunesse/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicAddressApiService } from './services/dynamic-address-api.service';
import { AddressLibStateService } from './services/address-lib-state.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddressValidationUpdateDialogComponent } from './components/dynamic-address/address-validation-update-dialog/address-validation-update-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { DaumPostcodeComponent } from './components/daum-postcode/daum-postcode.component';
import { InputNameFilterPipe } from './services/input-filter.pipe';
import { JpPostcodeLookupComponent } from './components/jp-postcode-lookup/jp-postcode-lookup.component';
import { JpPostcodeLookupDialogComponent } from './components/jp-postcode-lookup/jp-postcode-lookup-dialog.component';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
      DynamicAddressComponent,
      DaumPostcodeComponent,
      JpPostcodeLookupComponent,
      JpPostcodeLookupDialogComponent,
      AddressValidationUpdateDialogComponent,
      InputNameFilterPipe
    ],
  imports: [
      ReactiveFormsModule,
      CommonModule,
      CmsModule.forRoot(),
      FlexLayoutModule,
      MatCardModule,
      MatAutocompleteModule,
      MatCheckboxModule,
      MatDialogModule,
      MatDividerModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      MatSelectModule,
      MatToolbarModule,
      MatTooltipModule,
      MatRadioModule,
      MatButtonModule,
      MatTableModule,
      FormsModule
  ],
  exports: [
    DynamicAddressComponent,
    DaumPostcodeComponent,
    JpPostcodeLookupComponent,
    AddressValidationUpdateDialogComponent
  ],
  providers: [
      DynamicAddressApiService,
      AddressLibStateService
  ],
  entryComponents: [
    JpPostcodeLookupDialogComponent
  ]
})
export class AddressLibModule { }
