
/**
 * Address model to represent the billing or shipping address for a member object.
 */
export class AddressModel {
    public firstName: string;
    public lastName: string;
    public firstName2: string;
    public lastName2: string;
    public firstName3: string;
    public lastName3: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public stateToUse: string;
    public stateCodeIsNumber: boolean;
    public area: string;
    public postalCode: string;
    public country: string;
    public email: string;
    public phoneNumber: string;
    public secondPhoneNumber: string;
    public isResidential: boolean;
    public company: string;
    public verifiedShippingAddress: boolean;
    public taxIdNumber: string;
    public ssn: string;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.address1 = '';
        this.address2 = '';
        this.address3 = '';
        this.city = '';
        this.postalCode = '';
        this.email = '';
        this.phoneNumber = '';
        this.secondPhoneNumber = '';
        this.verifiedShippingAddress = false;
    }

    public setAddress(updated: AddressModel): void {
        if (updated.firstName && this.firstName !== updated.firstName) {
            this.firstName = updated.firstName;
        }
        if (updated.lastName && this.lastName !== updated.lastName) {
            this.lastName = updated.lastName;
        }
        if (updated.firstName2 && this.firstName2 !== updated.firstName2) {
            this.firstName2 = updated.firstName2;
        }
        if (updated.lastName2 && this.lastName2 !== updated.lastName2) {
            this.lastName2 = updated.lastName2;
        }
        if (updated.firstName3 && this.firstName3 !== updated.firstName3) {
            this.firstName3 = updated.firstName3;
        }
        if (updated.lastName3 && this.lastName3 !== updated.lastName3) {
            this.lastName3 = updated.lastName3;
        }
        if (this.address1 !== updated.address1) {
            this.address1 = updated.address1;
        }
        if (this.address2 !== updated.address2) {
            this.address2 = updated.address2;
        }
        if (this.address3 !== updated.address3) {
            this.address3 = updated.address3;
        }
        if (this.area !== updated.address3) {
            this.area = updated.address3; // area is the same as address 3
        }
        if (this.area !== updated.area) {
            this.area = updated.area;
            if (!this.address3) {
                this.address3 = updated.area;
            }
        }
        if (this.city !== updated.city) {
            this.city = updated.city;
        }
        if (updated.state && this.state !== updated.state) {
            this.state = updated.state;
            if (!this.stateToUse) {
                this.stateToUse = this.state;
            }
        }
        if (updated.stateToUse && this.stateToUse !== updated.stateToUse) {
            this.stateToUse = updated.stateToUse;
            if (!this.state) {
                this.state = updated.stateToUse;
            }
        }
        if (this.postalCode !== updated.postalCode) {
            this.postalCode = updated.postalCode;
        }
        if (this.country !== updated.country) {
            this.country = updated.country;
        }
        if (this.phoneNumber !== updated.phoneNumber) {
            this.phoneNumber = updated.phoneNumber;
        }
        if (this.secondPhoneNumber !== updated.secondPhoneNumber) {
            this.secondPhoneNumber = updated.secondPhoneNumber;
        }
        if (this.email !== updated.email) {
            this.email = updated.email;
        }
        if (this.taxIdNumber !== updated.taxIdNumber) {
            this.taxIdNumber = updated.taxIdNumber;
        }
        if (this.ssn !== updated.ssn) {
            this.ssn = updated.ssn;
        }
        if (this.verifiedShippingAddress !== updated.verifiedShippingAddress) {
            this.verifiedShippingAddress = updated.verifiedShippingAddress;
        }
    }
}
