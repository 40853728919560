export class PaymentMethodViewModel {
    public paymentMethodValue: string;
    public paymentMethodDisplayName: string;
    public showorder?: number;
    public isPaymentWire: boolean;
    public orderKeyEncoded: string;
    public redirectUrl: string;
    public paymentMethodTypePK?: number;
    public isMerchantRedirect: boolean;

    constructor(options: any) {
        this.paymentMethodValue = options.paymentMethodValue;
        this.paymentMethodDisplayName = options.paymentMethodDisplayName;
        this.showorder = options.showorder;
        this.isPaymentWire = options.isPaymentWire;
        this.orderKeyEncoded = options.orderKeyEncoded;
        this.redirectUrl = options.redirectUrl;
        this.paymentMethodTypePK = options.paymentMethodTypePK;
        this.isMerchantRedirect = options.isMerchantRedirect;
    }

}