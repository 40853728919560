import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthHttpService, AuthService } from '@jeunesse/angular';
import { UrlHelperService, WebStorageService } from '@jeunesse/angular';
import { MemberService, AddressModel, SmartDeliveryModel, MemberModel, SmartDeliveryApiService } from '../../member/public-api';
import {
    OrderModel,
    StateModel,
    CountryModel,
    ShippingMethodsViewModel,
    ShippingCostModel,
    LookupAddressViewModel,
    ShippingOptionQuery,
    RetailCampaignQuery,
    SetOrderShipping,
    AddressLookup,
    WalletDiscountQuery,
    ValidateOrderResult,
    OrderProcessingFeesModel,
    AddProcessingFeesModel,
    Get3DSUrlModel,
    Get3DS2Model,
    Get3DSTokenUrlModel,
    DocumentsModel,
    PersonalUse,
    MerchantInstallmentConfigModel,
    MerchantInstallmentFormModel,
    CurrencyNumberModel,
    PaymentMethodsViewModel,
    CheckoutMessage,
    CheckoutMessageQuery,
    BonusTokenOrderLimit,
    WalletOrderLimit,
    OrderResponseModel,
    AddCCPaymentFormModel,
    CreditCardTypeModel,
} from '../models/public-api';
import { BillingCountry } from '../models/billing-country.interface';
import { SubSink } from 'subsink';
import { GatewayResponse } from '../../payment/public-api';
import { FormatCurrency } from '../models/format-currency.model';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    public hideCart = new Subject<boolean>();
    protected _orderModel: OrderModel = new OrderModel('US', 4);
    protected orderInfoSubject$ = new BehaviorSubject<OrderModel>(this._orderModel);
    protected splitOrderTotalSubject = new Subject<number>();
    public splitOrderTotalState: Observable<number> = this.splitOrderTotalSubject.asObservable();
    private subs = new SubSink();
    private countryCookie: string;
    protected personalUse: PersonalUse = { isPersonalResaleOn: false, showPersonalUse: false, showPersonalUseWarning: false, isValid: true };
    protected showPersonalUseSubject$ = new Subject<PersonalUse>();
    public showPersonalUse$ = this.showPersonalUseSubject$.asObservable();

    private _formattedCurrencyCache$ = new Map<string, Observable<FormatCurrency>>();
    private _paymentMethodsQuery$ = new Map<number | string, Observable<PaymentMethodsViewModel>>();

    constructor(
        private readonly authHttp: AuthHttpService,
        private readonly urlHelper: UrlHelperService,
        private readonly authService: AuthService,
        private readonly webStorageService: WebStorageService,
        private readonly memberService: MemberService,
        private readonly smartDeliveryApiService: SmartDeliveryApiService
    ) {
        // KLK - Task: 46730 - KR CustomsID for Import Cart
        this.countryCookie = this.webStorageService.getCookie('Country') ? this.webStorageService.getCookie('Country').replace(/\"/g, '') : 'US';
        this._orderModel.countryCode = this.countryCookie;
        this.orderInfoSubject$.next(this._orderModel);
    }

    public setOrderModel(orderModel: OrderModel) {
        this._orderModel = orderModel;
        this.orderInfoSubject$.next(orderModel);
    }

    public getOrderModel(): Observable<OrderModel> {
        return this.orderInfoSubject$.asObservable();
    }

    public clearOrderModel(): void {
        // KLK - Task: 46730 - KR CustomsID for Import Cart
        this._orderModel = new OrderModel(this.countryCookie, 2);
        this.orderInfoSubject$.next(this._orderModel);
    }

    // general central config
    public config(config: TargetConfigEnum, options: any = null): void {
        switch (config) {
            case TargetConfigEnum.SignupOrder:
                this._orderModel.countryCode = options == null ? this.countryCookie : options.countryCode;
                this._orderModel.mainOrderTypeFk = 1;
                break;
            default:
        }
    }

    /**
     * Checks if an order has been paid for.
     * @param orderNumber Order number to check.
     */
    public isOrderPaidFor(orderNumber: any): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/${orderNumber}/paid`));
    }

    /**
     * Checks to see if the logged in user has completed an order in the past.
     */
    public hasUserCompletedOrder(): Observable<boolean> {
        if (this.authService.isLoggedIn) {
            return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/${this.authService.getMainId()}/has-completed`));
        }
    }

    /**
     * Returns a list of supported states by country.
     * @param countryCode Country code to lookup. Example: US => United States
     */
    public getStates(countryCode: string): Observable<StateModel[]> {
        return this.authHttp.get<StateModel[]>(this.urlHelper.toProfileApi(`/v1/localization/countries/${countryCode}`)).pipe(
            map((states: any) => {
                this.webStorageService.setSession('states', JSON.stringify(states));
                return states;
            })
        );
    }

    /**
     * Returns a list of supported countries by country.
     * @param countryCode Country code to lookup. Example: US => United States
     */
    public getCountries(countryCode: string): Observable<CountryModel[]> {
        return this.authHttp.get<CountryModel[]>(this.urlHelper.toProfileApi(`/v1/localization/shipping/countries/${countryCode}`));
    }

    /**
     * Returns a list of supported billing countries.
     */
    public getBillingCountries(): Observable<BillingCountry[]> {
        return this.authHttp.get<BillingCountry[]>(this.urlHelper.toProfileApi(`/v1/localization/billing/countries`)).pipe(
            map((res: BillingCountry[]) => {
                return res.filter((x) => x.code !== '' && x.code !== null && x.code != 'EM' && x.code !== 'EN');
            })
        );
    }

    /**
     * Looks up retail campaign information.
     * @param model RetailCampaignQuery model
     */
    public getRetailCampaign(model: RetailCampaignQuery): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`/v1/order/campaigns/info/${model.brandFk}/US/${model.campaignCode}`));
    }

    /**
     * Used to query a list of shipping options without the order number.
     * @param model Shipping option query model.
     */
    public getShippingOptions(model: ShippingOptionQuery): Observable<ShippingMethodsViewModel> {
        const params: any = {
            cartKey: model.cartKey,
            country: model.country,
            state: model.state,
            zip: model.postalCode,
            city: model.city,
            address1: model.address1,
            address2: model.address2,
            address3: model.address3,
            mainOrderPK: model.orderNumber,
        };
        return this.authHttp
            .get<ShippingMethodsViewModel>(this.urlHelper.toCartApi('/v2/shipping/shipping/options'), { params: params })
            .pipe(
                map((resp: ShippingMethodsViewModel) => {
                    return new ShippingMethodsViewModel(resp);
                })
            );
    }

    /**
     * Used to set the shipping method on an order.
     * @param model Set order shipping model.
     */
    public setShippingMethodForOrder(model: SetOrderShipping): Observable<ShippingCostModel> {
        if (!model || !model.orderNumber) {
            return of(new ShippingCostModel({}));
        }
        const formModel: any = {
            orderPk: model.orderNumber,
            chartTypeFk: model.shippingChartTypeFk,
            signatureRequired: model.signatureRequired,
            cartKey: this.webStorageService.getCookie('cartKey'),
        };
        return this.authHttp.put<ShippingCostModel>(this.urlHelper.toCartApi('/v2/order/set-shipping'), formModel).pipe(
            map((resp: ShippingCostModel) => {
                return new ShippingCostModel(resp);
            })
        );
    }

    /**
     * Updates the current order shipping address based on the shipping address on the member model.
     */
    public setShippingAddressOnOrder(shippingAddress: AddressModel): Observable<boolean> {
        if (this._orderModel.mainOrdersFk || this._orderModel.mainOrdersPk) {
            const model: any = {
                mainOrdersFK: this._orderModel.mainOrdersFk || this._orderModel.mainOrdersPk,
                firstName: (shippingAddress.country === 'JP') ? (shippingAddress.firstName3 || shippingAddress.firstName) : (shippingAddress.firstName || shippingAddress.firstName3),
                lastName: (shippingAddress.country === 'JP') ? (shippingAddress.lastName3 || shippingAddress.lastName) : (shippingAddress.lastName || shippingAddress.lastName3),
                address1: shippingAddress.address1,
                address2: shippingAddress.address2,
                address3: shippingAddress.address3,
                city: shippingAddress.city,
                state: shippingAddress.state,
                postalCode: shippingAddress.postalCode,
                country: shippingAddress.country,
                area: shippingAddress.area,
                phone: shippingAddress.phoneNumber,
                fax: shippingAddress.secondPhoneNumber,
                email: shippingAddress.email,
                verifiedShippingAddress: shippingAddress.verifiedShippingAddress,
            };
            return this.authHttp.put<boolean>(
                this.urlHelper.toCartApi(`/v2/order/shipping/address/${this._orderModel.mainOrdersFk || this._orderModel.mainOrdersPk}`),
                model
            );
        } else {
            return of(false);
        }
    }

    /**
     * Moves the shopping cart items over to the order items table.
     */
    public moveCartItemsToOrder(mainOrdersFk: number): Observable<any> {
        return this.authHttp
            .put(
                this.urlHelper.toCartApi(`/v2/order/items/add-from-cart/${mainOrdersFk}/${this.webStorageService.getCookie('cartKey')}`),
                null
            )
            .pipe();
    }
    /**
     * Checks to see Split Payment Order has been paid
     */

    public checkForSplitPayStatus(mainOrdersPK): Observable<any> {
        return this.authHttp.get(this.urlHelper.toCartApi(`/v1/orders/${mainOrdersPK}`)).pipe();
    }
    /**
     * Creates a new order record for the logged in user.
     */
    public createOrder(): Observable<number> {
        if (this.authService.isLoggedIn && !this._orderModel.mainOrdersFk) {
            const model: any = {
                mainId: this.authService.getMainId(),
                mainOrderType: this._orderModel.mainOrderTypeFk,
                countryCode: this._orderModel.countryCode,
                salesCampaignFK: this._orderModel.salesCampaignFk,
                cartKey: this.webStorageService.getCookie('cartKey'),
            };
            return this.authHttp.post<any>(this.urlHelper.toCartApi('/v2/order'), model).pipe(
                map((resp: any) => {
                    this._orderModel.mainOrdersFk = resp.mainOrdersFK;
                    this._orderModel.encOrderNumber = resp.encOrderNumber;
                    this._orderModel.status = resp.message;
                    return resp.mainOrdersFK;
                })
            );
        } else {
            const model: any = { mainOrdersFk: this._orderModel.mainOrdersFk, cartKey: this.webStorageService.getCookie('cartKey') };
            return this.authHttp.post<any>(this.urlHelper.toCartApi('/v1/order/update-type'), model).pipe(
                map((res: any) => {
                    if (res) {
                        this._orderModel.mainOrderTypeFk = res.mainOrderTypeFk;
                        if (res.mainOrderTypeFk === 1) {
                            this._orderModel.saveCardInfo = true;
                        } else {
                            this._orderModel.saveCardInfo = false;
                        }
                    }
                    return this._orderModel.mainOrdersFk;
                })
            );
        }
    }

    /**
     * Apply discount
     */
    public applyDiscount(discountCode: string, mainOrdersFK?: number): Observable<any> {
        return this.authHttp.put<any>(this.urlHelper.toCartApi(`/v1/shopping/apply-discount`), { discountCode, mainOrdersFK });
    }

    /**
     * Used to search for products
     * @param model
     */
    public searchForProducts(model: any): Observable<[]> {
        const params: any = {
            culture: model.culture,
            cartType: model.cartType,
            searchTerm: model.value,
        };
        return this.authHttp.get<any>(this.urlHelper.toCartApi('/v2/products/search'), { params: params }).pipe();
    }

    /**
     * Used to return a possible match for an address based on the street address.
     * @param address1 Street Address
     */
    public validateAddress(placeid: string): Observable<LookupAddressViewModel> {
        return this.authHttp.post<LookupAddressViewModel>(this.urlHelper.toProfileApi(`/v1/address/validate?placeid=${placeid}`)).pipe(
            map((resp: LookupAddressViewModel) => {
                return new LookupAddressViewModel(resp);
            })
        );
    }

    /**
     * Returns saved credit cards for a logged in user.
     */
    public getSavedCreditCards(): any {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v1/payment')).pipe(
            map((data: any) => {
                return data.map((singlePayment: any) => {
                    return singlePayment;
                });
            })
        );
    }

    /**
     * Save newly entered credit card information
     * Do not use for new retail checkouts. This will create a new Autoship order and will cause AS to break for new order checkouts.
     */
    public saveNewCreditCardInfo(model: any): Observable<any> {
        return this.authHttp.post<any>(this.urlHelper.toProfileApi('/v1/payment'), model);
    }

    /**
     * Used to update the hide cart subject.
     * @param hide Used to indicate hiding or showing the cart.
     */
    public updateHideCart(hide: boolean): void {
        this._orderModel.hideCart = hide;
        this.hideCart.next(hide);
    }

    /**
     * Used to mark and order for autoship.
     */
    public markOrderForAutoShip(): void {
        if (this.authService.isLoggedIn) {
            // Check if we have an active SD customer logged in before we set this for the order.
            // If they are an active SD customer we should not set the ordertype to 1 it should be 4.
            this.smartDeliveryApiService.getSettings().subscribe((sd: SmartDeliveryModel) => {
                if (sd && !sd.active) {
                    this._orderModel.mainOrderTypeFk = 1;
                }
            });
        } else {
            this._orderModel.mainOrderTypeFk = 1;
        }
    }

    /**
     * Returns disclosure info for the order
     */
    public checkForDisclosure(): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`/v1/order/disclosure/${this._orderModel.mainOrdersFk}`)).pipe(
            map((disclosureInfo: any) => {
                this._orderModel.disclosure = disclosureInfo;
            })
        );
    }

    /**
     * Gets a list of payment options for the order
     */
    public getOrderPaymentOptions(): Observable<PaymentMethodsViewModel> {
        const orderNumber: number | string= this._orderModel.mainOrdersFk || this._orderModel.encOrderNumber;
        if(!this._paymentMethodsQuery$.get(orderNumber)) {
            this._paymentMethodsQuery$.set(
                orderNumber,
                this.authHttp.get<PaymentMethodsViewModel>(
                    this.urlHelper.toCartApi(`/v1/order/payment/${this._orderModel.mainOrdersFk || this._orderModel.encOrderNumber}`)
                ).pipe(shareReplay())
            )
        }
        return this._paymentMethodsQuery$.get(orderNumber)
    }

    /**
     * Returns a list of accepted credit cards for the order
     * @param countryCode Country code
     * @param currencyCode Currency code
     * @param mainOrderTypeFk Order Type
     * @param mainOrdersFk Order ID
     */
    public getAcceptedCreditCardTypes(
        countryCode: string,
        currencyCode: string,
        mainOrderTypeFk: number,
        mainOrdersFk?: number
    ): Observable<CreditCardTypeModel[]> {
        const params: any = {
            mainOrderTypeFk: mainOrderTypeFk,
            mainOrderPk: mainOrdersFk ? mainOrdersFk : '',
        };
        return this.authHttp.get<CreditCardTypeModel[]>(this.urlHelper.toCartApi(`/v1/order/payment/creditcard/types/${countryCode}/${currencyCode}`), {
            params: params,
        });
    }

    /**
     * Sets a new credit card info on an order.
     * Added additional keys for when billing is changed
     */
    public setCreditCardOnOrder(memberModel: MemberModel): Observable<any> {
        let model: AddCCPaymentFormModel;
        if (memberModel) {
            model = {
                mainOrdersFK: this._orderModel.mainOrdersFk,
                mainFK: this._orderModel.mainPk ? this._orderModel.mainPk : memberModel.mainPk,
                cardFullName: this._orderModel.creditCard.creditName,
                cardNumber: this._orderModel.creditCard.creditNumber,
                cardType: this._orderModel.creditCard.creditType,
                cvv: this._orderModel.creditCard.cvv,
                expireMonth: this._orderModel.creditCard.creditMonth,
                expireYear: this._orderModel.creditCard.creditYear,
                address1: !this._orderModel.billingEqualToShipping
                    ? memberModel.billingAddress.address1
                    : this._orderModel.creditCard.address1,
                address2: !this._orderModel.billingEqualToShipping
                    ? memberModel.billingAddress.address2
                    : this._orderModel.creditCard.address2,
                address3: memberModel.billingAddress.address3,
                city: !this._orderModel.billingEqualToShipping ? memberModel.billingAddress.city : this._orderModel.creditCard.city,
                state: !this._orderModel.billingEqualToShipping ? memberModel.billingAddress.state : this._orderModel.creditCard.state,
                zip: !this._orderModel.billingEqualToShipping
                    ? memberModel.billingAddress.postalCode
                    : this._orderModel.creditCard.postalCode,
                country: !this._orderModel.billingEqualToShipping
                    ? memberModel.billingAddress.country
                    : this._orderModel.creditCard.country,
                phone: !this._orderModel.billingEqualToShipping
                    ? memberModel.billingAddress.phoneNumber
                    : this._orderModel.creditCard.phone,
                saveCardInfo: (this._orderModel.creditCard.saveCardInfo) ? true : false,
                installment: this._orderModel.noOfInstallments ? this._orderModel.noOfInstallments : 0,
                useBillingAddress: !this._orderModel.billingEqualToShipping,
                fname: !this._orderModel.billingEqualToShipping
                    ? memberModel.billingAddress.firstName
                    : memberModel.shippingAddress.firstName,
                lname: !this._orderModel.billingEqualToShipping
                    ? memberModel.billingAddress.lastName
                    : memberModel.shippingAddress.lastName,
                email: !this._orderModel.billingEqualToShipping ? memberModel.billingAddress.email : this._orderModel.creditCard.email,
            };
        } else {
            model = {
                mainOrdersFK: this._orderModel.mainOrdersFk,
                mainFK: this._orderModel.mainPk,
                cardFullName: this._orderModel.creditCard.creditName,
                cardNumber: this._orderModel.creditCard.creditNumber,
                cardType: this._orderModel.creditCard.creditType,
                cvv: this._orderModel.creditCard.cvv,
                expireMonth: this._orderModel.creditCard.creditMonth,
                expireYear: this._orderModel.creditCard.creditYear,
                address1: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.address1
                    : this._orderModel.creditCard.address1,
                address2: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.address2
                    : this._orderModel.creditCard.address2,
                address3: this.memberService.memberModel.billingAddress.address3,
                city: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.city
                    : this._orderModel.creditCard.city,
                state: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.state
                    : this._orderModel.creditCard.state,
                zip: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.postalCode
                    : this._orderModel.creditCard.postalCode,
                country: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.country
                    : this._orderModel.creditCard.country,
                phone: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.phoneNumber
                    : this._orderModel.creditCard.phone,
                saveCardInfo: this._orderModel.creditCard.saveCardInfo ? true : false,
                installment: this._orderModel.noOfInstallments ? this._orderModel.noOfInstallments : 0,
                useBillingAddress: !this._orderModel.billingEqualToShipping,
                fname: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.firstName
                    : this.memberService.memberModel.shippingAddress.firstName,
                lname: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.lastName
                    : this.memberService.memberModel.shippingAddress.lastName,
                email: !this._orderModel.billingEqualToShipping
                    ? this.memberService.memberModel.billingAddress.email
                    : this._orderModel.creditCard.email,
            };
        }
        return this.authHttp.post<any>(this.urlHelper.toCartApi('/v2/order/payment/creditcard'), model);
    }

    /**
     * Sets a saved Credit Card to be used on an order
     * @param sequencePk Credit Card Sequence Pk to use for order
     */
    public useSavedCreditCard(sequencePk: number): Observable<any> {
        const model: any = {
            sequenceFk: sequencePk,
            mainOrdersFk: this._orderModel.mainOrdersFk,
        };
        return this.authHttp.post<any>(this.urlHelper.toCartApi('v1/order/payment/set-card'), model);
    }

    /**
     * Sets a saved Credit Card to be used on an order
     * @param sequencePk Credit Card Sequence Pk to use for order
     */
    public getSavedCreditCard(sequencePk: number): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`v1/order/payment/get-card/${sequencePk}`));
    }

    /**
     * Returns the amount of tax for the order
     */
    public getOrderTaxes(orderNumber?: number): Observable<number> {
        return this.authHttp.get<number>(this.urlHelper.toCartApi(`/v2/order/taxes/${this._orderModel.mainOrdersFk || orderNumber}`));
    }

    /**
     * Returns the amount of tax for the order
     */
    public setOrderTaxes(orderNumber?: number): Observable<number> {
        return this.authHttp.put<number>(this.urlHelper.toCartApi(`/v1/order/taxes/${this._orderModel.mainOrdersFk || orderNumber}`), null);
    }

    /**
     * Generates a TID for the order
     */
    public generateTid(): void {
        const now: Date = new Date();
        this._orderModel.tid =
            this._orderModel.mainOrdersFk +
            '-' +
            now.getFullYear() +
            now.getMonth() +
            now.getDate() +
            now.getHours() +
            now.getMinutes() +
            now.getSeconds();
    }

    /**
     * Gets the 3DsUrl for the order
     */
    public get3DsUrl(model: Get3DSUrlModel): Observable<any> {
        model.source = 'shop';
        return this.authHttp.post<any>(this.urlHelper.toCartApi('/v1/order/3dsurl'), model);
    }

    /**
     * Gets the 3DsUrl for card token
     */
    public get3DSTokenUrl(model: Get3DSTokenUrlModel): Observable<GatewayResponse> {
        model.source = 'shop';
        return this.authHttp.post<GatewayResponse>(this.urlHelper.toCartApi('/v1/order/3dstokenurl'), model);
    }

    /**
     * Gets the 3DsUrl for the order
     */
    public get3DS2(model: Get3DS2Model): Observable<any> {
        model.source = 'shop';
        return this.authHttp.post<any>(this.urlHelper.toCartApi('/v1/order/3ds2'), model);
    }

    /**
     * Submits an order for final processing
     */
    public submitOrder(): Observable<any> {
        const formModel: any = {
            mainOrderPk: this._orderModel.mainOrdersFk,
            securityCode: this._orderModel.creditCard ? this._orderModel.creditCard.cvv : null,
            inAuthId: this._orderModel.inAuthId,
            paymentType: 1,
        };
        return this.authHttp.post<any>(this.urlHelper.toCartApi('/v1/order/complete'), formModel);
    }

    /**
     * Checks to see if an order has a package
     * @param mainOrdersFk Order number
     */
    public hasPackage(mainOrdersFk): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/${mainOrdersFk}/has-package`));
    }

    /**
     * Returns the order details
     * @param mainOrdersFk Main Order Number
     */
    public getOrderDetails(mainOrdersFk: any): Observable<any> {
        if (!mainOrdersFk) {
            return of({});
        }
        if (!this.authService.isLoggedIn) {
            return of({});
        }
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`/v1/order/${mainOrdersFk}/confirmation-details`)).pipe(
            map((confirmationInfo: any) => {
                if (!this._orderModel.shippingMethod) {
                    const model: ShippingOptionQuery = {
                        cartKey: '',
                        orderNumber: confirmationInfo.mainOrderPK,
                        state: confirmationInfo.shipState,
                        postalCode: confirmationInfo.shipZip,
                        country: confirmationInfo.shipCountry,
                        city: confirmationInfo.shipCity,
                    };
                    this.subs.sink = this.getShippingOptions(model).subscribe((options) => {
                        this._orderModel.shippingMethod = options.methods.find(
                            (x) => x.shippingChartType === confirmationInfo.shippingTypeFK
                        );
                        // this.setOrderModel(this._orderModel);
                    });
                }
                this._orderModel.mainOrdersFk = confirmationInfo.mainOrderPK;
                this._orderModel.encOrderNumber = confirmationInfo.encOrderNumber;
                this._orderModel.mainPk = confirmationInfo.mainPk;
                this._orderModel.mainTypeFk = confirmationInfo.mainTypeFK;
                this._orderModel.mainOrderTypeFk = confirmationInfo.mainOrderTypeFK;
                this._orderModel.accountCreated = confirmationInfo.accountCreated;
                this._orderModel.shippingTypeFk = confirmationInfo.shippingTypeFK;
                this._orderModel.hasPromo = confirmationInfo.hasPromo;
                this._orderModel.paymentType = confirmationInfo.paymentType;
                this._orderModel.ruCode = confirmationInfo.rUCode;
                this._orderModel.offerLoyaltyProgram = confirmationInfo.offerLoyaltyProgram;
                this._orderModel.shipState = confirmationInfo.shipState;
                this._orderModel.shipZip = confirmationInfo.shipZip;
                this._orderModel.shipCity = confirmationInfo.shipCity;
                this._orderModel.shipAddress3 = confirmationInfo.shipAddress3;
                this._orderModel.shipAddress2 = confirmationInfo.shipAddress2;
                this._orderModel.shipAddress = confirmationInfo.shipAddress;
                this._orderModel.shipLastName = confirmationInfo.shipLastName;
                this._orderModel.shipFirstName = confirmationInfo.shipFirstName;
                this._orderModel.shipCountry = confirmationInfo.shipCountry;

                this._orderModel.billingState = confirmationInfo.billingState;
                this._orderModel.billingZip = confirmationInfo.billingZip;
                this._orderModel.billingCity = confirmationInfo.billingCity;
                this._orderModel.billingAddress3 = confirmationInfo.billingAddress3;
                this._orderModel.billingAddress2 = confirmationInfo.billingAddress2;
                this._orderModel.billingAddress = confirmationInfo.billingAddress;
                this._orderModel.billingLastName = confirmationInfo.billingLastName;
                this._orderModel.billingFirstName = confirmationInfo.billingFirstName;
                this._orderModel.billingCountry = confirmationInfo.billingCountry;

                this._orderModel.formattedDiscount = confirmationInfo.formattedDiscount;
                this._orderModel.formattedShipping = confirmationInfo.formattedShipping;
                this._orderModel.formattedSubTotal = confirmationInfo.formattedSubTotal;
                this._orderModel.formattedTaxes = confirmationInfo.formattedTaxes;
                this._orderModel.formattedShippingTax = confirmationInfo.formattedShippingTax;
                this._orderModel.formattedProductTax = confirmationInfo.formattedProductTax;
                this._orderModel.formattedAdditionalTax = confirmationInfo.formattedAdditionalTax;  //#44233
                this._orderModel.formattedOrderProcessingFees = confirmationInfo.formattedOrderProcessingFees;
                this._orderModel.formattedTotal = confirmationInfo.formattedTotal;
                this._orderModel.discount = confirmationInfo.discount;
                this._orderModel.shipping = confirmationInfo.shipping;
                this._orderModel.taxes = confirmationInfo.taxes;
                this._orderModel.productTax = confirmationInfo.productTax;
                this._orderModel.shippingTax = confirmationInfo.shippingTax;
                this._orderModel.additionalTax = confirmationInfo.additionalTax;  //#44233
                this._orderModel.taxLabel = confirmationInfo.taxLabel;
                this._orderModel.subTotal = confirmationInfo.subTotal;
                this._orderModel.total = confirmationInfo.total;
                this._orderModel.usdTotal = confirmationInfo.usdTotal;
                this._orderModel.currencyCode = confirmationInfo.currencyCode;
                this._orderModel.currencySymbol = confirmationInfo.currencySymbol;
                this._orderModel.currencyRate = confirmationInfo.currencyRate;
                this._orderModel.countryCode = confirmationInfo.countryCode;
                this._orderModel.ccType = confirmationInfo.ccType;
                this._orderModel.ccNumberLastFour = confirmationInfo.ccNumberLastFour;
                this._orderModel.items = confirmationInfo.items;
                this._orderModel.payments = confirmationInfo.payments;
                this._orderModel.paymentStatus = confirmationInfo.paymentStatus;
                this._orderModel.email = confirmationInfo.email;
                this._orderModel.phoneNumber = confirmationInfo.phoneNumber;
                this._orderModel.paidStatus = confirmationInfo.paidStatus;
                this._orderModel.paidStatusFk = confirmationInfo.paidStatusFk;
                this._orderModel.isRetailSignup = confirmationInfo.retailSignup;
                this._orderModel.retailStarterKit = confirmationInfo.retailStarterKit;
                this._orderModel.eventTitle = confirmationInfo.eventTitle;
                this._orderModel.isVolumeBasedRSB = confirmationInfo.isVolumeBasedRSB;
                this._orderModel.shippingInstructions = confirmationInfo.shippingInstructions;
                this._orderModel.deliveryDate = confirmationInfo.deliveryDate;
                this._orderModel.deliverySlot = confirmationInfo.deliverySlot;
                this._orderModel.showFISCALink = confirmationInfo.showFISCALink;
                this._orderModel.showMicroEntrFISCALink = confirmationInfo.showMicroEntrFISCALink;
                this._orderModel.isImportOrder = confirmationInfo.isImportOrder;

                // KLK - Set lock page if order has a payment started or finished.
                this._orderModel.lockPage = confirmationInfo.paidStatus || confirmationInfo.paidStatusFk > 0;
                this.setOrderModel(this._orderModel);
                return confirmationInfo;
            })
        );
    }

    /**
     *Returns ipAddress and cloudflare countryCode
     * */
    public getIpCountryCode(): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCmsApi('/v1/application/user-ip'));
    }

    /**
     * Returns order Details
     */
    public getOrderDetailCreateAndPay(orderNumber: string): Observable<any> {
        var params = {
            orderNumber: orderNumber,
            shouldCheckIfPrepaid: 'true',
        };
        return this.authHttp.get<any>(this.urlHelper.toCartApi('/v1/orders/'), { params: params });
    }

    /**
     * Get Paypal redirect Address
     */
    public getPaypalRedirectUrl(url: string, total: number): Observable<any> {
        var params = {
            urlString: url,
            amount: total,
        };
        return this.authHttp.post<any>(this.urlHelper.toCartApi('/v1/order/paypal/get-process-url'), params);
    }

    /**
     * Get Paypal Order Response
     */
    public processMerchantOrderResponse(url: string): Observable<any> {
        const params = new OrderResponseModel({
            queryString: url,
            inAuthId: this.webStorageService.getCookie('inAuthId')
        });
        return this.authHttp.post<any>(this.urlHelper.toCartApi('/v1/order/merchant/process-response'), params);
    }

    /**
     * Get Paypal cancel response
     */
    public getCancelMerchantOrderResponse(url: string): Observable<any> {
        var params = {
            queryString: url,
        };
        return this.authHttp.post<any>(this.urlHelper.toCartApi('/v1/order/merchant/cancel'), params);
    }

    /**
     * Validate Credit Card Number
     */
    public validateCreditCardNumber(ccNumber: string, ccType: string): Observable<any> {
        var params = {
            cCNumber: ccNumber,
            cCType: ccType,
        };
        return this.authHttp.post<any>(this.urlHelper.toCartApi('/v1/order/creditcard/validate'), params);
    }

    /**
     * Get InAuth Configuration
     */
    public getInAuthConfig(): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCartApi('/v1/order/get-inauth-config'));
    }

    /**
     * Get Sift Configuration
     */
    public getSiftConfig(): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCartApi('/v1/order/get-sift-config'));
    }

    /**
     * Get BraspagAntifraud scripts
     */
    public getBraspagAntifraudScripts(identifier: string): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`/v1/order/get-braspagantifraud-scripts/${identifier}`));
    }

    /**
     * Update split remaining total
     */
    public updateSplitRemainingOrder(value: number) {
        this.splitOrderTotalSubject.next(value);
    }

    /**
     * Checks if the order configuration, products, etc. are valid.
     * @param orderNumber Main Orders PK
     */
    public validateOrder(orderNumber: number): Observable<ValidateOrderResult> {
        if (!orderNumber) {
            return of({ count: 0, messages: [] });
        }
        return this.authHttp.post<ValidateOrderResult>(this.urlHelper.toCartApi(`/v1/orders/${orderNumber}/validate`));
    }

    public GetWalletDiscount(model: WalletDiscountQuery): Observable<any> {
        const params: any = {
            mainOrdersFk: model.mainOrdersFK,
            countryCode: model.countryCode,
        };
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`/v1/order/wallet/discount/`), { params: params });
    }

    public getBonusTokenOrderLimit(orderNumber: number): Observable<BonusTokenOrderLimit> {
        return this.authHttp.get<BonusTokenOrderLimit>(this.urlHelper.toCartApi(`/v1/order/getBonusTokenOrderLimit/${orderNumber}`));
    }

    public getWalletOrderLimit(orderNumber: number): Observable<WalletOrderLimit> {
        return this.authHttp.get<WalletOrderLimit>(this.urlHelper.toCartApi(`/v1/order/getWalletOrderLimit/${orderNumber}`));
    }

    public getPaymentOrderProcessingFees(model: any): Observable<OrderProcessingFeesModel> {
        const params: any = {
            culture: model.culture,
            countryCode: model.countryCode,
        };
        return this.authHttp.get<OrderProcessingFeesModel>(this.urlHelper.toCartApi(`/v1/order/processingFees/`), { params: params });
    }

    public addProcessingFees(model: AddProcessingFeesModel): Observable<number> {
        return this.authHttp.post<number>(this.urlHelper.toCartApi(`/v1/order/addProcessingFees/`), model);
    }

    public clearMerchantPendingStatus(orderNumber): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/${orderNumber}/clearMerchantPendingStatus`));
    }

    public isQRCodeOrderStatusUpdated(orderNumber: number, code: string, reference: string): Observable<boolean> {
        const params: any = {
            mainOrdersFK: orderNumber,
            paymentMethodCode: code,
            paymentReference: reference,
        };
        return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/isQRCodeOrderStatusUpdated/`), { params: params });
    }

    public formatCurrency(value: number, currencyCode: string): Observable<FormatCurrency> {
        let query = JSON.stringify({ value: value, currencyCode: currencyCode });
        if (!this._formattedCurrencyCache$.get(query)) {
            this._formattedCurrencyCache$.set(
                query,
                this.authHttp
                    .post<FormatCurrency>(this.urlHelper.toCmsApi(`/v1/format/currency`), { value, currencyCode })
                    .pipe(shareReplay())
            );
        }
        return this._formattedCurrencyCache$.get(query);
    }

    public formatCurrencyNumber(value: number, currencyCode: string): Observable<number> {
        if (!currencyCode) {
            return of(value);
        }
        return this.authHttp.post<any>(this.urlHelper.toCmsApi(`/v1/format/currency-number`), { value, currencyCode }).pipe(
            map((resp) => {
                return resp ? resp.formattedValue : '';
            })
        );
    }

    public formatCurrencyNumberWithRegex(value: number, currencyCode: string, showRegex: boolean): Observable<CurrencyNumberModel> {
        if (!currencyCode) {
            return of(new CurrencyNumberModel({ value: value, formattedValue: value, currencyCode: currencyCode, showRegex: showRegex }));
        }
        return this.authHttp.post<CurrencyNumberModel>(this.urlHelper.toCmsApi(`/v1/format/currency-number`), {
            value,
            currencyCode,
            showRegex,
        });
    }

    // public checkForPOBox(address1: string): boolean {
    //     const regexPattern = RegExp(/\b(?:p\.?o\.?|post|postal\s+office)(\s+)?(?:box|[0-9]*)?\b/i);
    //     // const regex = RegExp(/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)?\b/i);
    //     if (this._orderModel.countryCode === 'CA') {
    //         let canadaRegex = RegExp(/\b(?:c\.?p\.?|box|canada|canadian\s+post|postal)(\s+)?(?:box|[0-9]*)?\b/i);
    //         return canadaRegex.test(address1) || regexPattern.test(address1) ? true : false;
    //     }
    //     return regexPattern.test(address1);
    // }

    /**
     * Use to check if a retail customer has purchased an upgrade to distributor package and can continue to signup.
     */
    public validateRetailSignup(mainFk: number): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`/v2/order/validate-retail-signup?mainFK=${mainFk}`));
    }

    /**
     * Used to apply main discount config if applicable to order
     */
    public applyIterativeDiscountCode(): Observable<void> {
        return this.authHttp
            .post<void>(this.urlHelper.toCartApi(`/v2/order/apply-iterative-discount`), { mainOrdersFk: this._orderModel.mainOrdersFk })
            .pipe();
    }
    /**
     * Check to see if the country has CrossBorderCharges.
     * @param shipTo
     * @param shipFrom
     */
    public checkForCrossBorderCharge(shipTo: string, shipFrom: string) {
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`v1/order/useCrossBorderCharges/${shipTo}/${shipFrom}`));
    }

    /**
     * Used to set/update the personal use model in the service and send out updates through the Observable
     * @param model PersonalUse
     */
    public setPersonalUsePromptDisplay(model: PersonalUse): void {
        this.personalUse = model;
        this.showPersonalUseSubject$.next(model);
    }

    /**
     * Used to set the isPersonalUseOnly flag on the PersonalUse model in the service and send out updates through the Observalbe
     * @param isPersonalUseOnly boolean
     */
    public setPersonalUseFlag(isPersonalUseOnly: boolean): void {
        this.setPersonalUsePromptDisplay({
            ...this.personalUse,
            isPersonalUseOnly,
            isValid: true,
        });
    }

    /**
     * Update DB noPersonalUseOnly
     *
     * @author Alex Togo
     * @date 2019-08-15
     * @param number mainOrdersPK
     * @param boolean noPersonalUseOnly
     * @returns Observable<PersonalUseOnlyModel>
     * @memberof OrderService
     */
    public updatePersonalUseOnly(noPersonalUseOnly: boolean): Observable<void> {
        return this.authHttp
            .post<void>(
                this.urlHelper.toCartApi(
                    `/v1/orders/${this._orderModel.mainOrdersFk || this._orderModel.encOrderNumber}/update-personal-use`
                ),
                { noPersonalUseOnly }
            )
            .pipe();
    }

    public checkIsPersonalUse(cartKey?: string, mainOrdersFk?: number): Observable<void> {
        cartKey = cartKey || '';
        mainOrdersFk = mainOrdersFk || 0;
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`/v1/order/personal-use?cartKey=${cartKey}&mainOrdersPK=${mainOrdersFk}`))
            .pipe(map((resp: PersonalUse) => {
                this.setPersonalUsePromptDisplay(resp);
            }))
    }

    private isSignup(): boolean {
        try {
            if (this.webStorageService.getCookie('signupKey')) {
                return true;
            }
        } catch (e) {}
        return false;
    }

    /**
     * Get country document lists
     */
    public getDocumentsbyCountryLanguage(usage: string): Observable<DocumentsModel[]> {
        return this.authHttp
            .get<any>(
                this.urlHelper.toStorageApi(
                    `/v1/documents/docs-by-lang-country?country=${this.authService.getCountry()}&languageFk=${this.authService.getLangId()}&usage=${usage}`
                )
            )
            .pipe(
                map((data: any) => {
                    return data.map((data: any) => {
                        return new DocumentsModel(data);
                    });
                })
            );
    }

    public getCheckoutMessages(query: CheckoutMessageQuery): Observable<CheckoutMessage[]> {
        const params: any = {
            ...query,
        };
        return this.authHttp.get<CheckoutMessage[]>(this.urlHelper.toCartApi('/v1/shopping/get-checkout-messages'), { params });
    }

    public getInstallments(countryCode: string, creditCardType: string, mainOrderTypeFK: number): Observable<MerchantInstallmentConfigModel> {
        return this.authHttp.post<MerchantInstallmentConfigModel>(
            this.urlHelper.toCartApi('/v1/order/getInstallments'),
            {
                countryCode,
                creditCardType,
                mainOrderTypeFK,
            });
    }

    public initInstallments(formModel: MerchantInstallmentFormModel): Observable<MerchantInstallmentConfigModel> {
        return this.authHttp.post<MerchantInstallmentConfigModel>(this.urlHelper.toCartApi('/v1/order/initInstallments'), formModel);
    }

    public isInstallmentExtensionValid(formModel: MerchantInstallmentFormModel): Observable<boolean> {
        return this.authHttp.post<boolean>(this.urlHelper.toCartApi('/v1/order/isInstallmentsExtensionValid'), formModel);
    }

    public SaveInstallments(formModel: MerchantInstallmentFormModel): Observable<void> {
        return this.authHttp.post<void>(this.urlHelper.toCartApi('/v1/order/saveInstallments'), formModel);
    }

    public getMinimumAllowedPayment(): Observable<number> {
        return this.authHttp.get<number>(this.urlHelper.toCartApi(`/v1/order/getMinimumAllowedPayment/${this._orderModel.mainOrdersFk}`));
    }

    public getPayUReceiptLink(): Observable<string> {
        return this.authHttp.get<string>(this.urlHelper.toCartApi(`/v1/order/getPayUReceiptLink/${this._orderModel.mainOrdersFk}`));
    }

    public getPayUBankTransferDetails(): Observable<any> {
        return this.authHttp.get<any>(this.urlHelper.toCartApi(`/v1/order/getPayUBankTransferDetails/${this._orderModel.mainOrdersFk}`));
    }

    public isMercadoPagoOrder(mainOrderTypeFk: number, countryCode: string): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/isMercadoPagoOrder/${mainOrderTypeFk}/${countryCode}`));
    }

    public isLacoreFraudSettings(mainOrderTypeFk: number, countryCode: string, currencyCode: string): Observable<string> {
        return this.authHttp.get<string>(this.urlHelper.toCartApi(`/v1/order/isLacoreFraudSettings/${mainOrderTypeFk}/${countryCode}/${currencyCode}`));
    }

    public isNiceGtwEnabled(mainOrderTypeFk: number): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/isNiceGtwEnabled/${mainOrderTypeFk}`));
    }

    public isRoutedToZiraat(mainOrderTypeFk: number, countryCode: string): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/isRoutedToZiraat/${mainOrderTypeFk}/${countryCode}`));
    }
}
export enum TargetConfigEnum {
    SignupOrder,
}
