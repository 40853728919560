/**
 * Best Seller model
 */
export class BestSellerModel {
    public productPK: number;
    public price: string;
    public name: string;
    public description: string;
    public imageUrl: string;
}
