/**
 * Model to send items for update.
 */
export class SmartDeliveryItemFormModel {
    public quantity: number;
    public productFk: number;
    public selectedConfig?: SmartDeliveryItemSelectedConfigFormModel[];
    public agreedToTerms: boolean;
    public isImportItem?: boolean;

    constructor(options: any = {}) {
        Object.assign(this, options);
    }
}

export class SmartDeliveryItemSelectedConfigFormModel {
    public productFk: number;
    public configFk: number;
    constructor(options: any = {}) {
        Object.assign(this, options);
    }
}
