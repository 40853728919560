/**
 * The model to hold the translation info.
 */
export class TranslationViewModel {
    public id: number;
    public state: string;
    public name: string;
    public content: string;
    public liveContent: string;
    public cssClasses: string;
    public culture: string;
    public isSession: boolean;
    public dateModified: Date | null;
    public modules: string[];

    constructor(options: any = {}) {
        this.id = options.id;
        this.state = options.state;
        this.name = options.name;
        this.content = options.content;
        this.liveContent = options.liveContent;
        this.cssClasses = options.cssClasses;
        this.culture = options.culture;
        this.isSession = options.isSession;
        this.dateModified = options.dateModified;
        this.modules = options.modules;
    }
}