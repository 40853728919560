import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, Params } from '@angular/router';
import { ProductService, ProductModel, ProductQueryModel, SimpleProductComponent } from '@jeunesse/cart-lib';
import { LocaleService, AuthService, TranslationService } from '@jeunesse/angular/cms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SnackBarService } from '../../shared/snack-bar/snack-bar.service';
import { MainCartService, MainCartStateService } from '@jeunesse/slide-cart/main-cart-service';
import { SubSink } from 'subsink';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Direction } from '@angular/cdk/bidi';


@Component({
    selector: 'app-create-a-pack',
    templateUrl: './create-a-pack.component.html',
    styleUrls: ['./create-a-pack.component.scss']
})
export class CreateAPackComponent implements OnInit, OnDestroy {
    @Input() public addToCart$: Observable<boolean>;
    @Input() public smartDeliveryInfo: any;
    public canAddToCart: boolean = true;
    public products: ProductModel[];
    public product: ProductModel;
    public sortBy: string;
    public sortOptions: string;
    public cartItems: any[] = [];

    public submitting: boolean = false;
    public cartType: string;
    public allProducts: ProductModel[];
    public filteredProducts: ProductModel[];
    public filters: string = '';
    public lengthOfPaginator: number = 0;
    public pageIndex: number = 0;
    public previousPageIndex: number;
    public fromPage = 0;
    public toPage = 10;
    public displayProduct: ProductModel [] = [];
    public tInitialParams: Params;
    public defaultProduct: ProductModel [] = [];

    private directionality: Direction = 'ltr';
    private subs = new SubSink();
    private translations: any = {
        maxQuantity: 'Max QTY is 25.',
        minCV: 'Minimum CV must be 100',
        notAllowed: 'Sorry, create a pack is not allowed for your current settings!',
    };

    constructor(private activatedRoute: ActivatedRoute,
        protected mainCartService: MainCartService,
        protected mainCartStateService: MainCartStateService,
        protected productService: ProductService,
        protected localeService: LocaleService,
        private readonly dialog: MatDialog,
        private readonly snackBarService: SnackBarService,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly translationService: TranslationService
    ) { }

    public ngOnInit(): void {
        this.setTranslations();
        this.tInitialParams = this.activatedRoute.snapshot.queryParams;
        this.paramParser(this.tInitialParams);
        this.subs.sink = this.mainCartService.getMainCart$().subscribe((cart) => {
            this.cartType = cart.cartType;
            this.cartItems = cart.cartItems;

            if (this.authService.isLoggedIn && [1,13].includes(this.authService.getMainTypeId()) && this.cartType === 'W') {
                const qry: ProductQueryModel = {
                    cartType: this.cartType,
                    countryCode: this.localeService.getCountryCode(),
                    categoryId: null,
                    eventId: null,
                    culture: this.localeService.getLocaleCookie(),
                    brandId: null,
                    take: null,
                    skip: 0,
                    searchTerm: '',
                    includePromos: false,
                    isRedemption: this.mainCartService.isStoreInRedemptionMode,
                    isCreateAPack: true,
                    menuId: 0
                };
                this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                        m: '53'
                    },
                    queryParamsHandling: 'merge'
                });
                this.loadProducts(qry);
            } else {
                // Clear cart and redirect
                this.snackBarService.warn(this.translations.notAllowed);
                this.router.navigate([cart.referrerSiteUrl, 'catalog', 'products']);
            }
        });
        this.subs.sink = this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);

        /*
            The functions this file will perform will be to query/filter/sort the product service for a
            list of create-a-pack products and pass them to the component in the cart-lib library.
            Update/Delete/Add calls as well
        */
        // Make call to get products and set local products variable
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public loadProducts(query): void {
        this.subs.sink = this.productService.products(query).subscribe((res => {
            this.defaultProduct = res.slice();
            this.products = res;
            // all products loaded
            this.allProducts = res;
            // filtered with initial query
            this.filterByMenuId(this.filters.split(','));
            // sorted
            if (this.sortBy && this.sortBy.length > 0) {
                this.sortChange(this.sortBy);
            }
            // After product loaded we subscribe to query params changes, so we can filter product
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                if (params.has('sort')) {
                    this.sortChange(params.get('sort'));
                }
                const query: string = params.has('m') ? params.get('m') : '';
                this.filterByMenuId(query.split(','));
            });
        }));
    }
    public paramParser(params: Params): void {
        this.sortBy = params['sort'] ? params['sort'] : 'asc';
        this.filters = params['m'] ? params['m'] : '';
}

    /**
     * Sorts the list of products displayed.
     * @param sortBy Option to sort by
     */
    public sortChange(sortBy: string): void {
        const isRedemption: boolean = this.mainCartService.isStoreInRedemptionMode;
        switch (sortBy) {
            case 'default':
                this.allProducts = this.defaultProduct.slice();
                break;
            case 'low':
                this.allProducts = this.allProducts.sort((a, b) => a.pricing[isRedemption ? 2 : 0].price - b.pricing[isRedemption ? 2 : 0].price);
                break;
            case 'high':
                this.allProducts = this.allProducts.sort((a, b) => b.pricing[isRedemption ? 2 : 0].price - a.pricing[isRedemption ? 2 : 0].price);
                break;
            case 'asc':
                this.allProducts = this.allProducts.sort((a, b) => {
                    if (a.name && b.name) {
                        const nameA: string = a.name.toLowerCase(),
                            nameB: string = b.name.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                    }
                    return 0;
                });
                break;
            case 'desc':
                this.allProducts = this.allProducts.sort((a, b) => {
                    if (a.name && b.name) {
                        const nameA: string = a.name.toLowerCase(),
                            nameB: string = b.name.toLowerCase();
                        if (nameA > nameB) return -1;
                        if (nameA < nameB) return 1;
                    }
                    return 0;
                });
                break;
            case 'smartDelivery':
                this.allProducts = this.allProducts.filter((product) => { return product.isProductAvailableOnAutoship && this.authService.isLoggedIn; });
                break;
            default:
                this.allProducts = this.allProducts.sort((a, b) => {
                    if (a.name && b.name) {
                        const nameA: string = a.name.toLowerCase(),
                            nameB: string = b.name.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                    }
                    return 0;
                });
                break;
          }
    }

    public addToCart(capItems: ProductModel[]): void {
        let cvTotal = 0;
        if (capItems && capItems.length > 0) {
            let items: any[] = new Array();
            capItems.forEach(cartItem => {
                cvTotal += cartItem.quantity * cartItem.bv;
                items.push({
                    'quantity': cartItem.quantity,
                    'setupForAs': false,
                    'productFk': cartItem.selectedConfig ? cartItem.selectedConfig[0].productPK : cartItem.productPK,
                    'packageFk': 0,
                    'configFk': 0,
                    'isAddOnItem': false,
                    'isPaCItem': true
                });
            });
            if (cvTotal >= 100) {
                this.mainCartService.addCartItems(items, true);
                this.subs.sink = this.mainCartStateService.isProductAdded.pipe(
                    take(1)
                  ).subscribe((res) => {
                        this.submitting = false;
                        let paycarditem: any = this.cartItems.filter(x => x.product.brandName === 'Paycard')[0];
                        if (paycarditem) {
                            const dialogConfig: MatDialogConfig<any> = {
                                hasBackdrop: true,
                                disableClose: true,
                                data: {
                                    product: Object.assign({}, paycarditem.product)
                                },
                                direction: this.directionality
                            };
                            this.dialog.open(SimpleProductComponent, dialogConfig);
                        }
                });

            } else {
                this.submitting = false;
                this.snackBarService.warn(this.translations.minCV);
            }
        }
    }

    public filterByMenuId(menuIdArray: string []): void {
        if ((menuIdArray[0] === '' && menuIdArray.length === 1) || (menuIdArray[0] === '53' && menuIdArray.length === 1)) {
            this.filteredProducts = this.allProducts;
         } else {
            this.filteredProducts = this.allProducts.filter(
                product => menuIdArray.includes(product.productMenuId.toString())
                );
        }
        this.lengthOfPaginator = this.filteredProducts.length;
        this.pageIndex = 0;
        this.pagination({previousPageIndex: 0, pageIndex: 0, pageSize: 10, length: this.lengthOfPaginator});
    }

    public pagination(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.previousPageIndex = event.previousPageIndex;
        this.fromPage = (event.pageIndex) * event.pageSize;
        this.toPage = (event.pageIndex + 1) * event.pageSize;
        this.displayProduct = this.filteredProducts.slice(this.fromPage, this.toPage);
    }

    public updateItem(product): void {
        if (product.quantity > 25) {
            this.snackBarService.warn(this.translations.maxQuantity);
        }
    }

    private setTranslations(): void {
        // Load Translations
        this.translations = {
            maxQuantity: this.translationService.translate('shop', 'max-quantity', 'Max QTY is 25.'),
            minCV: this.translationService.translate('shop', 'min-cv', 'Minimum CV must be 100'),
            notAllowed: this.translationService.translate('shop', 'not-allowed', 'Sorry, create a pack is not allowed for your current settings!'),
        };
    }
}
