import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SettingsService } from '../../common/src/settings.service';
import { UrlOptions } from './models/public-api';
import { ApiNameEnum } from './enums/public-api';

/**
 * This is a helper class for Urls.
 */
@Injectable()
export class UrlHelperService {
    private readonly apiNameEnum: typeof ApiNameEnum = ApiNameEnum;

    constructor(private readonly settings: SettingsService) { }

    /**
     * This create a full Url to the Cart Api.
     * @param path This is a path or UrlOptions object.
     */
    public toCartApi(path: string | UrlOptions): string {
        if (!this.settings.cartApi) {
            throw new Error('Missing: AppSettings.cartApi');
        }

        return this.buildUrl(this.settings.cartApi, path);
     }

    /**
     * This create a full Url to the Cms Api.
     * @param path This is a path or UrlOptions object.
     */
    public toCmsApi(path: string | UrlOptions): string {
        if (!this.settings.cmsApi) {
            throw new Error('Missing: AppSettings.cmsApi');
        }

        return this.buildUrl(this.settings.cmsApi, path);
     }

    /**
     * This create a full Url to the Crm Api.
     * @param path This is a path or UrlOptions object.
     */
    public toCrmApi(path: string | UrlOptions): string {
        if (!this.settings.crmApi) {
            throw new Error('Missing: AppSettings.crmApi');
        }

        return this.buildUrl(this.settings.crmApi, path);
     }

    /**
     * This create a full Url to the Event Api.
     * @param path This is a path or UrlOptions object.
     */
    public toEventApi(path: string | UrlOptions): string {
        if (!this.settings.eventApi) {
            throw new Error('Missing: AppSettings.eventApi');
        }

        return this.buildUrl(this.settings.eventApi, path);
     }

    /**
     * This create a full Url to the HelpDesk Api.
     * @param path This is a path or UrlOptions object.
     */
    public toHelpDeskApi(path: string | UrlOptions): string {
        if (!this.settings.helpDeskApi) {
            throw new Error('Missing: AppSettings.helpDeskApi');
        }

        return this.buildUrl(this.settings.helpDeskApi, path);
     }

     /**
      * This create a full Url to the Storage Api.
      * @param path This is a path or UrlOptions object.
      */
     public toStorageApi(path: string | UrlOptions): string {
          if (!this.settings.storageApi) {
                throw new Error('Missing: AppSettings.storageApi');
          }
          return this.buildUrl(this.settings.storageApi, path);
     }

    /**
     * This create a full Url to the Profile Api.
     * @param path This is a path or UrlOptions object.
     */
    public toProfileApi(path: string | UrlOptions): string {
        if (!this.settings.profileApi) {
            throw new Error('Missing: AppSettings.profileApi');
        }

        return this.buildUrl(this.settings.profileApi, path);
     }

    /**
     * This create a full Url to the Shared Api.
     * @param path This is a path or UrlOptions object.
     */
    public toSharedApi(path: string | UrlOptions): string {
        if (!this.settings.sharedApi) {
            throw new Error('Missing: AppSettings.sharedApi');
        }

        return this.buildUrl(this.settings.sharedApi, path);
     }

    /**
     * This create a full Url to the EasyMatch Api.
     * @param path This is a path or UrlOptions object.
     */
    public toEasyMatchApi(path: string | UrlOptions): string {
        if (!this.settings.easyMatchApi) {
            throw new Error('Missing: AppSettings.easyMatchApi');
        }

        return this.buildUrl(this.settings.easyMatchApi, path);
     }

    /**
     * This create a full Url to the JFront Api.
     * @param path This is a path or UrlOptions object.
     */
    public toJFrontApi(path: string | UrlOptions): string {
        if (!this.settings.jFrontApi) {
            throw new Error('Missing: AppSettings.jFrontApi');
        }

        return this.buildUrl(this.settings.jFrontApi, path);
     }

    /**
     * This create a full Url to the Security Api.
     * @param path This is a path or UrlOptions object.
     */
    public toSecurityApi(path: string | UrlOptions): string {
        if (!this.settings.securityApi) {
            throw new Error('Missing: AppSettings.securityApi');
        }

        return this.buildUrl(this.settings.securityApi, path);
    }

    /**
     * This create a full Url to the LiveChat Api.
     * @param path This is a path or UrlOptions object.
     */
    public toLiveChatApi(path: string | UrlOptions): string {
        if (!this.settings.liveChatApi) {
            throw new Error('Missing: AppSettings.liveChatApi');
        }

        return this.buildUrl(this.settings.liveChatApi, path);
    }

    /**
     * This create a full Url to the Reports Api.
     * @param path This is a path or UrlOptions object.
     */
    public toReportsApi(path: string | UrlOptions): string {
        if (!this.settings.reportsApi) {
            throw new Error('Missing: AppSettings.reportsApi');
        }

        return this.buildUrl(this.settings.reportsApi, path);
    }

    public toApiByName(apiName: string, path: string | UrlOptions): string {
        switch (apiName) {
            case this.apiNameEnum.CART:
                return this.toCartApi(path);
            case this.apiNameEnum.CMS:
                return this.toCmsApi(path);
            case this.apiNameEnum.CRM:
                return this.toCrmApi(path);
            case this.apiNameEnum.EVENT:
                return this.toEventApi(path);
            case this.apiNameEnum.HELP_DESK:
                return this.toHelpDeskApi(path);
            case this.apiNameEnum.STORAGE:
                return this.toStorageApi(path);
            case this.apiNameEnum.PROFILE:
                return this.toProfileApi(path);
            case this.apiNameEnum.SHARED:
                return this.toSharedApi(path);
            case this.apiNameEnum.EASY_MATCH:
                return this.toEasyMatchApi(path);
            case this.apiNameEnum.JFRONT:
                return this.toJFrontApi(path);
            case this.apiNameEnum.SECURITY:
                return this.toSecurityApi(path);
            case this.apiNameEnum.LIVE_CHAT:
                return this.toLiveChatApi(path);
            case this.apiNameEnum.REPORTS:
                return this.toReportsApi(path);
            default:
                return;
        }
    }



    /**
     * Builds a full Url from different parts with the correct placement and slashes. This does not apply "encodeURI" to the path or values.
     * @param options This is path, queryParams and, hash values to be added to the base Url.
     */
    public buildUrl(options: UrlOptions): string;
    /**
     * @param url The base Url.
     * @param path Adds the path to the end of the base Url with the correct slashes.
     */
    public buildUrl(url: string, path: string): string;
    /**
     * @param url The base Url.
     * @param options This is the path, queryParams and, hash values to be added to the base Url.
     */
    public buildUrl(url: string, options: UrlOptions): string;
    /**
     * @ignore
     */
    public buildUrl(url: string, options: string | UrlOptions): string;
    /**
     * @ignore
     */
    public buildUrl(url: string | UrlOptions, options?: string | UrlOptions): string {
        let builtUrl: string;

        if (typeof (url) === 'object') {
            builtUrl = '';
            options = url;
        }
        else {
            builtUrl = url.replace(/\/$/, '');
        }

        if (typeof (options) === 'string') {
            options = {
                path: options
            };
        }

        if (options) {
            if (options.path) {
                if (options.path.indexOf('/') === 0) {
                    builtUrl += options.path;
                }
                else {
                    builtUrl += `/${options.path}`;
                }
            }

            if (options.queryParams) {
                const queryString: string[] = [];

                for (let key in options.queryParams) {
                    if (options.queryParams.hasOwnProperty(key)) {
                        queryString.push(key + '=' + options.queryParams[key]);
                    }
                }

                // TODO: See about adding this code to the logic.
                // const qIdx = url.indexOf('?');
                // There are 3 cases to handle:
                // 1) No existing parameters -> append '?' followed by params.
                // 2) '?' exists and is followed by existing query string ->
                //     append '&' followed by params.
                // 3) '?' exists at the end of the url -> append params directly.
                // This basically amounts to determining the character, if any, with
                // which to join the URL and parameters.
                // const sep: string = qIdx === -1 ? '?' : (qIdx < url.length - 1 ? '&' : '');

                builtUrl += `?${queryString.join('&')}`;
            }

            if (options.hash) {
                builtUrl += `#${options.hash}`;
            }
        }

        return builtUrl;
    }

    /**
     * This builds the template path of a route.
     * @param route The snapshot of the route the template path should be built from.
     */
    public toGenericPath(route: ActivatedRouteSnapshot): string {

        let url: string = '';

        route.pathFromRoot.forEach((x: ActivatedRouteSnapshot) => {
            if (x.routeConfig && x.routeConfig.path) {
                url += `/${x.routeConfig.path}`;
            }
        });

        return url;
    }
}
