export class UserTicketModel {
    public endDate?: Date | string;
    public firstName?: string;
    public hasGroup?: number;
    public hasMissingGroupInfo?: number;
    public lastName?: string;
    public name?: string;
    public primaryMemberID?: number;
    public eventMemberID?: number;
    public primarySiteURL?: string;
    public siteURL?: string;
    public startDate?: Date | string;
    public email?: string;
    public eventID?: number | string;
    public qRcodeEmailSendDaysPrior?: number;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
