import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';

import { OrderService } from '../../../checkout/services/order.service';
import {
    AuthHttpService,
    AuthService,
    CmsSessionService,
    LocaleService,
    SeoService,
    TranslationService,
    UrlHelperService,
} from '@jeunesse/angular';
import { SubSink } from 'subsink';
import { CreditCardTypeModel, OrderModel } from '../../../checkout/public-api';
import { NumberOnly } from '../../../shared/pipe';
import { PaymentViewModel } from '../../../../../../../apps/joffice/src/app/+account/models';
import { MatRadioButton } from '@angular/material/radio';
import { AccountService } from '../../../../../../../apps/joffice/src/app/+account/account-service';
import { SmartDeliveryModel } from '../../../member/models/smart-delivery.model';
import { MemberService } from '../../../member/services/member.service';
import * as _ from 'lodash';
import { SmartDeliveryApiService } from '../../../member/public-api';

interface ILocalizedCountries {
    code2: string;
    currencyCode: string;
}

class ViewValue {
    public value: string;
    public viewValue: string;
}

export enum CreditCardFormType {
    Checkout,
    CheckoutKR,
    SavedCards,
    SavedCardsKR,
    ProfileAdd,
    ProfileAddKR,
    ProfileEdit,
    ProfileEditKR,
}

export class InstallmentComponentUpdater {
    public creditCardTypeValue: string;
    public creditCardFkValue: number;
    public creditCardNumValue: string;
    constructor(options: any) {
        this.creditCardFkValue = options.creditCardFkValue;
        this.creditCardNumValue = options.creditCardNumValue;
        this.creditCardTypeValue = options.creditCardTypeValue;
    }
}

@Component({
    selector: 'jn-credit-card-form',
    templateUrl: './credit-card-form.component.html',
    styleUrls: ['./credit-card-form.component.scss'],
})
export class CreditCardFormComponent implements OnInit, OnDestroy {
    @Input() public creditCardFormName: string;
    @Input() public isVisible: boolean = false;
    @Input() public formType: CreditCardFormType = CreditCardFormType.Checkout;
    @Input() public creditCard: PaymentViewModel;
    @Output() public savedCreditCard: EventEmitter<boolean> = new EventEmitter<boolean>(true);
    @Output() public needBillingAddress: EventEmitter<boolean> = new EventEmitter<boolean>(true);
    @Output() public selectedSavedCreditCard: EventEmitter<any> = new EventEmitter<any>(true);
    @Output() public updateInstallmentComponent: EventEmitter<any> = new EventEmitter<any>(true);
    public translations: any = {
        creditCardType: '',
        creditCardName: '',
        creditCardNumber: '',
        creditCardMonth: '',
        creditCardYear: '',
        cvv: '',
        cvvPaternError: '',
    };
    public years: ViewValue[];
    public months: ViewValue[];
    public creditCardTypeModel: CreditCardTypeModel[];
    public orderModel: OrderModel;
    public creditCardForm: FormGroup;
    public allFormTypes = CreditCardFormType;
    public savedCreditCardModel: any;
    public showCvv: boolean = false;

    private subs = new SubSink();
    private isNiceGtwEnabled: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService,
        private readonly seoService: SeoService,
        private readonly numberPipe: NumberOnly,
        private readonly urlHelper: UrlHelperService,
        private readonly authHttp: AuthHttpService,
        private readonly authService: AuthService,
        @Optional() private readonly memberService: MemberService,
        @Optional() private readonly accountService: AccountService,
        @Optional() private rootFormGroup: FormGroupDirective,
        @Optional() private readonly smartDeliveryApiService: SmartDeliveryApiService
    ) {
        this.subs.sink = this.orderService.getOrderModel().subscribe((order: OrderModel) => {
            this.orderModel = order;
        });
    }

    public ngOnInit(): void {
        this.initCreditCardForm();
        this.initCreditCardTypes();
        this.getCCYears();
        this.getMonths();
        if (this.formType === CreditCardFormType.CheckoutKR || this.formType === CreditCardFormType.SavedCardsKR) {
            this.subs.sink = this.orderService.isNiceGtwEnabled(this.orderModel.mainOrderTypeFk).subscribe((res: boolean) => {
                this.isNiceGtwEnabled = res;
                this.setValidators();
                this.getTranslations();
            });
        }
        else {
            this.setValidators();
            this.getTranslations();
        }
        this.setPipeTransformForControls();
        if (this.creditCardFormName && this.rootFormGroup) {
            this.rootFormGroup.control.addControl(this.creditCardFormName, this.creditCardForm);
        }
        if (this.formType === CreditCardFormType.ProfileAdd) {
            this.accountService.requiresCvv().subscribe((result: boolean) => {
                this.showCvv = result;
                this.addCreditCardCvv();
            });
        }
        if (this.formType === CreditCardFormType.ProfileEdit && this.creditCard && this.creditCard.ccType !== 'PAYPAL_BILLING_AGREEMENT') {
            this.accountService.requiresCvv().subscribe((result: boolean) => {
                this.showCvv = result;
                this.addCreditCardCvv();
            });
        }
    }
    public ngOnDestroy(): void {
        this.subs.unsubscribe();
        if (this.creditCardFormName && this.rootFormGroup) {
            this.rootFormGroup.form.removeControl(this.creditCardFormName);
        }
    }

    public initCreditCardForm(): void {
        if (this.formType === CreditCardFormType.Checkout) {
            this.creditCardForm = this.formBuilder.group({
                creditCardType: [''],
                creditCardName: [''],
                creditCardNumber: [''],
                creditCardMonth: [''],
                creditCardYear: [''],
                creditCardCvv: [''],
            });
        }
        else if (this.formType === CreditCardFormType.CheckoutKR) {
            this.creditCardForm = this.formBuilder.group({
                creditCardNumber: [''],
                creditCardMonth: [''],
                creditCardYear: [''],
                creditCardCvv: [''],
            });
        }
        else if (this.formType === CreditCardFormType.SavedCards || this.formType === CreditCardFormType.SavedCardsKR) {
            this.creditCardForm = this.formBuilder.group({
                savedCreditCard: [''],
                creditCardCvv: [''],
            });
            if (this.authService.isLoggedIn && !this.memberService.isGuestAccount) {
                if (this.orderModel.currencyCode !== 'RUB' && this.orderModel.currencyCode !== 'MXN') {
                    this.subs.sink = this.orderService.getSavedCreditCards().subscribe((cards: any) => {
                        if (cards && cards.filter(c => c.ccType !== 'PAYPAL_BILLING_AGREEMENT').length > 0) {
                            this.savedCreditCardCleaner(cards.filter(c => c.ccType !== 'PAYPAL_BILLING_AGREEMENT'));
                            this.subs.sink = this.smartDeliveryApiService.getSettings().subscribe((smartDeliverInfo: SmartDeliveryModel) => {
                                if (smartDeliverInfo && smartDeliverInfo.isPaymentSetup) {
                                    this.savedCreditCard.next(true);
                                    this.setCreditCardCvvValidator();
                                } else {
                                    this.savedCreditCard.next(false);
                                }
                            });
                        } else {
                            this.savedCreditCard.next(false);
                        }
                    });
                }
            }
        }
        else if (this.formType === CreditCardFormType.ProfileAdd ||
                    this.formType === CreditCardFormType.ProfileEdit ||
                    this.formType === CreditCardFormType.ProfileAddKR ||
                    this.formType === CreditCardFormType.ProfileEditKR) {
            let currentMonth: number = new Date().getMonth() + 1;
            let month: string = currentMonth < 10 ? `0${currentMonth.toString()}` : currentMonth.toString();
            let year: string = new Date().getFullYear().toString();
            let currentYear: number = new Date().getFullYear();
            let useOrder: string = '';
            if (this.creditCard) {
                month = this.creditCard.ccExpireMonth;
                let ccYear: number = Number('20' + this.creditCard.ccExpireYear);
                year = ccYear < currentYear ? '' : ccYear.toString();
                useOrder = this.creditCard.useOrder.toString();
            }

            this.creditCardForm = this.formBuilder.group({
                creditCardMonth: [month],
                creditCardYear: [year],
                useOrder: [useOrder],
            });
            if (this.formType === CreditCardFormType.ProfileAdd) {
                this.creditCardForm.addControl('creditCardType', this.formBuilder.control(''));
                this.creditCardForm.addControl('creditCardName', this.formBuilder.control(''));
                this.creditCardForm.addControl('creditCardNumber', this.formBuilder.control(''));
            }
            if (this.formType === CreditCardFormType.ProfileAddKR) {
                this.creditCardForm.addControl('creditCardName', this.formBuilder.control(''));
                this.creditCardForm.addControl('creditCardNumber', this.formBuilder.control(''));
            }
            if (this.showCvv) {
                this.creditCardForm.addControl('creditCardCvv', this.formBuilder.control(''));
            }
        }
    }
    public setValidators(): void {
        switch (this.formType) {
            case CreditCardFormType.Checkout:
                this.creditCardForm.get('creditCardType').setValidators([Validators.required]);
                this.creditCardForm.get('creditCardType').updateValueAndValidity();
                this.creditCardForm.get('creditCardName').setValidators([Validators.required]);
                this.creditCardForm.get('creditCardName').updateValueAndValidity();
                this.creditCardForm.get('creditCardMonth').setValidators([Validators.required]);
                this.creditCardForm.get('creditCardMonth').updateValueAndValidity();
                this.creditCardForm.get('creditCardYear').setValidators([Validators.required]);
                this.creditCardForm.get('creditCardYear').updateValueAndValidity();
                this.setCreditCardNumberValidator();
                this.setCreditCardCvvValidator();
                break;
            case CreditCardFormType.CheckoutKR:
                this.creditCardForm.get('creditCardMonth').setValidators([Validators.required]);
                this.creditCardForm.get('creditCardMonth').updateValueAndValidity();
                this.creditCardForm.get('creditCardYear').setValidators([Validators.required]);
                this.creditCardForm.get('creditCardYear').updateValueAndValidity();
                this.setCreditCardNumberValidator();
                this.setCreditCardCvvValidator();
                break;
            case CreditCardFormType.SavedCards:
            case CreditCardFormType.SavedCardsKR:
                if (this.savedCreditCardModel) {
                    this.setCreditCardCvvValidator();
                }
                break;
            case CreditCardFormType.ProfileAdd:
            case CreditCardFormType.ProfileEdit:
            case CreditCardFormType.ProfileAddKR:
            case CreditCardFormType.ProfileEditKR:
                if (!this.creditCard || (this.creditCard && this.creditCard.ccType !== 'PAYPAL_BILLING_AGREEMENT')) {
                    this.creditCardForm.get('creditCardMonth').setValidators([Validators.required]);
                    this.creditCardForm.get('creditCardMonth').updateValueAndValidity();
                    this.creditCardForm.get('creditCardYear').setValidators([Validators.required]);
                    this.creditCardForm.get('creditCardYear').updateValueAndValidity();
                    if (this.formType === CreditCardFormType.ProfileAdd) {
                        this.creditCardForm.get('creditCardType').setValidators([Validators.required]);
                        this.creditCardForm.get('creditCardType').updateValueAndValidity();
                        this.creditCardForm.get('creditCardName').setValidators([Validators.required]);
                        this.creditCardForm.get('creditCardName').updateValueAndValidity();
                        this.setCreditCardNumberValidator();
                    }
                    if (this.formType === CreditCardFormType.ProfileAddKR) {
                        this.creditCardForm.get('creditCardName').setValidators([Validators.required]);
                        this.creditCardForm.get('creditCardName').updateValueAndValidity();
                        this.setCreditCardNumberValidator();
                    }
                    if (this.showCvv) {
                        this.setCreditCardCvvValidator();
                    }
                }
                break;
            default:
                break;
        }
    }
    public changeCardType(event: any): void {
        this.seoService.trackClick(event, 'select card type', 'Payment & Billing');
        this.updateInstallmentComponent.next(new InstallmentComponentUpdater({ creditCardTypeValue: event }));
        this.setCreditCardNumberValidator();
    }
    public resetBillingCreditForm(): void {
        if (this.formType === CreditCardFormType.Checkout) {
            if (this.creditCardForm) {
                this.creditCardForm.get('creditCardType').clearValidators();
                this.creditCardForm.get('creditCardType').updateValueAndValidity();
                this.creditCardForm.get('creditCardName').clearValidators();
                this.creditCardForm.get('creditCardName').updateValueAndValidity();
                this.creditCardForm.get('creditCardNumber').clearValidators();
                this.creditCardForm.get('creditCardNumber').updateValueAndValidity();
                this.creditCardForm.get('creditCardMonth').clearValidators();
                this.creditCardForm.get('creditCardMonth').updateValueAndValidity();
                this.creditCardForm.get('creditCardYear').clearValidators();
                this.creditCardForm.get('creditCardYear').updateValueAndValidity();
                this.creditCardForm.get('creditCardCvv').clearValidators();
                this.creditCardForm.get('creditCardCvv').updateValueAndValidity();
            }
        }
        else if (this.formType === CreditCardFormType.CheckoutKR) {
            if (this.creditCardForm) {
                this.creditCardForm.get('creditCardNumber').clearValidators();
                this.creditCardForm.get('creditCardNumber').updateValueAndValidity();
                this.creditCardForm.get('creditCardMonth').clearValidators();
                this.creditCardForm.get('creditCardMonth').updateValueAndValidity();
                this.creditCardForm.get('creditCardYear').clearValidators();
                this.creditCardForm.get('creditCardYear').updateValueAndValidity();
                this.creditCardForm.get('creditCardCvv').clearValidators();
                this.creditCardForm.get('creditCardCvv').updateValueAndValidity();
            }
        }
        else if (this.formType === CreditCardFormType.SavedCards || this.formType === CreditCardFormType.SavedCardsKR) {
            this.creditCardForm.get('creditCardCvv').clearValidators();
            this.creditCardForm.get('creditCardCvv').updateValueAndValidity();
        }
    }
    public onCreditCardNumberChange($event: any): void {
        this.updateInstallmentComponent.next(new InstallmentComponentUpdater({ creditCardNumValue: $event }));
    }
    public changeUseOrder($event: any, el: MatRadioButton): void {
        $event.preventDefault();
        let value: string = this.creditCardForm.get('useOrder').value;
        this.creditCardForm.get('useOrder').setValue(value !== el.value ? el.value : '');
    }
    public setCreditCardNumberInvalidError(): void {
       this.creditCardNumberErrors = { 'incorrect': true };
    }

    /**
     * Displays expiration date errors for month and year
     */
    public expirationDate(): void {
        let formYear: number = Number(this.creditCardForm.get('creditCardYear').value);
        let formMonth: number = Number(this.creditCardForm.get('creditCardMonth').value);
        if (formYear !== 0 && formMonth !== 0) {
            let dateObj: Date = new Date();
            let currentMonth: number = dateObj.getUTCMonth() + 1;
            let currentYear: number = dateObj.getUTCFullYear();

            if (formYear === currentYear && formMonth < currentMonth) {
                this.creditCardForm.get('creditCardMonth').markAsDirty();
                this.creditCardForm.get('creditCardMonth').setErrors({ expired: true });
                return;
            }
            this.creditCardForm.get('creditCardMonth').setErrors(null);
        }
    }
    public initCreditCardTypes(): void {
        if (this.formType === CreditCardFormType.Checkout) {
            this.orderService.getAcceptedCreditCardTypes(this.orderModel.countryCode, this.orderModel.currencyCode, this.orderModel.mainOrderTypeFk, this.orderModel.mainOrdersFk).subscribe((cardTypes: CreditCardTypeModel[]) => {
                if (cardTypes) {
                    this.creditCardTypeModel = cardTypes;
                }
            });
        }
        else if (this.formType === CreditCardFormType.ProfileAdd) {
            const memberCountry: string = this.authService.getCountry();
            let mainOrderTypeFk: number = 5;
            this.subs.sink = this.authHttp.get<ILocalizedCountries[]>(this.urlHelper.toProfileApi(`/v1/localization/countries?singleCountryCode=${memberCountry}`)).subscribe((countries: ILocalizedCountries[]) => {

                const index: number = countries.findIndex(country => country.code2 === memberCountry);
                let myCountry: ILocalizedCountries = (index === -1 ? countries.length > 0 ? countries[0] : { currencyCode: 'USD', code2: 'US' } : countries[index]);

                this.subs.sink = this.orderService.getAcceptedCreditCardTypes(
                    memberCountry,
                    myCountry.currencyCode,
                    mainOrderTypeFk,
                ).subscribe((cardTypes: CreditCardTypeModel[]) => {
                    if (cardTypes) {
                        this.creditCardTypeModel = cardTypes;
                    }
                });

            });
        }
    }

    public setSavedCreditCard(creditCard: any): void {
        this.setCreditCardCvvValidator();
        this.updateInstallmentComponent.next(new InstallmentComponentUpdater({ creditCardTypeValue: creditCard.ccType, creditCardFkValue: creditCard.sequencePk }));
        this.selectedSavedCreditCard.next(creditCard);
    }
    public setPipeTransformForControls(): void {
        if (this.creditCardForm.get('creditCardNumber')) {
            this.subs.sink = this.creditCardForm.get('creditCardNumber').valueChanges.subscribe(val => {
                this.creditCardForm.get('creditCardNumber').setValue(this.numberPipe.transform(val), { emitEvent: false });
            });
        }

        if (this.creditCardForm.get('creditCardCvv')) {
            this.subs.sink = this.creditCardForm.get('creditCardCvv').valueChanges.subscribe(val => {
                this.creditCardForm.get('creditCardCvv').setValue(this.numberPipe.transform(val), { emitEvent: false });
            });
        }
    }
    public markAsTouched(): void {
        this.creditCardForm.markAllAsTouched();
    }
    public reset(): void {
        this.creditCardForm.reset();
    }
    public compareCardTypes(o1: any, o2: any): boolean {
        return o1 === o2;
    }
    public editTranslation(name: string, state: string): boolean {
        let element: HTMLElement = document.getElementById('translationEdit');
        element.setAttribute('cmsTranslation', name);
        element.setAttribute('state', state);
        let ev: Event = document.createEvent('HTMLEvents');
        ev.initEvent('contextmenu', true, false);
        element.dispatchEvent(ev);
        return false;
    }

    get creditCardName(): string {
        if (this.formType === CreditCardFormType.CheckoutKR) {
            return '';
        }
        else {
            return this.creditCardForm.get('creditCardName').value;
        }
    }
    get creditCardType(): string {
        if (this.formType === CreditCardFormType.CheckoutKR ||
            this.formType === CreditCardFormType.ProfileAddKR ||
            this.formType === CreditCardFormType.ProfileEditKR) {
            return '';
        }
        else {
            return this.creditCardForm.get('creditCardType').value;
        }
    }
    get creditCardNumber(): string {
        return this.creditCardForm.get('creditCardNumber').value;
    }
    get creditCardMonth(): string {
        return this.creditCardForm.get('creditCardMonth').value;
    }
    get creditCardFullYear(): string {
        return this.creditCardForm.get('creditCardYear').value;
    }
    get creditCardShortYear(): string {
        return this.creditCardForm.get('creditCardYear').value.substr(2, 2);
    }
    get creditCardCvv(): string {
        if (this.creditCardForm.get('creditCardCvv')) {
            return this.creditCardForm.get('creditCardCvv').value;
        }
        return null;
    }
    get useOrder(): number {
        return this.creditCardForm.get('useOrder').value;
    }
    get valid(): boolean {
        return this.creditCardForm.valid;
    }

    set creditCardNumberErrors(errors: ValidationErrors) {
        if (errors !== null) {
            this.creditCardForm.get('creditCardNumber').markAsDirty();
        }
        this.creditCardForm.get('creditCardNumber').setErrors(errors);
    }

    private savedCreditCardCleaner(cards: any): void {
        this.savedCreditCardModel = cards;
        this.savedCreditCardModel = _.map(this.savedCreditCardModel, el => {
            let x: any = Object.assign({}, el);
            return x;
        });
        this.savedCardExpirationDateChecker();
    }
    private savedCardExpirationDateChecker(): any {
        let dateObj: Date = new Date();
        let currentMonth: string = (dateObj.getUTCMonth() + 1).toString().length === 1 ? '0' + (dateObj.getUTCMonth() + 1).toString() : (dateObj.getUTCMonth() + 1).toString();
        let currentYear: string = (dateObj.getUTCFullYear()).toString().substr(2);
        this.savedCreditCardModel = this.savedCreditCardModel.filter((res) => {
            return (parseInt(res.ccExpireYear + res.ccExpireMonth, 10) >= parseInt(currentYear + currentMonth, 10));
        });
        if (this.savedCreditCardModel && this.savedCreditCardModel.length > 0) {
            this.needBillingAddress.next(false);
        }
    }
    private setCreditCardCvvValidator(): void {
        if (this.formType === CreditCardFormType.Checkout) {
            this.creditCardForm.get('creditCardCvv').setValidators([Validators.required, Validators.pattern(/^\d{3,4}?$/), Validators.minLength(3), Validators.maxLength(4)]);
        }
        else if (this.formType === CreditCardFormType.CheckoutKR) {
            if (this.isNiceGtwEnabled)
                this.creditCardForm.get('creditCardCvv').setValidators([Validators.required, Validators.pattern(/^[0-9]{6}?$/)]);
            else
                this.creditCardForm.get('creditCardCvv').setValidators([Validators.required, Validators.pattern(/^\d{3,4}?$/)]);
        }
        this.creditCardForm.get('creditCardCvv').updateValueAndValidity();
    }
    private setCreditCardNumberValidator(): void {
        let useDefault: boolean = true;
        if (this.creditCardTypeModel && this.creditCardTypeModel.length > 0) {
            const ccType: string = this.creditCardForm.get('creditCardType').value;
            for (let i: number = 0; i < this.creditCardTypeModel.length; i++) {
                if (this.creditCardTypeModel[i].ccTypeCode === ccType &&
                    this.creditCardTypeModel[i].validationRegEx) {
                    this.creditCardForm.get('creditCardNumber').setValidators([Validators.required, Validators.pattern(this.creditCardTypeModel[i].validationRegEx)]);
                    useDefault = false;
                    break;
                }
            }
        }
        if (useDefault)
            this.creditCardForm.get('creditCardNumber').setValidators([Validators.required, Validators.pattern('^[0-9]{13,16}$')]);
        this.creditCardForm.get('creditCardNumber').updateValueAndValidity();
    }
    private addCreditCardCvv(): void {
        if (this.creditCardForm && !this.creditCardForm.get('creditCardCvv')) {
            this.creditCardForm.addControl('creditCardCvv', this.formBuilder.control(''));
            if (this.showCvv) {
                this.setCreditCardCvvValidator();
            }
        }
    }
    private getCCYears(): void {
        let currentYear: number = new Date().getFullYear();
        let years: ViewValue[] = [];
        for (let i: number = 0; i < 15 + 1; i++) {
            years.push({
                value: (currentYear + i).toString(),
                viewValue: (currentYear + i).toString(),
            });
        }
        this.years = years;
    }
    private getMonths(): void {
        let months: ViewValue[] = [];
        for (let i: number = 1; i <= 12; i++) {
            const month: string = i < 10 ? '0' + i : i.toString();
            months.push({
                value: month,
                viewValue: month,
            });
        }
        this.months = months;
    }
    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }
    private setTranslations(): void {
        switch (this.formType) {
            case CreditCardFormType.Checkout:
                this.translations['creditCardType'] = this.translationService.translate('shop', 'creditCardType', 'Choose Card Type') || 'Choose Card Type';
                this.translations['creditCardName'] = this.translationService.translate('checkout', 'nameOnCard', 'Name on Card') || 'Name on Card';
                this.translations['creditCardNumber'] = this.translationService.translate('checkout', 'cardNumber', 'Card Number') || 'Card Number';
                this.translations['creditCardMonth'] = this.translationService.translate('checkout', 'paymentMonth', 'Month') || 'Month';
                this.translations['creditCardYear'] = this.translationService.translate('checkout', 'paymentYear', 'Year') || 'Year';
                this.translations['cvv'] = this.translationService.translate('checkout', 'cvv', 'SECURITY CODE') || 'SECURITY CODE';
                break;
            case CreditCardFormType.CheckoutKR:
                this.translations['creditCardNumber'] = this.translationService.translate('checkout', 'cardNumber', 'Card Number') || 'Card Number';
                this.translations['creditCardMonth'] = this.translationService.translate('checkout', 'paymentMonth', 'Month') || 'Month';
                this.translations['creditCardYear'] = this.translationService.translate('checkout', 'paymentYear', 'Year') || 'Year';
                if (this.isNiceGtwEnabled) {
                    this.translations['cvv'] = this.translationService.translate('checkout', 'buyerBirthDate', 'Card holder birthday (YYMMDD)') || 'Card holder birthday (YYMMDD)';
                    this.translations['cvvPaternError'] = this.translationService.translate('checkout', 'cvvPaternErrorLength6', 'Only numbers, length 6');
                }
                else {
                    this.translations['cvv'] = this.translationService.translate('checkout', 'cvv', 'SECURITY CODE') || 'SECURITY CODE';
                    this.translations['cvvPaternError'] = this.translationService.translate('checkout', 'cvvPaternErrorLength3', 'Only numbers, length 3-4');
                }
                break;
            case CreditCardFormType.ProfileEdit:
            case CreditCardFormType.ProfileAdd:
            case CreditCardFormType.ProfileAddKR:
            case CreditCardFormType.ProfileEditKR:
                this.translations.nameOnCard = this.translationService.translate('user-account', 'name-on-card', 'Name on Credit Card');
                this.translations['card-type'] = this.translationService.translate('user-account', 'card-type', 'Card Type');
                this.translations.cardNumber = this.translationService.translate('user-account', 'credit-card-number', 'Credit Card Number');
                this.translations['expiration-month'] = this.translationService.translate('user-account', 'expiration-month', 'Expiration Month');
                this.translations['expiration-year'] = this.translationService.translate('user-account', 'expiration-year', 'Expiration Year');
                this.translations.cvv = this.translationService.translate('checkout', 'creditCode', 'CVV');
                break;
            case CreditCardFormType.SavedCardsKR:
                if (this.isNiceGtwEnabled) {
                    this.translations['cvv'] = this.translationService.translate('checkout', 'buyerBirthDate', 'Card holder birthday (YYMMDD)') || 'Card holder birthday (YYMMDD)';
                    this.translations['cvvPaternError'] = this.translationService.translate('checkout', 'cvvPaternErrorLength6', 'Only numbers, length 6');
                }
                else {
                    this.translations['cvv'] = this.translationService.translate('checkout', 'cvv', 'SECURITY CODE') || 'SECURITY CODE';
                    this.translations['cvvPaternError'] = this.translationService.translate('checkout', 'cvvPaternErrorLength3', 'Only numbers, length 3-4');
                }
                break;
            default:
                break;
        }
    }
}
