import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { UrlHelperService } from '../../common/src/url-helper.service';
import { AuthHttpService } from '../../authentication/src/auth-http.service';
import { CmsSessionService } from './cms-session.service';

@Injectable()
export class TranslationVideoService {
    private videoCache: any[] = [];
    private highlighted: boolean = false;
    private highlightSubject = new Subject<any>();
    private _cmsSessionSubscription: Subscription;
    public get cmsSessionSubscription(): Subscription {
        return this._cmsSessionSubscription;
    }
    public set cmsSessionSubscription(value: Subscription) {
        this._cmsSessionSubscription = value;
    }
    private sessionSubject = new Subject<any>();

    constructor(
        private readonly urlHelper: UrlHelperService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly authHttp: AuthHttpService) {
        this.cmsSessionSubscription = this.cmsSessionService.getSessionObservable().subscribe((session: any) => {
            this.loadCmsSession(session);
        });
    }

    public translate(state: string, name: string, content: string = ''): string {
        const video: any = this.videoCache.find(el => el.name === name);

        if (video) {
            return video.url;
        }
        else {
            let cultureName: string = this.cmsSessionService.getCulture();

            if (this.cmsSessionService.isTranslator() && cultureName === 'en-US') {
                this.addVideo(name, cultureName, content).subscribe();
                console.warn(`Adding New Video: State: ${state} Name: ${name}`);
                return content;
            } else {
                console.warn(`Video Not Found: State: ${state} Name: ${name}`);
                return content;
            }
        }
    }

    public addVideo(name: string, culture: string, url: string): Observable<any> {
        let parts: string[] = name.split('/');
        let filename: string = parts[parts.length - 1];

        return this.authHttp.post<any>(this.urlHelper.toCmsApi(`v1/cms/add-initial-media?name=${name}&filename=${filename}&culture=${culture}&mediaType=video&url=${url}`)).pipe(
            map((data: any) => {
                if (data) {
                    let index: number = this.videoCache.findIndex(x => x.name === data.name);
                    this.videoCache[index] = data;
                    return data.url;
                } else {
                    return '';
                }
            }),
            catchError(() => {
                return of('');
            }));
    }

    public loadVideos(states: string | string[], culture?: string): Observable<boolean> {
        let cultureName: string = culture || this.cmsSessionService.getCulture();
        cultureName = encodeURIComponent(cultureName);

        return this.authHttp.get<any>(this.urlHelper.toCmsApi(`v1/cms/videos/${cultureName}`)).pipe(
            map((data: any) => {
                this.videoCache.push(...data.videos);
                let session: any = this.cmsSessionService.getSession();
                this.loadCmsSession(session);
                return true;
            }), catchError(error => of(false))
        );
    }

    public loadCmsSession(session: any): void {
        let culture: string = this.cmsSessionService.getCulture();
        session.videos.forEach(element => {
            if (element.culture === culture) {
                let index: any = this.videoCache.findIndex(x => x.name === element.name);
                if (index >= 0) {
                    this.videoCache.splice(index, 1);
                }
                this.videoCache.push(element);
            }
        });

        this.sessionSubject.next(session);
    }

    public isTranslator(): boolean {
        return this.cmsSessionService.isTranslator();
    }

    public findVideo(state: string, name: string): any {
        return this.videoCache.find(x => x.name === name);
    }

    public saveVideo(file: File, mediaKey: string, mediaType: string): Observable<any> {
        let ext: string = '';
        let culture: string = this.cmsSessionService.getCulture();
        if (file.name.indexOf('.') > -1) {
            let parts: string[] = file.name.split('.');
            ext = '.' + parts[parts.length - 1];
        }
        let filename: string = mediaKey + '_' + culture + ext;
        let data: FormData = new FormData();
        data.append(mediaKey, file);

        let options: any = { headers: { 'Culture': culture } };
        return this.authHttp.post<any>(
            this.urlHelper.toCmsApi(`/v1/cms/upload-media/?name=${mediaKey}&filename=${filename}&culture=${culture}&mediaType=${mediaType}`), data, options).pipe(
                map((data: any) => {
                    if (data) {
                        let index: number = this.videoCache.findIndex(x => x.name === data.name && x.state === data.state);
                        this.videoCache[index] = data;
                        return data.url;
                    } else {
                        return '';
                    }
                }),
                catchError(() => {
                    return of('');
                }));
    }

    public toggleHighlight(): void {
        this.highlighted = !this.highlighted;
        this.highlightSubject.next(this.highlighted);
    }

    public checkHighlight(): boolean {
        return this.highlighted;
    }

    public getHighlightedObservable(): Observable<any> {
        return this.highlightSubject.asObservable();
    }

    public getSessionObservable(): Observable<any> {
        return this.sessionSubject.asObservable();
    }
}