
/**
 * Address model to represent the billing or shipping address for a member object.
 */
export class AddressModel {
    public firstName: string;
    public lastName: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public area: string;
    public postalCode: string;
    public country: string;
    public email: string;
    public phoneNumber: string;
    public secondPhoneNumber: string;
    public isResidential: boolean;
    public company: string;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.address1 = '';
        this.address2 = '';
        this.address3 = '';
        this.city = '';
        this.postalCode = '';
        this.email = '';
        this.phoneNumber = '';
        this.secondPhoneNumber = '';
        this.country = '';
        this.state = '';
    }
}