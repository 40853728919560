export class DocumentViewModel {
    public documentName: string;
    public signupDocumentsPk: number;
    public required: string;
    public docStatus: string;
    public lastComment: string;
    public userDocumentsFk: number;
    public fileName: string;
    public showProgressBar: boolean;
    public corpIndivBoth: string;
    public requiredForcommission: boolean;
    public langTableName: string;
    public messageLangTableName: string;
    public documentLinkText: string;
    public documentLink: string;
    public autoPopulate: boolean;
    public askExpirationDate: boolean;
    public docExpirationDate? : Date;
    public docNoExpiration: boolean;

    constructor(options: any = {}) {
        this.documentName = options.documentName;
        this.signupDocumentsPk = options.signupDocumentsPk;
        this.required = options.required;
        this.docStatus = options.docStatus;
        this.lastComment = options.lastComment;
        this.userDocumentsFk = options.userDocumentsFk;
        this.fileName = options.fileName;
        this.showProgressBar = false;
        this.corpIndivBoth = options.corpIndivBoth;
        this.requiredForcommission = options.requiredForcommission;
        this.langTableName = options.langTableName;
        this.messageLangTableName = options.messageLangTableName;
        this.documentLinkText = options.documentLinkText;
        this.documentLink = options.documentLink;
        this.autoPopulate = options.autoPopulate;
        this.askExpirationDate = options.askExpirationDate;
        this.docExpirationDate = options.docExpirationDate;
        this.docNoExpiration = options.docNoExpiration;
    }
}
