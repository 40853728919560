export class RepSiteInfo {
    public mainPk: number;
    public mainReferrerFK: number;
    public name: string;
    public phone: string;
    public email: string;
    public country: string;
    public isInventory: boolean;
    public showOption: string;
    public cinsayUrl: string;
    public showCinsay: boolean;
    public jSocial: boolean;
    public alias: string;
    public canShowPrefCust: boolean;
    public canShowJoinNowLink: boolean;
    public googleAnalyticsUA: string;
    public isWholesaleCustomer: boolean;
    public canAllowRetailCustomer: boolean;
    public canAllowRetailSignup: boolean;
    public facebookPixelId: string;
    public isCCAP: boolean;
    public mainTypeFK: number;
    public availableSamplesCountryCodes: string[];
    public corporate: boolean;
    public isValid: boolean;

    constructor(obj: any) {
        this.availableSamplesCountryCodes = obj.availableSamplesCountryCodes === undefined ? [] : obj.availableSamplesCountryCodes;
        this.mainPk = obj.mainPk;
        this.mainReferrerFK = obj.mainReferrerFK;
        this.name = obj.name;
        this.phone = obj.phone;
        this.email = obj.email;
        this.country = obj.country;
        this.isInventory = obj.isInventory;
        this.showOption = obj.showOption;
        this.cinsayUrl = obj.cinsayUrl;
        this.jSocial = obj.jSocial;
        this.alias = obj.alias;
        this.canShowPrefCust = obj.canShowPrefCust;
        this.googleAnalyticsUA = obj.googleAnalyticsUA;
        this.canAllowRetailCustomer = obj.canAllowRetailCustomer;
        this.canAllowRetailSignup = obj.canAllowRetailSignup;
        this.facebookPixelId = obj.facebookPixelId;
        this.isCCAP = obj.isCCAP;
        this.mainTypeFK = obj.mainTypeFK;
        this.corporate = obj.corporate;
        this.isValid = obj.isValid || false;
    }
}
