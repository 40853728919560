export class CustomerAndLevel {
    public mainPk: number;
    public siteUrl: string;
    public sponsorLevel: number;

    constructor(options: any = {}) {
        this.mainPk = options.mainPk;
        this.siteUrl = options.siteUrl;
        this.sponsorLevel = options.sponsorLevel;
    }
}