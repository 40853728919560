import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { IconBoxComponent } from './icon-box.component';
import { IconSelectionComponent } from './icon-selection.component';

/**
 * Loads and exposes the Icon Selection Component and the Icon Box Component
 */
@NgModule({
     imports: [
        CommonModule,
        FormsModule,
          MatIconModule,
          FlexLayoutModule,
        MatRippleModule
     ],
     declarations: [
          IconBoxComponent,
          IconSelectionComponent
     ],
     exports: [
          IconBoxComponent,
          IconSelectionComponent
     ]
})
export class IconSelectorModule {}
