export class CurrencyNumberModel {
    public value: number;
    public formattedValue: number;
    public precision: number;
    public currrencyCode: string;
    public showRegex: boolean;
    public regexFormat: string;
    public regexFormatExample: string;

    public constructor(options: any) {
        this.value = options.value;
        this.formattedValue = options.formattedValue;
        this.currrencyCode = options.currencyCode;
        this.showRegex = options.showRegex;
        this.regexFormat = options.regexFormat;
        this.regexFormatExample = options.regexFormatExample;
        this.precision = options.precision;
    }

}