import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BonusCreditComponent } from './components/bonus-credit/bonus-credit.component';
import { WalletSplitComponent } from './components/wallet-split/wallet-split.component';
import { SignupTokenComponent } from './components/signup-token/signup-token.component';
import { PaypalPaymentMethodComponent } from './components/paypal-payment-method/paypal-payment-method.component';
import { ManualPaymentComponent } from './components/manual-payment/manual-payment.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { ManualPaymentConfirmationComponent } from './components/manual-payment/manual-payment-confirmation.component';
import { MerchantDynamicFormParamComponent } from './components/merchant-redirect-payment/merchant-dynamic-form-param.component';
import { MerchantDynamicFormComponent } from './components/merchant-redirect-payment/merchant-dynamic-form.component';
import { WirePaymentComponent } from './components/wire-payment/wire-payment.component';
import { CmsModule } from '@jeunesse/angular';
import { InstallmentsComponent, InstallmentsDialogComponent } from './components/installments/public-api';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CustomsPaymentWarningComponent } from './components/customs-warning/customs-warning.component';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { ToolTipModule } from '../tool-tip/tool-tip.module';
import { NumberOnly } from '../shared/pipe';
import { PaymentAmountValidatorDirective } from './directives/payment-amount-validator.directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        BonusCreditComponent,
        WalletSplitComponent,
        SignupTokenComponent,
        PaypalPaymentMethodComponent,
        ManualPaymentComponent,
        ManualPaymentConfirmationComponent,
        MerchantDynamicFormParamComponent,
        MerchantDynamicFormComponent,
        WirePaymentComponent,
        InstallmentsComponent,
        InstallmentsDialogComponent,
        CustomsPaymentWarningComponent,
        CreditCardFormComponent,
        NumberOnly,
        PaymentAmountValidatorDirective,
    ],
    imports: [
        MatFormFieldModule,
        MatIconModule,
        MatDividerModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatGridListModule,
        MatProgressSpinnerModule,
        MatCardModule,
        FlexLayoutModule,
        CommonModule,
        CmsModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        DeviceDetectorModule.forRoot(),
        ToolTipModule,
        MatProgressBarModule,
    ],
    exports: [
        BonusCreditComponent,
        WalletSplitComponent,
        SignupTokenComponent,
        PaypalPaymentMethodComponent,
        ManualPaymentComponent,
        ManualPaymentConfirmationComponent,
        MerchantDynamicFormParamComponent,
        MerchantDynamicFormComponent,
        WirePaymentComponent,
        InstallmentsComponent,
        InstallmentsDialogComponent,
        CustomsPaymentWarningComponent,
        CreditCardFormComponent,
        NumberOnly,
        PaymentAmountValidatorDirective,
    ],
    entryComponents: [
        InstallmentsDialogComponent,
    ],
    providers: [
        CurrencyPipe,
        NumberOnly,
    ],
})
export class PaymentModule {}
