export enum ApiNameEnum {
    CART = 'Cart',
    CMS = 'Cms',
    CRM = 'Crm',
    EVENT = 'Event',
    HELP_DESK = 'HelpDesk',
    STORAGE = 'Storage',
    PROFILE = 'Profile',
    SHARED = 'Shared',
    EASY_MATCH = 'EasyMatch',
    JFRONT = 'JFront',
    SECURITY = 'Security',
    LIVE_CHAT = 'LiveChat',
    REPORTS = 'Reports',
    FRONT = 'Front'
}
