import { IconDataModel } from '../models/icon-data.interface';

export const JEUNESSEICONLIST: IconDataModel[] = [
    { fontSet: 'jn', id: 'events', filter: ['jeunesse'] },
    { fontSet: 'jn', id: 'jcloud', filter: ['jeunesse'] },
    { fontSet: 'jn', id: 'prepaid-smart-delivery', filter: ['shipping', 'money'] },
    { fontSet: 'jn', id: 'fund-request', filter: ['money'] },
    { fontSet: 'jn', id: 'question-request', filter: ['helpdesk'] },
    { fontSet: 'jn', id: 'address-change', filter: ['form', 'application'] },
    { fontSet: 'jn', id: 'change-ticket', filter: ['helpdesk'] },
    { fontSet: 'jn', id: 'missing-ticket', filter: ['helpdesk'] },
    { fontSet: 'jn', id: 'boxes', filter: ['delivery'] },
    { fontSet: 'jn', id: 'dolly-flatbed', filter: ['delivery'] },
    { fontSet: 'jn', id: 'truck-loading', filter: ['delivery'] },
    { fontSet: 'jn', id: 'logo', filter: ['jeunesse', 'brand'] },
    { fontSet: 'jn', id: 'gem', filter: ['rank'] },
    { fontSet: 'jn', id: 'pearl', filter: ['gem', 'rank'] },
    { fontSet: 'jn', id: 'sapphire25', filter: ['gem', 'rank'] },
    { fontSet: 'jn', id: 'sapphire50', filter: ['gem', 'rank'] },
    { fontSet: 'jn', id: 'sapphire100', filter: ['gem', 'rank'] },
    { fontSet: 'jn', id: 'double', filter: ['gem', 'rank'] },
    { fontSet: 'jn', id: 'triple', filter: ['gem', 'rank'] },
    { fontSet: 'jn', id: 'imperial', filter: ['gem', 'rank'] },
    { fontSet: 'jn', id: 'presidential', filter: ['gem', 'rank'] },
    { fontSet: 'jn', id: 'crown', filter: ['rank'] },
    { fontSet: 'jn', id: 'moveto-open', filter: ['helpdesk']},
    { fontSet: 'jn', id: 'moveto-assigned', filter: ['helpdesk'] },
    { fontSet: 'jn', id: 'moveto-pending', filter: ['helpdesk'] },
    { fontSet: 'jn', id: 'refund', filter: ['money'] },
    { fontSet: 'jn', id: 'return' },
    { fontSet: 'jn', id: 'reship', filter: ['shipping'] },
    { fontSet: 'jn', id: 'financial-reward', filter: ['money'] },
    { fontSet: 'jn', id: 'tax', filter: ['money'] },
    { fontSet: 'jn', id: 'payout', filter: ['money'] },
    { fontSet: 'jn', id: 'points' }
];
