import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';

import { IAppSettings } from '@jeunesse/angular/common';

import './app/app.global.scss';

declare const AppSettings: IAppSettings;

if (AppSettings.env === 'PROD' || AppSettings.env === 'STG') {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
