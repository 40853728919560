// tslint:disable: typedef
import { createAction, props, union } from '@ngrx/store';
import { EventInfo, EventQueryModel } from '../../models/public-api';

export const loadEventInfo = createAction(
    '[Events] Load Event Info',
    props<{ eventUrl: EventQueryModel }>()
);

export const loadEventInfoError = createAction(
    '[Events] Load Event InfoError',
    props<{ error: any }>()
);

export const updateEventInfo = createAction(
    '[Events] manually update state',
    props<EventInfo>()
);

const actions = union({
    loadEventInfo,
    loadEventInfoError,
    updateEventInfo
});

export type EventInfoActions = typeof actions;