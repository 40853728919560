import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicFieldsService } from './dynamic-fields.service'
import { WebStorageService } from '../../common/src/web-storage.service';
import { AuthService } from '../../authentication/src/auth.service'
import { DocumentFormModel, DocumentViewModel, FieldValuesModel } from './models/public-api';
import { TranslationService } from '../../cms/src/translation.service';
import { ListRange } from '@angular/cdk/collections'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import moment = require('moment');
import { ApiNameEnum } from '../../common/src/enums/api-name.enum';
import { Direction } from '@angular/cdk/bidi';
import { LocaleService } from '../../cms/src/locale.service';

@Component({
    selector: 'jn-dynamic-fields',
    templateUrl: './dynamic-fields.component.html',
    styleUrls: ['./dynamic-fields.component.scss']
})
export class DynamicFieldsComponent implements OnInit {
    @Input() public country: string;
    @Input() public formType: string;
    @Input() public formGroup: FormGroup;
    @Input() public isSignup: boolean;
    @Input() public floatLabel: string = 'always';
    @Input() public snackBarService: any;
    @Input() public isCorporate: boolean = false;
    @Input() public mainTypeFk: number = 1;
    @Input() public flow: string = 'standard';
    @Input() public cartKey: string;
    @Input() public mainOrdersFk: number;
    @Output() formTemplateEmit = new EventEmitter<any>();
    @Output() onControlValueChange = new EventEmitter<any>();
    @Output() onUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() buttonDisable = new EventEmitter<boolean>();

    public formTemplate: any;
    public documentFieldSize: number;
    public signupKey: string;
    public hasDocuments: boolean = false;
    public documents: DocumentViewModel[];
    public fieldsWithDocuments: string[] = new Array<string>();
    public allowedDocuments: string = '.jpg,.docx,.doc,.pdf,.png,.gif,.txt';
    public allowedDocumentsText: string = 'jpg,docx,doc,pdf,png,gif,txt';
    public initialRange: ListRange = { start: 0, end: 6 } as ListRange;
    public virtualScrollThreshold: number = 250;
    public directionality: Direction = 'ltr';
    @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

    constructor(
        private readonly dynamicFieldsService: DynamicFieldsService,
        private readonly translationService: TranslationService,
        private readonly webStorageService: WebStorageService,
        private readonly authService: AuthService,
        private readonly localeService: LocaleService) {
    }

    public ngOnInit(): void {
        this.formatPage();
        this.getFormInformation();
        this.localeService.directionalityObservable$.subscribe((direction: Direction) => {
            this.directionality = direction;
        });
    }

    public getFormInformation() {
        // remove current formControls
        this.removeFormControls();
        let fieldsToRetrieve: string[] = new Array<string>();
        let inputsToPopulate: any[] = new Array<any>();
        const formsQuery = { countryCode: this.country, formType: this.formType };
        this.dynamicFieldsService.getForms(formsQuery, ApiNameEnum.PROFILE, true).subscribe(forms => {
            if (!forms)
                return;
            this.formTemplate = JSON.parse(forms.schemaData);
            if (this.formTemplate && this.formTemplate.inputGroups) {
                this.formTemplate.inputGroups.forEach(inputGroup => {
                    if (this.shouldExistForUser(inputGroup.name)) {
                        inputGroup.inputs.forEach(input => {
                            if (this.shouldExistForUser(input.name)) {
                                input.shouldExistForUser = true;
                                let validatorsToAdd: any = [];
                                input.editable = true;
                                input.required = false;
                                this.setValidators(input, validatorsToAdd);
                                this.formGroup.addControl(input.name, new FormControl('', validatorsToAdd));
                                if (input.type === 'select')
                                    this.optionsSearch(input);
                                if (input.type === 'date')
                                    this.setDates(input);
                                if (input.type === 'document') {
                                    this.fieldsWithDocuments.push(input.name);
                                    this.hasDocuments = true;
                                } else if (this.formTemplate.rules &&
                                    this.formTemplate.rules.filter(rule => rule.type === 'static-text').length > 0 &&
                                    this.formTemplate.rules.filter(rule => rule.type === 'static-text')[0].fields
                                    .filter(excludedField => excludedField === input.name).length >
                                    0) {
                                    input.isStaticText = true;
                                    input.fieldValue = this.translationService.translate(
                                        input.translationContent.placeholder.state,
                                        input.translationContent.placeholder.key,
                                        input.translationContent.placeholder.defaultText);
                                } else {
                                    if (!input.shouldNotSetValue) {
                                        fieldsToRetrieve.push(input.name);
                                    }
                                }
                                inputsToPopulate.push(input);

                                this.checkRules(input);
                            } else {
                                inputGroup.inputs = inputGroup.inputs.filter(x => x !== input);
                            }
                        });
                    }
                    else
                        this.formTemplate.inputGroups = this.formTemplate.inputGroups.filter(x => x !== inputGroup);
                });

                if (fieldsToRetrieve.length > 0) {
                    this.dynamicFieldsService.getDynamicFieldData(fieldsToRetrieve, this.signupKey, this.country, this.formType).subscribe(data => {
                        inputsToPopulate.forEach(input => {
                            if (data && data.filter(x => x.fieldName === input.name && input.type !== 'document').length > 0) {
                                let value: string = data.filter(x => x.fieldName === input.name && input.type !== 'document')[0].fieldValue;
                                if ((!value || value === '') && (input.defaultOutput && input.defaultOutput !== '')) {
                                    value = input.defaultOutput;
                                    input.isUsingDefault = true;
                                }
                                if (input.type === 'checkbox' && value === '')
                                    value = '0';
                                if (input.boolResponse && value)
                                    value = value === '1' ? input.boolResponse[1] : input.boolResponse[0];
                                if (input.isKey && value)
                                    value = this.translationService.translate('dynamicFields', value, value);
                                if (input.removeNonAlphaNumeric && value) {
                                    // removing "none" is left here for now for backword compatibility.
                                    if (value.toLowerCase().includes('none'))
                                        value = '';
                                    value = value.replace(/\W/g, '');
                                }
                                if (input.removeNoneOnFile && value && value.toLowerCase().includes('none')) {
                                    value = '';
                                }
                                if (input.type === 'date' && value && value !== '') {
                                    // 14 hours is the max absolute value diff from UTC for time zones. Will keep localization from changing to previous date.
                                    value = moment(value).add(14, 'hours').format('YYYY-MM-DDThh:mm:ss');
                                }
                                input.fieldValue = value;
                                if (!this.formGroup.get(input.name) && input.shouldExistForUser) {
                                    let validatorsToAdd: any = [];
                                    this.setValidators(input, validatorsToAdd);
                                    this.formGroup.addControl(input.name, new FormControl('', validatorsToAdd));
                                }
                                if (input.multiple && input.fieldValue !== null && input.fieldValue !== '' && input.fieldValue) {
                                        this.formGroup.controls[input.name].setValue(input.fieldValue.split(','));
                                } else {
                                    this.formGroup.controls[input.name].setValue(input.fieldValue);
                                }
                                if (input.lockedIfPopulated && input.fieldValue !== '' && input.fieldValue && !this.isSignup && !input.isUsingDefault) {
                                    input.editable = false;
                                    if (input.type === 'select' && input.options) {
                                        let selectedLabel = input.options.find(option => option.id === input.fieldValue).label;
                                        input.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                                    }

                                    this.formGroup.removeControl(input.name);
                                }

                                // KLK: Emit form control value changes to parent control
                                //#43713
                                if (this.formGroup.controls[input.name]) {
                                    this.formGroup.controls[input.name].valueChanges.subscribe((data) => {
                                        const model: any = {
                                            name: input.name,
                                            value: data
                                        };
                                        if (input.type === 'select') {
                                            var selectedOption = input.options.filter(x => x.id === data)[0];
                                            if (selectedOption) {
                                                model.option = selectedOption;
                                            }
                                        }
                                        this.onControlValueChange.emit(model);
                                    });
                                }

                                if (input.type === 'select' && input.options && input.options.length >= this.virtualScrollThreshold && !input.placeHolder && input.fieldValue) {
                                    let selectedLabel = input.options.find(option => option.id === input.fieldValue).label;
                                    input.placeHolder = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                                }
                            }
                        });
                        this.checkAfterDataRules(inputsToPopulate);
                    });
                }

                if (this.hasDocuments) {
                    let mainPk = this.authService.isLoggedIn ? this.authService.getMainId() : 0;
                    this.dynamicFieldsService.getNeededDocStatus(mainPk, this.signupKey, this.country, this.mainOrdersFk, this.cartKey).subscribe(documents => {
                        this.documents = documents;
                        this.formTemplate.inputGroups.forEach(inputGroup => {
                            inputGroup.inputs.forEach(input => {
                                if (input.type === 'document' && !input.shouldNotSetValue) {
                                    let currentDoc = documents.filter(x => x.documentName === input.name)[0];
                                    input.status = currentDoc.docStatus;
                                    input.comment = currentDoc.lastComment;
                                    inputGroup.status = input.status;
                                    inputGroup.comment = input.comment;
                                    if (currentDoc.docStatus || currentDoc.required !== 'R') {
                                        this.formGroup.get(input.name).setValue(currentDoc.docStatus ? currentDoc.docStatus : 'not required');
                                        input.skipControl = true;
                                    }
                                    else if (this.formGroup.controls[input.name])
                                        this.formGroup.controls[input.name].setValidators(Validators.required);
                                }
                            });
                        });
                        this.formTemplateEmit.emit(this.formTemplate);
                    });
                } else {
                    this.formTemplateEmit.emit(this.formTemplate);
                }
            }
        });
    }

    public formatPage() {
        if (this.isSignup) {
            this.signupKey = this.webStorageService.getCookie('signupKey');
            this.documentFieldSize = 50;
        }
        else {
            this.documentFieldSize = 33;
        }
    }

    public validateId(inputName: string) {
        if (this.formTemplate && this.formTemplate.inputGroups) {
            this.formTemplate.inputGroups.forEach(inputGroup => {
                inputGroup.inputs.forEach(input => {
                    if (!this.formGroup.get(inputName)) {
                        const validatorsToAdd: any = [];
                        this.setValidators(input, validatorsToAdd);
                        this.formGroup.addControl(input.name, new FormControl('', validatorsToAdd));
                    }
                    if (input.name === inputName && input.complexValidation) {
                        this.buttonDisable.emit(true);
                        if (!this.formGroup.get(inputName).hasError('pattern') &&
                            this.formGroup.get(inputName).value !== null &&
                            this.formGroup.get(inputName).value !== '' &&
                            this.formGroup.get(inputName).valid) {
                            const fieldValue: FieldValuesModel[] = new Array();
                            fieldValue.push({
                                field: inputName,
                                value: this.formGroup.get(inputName).value
                            });

                            this.dynamicFieldsService.validateDynamicFieldData(fieldValue, this.country, this.formType, this.signupKey).subscribe((data: any) => {
                                if (data.length > 0) {
                                    input.complexFailText = this.translationService.translate('dynamicFields', data[0].fieldValue, 'Validation Error');
                                    this.formGroup.get(inputName).setErrors({ 'complexFail': true });
                                }
                                this.buttonDisable.emit(false);
                            });
                        }
                    }
                });
            });
        }
    }

    public shouldExistForUser(inputName: any): boolean {
        let shouldExist = true;
        let test = this.mainTypeFk;
        if (this.formTemplate && this.formTemplate.rules) {
            this.formTemplate.rules.forEach(rule => {
                if (rule.type === 'distributor-only' && this.mainTypeFk !== 1 && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (rule.type === 'hide-for-distributor' && this.mainTypeFk === 1 && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (rule.type === 'hide-for-customer' && (!this.authService.isLoggedIn || [2,21].includes(this.mainTypeFk)) && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (rule.type === 'anonymous-only' && this.authService.isLoggedIn && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (rule.type === 'hide-on-signup' && (this.isSignup || this.signupKey) && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (rule.type === 'hide-if-not-signup' && (!this.isSignup && !this.signupKey) && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (rule.type === 'affiliate-only' && this.mainTypeFk !== 22 && shouldExist && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (shouldExist && rule.type === 'individual-only' && this.isCorporate && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (shouldExist && rule.type === 'business-only' && !this.isCorporate && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
                if (shouldExist && rule.type === 'nvpro-only' && this.flow !== 'nvpro' && rule.fields.filter(excludedField => excludedField === inputName).length > 0) {
                    shouldExist = false;
                }
            });
        }
        return shouldExist;
    }

    public checkRules(input: any) {
        if (this.formTemplate && this.formTemplate.rules) {
            this.formTemplate.rules.forEach(rule => {
                if (rule.type === 'read-only' && rule.fields.filter(field => field === input.name).length > 0) {
                    input.editable = false;
                }
                if (rule.type === 'hidden-control' && rule.fields.filter(field => field === input.name).length > 0) {
                    input.hidden = true;
                }
                if (rule.type === 'disabled' && rule.fields.filter(field => field === input.name).length > 0) {
                    input.disabled = true;
                }
            });
        }
    }

    public checkAfterDataRules(inputs: any) {
        if (this.formTemplate && this.formTemplate.rules) {
            this.formTemplate.rules.forEach(rule => {
                if (rule.type === 'show-if-source-empty') {
                    rule.groups.forEach(group => {
                        const sourceInput = inputs.filter(input => input.name === group.source)[0];
                        const targetInput = inputs.filter(input => input.name === group.target)[0];
                        if (sourceInput) {
                            const sourceInputValue = sourceInput.fieldValue;
                            if (sourceInputValue === null || sourceInputValue === '') {
                                if ((targetInput.fieldValue === null || targetInput.fieldValue === '') &&
                                    targetInput.translationContent.placeholder.state &&
                                    targetInput.translationContent.placeholder.key)
                                    targetInput.fieldValue = this.translationService.translate(
                                        targetInput.translationContent.placeholder.state,
                                        targetInput.translationContent.placeholder.key,
                                        targetInput.translationContent.placeholder.defaultText);
                            } else {
                                targetInput.hidden = true;
                            }
                        }
                    });
                }
                else if (rule.type === 'bind-to-target-value') {
                    rule.groups.forEach(group => {
                        this.bindToTargetValue(inputs, group);
                        if (group.targets) {
                            group.targets.forEach(target => {
                                let currentInput = inputs.filter(input => input.name === target.name)[0];
                                if (currentInput && this.formGroup.contains(target.name)) {
                                    this.formGroup.get(target.name).valueChanges.subscribe(data => {
                                        this.bindToTargetValue(inputs, group);
                                    });
                                }
                            });
                        }
                        else {
                            let currentInput = inputs.filter(input => input.name === group.target)[0];
                            if (currentInput && this.formGroup.contains(group.target)) {
                                this.formGroup.get(group.target).valueChanges.subscribe(data => {
                                    this.bindToTargetValue(inputs, group);
                                });
                            }
                        }
                    });
                } else if (rule.type === 'hide-to-target-matchvalue') {
                    rule.groups.forEach((group) => {
                        this.hideToTargetMatchValue(inputs, group);
                        if (group.targets) {
                            group.targets.forEach((target) => {
                                let currentInput = inputs.filter((input) => input.name === target.name)[0];
                                if (currentInput && this.formGroup.contains(target.name)) {
                                    this.formGroup.get(target.name).valueChanges.subscribe((data) => {
                                        this.hideToTargetMatchValue(inputs, group);
                                    });
                                }
                            });
                        } else {
                            let currentInput = inputs.filter((input) => input.name === group.target)[0];
                            if (currentInput && this.formGroup.contains(group.target)) {
                                this.formGroup.get(group.target).valueChanges.subscribe((data) => {
                                    this.hideToTargetMatchValue(inputs, group);
                                });
                            }
                        }
                    });
                }
                else if (rule.type === 'bind-to-target-dynamic') {
                    rule.groups.forEach(group => {
                        const { fields, target, targetProperty, targetValue } = group;
                        this.hideTargetByDynamicBindng(inputs, fields, true);
                        let inputToUse = inputs.filter(input => input.name === group.target)[0];
                        if (inputToUse && this.formGroup.contains(target)) {
                            this.formGroup.get(target).valueChanges.subscribe((data) => {
                                if (inputToUse && inputToUse.options) {
                                    const option = inputToUse.options.filter(x => x.id === data);
                                    if ((option && option[0]) && option[0][targetProperty]) {
                                        if (option[0][targetProperty].toString() === targetValue) {
                                            this.hideTargetByDynamicBindng(inputs, fields, false);
                                        } else {
                                            this.hideTargetByDynamicBindng(inputs, fields, true);
                                        }
                                    } else {
                                        this.hideTargetByDynamicBindng(inputs, fields, true);
                                    }
                                }
                            });
                        }
                    });
                }
                else if (rule.type === 'lock-if-target-value') {
                    rule.groups.forEach(group => {
                        this.lockIfTargetValue(inputs, group);
                        let currentInput = inputs.filter(input => input.name === group.target)[0];
                        if (currentInput && this.formGroup.contains(group.target)) {
                            this.formGroup.get(group.target).valueChanges.subscribe(data => {
                                this.lockIfTargetValue(inputs, group);
                            });
                        }
                    });

                } else if (rule.type === 'hide-if-target-value') {
                    rule.groups.forEach((group) => {
                        this.hideIfTargetValue(inputs, group);
                        let currentInput = inputs.filter((input) => input.name === group.target)[0];
                        if (currentInput && this.formGroup.contains(group.target)) {
                            this.formGroup.get(group.target).valueChanges.subscribe((data) => {
                                this.hideIfTargetValue(inputs, group);
                            });
                        }
                    });
                } else if (rule.type === 'mutuallyExclusive') {
                    rule.groups.forEach(group => {
                        group.fields.forEach(field => {
                            const input = inputs.filter(input => input.name === field)[0];
                            if (input) {
                                input.mutuallyExclusive = [];
                                group.fields.forEach(field2 => {
                                    if (field !== field2)
                                        input.mutuallyExclusive.push(field2);
                                });
                            }
                        });
                    });
                }
                else if (rule.type === 'lock-if-source-populated') {
                    rule.groups.forEach(group => {
                        const sourceInputValue = inputs.filter(input => input.name === group.source)[0].fieldValue;
                        if (sourceInputValue !== null && sourceInputValue !== '') {
                            const targetInput = inputs.filter(input => input.name === group.target)[0];
                            targetInput.editable = false;
                            this.formGroup.removeControl(targetInput.name);
                            if (targetInput.type === 'select' && targetInput.options) {
                                const selectedLabel = targetInput.options.filter(option => option.id === targetInput.fieldValue)[0].label;
                                targetInput.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                            }
                        }
                    });
                }
                else if (rule.type === 'show-if-empty') {
                    rule.fields.forEach(field => {
                        const inputField = inputs.filter(input => input.name === field)[0];
                        if (inputField && inputField.fieldValue !== null && inputField.fieldValue !== '') {
                            this.formGroup.removeControl(inputField.name);
                            inputField.hidden = true;
                        }
                    });
                }
                else if (rule.type === 'hide-if-empty') {
                    rule.fields.forEach(field => {
                        const inputField = inputs.filter(input => input.name === field)[0];
                        if (inputField && (inputField.fieldValue === null || inputField.fieldValue === '')) {
                            inputField.hidden = true;
                        }
                    });
                }
            });
        }
        this.formTemplate.inputGroups.forEach(inputGroup => {
            if (inputGroup.inputs.filter(input => input.hidden === false || input.hidden === undefined).length === 0) {
                inputGroup.hidden = true;
            }
        });
    }

    public hideTargetByDynamicBindng(inputs: any[], fields: string[], hide: boolean) {
        fields.forEach((field) => {
            const input = inputs.filter((input) => input.name === field)[0];
            if (!input) return;

            if (!hide) {
                input.required = false;
                let validatorsToAdd: any = [];
                this.setValidators(input, validatorsToAdd);
                if (input.editable && !input.skipControl) {
                    this.formGroup.get(field).setValidators(validatorsToAdd);
                    input.skipSave = false;
                }
                input.hidden = false;
                this.formTemplate.inputGroups.forEach((inputGroup) => {
                    if (inputGroup.inputs.filter((x) => x.hidden === false || x.hidden === undefined).length > 0) {
                        inputGroup.hidden = false;
                    } else {
                        inputGroup.hidden = true;
                    }
                });
            } else {
                if (input.editable) {
                    this.formGroup.get(input.name).setValidators(null);
                    this.formGroup.get(input.name).updateValueAndValidity();
                    input.skipSave = true;
                }
                    input.hidden = true;
            }
        });
    }

    public onDocumentChange(event: any, formInput: any): void {
        const fileList: FileList = event.target.files;
        if (fileList.length === 1) {
            formInput.uploading = true;

            let fileNameArr: string[] = fileList[0].name.split('.');
            let extension: string = `.${fileNameArr[fileNameArr.length - 1].toLowerCase()}`;

            if (this.allowedDocuments.indexOf(extension) === -1) {
                if (this.snackBarService)
                    this.snackBarService.warn(this.translationService.translate('dynamicFields', 'fileTypesWarning', 'Only {0} file types allowed').replace('{0}', this.allowedDocumentsText));
                else
                    alert(this.translationService.translate('dynamicFields', 'fileTypesWarning', 'Only {0} file types allowed').replace('{0}', this.allowedDocumentsText));

                formInput.uploading = false;
                return;
            }

            let document: DocumentViewModel = this.documents.filter(x => x.documentName === formInput.name)[0];

            const uploadDoc: DocumentFormModel = {
                fileName: document.documentName,
                fileType: extension,
                fileSize: 1,
                mainPk: this.authService.isLoggedIn ? this.authService.getMainId() : 0,
                signupDocumentsPK: document.signupDocumentsPk,
                userDocumentsPk: document.userDocumentsFk,
                isCorporate: false,
                classification: 'Signup',
                signupKey: this.signupKey,
                cartKey: this.cartKey
            };

            this.dynamicFieldsService.upload(uploadDoc, fileList[0]).subscribe((data: any) => {
                formInput.uploading = false;
                formInput.status = data.docStatus;
                this.formTemplate.inputGroups.forEach(inputGroup => {
                    if (inputGroup.inputs.filter(input => input.name === formInput.name).length > 0)
                        inputGroup.status = data.docStatus;
                });
                this.formGroup.get(formInput.name).setValue(data.docStatus);
                this.documents.filter(x => x.documentName === formInput.name)[0].userDocumentsFk = data.userDocumentsPk;
                this.onUpload.emit(data);
            });
        }
    }

    public lockIfTargetValue(inputs, group): void {
        const input = inputs.filter(input => input.name === group.field)[0];
        let currentFormValue;
        let targetInput = inputs.filter(input => input.name === group.target)[0];
        if (!targetInput)
            return;
        if (!targetInput.editable)
            currentFormValue = targetInput.fieldValue;
        if (this.formGroup.contains(targetInput.name))
            currentFormValue = this.formGroup.contains(group.target) ? this.formGroup.get(group.target).value : null;
        if (!((currentFormValue !== null && group.targetValue === 'hasValue') || currentFormValue === group.targetValue)) {
            input.editable = true;
            if (input.type === 'select' && input.options && input.fieldValue && input.fieldValue !== '' && input.fieldValue) {
                const selectedLabel = input.options.find(option => option.id === input.fieldValue || option.label === input.fieldValue).label;
                input.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
            }
        }
        else {
            input.editable = false;
            if (input.type === 'select' && input.options && input.fieldValue && input.fieldValue !== '' && input.fieldValue) {
                const selectedLabel = input.options.find(option => option.id === input.fieldValue).label;
                input.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
            }
        }
    }

    public hideIfTargetValue(inputs, group): void {
        const input = inputs.filter((input) => input.name === group.field)[0];
        let currentFormValue;
        let hideField = false;
        let targetInput = inputs.filter((input) => input.name === group.target)[0];
        if (!targetInput) return;
        if (!targetInput.editable) currentFormValue = targetInput.fieldValue;
        if (this.formGroup.contains(targetInput.name))
            currentFormValue = this.formGroup.contains(group.target) ? this.formGroup.get(group.target).value : null;
        if (currentFormValue === group.targetValue)
            hideField = true;

        if (!hideField) {
            input.required = false;
            let validatorsToAdd: any = [];
            this.setValidators(input, validatorsToAdd);
            if (input.editable && !input.skipControl) {
                this.formGroup.get(input.name).setValidators(validatorsToAdd);
                input.skipSave = false;
            }
            input.hidden = false;
            this.formTemplate.inputGroups.forEach((inputGroup) => {
                if (inputGroup.inputs.filter((x) => x.hidden === false || x.hidden === undefined).length > 0) {
                    inputGroup.hidden = false;
                } else {
                    inputGroup.hidden = true;
                }
            });
        } else {
            if (input.editable) {
                this.formGroup.get(input.name).setValidators(null);
                this.formGroup.get(input.name).updateValueAndValidity();
                input.skipSave = true;
            }
            input.hidden = true;

        }
    }

    //The “field” will only show if ALL name/value combinations match the values indicated.
    public bindToTargetValue(inputs, group): void {
        let currentFormValue;
        let hideField = false;
        if (group.targets) {
            group.targets.forEach(target => {
                let targetInput = inputs.filter(input => input.name === target.name)[0];
                if (!targetInput)
                    return;
                if (!targetInput.editable)
                    currentFormValue = targetInput.fieldValue;
                if (this.formGroup.contains(targetInput.name))
                    currentFormValue = this.formGroup.contains(targetInput.name)
                        ? this.formGroup.get(targetInput.name).value
                        : null;
                if (!((currentFormValue !== null && targetInput.fieldValue === 'hasValue') || currentFormValue === target.value))
                    hideField = true;
            });
        } else {
            let targetInput = inputs.filter(input => input.name === group.target)[0];
            if (!targetInput)
                return;
            if (!targetInput.editable)
                currentFormValue = targetInput.fieldValue;
            if (this.formGroup.contains(targetInput.name))
                currentFormValue = this.formGroup.contains(group.target) ? this.formGroup.get(group.target).value : null;
            if (!((currentFormValue !== null && group.targetValue === 'hasValue') || currentFormValue === group.targetValue))
                hideField = true;
        }
        const input = inputs.filter(input => input.name === group.field)[0];
        if (!hideField) {
            input.required = false;
            let validatorsToAdd: any = [];
            this.setValidators(input, validatorsToAdd);
            if (input.editable && !input.skipControl) {
                this.formGroup.get(input.name).setValidators(validatorsToAdd);
                input.skipSave = false;
            }
            input.hidden = false;
            this.formTemplate.inputGroups.forEach(inputGroup => {
                if (inputGroup.inputs.filter(x => x.hidden === false || x.hidden === undefined).length > 0) {
                    inputGroup.hidden = false;
                }
                else {
                    inputGroup.hidden = true;
                }
            });
        }
        else {
            if (input.editable) {
                this.formGroup.get(input.name).setValidators(null);
                this.formGroup.get(input.name).updateValueAndValidity();
                input.skipSave = true;
            }
            input.hidden = true;
            if (input.type === 'document') {
                this.formTemplate.inputGroups.forEach(inputGroup => {
                    inputGroup.inputs.forEach(mainInput => {
                        if (input.type === 'document' && input.name === mainInput.name) {
                            inputGroup.hidden = true;
                        }
                    });
                });
            }
        }

        // Check for and run any child rules
        if (group.childRules) {
            group.childRules.forEach((rule) => {
                if (rule.type === 'bind-to-target-dynamic') {
                    this.hideTargetByDynamicBindng(inputs, rule.fields, true);
                }
            });
        }
    }

    //#42784 KP
    //The “field” will hide if one of the name/value match the values indicated.
    public hideToTargetMatchValue(inputs, group): void {
        let currentFormValue;
        let hideField = false;
        let foundmatchvalue = false;
        if (group.targets) {
            group.targets.forEach((target) => {
                let targetInput = inputs.filter((input) => input.name === target.name)[0];
                if (!targetInput) return;
                if (!targetInput.editable) currentFormValue = targetInput.fieldValue;
                if (this.formGroup.contains(targetInput.name))
                    currentFormValue = this.formGroup.contains(targetInput.name) ? this.formGroup.get(targetInput.name).value : null;
                if (currentFormValue === target.value)
                    foundmatchvalue = true;
            });
        } else {
            let targetInput = inputs.filter((input) => input.name === group.target)[0];
            if (!targetInput) return;
            if (!targetInput.editable) currentFormValue = targetInput.fieldValue;
            if (this.formGroup.contains(targetInput.name))
                currentFormValue = this.formGroup.contains(group.target) ? this.formGroup.get(group.target).value : null;
            if (currentFormValue === group.targetValue)
                foundmatchvalue = true;
        }
        if (foundmatchvalue === true)
            hideField = true;

        const input = inputs.filter((input) => input.name === group.field)[0];
        if (!hideField) {
            input.required = false;
            let validatorsToAdd: any = [];
            this.setValidators(input, validatorsToAdd);
            if (input.editable && !input.skipControl) {
                this.formGroup.get(input.name).setValidators(validatorsToAdd);
                input.skipSave = false;
            }
            input.hidden = false;
            this.formTemplate.inputGroups.forEach((inputGroup) => {
                if (inputGroup.inputs.filter((x) => x.hidden === false || x.hidden === undefined).length > 0) {
                    inputGroup.hidden = false;
                } else {
                    inputGroup.hidden = true;
                }
            });
        } else {
            if (input.editable) {
                this.formGroup.get(input.name).setValidators(null);
                this.formGroup.get(input.name).updateValueAndValidity();
                input.skipSave = true;
            }
            input.hidden = true;

        }
    }

    public mutuallyExclusive(selectedValue, input) {
        if (input.mutuallyExclusive)
            input.mutuallyExclusive.forEach(exclusive => {
                if (input.options.filter(option => option.id === selectedValue)[0].label === this.formGroup.get(exclusive).value)
                    this.formGroup.controls[exclusive].setValue(null);
            });
    }

    public openedChange($event: boolean, formInput: any) {
        if ($event) {
            const index = formInput.options.findIndex(option => option.id === this.formGroup.controls[formInput.name].value);
            if (this.formGroup.controls[formInput.name].value) {
                const selectedLabel = formInput.options.filter(option => option.id === this.formGroup.controls[formInput.name].value)[0].label;
                formInput.placeHolder = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
            }
            this.cdkVirtualScrollViewport.scrollToIndex(index);
            this.cdkVirtualScrollViewport.checkViewportSize();
        }
    }

    public getSelectInnerText(option: any): string {
        if (option && option.label) {
            return this.translationService.translate('dynamicFields', option.label, option.label);
        }
    }


    private optionsSearch(input: any) {
        if (input.optionsSearch) {
            const formsQuery = { countryCode: this.country, formType: this.formType };
            this.dynamicFieldsService.optionsSearch(input.name, formsQuery).subscribe(optionsArray => {
                input.options = optionsArray;
                if (!input.placeHolder && input.fieldValue) {
                    let selectedLabel = input.options.filter(option => option.id === input.fieldValue)[0].label;
                    input.placeHolder = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                    if (!input.editable)
                        input.fieldValue = input.placeHolder;
                }
            });
        }
    }

    private setValidators(input, validatorsToAdd) {
        input.validators.forEach(validator => {
            if (validator.type === 'required' && input.type !== 'document') {
                input.required = true;
                validatorsToAdd.push(Validators.required);
            }
            if (validator.type === 'pattern') {
                validatorsToAdd.push(Validators.pattern(validator.regexPattern));
            }
            if (validator.type === 'email') {
                validatorsToAdd.push(Validators.email);
            }
        });
    }

    private setDates(input) {
        if (input.minYears !== null) {
            input.minDate = new Date();
            input.minDate.setFullYear(input.minDate.getFullYear() - input.minYears);
            input.minDate.setHours(0, 0, 0, 0);
        }
        if (input.maxYears !== null) {
            input.maxDate = new Date();
            input.maxDate.setFullYear(input.maxDate.getFullYear() - input.maxYears);
            input.maxDate.setHours(0, 0, 0, 0);
        }

        this.formGroup.get(input.name).valueChanges.subscribe(
            (selectedDate: Date) => {
                if (input.maxDate && selectedDate && selectedDate > input.maxDate)
                    this.formGroup.get(input.name).setErrors({ 'maxDate': true });
                else if (input.minDate && selectedDate && selectedDate < input.minDate)
                    this.formGroup.get(input.name).setErrors({ 'minDate': true });
            });
    }

    public removeFormControls(): void {
        if (this.formTemplate && this.formTemplate.inputGroups) {
            this.formTemplate.inputGroups.forEach(group => {
                if (group.inputs) {
                    group.inputs.forEach(input => {
                        this.formGroup.removeControl(input.name);
                    });
                }
            });
            this.formTemplate = {};
        }
    }
}
