import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UrlHelperService } from '../../common/src/url-helper.service';

import { AuthHttpService } from './auth-http.service';
import { UsersQuery, UserSimpleModel } from './models/public-api';

/**
 * Handles everything related to Jet users.
 */
@Injectable()
export class UsersService {
    constructor(
        private readonly authHttp: AuthHttpService,
        private readonly urlHelper: UrlHelperService) { }

    /**
     * Return simple info on a list of Jet users.
     * @param usersQuery Query options to be added to the request.
     * @return Returns a list of Jet users data.
     */
    public getUsers(usersQuery?: UsersQuery): Observable<UserSimpleModel[]> {
        return this.authHttp.get<any>(this.urlHelper.toSecurityApi('/v1/users'), { params: usersQuery }).pipe(
            map((data: any) => {
                return data.map((user: any) => new UserSimpleModel(user));
            }));
    }
}
