import { Injectable } from '@angular/core';
import { Observable, Subject, throwError, Subscription } from 'rxjs';
import { ManualPayment } from '../models/manual-payment.model';
import { ManualPaymentType } from '../models/manual-payment-type.model';
import { PaymentApiService } from './payment-api.service';
import { PaymentInfo } from '../models/payment-info.model';
import { AuthHttpService } from '@jeunesse/angular';
import { UrlHelperService } from '@jeunesse/angular';
import { map } from 'rxjs/operators';
import { PaymentMethodViewModel } from '../../checkout/models/payment-method-view.model';

/**
 *
 */
@Injectable({
    providedIn: 'root'
})
export class ManualPaymentService {

    private manualPaymentTypes: ManualPaymentType[] = [];

    constructor(private paymentApiService: PaymentApiService,
        private readonly authHttp: AuthHttpService,
        private readonly urlHelper: UrlHelperService) {
        this.loadManualPayTypes();
    }

    private async loadManualPayTypes() {
        this.manualPaymentTypes = await this.authHttp.get<ManualPaymentType[]>(this.urlHelper.toCartApi(`/v1/orders/payments/manual`)).toPromise();
    }

    /**
     *Get payment info/messages
     *@param orderNumber orderPK
     */
    public getOrderPaymentInfo(orderNumber: number): Observable<PaymentInfo[]> {
        return this.authHttp.get<PaymentInfo>(this.urlHelper.toCartApi(`/v1/orders/${orderNumber}/payments/info`))
            .pipe(
                map((data: any) => {
                    return data.sort((a, b) => a.priority - b.priority)
                })
            );
    }

    /**
     * converts payment type to manual payment type structure
     * @param paymentMethodType payment type
     */
    public paymentTypeToManualPayment(paymentMethodType: number, amount: number, checkNumber: number): ManualPayment {
        let manualPaymentType: ManualPaymentType = this.paymentTypeToManualType(+paymentMethodType);
        return { amount: amount, manualPayType: manualPaymentType.merchantManualType, manualPayDescription: manualPaymentType.paymentMethodName, checkNumber: checkNumber, transactionDate: new Date() };
    }

    public isManual(paymentMethodType: number): boolean {
        return paymentMethodType == null || paymentMethodType == 0? false: this.paymentTypeToManualType(paymentMethodType).merchantManualType > 0;
    }

    /**
    * converts payment type to manual payment type structure
    * @param paymentMethodType payment type
    */
    public paymentTypeToManualType(paymentMethodType: number): ManualPaymentType {
        let manualPaymentType = this.manualPaymentTypes.find(payType => payType.paymentMethodType == paymentMethodType);
        return manualPaymentType == null ? { paymentMethodType: paymentMethodType, paymentMethodCode: '', merchantManualType: 0, paymentMethodName: '' } : manualPaymentType;
    }

    public manualPaymentTypeToPaymentMethod(manualPayType: ManualPaymentType): PaymentMethodViewModel {
        return new PaymentMethodViewModel({
            paymentMethodDisplayName: manualPayType.paymentMethodName
            , paymentMethodValue: 'MM'
            , redirectUrl: ''
            , showorder: 9
            , isPaymentWire: true
            , orderKeyEncoded: null
            , paymentMethodTypePK: manualPayType.paymentMethodType
        });
    }

    /**
    * Gets all available payment type
    */
    public allPaymentTypes(): number[] {
        return this.manualPaymentTypes.map(pt => pt.paymentMethodType);
    }
}
