
import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslationFormModel, TranslationViewModel } from '../models/public-api';


@Component({
    selector: 'jn-edit-translation-modal',
    templateUrl: './edit-translation-modal.component.html',
    styleUrls: ['./edit-translation-modal.component.scss'],
    providers: [ MatDialogModule ]
})

/**
 * Modal component to allow users to sign up.
 */
export class EditTranslationModalComponent implements OnInit {

    public translationModel: TranslationViewModel;
    public translationText = 'enter your text';
    public editedContent = '';
    public applyToAll = false;

    constructor(public dialogRef: MatDialogRef<EditTranslationModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any) {
            this.translationModel = this.data;
    }

    public ngOnInit(): void {
        this.translationText = this.translationModel.content;
    }

    /**
     * save translation
     */
    public saveTranslation(): void {
        this.translationModel.content = this.editedContent;
        const updated: TranslationFormModel = {
            state: this.translationModel.state,
            name: this.translationModel.name,
            content: this.translationModel.content,
            cssClasses: this.translationModel.cssClasses,
            culture: this.translationModel.culture,
            applyToAll: this.applyToAll,
            module: '' };
        this.dialogRef.close(updated);
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public onContentChanged = (event) => {
        const sub: string = event.html.substring(0, 3);
        if (sub === '<p>') {
            // strip the <p> tag added by editor
            this.editedContent = event.html.substring(3, event.html.length - 4);
        }
        else {
            this.editedContent = event.html;
        }
    }
}
