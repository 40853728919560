export class CreditCardTypeModel {
    public ccTypeFK: number;
    public ccTypeCode: string;
    public ccTypeDescription: string;
    public validationRegEx: string;

    public constructor(options: any) {
        this.ccTypeFK = options.ccTypeFK;
        this.ccTypeCode = options.ccTypeCode;
        this.ccTypeDescription = options.ccTypeDescription;
        this.validationRegEx = options.validationRegEx;
    }
}