import { Component, Inject, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { Subscription, timer } from 'rxjs';

import { SeoService } from '@jeunesse/angular';
import { ANIMATIONS } from '../../components/animations';

import { CategoryModel } from '../../../products/models/category/category.model';

import { CATEGORY_OVERLAY_DATA } from './category-overlay.tokens';
import { CategoryOverlayRef } from './category-overlay-ref';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * This is for showing the categories of products in an overlay.
 */
@Component({
    templateUrl: './category-overlay.component.html',
    styleUrls: ['./category-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ANIMATIONS.transformOverlay],
    host: {
        '[@transformOverlay]': 'animationState',
        '(@transformOverlay.start)': 'onAnimationStart($event)',
        '(@transformOverlay.done)': 'onAnimationDone($event)'
    }
})
export class CategoryOverlayComponent {
    /**
     * This is the remote for controlling the overlay.
     * This needs to be set after the overlay has been created.
     */
    public categoryOverlayRef: CategoryOverlayRef;
    /**
     * Changed state of the animations.
     */
    public animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

    /**
     * The current state of the animation.
     */
    public animationState: 'void' | 'enter' | 'leave' = 'enter';

    /**
     * Siteurl of the current rep
     */
    public siteUrl: string;

    private timer: Subscription;

    constructor(
        private readonly router: Router,
        private readonly seoService: SeoService,
        @Inject(CATEGORY_OVERLAY_DATA) public readonly data: CategoryModel,
        private readonly ref: ChangeDetectorRef,
        private readonly activatedRoute: ActivatedRoute
    ) {
        // Grab the reps siteurl to use in the shop all link
        if (this.activatedRoute.children[0] && this.activatedRoute.children[0].children[0]) {
            this.siteUrl = this.activatedRoute.children[0].children[0].snapshot.params.siteUrl;
        }
    }

    /**
     * This is called when the animation starts.
     * @param event The event.
     */
    public onAnimationStart(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * This is called when the animation ends.
     * @param event The event.
     */
    public onAnimationDone(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * Start the exist animation.
     */
    public startExitAnimation(): void {
        this.animationState = 'leave';

        this.ref.markForCheck();
    }

    public sendGAInfo(category: string, action: string, label?: string): void {
        if (label === undefined) {
            label = this.router.url + ' - ' + this.data.name;
        }
        this.seoService.trackClick(label, action, category);
    }

    // public prepareImageFamilyName (name: string): string {
    //     let t = name.replace(/[^\w]/gi, '').toLowerCase() + 'family';
    //     return t;
    // }

    public closeMe(): void {
        if (this.categoryOverlayRef) {
            this.timer = timer(180).subscribe(() => {
                this.categoryOverlayRef && this.categoryOverlayRef.close();
            });
        }
    }

    @HostListener('mouseout')
    public hide() {
        this.closeMe();
    }

    @HostListener('mouseover')
    public show() {
        if (this.timer) {
            this.timer.unsubscribe();
        }
    }

    // KLK - Removed due to issue for CMS Translators uploading new category menu images - Task: 40507
    // @HostListener('body:click', ['$event'])
    // public handleKeydown(event: MouseEvent): void {
    //     this.closeMe();
    // }
}
