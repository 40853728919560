import {ICmsValue} from './cms-value.interface';

export class KeyValueString implements ICmsValue {
    public key: string;
    public value: string;
    public cmsName: string;
    public cmsState: string;

    constructor(options: any = {}) {
        this.key = options.key;
        this.value = options.value;
        this.cmsName = options.cmsName;
        this.cmsState = options.cmsState;
    }
}

export class KeyValueDropdowns {
    public value: string;
    public viewValue: string;
    public cmsName: string;
    public cmsState: string;

    constructor(options: any = {}) {
        this.value = options.value;
        this.viewValue = options.viewValue;
        this.cmsName = options.cmsName;
        this.cmsState = options.cmsState;
    }
}

export class KeyValueLanguage {
    public key: number;
    public value: string;
    public cmsName: string;
    public cmsState: string;

    constructor(options: any = {}) {
        this.key = options.id;
        this.value = options.language;
        this.cmsName = options.cmsName;
        this.cmsState = options.cmsState;
    }
}

export class KeyValueState {
    public code: string;
    public name: string;
    public cmsName: string;
    public cmsState: string;

    constructor(options: any = {}) {
        this.code = options.code;
        this.name = options.name;
        this.cmsName = options.cmsName;
        this.cmsState = options.cmsState;
    }
}