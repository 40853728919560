/**
 * Classes for merchant specified form parameters
 */
export class MerchantParamBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  height: number;
  width: number;
  options: { key: string, value: string, cmsTranslation: string}[];
  validatorType: string;
  regexPattern: string;
  validatorState: string;
  validatorKey: string;
  validatorDefaultText: string;
  cmsTranslation: string;

  constructor(options: {
      value?: T,
      key?: string,
      label?: string,
      required?: boolean,
      order?: number,
      controlType?: string,
      type?: string,
      height?: number,
      width?: number,
      validatorType?: string,
      regexPattern?: string,
      validatorState?: string,
      validatorKey?: string,
      validatorDefaultText?: string,
      cmsTranslation?: string
    } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.height = options.height === undefined ? 1 : options.height;
    this.width = options.width === undefined ? 1 : options.width;
    this.validatorType = options.validatorType || '';
    this.regexPattern = options.regexPattern || '';
    this.validatorState = options.validatorState || '';
    this.validatorKey = options.validatorKey || '';
    this.validatorDefaultText = options.validatorDefaultText || '';
    this.cmsTranslation = options.cmsTranslation;
  }
}

export class TextboxMerchantParam extends MerchantParamBase<string> {
    controlType = 'textbox';
    type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || '';
    }
}

export class HiddenMerchantParam extends MerchantParamBase<string> {
    controlType = 'hidden';
    type: 'hidden';

    constructor(options: {} = {}) {
        super(options);
        this.type = 'hidden';
    }
}

export class DropdownMerchantParam extends MerchantParamBase<string> {
    controlType = 'dropdown';
    options: { key: string, value: string, cmsTranslation: string }[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options'] || [];
    }
}