import { Injectable } from "@angular/core";

import { AuthHttpService, UrlHelperService } from "@jeunesse/angular";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { PromoPrize, PromoPrizePostModel, PromoReport, PromoWinner } from "../models/promo/public-api";
import { RsvpPostModel } from "../models/rsvp/public-api";
import { LoadingEventsService } from "./loading-events.service";


@Injectable({ providedIn: 'root' })
export class RSVPApiService {
    constructor(private readonly authHttpService: AuthHttpService,
        private readonly urlHelperService: UrlHelperService,
        private readonly loadingEventsService: LoadingEventsService) { }

    /**
     * Checks to see if logged in distributor is qualified for promo.
     */
    public validateMainHasPromo(promoEventUrl: string): Observable<PromoWinner> {
        this.loadingEventsService.changeLoadingState('validateMainHasPromo', true);
        return this.authHttpService.get<PromoWinner>(this.urlHelperService.toEventApi(`/v1/promo/${promoEventUrl}/qualified`)).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('validateMainHasPromo', false);
            })
        );
    }

    /**
     * Get's promotion information
     */
    public getPromoInfo(promoReportsId: number): Observable<PromoReport> {
        if (!promoReportsId) { return of(null); }
        this.loadingEventsService.changeLoadingState('gettingPromoDetails', true);
        return this.authHttpService.get<PromoReport>(this.urlHelperService.toEventApi(`/v1/promo/cms/${promoReportsId}`)).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('gettingPromoDetails', false);
            })
        );
    }

    /**
     * Grabs all prizes available for a promo
     */
    public loadPromoPrizes(promoReportsId: number): Observable<PromoPrize[]> {
        this.loadingEventsService.changeLoadingState('gettingPromoPrizes', true);
        return this.authHttpService.get<PromoPrize[]>(this.urlHelperService.toEventApi(`/v1/promo/cms/${promoReportsId}/prizes`)).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('gettingPromoPrizes', false);
            })
        );
    }

    /**
     * Saves the selected prize for a promo for the distributor
     */
    public savePrizeSelection(model: PromoPrizePostModel): Observable<void> {
        this.loadingEventsService.changeLoadingState('savingPromoPrizes', true);
        return this.authHttpService.post<void>(this.urlHelperService.toEventApi(`/v1/promo/${model.promoId}/prize`), model).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('savingPromoPrizes', false);
            })
        );
    }

    /**
     * Uploads passport image photo
     */
    public uploadPassportPhoto(promoReportsId: number, file: File): Observable<string> {
        if (!file) {
            return of('No file set!');
        }
        let ext: string = '.jpg';
        if (file.name.indexOf('.') > -1){
            var parts = file.name.split('.');
            ext = parts[parts.length -1];
        }
        const formData: FormData = new FormData();
        formData.append('fileKey', file, file.name);
        this.loadingEventsService.changeLoadingState('uploadPassportPhoto', true);
        return this.authHttpService.post<string>(this.urlHelperService.toEventApi(`/v1/promo/${promoReportsId}/upload-media/${ext}`), formData).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('uploadPassportPhoto', false);
            })
        );
    }

    /**
     * Saves promo information
     */
    public saveRsvpInfo(model: RsvpPostModel): Observable<string> {
        this.loadingEventsService.changeLoadingState('savingRSVPInfo', true);
        return this.authHttpService.post<string>(this.urlHelperService.toEventApi(`/v1/promo/${model.promoReportsId}/rsvp`), model).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('savingRSVPInfo', false);
            })
        );
    }
}
