import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthHttpService } from '../../authentication/src/auth-http.service';
import { AuthService } from '../../authentication/src/auth.service';
import { UrlHelperService } from '../../common/src/url-helper.service';
import { ApiNameEnum } from '../../common/src/enums/public-api';
import { throwError, Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { DynamicFormModel, DocumentFormModel, DocumentViewModel, FieldValuesModel, DropdownViewModel, ExternalDynamicFormQueryModel } from './public-api';
import { TranslationService } from '../../cms/src/translation.service';
import moment = require('moment');
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class DynamicFieldsService {
    public forms = new BehaviorSubject<any>(null);
    public countryCode = new BehaviorSubject<any>(null);

    private readonly _apiNameEnum: typeof ApiNameEnum = ApiNameEnum;
    private _formsQuery$ = new Map<string, Observable<DynamicFormModel>>();

    constructor(
        private readonly authHttpService: AuthHttpService,
        private readonly urlHelperService: UrlHelperService,
        private readonly authService: AuthService,
        private readonly translationService: TranslationService
    ) { }



    public getForms(formsQuery: ExternalDynamicFormQueryModel, apiName = this._apiNameEnum.PROFILE, forceReload: boolean = false): Observable<DynamicFormModel> {
        const model: any = this.removeEmpty(formsQuery);

        const query: string = JSON.stringify(model);

        let shouldReturnSavedForm: boolean = false;
        switch (apiName) {
            case this._apiNameEnum.PROFILE:
                shouldReturnSavedForm = this.forms.value
                                        && formsQuery.formType === this.forms.value.schemaType;
                break;
            case this._apiNameEnum.EVENT:
                shouldReturnSavedForm = this.forms.value
                                        && formsQuery.promoId
                                        && formsQuery.promoId === this.forms.value.promoId
                                        && formsQuery.isGuest === this.forms.value.isGuest;
                break;
            default:
                return;
        }

        if (shouldReturnSavedForm && !forceReload) return this.forms;


        if(!this._formsQuery$.get(query)) {

            this._formsQuery$.set(
                query,
                this.authHttpService.get<DynamicFormModel>(this.urlHelperService.toApiByName(apiName, '/v1/form'), { params: model }).pipe(
                    map((dynamicData: DynamicFormModel) => {
                        this.forms.next(dynamicData);
                        this.countryCode.next(formsQuery.countryCode);
                        return dynamicData;
                    }),
                    shareReplay()
            ))
        }

        return this._formsQuery$.get(query)
    }

    public getDynamicFieldData(
        fields: string[],
        signupKey: string,
        country: string,
        schemaType: string,
        cartKey?: string,
        promoId?: number,
        isGuest?: boolean,
        guestNo?: number,
        apiName = this._apiNameEnum.PROFILE
    ): Observable<any> {
        let mainPk: string;
        if (this.authService.isLoggedIn)
            mainPk = this.authService.getMainId().toString();

        const model: any = this.removeEmpty({
            fields,
            signupKey,
            mainPk,
            country,
            schemaType,
            cartKey,
            promoId,
            isGuest,
            guestNo: isGuest ? guestNo : null,
        });

        return this.authHttpService.get<any>(this.urlHelperService.toApiByName(apiName, '/v1/dynamic-fields/get-data'), { params: model }).pipe(
            map((fieldData: any) => {
                return fieldData;
            })
        );
    }

    public setDynamicFieldData(
        fieldValues: FieldValuesModel[],
        signupKey: string,
        country: string,
        schemaType: number,
        cartKey: string,
        promoId?: number,
        isGuest?: boolean,
        apiName = this._apiNameEnum.PROFILE
    ): Observable<any> {
        fieldValues.forEach(fieldValue => {
            if (Array.isArray(fieldValue.value)) { fieldValue.value = fieldValue.value.join(","); }
            if (fieldValue.value === true)
                fieldValue.value = '1';
            else if (fieldValue.value === false)
                fieldValue.value = '0';
        });
        let mainPk: string;
        if (this.authService.isLoggedIn)
            mainPk = this.authService.getMainId().toString();

        const model = this.removeEmpty({
            fieldValues,
            signupKey,
            mainPk,
            country,
            schemaType,
            cartKey,
            promoId,
            isGuest
        });

        return this.authHttpService.post<any>(this.urlHelperService.toApiByName(apiName, '/v1/dynamic-fields/set-data'), model).pipe(
            map((fieldData: any) => {
                return fieldData;
            })
        );
    }

    public validateDynamicFieldData(
        fieldValues: FieldValuesModel[],
        country: string,
        schemaType: string,
        signupKey: string,
        promoId?: number,
        isGuest?: boolean,
        apiName = this._apiNameEnum.PROFILE
    ): Observable<FieldValuesModel[]> {
        let mainPk: string;
        if (this.authService.isLoggedIn)
            mainPk = this.authService.getMainId().toString();

        const model = this.removeEmpty({
            fieldValues,
            mainPk,
            country,
            schemaType,
            signupKey,
            promoId,
            isGuest
        });

        return this.authHttpService.post<any>(this.urlHelperService.toApiByName(apiName, '/v1/dynamic-fields/validation'), model).pipe(
            map((fieldData: any) => {
                return fieldData;
            })
        );
    }

    public updateUserInfo(
        formTemplate: any,
        formGroup: FormGroup,
        signupKey: string,
        country: string,
        schemaType: number,
        cartKey: string,
        mainOrdersFk?: number,
        promoId?: number,
        isGuest?: boolean,
        apiName = this._apiNameEnum.PROFILE
    ): Observable<any> {
        if (formTemplate && formTemplate.inputGroups) {
            const fieldsToUpdate: FieldValuesModel[] = new Array<FieldValuesModel>();
            const inputsToUpdate: any[] = new Array<any>();
            const allInputs: any[] = new Array<any>();
            formTemplate.inputGroups.forEach(inputGroup => {
                inputGroup.inputs.forEach(input => {
                    allInputs.push(input);
                    if ((input.type !== 'document' && input.editable && !input.skipSave) || input.alwaysUpdate) {
                        let value = formGroup.controls[input.name] ? formGroup.controls[input.name].value : null;
                        if (input.type === 'date' && value && value !== '') {
                            // 14 hours is the max absolute value diff from UTC for time zones. Will keep localization from changing to previous date.
                            value = moment(value).add(14, 'hours').format('YYYY-MM-DDThh:mm:ss');
                        }
                        fieldsToUpdate.push({ field: input.name, value: value });
                        inputsToUpdate.push(input);
                    }
                });
            });

            fieldsToUpdate.forEach(fieldValue => {
                if (Array.isArray(fieldValue.value)) { fieldValue.value = fieldValue.value.join(","); }
                if (fieldValue.value === true)
                    fieldValue.value = '1';
                else if (fieldValue.value === false)
                    fieldValue.value = '0';
            });

            let mainPk: string;
            if (this.authService.isLoggedIn)
                mainPk = this.authService.getMainId().toString();

            const model = this.removeEmpty({
                fieldValues: fieldsToUpdate,
                signupKey,
                mainPk,
                country,
                schemaType,
                cartKey,
                promoId,
                isGuest,
                MainOrderPk: mainOrdersFk
            });

            return this.authHttpService.post<any>(this.urlHelperService.toApiByName(apiName, '/v1/dynamic-fields/set-data'), model).pipe(
                map((data: any) => {
                    inputsToUpdate.forEach(output => {
                        if (data && data.filter(x => x.fieldName === output.name && output.type !== 'document').length > 0) {
                            let currentField = data.filter(x => x.fieldName === output.name && output.type !== 'document')[0];
                            let value: string = currentField.fieldValue;
                            if ((!value || value === '') && (output.defaultOutput && output.defaultOutput !== '')) {
                                value = output.defaultOutput;
                                output.isUsingDefault = true;
                            }
                            if (output.boolResponse && value)
                                value = value === '1' ? output.boolResponse[1] : output.boolResponse[0];
                            if (output.isKey && value)
                                value = this.translationService.translate('dynamicFields', value, value);
                            if (output.type === 'date' && output.editable) {
                                // 14 hours is the max absolute value diff from UTC for time zones. Will keep localization from changing to previous date.
                                value = moment(value).add(14, 'hours').format('YYYY-MM-DDThh:mm:ss');
                            }
                            if (currentField.errorResonse && currentField.errorResonse !== '') {
                                output.complexFailText = this.translationService.translate('dynamicFields', currentField.errorResonse, 'Validation Error');
                                formGroup.get(output.name).setErrors({ 'complexFail': true });
                            }
                            else {
                                if (!output.hidden || output.alwaysUpdate) {
                                    if (output.multiple) {
                                        formGroup.controls[output.name].setValue(value.split(","));
                                    } else {
                                        formGroup.controls[output.name].setValue(value);
                                    }
                                }
                                output.fieldValue = value;
                                if (output.lockedIfPopulated && value !== '' && !signupKey)
                                    output.editable = false;
                                else
                                    output.editable = true;
                                if (output.type === 'select' && !output.editable) {
                                    const selectedLabel = output.options.filter(option => option.id === output.fieldValue)[0].label;
                                    output.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                                }
                            }

                        }
                    });
                    this.checkAfterDataRules(formTemplate, inputsToUpdate, allInputs);
                    return data;
                }),
                catchError((e) => {
                    console.log('ERROR', e)
                    return of(null);
                }))
        } else {
            return of({});
        }
    }

    public updateDocStatusForOrder(mainFk: number, cartKey: string, documents: any[]): Observable<any> {
        if (documents.length > 0) {
            return this.authHttpService.post<any>(this.urlHelperService.toStorageApi(`/v1/user-documents/update-for-checkout`), {mainFk, cartKey, documents}).pipe();
        } else {
            return of({});
        }
    }

    public getNeededDocStatus(mainFk: number, signupKey: string = '', country: string = '', mainOrdersFk: number = null, cartKey: string = ''): Observable<DocumentViewModel[]> {
        return this.authHttpService.get<any>(this.urlHelperService.toStorageApi(`/v1/user-documents/needed?mainFk=${mainFk}&signupKey=${signupKey}&country=${country}&mainOrdersFk=${mainOrdersFk}&cartKey=${cartKey}`)).pipe(
            map((docList: DocumentViewModel[]) => {
                return docList.map((doc: DocumentViewModel) => {
                    return doc;
                });
            }));
    }

    public uploadBlob(doc: DocumentFormModel, document: Blob, documentName: string): Observable<any> {
        const formData: FormData = new FormData();
        let data: DocumentFormModel | FormData = doc;
        formData.append('document', document, documentName);
        formData.append('documentFormData', new Blob([JSON.stringify(doc)], { type: 'application/json' }));
        data = formData;

        return this.authHttpService.post<any>(this.urlHelperService.toStorageApi('v1/user-documents'), data);
    }

    public upload(doc: DocumentFormModel, document: File): Observable<any> {
        const formData: FormData = new FormData();
        let data: DocumentFormModel | FormData = doc;
        formData.append('document', document, document.name);
        formData.append('documentFormData', new Blob([JSON.stringify(doc)], { type: 'application/json' }));
        data = formData;

        return this.authHttpService.post<any>(this.urlHelperService.toStorageApi('v1/user-documents'), data);
    }

    public optionsSearch(inputName: string, formsQuery: ExternalDynamicFormQueryModel, fieldValues?: any[], apiName = this._apiNameEnum.PROFILE): Observable<DropdownViewModel[]> {
        let mainPk: string;
        if (this.authService.isLoggedIn && apiName === this._apiNameEnum.EVENT)
            mainPk = this.authService.getMainId().toString();

        const model = this.removeEmpty({
            inputName,
            fieldValues,
            country: formsQuery.countryCode,
            schemaType: formsQuery.formType,
            promoId: formsQuery.promoId,
            isGuest: formsQuery.isGuest,
            mainPk
        });

        return this.authHttpService.post<any>(this.urlHelperService.toApiByName(apiName, '/v1/dynamic-fields/options-search'), model).pipe(
            map((optionsArray: any) => {
                return optionsArray;
            })
        );

        // NOTE: here we use POST just for getting information by request with body
    }

    public checkAfterDataRules(formTemplate: any, inputs: any, allInputs: any) {
        if (formTemplate && formTemplate.rules) {
            formTemplate.rules.forEach(rule => {
                if (rule.type === 'show-if-source-empty') {
                    rule.groups.forEach(group => {
                        const sourceInput = inputs.filter(input => input.name === group.source)[0];
                        if (sourceInput) {
                            let sourceInputValue = sourceInput.fieldValue;
                            if (sourceInputValue !== null && sourceInputValue !== '') {
                                allInputs.filter(input => input.name === group.target)[0].hidden = true;
                            }
                        }
                    });
                }
                else if (rule.type === 'mutuallyExclusive') {
                    rule.groups.forEach(group => {
                        group.fields.forEach(field => {
                            const input = inputs.filter(input => input.name === field)[0];
                            if (input) {
                                input.mutuallyExclusive = [];
                                group.fields.forEach(field2 => {
                                    if (field !== field2)
                                        input.mutuallyExclusive.push(field2);
                                });
                            }
                        });
                    });
                }
                else if (rule.type === 'lock-if-source-populated') {
                    rule.groups.forEach(group => {
                        const sourceInput = inputs.filter(input => input.name === group.source)[0];
                        if (sourceInput) {
                            let sourceInputValue = null;
                            sourceInputValue = sourceInput.fieldValue;
                            if (sourceInputValue !== null && sourceInputValue !== '') {
                                let targetInput = inputs.filter(input => input.name === group.target)[0];
                                targetInput.editable = false;
                                if (targetInput.type === 'select' && targetInput.options) {
                                    let selectedLabel = targetInput.options.filter(option => option.id === targetInput.fieldValue)[0].label;
                                    targetInput.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                                }
                            }
                        }
                    });
                }
                else if (rule.type === 'show-if-empty') {
                    rule.fields.forEach(field => {
                        const inputField = inputs.filter(input => input.name === field)[0];
                        if (inputField && inputField.fieldValue !== null && inputField.fieldValue !== '') {
                            inputField.hidden = true;
                        }
                    });
                }
                else if (rule.type === 'read-only') {
                    rule.fields.forEach(field => {
                        const inputField = inputs.filter(input => input.name === field)[0];
                        if (inputField)
                            inputField.editable = false;
                    });
                }
                else if (rule.type === 'hide-if-empty') {
                    rule.fields.forEach(field => {
                        const inputField = inputs.filter(input => input.name === field)[0];
                        if (inputField && (inputField.fieldValue === null || inputField.fieldValue === '')) {
                            inputField.hidden = true;
                        }
                    });
                }
            });
        }
        formTemplate.inputGroups.forEach(inputGroup => {
            if (inputGroup.inputs.filter(input => (input.fieldValue !== '' && input.hidden !== true) || input.hidden === undefined).length === 0)
                inputGroup.hidden = true;
        });
    }

    private removeEmpty(obj: any): any {
        return _.omitBy(obj, _.isNil);
    }
}
