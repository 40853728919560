import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ProductModel } from '../../../models/public-api';
import { ProductSeoService } from '../../../../products/services/public-api';

@Component({
    selector: 'jn-product-description',
    templateUrl: './product-description.component.html',
    styleUrls: ['./product-description.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductDescriptionComponent implements OnInit {
    @Input() public product: ProductModel;
    public productInfo: any = {};
    public objectKeys = Object.keys;
    constructor(private readonly productSeoService: ProductSeoService) {}

    public ngOnInit(): void {
        this.createDescription();
    }
    public createDescription(): void {
        this.product.additionalInfo.forEach((info) => {
            if (this.isDescriptionContainsContent(info.content)) this.productInfo[info.name] = info.content;
        });
    }

    public isDescriptionContainsContent(content: string): boolean {
        let element = document.createElement('DIV');
        element.innerHTML = content;
        return !!element.textContent.trim();
    }

    public sendAdditionalInfo(name: string): void {
        this.productSeoService.sendGAInfo(this.product, 'open - dropdown - ' + name);
    }
}
