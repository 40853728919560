import { MerchantInstallmentViewInterface } from "./merchant-installment-view.interface";

export class MerchantInstallmentConfigModel {
    public available: boolean;
    public maxInstallments: number;
    public minInstallments: number;
    public minSumInstallment: number;
    public extensionAvailable: boolean;
    public installmentList: string[];
    public viewModel: MerchantInstallmentViewInterface;
    public minOrderTotal: number;

    constructor(options: any = {}) {
        this.available = options.available;
        this.maxInstallments = options.maxInstallments;
        this.minInstallments = options.minInstallments;
        this.minSumInstallment = options.minSumInstallment;
        this.extensionAvailable = options.extensionAvailable;
        this.installmentList = options.installmentList;
        this.viewModel = options.viewModel;
        this.minOrderTotal = options.minOrderTotal;
    }
}