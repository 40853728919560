import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoaderState } from './loader-state.interface';

@Injectable()
/**
 * Used to show or hide our loading component.
 */
export class LoaderService {
    protected loaderSubject = new Subject<LoaderState>();
    /**
     * Current loading state
     */
    public loaderState: Observable<LoaderState> = this.loaderSubject.asObservable();

    constructor() { }

    public show(): void {
        this.loaderSubject.next(<LoaderState>{ show: true });
    }

    public hide(): void {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }
}
