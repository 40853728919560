import { Injectable, OnDestroy } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { SubSink } from "subsink";
import { CheckQualifiedCampaignRequest, CheckQualifiedCampaignResponse } from "../models/qualified-campaign.interface";
import { MainCartApiService } from "./main-cart-api.service";


@Injectable({ providedIn: 'root'})
export class QualifiedCampaignService implements OnDestroy {
  public qualifiedCampaignInfo$: ReplaySubject<CheckQualifiedCampaignResponse> = new ReplaySubject<CheckQualifiedCampaignResponse>();
  private subs = new SubSink();
  constructor(private readonly mainCartApiService: MainCartApiService)
  {}

  public ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  public getCampaignInfo$(): Observable<CheckQualifiedCampaignResponse> {
    return this.qualifiedCampaignInfo$.asObservable();
  }

  public setCampaignInfo$(campaignInfo: CheckQualifiedCampaignResponse): void {
    if (campaignInfo) {
      this.qualifiedCampaignInfo$.next(campaignInfo);
    }
  }

  public checkForCampaignInfo(request: CheckQualifiedCampaignRequest): void {
    this.subs.sink = this.mainCartApiService.checkQualifiedCampaigns(request).subscribe((result) => this.setCampaignInfo$(result));
  }

  public checkForCampaignInfoNoCache(request: CheckQualifiedCampaignRequest): Observable<CheckQualifiedCampaignResponse> {
      return this.mainCartApiService.checkQualifiedCampaigns(request).pipe();
  }
}
