import { Country } from './public-api';

export class MarketingRegion {
    public marketingRegionPK?: number;
    public name?: string;
    public imgUrl?: string;
    public order?: number;
    public countries?: Country[];

    constructor(obj: any) {
        if (obj !== null && obj !== undefined) {
            this.marketingRegionPK = obj.marketingRegionPK;
            this.name = obj.name;
            this.imgUrl = obj.imgUrl;
            this.order = obj.order;
            this.countries = obj.countries;
        }
    }
}
