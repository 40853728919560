import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CmsModule } from '../../cms/src/cms.module';
import { CommonModule as JCommonModule } from '../../common/src/common.module';
import { DynamicFieldsComponent } from './dynamic-fields.component';
import { ExternalDynamicFieldsComponent } from './external-dynamic-fields/external-dynamic-fields.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatRadioModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatProgressBarModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        CmsModule,
        JCommonModule,
        ScrollingModule
    ],
    exports: [DynamicFieldsComponent, ExternalDynamicFieldsComponent],
    declarations: [DynamicFieldsComponent, ExternalDynamicFieldsComponent],
    providers: [DatePipe]
})
export class DynamicFieldsModule {}
