import { LoaderService } from './loader.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { LoaderComponent } from './loader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule
    ],
    declarations: [LoaderComponent],
    exports: [LoaderComponent],
    bootstrap: []
})
/**
 * Loader Module
 */
export class LoaderModule {
    public static forRoot(): ModuleWithProviders<LoaderModule> {
      return {
        ngModule: LoaderModule,
        providers: [
          LoaderService
        ]
      };
    }
  }
