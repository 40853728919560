import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ProductModel, ProductConfigModel } from '../../models/public-api';
import * as lodash from 'lodash';

const _ = lodash;

@Component({
    selector: 'jn-config-modal',
    templateUrl: './config-modal.component.html',
    styleUrls: ['./config-modal.component.scss']
})

/**
 * Modal component to allow user's to select product configurations.
 */
export class ConfigModalComponent implements OnInit {
    public product: ProductModel;
    public showNVShadeMatch: boolean;
    public selectedConfigs: Array<ProductConfigModel> = new Array<ProductConfigModel>();
    public groupedConfigurations: Array<Array<ProductConfigModel>> = new Array<Array<ProductConfigModel>>();
    public alreadyInSD: boolean;
    public disableSelection: boolean = false;
    public isComingSoon: boolean = false;
    constructor(public dialogRef: MatDialogRef<ConfigModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProductModel) {
        this.product = data;

    }

    public ngOnInit(): void {
        //
        // This is NV foundation only check here to show the shade match functionality.
        if (this.product.productCode && (this.product.productCode.startsWith('NVCONF')
            || this.product.productCode.startsWith('NVEVERYDAY')
            || this.product.productCode.startsWith('NVGETTHEGLOWBUNDLE')))
            this.showNVShadeMatch = true;
        else
            this.showNVShadeMatch = false;

        this.groupConfiguration(this.product.configurations);
        this.disableSelection = this.product.configurations.some( x => x.productPK === 0 );
    }

    public groupConfiguration(allConfigs) {
        let groupedConfigs: any =  _(allConfigs)
                                    .groupBy(item => item.configurableProductPK)
                                    .sortBy(group => allConfigs.indexOf(group[0]))
                                    .value();
        this.groupedConfigurations = _.map(groupedConfigs, (configs, key) => {
            return configs;
        });
    }

    /**
     * Adds the configuration item to the cart.
     */
    public addToCart(): void {
        let lastModel: Array<ProductConfigModel> = new Array<ProductConfigModel>();
        _.forEach(this.selectedConfigs, ((config, key) => {
            let model: ProductConfigModel = new ProductConfigModel(null);
            model.packageFK = config.packageFK;
            model.configurableProductPK = config.configurableProductPK;
            model.productPK = config.productPK;
            model.productCode = config.productCode;
            model.productName = config.productName;
            model.description = config.description;
            model.image = config.image;
            lastModel.push(model);
        }))
        this.dialogRef.close(lastModel);
    }

    /**
     * Fires when a specific configuration is selcted.
     * @param config Product configuration model
     */
    public onSelected(config: ProductConfigModel): void {
        _.remove(this.selectedConfigs, c => {
            return c.configurableProductPK === config.configurableProductPK;
        });
        this.selectedConfigs.push(config);
        this.isComingSoon = this.selectedConfigs && this.selectedConfigs.some(x => x.isComingSoon);

        if (this.product.addToSmartDelivery && this.product.isConfigurable) {
            this.alreadyInSD = false;
            if (this.product.isConfigurable && this.product.selectedSDConfig.some(x => x.productPK === config.productPK))
                this.alreadyInSD = true;
        }
    }
}