import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/operators';
import { FieldConfigModel } from '../models/field.model';
import { EventsApiService } from '../services/events-api.service';
import { CmsSessionService, LocaleService, TranslationService } from '@jeunesse/angular';

@Component({
    selector: 'jn-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFormComponent implements OnInit, AfterViewInit {
    public fields: FieldConfigModel[] = [];
    public oldSiteUrl = '';
    public genderIndex: number;
    public mainFk: number = null;
    public translation: { [key: string]: string } = {};
    public form: FormGroup;
    @Input() public fields$: Observable<FieldConfigModel[]>;
    @Output() public update: EventEmitter<any> = new EventEmitter<any>();
    get value(): any {
        return this.form.value;
    }

    // private isGuest: boolean;

    constructor(
        private readonly fb: FormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService,
        private readonly eventApiService: EventsApiService
    ) {}

    public ngOnInit(): void {
        this.form = this.createControl();
        this.fields$.subscribe((data) => {
            this.fields = data;
            this.form = this.createControl();
            if (this.fields[0] && this.fields[0].name === 'isDistributor') {
                this.rules();
            }
            this.detectChanges();
        });
        this.form.valueChanges.subscribe((value: any) => {
            this.update.emit(value);
        });
        // Assign translations
        this.getTranslations();
    }
    public createControl(): FormGroup {
        const group: FormGroup = this.fb.group({});
        this.fields.forEach((field, i) => {
            field.index = i;
            if (field.name === 'gender') {
                this.genderIndex = i;
            } else if (field.name === 'email') {
                group.addControl(field.name, new FormControl(field.value, [Validators.required, Validators.email]));
            } else if (field.name === 'homePhone' || field.name === 'cellPhone') {
                group.addControl(field.name, new FormControl(field.value, [Validators.required, Validators.pattern("^[0-9]{10,11}$")]));
            } else {
                group.addControl(field.name, new FormControl(field.value, [Validators.required]));
            }
        });
        return group;
    }
    // need to check if form valid or not only afterViewChecked hook, otherwise will have an error
    public ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    public rules(): void {
        let siteUrl: AbstractControl = this.form.get('siteURL');
        let isDistributorCtr: AbstractControl = this.form.get('isDistributor');
        let lNameCtr: AbstractControl = this.form.get('lastName');
        let fNameCtr: AbstractControl = this.form.get('firstName');
        let gender: AbstractControl = this.form.get('gender');

        if (isDistributorCtr && isDistributorCtr.value) {
            siteUrl.setAsyncValidators(this.siteUrlValidation.bind(this)());
            lNameCtr.disable({ emitEvent: false });
            fNameCtr.disable({ emitEvent: false });
            siteUrl.enable({ emitEvent: false });
            // need new object assigning to trigger change detection
            if (this.genderIndex) {
                gender.disable({ emitEvent: false });
                this.fields[this.genderIndex].style.desktop = { ...this.fields[this.genderIndex].style.desktop, display: 'none' };
                gender.setValue(null);
            }
            this.oldSiteUrl = '';
        } else {
            siteUrl.clearAsyncValidators();
            siteUrl.disable({ emitEvent: false });
            lNameCtr.enable({ emitEvent: false });
            fNameCtr.enable({ emitEvent: false });
            if (this.genderIndex) {
                gender.enable({ emitEvent: false });
                this.fields[this.genderIndex].style.desktop = { ...this.fields[this.genderIndex].style.desktop, display: 'block' };
            }
            this.mainFk = null;
        }
        if (isDistributorCtr) {
            isDistributorCtr.valueChanges.subscribe((isDistributor: boolean) => {
                this.form.markAsUntouched();
                this.form.patchValue({ lastName: null, firstName: null, gender: null, siteURL: null }, { emitEvent: false });

                if (isDistributor) {
                    siteUrl.setAsyncValidators(this.siteUrlValidation.bind(this)());
                    lNameCtr.disable({ emitEvent: false });
                    fNameCtr.disable({ emitEvent: false });
                    siteUrl.enable({ emitEvent: false });
                    // need new object assigning to trigger change detection
                    if (this.genderIndex) {
                        gender.disable({ emitEvent: false });
                        this.fields[this.genderIndex].style.desktop = { ...this.fields[this.genderIndex].style.desktop, display: 'none' };
                        gender.setValue(null);
                    }
                    this.oldSiteUrl = '';
                } else {
                    siteUrl.clearAsyncValidators();
                    siteUrl.disable({ emitEvent: false });
                    isDistributorCtr.clearAsyncValidators();
                    isDistributorCtr.disable({ emitEvent: false });
                    lNameCtr.enable({ emitEvent: false });
                    fNameCtr.enable({ emitEvent: false });
                    if (this.genderIndex) {
                        gender.enable({ emitEvent: false });
                        this.fields[this.genderIndex].style.desktop = { ...this.fields[this.genderIndex].style.desktop, display: 'block' };
                    }
                    this.mainFk = null;
                }
            });
        }
    }

    public isGuestCheck(): void {
        let isDistributorCtr: AbstractControl = this.form.get('isDistributor');
        let lNameCtr: AbstractControl = this.form.get('lastName');
        let fNameCtr: AbstractControl = this.form.get('firstName');

        if((isDistributorCtr && isDistributorCtr.value == null) && fNameCtr.value && lNameCtr.value) {
            isDistributorCtr.disable({ emitEvent: false });
        }
    }
    // detect changes from outside
    public detectChanges(): void {
        this.cdr.detectChanges();
    }

    private siteUrlValidation(): AsyncValidatorFn {
        let siteUrl: AbstractControl = this.form.get('siteURL');

        return (ctr: AbstractControl): Observable<{ [key: string]: string | boolean } | null> => {
            const value: string = ctr.value;
            if (!value || value.length === 0) {
                return of({ required: 'required' });
            }
            return of(value).pipe(
                switchMap(() => this.eventApiService.distributorLookup(value)),
                map((data) => {
                    if(data.mainPK > 0 && data.status === 'A') {
                        this.mainFk = data.mainPK; // data.mainFK; //
                        this.form.patchValue({ firstName: data.firstName, lastName: data.lastName });
                        this.oldSiteUrl = ctr.value;
                        return null;
                    } else {
                        this.form.patchValue({ firstName: '', lastName: ''}); //siteURL: '',
                        //this.form.markAsUntouched();
                        return { inactiveSiteUrl: 'inactiveSiteUrl' };
                    }
                })
            );
        };
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe(culture => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translation.required = this.translationService.translate('events', 'required', 'Required field');
        this.translation.inactiveSiteUrl = this.translationService.translate('events', 'inactiveSiteUrl', 'Inactive SiteURL');
        this.translation.invalidEmail = this.translationService.translate('events', 'invalidEmail', 'Invalid email address');
        this.translation.invalidValue = this.translationService.translate('events', 'invalidValue', 'Invalid value entered');
    }
}
