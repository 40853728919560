import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RepSiteService, RepSiteInfo } from '@jeunesse/cart-lib';
import { map } from 'rxjs/operators';
import { ISiteSettings } from './shared/helpers/site-settings.interface';

declare const SiteSettings: ISiteSettings;

@Injectable({
  providedIn: 'root'
})
export class RepGuard implements CanActivate {
  constructor(private readonly repSiteService: RepSiteService,
    private readonly router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean | UrlTree> | UrlTree {
    const siteUrl: string = route.paramMap.get('siteUrl') ?? SiteSettings.defaultSiteUrl;
    const locale: string = route.paramMap.get('locale');
    return this.repSiteService.loadRepInfo(siteUrl)
          .pipe(map((repSiteInfo: RepSiteInfo) => {
            // Check if valid
            if (!repSiteInfo || !repSiteInfo.canAllowRetailCustomer) {
              return this.router.parseUrl(`/${locale}/${SiteSettings.defaultSiteUrl}`);
            }
            return true;
          }));
  }
}
