import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { RepSiteInfo, RepSiteService } from '@jeunesse/cart-lib';

@Injectable({
    providedIn: 'root'
})
export class RepSiteResolver implements Resolve<RepSiteInfo> {
    constructor(
        private readonly service: RepSiteService) { }

    public resolve(route: ActivatedRouteSnapshot): Observable<RepSiteInfo> {
        const siteUrl: string = route.paramMap.get('siteUrl');
        return this.service.loadRepInfo(siteUrl).pipe();
    }
}
