import { createReducer, Action, on } from '@ngrx/store';
import * as EventInfoActions from '../actions/event-info.actions';
import { EventInfo } from '../../models/public-api';
import { EventInfoState } from '../../models/event-info-state.model';

const initialState: any = {
    eventInfo: {},
    error: ''
};

const eventReducer = createReducer(initialState,
    on(EventInfoActions.updateEventInfo, (state: EventInfoState, eventInfo: EventInfo) => {
        state = { eventInfo,  error: ''};
        return { ...state, error: '' };
    })
);

export const eventInfoReducer = function reducer(state: EventInfoState | undefined, action: Action) {
    return eventReducer(state, action);
};