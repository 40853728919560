import { Component, OnInit, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { IconModel } from './models/icon.interface';

/**
 * Icon presentation box works with Icon Selector Component and independently.
 */
@Component({
    selector: 'jn-icon-box',
    templateUrl: './icon-box.component.html',
    styleUrls: ['./icon-box.component.scss'],
    host: {
        'class': 'jn-icon-box'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconBoxComponent implements OnInit {
    /**
     * Icon Model to be presented in the box.
     * If the component is used independently only use IconModel.
     */
    @Input()
    public icon: IconModel;

     /**
      * Changes the state of the box to an active state,
      * also adds behavior to use the box as a button.
      * Default is 'false' meaning the box acts as a label.
      */
     @Input()
     public set active(active: boolean) {
          if (active !== undefined) {
                this.actionableClass = true;
                this.toggleActiveState(active);
          } else {
                this.toggleActiveState(false);
          }
    }

     /**
      * @ignore
      */
     @HostBinding('class.mat-elevation-z1')
     public matElevation1: boolean = true;

     /**
      * @ignore
      */
     @HostBinding('class.mat-elevation-z3')
     public matElevation3: boolean = false;

     /**
      * @ignore
      */
     @HostBinding('class.active')
     public activeClass: boolean = false;

     /**
      * @ignore
      */
     @HostBinding('class.actionable')
     public actionableClass: boolean = false;

    constructor() {
    }

     /**
      * @ignore
      */
     public ngOnInit(): void {

          // if (this.icon instanceof IconBoxModel) {
          //     this.iconClass    = `${this.icon.fontSet}-${this.icon.id}`;
          //     this.iconName     = this.icon.id.replace('-', ' ');
          //     this.iconFontSet = this.icon.fontSet;
          // }

          // if (this.icon instanceof IconModel) {
          //     this.iconClass    = this.icon.fontIcon;
          //     this.iconName     = this.icon.fontIcon.replace(this.iconFontSet, '').replace('-', ' ');
          //     this.iconFontSet = this.icon.fontSet;
          // }

     }

     private toggleActiveState(active: boolean = false): void {
          this.matElevation1 = !active;
          this.matElevation3 = active;
          this.activeClass = active;
    }
}
