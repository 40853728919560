import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthHttpService } from '../../../authentication/src/auth-http.service';
import { UrlHelperService } from '../../../common/src/url-helper.service';
import { DocumentsVideoModel } from '../models/public-api';

@Injectable()
export class DocumentsVideoService {
    constructor(private readonly authHttp: AuthHttpService, private readonly urlHelper: UrlHelperService) {}

    /**
     * GET ALL VIDEO FROM DOCUMENTS UPLOADING TOOL BY USAGE
     *
     * @author Alex Togo
     * @date 2019-11-13
     * @param {string} country String 'US'
     * @param {number} language Number 1
     * @param {string} usage String 'join'
     * @returns {Observable<DocumentsVideoModel[]>} USED DocumentsVideoModel
     * @memberof DocumentsVideoService
     */
    public getDocumentsbyCountryLanguage(country: string, language: number, usage: string): Observable<DocumentsVideoModel[]> {
        return this.authHttp
            .get<any>(this.urlHelper.toStorageApi(`/v1/documents/docs-by-lang-country?country=${country}&languageFk=${language}&usage=${usage}`))
            .pipe(
                map((data: any) => {
                    return data.map((data: any) => {
                        return new DocumentsVideoModel(data);
                    });
                })
            );
    }
}
