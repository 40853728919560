import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

import { ProductModel, BestSellerModel, BrandViewModel } from '../models/public-api';
import { AuthService } from '@jeunesse/angular';

@Injectable(
    {
    providedIn: 'root'
}
)
export class ProductStateService {
    private productsState: any = {
        products: [],
        spotlightProducts: [],
        bestSellerProducts: []
    };
    private productsDisplayState: string = this.authService.isLoggedIn ? 'row' : 'column';
    private readonly productsState$ = new BehaviorSubject<any>(this.productsState);
    private readonly products$ = new BehaviorSubject<ProductModel[]>(this.productsState.products);
    private readonly spotlightProducts$ = new BehaviorSubject<BrandViewModel[]>(this.productsState.spotlightProducts);
    private readonly bestSellerProducts$ = new BehaviorSubject<BestSellerModel[]>(this.productsState.bestSellerProducts);
    private readonly productsDisplayState$ = new BehaviorSubject<string>(this.productsDisplayState);
    constructor( private readonly authService: AuthService) {

    }

    // Get Products, Spotlight, and Best Sellers
    public getProductsState$(): Observable<any> {
        return this.productsState$.asObservable();
    }
    // Get the way we want display product (grid or list)
    public getProductsDisplayState$(): Observable<any> {
        return this.productsDisplayState$.asObservable();
    }
    // Set the way we want display product (grid or list)
    public setProductsDisplayState$(productsDisplayState: string): void {
        this.productsDisplayState = productsDisplayState;
        this.productsDisplayState$.next(this.productsDisplayState);
    }

    // Get products
    public getProducts$(): Observable<any> {
        return this.products$.asObservable();
    }

    // Get spotlight products
    public getSpotlightProducts$(): Observable<any> {
        return this.spotlightProducts$.asObservable();
    }

    // Get best seller products
    public getBestSellerProducts$(): Observable<any> {
        return this.bestSellerProducts$.asObservable();
    }

    // Set products
    public setProducts(products: ProductModel[]): void {
        this.productsState.products = products;
        this.products$.next(this.productsState.products);
    }

    // Set  spotlight products
    public setSpotlightProducts(products: BrandViewModel[]): void {
        this.productsState.spotlightProducts = products;

        this.spotlightProducts$.next(this.productsState.spotlightProducts);
    }

    // Set best seller products
    public setBestSellerProducts(products: BestSellerModel[]): void {
        this.productsState.bestSellerProducts = products;

        this.bestSellerProducts$.next(this.productsState.bestSellerProducts);
    }
}
