/**
 * DistributorLookup model
 */
 export class DistributorLookupModel {
    public mainPK?: number;
    public country: string;
    public languageFk: number;
    public firstName: string;
    public lastName: string;
    public status: string;

    constructor(options: any = {}) {
        this.mainPK = options.mainPK;
        this.country = options.country;
        this.languageFk = options.languageFk;
        this.firstName = options.firstName;
        this.lastName = options.lastName;
        this.status = options.status;
    }
}