export class ShippingMethodViewModel {
    public name: string;
    public cost: number;
    public shippingCompany: string;
    public packageType: string;
    public confirmationType: string;
    public shippingChartType: number;
    public currencySymbol: string;
    public signatureRequired: boolean;
    public isSignatureSelectionRequired: boolean;
    public signatureOffered: boolean;
    public originalCurrencyCost: number;
    public displayCost: string;
    public signatureFee: number;
    public signatureMessage: string;
    public shippingMessage: string;
    public selectedVal?: number;
    public formattedShipping?: string;
    public signaturePromptFee?: string;
    public signaturePromptNoFee?: string;
    public isPickup?: boolean;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}

export class ShippingMethodsViewModel {
    public methods: ShippingMethodViewModel[];
    public message: string;
    public showShipFilter: boolean;
    constructor(obj: any) {
        Object.assign(this, obj);
    }
}
