import { Injectable } from'@angular/core';
import { IAppSettings } from './models/public-api';
import { environment } from '../../../config/environment';

/**
 * @ignore
 */
declare const AppSettings: IAppSettings;

/**
 * Class used for settings.
 */
@Injectable({
    providedIn: 'root'
})
export class SettingsService implements IAppSettings {
    public env: 'LOCAL' | 'DEV' | 'QA' | 'STG' | 'PROD';
    public appKey: string;
    public cookieDomain: string;
    public claim?: 'ADMIN' | 'JOFFICE' | 'SHOP' | 'FRONT';
    public jOffice?: string;
    public classicSiteUrl?: string;
    public shop?: string;
    public jFront: string;
    public joinUrl: string;
    public crmUrl: string;
    public shareUrl: string;
    public events?: string;
    public cartApi?: string;
    public cmsApi?: string;
    public crmApi?: string;
    public jFrontApi?: string;
    public eventApi?: string;
    public sharedApi?: string;
    public securityApi?: string;
    public helpDeskApi?: string;
    public profileApi?: string;
    public storageApi?: string;
    public liveChatApi?: string;
    public liveApi?: string;
    public easyMatchApi?: string;
    public siteName?: string;
    public reportsApi?: string;
    public client?: string = 'T01';
    constructor() {
        if (typeof AppSettings !== 'undefined') {
            this.env = AppSettings.env;
            this.appKey = AppSettings.appKey;
            this.cookieDomain = AppSettings.cookieDomain;
            this.claim = AppSettings.claim;
            this.jOffice = AppSettings.jOffice;
            this.classicSiteUrl = AppSettings.classicSiteUrl;
            this.shop = AppSettings.shop;
            this.jFront = AppSettings.jFront;
            this.joinUrl = AppSettings.joinUrl;
            this.crmUrl = AppSettings.crmUrl;
            this.shareUrl = AppSettings.shareUrl;
            this.events = AppSettings.events;
            this.cartApi = AppSettings.cartApi;
            this.cmsApi = AppSettings.cmsApi;
            this.crmApi = AppSettings.crmApi;
            this.jFrontApi = AppSettings.jFrontApi;
            this.eventApi = AppSettings.eventApi;
            this.sharedApi = AppSettings.sharedApi;
            this.securityApi = AppSettings.securityApi;
            this.helpDeskApi = AppSettings.helpDeskApi;
            this.profileApi = AppSettings.profileApi;
            this.storageApi = AppSettings.storageApi;
            this.liveChatApi = AppSettings.liveChatApi;
            this.liveApi = AppSettings.liveApi;
            this.easyMatchApi = AppSettings.easyMatchApi;
            this.siteName = AppSettings.siteName;
            this.reportsApi = AppSettings.reportsApi;
            this.client = AppSettings.client;
        }
        else {
            this.env = environment.env;
            this.appKey = environment.appKey;
            this.cookieDomain = environment.cookieDomain;
            this.claim = environment.claim;
            this.jOffice = environment.jOffice;
            this.classicSiteUrl = environment.classicSiteUrl;
            this.shop = environment.shop;
            this.jFront = environment.jFront;
            this.joinUrl = environment.joinUrl;
            this.crmUrl = environment.crmUrl;
            this.shareUrl = environment.shareUrl;
            this.events = environment.events;
            this.cartApi = environment.cartApi;
            this.cmsApi = environment.cmsApi;
            this.crmApi = environment.crmApi;
            this.jFrontApi = environment.jFrontApi;
            this.eventApi = environment.eventApi;
            this.sharedApi = environment.sharedApi;
            this.securityApi = environment.securityApi;
            this.helpDeskApi = environment.helpDeskApi;
            this.profileApi = environment.profileApi;
            this.storageApi = environment.storageApi;
            this.liveChatApi = environment.liveChatApi;
            this.liveApi = environment.liveApi;
            this.easyMatchApi = environment.easyMatchApi;
            this.siteName = environment.siteName;
            this.reportsApi = environment.reportsApi;
            this.client = environment.client;
        }
    }
}
