import { Component, Input, OnInit } from '@angular/core';

interface ToolTipModel {
    imgSrc?: string;
    imgAlt?: string;
    txt?: string;
    iconName?: string;
    iconColor?: string;
    direction?: string;
}

@Component({
    selector: 'jn-tool-tip',
    templateUrl: './tool-tip.component.html',
    styleUrls: ['./tool-tip.component.scss']
})
export class ToolTipComponent implements OnInit {
    @Input() public source: ToolTipModel[] = [];
    constructor() {}

    public ngOnInit(): void {}
}
