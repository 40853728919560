
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { AuthService, IAppSettings, LogoutRedirectEnum, SecurityService, TokenModel, UrlHelperService } from '@jeunesse/angular';
import { CmsSessionService, LocaleService, TranslationService } from '@jeunesse/angular';
import { SettingsService, SeoService } from '@jeunesse/angular';
import { interval } from 'rxjs';
import { RepSiteInfo, RepSiteService } from '../../../repsite/public-api';
import { LoginOverlayRef } from '../../overlays/login/login-overlay';
import { LoginOverlayService } from '../../overlays/login/login-overlay.service';
import { MenuService } from '../../services/menu.service';
import { ANIMATIONS } from '../animations';
import { MainCartService } from '@jeunesse/slide-cart';
import { AddressModel, MemberService, SmartDeliveryService } from '../../../member/public-api';
import { SubSink } from 'subsink';
import { MenuAlertModel } from '../../../shared/models/menuAlert.model';
import { MenuStateService } from '../../services/menu-state.service';
import { PolicyBoolValueEnum } from '@jeunesse/angular/lib/security';


declare const AppSettings: IAppSettings;

@Component({
    selector: 'jn-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    animations: [ANIMATIONS.transformMenu, ANIMATIONS.transformWidth]
})
export class UserMenuComponent implements OnInit, OnDestroy {

    @ViewChild('menuOverlayOrigin') public menuOrigin !: CdkOverlayOrigin;
    public showAlert: boolean;
    public unreadCount: number = 0; // Total of unread notifications of any kind to display. Badge is dissplayed if number > 0.
    public isLoggedIn: boolean;
    @Input()
    public repInfo?: RepSiteInfo;
    public cartType: string;
    public culture: string;
    public friendlyName: string;
    public clientName: string;
    public site: string;
    public supportLinkURL: string = null;
    public menuAlerts: MenuAlertModel[];
    public translations: any = {
        hi: '',
        support: '',
        liveChat: '',
        alertMessage: '',
        unreadMessagesTooltip: '',
    };
    public alertMessage: string = '';
    public showChatMenu: boolean = true;
    public showSupportMenu: boolean;
    @Output() loginComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    private logonTimer: any;
    private subs = new SubSink();

    constructor(
        public readonly media: MediaObserver,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly securityService: SecurityService,
        private readonly memberService: MemberService,
        private readonly mainCartService: MainCartService,
        private readonly loginOverlayService: LoginOverlayService,
        private readonly repSiteService: RepSiteService,
        private readonly seoService: SeoService,
        private readonly menuService: MenuService,
        private readonly menuStateService: MenuStateService,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService,
        private readonly urlHelperService: UrlHelperService,
        private readonly settings: SettingsService,
        private readonly smartDeliveryService: SmartDeliveryService) {
        this.isLoggedIn = this.authService.isLoggedIn;
        this.setName();
        this.site = this.settings.siteName;
    }

    public ngOnInit(): void {
        this.site = this.settings.siteName;
        this.isLoggedIn = this.authService.isLoggedIn;
        this.setName();
        this.logonTimer = interval(120000)
            .subscribe((val) => {
                this.logonCheck();
            });
        this.getTranslations();
        this.authService.tokenChanged.subscribe((result) => {
            // Get menu alert notification data
            this.isLoggedIn = result;
            this.setName();
            // KLk - Task: 45127
            if (this.isLoggedIn) {
                this.checkMenuPolicy();
            }
        });
        this.menuStateService.getMenuAlert().subscribe((data) => {
            this.alertMessage = data.length === 1 ? data[0].warningText :  this.translations.alertMessage;
            this.showAlert = data.length > 0;
        });
        this.menuStateService.getMenuUnreadCount().subscribe((data) => {
            this.unreadCount = 0;
            if (data !== null) {
                data.forEach(m => this.unreadCount += parseInt(m.warningText, 10));
            }
        });
    }

    public ngOnDestroy(): void {
        this.killTimer();
        this.subs.unsubscribe();
    }

    /**
     * Called when the sign in button is pressed.
     */
    public onSignIn(): void {
        this.openLoginOverlay();
    }

    public supportLink(): string {
        if (this.supportLinkURL) {
            return this.supportLinkURL;
        }
        if (this.isLoggedIn) {
            this.supportLinkURL = this.menuService.toEnvLink(`${AppSettings.jOffice}/helpdesk/categories`);
        } else {
            this.supportLinkURL = this.menuService.toEnvLink(`https://www.${AppSettings.cookieDomain}/en-US/contact-us`);
        }
        return this.supportLinkURL;
    }

    public sendGAInfo(category: string, action: string, label?: string): void {
        if (label === undefined) {
            label = this.router.url;
        }
        this.seoService.trackClick(label, action, category);
    }

    public checkMenuPolicy(): void {
        if (this.site.toUpperCase() !== 'JOFFICE2') {
            this.showChatMenu = false; // Only show for JOffic2
        }
        if (this.site.toUpperCase() !== 'EVENTS') {
            this.subs.sink = this.securityService.loadPolicies('SUPPORT').subscribe(() => {
                this.showSupportMenu = this.securityService.policy('SUPPORT') === PolicyBoolValueEnum.YES;
            });
        }
        if (this.site.toUpperCase() === 'JOFFICE2') {
            this.subs.sink = this.securityService.loadPolicies('CHAT').subscribe(() => {
                this.showChatMenu = this.securityService.policy('CHAT') === PolicyBoolValueEnum.YES;
            });
        }
    }

    /**
     * Opens the login overlay screen.
     */
    public openLoginOverlay(): void {
        const overlay: LoginOverlayRef = this.loginOverlayService.open({
            // Attach the overlay to the menu or full screen when on small screens.
            showAlert: this.showAlert,
            flexibleConnectedTo:  {
                    origin: this.menuOrigin,
                    positions: [{ originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top' }]
                }
        });

        this.subs.sink = overlay.afterClosed().subscribe((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                // If distributor account is pending send them back to signup
                if (this.authService.getStatus() === 'P' && [1,22].includes(this.authService.getMainTypeId())) {
                    location.href = this.urlHelperService.buildUrl(AppSettings.classicSiteUrl, '/Pending.asp');
                } else if (!['A', 'I', 'P', 'H'].includes(this.authService.getStatus())) {
                    this.authService.logout(LogoutRedirectEnum.LOGIN).subscribe();
                } else {

                    // Set culture if not a logged in CMS Admin user
                    if (!this.translationService.isTranslator()) {
                        const userCulture: string = this.authService.getCulture();
                        this.subs.sink = this.localeService.isCultureSupported(userCulture).subscribe(culture => {
                            this.culture = culture;
                            this.localeService.setCultureCookie(culture);
                        });
                    }
                    // Task: 40026 - Set cookie to logged in user's country if they physically log in.
                    this.localeService.setCountryCode(this.authService.getCountry(), true);

                    // Load the logged in user's rep info and SmartDelivery provile
                    if ([1, 2, 21, 22].includes(this.authService.getMainTypeId())) {
                        this.subs.sink = this.repSiteService.loadRepInfo().subscribe((repInfo: RepSiteInfo) => {
                            if (repInfo) {
                                this.repInfo = repInfo;
                                // Task: 44227 - KLK Set country to main user's address2 value (ship country)
                                this.subs.sink = this.memberService.getShippingAddress().subscribe((address: AddressModel) => {
                                    // Task: 40026 - Set cookie to logged in user's country if they physically log in.
                                    this.localeService.setCountryCode(address.country || this.authService.getCountry(), true);
                                    // Update shopping cart with the user's info
                                    const model: any = {
                                        mainFk: this.authService.getMainId(),
                                        countryCode: address.country || this.authService.getCountry(),
                                        culture: this.authService.getCulture(),
                                        mainReferrerFK: repInfo.mainPk,
                                        referrerSiteUrl: repInfo.alias
                                    };
                                    this.mainCartService.updateCart(model).subscribe();
                                }, error => {
                                    // Update shopping cart with the user's info
                                    const model: any = {
                                        mainFk: this.authService.getMainId(),
                                        countryCode: this.authService.getCountry(),
                                        culture: this.authService.getCulture(),
                                        mainReferrerFK: repInfo.mainPk,
                                        referrerSiteUrl: repInfo.alias
                                    };
                                    this.mainCartService.updateCart(model).subscribe();
                                });
                            }
                        });
                        this.subs.sink = this.smartDeliveryService.getSettings$().subscribe();
                    } else {
                        // Update shopping cart with the user's info
                        const csrTypes = [3, 6, 7, 11, 17];
                        let cartModel: any = {}
                        if (csrTypes.includes(this.authService.getMainTypeId())) {
                            cartModel = {
                                mainFk: this.authService.getMainId(),
                                countryCode: this.authService.getCountry(),
                                culture: this.authService.getCulture(),
                                cartType: 'W'
                            };
                        } else {
                            cartModel = {
                                mainFk: this.authService.getMainId(),
                                countryCode: this.authService.getCountry(),
                                culture: this.authService.getCulture()
                            };
                        }
                        this.mainCartService.updateCart(cartModel).subscribe();
                    }
                    this.isLoggedIn = true;
                    this.setName();
                    this.menuService.reloadMenus();
                }
            }
            this.loginComplete.emit(isLoggedIn);
        });
    }

    public goToMessageCenter(): void {
        if (this.settings.siteName === 'JOffice2') {
            this.router.navigate(['message-center'])
        } else {
            window.location.href = this.urlHelperService.buildUrl(AppSettings.jOffice, '/message-center');
        }
    }

    private killTimer(): void {
        try {
            if (this.logonTimer) {
                this.logonTimer.unsubscribe();
            }
        }
        catch (e) { }
    }

    private setName(): void {
        if (this.authService.isLoggedIn) {
            let token: TokenModel = this.authService.getAuthToken();
            this.clientName = token.firstName + ' ' + token.lastName;
            // console.log('clientName=' + this.clientName);
        }
    }

    private logonCheck(): void {
        if (this.isLoggedIn !== this.authService.isLoggedIn) {
            if (!this.authService.isLoggedIn) {
                this.killTimer();
                this.friendlyName = '';
            }
            this.isLoggedIn = this.authService.isLoggedIn;
            this.setName();
            this.menuService.loadMenus();
        }
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.subs.sink = this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.support = this.translationService.translate('menu-library', 'support', 'Support');
        this.translations.hi = this.translationService.translate('menu-library', 'hi', 'Hi');
        this.translations.liveChat = this.translationService.translate('menu-library', 'liveChat', 'Live Chat');
        this.translations.alertMessage = this.translationService.translate('menu-library', 'alertMessage', 'Click to resolve Account issues');
        this.translations.unreadMessagesTooltip = this.translationService.translate('menu-library', 'unread-messages-tooltip', 'Open Message Center');
    }
}
