export class AddressAutoCompleteModel {
    public description: string;
    public address1: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public country: string;
    public longitude: string;
    public latitude: string;
    public placeId: string;

    constructor(options: any = {}) {
        this.description = options.description;
        this.address1 = options.address1;
        this.city = options.city;
        this.state = options.state;
        this.zipCode = options.zipCode;
        this.country = options.country;
        this.longitude = options.longitude;
        this.latitude = options.latitude;
        this.placeId = options.placeId;
    }
}