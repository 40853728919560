import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { CmsModule } from '../../cms/src/cms.module';
import { ExportDataSourceButtonComponent } from '../components/export-data-source-button/export-data-source-button.component';
import { MatPaginationIntlService } from './paginator-intl.service';

@NgModule({
    imports: [
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        CmsModule,
    ],
    exports: [ExportDataSourceButtonComponent],
    declarations: [ExportDataSourceButtonComponent],
    providers: [MatPaginationIntlService],
})
export class TableModule {}
