/**
 * Add Payment model
 */
export class PaymentFormModel{
    public ccNumber: string;
    public sequencePk: number;
    public ccType: string;
    public ccExpireMonth: string;
    public ccExpireYear: string;
    public ccCvv: string;
    public useOrder: number;
    public oldUseOrder: number;
    public ccName: string;
    public ccBillingAddr1: string;
    public ccBillingAddr2: string;
    public ccBillingAddr3: string;
    public ccBillingCity: string;
    public ccBillingState: string;
    public ccBillingCountry: string;
    public ccBillingZip: string;
    public ccBillingPhone: string;
    public ccBillingEmail: string;
    public ccIp : string;

    constructor(options: any = {}) {
        this.ccNumber = options.ccNumber;
        this.sequencePk = options.sequencePk;
        this.ccType = options.ccType;
        this.ccExpireMonth = options.ccExpireMonth;
        this.ccExpireYear = options.ccExpireYear;
        this.ccCvv = options.ccCvv;
        this.useOrder = options.useOrder;
        this.oldUseOrder = options.oldUseOrder;
        this.ccName = options.ccName;
        this.ccBillingAddr1 = options.ccBillingAddr1;
        this.ccBillingAddr2 = options.ccBillingAddr2;
        this.ccBillingAddr3 = options.ccBillingAddr3;
        this.ccBillingCity = options.ccBillingCity;
        this.ccBillingState = options.ccBillingState;
        this.ccBillingCountry = options.ccBillingCountry;
        this.ccBillingZip = options.ccBillingZip;
        this.ccBillingPhone = options.ccBillingPhone;
        this.ccBillingEmail = options.ccBillingEmail;
        this.ccIp = options.ccIp;
    }
}