import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment = require('moment');

import { AuthHttpService, HttpOptions } from '@jeunesse/angular/authentication';
import { UrlHelperService } from '@jeunesse/angular/common';

import {
    KeyValueString,
    KeyValueLanguage,
    KeyValueState,
    MemberViewModel,
    MemberFormModel,
    AddressModel,
    AddressFormModel,
    PaymentViewModel,
    PaymentFormModel,
    PasswordFormModel,
    AddressAutoCompleteModel,
    MarketingModel,
    CustomerAndLevel,
    NLevelsFormModel,
    GetFileQueryModel,
    CreatePaymentModel,
    ItalyCardModel,
    PaymentOrderModel
} from '../+account/models';
import { UnifiedInfo } from './models/unified-info.interface';
import { UnifiedDonationOptions } from './models/unified-donation-options.interface';
import { HttpParams } from '@angular/common/http';

/**
 * This service handles all the calls for information related to the My Accounts page
 */
@Injectable()
export class AccountService {
    constructor(private readonly authHttp: AuthHttpService, private readonly urlHelper: UrlHelperService) { }

    public phoneValidation: string = '';

    /**
     *
     *
     * @param {number} mainPk
     * @returns {Observable<MemberViewModel>}
     * @memberof AccountService
     */
    public getUserProfile(mainPk: number): Observable<MemberViewModel> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v1/users/${mainPk}`)).pipe(
            map((data: any) => {
                return new MemberViewModel(data);
            })
        );
    }

    /**
     *
     *
     * @param {number} mainPk
     * @returns {Observable<MarketingModel>}
     * @memberof AccountService
     */
    public getUserMarketing(mainPk: number): Observable<MarketingModel> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v1/users/marketing/${mainPk}`)).pipe(
            map((data: any) => {
                return new MarketingModel(data);
            })
        );
    }

    /**
     * @description GET DOCUMENT DIRECT LINK
     * @author Alex Togo
     * @date 2020-07-01
     * @param {GetFileQueryModel} [docsQuery]
     * @returns {Observable<string>}
     * @memberof AccountService
     */
    public getFile(docsQuery?: GetFileQueryModel): Observable<string> {
        docsQuery = docsQuery || {};
        return this.authHttp.get<any>(this.urlHelper.toStorageApi('/v1/user-documents/get-file'), { params: docsQuery }).pipe(
            map((data: string) => {
                return data;
            })
        );
    }

    /**
     *
     *
     * @param {MemberFormModel} profile
     * @returns {Observable<MemberViewModel>}
     * @memberof AccountService
     */
    public updateUserProfile(profile: MemberFormModel): Observable<MemberViewModel> {
        if (profile.dateOfBirth && profile.dateOfBirth !== '')
            profile.dateOfBirth = moment(profile.dateOfBirth).format('YYYY-MM-DD');
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/users'), profile).pipe(
            map((data: any) => {
                return new MemberViewModel(data);
            })
        );
    }

    /**
     *
     *
     * @param {MarketingModel} marketingInfo
     * @returns {Observable<MarketingModel>}
     * @memberof AccountService
     */
    public updateUserMarketing(marketingInfo: MarketingModel): Observable<MarketingModel> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/users/marketing'), marketingInfo).pipe(
            map((data: any) => {
                return new MarketingModel(data);
            })
        );
    }

    /**
     *
     *
     *
     * @returns {Observable<AddressModel>}
     * @memberof AccountService
     */
    public getShippingAddress(isOTG: boolean): Observable<AddressModel> {
        let params = new HttpParams();
        params = params.append('isOTG', isOTG.toString());
        return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v1/member/address/shipping'), { params: params }).pipe(
            map((data: any) => {
                return new AddressModel(data);
            })
        );
    }

    /**
     *
     *
     *
     * @returns {Observable<AddressModel>}
     * @memberof AccountService
     */
    public getMailingAddress(): Observable<AddressModel> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v1/member/address/billing')).pipe(
            map((data: any) => {
                return new AddressModel(data);
            })
        );
    }


    /**
     *
     *
     * @param {string} placeId
     * @returns {Observable<AddressAutoCompleteModel>}
     * @memberof AccountService
     */
    public addressGetDetails(placeId: string): Observable<AddressAutoCompleteModel> {
        return this.authHttp.post<any>(this.urlHelper.toProfileApi(`/v1/address/validate?placeid=${placeId}`)).pipe(
            map((data: any) => {
                return new AddressAutoCompleteModel(data);
            })
        );
    }

    /**
     *
     *
     * @param {AddressFormModel} address
     * @returns {Observable<string>}
     * @memberof AccountService
     */
    public updateShippingAddress(address: AddressFormModel): Observable<boolean> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/member/address/shipping'), address);
    }

    /**
     *
     *
     * @param {AddressFormModel} address
     * @returns {Observable<string>}
     * @memberof AccountService
     */
    public updateMailingAddress(address: AddressFormModel): Observable<boolean> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('v1/member/address/billing'), address);
    }

    /**
     *
     *
     *
     * @returns {Observable<PaymentViewModel[]>}
     * @memberof AccountService
     */
    public getPaymentDetails(): Observable<PaymentViewModel[]> {
        return this.authHttp.get<PaymentViewModel[]>(this.urlHelper.toProfileApi('/v1/payment')).pipe(
            map((data: any) => {
                return data.map((singlePayment: any) => {
                    return new PaymentViewModel(singlePayment);
                });
            })
        );
    }

    /**
     *
     *
     * @param {PaymentFormModel} paymentMethod
     * @returns {Observable<PaymentViewModel[]>}
     * @memberof AccountService
     */
    public createPaymentMethod(paymentMethod: PaymentFormModel): Observable<CreatePaymentModel> {
        return this.authHttp.post<any>(this.urlHelper.toProfileApi('/v1/payment'), paymentMethod).pipe(
            map((data: any) => {
                return new CreatePaymentModel(data);
            })
        );
    }

   /**
     *
     *
     * @returns {Observable<boolean>}
     * @memberof AccountService
     */
    public requiresCvv(): Observable<boolean> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v1/payment/requires-cvv/`));
    }

    /**
     *
     *
     * @returns {Observable<boolean>}
     * @memberof AccountService
     */
    public payPalAgreementAvailable(): Observable<boolean> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v1/payment/paypal-agreement/`));
    }

    /**
     *
     *
     * @param {PaymentFormModel} paymentMethod
     * @returns {Observable<PaymentViewModel>}
     * @memberof AccountService
     */
    public updatePaymentMethod(paymentMethod: PaymentFormModel): Observable<CreatePaymentModel> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/payment'), paymentMethod).pipe(
            map((data: any) => {
                return new CreatePaymentModel(data);
            })
        );
    }

    /**
     *
     *
     * @param {PaymentOrderModel} paymentMethodOrder
     * @returns {Observable<boolean>}
     * @memberof AccountService
     */
    public updatePaymentMethodOrder(paymentMethodOrder: PaymentOrderModel): Observable<boolean> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/payment/order/'), paymentMethodOrder);
    }

    /**
     *
     * @param {number} sequencePk
     * @param {number} useOrder
     * @returns {Observable<PaymentViewModel[]>}
     * @memberof AccountService
     */
    public deletePaymentMethod(sequencePk: number, useOrder: number): Observable<PaymentViewModel[]> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi(`/v1/payment/${sequencePk}/${useOrder}`), null).pipe(
            map((data: any) => {
                return data.map((singlePayment: any) => {
                    return new PaymentViewModel(singlePayment);
                });
            })
        );
    }

    /**
     *
     *
     *
     * @returns {Observable<KeyValueString[]>}
     * @memberof AccountService
     */
    public getCountries(): Observable<KeyValueString[]> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v1/localization/countries')).pipe(
            map((data: any) => {
                return data.map((singleKv: any) => {
                    return new KeyValueString(singleKv);
                });
            })
        );
    }

    /**
     *
     *
     * @param {string} country
     * @returns {Observable<KeyValueState[]>}
     * @memberof AccountService
     */
    public getStates(country: string): Observable<KeyValueState[]> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi(`v1/localization/countries/${country}`)).pipe(
            map((data: any) => {
                return data.map((singleKv: any) => {
                    return new KeyValueState(singleKv);
                });
            })
        );
    }

    /**
     *
     *
     *
     * @returns {Observable<KeyValueLanguage[]>}
     * @memberof AccountService
     */
    public getLanguages(): Observable<KeyValueLanguage[]> {
        return this.authHttp.get<any>(this.urlHelper.toCmsApi('/v1/languages?active=active')).pipe(
            map((data: any) => {
                return data.map((singleKv: any) => {
                    return new KeyValueLanguage(singleKv);
                });
            })
        );
    }

    /**
     *
     *
     * @param {PasswordFormModel} passwordVariables
     * @returns {Observable<boolean>}
     * @memberof AccountService
     */
    public updatePassword(passwordVariables: PasswordFormModel): Observable<boolean> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/password'), passwordVariables);
    }

    /**
     *
     * @param {number} mainPk
     * @param {boolean} status
     * @returns {Observable<boolean>}
     * @memberof AccountService
     */
    public updateSoD(mainPk: number, status: boolean): Observable<boolean> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi(`/v1/users/update-success-on-demand/${mainPk}/${status}`), null);
    }

    /**
     *
     *
     * @param {number} mainPk
     * @returns {Observable<boolean>}
     * @memberof AccountService
     */
    public resetSecurityQuestions(mainPk: number): Observable<boolean> {
        return this.authHttp.put<any>(this.urlHelper.toSecurityApi(`/v1/security/reset-questions/${mainPk}`), null);
    }

    public getInsidePlacementLevels(): Observable<number> {
        return this.authHttp.get<any>(this.urlHelper.toReportsApi('/v1/genealogy/GetInsidePlacementLevels')).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public getNLevelsCustomers(levelInfo: NLevelsFormModel): Observable<CustomerAndLevel[]> {
        return this.authHttp.put<any>(this.urlHelper.toReportsApi(`/v1/genealogy/GetNLevelsCustomers`), levelInfo).pipe(
            map((data: any) => {
                return data.map((singleCustAndLevel: any) => {
                    return new CustomerAndLevel(singleCustAndLevel);
                });
            })
        );
    }

    public canChangeSponsor(): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toProfileApi(`/v1/users/can-change-sponsor`));
    }

    public isSponsorValid(sponsorSiteUrl: string): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toProfileApi(`/v1/users/is-sponsor-valid/${sponsorSiteUrl}`));
    }

    public getItalyUserCardData(): Observable<ItalyCardModel> {
        return this.authHttp.get<ItalyCardModel>(this.urlHelper.toProfileApi('/v1/main-extension/it-card'));
    }

    //#43623
    public getMinEnrollmentAge(country: string): Observable<number> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v1/localization/min-age?country=${country}`)).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    // TW Unified Info | Task: 43759
    /**
     * Returns the unified info options including the set values for the main user.
     * @param mainFk Main account to look up
     * @returns UnifiedInfo[]
     */
    public getUnifiedInfo(mainFk: number): Observable<UnifiedInfo[]> {
        return this.authHttp.get<UnifiedInfo[]>(this.urlHelper.toProfileApi(`/v1/member/unified-profile?mainFK=${mainFk}`)).pipe();
    }

    /**
     * Returns a list of donation type options for Taiwan Unified settings
     * @returns UnifiedDonationOptions[]
     */
    public getUnifiedDonationOptions(): Observable<UnifiedDonationOptions[]> {
        return this.authHttp.get<UnifiedDonationOptions[]>(this.urlHelper.toProfileApi(`/v1/member/unified-donation-options`)).pipe();
    }

    /**
     * Inserts or updates the Unified Invoice profile settings for a TW distributor
     * @param model UnifiedInfo
     * @returns UnifiedInfo
     */
    public setUnifiedInfo(model: UnifiedInfo): Observable<UnifiedInfo> {
        return this.authHttp.post<UnifiedInfo>(this.urlHelper.toProfileApi(`/v1/member/unified-profile`), model).pipe();
    }
}
