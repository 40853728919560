/**
 * Add Payment model
 */
export class PaymentOrderModel{
    public sequencePk: number;
    public useOrder: number;
    public oldUseOrder: number;

    constructor(options: any = {}) {
        this.sequencePk = options.sequencePk;
        this.useOrder = options.useOrder;
        this.oldUseOrder = options.oldUseOrder;
    }
}