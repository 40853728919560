import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MerchantRedirectModel, MerchantRedirectModelStep2 } from '../models/merchant-redirect.interface';
import { PaymentApiService } from './payment-api.service';
import { AuthHttpService } from '@jeunesse/angular';
import { UrlHelperService } from '@jeunesse/angular';
import { MerchantRedirectFormModel, MerchantRedirectFormModelStep2 } from '../models/merchant-redirect-form-model';
import { IAppSettings } from '@jeunesse/angular/lib/common/src/models/app-settings.interface';

declare const AppSettings: IAppSettings;

@Injectable({
    providedIn: 'root'
})
export class MerchantRedirectPaymentService {

    constructor(private paymentApiService: PaymentApiService,
        private readonly authHttp: AuthHttpService,
        private readonly urlHelper: UrlHelperService) {

    }

    public getMerchantModel(model: MerchantRedirectFormModel): Observable<MerchantRedirectModel> {
        model.source = 'shop';
        model.sourceDomain = AppSettings.cookieDomain;
        return this.authHttp.post<MerchantRedirectModel>(this.urlHelper.toCartApi(`/v1/order/merchant/getRedirectModel`), model);
    }

    public getMerchantModelStep2(model: MerchantRedirectFormModelStep2): Observable<MerchantRedirectModelStep2> {
        model.source = 'shop';
        model.sourceDomain = AppSettings.cookieDomain;
        return this.authHttp.post<MerchantRedirectModelStep2>(this.urlHelper.toCartApi(`/v1/order/merchant/getRedirectModelStep2`), model);
    }
}
