/**
 * Model to keep cc card details
 */
export class CardInfo {
    public type: string;
    public number: string;
    public date: string;
    public holderName: string;
    public faIconId: string;
    public useOrder: number;
    public recurringToken: string;
    public ccType: string;

    constructor(options: any = {}) {
        this.number = `****${options.ccNumberLastFour}`;
        this.date = `${options.ccExpireMonth}/20${options.ccExpireYear}`;
        this.holderName = options.ccHolderName;
        this.useOrder = options.useOrder;
        this.recurringToken = options.recurringToken;
        this.ccType = options.ccType;
        switch (options.ccType) {
            case 'A':
                this.faIconId = 'fa-cc-amex';
                this.type = 'American Express';
                break;
            case 'M':
                this.faIconId = 'fa-cc-mastercard';
                this.type = 'MasterCard';
                break;
            case 'V':
                this.faIconId = 'fa-cc-visa';
                this.type = 'Visa';
                break;
            case 'J':
                this.faIconId = 'fa-cc-jcb';
                this.type = 'JCB';
                break;
            case 'D':
                this.faIconId  = 'fa-cc-discover';
                this.type = 'Discover';
                break;
            default:
                this.faIconId = 'fa-credit-card';
                this.type = 'Card Number';
                break;
        }
    }
}