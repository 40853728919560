import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MatSnackBarConfig } from '@angular/material/snack-bar';

import { LoggerService, LoggerInterface, LoggerTypeEnum } from '@jeunesse/angular/common';
import { SnackBarWarnComponent } from './snack-bar-warn.component';
import { SnackBarInfoComponent } from './snack-bar-info.component';

/**
 * Display info using a snack bar to the user.
 */
@Injectable()
export class SnackBarService {
    constructor(private readonly snackBar: MatSnackBar, private readonly loggerService: LoggerService) {
        this.loggerService.loggerObservable$.subscribe((log: LoggerInterface) => {

            if (log && log.message) {
                if (log.logType === LoggerTypeEnum.ERROR || log.logType === LoggerTypeEnum.WARN) {
                    this.warn(log.message);
                } else if (log.logType === LoggerTypeEnum.INFO) {
                    this.info(log.message);
                }
            }
        });
    }

    /**
     * Display a message with a snack bar.
     * Comes with a dismiss button and timeout of 5 secs.
     * @param text The text you would like the snack bar to have.
     */
    public info(text: string): MatSnackBarRef<SnackBarInfoComponent> {
        if (!text) {
            return;
        }
        const config: MatSnackBarConfig = {
            announcementMessage: text,
            data: text,
            duration: 15000
        };

        return this.snackBar.openFromComponent(SnackBarInfoComponent, config);
    }

    /**
     * Display a warning icon with message with a snack bar.
     * Comes with a dismiss button and timeout of 5 secs.
     * @param text The text you would like the snack bar to have.
     */
    public warn(text: string): MatSnackBarRef<SnackBarWarnComponent> {
        if (!text) {
            return;
        }
        const config: MatSnackBarConfig = {
            announcementMessage: text,
            data: text,
            duration: 15000
        };

        return this.snackBar.openFromComponent(SnackBarWarnComponent, config);
    }
}
