import { UrlHelperService, WebStorageService } from '@jeunesse/angular';
import { AuthService } from '@jeunesse/angular';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthHttpService } from '@jeunesse/angular';
import { RepSiteInfo, RepSiteAdWords } from '../models/public-api';
import { map } from 'rxjs/operators';
import { SeoService } from '@jeunesse/angular';

/**
 * Service to make Rep specific api calls.
 */
@Injectable({
    providedIn: 'root'
})
export class RepSiteService {
    protected repInfo: RepSiteInfo = new RepSiteInfo({});
    protected repInfoSubject$: BehaviorSubject<RepSiteInfo> = new BehaviorSubject(this.repInfo);
    public repInfoObservable$: Observable<RepSiteInfo> = this.repInfoSubject$.asObservable();
    private repSiteAdwords: RepSiteAdWords;

    constructor(
        private readonly authHttp: AuthHttpService,
        private readonly authService: AuthService,
        private readonly urlHelper: UrlHelperService,
        private readonly webStorageService: WebStorageService,
        private readonly seoService: SeoService
    ) { }

    /**
     * Used to call the api to return Rep information based on their siteurl.
     * @param siteUrl The site URL for the Rep.
     */
    public loadRepInfo(siteUrl?: string): Observable<RepSiteInfo> {
        if (siteUrl === 'main') {
            siteUrl = null;
        }
        if (this.authService.isLoggedIn) {
            // If we have a distributor or affiliate then set themselves as the rep
            // If we have a csUser set the rep to jhqsocial (corporate)
            // Otherwise we use the logged in users rep
            if ([1, 22].includes(this.authService.getMainTypeId())) {
                return this.getRepInfo(this.authService.getSiteUrl());
            } else if ([2, 21].includes(this.authService.getMainTypeId())) {
                return this.getCustomersRepInfo();
            } else if ([3, 6].includes(this.authService.getMainTypeId())) {
                siteUrl = 'jhqsocial';
            }
        }

        return this.getRepInfo(siteUrl).pipe();
    }

    /**
     * Used to call the api to return Rep information based on their siteurl.
     * @param siteUrl The site URL for the Rep.
     */
    public getRepInfoForCheckout(siteUrl?: string): Observable<RepSiteInfo> {
        return this.getRepInfo(siteUrl).pipe();
    }

    /**
     * Gets the ad words info for a specific rep.
     * @param alias RepInfo SiteUrl
     */
    public GetRepSiteAdWords(alias: string): Observable<RepSiteAdWords> {
        if (this.repSiteAdwords && !alias) {
            return of(this.repSiteAdwords);
        } else {
            // Check cookie
            const siteUrlCookie: string = this.webStorageService.getCookie('repInfo');
            if (alias && siteUrlCookie) {
                // Clear cookie
                this.webStorageService.removeCookie('repInfo');
            } else if (!alias) {
                alias = siteUrlCookie;
            }
            if (alias) {
                return this.authHttp
                    .get<RepSiteAdWords>(
                        this.urlHelper.toProfileApi(`/v1/repsite/gawords/${alias}`)
                    )
                    .pipe(
                        map((res: any) => {
                            this.repSiteAdwords = new RepSiteAdWords(res);
                            return this.repSiteAdwords;
                        })
                    );
            } else {
                return of(this.repSiteAdwords);
            }
        }
    }

    /**
     * Clears the local storage object can cookie for the rep info.
     */
    public clearRepStorage(): void {
        this.repInfo = new RepSiteInfo({});
        this.repInfoSubject$.next(this.repInfo);
        this.webStorageService.removeCookie('repInfo');
    }

    private getCustomersRepInfo(repSiteUrl?: string): Observable<RepSiteInfo> {
        // KLK - Commented out for Task 33506
        // if (this.repInfo && (this.repInfo.alias && (this.repInfo.alias.toLowerCase() !== 'jhqsocial' || this.repInfo.alias.toLowerCase() !== 'jhqbr'))) {
        //     return of(this.repInfo);
        // }
        return this.authHttp.get<RepSiteInfo>(this.urlHelper.toProfileApi(`/v1/repsite`))
            .pipe(map((res: RepSiteInfo) => {
                if (res.alias && res.mainPk > 0) {
                    this.repInfo = new RepSiteInfo(res);
                    if (res.canAllowRetailCustomer || res.corporate) {
                        this.repInfo.isValid = true;
                        // Set GA tracking
                        this.seoService.setTrackingType('Replicated Site', res.alias);

                    }
                } else {
                    this.repInfo = new RepSiteInfo({});
                }
                this.repInfoSubject$.next(this.repInfo);
                return this.repInfo;
            }));
    }

    private getRepInfo(siteUrl: string | undefined): Observable<RepSiteInfo> {
        // Don't call api everytime if siteurl is the same
        if (this.repInfo && this.repInfo.alias === siteUrl) {
            return of(this.repInfo);
        }

        if (!siteUrl) {
            return of(this.repInfo);
        }

        return this.authHttp.get<RepSiteInfo>(this.urlHelper.toProfileApi(`/v1/repsite/${siteUrl}`))
            .pipe(map((res: RepSiteInfo) => {
                if (res.alias && res.mainPk > 0) {
                    this.repInfo = new RepSiteInfo(res);
                    if (res.canAllowRetailCustomer || res.corporate) {
                        this.repInfo.isValid = true;
                        // Set GA tracking
                        this.seoService.setTrackingType('Replicated Site', res.alias);

                    }
                } else {
                    this.repInfo = new RepSiteInfo({});
                }
                this.repInfoSubject$.next(this.repInfo);
                return this.repInfo;
            }));
    }
}
