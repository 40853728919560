import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ProductConfigModel, ProductModel } from '../../../models/public-api';
import { ClientInfo, ClientInfoService } from '@jeunesse/angular';

@Component({
    selector: 'jn-simple-product',
    templateUrl: 'simple-product.component.html',
    styleUrls: ['simple-product.component.scss']
})
export class SimpleProductComponent implements OnInit {
    public product: ProductModel;
    public clientInfo: ClientInfo;

    /**
     * PREVENT "ESC" FROM CLOSING THE DIALOG
     */
    @HostListener('window:keyup.esc') public onKeyUp(): void {
        this.closeDialog();
    }
    constructor(
        private readonly dialogRef: MatDialogRef<SimpleProductComponent>,
        private dialog: MatDialog,
        private readonly clientInfoService: ClientInfoService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        /**
         * SUBSCRIBE TO EVENTS EMITTED WHEN THE BACKDROP IS CLICKED
         */
        dialogRef.backdropClick().subscribe(() => {
            this.closeDialog();
        });
    }

    public ngOnInit(): void {
        this.clientInfo = this.clientInfoService.getClientInfo();
        this.product = this.data.product;
    }

    public closeDialog(): void {
        this.dialogRef.close(this.product);
    }

}
