import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthHttpService, UrlHelperService } from '@jeunesse/angular';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ShoppingCart, MainCart, ValidateCartQueryModel, ValidateCartViewModel, MainCartItemsValidate } from '../models/public-api';
import { SmartDeliveryItemFormModel, SmartDeliveryItemModel } from '../products/models/public-api';
import { CheckQualifiedCampaignRequest, CheckQualifiedCampaignResponse } from '../models/public-api';

/**
 * Main cart service for making api calls.
 */
@Injectable()
export class MainCartApiService {
    constructor(private readonly authHttpService: AuthHttpService, private readonly urlHelperService: UrlHelperService) {}

    /**
     * Api call to remove a main cart object.
     * @param cartKey Main cart unique key
     */
    public deleteMainCart(cartKey?: string): Observable<boolean> {
        return this.authHttpService.delete<boolean>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}`)).pipe();
    }

    /**
     * Api call to get a main cart object.
     * @param cartKey Main cart unique key
     */
    public getMainCart(cartKey: string): Observable<ShoppingCart> {
        return this.authHttpService.get<ShoppingCart>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}`)).pipe();
    }

    /**
     * Api call to patch a main cart object.
     * @param cartKey Main cart unique key
     * @param model Main cart interface object
     */
    public patchMainCart(cartKey: string, model: MainCart): Observable<ShoppingCart> {
        return this.authHttpService.patch<ShoppingCart>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}`), model).pipe();
    }

    /**
     * Api call to add a main cart object.
     * @param cartKey Main cart unique key
     * @param model Main cart interface object
     */
    public addMainCart(model: MainCart): Observable<ShoppingCart> {
        return this.authHttpService.post<ShoppingCart>(this.urlHelperService.toCartApi(`/v1/shopping`), model).pipe();
    }

    /**
     * Api call to update a main cart object.
     * @param cartKey Main cart unique key
     * @param model Main cart interface object
     */
    public updateMainCart(cartKey: string, model: MainCart): Observable<ShoppingCart> {
        return this.authHttpService.put<ShoppingCart>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}`), model).pipe();
    }

    /**
     * Adds a product to a logged in user's smart delivery.
     * @param model SmartDeliveryItemFormModel model
     */
    public addProductToSmartDelivery(model: SmartDeliveryItemFormModel): Observable<SmartDeliveryItemModel> {
        return this.authHttpService.post<SmartDeliveryItemModel>(this.urlHelperService.toCartApi('/v1/smartdelivery/items'), model).pipe();
    }

    /**
     * Used to remove the rep info from the current cart item.
     */
    public clearRepInfo(cartKey: string): Observable<any> {
        return this.authHttpService.delete<any>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/clear-referrer`)).pipe();
    }

    /**
     *
     */
    public applyDiscount(cartKey: string, cartDiscountFormModel: any): Observable<any> {
        return this.authHttpService
            .put<any>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/apply-discount`), cartDiscountFormModel)
            .pipe();
    }

    /**
     * Get formatted currency value
     */
    public formatCurrency(value: number, currencyCode: string): Observable<string> {
        if (!currencyCode) {
            return of(value.toString());
        }
        return this.authHttpService.post<any>(this.urlHelperService.toCmsApi(`/v1/format/currency`), { value, currencyCode }).pipe(
            map((resp) => {
                return resp ? resp.formattedValue : '';
            })
        );
    }

    /**
     * Used to validate the cart
     */
    public validateCartCheckout(cartKey: string, queryModel: ValidateCartQueryModel): Observable<ValidateCartViewModel> {
        let httpParams: HttpParams = new HttpParams();
        Object.keys(queryModel).forEach((key) => {
            httpParams = httpParams.append(key, queryModel[key]);
        });
        return this.authHttpService
            .get<ValidateCartViewModel>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/validate`), { params: httpParams })
            .pipe();
    }

    /**
     * Used to validate cart items
     */
    public validateCartItems(cartKey: string): Observable<MainCartItemsValidate[]> {
        return this.authHttpService
            .get<MainCartItemsValidate[]>(this.urlHelperService.toCartApi(`/v1/shopping/${cartKey}/items/validate`))
            .pipe();
    }

    /**
     * Used to check if the user qualifies for any special campaigns
     */
    public checkQualifiedCampaigns(model: CheckQualifiedCampaignRequest): Observable<CheckQualifiedCampaignResponse> {
        return this.authHttpService
            .post<CheckQualifiedCampaignResponse>(this.urlHelperService.toCartApi(`/v1/shopping/campaigns/qualified`), model)
            .pipe();
    }
}
