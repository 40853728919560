import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

import { ShoppingCart, ShoppingCartItem } from '../models/public-api';
import { LocaleService } from '@jeunesse/angular';
import { QualifiedCampaignService } from './qualified-campaign.service';
import { SubSink } from 'subsink';

@Injectable(
    {providedIn: 'root'}
)
export class MainCartStateService {
    public isProductAdded = new Subject<boolean>();

    private cartState: any = {
        shoppingCart: {
            cartItems: [],
            subTotal: 0,
            tax: 0,
            shipping: 0,
            discount: 0,
            total: 0,
            quantity: 0,
            cartHasAutoShipItems: false,
            toggleCart: false,
            isAffiliateOnForCountry: true
        },
        repInfo: {}
    };
    private readonly mainCartState = new BehaviorSubject<ShoppingCart>(this.cartState.shoppingCart);
    private readonly mainCartItemState = new BehaviorSubject<ShoppingCartItem[]>(this.cartState.shoppingCart.cartItems);
    private readonly enableButtonState = new Subject<boolean>();

    private buttonSubs = new SubSink();

    constructor(private readonly localeService: LocaleService,
        private readonly qualifiedCampaignService: QualifiedCampaignService){}

    public getMainCart$(): Observable<ShoppingCart> {
        return this.mainCartState.asObservable();
    }

    public getMainCartItems$(): Observable<ShoppingCartItem[]> {
        return this.mainCartItemState.asObservable();
    }

    public setDisableButton(): void {
        this.enableButtonState.next(true);
    }

    public getButtonState$(): Observable<boolean> {
        return this.enableButtonState.asObservable();
    }

    public getButtonSubs(): SubSink {
        return this.buttonSubs
    }

    public setMainCart(cart: ShoppingCart, toggleCart: boolean = false): void {
        this.cartState.shoppingCart = cart;
        this.cartState.shoppingCart.toggleCart = toggleCart;
        if (this.cartState.shoppingCart.cartItems
            && this.cartState.shoppingCart.cartItems.length > 0
            && this.cartState.shoppingCart.cartItems[0] === null) {
                this.cartState.shoppingCart.cartItems = [];
            }
        this.checkSD();
        this.cartState.shoppingCart.quantity = 0;
        this.localeService.getCountryMarkets().subscribe((countries) => {
            if (countries) {
                let country = countries.filter(x => x.code2 === cart.countryCode);
                if (country.length > 0) {
                    this.cartState.shoppingCart.isAffiliateOnForCountry = country[0].isAffiliateOn;
                    this.cartState.shoppingCart.allowImportCart = country[0].hasImportCart;
                    this.cartState.shoppingCart.offerJKidsDonation = country[0].offerJKidsDonation;
                }
            }
            this.cartState.shoppingCart.cartItems.forEach(item => {
                this.cartState.shoppingCart.quantity = this.cartState.shoppingCart.quantity + item.quantity;
            });

            this.mainCartState.next(this.cartState.shoppingCart);
            this.mainCartItemState.next(this.cartState.shoppingCart.cartItems);

            this.setEnableButton();
            this.getButtonSubs().unsubscribe();
        });
    }

    public addCartItems(cartItems: ShoppingCartItem[], toggleCart: boolean): void {
        this.cartState.shoppingCart.cartItems = cartItems;
        this.cartState.shoppingCart.toggleCart = toggleCart;
        this.cartState = {
            ...this.cartState
        };
        this.checkSD();
        this.mainCartState.next(this.cartState.shoppingCart);
        this.mainCartItemState.next(this.cartState.shoppingCart.cartItems);
        this.isProductAdded.next(false);
    }

    public undoAddToCartButton(): void {
        this.mainCartState.next(this.cartState.shoppingCart);
        this.mainCartItemState.next(this.cartState.shoppingCart.cartItems);
        this.isProductAdded.next(false);
    }

    private setEnableButton(): void {
        this.enableButtonState.next(false);
    }

    private updateSubtotal(): void {
        let quantity: number = 0;
        let subTotal: number = 0;
        let discount: number = 0;
        let cvTotal: number = 0;
        let total: number = 0;

        if (this.cartState.shoppingCart.cartItems
            && this.cartState.shoppingCart.cartItems.length > 0
            && this.cartState.shoppingCart.cartItems[0] !== null) {

            // set the currency symbol for the cart to display
            let itemHasCurrencySymbol = this.cartState.shoppingCart.cartItems.filter(x => x.currencySymbol != null);
            if (itemHasCurrencySymbol.length > 0) {
                this.cartState.shoppingCart.currencySymbol = itemHasCurrencySymbol[0].currencySymbol;
            } else {
                let hasChildItems = this.cartState.shoppingCart.cartItems.filter(x => x.childItems.length > 0);
                if (hasChildItems.length > 0) {
                    this.cartState.shoppingCart.currencySymbol = hasChildItems[0].childItems[0].currencySymbol;
                }
            }

            this.cartState.shoppingCart.cartItems.forEach(element => {
                quantity = quantity + element.quantity;
                subTotal = subTotal + element.subTotal;
                discount = discount + element.discount;
                cvTotal = cvTotal + (element.quantity * element.cv);
                total = total + element.total;
                if (!element.currencySymbol) {
                    element.currencySymbol = this.cartState.shoppingCart.currencySymbol;
                }
            });

            // Check for updated daily deal products and info.
            if (subTotal > 0 && cvTotal > 0) {
                this.qualifiedCampaignService.checkForCampaignInfo({
                    cartType: 'DOD',
                    cartKey: this.cartState.shoppingCart.cartKey,
                    culture: this.cartState.shoppingCart.culture
                });
            }
        }

        this.cartState.shoppingCart.quantity = quantity;
        this.cartState = {
            ...this.cartState
        };
    }

    private checkSD(): void {
        this.cartState.shoppingCart.cartHasAutoShipItems = _.some(this.cartState.shoppingCart.cartItems, ['setupForAs', true]);
    }
}
