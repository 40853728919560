import { ProductModel } from '../product/product.model';

/**
 * Brand model
 */
export class BrandModel {
    public id: number;
    public name: string;
    public categoryId: number;
    public products: ProductModel[];
    public description: string;
    public subtitle: string;
    public imageUrl: string;
    public seqNo: number;
    public bannerImageUrl: string;
    public bannerImageKey: string;

    constructor(options: any = {}) {
        this.id = options.id;
        this.name = options.name;
        this.categoryId = options.categoryId;
        // this.products = (options.products && options.products.map(value => new ProductModel(value))) || [];
        this.description = options.description;
        this.subtitle = options.subtitle;
        this.imageUrl = options.imageUrl;
        this.seqNo = options.seqNo;
        this.bannerImageUrl = options.bannerImageUrl;
        this.bannerImageKey = options.bannerImageKey;
    }
}