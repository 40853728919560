export class AgendaTicketSelectedModel {
    public id: number;
    public agendaId: number;
    public isSelected: boolean;

    constructor (options: any) {
        this.id = options.id;
        this.agendaId = options.eventAgendaID;
        this.isSelected = false;
    }
}
