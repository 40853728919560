import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'jn-disclosure',
    templateUrl: './disclosure.component.html',
    styleUrls: ['./disclosure.component.scss']
})
export class DisclosureComponent implements OnInit {
    @Input() public disclosures: any[];
    constructor() { }

    public ngOnInit(): void {
    }

}
