import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { PersonalUse } from '../../../models/public-api';

@Component({
    selector: 'jn-cart-info',
    templateUrl: './cart-info.component.html',
    styleUrls: ['./cart-info.component.scss']
})
export class CartInfoComponent implements OnInit {
    @Input() public showPersonalUse$: Observable<PersonalUse>;
    @Output() public setPersonalUseOnly$: EventEmitter<boolean> = new EventEmitter<boolean>();
    public showPersonalUse: boolean = false;
    public showPersonalUseError: boolean = false;
    public showPersonalUseWarning: boolean = false;
    constructor() {}

    public ngOnInit(): void {
        this.showPersonalUse$.subscribe(model => {
            this.showPersonalUse = model.showPersonalUse;
            this.showPersonalUseError = (model.isValid !== undefined && !model.isValid);
            this.showPersonalUseWarning = model.showPersonalUseWarning;
        })
    }

    public setPersonalUseOnly(value: boolean): void {
        this.setPersonalUseOnly$.emit(value);
    }
}
