import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, timer } from 'rxjs';
import { mergeMap, catchError, retryWhen } from 'rxjs/operators';

import { WebStorageService } from '../../common/src/web-storage.service';

import { AuthService } from './auth.service';
import {
    HttpOptionsBodyArrayBuffer,
    HttpOptionsBodyBlob,
    HttpOptionsBodyText,
    HttpOptionsBodyJson,
    HttpOptionsEventsArrayBuffer,
    HttpOptionsEventsBlob,
    HttpOptionsEventsText,
    HttpOptionsEventsJson,
    HttpOptionsResponseArrayBuffer,
    HttpOptionsResponseBlob,
    HttpOptionsResponseText,
    HttpOptionsResponseJson
} from './models/public-api';
import { LoggerService } from '../../common/src/logger.service';
import { LoggerTypeEnum } from '../../common/src/models/logger-type.enum';
import { LoggerInterface } from '../../common/src/models/logger.interface';

/**
 * A wrapper class around the HttpClient class for performing Authorization http requests.
 */
@Injectable()
export class AuthHttpService {

    constructor(
        private readonly authService: AuthService,
        private readonly http: HttpClient,
        private readonly webStorage: WebStorageService,
        private readonly loggerService: LoggerService) { }

    /**
     * Construct a DELETE request which interprets the body as an `ArrayBuffer` and returns it.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the body as an `ArrayBuffer`.
     */
    public delete(url: string, options: HttpOptionsBodyArrayBuffer): Observable<ArrayBuffer>;

    /**
     * Construct a DELETE request which interprets the body as a `Blob` and returns it.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `Blob`.
     */
    public delete(url: string, options: HttpOptionsBodyBlob): Observable<Blob>;

    /**
     * Construct a DELETE request which interprets the body as text and returns it.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `string`.
     */
    public delete(url: string, options: HttpOptionsBodyText): Observable<string>;

    /**
     * Construct a DELETE request which interprets the body as an `ArrayBuffer` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
     */
    public delete(url: string, options: HttpOptionsEventsArrayBuffer): Observable<HttpEvent<ArrayBuffer>>;

    /**
     * Construct a DELETE request which interprets the body as a `Blob` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
     */
    public delete(url: string, options: HttpOptionsEventsBlob): Observable<HttpEvent<Blob>>;

    /**
     * Construct a DELETE request which interprets the body as text and returns the full event stream.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
     */
    public delete(url: string, options: HttpOptionsEventsText): Observable<HttpEvent<string>>;

    /**
     * Construct a DELETE request which interprets the body as JSON and returns the full event stream.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
     */
    public delete<T>(url: string, options: HttpOptionsEventsJson): Observable<HttpEvent<T>>;

    /**
     * Construct a DELETE request which interprets the body as an `ArrayBuffer` and returns the full response.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
     */
    public delete(url: string, options: HttpOptionsResponseArrayBuffer): Observable<HttpResponse<ArrayBuffer>>;

    /**
     * Construct a DELETE request which interprets the body as a `Blob` and returns the full response.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
     */
    public delete(url: string, options: HttpOptionsResponseBlob): Observable<HttpResponse<Blob>>;

    /**
     * Construct a DELETE request which interprets the body as text and returns the full response.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `string`.
     */
    public delete(url: string, options: HttpOptionsResponseText): Observable<HttpResponse<string>>;

    /**
     * Construct a DELETE request which interprets the body as JSON and returns the full response.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `T`.
     */
    public delete<T>(url: string, options: HttpOptionsResponseJson): Observable<HttpResponse<T>>;

    /**
     * Construct a DELETE request which interprets the body as JSON and returns it.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the body as type `T`.
     */
    public delete<T>(url: string, options?: HttpOptionsBodyJson): Observable<T>;

    /**
     * @ignore
     */
    public delete(url: string, options: any = {}): Observable<any> {
        options.headers = this.getHeaders(options.headers);
        options.params = this.getParams(options.params);

        return this.http.delete<any>(url, options).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Construct a GET request which interprets the body as an `ArrayBuffer` and returns it.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the body as an `ArrayBuffer`.
     */
    public get(url: string, options: HttpOptionsBodyArrayBuffer): Observable<ArrayBuffer>;

    /**
     * Construct a GET request which interprets the body as a `Blob` and returns it.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `Blob`.
     */
    public get(url: string, options: HttpOptionsBodyBlob): Observable<Blob>;

    /**
     * Construct a GET request which interprets the body as text and returns it.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `string`.
     */
    public get(url: string, options: HttpOptionsBodyText): Observable<string>;

    /**
     * Construct a GET request which interprets the body as an `ArrayBuffer` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
     */
    public get(url: string, options: HttpOptionsEventsArrayBuffer): Observable<HttpEvent<ArrayBuffer>>;

    /**
     * Construct a GET request which interprets the body as a `Blob` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
     */
    public get(url: string, options: HttpOptionsEventsBlob): Observable<HttpEvent<Blob>>;

    /**
     * Construct a GET request which interprets the body as text and returns the full event stream.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
     */
    public get(url: string, options: HttpOptionsEventsText): Observable<HttpEvent<string>>;

    /**
     * Construct a GET request which interprets the body as JSON and returns the full event stream.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
     */
    public get<T>(url: string, options: HttpOptionsEventsJson): Observable<HttpEvent<T>>;

    /**
     * Construct a GET request which interprets the body as an `ArrayBuffer` and returns the full response.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
     */
    public get(url: string, options: HttpOptionsResponseArrayBuffer): Observable<HttpResponse<ArrayBuffer>>;

    /**
     * Construct a GET request which interprets the body as a `Blob` and returns the full response.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
     */
    public get(url: string, options: HttpOptionsResponseBlob): Observable<HttpResponse<Blob>>;

    /**
     * Construct a GET request which interprets the body as text and returns the full response.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `string`.
     */
    public get(url: string, options: HttpOptionsResponseText): Observable<HttpResponse<string>>;

    /**
     * Construct a GET request which interprets the body as JSON and returns the full response.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `T`.
     */
    public get<T>(url: string, options: HttpOptionsResponseJson): Observable<HttpResponse<T>>;

    /**
     * Construct a GET request which interprets the body as JSON and returns it.
     * @param url The full Url to the end point.
     * @param options Options for the request.
     * @returns An `Observable` of the body as type `T`.
     */
    public get<T>(url: string, options?: HttpOptionsBodyJson): Observable<T>;

    /**
     * @ignore
     */
    public get(url: string, options: any = {}): Observable<any> {
        options.headers = this.getHeaders(options.headers);
        options.params = this.getParams(options.params);

        return this.http.get<any>(url, options).pipe(
            // Retry on unknown errors
            retryWhen((httpError: Observable<HttpErrorResponse>) => {
                return httpError.pipe(mergeMap((error: HttpErrorResponse, i: number) => {
                    const retryAttempt: number = i + 1;
                    // if maximum number of retries have been met
                    // or response is a status code we don't wish to retry, throw error
                    if (retryAttempt > 3 || error.status === 500 || error.status === 401) {
                        return throwError(error);
                    }

                    console.log(`Retrying: ${url}\n` + `Attempt ${retryAttempt}: retrying in 500ms`);

                    // retry after .500s
                    return timer(500);
                }));
            }),
            catchError(this.handleError) // then handle the error
        );
    }


    /**
     * Construct a POST request which interprets the body as an `ArrayBuffer` and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as an `ArrayBuffer`.
     */
    public post(url: string, body: any | null, options: HttpOptionsBodyArrayBuffer): Observable<ArrayBuffer>;

    /**
     * Construct a POST request which interprets the body as a `Blob` and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `Blob`.
     */
    public post(url: string, body: any | null, options: HttpOptionsBodyBlob): Observable<Blob>;

    /**
     * Construct a POST request which interprets the body as text and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `string`.
     */
    public post(url: string, body: any | null, options: HttpOptionsBodyText): Observable<string>;

    /**
     * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
     */
    public post(url: string, body: any | null, options: HttpOptionsEventsArrayBuffer): Observable<HttpEvent<ArrayBuffer>>;

    /**
     * Construct a POST request which interprets the body as a `Blob` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
     */
    public post(url: string, body: any | null, options: HttpOptionsEventsBlob): Observable<HttpEvent<Blob>>;

    /**
     * Construct a POST request which interprets the body as text and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
     */
    public post(url: string, body: any | null, options: HttpOptionsEventsText): Observable<HttpEvent<string>>;

    /**
     * Construct a POST request which interprets the body as JSON and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
     */
    public post<T>(url: string, body: any | null, options: HttpOptionsEventsJson): Observable<HttpEvent<T>>;

    /**
     * Construct a POST request which interprets the body as an `ArrayBuffer` and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
     */
    public post(url: string, body: any | null, options: HttpOptionsResponseArrayBuffer): Observable<HttpResponse<ArrayBuffer>>;

    /**
     * Construct a POST request which interprets the body as a `Blob` and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
     */
    public post(url: string, body: any | null, options: HttpOptionsResponseBlob): Observable<HttpResponse<Blob>>;

    /**
     * Construct a POST request which interprets the body as text and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `string`.
     */
    public post(url: string, body: any | null, options: HttpOptionsResponseText): Observable<HttpResponse<string>>;

    /**
     * Construct a POST request which interprets the body as JSON and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `T`.
     */
    public post<T>(url: string, body: any | null, options: HttpOptionsResponseJson): Observable<HttpResponse<T>>;

    /**
     * Construct a POST request which interprets the body as JSON and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as type `T`.
     */
    public post<T>(url: string, body?: any | null, options?: HttpOptionsBodyJson): Observable<T>;

    /**
     * @ignore
     */
    public post(url: string, body: any | null = null, options: any = {}): Observable<any> {
        options.headers = this.getHeaders(options.headers);
        options.params = this.getParams(options.params);

        return this.http.post<any>(url, body, options).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Construct a PUT request which interprets the body as an `ArrayBuffer` and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as an `ArrayBuffer`.
     */
    public put(url: string, body: any | null, options: HttpOptionsBodyArrayBuffer): Observable<ArrayBuffer>;

    /**
     * Construct a PUT request which interprets the body as a `Blob` and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `Blob`.
     */
    public put(url: string, body: any | null, options: HttpOptionsBodyBlob): Observable<Blob>;

    /**
     * Construct a PUT request which interprets the body as text and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `string`.
     */
    public put(url: string, body: any | null, options: HttpOptionsBodyText): Observable<string>;

    /**
     * Construct a PUT request which interprets the body as an `ArrayBuffer` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
     */
    public put(url: string, body: any | null, options: HttpOptionsEventsArrayBuffer): Observable<HttpEvent<ArrayBuffer>>;

    /**
     * Construct a PUT request which interprets the body as a `Blob` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
     */
    public put(url: string, body: any | null, options: HttpOptionsEventsBlob): Observable<HttpEvent<Blob>>;

    /**
     * Construct a PUT request which interprets the body as text and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
     */
    public put(url: string, body: any | null, options: HttpOptionsEventsText): Observable<HttpEvent<string>>;

    /**
     * Construct a PUT request which interprets the body as JSON and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
     */
    public put<T>(url: string, body: any | null, options: HttpOptionsEventsJson): Observable<HttpEvent<T>>;

    /**
     * Construct a PUT request which interprets the body as an `ArrayBuffer` and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
     */
    public put(url: string, body: any | null, options: HttpOptionsResponseArrayBuffer): Observable<HttpResponse<ArrayBuffer>>;

    /**
     * Construct a PUT request which interprets the body as a `Blob` and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
     */
    public put(url: string, body: any | null, options: HttpOptionsResponseBlob): Observable<HttpResponse<Blob>>;

    /**
     * Construct a PUT request which interprets the body as text and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `string`.
     */
    public put(url: string, body: any | null, options: HttpOptionsResponseText): Observable<HttpResponse<string>>;

    /**
     * Construct a PUT request which interprets the body as JSON and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `T`.
     */
    public put<T>(url: string, body: any | null, options: HttpOptionsResponseJson): Observable<HttpResponse<T>>;

    /**
     * Construct a PUT request which interprets the body as JSON and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as type `T`.
     */
    public put<T>(url: string, body: any | null, options?: HttpOptionsBodyJson): Observable<T>;

    /**
     * @ignore
     */
    public put(url: string, body: any | null, options: any = {}): Observable<any> {
        options.headers = this.getHeaders(options.headers);
        options.params = this.getParams(options.params);

        return this.http.put<any>(url, body, options).pipe(
            catchError(this.handleError)
        );
    }

    /**
     * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as an `ArrayBuffer`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsBodyArrayBuffer): Observable<ArrayBuffer>;

    /**
     * Construct a PATCH request which interprets the body as a `Blob` and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `Blob`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsBodyBlob): Observable<Blob>;

    /**
     * Construct a PATCH request which interprets the body as text and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as a `string`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsBodyText): Observable<string>;

    /**
     * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `ArrayBuffer`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsEventsArrayBuffer): Observable<HttpEvent<ArrayBuffer>>;

    /**
     * Construct a PATCH request which interprets the body as a `Blob` and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `Blob`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsEventsBlob): Observable<HttpEvent<Blob>>;

    /**
     * Construct a PATCH request which interprets the body as text and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `string`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsEventsText): Observable<HttpEvent<string>>;

    /**
     * Construct a PATCH request which interprets the body as JSON and returns the full event stream.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
     */
    public patch<T>(url: string, body: any | null, options: HttpOptionsEventsJson): Observable<HttpEvent<T>>;

    /**
     * Construct a PATCH request which interprets the body as an `ArrayBuffer` and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `ArrayBuffer`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsResponseArrayBuffer): Observable<HttpResponse<ArrayBuffer>>;

    /**
     * Construct a PATCH request which interprets the body as a `Blob` and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `Blob`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsResponseBlob): Observable<HttpResponse<Blob>>;

    /**
     * Construct a PATCH request which interprets the body as text and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `string`.
     */
    public patch(url: string, body: any | null, options: HttpOptionsResponseText): Observable<HttpResponse<string>>;

    /**
     * Construct a PATCH request which interprets the body as JSON and returns the full response.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the `HttpResponse` for the request, with a body type of `T`.
     */
    public patch<T>(url: string, body: any | null, options: HttpOptionsResponseJson): Observable<HttpResponse<T>>;

    /**
     * Construct a PATCH request which interprets the body as JSON and returns it.
     * @param url The full Url to the end point.
     * @param body The data to send in the body of the request.
     * @param options Options for the request.
     * @returns An `Observable` of the body as type `T`.
     */
    public patch<T>(url: string, body: any | null, options?: HttpOptionsBodyJson): Observable<T>;

    /**
     * @ignore
     */
    public patch(url: string, body: any | null, options: any = {}): Observable<any> {
        options.headers = this.getHeaders(options.headers);
        options.params = this.getParams(options.params);

        return this.http.patch<any>(url, body, options).pipe(
            catchError(this.handleError)
        );
    }

    private getHeaders(headers?: HttpHeaders | { [header: string]: string | string[]; }): HttpHeaders {
        let additionalHeaders: HttpHeaders;

        if (headers instanceof HttpHeaders) {
            additionalHeaders = headers;
        }
        else {
            additionalHeaders = new HttpHeaders(headers);
        }

        // Add AppKey and JWT to the call.
        if (this.authService.isLoggedIn) {
            additionalHeaders = additionalHeaders.set('Authorization', `Bearer ${this.webStorage.getCookie('token')}`);

            this.authService.refreshTokenIfNeeded();
        }
        return additionalHeaders;
    }

    private getParams(params?: HttpParams | { [param: string]: string | string[]; }): HttpParams {
        let additionalParams: HttpParams;

        if (params instanceof HttpParams) {
            additionalParams = params;
        }
        else {
            additionalParams = new HttpParams({ fromObject: params });
        }

        return additionalParams;
    }

    private handleError: (error: HttpErrorResponse) => Observable<Error> = (error: HttpErrorResponse): Observable<Error> => {
        if (error.status === 401) {
            //this.authService.requiredLogin(window.location.href);
            console.log('401 error', error)
        }

        let log: LoggerInterface = { logType: LoggerTypeEnum.ERROR, message: '' };
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            log.message = error.error.message;
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            const errorMessage: any = error.error && (error.error instanceof Array || error.error instanceof Object ? JSON.stringify(error.error) : error.error);
            log.message = errorMessage;
            console.error(`Backend returned code ${error.status}\n` + `Body was: ${errorMessage}`);
            return throwError(new Error(errorMessage));
        }
        this.loggerService.log(log);

        // return an ErrorObservable with a user-facing error message
        // return throwError(new Error('Error'));
    }
}
