/**
 * Smart delivery item return model.
 */
export class SmartDeliveryItemModel {
    public autoshipOrderItemsPK: number;
    public productPK: number;
    public productName: string;
    public unitPrice: number;
    public currencyCode: string;
    public currencySymbol: string;
    public currencyRatesFK: number;
    public formattedUnitPrice: string;
    public quantity: number;
    public maxLimit: number;
    public imageUrl: string;
    public validationMessages = [];
    public cv: number = 0;
    public price: number;
}
