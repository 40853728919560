import { JetMenuItem } from "./jet-menu-item.model";

export class MenuItem {
    id?: number;
    title?: string;
    navigationLink?: string;
    isExternalLink?: boolean;
    imageUrl?: string;
    subItemLayout?: MenuLayoutType;
    subItems?: MenuItem[];
    translationKey?: string;
    inNewTab?: boolean;
    name?: string;
    content?: string;
    parentId? : number;
    sortId?: number;
    action?: string;
    submenu?: JetMenuItem[];
    icon?: string;
    isOpened?: boolean;
    isWebsiteLink?: boolean;
    menuItems?: MenuItem[];

    constructor(options:any = {}) {
        this.id = options.id;
        this.title = options.title;
        this.navigationLink = options.navigationLink;
        this.isExternalLink = options.isExternalLink;
        this.imageUrl = options.imageUrl;
        this.subItemLayout = options.subItemLayout;
        this.subItems = options.subItems;
        this.translationKey = options.translationKey;
        this.inNewTab = options.inNewTab;
        this.name = options.name;
        this.content = options.content;
        this.parentId = options.parentId;
        this.sortId = options.sortId;
        this.action = options.action;
        this.submenu = options.submenu;
        this.icon = options.icon;
        this.isOpened = options.isOpened;
        this.isWebsiteLink = options.isWebsiteLink;
        this.menuItems = options.menuItems;
    }

}

export enum MenuLayoutType { List, Title }
