/**
 * Address model
 */
export class AddressModel {
    public firstName: string;
    public lastName: string;
    public firstName2: string;
    public lastName2: string;
    public firstName3: string;
    public lastName3: string;
    public shipFName: string;
    public shipLName: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: StateModel;
    public zipCode: string;
    public area: string;
    public country: CountryModel;
    public fullAddress: string;
    public isUseShippingAddress: boolean;

    constructor(options: any = {}) {
        this.firstName = options.firstName;
        this.lastName = options.lastName;
        this.shipFName = options.shipFName;
        this.shipLName = options.shipLName;
        this.address1 = options.address1;
        this.address2 = options.address2;
        this.address3 = options.address3;
        this.city = options.city;
        this.state = options.state;
        this.zipCode = options.zip;
        this.area = options.area;
        this.country = options.country;
        this.isUseShippingAddress = options.isUseShippingAddress;
    }
}

export interface CountryModel {
    code2: string;
    states: string;
}

export interface StateModel {
    code: string;
    name: string;
    name2: string;
}
