export class PaymentOption {
    public paymentMethodValue: string;
    public paymentMethodDisplayName: string;
    public showorder?: number;

    constructor(options: any = {}) {
        this.paymentMethodValue = options.paymentMethodValue;
        this.paymentMethodDisplayName = options.paymentMethodDisplayName;
        this.showorder = options.showorder;
    }
}
