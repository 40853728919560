import { createReducer, Action, on } from '@ngrx/store';

import * as EventAgendaActions from '../actions/event-agenda.actions';
import { Agenda } from '../../models/event-info.interface';
import { EventAgendaState } from '../../models/event-agenda-state.model';
import { EntityState } from '@ngrx/entity';
import { EventMemberModel } from '../..';

const initialState: EventAgendaState = {
    agendas: [],
    error: '',
    hasLoaded: false
};
export interface AgendaPostState extends EntityState<EventMemberModel> {}

const eventReducer = createReducer(
    initialState,
    on(EventAgendaActions.updateAgendasInfo, (state: EventAgendaState, agendasInfo: { data: Agenda[] }) => {
        return { ...state, agendas: agendasInfo.data, hasLoaded: true };
    }),
    on(EventAgendaActions.loadEventAgendaError, (state: EventAgendaState, error) => {
        return { ...state, error };
    }),
    on(EventAgendaActions.loadEventAgendaSuccess, (state: EventAgendaState, error) => {
        return { ...state, error };
    })
);

export const eventAgendaReducer = function reducer(state: EventAgendaState | undefined, action: Action) {
    return eventReducer(state, action);
};
