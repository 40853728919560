export class LoyaltyPointsModel {
    public loyaltyRank: number;
    public cycleMonth: number;
    public loyaltyRankName: string;
    public loyaltyPointsBalance: number;
    public loyaltyPointsPriceListFK: number;
    public expiringPoints: number;
    public expirationDate: Date;
    public asCycles: number;
    public asCyclesLeftForNextRank: number;
    public canRedeemPoints: boolean;
    public active: boolean;
}