import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UrlHelperService } from './url-helper.service';
import { WebStorageService } from './web-storage.service';
import { SeoService } from './seo.service';
import { LoggerService } from './logger.service';
import { SettingsService } from './settings.service';
import { CurrencyConverter } from './pipes/index';


@NgModule({
    imports: [
        RouterModule
    ],
    exports: [
        CurrencyConverter
    ],
    declarations: [
        CurrencyConverter
    ],
    providers: [
        UrlHelperService,
        WebStorageService,
        SeoService,
        LoggerService,
        SettingsService
    ]
})
export class CommonModule {
    /**
     * This is required so the same provider is injected into the load on demand modules.
     *
     * More Details: http://angularfirst.com/the-ngmodule-forroot-convention/
     */
    public static forRoot(): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [
                UrlHelperService,
                WebStorageService,
                SeoService,
                LoggerService,
                SettingsService
            ]
        };
    }
}