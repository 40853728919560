export class MainDistributorDetailsModel {
    public isActive: boolean;
    public isQualified: boolean;
    public lastSmartDeliveryDate: Date;
    public nextSmartDeliveryDate: Date;
    public prePaidOrdersLastDate: Date;
    public isLastSmartDeliverySuccessful: boolean;
    public currentRank: number;
    public currentRankName: string;
    public nextRank: number;
    public nextRankName: string;
    public LCV: number;
    public goalLCV: number;
    public RCV: number;
    public goalRCV: number;
    public cycles: number;
    public goalCycles: number;
    public monthlyCV: number;
    public goalMonthlyCV: number;
    public maxYearMonthlyCV: number;
    public goalMaxYearMonthlyCV: number;
    public leftDistributorsCount: number;
    public goalLeftDistributorsCount: number;
    public rightDistributorsCount: number;
    public goalRightDistributorsCount: number;
    public leftExecutivesCount: number;
    public goalLeftExecutivesCount: number;
    public rightExecutivesCount: number;
    public goalRightExecutivesCount: number;
    public diamondLegsCount: number;
    public goalDiamondLegsCount: number;
    public sapphireLegsCount: number;
    public goalSapphireLegsCount: number;
    public activeDistributorsCount: number;
    public goalActiveDistributorsCount: number;
    public PGV: number;
    public goalPGV: number;
    public consecutiveMonths: number;
    public goalConsecutiveMonths: number;
    public announcements: string;

    public cyclesProgress: number;
    public monthlyCVProgress: number;
    public sapphireLegsProgress: number;
    public diamondLegsProgress: number;
    public activeDistributorsProgress: number;
    public pgvProgress: number;
    public leftDistributorsCountProgress: number;
    public rightDistributorsCountProgress: number;
    public leftExecutivesCountProgress: number;
    public rightExecutivesCountProgress: number;

    private setProgressProperties(): void {
        this.cyclesProgress = (this.cycles / this.goalCycles) * 100;
        this.monthlyCVProgress = (this.monthlyCV / this.goalMonthlyCV) * 100;
        this.sapphireLegsProgress = (this.sapphireLegsCount / this.goalSapphireLegsCount) * 100;
        this.diamondLegsProgress = (this.diamondLegsCount / this.goalDiamondLegsCount) * 100;
        this.activeDistributorsProgress = (this.activeDistributorsCount / this.goalActiveDistributorsCount) * 100;
        this.pgvProgress = (this.PGV / this.goalPGV) * 100;
        this.leftDistributorsCountProgress = (this.leftDistributorsCount / this.goalLeftDistributorsCount) * 100;
        this.rightDistributorsCountProgress = (this.rightDistributorsCount / this.goalRightDistributorsCount) * 100;
        this.leftExecutivesCountProgress = (this.leftExecutivesCount / this.goalLeftExecutivesCount) * 100;
        this.rightExecutivesCountProgress = (this.rightExecutivesCount / this.goalRightExecutivesCount) * 100;
    }

    // tslint:disable-next-line:member-ordering
    constructor(options: any) {
        this.isActive = options.isActive;
        this.isQualified = options.isQualified;
        this.lastSmartDeliveryDate = options.lastSmartDeliveryDate;
        this.nextSmartDeliveryDate = options.nextSmartDeliveryDate;
        this.prePaidOrdersLastDate = options.prePaidOrdersLastDate;
        this.isLastSmartDeliverySuccessful = options.isLastSmartDeliverySuccessful;
        this.currentRank = (options.currentRank % 1000 === 0) ? options.currentRank / 1000 : options.currentRank;
        this.currentRankName = options.currentRankName;
        this.nextRank = options.nextRank;
        this.nextRankName = options.nextRankName;
        this.LCV = options.lcv || 0;
        this.goalLCV = options.goalLCV || 0;
        this.RCV = options.rcv || 0;
        this.goalRCV = options.goalRCV || 0;
        this.cycles = options.cycles || 0;
        this.goalCycles = options.goalCycles || 0;
        this.monthlyCV = options.monthlyCV || 0;
        this.goalMonthlyCV = options.goalMonthlyCV || 0;
        this.maxYearMonthlyCV = options.maxYearMonthlyCV || 0;
        this.goalMaxYearMonthlyCV = options.goalMaxYearMonthlyCV || 0;
        this.leftDistributorsCount = options.leftDistributorsCount || 0;
        this.goalLeftDistributorsCount = options.goalLeftDistributorsCount || 0;
        this.rightDistributorsCount = options.rightDistributorsCount || 0;
        this.goalRightDistributorsCount = options.goalRightDistributorsCount || 0;
        this.leftExecutivesCount = options.leftExecutivesCount || 0;
        this.goalLeftExecutivesCount = options.goalLeftExecutivesCount || 0;
        this.rightExecutivesCount = options.rightExecutivesCount || 0;
        this.goalRightExecutivesCount = options.goalRightExecutivesCount || 0;
        this.diamondLegsCount = options.diamondLegsCount || 0;
        this.goalDiamondLegsCount = options.goalDiamondLegsCount || 0;
        this.sapphireLegsCount = options.sapphireLegsCount || 0;
        this.goalSapphireLegsCount = options.goalSapphireLegsCount || 0;
        this.activeDistributorsCount = options.activeDistributorsCount || 0;
        this.goalActiveDistributorsCount = options.goalActiveDistributorsCount || 0;
        this.PGV = options.pgv || 0;
        this.goalPGV = options.goalPGV || 0;
        this.consecutiveMonths = options.consecutiveMonths || 0;
        this.goalConsecutiveMonths = options.goalConsecutiveMonths || 0;
        this.announcements = options.announcements;

        this.setProgressProperties();
    }
}