import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { OrderService } from '../../../checkout/services/order.service';
import { TranslationService, CmsSessionService, LocaleService } from '@jeunesse/angular';
import { SubSink } from 'subsink';
import { OrderModel, BonusTokenOrderLimit } from '../../../checkout/public-api';

@Component({
    selector: 'app-bonus-credit',
    templateUrl: './bonus-credit.component.html',
    styleUrls: ['./bonus-credit.component.scss']
})
export class BonusCreditComponent implements OnInit, OnDestroy {
    public orderModel: any;
    public limitModel: BonusTokenOrderLimit;
    @Input() public tokenAmount: number = 0;
    @Output() siteUrl: EventEmitter<string> = new EventEmitter();
    @Output() signUpToken: EventEmitter<string> = new EventEmitter();
    public bonusForm: FormGroup;
    public translations: any = {
        siteUrl: '',
        bonusCreditToken: '',
        limitPrompt: ''
    };
    private subSink = new SubSink();
    public showNote: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService
    ) { }

    ngOnInit() {
        this.subSink.sink = this.orderService.getOrderModel().subscribe((orderModel: OrderModel) => {
            this.orderModel = orderModel;
            this.subSink.sink = this.orderService.getBonusTokenOrderLimit(orderModel.mainOrdersFk).subscribe((limitModel: BonusTokenOrderLimit) => {
                this.limitModel = limitModel;
                if (this.limitModel.allowedPercent > 0 || this.limitModel.allowedAmount > 0)
                    this.showNote = true;
                this.getTranslations();
            });
        });
        
        this.bonusForm = this.formBuilder.group({
            siteUrl: [''],
            bonusToken: ['']
        });

        this.bonusForm.get('siteUrl').valueChanges.subscribe((res) => {
            this.siteUrl.emit(res);
        });
        this.bonusForm.get('bonusToken').valueChanges.subscribe((res) => {
            this.signUpToken.emit(res);
        });

    }

    ngOnDestroy() {
        this.subSink.unsubscribe();
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.siteUrl = this.translationService.translate('bonusCredit', 'siteUrl', 'SiteUrl*');
        this.translations.bonusCreditToken = this.translationService.translate('bonusCredit', 'bonusCreditToken', 'Bonus Credit Token*');
        let limitPromptFormat: string = this.translationService.translate('bonusCredit', 'CVReduceNote',
            'Note* Only {0}% of the order total may be paid with Bonus Credits. Please note that order CV will be reduced by the percentage of Bonus Credits used to pay for the order.')
        this.translations.limitPrompt = this.translationService.stringFormat(limitPromptFormat, this.limitModel.allowedPercent * 100);
    }
}
