import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SnackBarService } from './snack-bar.service';
import { SnackBarWarnComponent } from './snack-bar-warn.component';
import { SnackBarInfoComponent } from './snack-bar-info.component';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatSnackBarModule,
        MatIconModule,
        FlexLayoutModule
    ],
    exports: [
        SnackBarInfoComponent,
        SnackBarWarnComponent
    ],
    declarations: [
        SnackBarInfoComponent,
        SnackBarWarnComponent
    ],
    entryComponents: [
        SnackBarInfoComponent,
        SnackBarWarnComponent
    ]
})
export class SnackBarModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SnackBarModule,
            providers: [
                SnackBarService
            ]
        };
    }
 }