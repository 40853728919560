import { Component, OnInit, OnDestroy } from '@angular/core';
import { CmsSessionService, CompanyCountry, LocaleService } from '@jeunesse/angular/common';
import { MemberService, MenuStateService, RepSiteInfo, RepSiteService } from '@jeunesse/cart-lib';
import { MainCartService } from '@jeunesse/slide-cart';
import { Subscription, of } from 'rxjs';
import { ISiteSettings } from './shared/helpers/site-settings.interface';

declare const SiteSettings: ISiteSettings;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    public cartOpen: boolean = false;
    public repSiteInfo: RepSiteInfo = new RepSiteInfo({});
    public locale: string;
    public companyCountry: CompanyCountry;
    private subscriptions = new Subscription();
    constructor(private readonly menuStateService: MenuStateService,
        private readonly memberService: MemberService,
        private readonly mainCartService: MainCartService,
        private repSiteService: RepSiteService,
        private cmsSessionService: CmsSessionService,
        private localeService: LocaleService) {}

    ngOnInit(): void {
        // Get the set locale
        this.locale = this.cmsSessionService.getCulture();
        // Subscribe to menu state changes
        this.subscriptions.add(this.menuStateService.getCartOpenState$()
            .subscribe((state: boolean) => {
            this.cartOpen = state;
        }));
        this.subscriptions.add(this.localeService.getCultureObservable$()
            .subscribe((culture: string) => {
                if (!culture) { return of(); }
                this.locale = culture;
                culture = culture.replace(/"/g, '')
                const countryCode: string = culture.split('-')[1];
                this.subscriptions.add(this.mainCartService.updateCart({
                        culture: culture,
                        countryCode: countryCode
                    }).subscribe());
        }));
        // Subscribe to RepSite info changes
        this.subscriptions.add(this.repSiteService.repInfoObservable$
            .subscribe((repInfo: RepSiteInfo) => {
                this.repSiteInfo = repInfo
            }));
        // Subscribe to CompanyCountry Info changes
        this.subscriptions.add(this.cmsSessionService.GetCountryInfo()
            .subscribe((companyCountry: CompanyCountry) => {
                this.companyCountry = companyCountry;
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    showHideCart(show: boolean): void {
        this.menuStateService.showHideCartState(show);
    }
}
