import { AddressModel } from './address.model';
/**
 * Model for holding member or customer information.
 */
export class MemberModel {
    public mainPk: number;
    public siteUrl: string;
    public firstName: string;
    public lastName: string;
    public company: string;
    public useBilling: boolean;
    public isUseShippingAddress: boolean;
    public userName: string;
    public password: string;
    public referrerFk: number;
    public mainTypeFk: number;
    public languageFk: number;
    public cultureName: string;
    public billingAddress: AddressModel;
    public shippingAddress: AddressModel;
    public mailingAddress: AddressModel;

    constructor(mainTypeFK: number = 2, languageFK: number = 1, referrer: number = 0) {
        this.mainPk = 0;
        this.siteUrl = '';
        this.firstName = '';
        this.lastName = '';
        this.company = '';
        this.useBilling = false;
        this.isUseShippingAddress = false;
        this.userName = '';
        this.password = '';
        this.cultureName = 'en-US';
        this.referrerFk = referrer;
        this.mainTypeFk = mainTypeFK;
        this.languageFk = languageFK;
        this.billingAddress = new AddressModel();
        this.shippingAddress = new AddressModel();
        this.mailingAddress = new AddressModel();
    }
}
