import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { SubSink } from 'subsink';

import { LocaleService } from '@jeunesse/angular/common';
import {
    SmartDeliveryModel, LoyaltyPointsModel,
    ProductService, ProductQueryModel, ProductModel, RepSiteService, RepSiteInfo, ProductStateService, MemberService, SmartDeliveryService
} from '@jeunesse/cart-lib';
import { SeoService } from '@jeunesse/angular/common';
import { MainCartService } from '@jeunesse/slide-cart';
import { MatDialog } from '@angular/material/dialog';
import { LoyaltyPointsInfoDialogComponent } from '../catalog/loyalty-points-info-dialog/loyalty-points-info-dialog.component';
import { Direction } from '@angular/cdk/bidi';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit, OnDestroy {
    protected productSubject$: BehaviorSubject<ProductModel> = new BehaviorSubject(new ProductModel());
    public productObservable$: Observable<ProductModel> = this.productSubject$.asObservable();
    public smartDeliveryInfo: SmartDeliveryModel = new SmartDeliveryModel();
    public loyaltyPointsInfo: LoyaltyPointsModel;
    public productUpdatedObservable$: Observable<boolean>;
    public repInfoObservable$: Observable<RepSiteInfo>;
    private directionality: Direction = 'ltr';
    private subs = new SubSink();

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly productService: ProductService,
        private readonly router: Router,
        private readonly memberService: MemberService,
        private readonly smartDeliveryService: SmartDeliveryService,
        private readonly seoService: SeoService,
        private readonly repSiteService: RepSiteService,
        private readonly mainCartService: MainCartService,
        private readonly localeService: LocaleService,
        public readonly dialog: MatDialog,
        public readonly productStateService: ProductStateService
    ) { }

    public ngOnInit(): void {

        this.subs.sink = this.activatedRoute.params.subscribe((params: Params) => {
            const productPk: number = params['id'];
            const menuId: number = params['menuId'];
            const country: string = this.localeService.getCountryCode();
            const culture: string = this.localeService.getLocaleCookie();
            const isRedemption: boolean = this.mainCartService.isStoreInRedemptionMode;

            this.subs.sink = this.repSiteService.repInfoObservable$.subscribe((rep: RepSiteInfo) => {
                const siteUrl: string = this.activatedRoute.parent.parent.snapshot.params['siteUrl'];

                this.subs.sink = this.mainCartService.getMainCart$().subscribe((cart) => {
                    const query: ProductQueryModel = {
                        cartType: isRedemption ? 'LR' : cart.cartType,
                        productPk: productPk,
                        countryCode: country || 'US',
                        culture: culture || 'en-US',
                        isRedemption: isRedemption,
                        menuId: menuId,
                        repSiteUrl: siteUrl
                    };
                    // Get Product
                    this.subs.sink = this.productStateService.getProducts$().subscribe((res: ProductModel[]) => {
                        if (res.length === 0) {
                            this.getProductFromServer(query, siteUrl);
                        } else {
                            const products: ProductModel[] = res.filter((product) => {
                                return product.productPK.toString() === query.productPk.toString();
                            });
                            this.productSubject$.next(products[0]);
                        }
                    });
                });
            });
            this.subs.sink = this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
        });
        this.repInfoObservable$ = this.repSiteService.repInfoObservable$;
        this.productUpdatedObservable$ = of(!!this.mainCartService.getMainCart$());

        // Get SmartDelivery Info
        this.subs.sink = this.smartDeliveryService.getSettings$().subscribe((res: SmartDeliveryModel) => {
            this.smartDeliveryInfo = res;
        });
        // Load the user's loyalty points
        this.subs.sink = this.memberService.getLoyaltyPointsInfo().subscribe((lp: LoyaltyPointsModel) => {
            this.loyaltyPointsInfo = lp;
        });
        this.seoService.sendFBPixelPageView('shop-category', this.router.url);
        this.seoService.sendSiftPageView();
        window.scrollTo(0, 0);
    }

    public getProductFromServer(query, siteUrl): void {
        this.subs.sink = this.productService.products(query).subscribe((products: ProductModel[]) => {
            // Not found
            if (!products[0]) {
                this.router.navigate([siteUrl, 'catalog', 'products']);
            }
            this.productSubject$.next(products[0]);
        });
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.productSubject$.unsubscribe();
    }
    public openLpInfoDialog(): void {
        this.dialog.open(LoyaltyPointsInfoDialogComponent, { direction: this.directionality });
    }
}
