import { NgModule, ModuleWithProviders } from '@angular/core';
import { SlideCartComponent } from './components/slide-cart/slide-cart.component';
import { CommonModule } from '@angular/common';
import { SlideCartItemComponent } from './components/slide-cart-item/slide-cart-item.component';
import { FormsModule } from '@angular/forms';
import { MainCartService } from './services/main-cart.service';
import { MainCartApiService } from './services/main-cart-api.service';
import { MainCartStateService } from './services/main-cart-state.service';
import { MainCartItemApiService } from './services/main-cart-item-api.service';
import { CmsModule } from '@jeunesse/angular';
import { DialogExitImportCartComponent } from './components/exit-import-cart/dialog-exit-import-cart.component';
import { RemoveCartItemDialogComponent } from './components/remove-cart-item-dialog/remove-cart-item-dialog.component';
import { CartItemsRemovedDialogComponent } from './components/cart-items-removed-dialog/cart-items-removed-dialog.component';
import { OrderErrorDialogComponent } from './components/order-error-dialog/order-error-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfigModalComponent } from './products/components/configuration/config-modal.component';
import { ConfigurationComponent } from './products/components/configuration/configuration.component';
import { SmartDeliveryConfirmationComponent } from './products/components/smartdelivery/sd-confirmation.component';
import { ShadeMatchComponent } from './products/components/nvshadematch/shade-match.component';
import { ShadeFormComponent } from './products/components/nvshadematch/shade-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { QualifiedCampaignService } from '@jeunesse/slide-cart';

@NgModule({
    declarations: [
        SlideCartComponent,
        SlideCartItemComponent,
        DialogExitImportCartComponent,
        RemoveCartItemDialogComponent,
        CartItemsRemovedDialogComponent,
        OrderErrorDialogComponent,
        SmartDeliveryConfirmationComponent,
        ConfigModalComponent,
        ConfigurationComponent,
        ShadeMatchComponent,
        ShadeFormComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatInputModule,
        MatDividerModule,
        MatDialogModule,
        MatCheckboxModule,
        MatIconModule,
        MatSelectModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatListModule,
        MatMenuModule,
        FormsModule,
        CmsModule.forRoot()
    ],
    exports: [
        SlideCartComponent,
        DialogExitImportCartComponent,
        CartItemsRemovedDialogComponent,
        OrderErrorDialogComponent,
        SmartDeliveryConfirmationComponent,
        ConfigModalComponent,
        ConfigurationComponent,
        ShadeMatchComponent,
        ShadeFormComponent
    ],
    providers: [
        MainCartService,
        MainCartApiService,
        MainCartItemApiService,
        MainCartStateService,
        QualifiedCampaignService
    ],
    entryComponents: [
        DialogExitImportCartComponent,
        RemoveCartItemDialogComponent,
        CartItemsRemovedDialogComponent,
        OrderErrorDialogComponent,
        SmartDeliveryConfirmationComponent,
        ConfigModalComponent,
        ConfigurationComponent,
        ShadeMatchComponent,
        ShadeFormComponent
    ]
})
export class SlideCartModule {
    public static forRoot(): ModuleWithProviders<SlideCartModule> {
        return {
            ngModule: SlideCartModule,
            providers: [
                MainCartService,
                MainCartApiService,
                MainCartItemApiService,
                MainCartStateService,
                {provide: MAT_DIALOG_DATA, useValue: {}},
                {provide: MatDialogRef, useValue: {}}
            ]
        };
    }
 }
