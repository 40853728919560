import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthHttpService, AuthService, TokenModel } from '@jeunesse/angular';
import { UrlHelperService, WebStorageService } from '@jeunesse/angular';
import { forkJoin, Observable, of, Subject, Subscriber } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RepDiscountResponse } from '../../checkout/public-api';
import { LoyaltyPointsModel, MemberConvert, MemberModel, SmartDeliveryModel, Wallet, StatesModel } from '../models/public-api';
import { ReferrerModel } from '../models/referrer-model';
import { AddressModel } from './../models/address.model';
import { SmartDeliveryService } from './smartdelivery.service';
import { MainDistributorDetailsModel } from '../../shared/models/main-dist-details.model';

/**
 * Service for managing member information.
 */
@Injectable({
    providedIn: 'root'
})
export class MemberService {
    protected memberInfoSubject = new Subject<MemberModel>();
    public statesModel: StatesModel[];
    public memberInfoState: Observable<MemberModel> = this.memberInfoSubject.asObservable();
    protected walletInfoSubject = new Subject<Wallet>();
    public walletInfoState: Observable<Wallet> = this.walletInfoSubject.asObservable();
    //protected smartDeliveryInfoSubject$ = new BehaviorSubject<SmartDeliveryModel>(null);
    //public smartDeliveryInfoObservable$: Observable<SmartDeliveryModel> = this.smartDeliveryInfoSubject$.asObservable();
    //private smartDeliveryInfo: SmartDeliveryModel;
    private loyaltyPointsInfo: LoyaltyPointsModel;
    private _memberModel: MemberModel;
    constructor(private readonly authHttp: AuthHttpService,
        private readonly urlHelper: UrlHelperService,
        private readonly authService: AuthService,
        private readonly webStorageService: WebStorageService,
        private readonly smartDeliverService: SmartDeliveryService) {
        const sessionItem: string = this.webStorageService.getSession('member');
        const sessionStates: any = this.webStorageService.getSession('states');
        if (sessionStates) {
            this.statesModel = JSON.parse(sessionStates);
        }
        if (sessionItem) {
            this._memberModel = JSON.parse(sessionItem);
            this.memberInfoSubject.next(this._memberModel);
        } else {
            this._memberModel = new MemberModel(2, 1);
            this.generateGuestSiteUrl();
            this._memberModel.password = this.uniqueid();
            this.memberInfoSubject.next(this._memberModel);
        }
    }

    /**
     * Returns the current session's model.
     */
    public get memberModel(): MemberModel {
        return this._memberModel;
    }

    /**
     * Sets the current session's model.
     */
    public set memberModel(memberModel: MemberModel) {
        this._memberModel = memberModel;
        this.memberInfoSubject.next(this._memberModel);
    }

    /**
     * Saves the member model to session.
     */
    public saveToSession(): void {
        this.webStorageService.setSession('member', JSON.stringify(this._memberModel));
    }

    /**
     * Clears and resets the current session's model.
     */
    public clearMemberModel(): void {
        this.webStorageService.removeSession('member');
        this._memberModel = new MemberModel(2, 1);
        this.generateGuestSiteUrl();
        this._memberModel.password = this.uniqueid();
    }

    /**
     * remove product to a logged in user's smart delivery.
     * @param autoshipitempk autoshipitempk of product.
     */
    // public removeProductToSmartDelivery(autoshipitempk: number, productPk: number): Observable<void> {
    //     return this.authHttp.delete<void>(this.urlHelper.toCartApi(`/v1/smartdelivery/items/${autoshipitempk}`))
    //         .pipe(map((_) => {
    //             if (this.smartDeliveryInfo && this.smartDeliveryInfo.smartDeliveryItems) {
    //                 this.smartDeliveryInfo.smartDeliveryItems = this.smartDeliveryInfo.smartDeliveryItems.filter(x => x.productPK !== autoshipitempk);
    //                 this.smartDeliveryInfoSubject$.next(this.smartDeliveryInfo);
    //             }
    //         }));
    //     this.smartDeliverService.removeItem(autoshipitempk, productPk);
    //     return of();
    // }

    /**
     * Returns a SmartDelivery profile for a logged in user from session if available.
     */
    // public getSmartDeliveryInfo(): Observable<SmartDeliveryModel> {
    //     if (this.smartDeliveryInfo) {
    //         return of(this.smartDeliveryInfo);
    //     } else {
    //         const model: TokenModel = (this.authService.isLoggedIn) ? this.authService.getAuthToken() : undefined;
    //         const containCmsAdmin: boolean = (model !== undefined) ? model.claims.includes('CmsAdmin') : false;
    //         if (this.authService.isLoggedIn && !containCmsAdmin) {
    //             return this.authHttp.get<SmartDeliveryModel>(this.urlHelper.toCartApi('/v1/smartdelivery'))
    //                 .pipe(mergeMap(model =>
    //                     this.authHttp.get<SmartDeliveryItemModel[]>(this.urlHelper.toCartApi('/v1/smartdelivery/items')).pipe(
    //                         map((res: SmartDeliveryItemModel[]) => {
    //                             model.smartDeliveryItems = res;
    //                             this.smartDeliveryInfo = model;
    //                             this.smartDeliveryInfoSubject$.next(model);
    //                             return model;
    //                         }))
    //                 ));
    //         } else {
    //             return of(this.smartDeliveryInfo);
    //         }
    //     }
    // }

    /**
     * Checks if a product already exists in a Member's smart delivery list.
     * @param productFk Product PK to check
     */
    // public hasSDItemAlready(productFk: number): Observable<boolean> {
    //     return this.getSmartDeliveryInfo().pipe(map((res: SmartDeliveryModel) => {
    //         if (!res) { return false; }
    //         return (res.smartDeliveryItems.filter(x => x.productPK === productFk)[0]) ? true : false;
    //     }));
    // }

    /**
     * Adds a product to a logged in user's smart delivery.
     * @param model SmartDeliveryItemFormModel model
     */
    // public addProductToSmartDelivery(model: SmartDeliveryItemFormModel): Observable<SmartDeliveryItemModel> {
    //     return this.authHttp.post<SmartDeliveryItemModel>(this.urlHelper.toCartApi('/v1/smartdelivery/items'), model)
    //         .pipe(map((item: SmartDeliveryItemModel) => {
    //             if (this.smartDeliveryInfo && item) {
    //                 if (model.isImportItem) {
    //                     this.smartDeliveryInfo.isImportOrder = model.isImportItem;
    //                 }
    //                 this.smartDeliveryInfo.smartDeliveryItems.push(item);
    //                 this.smartDeliveryInfoSubject$.next(this.smartDeliveryInfo);
    //             }
    //             return item;
    //         }));
    // }

    // public addProductsToSmartDelivery(model: SmartDeliveryItemFormModel[]): Observable<SmartDeliveryItemModel[]> {
    //     return this.authHttp.post<SmartDeliveryItemModel[]>(this.urlHelper.toCartApi('/v1/smartdelivery/items/list'), model)
    //         .pipe(map((items: SmartDeliveryItemModel[]) => {
    //             if (this.smartDeliveryInfo && (items && items.length)) {
    //                 if (model.some(x => x.isImportItem)) {
    //                     this.smartDeliveryInfo.isImportOrder = model.some(x => x.isImportItem);
    //                 }
    //                 this.smartDeliveryInfo.smartDeliveryItems.push(...items);
    //                 this.smartDeliveryInfoSubject$.next(this.smartDeliveryInfo);
    //             }
    //             return items;
    //         }));
    // }

    /**
     * Grabs the rep info for the currently logged in user.
     */
    public getReferrerInfo(): Observable<any> {
        if (this.authService.isLoggedIn) {
            return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v1/member/referrer')).pipe();
        }
    }

    /**
     * Returns the local loyalty points model object.
     */
    public getLoyaltyPointsInfo(): Observable<LoyaltyPointsModel> {
        if (this.loyaltyPointsInfo) {
            return Observable.create((res: Subscriber<LoyaltyPointsModel>) => {
                res.next(this.loyaltyPointsInfo);
                res.complete();
            });
        } else {
            if (this.authService.isLoggedIn) {
                return this.authHttp.get<LoyaltyPointsModel>(this.urlHelper.toCartApi(`/v1/loyalty-points/${this.authService.getMainId()}`))
                    .pipe(map((info: LoyaltyPointsModel) => {
                        this.loyaltyPointsInfo = info;
                        return info;
                    }));
            } else {
                return Observable.create((res: Subscriber<LoyaltyPointsModel>) => {
                    res.next(new LoyaltyPointsModel());
                    res.complete();
                });
            }
        }
    }

    /**
     * Clears the local object models.
     */
    public clearLocalInfo(): void {
        this.loyaltyPointsInfo = null;
        //this.smartDeliveryInfo = null;
    }

    /**
     * Creates a unique random guest site url.
     */
    public generateGuestSiteUrl(): void {
        const siteUrl: string = 'Guest_' + this.generateUUID();
        this._memberModel.siteUrl = siteUrl;
    }

    /**
     * Updates the site url.
     */
    public updateSiteUrl(siteUrl): void {
        this._memberModel.siteUrl = siteUrl;
    }

    /**
     * Used to create a new Main object based off the member model.
     */
    public createMember(): Observable<number> {
        if (this.authService.isLoggedIn) {
            // Add check if it's a guest account and they entered a username.
            if (this.isGuestAccount && (this._memberModel.userName && (this._memberModel.siteUrl !== this._memberModel.userName))) {
                // Update the account with their login
                const model: MemberConvert = { email: this._memberModel.userName, password: this._memberModel.password, mainFK: this._memberModel.mainPk };
                return this.setEmailAndPassword(model).pipe(map((success: any) => {
                    this._memberModel.siteUrl = this._memberModel.userName;
                    this._memberModel.userName = '';
                    this._memberModel.mainPk = this.authService.getMainId();
                    return this.authService.getMainId();
                }));
            } else {
                return Observable.create((res: Subscriber<number>) => {
                    res.next(this.authService.getMainId());
                    res.complete();
                });
            }
        } else if (this._memberModel.mainPk > 0) {
            // Add check if it's a guest account and they entered a username.
            if (this.isGuestAccount && (this._memberModel.userName && (this._memberModel.siteUrl !== this._memberModel.userName))) {
                // Update the account with their login
                const model: MemberConvert = { email: this._memberModel.userName, password: this._memberModel.password, mainFK: this._memberModel.mainPk };
                return this.setEmailAndPassword(model).pipe(map((success: any) => {
                    this._memberModel.siteUrl = this._memberModel.userName;
                    this._memberModel.userName = '';
                    return this._memberModel.mainPk;
                }));
            } else {
                return Observable.create((res: Subscriber<number>) => {
                    res.next(this._memberModel.mainPk);
                    res.complete();
                });
            }
        } else {
            const model: any = this.toCreateMemberModel();
            return this.authHttp.post<number>(this.urlHelper.toProfileApi('/v1/member'), model).pipe(map((res: number) => {
                this._memberModel.mainPk = res;
                if (this.isGuestAccount && this._memberModel.userName) {
                    this._memberModel.siteUrl = this._memberModel.userName;
                    this._memberModel.userName = '';
                }
                return res;
            }));
        }
    }

    /**
     * Updates the current member model's shipping address info.
     */
    public updateShippingAddress(isOTG: boolean, mainOrderTypeFk: number = null): Observable<boolean> {
        // Only allow updates for customers and distributors doing a signup order
        const mainTypeId: number = this.authService.getMainTypeId();
        if (mainTypeId === 1 && (mainOrderTypeFk && mainOrderTypeFk !== 1)) {
            return of(true);
        }

        const model: any = {
            firstName: this._memberModel.shippingAddress.country === 'JP' ? this._memberModel.shippingAddress.firstName3 : this._memberModel.shippingAddress.firstName,
            lastName: this._memberModel.shippingAddress.country === 'JP' ? this._memberModel.shippingAddress.lastName3 :  this._memberModel.shippingAddress.lastName,
            address1: this._memberModel.shippingAddress.address1,
            address2: this._memberModel.shippingAddress.address2,
            address3: this._memberModel.shippingAddress.address3,
            city: this._memberModel.shippingAddress.city,
            state: this._memberModel.shippingAddress.state || this._memberModel.shippingAddress.stateToUse,
            postalCode: this._memberModel.shippingAddress.postalCode,
            country: this._memberModel.shippingAddress.country,
            phone: this._memberModel.shippingAddress.phoneNumber,
            secondPhone: this._memberModel.shippingAddress.secondPhoneNumber,
            email: this._memberModel.shippingAddress.email,
            mainTypeFk: this._memberModel.mainTypeFk,
            area: this._memberModel.shippingAddress.area,
            company: this._memberModel.shippingAddress.company,
            ssn: this._memberModel.shippingAddress.ssn,
            taxIdNumber: this._memberModel.shippingAddress.taxIdNumber,
            // SP overrides it with the opposite
            isUseShippingAddress: !this._memberModel.isUseShippingAddress,
            isOTG
        };

        // Only update if the user has not completed an order
        return this.authHttp.get<boolean>(this.urlHelper.toCartApi(`/v1/order/${this.authService.getMainId()}/has-completed`)).pipe(switchMap((completed: boolean) => {
            if (!completed) {
                return this.authHttp.put<boolean>(this.urlHelper.toProfileApi('/v1/member/address/shipping'), model).pipe(map((success: boolean) => success));
            } else {
                return of(true);
            }
        }));
    }

    /**
     * Updates the current member model's billing address info.
     */
    public updateBillingAddress(): Observable<boolean> {
        const model: any = {
            firstName: this._memberModel.billingAddress.firstName,
            lastName: this._memberModel.billingAddress.lastName,
            address1: this._memberModel.billingAddress.address1,
            address2: this._memberModel.billingAddress.address2,
            address3: this._memberModel.billingAddress.address3,
            city: this._memberModel.billingAddress.city,
            state: this._memberModel.billingAddress.state || this._memberModel.billingAddress.stateToUse,
            postalCode: this._memberModel.billingAddress.postalCode,
            country: this._memberModel.billingAddress.country,
            phone: this._memberModel.billingAddress.phoneNumber,
            secondPhone: this._memberModel.billingAddress.secondPhoneNumber,
            email: this._memberModel.billingAddress.email,
            mainTypeFk: this._memberModel.mainTypeFk,
            area: this._memberModel.billingAddress.area,
            company: this._memberModel.billingAddress.company
        };

        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/member/address/billing'),
            model).pipe(map((resp: boolean) => {
                return resp;
            }));
    }

    /**
     * Updates the referrer on a member record.
     * @param referrerSiteUrl Referrer's siteurl
     */
    public setReferrer(referrerSiteUrl: string): Observable<any> {
        const model: any = {
            mainPk: this._memberModel.mainPk,
            siteUrl: referrerSiteUrl
        };
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/member/referrer'), model);
    }

    /**
     * Gets the shipping address for the currently member session.
     */
    public getShippingAddress(isOTG: boolean = true): Observable<AddressModel> {
        if (this.authService.isLoggedIn) {
            let params = new HttpParams();
            params = params.append('isOTG', isOTG.toString());
            return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v1/member/address/shipping'), { params: params })
            .pipe(map((res: any) => {
                this.getStatesCode(res);
                if (res.country.code2 === 'JP') {
                    this._memberModel.shippingAddress.firstName3 = res.firstName;
                    this._memberModel.shippingAddress.lastName3 = res.lastName;
                } else {
                    this._memberModel.shippingAddress.firstName = res.firstName;
                    this._memberModel.shippingAddress.lastName = res.lastName;
                }
                this._memberModel.shippingAddress.address1 = res.address1;
                this._memberModel.shippingAddress.address2 = res.address2;
                this._memberModel.shippingAddress.address3 = res.address3;
                this._memberModel.shippingAddress.city = res.city;
                this._memberModel.shippingAddress.country = res.country.code2;
                this._memberModel.shippingAddress.state = res.state.code;
                this._memberModel.shippingAddress.stateToUse = res.stateToUse || res.state.code;
                this._memberModel.shippingAddress.stateCodeIsNumber = !isNaN(res.state.code);
                this._memberModel.shippingAddress.postalCode = res.zip;
                this._memberModel.shippingAddress.phoneNumber = res.phone;
                this._memberModel.shippingAddress.secondPhoneNumber = res.secondPhone;
                this._memberModel.shippingAddress.area = res.area;
                this._memberModel.shippingAddress.email = res.email;
                this._memberModel.isUseShippingAddress = res.isUseShippingAddress;
                return this._memberModel.shippingAddress;
            }));
        } else {
            return of(this._memberModel.shippingAddress);
        }
    }

    public getStatesCode(addressModel: any): any {
        let selectedStates = null;
        const state = addressModel.state.code ? addressModel.state.code : addressModel.state;
        addressModel.stateCodeIsNumber = !isNaN(state);
        if (this.statesModel && this.statesModel.length) {
            selectedStates = this.statesModel.find(x => x.code === state);
        }
        if (selectedStates) {
            addressModel.stateToUse = selectedStates.code;
            addressModel.state = selectedStates.code;
        } else {
            addressModel.stateToUse = state;
            addressModel.state = state;
        }
    }

    /**
     * Gets the billing address for the currently member session.
     */
    public getBillingAddress(): Observable<AddressModel> {
        if (this.authService.isLoggedIn) {
            return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v1/member/address/billing')).pipe(map((res: any) => {
                this.getStatesCode(res);
                this._memberModel.billingAddress.firstName = res.firstName;
                this._memberModel.billingAddress.lastName = res.lastName;
                if (res.country.code2 === 'JP') {
                    this._memberModel.billingAddress.firstName3 = res.firstName;
                    this._memberModel.billingAddress.lastName3 = res.lastName;
                }
                this._memberModel.billingAddress.address1 = res.address1;
                this._memberModel.billingAddress.address2 = res.address2;
                this._memberModel.billingAddress.address3 = res.address3;
                this._memberModel.billingAddress.city = res.city;
                this._memberModel.billingAddress.country = res.country.code2;
                this._memberModel.billingAddress.state = res.state.code || res.stateToUse;
                this._memberModel.billingAddress.stateToUse = res.stateToUse || res.state.code;
                this._memberModel.billingAddress.stateCodeIsNumber = !isNaN(res.state.code);
                this._memberModel.billingAddress.postalCode = res.zip;
                this._memberModel.billingAddress.phoneNumber = res.phone;
                this._memberModel.billingAddress.secondPhoneNumber = res.secondPhone;
                this._memberModel.billingAddress.area = res.area;
                this._memberModel.billingAddress.email = res.email;

                this._memberModel.mailingAddress.firstName = res.firstName;
                this._memberModel.mailingAddress.lastName = res.lastName;
                this._memberModel.mailingAddress.address1 = res.address1;
                this._memberModel.mailingAddress.address2 = res.address2;
                this._memberModel.mailingAddress.address3 = res.address3;
                this._memberModel.mailingAddress.city = res.city;
                this._memberModel.mailingAddress.country = res.country.code2;
                this._memberModel.mailingAddress.state = res.state.code || res.stateToUse;
                this._memberModel.mailingAddress.postalCode = res.zip;
                this._memberModel.mailingAddress.phoneNumber = res.phone;
                this._memberModel.mailingAddress.secondPhoneNumber = res.secondPhone;
                this._memberModel.mailingAddress.area = res.area;
                this._memberModel.mailingAddress.email = res.email;

                return this._memberModel.billingAddress;
            }));
        } else {
            return of(this._memberModel.billingAddress);
        }
    }

    /**
     * Used to convert an anonymous user to a customer account.
     * @param model MemberEmailPassword Model
     */
    public setEmailAndPassword(model: MemberConvert): Observable<any> {
        return this.authHttp.post<any>(this.urlHelper.toProfileApi('/v1/member/set-email-and-password'), model).pipe();
    }

    /**
     * Returns the URL for a link to upgrade the member.
     */
    public getUpgradeLink(): Observable<string> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v1/member/upgrade-link/${this._memberModel.mainPk}/${this._memberModel.shippingAddress.country}`));
    }

    /**
     * Returns the referrer main record for the currently logged in user.
     */
    public getReferrer(): Observable<any> {
        if (this.authService.isLoggedIn) {
            return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v1/member/referrer'));
        }
    }

    /**
     * Validates if requested siteUrl is available and valid
     * @param name Siteurl to validate
     */
    public checkIsSiteUrlAvailable(siteUrl: string): Observable<boolean> {
        const name: string = encodeURIComponent(siteUrl);
        return this.authHttp.post<boolean>(this.urlHelper.toProfileApi(`/v1/repsite/url/validate/${name}`));
    }

    /**
     *
     *
     */
    public updateUserProfile(profile: any): Observable<any> {
        return this.authHttp.put<any>(this.urlHelper.toProfileApi('/v1/users'), profile);
    }

    /**
     * Loads the currently logged in users info into our member model.
     */
    public loadMemberInfoFromLogin(isOTG: boolean = true): void {
        if (this.authService.isLoggedIn) {
            let tokenModel: TokenModel = this.authService.getAuthToken();
            this._memberModel = new MemberModel(tokenModel.mainTypeId, this.authService.getLangId());
            this._memberModel.mainPk = this.authService.getMainId();
            this._memberModel.siteUrl = this.authService.getSiteUrl();
            //
            // Get Referrer Info
            let referrerCall: Observable<any> = this.getReferrer();
            let getBillingCall: Observable<AddressModel> = this.getBillingAddress();
            let getShippingCall: Observable<AddressModel> = this.getShippingAddress(isOTG);

            forkJoin([referrerCall, getBillingCall, getShippingCall]).subscribe((results) => {
                if (results[0]) {
                    this._memberModel.referrerFk = results[0].mainPK;
                }
                this.memberInfoSubject.next(this._memberModel);
            });
        } else {
            // check for signup key and load signup user info
            let signupKey: string = this.webStorageService.getCookie('signupKey');
            if (signupKey) {
                this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v1/users/signup?guid=${signupKey}`)).subscribe((res: any) => {
                    this._memberModel.firstName = res.fname;
                    this._memberModel.lastName = res.lname;
                    this._memberModel.shippingAddress.address1 = res.addressOne;
                    this._memberModel.shippingAddress.address2 = res.addressTwo;
                    this._memberModel.shippingAddress.city = res.city;
                    this._memberModel.shippingAddress.country = res.country;
                    this._memberModel.shippingAddress.state = res.state || res.stateToUse;
                    this._memberModel.shippingAddress.postalCode = res.zip;
                    this._memberModel.shippingAddress.phoneNumber = res.phone;
                    this._memberModel.shippingAddress.email = res.email;
                    this._memberModel.mainTypeFk = res.mainTypeFk || 1; // signup users are distributors
                    this.memberInfoSubject.next(this._memberModel);
                });
            }
        }
    }

    /**
     * Will log in the current member.
     */
    public loginMember(): Observable<boolean> {
        if (this.authService.isLoggedIn) {
            return Observable.create((res: Subscriber<boolean>) => {
                res.next(true);
                res.complete();
            });
        } else {
            return this.authService.login(this._memberModel.siteUrl, this._memberModel.password).pipe(map((result: boolean) => {
                return result;
            }));
        }
    }

    /**
     * Returns if the current member model is a guest account.
     */
    public get isGuestAccount(): boolean {
        if (this._memberModel.siteUrl) {
            return this._memberModel.siteUrl.startsWith('Guest_');
        } else if (this.authService.isLoggedIn) {
            if (this.authService.getSiteUrl().startsWith('Guest_')) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Returns the referrer main record for the currently logged in user.
     */
    public setMemberPriceList(model: any, applyMainPriceList: boolean): Observable<void> {
        if (applyMainPriceList) {
            return this.authHttp.post<any>(this.urlHelper.toProfileApi('/v1/set-price-list'), model);
        } else {
            return of(undefined);
        }
    }

    /**
     * Returns an array of available rep discount codes that can be used for user's on checkout.
     */
    public getRepDiscountCodes(repSite: string, cartType: string, countryCode: string): Observable<RepDiscountResponse[]> {
        return this.authHttp.get<RepDiscountResponse[]>(this.urlHelper.toProfileApi(`/v1/repsite/discount-codes/${repSite}`), {params:{cartType, countryCode}});
    }

    public getWallet(mainPK?: number, mainTypePK?: number): Observable<any> {
        if (mainTypePK === 3) {
            return this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v2/member/wallet?mainPK=${mainPK}`)).pipe(map((result: Wallet) => {
                return this.walletInfoSubject.next(result);
            }))
        }
        else {
            return this.authHttp.get<any>(this.urlHelper.toProfileApi('/v2/member/wallet')).pipe(map((result: Wallet) => {
                return this.walletInfoSubject.next(result);
            }));
        }
    };

    public optOutRetailMember(orderNumber: number) {
        var model = {
            mainOrdersFK: orderNumber
        }
        return this.authHttp.post<any>(this.urlHelper.toProfileApi('/v2/member/guest/opt-out'), model)
    }

    public getReferrerDetails(siteurl: string): Observable<ReferrerModel> {
        return this.authHttp.get<any>(this.urlHelper.toProfileApi(`/v1/users/referrer?SiteUrl=${siteurl}&CheckFlow=true`))
            .pipe(
            map((data: any) => {
                return new ReferrerModel(data);
            }));
    }

    public setupMemberFromEventGuest(cartKey: string): Observable<any> {
        if (this.authService.isLoggedIn || !this.isGuestAccount) {
            return of(null);
        }
        var postModel: any = { cartKey, siteUrl: this.memberModel.siteUrl, password: this.memberModel.password };
        return this.authHttp.post<any>(this.urlHelper.toProfileApi('/v1/member-from-event-guest'), postModel).pipe(map((data: any) => {
            // Map info to the local member model
            if (data) {
                this.memberModel.mainPk = data.mainPK;
                this.memberModel.mainTypeFk = data.mainTypeFK;
                this.memberModel.referrerFk = data.referrerMainFK;
                this.memberModel.firstName = data.firstName;
                this.memberModel.lastName = data.lastName;
                this.memberModel.shippingAddress.firstName = data.firstName;
                this.memberModel.shippingAddress.lastName = data.lastName;
                this.memberModel.shippingAddress.address1 = data.shipAddress1;
                this.memberModel.shippingAddress.address2 = data.shipAddress2;
                this.memberModel.shippingAddress.city = data.shipCity;
                this.memberModel.shippingAddress.state = data.shipState;
                this.memberModel.shippingAddress.postalCode = data.shipZip;
                this.memberModel.shippingAddress.country = data.shipCountry;
                this.memberModel.shippingAddress.phoneNumber = data.phone;
                this.memberModel.shippingAddress.email = data.email;
                this.memberModel.languageFk = data.languageFK;
            }
            return data;
        }));
    }

    public getMainUserDetails(siteUrl: string): Observable<MainDistributorDetailsModel> {
        return this.authHttp.get<MainDistributorDetailsModel>(this.urlHelper.toProfileApi(`/v1/dashboards/main-details/${siteUrl}`)).pipe(
            map((res: MainDistributorDetailsModel) => {
                return new MainDistributorDetailsModel(res);
            })
        );
    }

    private uniqueid(): string {
        return new Date().getTime().toString();
    }

    private generateUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            (c: string) => {
                const r: number = Math.floor(Math.random() * 16),
                    v: number = c === 'x' ? r : (r % 4 + 4);
                return v.toString(16);
            }).toLowerCase();
    }

    private toCreateMemberModel(): any {
        return {
            siteUrl: (this._memberModel.userName) ? this._memberModel.userName : this._memberModel.siteUrl,
            firstName: this.getFirstName(),
            lastName: this.getLastName(),
            firstName2: this._memberModel.billingAddress.firstName2,
            lastName2: this._memberModel.billingAddress.lastName2,
            address1: this._memberModel.billingAddress.address1,
            address2: this._memberModel.billingAddress.address2,
            address3: this._memberModel.billingAddress.address3,
            company: this._memberModel.company,
            city: this._memberModel.billingAddress.city,
            state: this._memberModel.billingAddress.state,
            postalCode: this._memberModel.billingAddress.postalCode,
            country: this._memberModel.billingAddress.country,
            useBilling: this._memberModel.useBilling,
            shipFirstName: this.getFirstName(),
            shipLastName: this.getLastName(),
            shipAddress1: this._memberModel.shippingAddress.address1,
            shipAddress2: this._memberModel.shippingAddress.address2,
            shipAddress3: this._memberModel.shippingAddress.address3,
            shipCity: this._memberModel.shippingAddress.city,
            shipState: this._memberModel.shippingAddress.state,
            shipPostalCode: this._memberModel.shippingAddress.postalCode,
            shipCountry: this._memberModel.shippingAddress.country,
            phoneNumber: (!this._memberModel.billingAddress.phoneNumber || this._memberModel.billingAddress.phoneNumber === '') ? '0000000000' : this._memberModel.billingAddress.phoneNumber,
            phoneNumber2: (!this._memberModel.billingAddress.secondPhoneNumber || this._memberModel.billingAddress.secondPhoneNumber === '') ? '' : this._memberModel.billingAddress.secondPhoneNumber,
            email: this._memberModel.billingAddress.email,
            password: (this._memberModel.password.length < 1) ? this.uniqueid() : this._memberModel.password,
            referrer: this._memberModel.referrerFk,
            mainTypeFK: this._memberModel.mainTypeFk,
            culture: this._memberModel.cultureName
        };
    }

    private getFirstName(): string {
        if (this._memberModel.firstName) return this._memberModel.firstName;
        if (this._memberModel.billingAddress.firstName) return this._memberModel.billingAddress.firstName;
        if (this._memberModel.shippingAddress.firstName) return this._memberModel.shippingAddress.firstName;
        if (this._memberModel.shippingAddress.firstName2) return this._memberModel.shippingAddress.firstName2;
        if (this._memberModel.shippingAddress.firstName3) return this._memberModel.shippingAddress.firstName3;
        return "";
    }

    private getLastName(): string {
        if (this._memberModel.lastName) return this._memberModel.lastName;
        if (this._memberModel.billingAddress.lastName) return this._memberModel.billingAddress.lastName;
        if (this._memberModel.shippingAddress.lastName) return this._memberModel.shippingAddress.lastName;
        if (this._memberModel.shippingAddress.lastName2) return this._memberModel.shippingAddress.lastName2;
        if (this._memberModel.shippingAddress.lastName3) return this._memberModel.shippingAddress.lastName3;
        return "";
    }
}
