import { ShippingMethodViewModel } from './shipping-method-view.model';

export class OrderModel {
    public eventMemberId?: string;
    public mainOrdersFk: number;
    public mainOrdersPk?: number;
    public mainPk: number;
    public mainTypeFk?: number;
    public mainOrderTypeFk: number;
    public accountCreated?: boolean;
    public countryCode: string;
    public shippingTypeFk?: number;
    public hasPromo?: boolean;
    public paymentType?: string;
    public shipState?: string;
    public shipZip?: string;
    public shipCity?: string;
    public shipAddress3?: string;
    public shipAddress2?: string;
    public shipAddress?: string;
    public shipLastName?: string;
    public shipFirstName?: string;
    public shipCountry?: string;

    public billingState?: string;
    public billingZip?: string;
    public billingCity?: string;
    public billingAddress3?: string;
    public billingAddress2?: string;
    public billingAddress?: string;
    public billingLastName?: string;
    public billingFirstName?: string;
    public billingCountry?: string;

    public ccType?: string;
    public ccNumberLastFour?: string;
    public creditCard: any;
    public disclosure: any;
    public donate: boolean;
    public inRepSite: boolean;
    public saveCardInfo: boolean;
    public referrerMainPk: number;
    public shippingMethodsLoaded: boolean;
    public subscribe: boolean;
    public salesCampaignFk: number;
    public hideCart: boolean;
    public shippingMethod: ShippingMethodViewModel;
    public signatureRequired: boolean;
    public paymentMethod: any;
    public billingEqualToShipping: boolean = true;
    public isCreateAndPayOrder: boolean;
    public tid: string;
    public inAuthId: string;
    public payment: any;
    public subTotal: number;
    public discount: number;
    public shipping: number;
    public taxes: number;
    public productTax: number;
    public shippingTax: number;
    public taxLabel: string;
    public additionalTaxLabel: string; //#44233
    public additionalTax: number; //#44233
    public total: number;
    public usdTotal: number;
    public formattedSubTotal: string;
    public formattedTotal: string;
    public formattedDiscount: string;
    public formattedTaxes: string;
    public formattedProductTax: string;
    public formattedShippingTax: string;
    public formattedAdditionalTax: string; //#44233
    public formattedShipping: string;
    public formattedRetailPrice: string;
    public formattedWholesalePrice: string;
    public formattedBasePrice: string;
    public formattedUnitPrice: string;
    public formattedRetailTaxablePrice: string;
    public formattedRemainingBalance: string;
    public formattedSubtotalMinusDiscount: string;
    public formattedShippingPlusTax: string;
    public formattedOrderProcessingFees: string;
    public lastSubTotalValue?: number;
    public lastTotalValue?: number;
    public lastDiscountValue?: number;
    public processingFees: number;
    public validation: any;
    public abandonOrderActive: boolean;
    public orderItems: any[];
    public bonusToken?: number;
    public splitPayment?: boolean = false;
    public splitPaymentLists?: any[];
    public splitPaymentChecker?: any[];
    public lockPage?: boolean = false;
    public splitReset?: boolean = false;
    public splitRemainingBalance?: any;
    public paypalRedirect?: string;
    public paypalSplitBalance?: number;
    public discountApplied?: boolean = false;
    public status?: string;
    public ruCode: string;
    public phoneNumber?: string;
    public email?: string;
    public crossBorderCharge: boolean;
    public encOrderNumber?: string;
    public discountObject?: object = {
        discountCodeValid: false,
        discountPercent: 0,
        discountType: 0,
        maxDiscountAmount: 0,
        eventSystemTicketId: ''
    }
    public currencyCode?: string;
    public currencySymbol?: string;
    public isPrivacy?: boolean;
    public walletDiscount: number = 0;
    public currencyRate?: number;
    public noOfInstallments?: number;
    public payments = [];
    public items = [];
    public paymentStatus = {};
    public itemsToRemove = [];
    public paidStatus?: boolean;
    public paidStatusFk?: number;
    public isRetailSignup: boolean;
    public retailStarterKit: boolean;
    public eventTitle: string;
    public offerLoyaltyProgram: boolean;
    public deliveryDate?: Date;
    public deliverySlot?: string;
    public shippingInstructions?: string;
    public showFISCALink?: boolean;
    public showMicroEntrFISCALink?: boolean;
    public isVolumeBasedRSB?: boolean;
    public isImportOrder?: boolean;

    constructor(countryCode: string, mainOrderTypeFk: number) {
        this.countryCode = countryCode;
        this.mainOrderTypeFk = mainOrderTypeFk;
        this.subTotal = 0;
        this.discount = 0;
        this.shipping = 0;
        this.taxes = 0;
        this.total = 0;
        this.usdTotal = 0;
        this.isCreateAndPayOrder = false;
        this.orderItems = [];
        this.processingFees = 0;
        this.payments = [];
    }
}
