import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProductsLibModule } from '../products/products-lib.module';
import { RouterModule } from '@angular/router';
import { CmsModule } from '@jeunesse/angular';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CountrySelectorComponent } from './components/country/country-selector.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { CategoryOverlayService } from './overlays/category/category-overlay.service';
import { LoginOverlayService } from './overlays/login/login-overlay.service';
import { AuthenticationModule } from '@jeunesse/angular';
import { OverlayModule } from '@angular/cdk/overlay';

import { CategoryOverlayModule } from './overlays/category/category-overlay.module';
import { LoginOverlayModule } from './overlays/login/login-overlay.module';
import { MenuOverlayModule } from './overlays/menu/menu-overlay.module';

import { BackofficeMenuComponent } from './components/backoffice-menu/backoffice-menu.component';
import { MenuOverlayService } from './overlays/menu/menu-overlay.service';

import { CategoryOverlayComponent } from './overlays/category/category-overlay.component';
import { MenuOverlayComponent } from './overlays/menu/menu-overlay.component';
import { LoginOverlayComponent } from './overlays/login/login-overlay.component';
import { BackofficeMobileMenuComponent } from './components/backoffice-mobile-menu/backoffice-mobile-menu.component';
import { BackofficeMenuService } from './components/backoffice-mobile-menu/backoffice-mobile-menu.service';
import { SideMenuService } from './components/side-menu/side-menu.service';
import { ProductFilterComponent } from './components/product-filter/product-filter.component';
import { ProductSorterComponent } from './components/product-sorter/product-sorter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToolTipModule } from '../tool-tip/tool-tip.module';

@NgModule({
    imports: [CommonModule, ProductsLibModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatRadioModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatToolbarModule,
        MatInputModule,
        MatCardModule,
        MatMenuModule,
        MatDialogModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        RouterModule,
        OverlayModule,
        MatExpansionModule,
        FlexLayoutModule,
        CategoryOverlayModule,
        LoginOverlayModule,
        MenuOverlayModule,
        CmsModule,
        MatCheckboxModule,
        AuthenticationModule,
        MatTooltipModule,
        ToolTipModule
    ],
    exports: [TopMenuComponent, UserMenuComponent, SideMenuComponent, BackofficeMenuComponent, BackofficeMobileMenuComponent, ProductFilterComponent, ProductSorterComponent],
    declarations: [TopMenuComponent, UserMenuComponent, SideMenuComponent, CountrySelectorComponent, BackofficeMenuComponent, BackofficeMobileMenuComponent, ProductFilterComponent, ProductSorterComponent],
    providers: [CategoryOverlayService, LoginOverlayService, MenuOverlayService],
    entryComponents: [
        MenuOverlayComponent, CategoryOverlayComponent, LoginOverlayComponent, ProductFilterComponent, ProductSorterComponent
    ]
})
export class MenuLibModule {
    /**
 * This is required so the same provider is injected into the load on demand modules.
 *
 * More Details: http://angularfirst.com/the-ngmodule-forroot-convention/
 */
    public static forRoot(): ModuleWithProviders<MenuLibModule> {
        return {
            ngModule: MenuLibModule,
            providers: [
                BackofficeMenuService,
                SideMenuService,
                {provide: MAT_DIALOG_DATA, useValue: {}},
                {provide: MatDialogRef, useValue: {}}
            ]
        };
    }
}
