import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, IAppSettings, LocaleService, WebStorageService } from '@jeunesse/angular';
import { LoyaltyPointsModel, SmartDeliveryItemModel, SmartDeliveryService } from '@jeunesse/cart-lib';
import { MainCartService, MainCartStateService, ShoppingCart, ShoppingCartItem } from '@jeunesse/slide-cart';
import { Observable, Subject } from 'rxjs';
import { SubSink } from 'subsink';

import { SmartDeliveryModel } from '../../../../member/models/smart-delivery.model';
import { ProductSeoService, ProductTranslationService } from '../../../../products/services/public-api';
import { RepSiteInfo, RepSiteService } from '../../../../repsite/public-api';
import { DeepLinkModel, ProductModel, ProductPriceModel } from '../../../models/public-api';
import { CreateAPackDetailsComponent } from '../../create-a-pack/create-a-pack-details.component';
import { SimpleProductComponent } from '../../product/simple-product/simple-product.component';
import { Direction } from '@angular/cdk/bidi';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProductApiService } from '../../../services/product-api.service';

declare const AppSettings: IAppSettings;

@Component({
    selector: 'jn-category-product-item',
    templateUrl: './category-product-item.component.html',
    styleUrls: ['./category-product-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryProductItemComponent implements OnInit, OnDestroy {
    @Input() public addToCart$: Observable<boolean>;
    @Input() public product: ProductModel;
    @Input() public smartDeliveryInfo: SmartDeliveryModel;
    @Input() public loyaltyPointsInfo: LoyaltyPointsModel;
    @Input() public showAddToCart: boolean;
    @Input() public viewDetails: boolean;
    @Input() public viewType: string;
    @Input() public showDetailsInModal: boolean;
    @Input() public onShop: boolean;
    @Input() public onSmartDelivery: boolean = false;
    @Input() public isCreateAPac: boolean = false;
    @Input() public toggleCart: boolean = true;
    @Input() public priceListOverride: number;
    // don't delete, needed for trigger change detection
    @Input() public products: ProductModel[];
    @Output() public matchFound: EventEmitter<ProductModel> = new EventEmitter<ProductModel>();
    @Output() public alertInfo: EventEmitter<string> = new EventEmitter<string>();
    public qtyChange$ = new Subject<string>();
    public isLoggedIn: boolean;
    public isDistributor: boolean = false;
    public isAffiliate: boolean = false;
    public currencySymbol: string;
    public quantity: number;
    public price: string;
    public priceWarning: string;
    public smartDeliveryPrice: string;
    public showSDButton: boolean;
    public showInSDButton: boolean;
    public showButtonFiller: boolean;
    public isRedemptionMode: boolean;
    public translations: any = {};
    public isOnSmartDelivery: boolean;
    public canAddToCart: boolean = true;
    public cartItems: ShoppingCartItem[] = [];
    public itemDialogRef: MatDialogRef<any>;
    public cartType: string;
    public repSiteInfo: RepSiteInfo;
    public cart: ShoppingCart;
    public countryCode: string;
    public image: any = {};
    public productInfo: any = {};
    public isDescriptionOpen: boolean = false;
    public isActive: boolean = false;
    public pricePIW: number;
    public saveAmount: number;
    public profitAmount: number;
    public showHideDescDetails: boolean;
    public directionality: Direction = 'ltr';
    private isButtonImplicitlyDisabled: boolean = false;
    private nextSmartDeliveryDate: Date;
    private subs = new SubSink();

    constructor(
        protected activatedRoute: ActivatedRoute,
        private readonly localeService: LocaleService,
        private readonly authService: AuthService,
        private readonly smartDeliveryService: SmartDeliveryService,
        private readonly dialog: MatDialog,
        private readonly mainCartService: MainCartService,
        private readonly repSiteService: RepSiteService,
        private readonly router: Router,
        private readonly changeDetection: ChangeDetectorRef,
        private readonly mainCartStateService: MainCartStateService,
        private readonly webStorageService: WebStorageService,
        private readonly productTranslationService: ProductTranslationService,
        private readonly productSeoService: ProductSeoService,
        private readonly productApiService: ProductApiService
    ) {  }

    public ngOnInit(): void {
        this.countryCode = this.localeService.getCountryCode();
        let cartCountry: string = this.webStorageService.getCookie('Country').replace(/[^A-Z]/gi, '');
        cartCountry = cartCountry ? cartCountry : this.countryCode;
        this.quantity = 0;
        this.product.buttons = [];

        this.subs.sink = this.mainCartService.getMainCart$().subscribe((cart) => {
            this.cartType = cart.cartType;
            if (!this.onShop) {
                this.cartType = 'W';
            }
            this.cart = cart;
        });

        this.isLoggedIn = this.authService.isLoggedIn;
        this.isDistributor = [1, 3, 6, 13].includes(this.product.mainType) || this.cartType === 'W';
        this.isAffiliate = this.product.mainType === 21;

        this.subs.sink = this.productTranslationService.getTranslationsObservable$.subscribe((translations) => {
            this.translations = translations;
        });

        this.subs.sink = this.repSiteService.repInfoObservable$.subscribe((repSiteInfo: RepSiteInfo) => {
            this.repSiteInfo = repSiteInfo;
            this.getPricingDisplay();
        });

        if (this.product && this.product.productPK > 0) {
            this.getPricingDisplay();
            // Subscribe to SmartDeliveryProfile Info
            this.isRedemptionMode = this.mainCartService.isStoreInRedemptionMode;
            this.subs.sink = this.smartDeliveryService.getItems$().subscribe((items: SmartDeliveryItemModel[]) => {
                let alreadyInSd: boolean = false;

                if (items &&  this.product.configurations.length === 0) {
                    alreadyInSd = items.find((x) => x.productPK === this.product.autoshipProductPk)
                        ? true
                        : false;
                }
                if (
                    this.isLoggedIn &&
                    this.product.isProductAvailableOnAutoship &&
                    this.product.autoshipProductPk > 0 &&
                    this.product.offerSDOnShop
                ) {
                    this.showSDButton = !alreadyInSd;
                    this.showInSDButton = alreadyInSd;
                } else {
                    this.showSDButton = false;
                    this.showInSDButton = false;
                }

                this.getPricingDisplay();
                // Storing previous value of buttons array
                let buttonSmartDeliveryStatus: any = this.product.buttons[1];
                this.setButtons();
                // watching if buttons array get changed, if so, trigger changeDetection
                if (buttonSmartDeliveryStatus && this.product.buttons[1] && this.product.buttons[1].icon !== buttonSmartDeliveryStatus.icon) {
                    this.changeDetection.detectChanges();
                }

                // if (alreadyInSd && smartDeliveryModel.nextDeliveryDate) {
                //     this.nextSmartDeliveryDate = new Date(smartDeliveryModel.nextDeliveryDate);
                // }
            });
        }

        this.subs.sink = this.addToCart$?.subscribe((updateComplete: boolean) => {
            this.canAddToCart = updateComplete;
        });

        this.subs.sink = this.authService.tokenChanged.subscribe(() => {
            this.isLoggedIn = this.authService.isLoggedIn;
            if (this.isLoggedIn) {
                this.isActive = this.authService.getStatus() === 'A';
            }
        });

        if (!this.smartDeliveryInfo) {
            this.smartDeliveryService.loadSmartDeliveryInfo(false);
        }
        this.subs.sink = this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
        this.createDescription();
        this.qtyChange$.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe(value => {
                this.product.quantity = +value;
                this.checkIntValue(this.product);
            });
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public sendGAInfo(product: ProductModel, action: string): void {
        this.productSeoService.sendGAInfo(product, action);
    }

    public checkIntValue(product: ProductModel): void {
        product.quantity = this.mainCartService.checkIntValue(product);
        this.changeDetection.detectChanges();
        if (this.onSmartDelivery && this.product.isProductOnAutoship) {
            this.addToCart(true);
        }
    }

    public openDialog(product: ProductModel): void {
        const dialogConfig: MatDialogConfig<any> = {
            hasBackdrop: true,
            disableClose: true,
            direction: this.directionality,
            data: {
                product: Object.assign({}, product),
            },
        };
        this.itemDialogRef = this.dialog.open(CreateAPackDetailsComponent, dialogConfig);
        this.itemDialogRef.afterClosed().subscribe((data: ProductModel) => {
            if (data && data.quantity > 0) {
                this.matchFound.emit(data);
            }
        });
    }

    public openDropDownDescription(): void {
        this.showHideDescDetails = !this.showHideDescDetails;
        this.isDescriptionOpen = !this.isDescriptionOpen;
    }

    private _emitProduct(): void {
        this.product.quantity = this.product.quantity ? this.product.quantity : 1;
        this.matchFound.emit(this.product);
    }

    public setButtons(): void {
        this.product.buttons = [];
        if (!this.onSmartDelivery) {
            // Add to cart or redeem
            if (!this.product.isComingSoon && this.showAddToCart && !this.product.sdOnlyPackage) {
                this.product.buttons.push({
                    name: this.isRedemptionMode ? 'REDEEM' : 'ADD TO CART',
                    action: () => {
                        this.addToCart();
                    },
                    isDisabled: false,
                    icon: 'fa-cart-plus',
                    cmsTranslation: 'addToCart',
                    class: 'btn btn-addToCart',
                });
            }
            // Coming soon
            if (this.product.isComingSoon && !this.isRedemptionMode && !this.product.sdOnlyPackage) {
                this.product.buttons.push({
                    name: 'ADD TO CART',
                    action: () => {},
                    isDisabled: true,
                    icon: '',
                    cmsTranslation: 'addToCart',
                    class: 'btn btn-comingSoon',
                });
            }
            // add smartDelivery
            if (this.showSDButton && !this.product.isComingSoon && this.showAddToCart) {
                this.product.buttons.push({
                    name: 'SMARTDELIVERY',
                    action: () => {
                        this.addToCart(true);
                    },
                    isDisabled: false,
                    icon: 'fa-plus',
                    cmsTranslation: 'smartDelivery',
                    class: 'btn btn-addSmartDeliver',
                });
            }
            // In smartDelivery
            if (this.showInSDButton && !this.product.isComingSoon && this.showAddToCart) {
                this.product.buttons.push({
                    name: 'SMARTDELIVERY',
                    action: () => {
                        this.mainCartService.openSmartDeliveryConfirmationDialog({ nextSmartDeliveryDate: this.nextSmartDeliveryDate });
                    },
                    isDisabled: false,
                    icon: 'fa-check-circle-o',
                    cmsTranslation: 'smartDelivery',
                    class: 'btn btn-inSmartDeliver',
                });
                this.changeDetection.detectChanges();
            }
            // Add button for create-a-pack and prepaid
            if (!this.product.isComingSoon && !this.showAddToCart) {
                this.product.buttons.push({
                    name: 'ADD',
                    action: () => {
                        this._emitProduct();
                    },
                    isDisabled: false,
                    icon: 'fa-cart-plus',
                    cmsTranslation: 'addToCart',
                    class: 'btn btn-addToCart',
                });
            }
        } else {
            // add smartDelivery
            if (!this.product.quantity ||this.product.quantity < 1) {
                this.product.buttons.push({
                    name: 'SMARTDELIVERY',
                    action: () => {
                        this.addToCart(true);
                    },
                    isDisabled: false,
                    icon: 'fa-plus',
                    cmsTranslation: 'smartDelivery',
                    class: 'btn btn-addSmartDeliver',
                });
            }
            // In smartDelivery
            if (this.product.quantity > 0) {
                this.product.buttons.push({
                    name: 'SMARTDELIVERY',
                    action: () => {},
                    isDisabled: true,
                    icon: 'fa-check-circle-o',
                    cmsTranslation: 'smartDelivery',
                    class: 'btn btn-inSmartDeliver',
                });
                //this.changeDetection.detectChanges();
            }
        }
        this.product.buttons = this.product.buttons.slice();
    }

    private addToCart(addToSmartDelivery?: boolean): void {
        if (!addToSmartDelivery) {
            this.canAddToCart = false;
        }

        // KLK - Task: 46882
        if (this.product.quantity > this.product.maxLimit) {
            this.product.quantity = this.product.maxLimit;
        }

        if (this.priceListOverride) {
            this.product.priceListFk = this.priceListOverride;
        }

        this.mainCartService.getButtonSubs().sink = this.mainCartService.getButtonState$().subscribe((result) => {
            this.isButtonImplicitlyDisabled = result;
        });

        this.product.buttons = this.product.buttons.slice();

        if(!this.isButtonImplicitlyDisabled) {
            this.mainCartService.addToCart(this.product, this.smartDeliveryInfo, addToSmartDelivery, this.loyaltyPointsInfo, this.toggleCart, !this.onSmartDelivery);
        }
        this.mainCartService.setDisableButton();

        this.subs.sink = this.mainCartStateService.isProductAdded.pipe().subscribe(() => {
            if (this.product.productMenu === 'Signup Packages' && this.product.brandName !== 'Paycard') {
                let payCardItem: any = this.cart.cartItems.find((x) => x.brandName === 'Paycard');
                if (payCardItem) {
                    const dialogConfig: MatDialogConfig<any> = {
                        hasBackdrop: true,
                        disableClose: true,
                        direction: this.directionality,
                        data: {
                            product: Object.assign({}, payCardItem.product),
                        },
                    };
                    this.itemDialogRef = this.dialog.open(SimpleProductComponent, dialogConfig);
                    this.itemDialogRef.afterClosed().subscribe();
                }
            }

            this.changeDetection.detectChanges();
        });
    }

    public imageClick(product): void {
        this.sendGAInfo(product, 'click - view details');
        if (this.showDetailsInModal) {
            this.openDialog(product);
        } else {
            this.router.navigate([product.productPK], { relativeTo: this.activatedRoute });
        }
    }

    public createDescription(): void {
        this.product.additionalInfo.forEach((info) => {
            this.productInfo[info.name] = info.content;
        });
    }

    public shareLink(e): void { // KLK - Task: 46724
        e.stopPropagation();
        e.preventDefault();
        let products: ProductModel[] = [];
        products.push(this.product);
        this.subs.sink = this.productApiService.generateDeepLinkString({
            productFK: this.product.productPK,
            domain: `https://shop.${AppSettings.cookieDomain}`,
            siteUrl: this.authService.isLoggedIn ? this.authService.getSiteUrl() : 'jhqsocial',
            cartKey: this.cart?.cartKey || ''
        }).subscribe((resp: DeepLinkModel) => {
            if (navigator.clipboard && (resp && resp.link)) {
                navigator.clipboard.writeText(resp.link);
                this.alertInfo.next(this.translations.copied);
            } else {
                this.alertInfo.next(this.translations.couldNotCopy);
            }
        }, error => {
            this.alertInfo.next(this.translations.couldNotCopy);
        });

    }

    private getPricingDisplay(): void {
        this.currencySymbol = this.product.pricing[0].currencySymbol;
        this.priceWarning = this.product.pricing[0].priceWarning;
        const unformattedPrice: ProductPriceModel = this.product.pricing.find((x) => x.priceType === this.product.priceType);
        if (this.product.priceType === 'LR') {
            this.price = Math.round(this.product.pricing.find((x) => x.priceType === this.product.priceType).price).toString();
        } else if(!this.authService.isLoggedIn && this.product.isVolumeBasedRSB && this.product.showSDCheckbox) { // Task: 44286 - Wholesale Pricing
            // Update to show the lowest price to non-logged in users if they were to choose SD
            const wholeSalePrice: ProductPriceModel = this.product.pricing.find((x) => x.priceType === 'W');
            if (wholeSalePrice) {
                this.price = wholeSalePrice.formattedPrice;
            } else {
                this.price = unformattedPrice.formattedPrice;
            }
        } else {
            this.price = unformattedPrice.formattedPrice;
        }
        if (this.product.pricing.find((x) => x.priceType === 'W')) {
            this.smartDeliveryPrice = this.product.pricing.find((x) => x.priceType === 'W').formattedPrice;
        } else {
            this.smartDeliveryPrice = this.price;
        }

        this.calculateAdditionalAmounts();
    }

    private calculateAdditionalAmounts(): void {
        const price: ProductPriceModel = this.product.pricing
            ? this.product.pricing.find((p) => p.priceType === this.product.priceType)
            : null;
        const pricePIW: ProductPriceModel = this.product.pricing ? this.product.pricing.find((p) => p.priceType === 'PIW') : null;
        const pricePIR: ProductPriceModel = this.product.pricing ? this.product.pricing.find((p) => p.priceType === 'PIR') : null;
        if (pricePIW) {
            this.pricePIW = pricePIW.price;
            this.saveAmount = this.pricePIW - price.price;
        }

        if (pricePIR) {
            this.profitAmount = -(price.price - pricePIR.price);
        }
    }


}
