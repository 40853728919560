import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { delay, map, shareReplay } from 'rxjs/operators';

import { AuthHttpService } from '../../authentication/src/auth-http.service';
import { AuthService } from '../../authentication/src/auth.service';
import { UrlHelperService } from '../../common/src/url-helper.service';
import { WebStorageService } from '../../common/src/web-storage.service';
import { CountryCultureModel } from './models/country-culture.model';
import { Country } from './models/country.model';
import { Language } from './models/public-api';
import { Direction } from '@angular/cdk/bidi';

export enum LanguageActiveEnum {
  All = '',
  Active = 'active',
  CmsActive = 'cmsactive',
}

/**
 * Locale Service
 */
@Injectable({ providedIn: 'root' })
export class LocaleService {
  protected countries: Country[] = [];
  protected countriesSubject$: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>(this.countries);
  public countriesObservable$: Observable<Country[]> = this.countriesSubject$.asObservable();
  protected countryCodeSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public countryCodeObservable$: Observable<string> = this.countryCodeSubject$.asObservable();
  protected cultureSubject$: Subject<string> = new Subject<string>();
  public cultureObservable$: Observable<string> = this.cultureSubject$.asObservable();
  protected directionalitySubject$: BehaviorSubject<Direction> = new BehaviorSubject<Direction>('ltr');
  public directionalityObservable$: Observable<Direction> = this.directionalitySubject$.asObservable();
  private allowedCountries: string[] = [];

  private _languagesQuery$ = new Map<string, Observable<Language[]>>();
  private _cmsLanguages: Language[] = [];
  private _loadingCmsLanguages: boolean = false;
  private _cmsLanguages$: Observable<Language[]>;

  private readonly _defaultCountry: string = 'US';
  private readonly _defaultCulture: string = 'en-US';

  constructor(
    private readonly webStorage: WebStorageService,
    private readonly authService: AuthService,
    private readonly urlHelper: UrlHelperService,
    private readonly authHttp: AuthHttpService
  ) {
    this._loadCmsLanguages(this.getLocaleCookie());
  }

  public init(allowedCountries: string[]): void {
    const culture: string = this.getSetCultureCookieFromBrowser();
    this.allowedCountries = allowedCountries;
    this.loadCountries();
    this.getLanguages(culture);
    this.setBodyDirectionality(culture);
  }

  public getSetCultureCookieFromBrowser(): string {
    let culture: string = this.webStorage.getCookie('Culture') && JSON.parse(this.webStorage.getCookie('Culture'));
    if (culture) return culture;

    if (this.authService.isLoggedIn) {
      culture = this.authService.getCulture();
    }

    if (!culture) culture = window.navigator.languages ? window.navigator.languages[0] : window.navigator.language;
    this.setCultureCookie(culture);

    return culture;
  }

  /**
   * Returns the set country code value from the user or cookie.
   */
  public getCountryCode(): string {
    let country: string = this.webStorage.getCookie('Country').replace(/"/g, '');
    if (country) return country;

    if (this.authService.isLoggedIn) {
      return this.authService.getCountry();
    }

    country = this.getSetCultureCookieFromBrowser().split('-')[1];
    if (country) {
      this.setCountryCode(country);
      return country;
    }

    return this._defaultCountry;
  }

  /**
   * Set's country code value.
   */
  public setCountryCode(code: string, ignoreAllowedList: boolean = true): void {
    const supportedCountries: string[] = this.allowedCountries;
    const country: string =
      !ignoreAllowedList && !supportedCountries.find((country: string) => country === code) ? this._defaultCountry : code;
    this.webStorage.setCookie('Country', JSON.stringify(country));
    this.countryCodeSubject$.next(country);
  }

  /**
   * @returns {Observable<Language[]>}
   * @memberof LocaleService
   */

  public getLanguages(culture: string, active: LanguageActiveEnum = LanguageActiveEnum.All): Observable<Language[]> {
    const query = JSON.stringify({ culture, active });

    if (!this._languagesQuery$.get(query)) {
      this._languagesQuery$.set(
        query,
        this.authHttp
          .get<Language[]>(this.urlHelper.toCmsApi(`/v1/languages-by-input`), { params: { culture, active } })
          .pipe(shareReplay())
      );
    }

    return this._languagesQuery$.get(query);
  }

  private _loadCmsLanguages(culture: string): void {
    if (this._loadingCmsLanguages) return;

    const query: string = JSON.stringify({ culture, active: 'cmsactive' });

    if (!this._languagesQuery$.get(query)) {
      this._loadingCmsLanguages = true;
      this._languagesQuery$.set(
        query,
        (this._cmsLanguages$ = this.authHttp
          .get<Language[]>(this.urlHelper.toCmsApi(`/v1/languages-by-input`), {
            params: { culture, active: 'cmsactive' },
          })
          .pipe(
            map((data: Language[]) => {
              this._cmsLanguages = data.map((language: Language) => new Language(language));
              this._loadingCmsLanguages = false;
              return data.map((l) => new Language(l));
            }),
            shareReplay()
          ))
      );
    }
  }

  public getSupportedCultureForShop(culture: string): Observable<string> {
    if (this._cmsLanguages.length) {
      return of(
        this._cmsLanguages.find((l) => l.cultureName === culture)
          ? this._cmsLanguages.find((l) => l.cultureName === culture).cultureName
          : culture
      );
    } else {
      if (!this._loadingCmsLanguages) this._loadCmsLanguages(this.getLocaleCookie());

      return this._cmsLanguages$.pipe(
        delay(200),
        map((data: any) => {
          return data.find((l) => l.cultureName === culture) ? data.find((l) => l.cultureName === culture).cultureName : culture;
        })
      );
    }
  }

  public getLanguagesForGuest(): Observable<Language[]> {
    if (!this._cmsLanguages.length) return this._cmsLanguages$.pipe();
    return of(this._cmsLanguages);
  }

  /**
   * Checks if a culture is supported for the currently set country code.
   * @param culture Culture value to check if it's supported
   * @param country Country to check
   */
  public isCultureSupported(culture: string, country?: string): Observable<string> {
    country = country || this.getCountryCode();

    return this._cmsLanguages$.pipe(
      map((languages: Language[]) => {
        if (!languages.length) {
          return culture;
        }
        if (languages.find((language: Language) => language.cultureName === culture)) {
          return culture;
        } else {
          return this._defaultCulture;
        }
      })
    );
  }

  /**
   * Set's the culture value on the cookie.
   * @param str Culture value to set
   */
  public setCultureCookie(str: string): void {
    // KLK - Task: 45556
    // Set Bidirectional support based on language chosen
    this.setBodyDirectionality(str);
    this.cultureSubject$.next(str);
    this.webStorage.setCookie('Culture', JSON.stringify(str));
  }

  public getCultureObservable$(): Observable<string> {
    return this.cultureObservable$;
  }

  public getCountryObservable$(): Observable<string> {
    return this.countryCodeObservable$;
  }

  public setLangIDCookie(languagePk: number): void {
    if (!this.authService.isLoggedIn) throw new Error('Not Logged in!');

    this.webStorage.setCookie('LangId', JSON.stringify(languagePk));
  }

  public getLangId(): number {
    return JSON.parse(this.webStorage.getCookie('LangId'));
  }

  /**
   * Returns the culture value saved in the [Culture] cookie.
   */
  public getLocaleCookie(): string {
    return JSON.parse(this.webStorage.getCookie('Culture') || `"${this._defaultCulture}"`);
  }

  /**
   * Loads an array of back office countries and languages, filtered by allowed countries.
   */
  public loadCountries(refresh: boolean = false): void {
    if (this.countries.length && !refresh) {
      this.countriesSubject$.next(this.countries);
    } else {
      this.authHttp.get<Country[]>(this.urlHelper.toCmsApi('/v1/application/locale-info')).subscribe((countries: any) => {
        this.countries = this.filterCountriesBySupported(countries || []);
        this.countries = this.countries.map((item) => new Country(item));
        this.countriesSubject$.next(this.countries);
      });
    }
  }

  public getCountries(): Observable<Country[]> {
    return this.authHttp.get<Country[]>(this.urlHelper.toCmsApi('/v1/application/locale-info')).pipe(
      map((countries: Country[]) => {
        return countries.map((country) => new Country(country));
      })
    );
  }

  /**
   * Filters the countries list by those that are allowed for the application.
   * @param countries
   */
  public filterCountriesBySupported(countries: Country[]): Country[] {
    return countries.filter((country: Country) =>
      this.allowedCountries.find((allowedCountry: string) => allowedCountry.toLowerCase() === country.code2.toLowerCase())
    );
  }

  public getCountryMarkets(): Observable<CountryCultureModel[]> {
    return this.authHttp.get<CountryCultureModel[]>(this.urlHelper.toProfileApi(`/v1/localization/countries`));
  }

  public getAllCountries(): Observable<Country[]> {
    return this.authHttp.get<Country[]>(this.urlHelper.toCmsApi('/v1/application/countries-by-market')).pipe(
      map((data: Country[]) => {
        return data && data.map((country: Country) => new Country(country));
      })
    );
  }

  public getCountriesByInput(culture: string): Observable<{ key: string; value: string }[]> {
    return this.authHttp.get<any>(this.urlHelper.toCmsApi(`/v1/countries-by-input?culture=${culture}`));
  }

  // KLK - Task: 45556
  // Set Bidirectional support based on language chosen
  private setBodyDirectionality(culture: string): void {
    if (this._cmsLanguages && this._cmsLanguages.length) {
      const lang: Language = this._cmsLanguages.filter((x) => x.cultureName === culture)[0];
      const body: HTMLBodyElement = document.querySelector('body[dir]');
      if (body && body.dir) {
        if (lang && lang.isRTL) {
          body.dir = 'rtl';
          this.directionalitySubject$.next('rtl');
        } else {
          body.dir = 'ltr';
          this.directionalitySubject$.next('ltr');
        }
      }
    } else {
      setTimeout(() => {
        this.setBodyDirectionality(culture);
      }, 1000);
    }
  }
}
