import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MenuItem } from '../models/menu-item.model';
import { CategoryModel } from '../../products/models/category/category.model';
import { MenuAlertModel } from '../../shared/models/menuAlert.model';
/**
 * Menu state service used to keep state for menu items on the application that the components would subscribe too.
 */
@Injectable({
    providedIn: 'root',
})
export class MenuStateService {
    private userMenuState$ = new BehaviorSubject<MenuItem[]>([]);
    private backofficeMenuState$ = new BehaviorSubject<MenuItem[]>([]);
    private categoryMenuState$ = new BehaviorSubject<CategoryModel[]>([]);
    private openCloseMenuState$ = new BehaviorSubject<boolean[]>([false, false]);

    private hideMenuState$ = new BehaviorSubject<boolean>(false);
    private showEmptyMenuState$ = new BehaviorSubject<boolean>(false);
    private showBonusCreditsExpirationWarn$ = new BehaviorSubject<boolean>(false);

    private cartOpen: boolean = false;
    private cartOpenState$ = new BehaviorSubject<boolean>(this.cartOpen);

    private backOfficeMenuOpen: boolean = false;
    private backOfficeMenuOpenState$ = new BehaviorSubject<boolean>(this.backOfficeMenuOpen);

    private categoryMenuOpen: boolean = false;
    private categoryMenuOpenState$ = new BehaviorSubject<boolean>(this.categoryMenuOpen);

    private menuAlertState$: BehaviorSubject<MenuAlertModel[]> = new BehaviorSubject<MenuAlertModel[]>([]);

    private unreadCountMenuState$ = new BehaviorSubject<MenuAlertModel[]>([]);
    // Side Cart Toggle States
    public getCartOpenState$(): Observable<boolean> {
        return this.cartOpenState$.asObservable();
    }

    public toggleCartOpenState(): void {
        this.cartOpen = !this.cartOpen;
        this.cartOpenState$.next(this.cartOpen);
        if (this.categoryMenuOpen) {
            this.categoryMenuOpen = false;
            this.categoryMenuOpenState$.next(false);
        }
    }

    public showHideCartState(show: boolean): void {
        this.cartOpen = show;
        this.cartOpenState$.next(this.cartOpen);
        if (this.categoryMenuOpen) {
            this.categoryMenuOpen = false;
            this.categoryMenuOpenState$.next(false);
        }
    }

    // Back Office Toggle States
    public getBackOfficeMenuOpenState$(): Observable<boolean> {
        return this.backOfficeMenuOpenState$.asObservable();
    }

    public toggleBackOfficeOpenState(): void {
        this.backOfficeMenuOpen = !this.backOfficeMenuOpen;
        this.backOfficeMenuOpenState$.next(this.backOfficeMenuOpen);
        if (this.categoryMenuOpen) {
            this.categoryMenuOpen = false;
            this.categoryMenuOpenState$.next(false);
        }
        if (this.cartOpen) {
            this.cartOpen = false;
            this.cartOpenState$.next(false);
        }
    }

    // Category Menu Toggle States
    public getCategoryMenuOpenState$(): Observable<boolean> {
        return this.categoryMenuOpenState$.asObservable();
    }

    public toggleCategoryMenuOpenState(): void {
        this.categoryMenuOpen = !this.categoryMenuOpen;
        this.categoryMenuOpenState$.next(this.categoryMenuOpen);
        if (this.categoryMenuOpen) {
            this.backOfficeMenuOpen = false;
            this.backOfficeMenuOpenState$.next(false);

            this.cartOpen = false;
            this.cartOpenState$.next(false);
        }
    }

    public getOpenCloseMenu(): Observable<boolean[]> {
        return this.openCloseMenuState$.asObservable();
    }
    public getCategoryMenu(): Observable<CategoryModel[]> {
        return this.categoryMenuState$.asObservable();
    }

    public getUserMenu(): Observable<MenuItem[]> {
        return this.userMenuState$.asObservable();
    }

    public getBackOfficeMenu(): Observable<MenuItem[]> {
        return this.backofficeMenuState$.asObservable();
    }

    public addCategoryMenu(menuItems: CategoryModel[]): void {
        this.categoryMenuState$.next(menuItems);
    }

    public addUserMenu(menuItems: MenuItem[]): void {
        this.userMenuState$.next(menuItems);
    }

    public addBackOfficeMenu(menuItems: MenuItem[]): void {
        this.backofficeMenuState$.next(menuItems);
    }

    public setOpenCloseMenu(menu: boolean = false, slideCart: boolean = false): void {
        this.openCloseMenuState$.next([menu, slideCart]);
    }

    public getMenuAlert(): Observable<MenuAlertModel[]> {
        return this.menuAlertState$.asObservable();
    }

    public setMenuAlert(alerts: MenuAlertModel[]): void {
        this.menuAlertState$.next(alerts);
    }

    public getMenuUnreadCount(): Observable<MenuAlertModel[]> {
        return this.unreadCountMenuState$.asObservable();
    }

    public setMenuUnreadCount(unreadCounts: MenuAlertModel[]): void {
        this.unreadCountMenuState$.next(unreadCounts);
    }

    // for Safari compatibility. full screen sales map task 30800
    public setHideMenu(state: boolean): void {
        this.hideMenuState$.next(state);
    }
    public getHideMenu(): Observable<boolean> {
        return this.hideMenuState$;
    }
    public setShowEmptyMenu(state: boolean): void {
        this.showEmptyMenuState$.next(state);
        this.hideMenuState$.next(state);
    }
    public getShowEmptyMenu(): Observable<boolean> {
        return this.showEmptyMenuState$;
    }
    public setShowBonusCreditsExpirationWarn(state: boolean): void {
        this.showBonusCreditsExpirationWarn$.next(state);
    }
    public getShowBonusCreditsExpirationWarn(): Observable<boolean> {
        return this.showBonusCreditsExpirationWarn$.asObservable();
    }
}
