import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationResolver } from '@jeunesse/angular/cms';
import { CreateAPackComponent } from './create-a-pack/create-a-pack.component';
import { ProductDescriptionComponent } from './product-description/product-description.component';
import { CatalogComponent } from './catalog/catalog.compontent';
import { CategoryComponent } from './category/category.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';

const routes: Routes = [
    {
        path: '',
        component: CatalogComponent,
        children: [
            {
                path: '',
                redirectTo: 'products',
                pathMatch: 'full',
            },
            {
                path: 'products',
                component: CategoryComponent,
                data: { states: ['jfrontGlobal', 'shop', 'product-brand'] },
                resolve: { translations: TranslationResolver },
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            },

            {
                path: 'create-a-pack',
                component: CreateAPackComponent,
                data: { states: ['jfrontGlobal', 'shop', 'create-a-pack'] },
                resolve: { translations: TranslationResolver },
            },
            {
                path: 'products/:id',
                component: ProductDetailComponent,
                data: { states: ['Product Detail', 'jfrontGlobal', 'shop', 'nv'] },
                resolve: { translations: TranslationResolver },
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            },
        ],
        data: { states: ['shoppingCart', 'crm-card'] },
        resolve: { translations: TranslationResolver },
    },
    {
        path: 'product-description/:culture/:sku',
        component: ProductDescriptionComponent,
        data: { states: ['shop'] },
        resolve: { translations: TranslationResolver },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProductsRoutingModule {}
