import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CmsSessionService, LocaleService, TranslationService } from '@jeunesse/angular';


@Component({
    selector: 'jn-installments-dialog',
    templateUrl: './installments-dialog.component.html',
    styleUrls: ['./installments-dialog.component.scss']
})

export class InstallmentsDialogComponent implements OnInit  {
    private noOfInstallments: number;
    private minSumForInstallments: number;
    private amountToPay: number;
    private newPaymentAmount: number;

    public translations: any = {
        installmentHeader: '',
        installmentMessage: ''
    };
    public isLimitPayment: boolean;
    public showNewAmount: boolean;

    constructor(
        private readonly matDialogRef: MatDialogRef<InstallmentsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService,
    ) {}

    ngOnInit() {

        this.noOfInstallments = this.data.noOfInstallments;
        this.minSumForInstallments = this.data.minSumInstallment * this.noOfInstallments;
        if (this.data.minOrderTotal && this.data.minOrderTotal > 0) {
            this.minSumForInstallments = this.data.minOrderTotal
        }
        this.amountToPay = this.data.amountToPay;
        this.isLimitPayment = this.minSumForInstallments > this.amountToPay;
        this.showNewAmount = this.data.showNewAmount;
        this.newPaymentAmount = this.data.newPaymentAmount;
        this.getTranslations();
    }

    ngOnDestroy() { }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }
    private setTranslations(): void {
        let installmentMessageFormat: string  = this.translationService.translate('checkout', 'installmentMessage', 'You\'ve selected an installment plan with {0} payment(s). Continue?') || 'You\'ve selected an installment plan with {0} payment(s). Continue?';
        let installmentLimitPromptFormat: string = this.translationService.translate('checkout', 'installmentLimitPrompt',
            'ATTENTION: This installments number is not available for amount less than {0}. Your amount is {1}.')
            || 'ATTENTION: This installments number is not available for amount less than {0}. Your amount is {1}.';
        let installmentNewAmountMessageFormat: string = this.translationService.translate('checkout', 'ConfirmInstallmentWithFee', 'Your new total is {0} because you selected {1} payments. Continue?') || 'Your new total is {0} because you selected {1} payments. Continue?';
        this.translations.installmentHeader = this.translationService.translate('checkout', 'installmentsHeader', 'Confirmation') || 'Confirmation';
        this.translations.installmentLimitPrompt = this.translationService.stringFormat(installmentLimitPromptFormat, this.minSumForInstallments, this.amountToPay);
        this.translations.installmentMessage = this.translationService.stringFormat(installmentMessageFormat, this.noOfInstallments);
        this.translations.installmentNewAmountMessage = this.translationService.stringFormat(installmentNewAmountMessageFormat, this.newPaymentAmount, this.noOfInstallments);
    }

    public close(): void {
        this.matDialogRef.close();
    }
    public save() {
        this.matDialogRef.close(this.noOfInstallments);
    }

}