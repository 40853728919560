import { animate, group, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@jeunesse/angular';
import { CmsSessionService, LocaleService, TranslationService } from '@jeunesse/angular';
import { SettingsService, UrlHelperService } from '@jeunesse/angular';
import { MenuItemModel } from '@jeunesse/angular';
import { Subscription } from 'rxjs';
import { JetMenuItem } from '../../models/jet-menu-item.model';
import { MenuApiService } from '../../services/menu-api.service';
import { BackofficeMenuService } from './backoffice-mobile-menu.service';

@Component({
  selector: 'jn-backoffice-mobile-menu',
    templateUrl: './backoffice-mobile-menu.component.html',
    styleUrls: ['./backoffice-mobile-menu.component.scss'],
    animations: [
        trigger('transformMenu', [
            transition('void => *', [
                style({ height: '0', opacity: 0 }),
                group([
                    animate(300, style({ height: '*' })),
                    animate('400ms ease-in-out', style({ 'opacity': '1' }))
                ])
            ]),
            transition('* => void', [
                style({ height: '*', opacity: 1 }),
                group([
                    animate(300, style({ height: 0 })),
                    animate('200ms ease-in-out', style({ 'opacity': '0' }))
                ])
            ])])
    ]
})
export class BackofficeMobileMenuComponent implements OnInit, OnDestroy  {
    /**
     * The menu data.
     */
    public menuItems: JetMenuItem[] = [];

    /**
     * The first name of the logged in user with the text Hi with it.
     */
    public friendlyName: string;
    public homeUrl: string;
    public dashboardUrl: string;


    public activeChatExists: boolean;

    /**
     * @ignore
     */
    @ViewChild('libsidenav', { static: true }) public menuDrawer: MatDrawer;

    public translations: any = {};
    public mainType: number;
    private navigationSubscription: Subscription;

    constructor(
        public readonly media: MediaObserver,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly urlHelper: UrlHelperService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly translationService: TranslationService,
        private readonly localeService: LocaleService,
        private readonly menuApiService: MenuApiService,
        private readonly backofficeMenuService: BackofficeMenuService,
        private readonly settings: SettingsService
    ) {
        this.mainType = this.authService.getAuthToken().mainTypeId;
    }

    public ngOnInit(): void {

        // this.friendlyName = this.translationService.translate('missing', 'friendly-name', 'Hi, {FirstName}')
        //    .replace(/{FirstName}/gi, this.authService.getFirstName());
        this.friendlyName = this.authService.getFirstName();


        // const mainType: number = this.authService.getAuthToken().mainTypeId;
        this.mainType = this.authService.getAuthToken().mainTypeId;
        // Get Dashboard URL.
        switch (this.mainType) {
            case 3:
            case 4:
            case 6:
                this.dashboardUrl = this.urlHelper.buildUrl(this.settings.classicSiteUrl, '/members/admin_BO.asp');
                break;
            case 1:
                this.dashboardUrl = this.urlHelper.buildUrl(this.settings.classicSiteUrl, '/members/back_office.asp');
                break;
            default:
                this.dashboardUrl = this.settings.classicSiteUrl;
        }

        if ((this.mainType === 2 || this.mainType === 21) && this.authService.getCountry() === 'US' && this.authService.getLangId() === 1) {
            this.dashboardUrl = this.settings.jOffice;
        }

        // Set Home Page URL.
        this.homeUrl = this.dashboardUrl;

        // Listen for any data changes.
        // this.route.data.subscribe((data: { menuItems: MenuItemModel[], loyaltyPoints: LoyaltyPointsStatusModel }) => {
        //    if (this.mainType != 1 && this.mainType != 2) {
        //        this.menuItems = data.menuItems;
        //    }
        //    this.loyaltyPointsStatus = data.loyaltyPoints;
        //    this.getTranslations();
        // });
        if (this.mainType === 1 || this.mainType === 2) {
            this.menuApiService.getBackofficeMenu().subscribe(jetMenuItems => {
                let menu: any = jetMenuItems;
                this.menuItems = menu.menuItems;
                // console.log('this.menuItems');
                // console.log(this.menuItems);
            });
        }

        // Listen for any navigation changes.
        this.navigationSubscription = this.router.events.subscribe((e: Event) => {
            if (e instanceof NavigationEnd) {
                this.menuDrawer.close();
            }
        });

        this.backofficeMenuService.toggleEvent.subscribe(x => {
            // this.menuDrawer.open();
            this.toggleSideNav();
        });
    }

    public isType(): boolean {
        if (this.mainType === 1 || this.mainType === 2) {
            return true;
        }
        return false;
    }

    /**
     * Select and Toggle one of the side navs.
     * @param nav The side nav to toggle.
     */
    public toggleSideNav(): void {
        this.menuDrawer.toggle();

        // console.log('backoffice-mobile-menu.component:toggleSideNav');
        //// If menu is open we close first then open the new menu.
        // if (this.menuDrawer.opened) {
        //    this.menuDrawer.close().then(() => {
        //        this.menuDrawer.open();
        //    });
        //    return;
        // }
        //// If menu is not opened and different set and open menu.
        // this.menuDrawer.open();
    }

    public isOpen(): boolean {
        return this.menuDrawer.opened;
    }

    public ngOnDestroy(): void {
        this.navigationSubscription.unsubscribe();
    }

    public getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);

            if (this.menuItems) {
                this.menuItems.forEach((item: MenuItemModel) => {
                    let infoContent: string = this.translationService.translate('header', item.content, item.content);
                    this.translations[item.content] = infoContent;
                });
            }
        });
    }

    /**
     * Converts the URL to a route.
     * @param url The URL to convert.
     * @return Local path.
     */
    public convertLinkToRoute(url: string): string {
        const parseUrl: RegExp = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;

        const result: RegExpExecArray = parseUrl.exec(url);
        if (result == null) {
            return url;
        }
        if (result.length > 4) {
            return `/${result[5]}`;
        }
        else {
            return url;
        }
    }
    public toRoute(url: string): string {
        // console.log('toRoute');
        // console.log(url);
        return url;
    }

    public toExternalUrl(url: string): string {
        if (!this.hasHttp(url)) {
            if (url.indexOf('.') !== -1) {
                return this.settings.classicSiteUrl + url;
            }
            else {
                return this.settings.jOffice + url;
            }
        }
        else {
            return url;
        }
    }

    public isExternal(link: string): boolean {
        let linkParts: string[] = link.toString().split('?');
        let url: string = linkParts[0];
        if (url.indexOf('.') !== -1 || this.settings.siteName === 'Shop' || this.hasHttp(url)) {
            return true;
        }
        return false;
    }

    private hasHttp(url: string): boolean {
        if (url.indexOf('http:') > -1 || url.indexOf('https:') > -1 || url.indexOf('http/') > -1 || url.indexOf('https/') > -1) {
            return true;
        }
        return false;
    }
}
