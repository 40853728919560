export class TicketSummaryModel {
    public eventTitle: string;
    public priceDisplay: number;
    public qty: number;
    public total: string;
    public ticketTypeFK: number;
    public eventAgendaID: number;
    public isoCode: string;

    constructor(options: any) {
        this.eventTitle = options.title;
        this.priceDisplay = options.price;
        this.ticketTypeFK = options.ticketTypeFk;
        this.eventAgendaID = options.eventAgendaId;
        this.qty = 0;
        this.total = '0';
        this.isoCode = options.twoLetterISOCode;        
    }
}