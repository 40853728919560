export class ProductDescriptionModel {
    public productCode: string;
    public brandName: string;
    public productName: string;
    public description: string;
    public ingredients: string;
    public usage: string;
    public benefits: string;
    public imageUrl: string;
    public weight: number;
    constructor(options: any = {}) {
        this.productCode = options.productCode;
        this.brandName = options.brandName;
        this.productName = options.productName;
        this.description = options.description;
        this.ingredients = options.ingredients;
        this.usage = options.usage;
        this.benefits = options.benefits;
        this.imageUrl = options.imageUrl;
        this.weight = options.weight;
    }
}