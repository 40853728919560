import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { MenuItem, MenuLayoutType } from '../../models/menu-item.model';

import { MenuOverlayComponent } from './menu-overlay.component';
import { MenuOverlayConfig } from './menu-overlay-config.interface';
import { MenuOverlayRef } from './menu-overlay-ref';
import { MENU_OVERLAY_DATA } from './menu-overlay.tokens';


/** Default settings of overlay. */
const defaultConfig: MenuOverlayConfig = {
    hasBackdrop: true,
    panelClass: 'jn-menu-overlay',
    data: {
        title: ''
        ,navigationLink: ''
        ,isExternalLink: false
        , imageUrl: ''
        , subItemLayout: MenuLayoutType.Title
        , subItems: []
        , translationKey: ''
        , id: 0
        , inNewTab: false
    }
};

/** Default set of positions for the overlay. Follows the behavior of a dropdown. */
const defaultPositionList: ConnectedPosition[] = [
    {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
    },
    {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
    },
    {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom'
    },
    {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
    }
];


/**
 * Controls the overlay of the category brands.
 */
@Injectable()
export class MenuOverlayService {
    constructor(
        private readonly injector: Injector,
        private readonly overlay: Overlay) {
    }

    /**
     * Opens the overlay.
     * @param config Config options to override the defaults.
     */
    public open(config: MenuOverlayConfig = {}): MenuOverlayRef {
        const dialogConfig: MenuOverlayConfig = { ...defaultConfig, ...config };

        // Returns an OverlayRef which is a PortalHost
        const overlayRef: OverlayRef = this.createOverlay(dialogConfig);

        const overlayComponent: MenuOverlayComponent = this.attachDialogContainer(overlayRef, dialogConfig);

        // Instantiate remote control
        const menuOverlayRef: MenuOverlayRef = new MenuOverlayRef(overlayRef, overlayComponent);

        // Set remote to component
        overlayComponent.menuOverlayRef = menuOverlayRef;

        overlayRef.backdropClick().subscribe(_ => menuOverlayRef.close());

        return menuOverlayRef;
    }

    private createOverlay(config: MenuOverlayConfig): OverlayRef {
        // Returns an OverlayConfig
        const overlayConfig: OverlayConfig = this.getOverlayConfig(config);

        // Returns an OverlayRef
        return this.overlay.create(overlayConfig);
    }

    private getOverlayConfig(config: MenuOverlayConfig): OverlayConfig {
        let positionStrategy: PositionStrategy;

        if (config.flexibleConnectedTo) {
            positionStrategy = this.overlay.position()
                .flexibleConnectedTo(config.flexibleConnectedTo.origin.elementRef)
                .withPositions(config.flexibleConnectedTo.positions || defaultPositionList)
                .withFlexibleDimensions(config.flexibleConnectedTo.dimensions)
                .withPush(config.flexibleConnectedTo.push)
                .withGrowAfterOpen(config.flexibleConnectedTo.growAfterOpen)
                .withViewportMargin(config.flexibleConnectedTo.viewportMargin || 0)
                .withLockedPosition(config.flexibleConnectedTo.lockPosition);
        }
        else {
            positionStrategy = this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically();
        }

        const overlayConfig: OverlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }

    private createInjector(config: MenuOverlayConfig): PortalInjector {
        // Instantiate new WeakMap for our custom injection tokens
        const injectionTokens: WeakMap<object, any> = new WeakMap();

        // Set custom injection tokens
        injectionTokens.set(MENU_OVERLAY_DATA, config.data);

        // Instantiate new PortalInjector
        return new PortalInjector(this.injector, injectionTokens);
    }

    private attachDialogContainer(overlayRef: OverlayRef, config: MenuOverlayConfig): MenuOverlayComponent {
        const injector: PortalInjector = this.createInjector(config);

        const containerPortal: ComponentPortal<MenuOverlayComponent> = new ComponentPortal(MenuOverlayComponent, null, injector);
        const containerRef: ComponentRef<MenuOverlayComponent> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }
}
