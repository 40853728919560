import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import { CmsModule } from '@jeunesse/angular/cms';
import { SnackBarModule } from './snack-bar/snack-bar.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatDialogModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatInputModule,
        FlexLayoutModule,
        MatButtonModule,
        MatProgressBarModule,
        CmsModule,
        RouterModule,
        OverlayModule,
        SnackBarModule
    ],
    exports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, RouterModule],
    declarations: [],
    entryComponents: []
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
