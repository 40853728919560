import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { CustomsPaymentMessage, PaymentApiService } from "../../public-api";

@Component({
    selector: 'jn-customs-payment-warning',
    templateUrl: './customs-warning.component.html',
    styleUrls: ['./customs-warning.component.scss']
})
export class CustomsPaymentWarningComponent implements OnInit {
    @Input() public countryCode: string;
    @Input() public culture: string;
    public messages$: Observable<CustomsPaymentMessage[]>;
    constructor(private readonly paymentApiService: PaymentApiService)  {}

    public ngOnInit(): void {
        this.loadMessages();
    }

    public loadMessages(): void {
        this.messages$ = this.paymentApiService.getCustomsPaymentMessages({ countryCode: this.countryCode, culture: this.culture});
    }
}
