import { OnInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService, UrlHelperService } from '@jeunesse/angular';
import { SmartDeliveryConfirmationModel } from '../../../member/public-api';

@Component({
    selector: 'jn-sd-confirmation',
    templateUrl: './sd-confirmation.component.html',
    styleUrls: ['./sd-confirmation.component.scss']
})

export class SmartDeliveryConfirmationComponent implements OnInit {
    public success: boolean = true;
    public notAllowImportCart: boolean;
    constructor(private readonly dialogRef: MatDialogRef<SmartDeliveryConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SmartDeliveryConfirmationModel,
        private readonly urlHelperService: UrlHelperService,
        private readonly settings: SettingsService) {
            this.success = data.value;
            this.notAllowImportCart = data.notAllowImportCart;
        }

    public ngOnInit(): void {
    }

    /**
     * Closes the modal.
     */
    public close(): void {
        this.dialogRef.close();
    }

    /**
     * Takes the user to their smart delivery profile.
     */
    public manageSdProfile(): void {
        window.location.href = this.urlHelperService.buildUrl(this.settings.jOffice, '/smartdelivery');
    }
}
