import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { TranslationService, LocaleService, CmsSessionService } from '@jeunesse/angular';
import { SeoService } from '@jeunesse/angular';
import { ProductModel, EasyMatchFormModel } from '../../models/public-api';
import { EasyMatchService } from '../../services/easy-match.service';

@Component({
    selector: 'jn-shade-form',
    templateUrl: './shade-form.component.html',
    styleUrls: ['./shade-form.component.scss']
})
export class ShadeFormComponent implements OnInit {
    public form: FormGroup;
    public model: EasyMatchFormModel = new EasyMatchFormModel();
    public translations: any = {
        modalManufacturer: '',
        modalBrand: '',
        modalShade: '',
        modalEmail: ''
    };
    public product: ProductModel;
    constructor(
        public dialogRef: MatDialogRef<ShadeFormComponent>,
        private fb: FormBuilder,
        private easyMatchService: EasyMatchService,
        private translationService: TranslationService,
        private seoService: SeoService,
        private readonly localeService: LocaleService,
        private readonly cmsSessionService: CmsSessionService,
        @Inject(MAT_DIALOG_DATA) public data: ProductModel
    ) {
        this.product = data;
    }

    public ngOnInit(): void {
        // Setup Form
        this.form = this.fb.group({
            manufacturer: [this.model.manufacturer, [Validators.required]],
            brand: [this.model.brand, [Validators.required]],
            shade: [this.model.shade, [Validators.required]],
            email: [this.model.email, [Validators.required, Validators.email]]
        });

        this.form.get('manufacturer').valueChanges.subscribe(v => (this.model.manufacturer = v));
        this.form.get('brand').valueChanges.subscribe(v => (this.model.brand = v));
        this.form.get('shade').valueChanges.subscribe(v => (this.model.shade = v));
        this.form.get('email').valueChanges.subscribe(v => (this.model.email = v));

        this.getTranslations();
    }

    public submit(): void {
        if (!this.form.valid) {
            return;
        }

        this.seoService.trackClick(this.product.name, 'trouble finding shade - submit');
        // Submit Form
        this.easyMatchService.PostEasyMatchQuery(this.model).subscribe(res => {
            this.dialogRef.close();
        });
    }

    public cancel(): void {
        this.seoService.trackClick(this.product.name, 'trouble finding shade - cancel');
    }

    public editTranslation(name: string): boolean {
        let element: HTMLElement = document.getElementById('translationEdit');
        element.setAttribute('cmsTranslation', name);
        let ev: Event = document.createEvent('HTMLEvents');
        ev.initEvent('contextmenu', true, false);
        element.dispatchEvent(ev);
        return false;
    }

    public updateSelect(): void {
        let inputEl: HTMLElement = document.getElementById('translationEdit');
        let tName: string = inputEl.getAttribute('cmsTranslation');
        this.translations[tName] = inputEl.innerText;
        inputEl.setAttribute('cmsTranslation', null);
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.modalManufacturer = this.translationService.translate('nv', 'modalManufacturer', 'Manufacturer');
        this.translations.modalBrand = this.translationService.translate('nv', 'modalBrand', 'Brand');
        this.translations.modalShade = this.translationService.translate('nv', 'modalShade', 'Shade');
        this.translations.modalEmail = this.translationService.translate('nv', 'modalEmail', 'Email');
    }
}
