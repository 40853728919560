import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { AuthHttpService } from '../../../authentication';
import { UrlHelperService } from '../../../common/src';
import { DocumentsByCountryLanguage } from '../models';

@Injectable({
    providedIn: 'root'
})
export class AgreeToTermsService {
    constructor(private readonly urlHelper: UrlHelperService, private readonly authHttp: AuthHttpService) {}

    /**
     * Get country document lists
     *
     * @author Alex Togo
     * @date 07/12/2020
     * @param {string} country
     * @param {number} language
     * @param {string} usage
     * @param {string} subUsage
     * @param {number} [mainTypeFk=1]
     * @return {*}  {Observable<DocumentsByCountryLanguage[]>}
     * @memberof AccountService
     */
    public getDocumentsByCountryLanguage(
        country: string,
        language: number,
        usage: string,
        subUsage: string,
        mainTypeFk: number = 1
    ): Observable<DocumentsByCountryLanguage[]> {
        return this.authHttp
            .get<any>(
                this.urlHelper.toStorageApi(
                    `/v1/documents/docs-by-lang-country?country=${country}&languageFk=${language}&usage=${usage}&subUsage=${subUsage}&mainTypeFk=${mainTypeFk}`
                )
            )
            .pipe(
                map((data: any) => {
                    return data.map((data: any) => {
                        return new DocumentsByCountryLanguage(data);
                    });
                })
            );
    }

    /**
     * PI Call Distributor Agreement To DB if user NOT Login
     *
     * @author Alex Togo
     * @date 08/12/2020
     * @param {string} singUpKey
     * @param {string} usage
     * @param {string} subUsage
     * @param {number} [mainTypeFk=1]
     * @return {*}  {Observable<boolean>}
     * @memberof AgreeToTermsService
     */
    public logAgreedToDocuments(singUpKey: string, usage: string, subUsage: string, mainTypeFk: number = 1): Observable<boolean> {
        return this.authHttp
            .post<any>(
                this.urlHelper.toProfileApi(
                    `/v1/legal/log-agreements-by-guid?guid=${singUpKey}&usage=${usage}&subUsage=${subUsage}&mainTypeFk=${mainTypeFk}`
                )
            )
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }

    /**
     * API Call Distributor Agreement To DB if user isLogin
     *
     * @author Alex Togo
     * @date 08/12/2020
     * @param {string} from
     * @param {string} purpose
     * @param {number} mainTypeFk
     * @return {*}  {Observable<boolean>}
     * @memberof AgreeToTermsService
     */
    public sendDistributorAgreementToDB(from: string, purpose: string, mainTypeFk: number): Observable<boolean> {
        return this.authHttp
            .post<any>(this.urlHelper.toProfileApi(`/v1/legal/log-agreements-by-mainpk?from=${from}&purpose=${purpose}&mainTypeFk=${mainTypeFk}`))
            .pipe(
                map((res: boolean) => {
                    return res;
                })
            );
    }
}
