import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateText'
  })
  export class TruncateTextPipe implements PipeTransform {

    public transform(value: string, length: number): string {
        const biggestWord: number = 50;
        const ellipses: string = '...';
        if (typeof value === 'undefined') return value;
        if (value.length <= length) return value;
        // Truncate to about correct length
        let truncatedText: string = value.slice(0, length + biggestWord);
        // Cutting ends till correct length
        while (truncatedText.length > length - ellipses.length) {
            let lastSpace: number = truncatedText.lastIndexOf(' ');
            if (lastSpace === -1) break;
            truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?;:]$/, '');
        }
       return truncatedText + ellipses;
    }
}