import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SecurityService } from './security.service';
import { PolicyLevelValueEnum } from './models/public-api';


@Directive({ selector: '[jnSecurity]' })
export class SecurityDirective implements OnInit {
    @Input()
    public jnSecurity: string;

    constructor(
        private readonly el: ElementRef,
        private readonly securityService: SecurityService,
        private readonly route: ActivatedRoute) {}

    public ngOnInit(): void {
        const securityValue: PolicyLevelValueEnum =
            this.securityService.policy(this.jnSecurity, this.route.snapshot) as PolicyLevelValueEnum;

        switch (securityValue) {
            case PolicyLevelValueEnum.NONE:
                this.el.nativeElement.remove();
                break;
            case PolicyLevelValueEnum.READ:
                this.el.nativeElement.setAttribute('readonly', 'readonly');
                break;
            default:
                break;
        }
    }
}
