import { FieldConfigModel } from './field.model';

// export class EventMemberLayoutPrimaryModel {
//     public firstName: number;
//     public lastName: number;
//     public gender: number;
//     public siteURL: number;
//     constructor(obj: any) {
//         this.firstName = obj.firstName;
//         this.lastName = obj.lastName;
//         this.gender = obj.gender;
//         this.siteURL = obj.siteURL;
//     }
// }
// export class EventMemberLayoutAdditionalModel {
//     public email: number;
//     public jobRequired: number;
//     public addressLine1: number;
//     public addressLine2: number;
//     public cellPhone: number;
//     public city: number;
//     public company: number;
//     public fax: number;
//     public homePhone: number;
//     public prefixId: number;
//     public state_Province: number;
//     public zipCode: number;
//     public rank: number;
//     public specialNeeds: number;
//     public language: number;
//     public country: number;
//     public firstName2: number;
//     public lastName2: number;
//     public firstName3: number;
//     public lastName3: number;
//     public shirtSize: number;
//     public hasVisa: number;
//     public flightInfo: number;
//     public rubyUpline: number;
//     public shareRoomMember: number;
//     public mealPreferance: number;
//     public dob: number;
//     public passportExpDate: number;
//     public needsTransponder: number;
// }
export class EventMemberFormLayoutModel {
    public primaryFields: FieldConfigModel[];
    public additionalFields: FieldConfigModel[];
    constructor(obj: any = {}) {
        this.primaryFields = obj.primaryFields || [];
        this.additionalFields = obj.additionalFields || [];
    }
}
export class EventMemberLayoutAPIModel {
    public registrationMemberFields: EventMemberFormLayoutModel;
    public groupMemberFields: EventMemberFormLayoutModel;
    constructor (obj: any = {}) {
        this.registrationMemberFields = new EventMemberFormLayoutModel(obj.registrationMemberFields);
        this.groupMemberFields = new EventMemberFormLayoutModel(obj.groupMemberFields);
    }
}

