export class ExternalDynamicFieldsOptionsModel {
    public inputGroupName: string;
    public filterFieldName: string;
    public filterFieldValue: string | number;

    constructor(options: any = {}) {
        this.inputGroupName = options.inputGroupName;
        this.filterFieldName = options.filterFieldName;
        this.filterFieldValue = options.filterFieldValue;
    }
}
