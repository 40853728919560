import { Injectable } from'@angular/core';
import { LoggerInterface } from './models/logger.interface';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Class used for logging.
 */
@Injectable()
export class LoggerService {
    protected loggerSubject$: BehaviorSubject<LoggerInterface> = new BehaviorSubject<LoggerInterface>(null);
    public loggerObservable$: Observable<LoggerInterface> = this.loggerSubject$.asObservable();

    constructor() {

    }

    public log(loggerInterface: LoggerInterface): void {
        this.loggerSubject$.next(loggerInterface);

        // TODO: log to some type of persistant storage!
    }
}