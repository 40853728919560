export class MenuAlertModel {
    public pageName: string;
    public subpageName: string;
    public warningText: string;

    constructor(obj: any = {}) {
        this.pageName = obj.pageName,
        this.subpageName = obj.subpageName;
        this.warningText = obj.warningText;
    }
}