import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'jn-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent implements OnInit {
  @Input() public imgUrl: string;
  constructor() { }

  public ngOnInit(): void {
  }

}
