/**
 * Product Config Model
 */
export class ProductConfigModel {
    public packageFK: number;
    public configurableProductPK: number;
    public productPK: number;
    public productCode: string;
    public productName: string;
    public image: string;
    public attribute1: string;
    public attribute2: string;
    public attribute3: string;
    public description: string;
    public unit: number;
    public price: number;
    public seqNo: number;
    public isComingSoon: boolean;
    public comingSoonMessage: string;

    constructor(obj: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
