export interface ExportDataSourceButtonSettings {
    columnsToExport: string[];
    allowToExportAllPages?: boolean;
    disabled?: boolean;
}

export enum PagesToExportEnum {
    All = 'all',
    Current = 'current',
}
