export class ShippingCostModel {
    public shippingCost: number;
    public isSignatureRequired: boolean;
    public signatureFee: number;
    public totalShippingCost: number;
    public shippingCostDisplay: string;
    public totalShippingCostDisplay: string;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}