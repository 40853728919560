import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthHttpService } from "../../../authentication";
import { UrlHelperService } from "../../../common";
import { ESignatureViewModel, ESignaturePostModel } from "../interfaces/public-api";
import { SponsorPlacementQuery } from "../interfaces/sponsor-placement-query.interface";
import { SponsorPlacementView } from "../interfaces/sponsor-placement-view.interface";


@Injectable({
  providedIn: 'root'
})
export class MainUsersService {
  constructor(
    private readonly authHttp: AuthHttpService,
    private readonly urlHelper: UrlHelperService
  )
  {}

  /**
     * Returns the E-Signature info for the provided user
     * @param mainPk
     * @returns ESignatureViewModel
     */
  public getESignatureInfo(mainPk: number): Observable<ESignatureViewModel> {
    const options = { params: new HttpParams().set('mainPk', mainPk.toString()) };
    return this.authHttp.get<ESignatureViewModel>(this.urlHelper.toProfileApi('/v1/users/e-signature'), options).pipe();
  }

  /**
   * Sets the E-Signature info for a user
   * @param model ESignaturePostModel
   * @returns ESignatureViewModel
   */
  public setESignatureInfo(model: ESignaturePostModel): Observable<ESignatureViewModel> {
    return this.authHttp.post<ESignatureViewModel>(this.urlHelper.toProfileApi('/v1/users/e-signature'), model).pipe();
  }

  public getSponsorPlacementView(model: SponsorPlacementQuery): Observable<SponsorPlacementView> {
    let params = new HttpParams();
    params = params.append('mainFk', model.mainFk ? model.mainFk.toString() : '');
    params = params.append('languageFk', model.languageFk ? model.languageFk.toString() : '');
    return this.authHttp.get<SponsorPlacementView>(this.urlHelper.toProfileApi('/v1/users/sponsor-placement'), { params: params }).pipe();
  }
}
