export class SocialMediaModel {
    public skypeAccount: string;
    public twitterAccount: string;
    public facebookUrl: string;
    public facebookPixelId: string;
    public successOnDemand: boolean;
    public infoToShow: string;
    public lockPlacement: string;
    public googleAnalyticsUa: string;
    public retailAdWordsId: string;
    public retailAdWordsLabel: string;
    public wholesaleAdWordsId: string;
    public wholesaleAdWordsLabel: string;

    constructor(options: any = {}) {
        Object.assign(this, options);
    }
}