import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    Router,
    UrlTree
} from '@angular/router';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../../authentication/src/auth.service';
import { PolicyBoolValueEnum } from './models/public-api';
import { SecurityService } from './security.service';

/**
 * Check if the current user can access the website and the page.
 */
@Injectable()
export class ReportsSecurityGuard implements CanActivate, CanActivateChild {
    constructor(private readonly authService: AuthService,
        private readonly securityService: SecurityService,
        private readonly router: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const codePath: string = route.data['policy'];
        // We only redirect if they are not loggedin.
        const redirectLocation: string = !this.authService.isLoggedIn ? window.location.href : '';
        if (!this.authService.isLoggedIn) {
            console.warn('Guard Logged In or Claim Check Failed');
            this.authService.requiredLogin(redirectLocation, route.queryParams);
            return of(false);
        }

        if (!codePath) {
            console.error('SecurityGuard was used without a policy defined.');
            return of(false);
        }

        return this.securityService.loadPolicies(codePath).pipe(
            map(() => {
                const allowed: boolean = (this.securityService.policy(codePath) === PolicyBoolValueEnum.YES);
                if (!allowed) {
                    if (this.authService.isReportsLoggedIn) {
                        return true;
                    }
                    console.warn(`Guard Policy Check Failed: ${codePath}`);
                    const returnUrl: string = state.url || '/reports/genealogy';
                    return this.router.parseUrl(`/reports/login?returnUrl=${returnUrl}`);
                }
                return true;
            }),
            catchError(() => {
                this.authService.requiredLogin(redirectLocation);
                return of(false);
            }));
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.canActivate(route, state);
    }
}
