import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@jeunesse/angular';
import { MainCartService } from '@jeunesse/slide-cart';
import { SubSink } from 'subsink';
import { CategoryModel } from '../../../products/models/category/category.model';
import { ProductModel } from '../../../products/models/product/product.model';
import { MenuService } from '../../services/menu.service';
import { ANIMATIONS } from '../animations';
import { SideMenuService } from './side-menu.service';

@Component({
    selector: 'jn-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    animations: [ANIMATIONS.transformMenu],
    encapsulation: ViewEncapsulation.None
})

export class SideMenuComponent implements OnInit, OnDestroy {
    @Input() public siteUrl = 'jhqsocial';

    public items: ProductModel[];
    public categoryItems: CategoryModel[] = [];
    public isInRedemptionMode: boolean;
    public isCreateAPac: boolean;
    public menuItems$: Observable<CategoryModel[]>;
    public menuItems: CategoryModel[];
    public subMenus: any = {};

    private subs = new SubSink();

    constructor(
        public readonly media: MediaObserver,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly mainCartService: MainCartService,
        private readonly menuService: MenuService,
        private readonly sideMenuService: SideMenuService
    ) {
        this.items = this.route.snapshot.data.categories;
        this.isInRedemptionMode = this.mainCartService.isStoreInRedemptionMode;
    }

    public ngOnInit(): void {
        this.authService.tokenChanged.subscribe(loggedIn => {
            this.init();
        });
        this.init();
        this.subs.sink = this.mainCartService.getMainCart$().subscribe((cart) => {
            this.isInRedemptionMode = this.mainCartService.isStoreInRedemptionMode;
        });
    }
    public createSubMenuArray(): void {
        this.menuItems.forEach(menu => {
            this.subMenus[menu.id] = this.isCreateAPac ? ['53'] : [];
            menu.menus.forEach(subMenu => {
                if (subMenu.id !== 53) {
                    this.subMenus[menu.id].push(subMenu.id.toString());
                }
            });
        });
    }

    private init(): void {
        this.menuItems$ = this.menuService.getCategoryMenu();
        // Listen for any navigation changes.
        this.subs.sink = this.router.events.subscribe((e: Event) => {
            if (e instanceof NavigationEnd) {
                this.isCreateAPac = false;
                let path: string = e.url.split('?')[0];
                if (path.includes('/catalog/create-a-pack')) {
                    this.isCreateAPac = true;
                }
                this.menuItems$.subscribe(menus => {
                    if (menus.length > 0) {
                        this.menuItems = menus;
                        this.createSubMenuArray();
                    }
                });
            }
        });

        this.subs.sink = this.menuService.getCategoryMenu().subscribe(cats => {
            this.categoryItems = cats.sort((a, b) => {
                return b.seqNo + a.seqNo;
            });
        });
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public exitRedeemShop(): void {
        window.sessionStorage.clear();
        this.mainCartService.clearCart();
        location.reload();
    }
}
