import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { SettingsService } from '../../common/src/settings.service';

import { AuthService } from '../../authentication/src/auth.service';

import { SecurityService } from './security.service';
import { MenuItemModel } from './models/public-api';
import { WebStorageService } from '../../common/src/web-storage.service';

/**
 * Returns menu items from the Security API. Will return an empty array if not logged in.
 */
@Injectable()
export class MenuResolver implements Resolve<MenuItemModel[]> {
    constructor(
        private readonly authService: AuthService,
        private readonly securityService: SecurityService,
        private readonly storageService: WebStorageService,
        private readonly settings: SettingsService) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MenuItemModel[]> {
        if (!this.authService.isLoggedIn) {
            return of([]);
        }
        if ([1, 2, 9, 13, 21, 22].includes(this.authService.getMainTypeId())) {
            return of([]);
        }
        let menu: string = this.storageService.getSession(this.settings.claim, false);
        if (menu) {
            if (menu.indexOf(',"version":') > -1) {
                menu = null;
            }
        }
        // Sidorov. When user 3,6 in shop, pulling backOffice menu regarding of AppSettings
        if ([3, 6].includes(this.authService.getMainTypeId()) && encodeURI(this.settings.claim) === 'SHOP') {
            return this.securityService.getMenu('JOFFICE').pipe(
                map((menu: MenuItemModel[]) => {
                    this.storageService.setSession('JOFFICE', JSON.stringify(menu), false);
                    return menu;
                })
            );
        }

        if (menu) {
            return of(JSON.parse(menu) as MenuItemModel[]);
        } else {
            return this.securityService.getMenu().pipe(
                map((menu: MenuItemModel[]) => {
                    this.storageService.setSession(this.settings.claim, JSON.stringify(menu), false);
                    return menu;
                })
            );
        }
    }
}
