export class PaymentErrorModel {
    message: string;
    state: string;
    name: string;
    hasTranslation: boolean;

    constructor(options: any){
        this.message = options.message;
        this.state = options.state;
        this.name = options.name;
        this.hasTranslation = options.hasTranslation;
    }
}