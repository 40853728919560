import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlHelperService } from '@jeunesse/angular';
import { Observable } from 'rxjs';

import { EasyMatchFormModel } from '../models/easymatch/public-api';

@Injectable({
  providedIn: 'root'
})
export class EasyMatchService {

  constructor(private http: HttpClient,
    private urlHelperService: UrlHelperService) { }

    /**
     * Returns an array of easy match manufacturers.
     * @returns Observable
     */
    public getManufacturers(): Observable<any> {
        return this.http.get<any>(this.urlHelperService.toEasyMatchApi('/api/Manufacturers/ManufacturersWithBrands')).pipe();
    }
    /**
     * Returns a list of shades by brand.
     * @param  brand
     * @returns Observable
     */
    public getShades(brand: number): Observable<any> {
        return this.http.get<any>(this.urlHelperService.toEasyMatchApi('/api/Brands/BrandShades?brandId=' + brand)).pipe();
    }
    /**
     * Returns a match object.
     * @param  manufacturer
     * @param  brand
     * @param  shade
     * @returns Observable
     */
    public getMatch(manufacturer: number, brand: number, shade: number): Observable<any> {
        return this.http.get<any>(this.urlHelperService.toEasyMatchApi('/api/Matches/ERAMatches?manufacturerID=' + manufacturer + '&brandId=' + brand + '&shadeID=' + shade)).pipe();
    }
    /**
     * Sends request to support for possible match.
     * @param  model
     * @returns Observable
     */
    public PostEasyMatchQuery(model: EasyMatchFormModel): Observable<any> {
        return this.http.post<any>(this.urlHelperService.toCartApi('/v1/product/nv/shade-request'), model).pipe();
    }
}
