import { Component, OnInit, OnDestroy, ViewChild, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { MenuService } from '../../services/menu.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CategoryOverlayRef } from '../../overlays/category/category-overlay';
import { CategoryOverlayService } from '../../overlays/category/category-overlay.service';
import { CategoryModel } from '../../../products/models/category/category.model';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { SeoService, CmsSessionService } from '@jeunesse/angular';
import { SubSink } from 'subsink';
import { SubMenuInterface } from '../../models/sub-menu.interface';

@Component({
    selector: 'jn-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss']
})

export class TopMenuComponent implements OnInit, OnDestroy {
    @ViewChild('menuOverlayOrigin0') public menuOrigin0 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin1') public menuOrigin1 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin2') public menuOrigin2 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin3') public menuOrigin3 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin4') public menuOrigin4 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin5') public menuOrigin5 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin6') public menuOrigin6 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin7') public menuOrigin7 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin8') public menuOrigin8 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin9') public menuOrigin9 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin10') public menuOrigin10 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin11') public menuOrigin11 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin12') public menuOrigin12 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin13') public menuOrigin13 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin14') public menuOrigin14 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin15') public menuOrigin15 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin16') public menuOrigin16 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin17') public menuOrigin17 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin18') public menuOrigin18 !: CdkOverlayOrigin;
    @ViewChild('menuOverlayOrigin19') public menuOrigin19 !: CdkOverlayOrigin;

    @Input() public siteUrl: string;

    public selectedMenuNode: CategoryModel | null;
    public menuItems$: Observable<CategoryModel[]>;
    public menuItems: CategoryModel[];
    public categoryOverlay: CategoryOverlayRef;
    public catTimer: any;
    public subMenus: SubMenuInterface = {};
    public menuIdArray: string [];
    public isCreateAPac: boolean;

    private subs = new SubSink();

    constructor(
        private readonly menuService: MenuService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly route: ActivatedRoute,
        private readonly categoryOverlayService: CategoryOverlayService,
        private readonly seoService: SeoService,
        private readonly router: Router) { }

    public ngOnInit(): void {
        // Here we just subscribe to the state object that way any changes are pushed via the behavior subject.
        this.menuItems$ = this.menuService.getCategoryMenu();

        this.menuItems$.subscribe(menus => {
            if (menus.length > 0) {
                this.menuItems = menus;
                this.createSubMenuArray();
            }
        });

        this.subs.sink = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isCreateAPac = false;
                let path: string = event.url.split('?')[0];
                if (path.includes('/catalog/create-a-pack')) {
                    this.isCreateAPac = true;
                }
            }
        });
    }
    public createSubMenuArray(): void {
        this.menuItems.forEach(menu => {
            this.subMenus[menu.id] = this.isCreateAPac ? ['53'] : ['empty'];
            menu.menus.forEach(subMenu => {
                if (subMenu.id !== 53) {
                    this.subMenus[menu.id].push(subMenu.id.toString());
                }
            });
        });
    }
    public ngOnDestroy(): void {
        if (this.categoryOverlay) {
            this.categoryOverlay.close();
        }
        this.subs.unsubscribe();
    }

    /**
     * Called when the category menu button entered by mouse.
     */
    public onCategoryMouseEnter(category: CategoryModel, index: number): Subscription {
        // Add a small delay so overlay doesn't get in the way for moving across link.
        this.sendGAInfo('main menu', 'select category', this.router.url + ' - ' + category.name);
        return timer(180).subscribe(_ => {
            this.openCategoryOverlay(category, index);
        });
    }
    /**
     * Opens the category overlay for displaying a list of brands.
     * @param category The category of pull the brands.
     */
    public openCategoryOverlay(category: CategoryModel, index: number): void {
        if (this.categoryOverlay) {
            // Do not reopen overlay if the current overlay is open.
            if (this.categoryOverlay.componentInstance.data === category) return;

            // Close current overlay if opened.
            this.categoryOverlay.close();
        }
        if (!this.hasItems(category))
            return;

        const overlay: CategoryOverlayRef = this.categoryOverlayService.open({
            hasBackdrop: false,
            data: category,
            flexibleConnectedTo: {
                origin: this.getOrigin(index),
                positions: [{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }]
            }
        });

        this.categoryOverlay = overlay;

        // Make sure we clear current selected overlay on close.
        const close: Subscription = overlay.beforeClose().subscribe(() => {
            if (this.categoryOverlay === overlay) {
                this.categoryOverlay = null;
            }
            close.unsubscribe();
        });
    }

    /**
     * Called when the category menu button leaves by mouse.
     */
    public onCategoryMouseLeave(event: MouseEvent, timer: Subscription): void {
        if (timer) {
            timer.unsubscribe();
        }
        if (this.shouldCloseCategoryOverlay(event)) {
            this.closeCategoryOverlay();
        }
    }

    public sendGAInfo(category: string, action: string, label?: string): void {
        if (label === undefined) {
            label = this.router.url;
        }
        this.seoService.trackClick(label, action, category);
    }

    public selectNode(node: CategoryModel): void {
        this.selectedMenuNode = node;
    }

    public hasItems(menuItem: CategoryModel): boolean {
        try {
            if (menuItem.menus) {
                if (menuItem.menus.length > 0) {
                    return true;
                }
            }
        }
        catch (e) {
        }
        return false;
    }

    public closeCategoryOverlay(): void {
        if (this.categoryOverlay && !this.cmsSessionService.isTranslator()) {
            timer(180).subscribe(() => {
                this.categoryOverlay && this.categoryOverlay.close();
            });
        }
    }

    /**
     * Listen on click events on a document level.
     * There is a problem with chrome not updating the render on
     * document level clicks, so we use body instead.
     * @param event The event for the mouse press.
     */
    @HostListener('body:click', ['$event'])
    public handleKeydown(event: MouseEvent): void {
        this.closeCategoryOverlay();
    }

    private getOrigin(index): CdkOverlayOrigin {
        return eval('this.menuOrigin' + index);
    }

    private shouldCloseCategoryOverlay(event: MouseEvent): boolean {
        if (event && event.relatedTarget) {
            const relatedTargetClassList = Array.from((event.relatedTarget as Element).classList);
            const keepShowing = relatedTargetClassList.indexOf('jn-category-overlay') > -1 || relatedTargetClassList.indexOf('category-overlay') > -1;

            return !keepShowing;
        }

        return false;
    }
}
