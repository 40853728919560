import { SeoService } from '@jeunesse/angular';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { EasyMatchService } from '../../services/easy-match.service';
import { ProductModel } from '../../models/product/product.model';
import { EasyMatchModel } from '../../models/easymatch/easy-match.model';
import { TranslationService, LocaleService, CmsSessionService } from '@jeunesse/angular';
import { ShadeFormComponent } from './shade-form.component';
import { Direction } from '@angular/cdk/bidi';

@Component({
    selector: 'jn-shade-match',
    templateUrl: './shade-match.component.html',
    styleUrls: ['./shade-match.component.scss']
})
export class ShadeMatchComponent implements OnInit {
    public easyMatchManufacturerList = [];
    public easyMatchBrandList = [];
    public easyMatchShadeList = [];
    public showForm: boolean;
    public translations: any = {
        manufacturerPlaceHolder: '',
        brandPlaceHolder: '',
        shadePlaceHolder: ''
    };
    @Input() public product: ProductModel;
    @Output() public matchFound: EventEmitter<string> = new EventEmitter<string>();
    private easyMatchModel: EasyMatchModel = new EasyMatchModel();
    private directionality: Direction = 'ltr';
    constructor(private readonly easyMatchService: EasyMatchService,
        private dialog: MatDialog,
        private translationService: TranslationService,
        private readonly localeService: LocaleService,
        private readonly cmsSessionService: CmsSessionService,
        private seoService: SeoService) { }

    public ngOnInit(): void {
        // Load Easy Match Info
        this.easyMatchService.getManufacturers().subscribe(res => {
            this.easyMatchManufacturerList = res.data;
        });
        this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
        this.getTranslations();
    }

    public manufacturerChange(id: number): void {
        this.easyMatchModel.manufacturer = id;
        this.easyMatchBrandList = [];
        this.easyMatchShadeList = [];
        const item: any = this.easyMatchManufacturerList.filter(x => Number(x.id) === Number(id))[0];
        if (item) {
            this.easyMatchBrandList = item.brands;
            this.easyMatchBrandList.unshift({ id: -1, brand: 'Please Choose' });
            this.seoService.trackClick(item.manufacturer, 'select - manufacturer');
        }
    }

    public brandChange(id: number): void {
        this.easyMatchModel.brand = id;
        this.easyMatchShadeList = [];
        if (this.easyMatchModel.brand !== -1) {
            this.easyMatchService.getShades(Number(this.easyMatchModel.brand)).subscribe(res => {
                this.easyMatchShadeList = res.data;
                this.easyMatchShadeList.unshift({
                    id: -1,
                    shade_name: 'Please Choose'
                });
            });
            const item: any = this.easyMatchBrandList.filter(x => Number(x.id) === Number(id))[0];
            this.seoService.trackClick(item.brand, 'select - brand');
        }
    }

    public shadeChange(id: number): void {
        this.easyMatchModel.shade = id;
        if (this.easyMatchModel.shade !== -1) {
            this.easyMatchService.getMatch(Number(this.easyMatchModel.manufacturer), Number(this.easyMatchModel.brand), Number(this.easyMatchModel.shade)).subscribe(res => {
                const result: any = res.data[0].match_name1;
                if (result) {
                    this.matchFound.emit(result);
                }
            });

            const item: any = this.easyMatchShadeList.filter(x => Number(x.id) === Number(id))[0];
            this.seoService.trackClick(item.shade_name, 'select - shade');
        }
    }

    public showMatchForm(): void {
        this.seoService.trackClick(this.product.name, 'trouble finding shade');
        const dialogRef: MatDialogRef<ShadeFormComponent, any> = this.dialog.open(ShadeFormComponent, {
            data: this.product,
            direction: this.directionality
        });

        dialogRef.afterClosed().subscribe(res => { });
    }

    public editTranslation(name: string): boolean {
        let element: HTMLElement = document.getElementById('translationEdit');
        element.setAttribute('cmsTranslation', name);
        let ev: Event = document.createEvent('HTMLEvents');
        ev.initEvent('contextmenu', true, false);
        element.dispatchEvent(ev);
        return false;
    }

    public updateSelect(): void {
        let inputEl: HTMLElement = document.getElementById('translationEdit');
        let tName: string = inputEl.getAttribute('cmsTranslation');
        this.translations[tName] = inputEl.innerText;
        inputEl.setAttribute('cmsTranslation', null);
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.manufacturerPlaceHolder = this.translationService.translate('nv', 'manufacturerPlaceHolder', 'Manufacturer');
        this.translations.brandPlaceHolder = this.translationService.translate('nv', 'brandPlaceHolder', 'Brand');
        this.translations.shadePlaceHolder = this.translationService.translate('nv', 'shadePlaceHolder', 'Shade');
    }
}
