import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { Subscription } from 'rxjs';
import { ListItem, ListSelectorItem } from './models/public-api';
import * as ListSelectorAccessor from './models/list-selector-accessor';

/**
 * Form component for selecting a list of items.
 *
 * There is no support for trees as of yet.
 */
@Component({
    selector: 'jn-list-selector',
    templateUrl: 'list-selector.component.html',
    styleUrls: ['list-selector.component.scss'],
    host: {
        'class': 'jn-list-selector'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ListSelectorAccessor.LIST_SELECTOR_VALUE_ACCESSOR]
})
export class ListSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
    /**
     * Ref to the mat-selection-list All component.
     */
    @ViewChild('listAllSelector', { static: true }) public listAllSelectorRef !: MatSelectionList;
    @ViewChild('listSelector', { static: true }) public listSelectorRef !: MatSelectionList;

    /**
     * @ignore
     */
    public selectorList: ListItem[] = [];

    /**
     * @ignore
     */
    public selectAllText: string = 'All';

    /**
     * @ignore
     */
    public indentationBase: number = 16;

    /**
     * Is the data is currently being filtered.
     */
    public isFiltering: boolean = false;
    /**
     * Is the "All" option checked.
     */
    public isAll: boolean = false;


    /**
     * The text to display for selecting/deselecting all items.
     */
    @Input()
    public set allText(text: string) {
        this.selectAllText = text;
    }

    /**
     * The list of items that can be selected.
     * The input ngModel should be used for setting and getting the currently
     * selected items.
     */
    @Input()
    public set list(list: ListSelectorItem[]) {
        this.selectorList = (list && this.buildList(list)) || [];

        // Set values
        if (this.tempValue) {
            this.innerValue = this.tempValue;
            this.tempValue = null;
        }

        if (this._isMultiple) {
            this.setAllState();
        }

        this.repositionSelected();

        this.ref.markForCheck();
    }

    /**
     * Filters the list of items. Passing blank or null will reset the list.
     */
    @Input()
    public set filter(filter: string | null) {
        this.isFiltering = !!filter;
        this.filterByText(filter);
    }

    /**
     * Sets the type of selection, multiple or single selection.
     * The default is 'true'.
     */
    @Input()
    public set multiple(isMultiple: boolean) {
        this._isMultiple = coerceBooleanProperty(isMultiple);
    }

    public get multiple(): boolean {
        return this._isMultiple;
    }



    /**
     * Event emitted when the selection changes.
     */
    @Output()
    public readonly change: EventEmitter<void> = new EventEmitter<void>();

    private _isMultiple: boolean = true;
    private listAllChangeSubscription: Subscription = Subscription.EMPTY;
    private listChangeSubscription: Subscription = Subscription.EMPTY;

    private innerValue: string[];
    /**
     * Used for storing the values that were assigned before the options were initialized.
     */
    private tempValue: string[] | null;

    private changed: (value: string[]) => void;
    private touched: () => void;

    constructor(private readonly ref: ChangeDetectorRef) { }

    public ngOnInit(): void {
        // Listen for any selection changes.
        this.listAllChangeSubscription =
            this.listAllSelectorRef.selectionChange.subscribe((data: MatSelectionListChange) => {
                if (this._isMultiple) {
                    // Check if the All option was selected.
                    if (data.option.selected) {
                        this.listSelectorRef.selectAll();
                    }
                    else {
                        this.listSelectorRef.deselectAll();
                    }
                }

                this.touch();
            });

        // Listen for any selection changes.
        this.listChangeSubscription =
            this.listSelectorRef.selectionChange.subscribe((data: MatSelectionListChange) => {
                if (this._isMultiple) {
                    this.setAllState();
                }

                this.change.emit();
                this.touch();
            });
    }

    public ngOnDestroy(): void {
        this.listAllChangeSubscription.unsubscribe();
        this.listChangeSubscription.unsubscribe();
    }

    /**
     * @ignore
     */
    public get value(): string[] {
        return this.innerValue;
    }

    /**
     * @ignore
     */
    public set value(value: string[]) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.changed(value);
        }
    }

    /**
     * @ignore
     */
    public touch(): void {
        this.touched();
    }

    /**
     * @ignore
     */
    public writeValue(value: string[]): void {
        if (this.selectorList.length > 0) {
            this.innerValue = value;
            this.repositionSelected();
            this.ref.markForCheck();
        }
        else {
            this.tempValue = value;
        }
    }

    /**
     * @ignore
     */
    public registerOnChange(fn: (value: string[]) => void): void {
        this.changed = fn;
    }

    /**
     * @ignore
     */
    public registerOnTouched(fn: () => void): void {
        this.touched = fn;
    }

    /**
     * @ignore
     */
    public setDisabledState(isDisabled: boolean): void {
        this.listSelectorRef.setDisabledState(isDisabled);
    }

    private setAllState(): void {
        // Check if every option is selected not counting All.
        if (this.value && this.selectorList && this.selectorList.every((x) => this.value.some(y => y === x.id))) {
            this.isAll = true;
        }
        else {
            this.isAll = false;
        }
    }

    /**
     * @ignore
     */
    private buildList(listSelectorItems: ListSelectorItem[]): ListItem[] {
        return listSelectorItems.map((item: ListSelectorItem) => {

            const listItem: ListItem = {
                id: item.id,
                level: 0,
                filtered: true,
                selectable: item.selectable === undefined ? true : item.selectable
            };

            return Object.assign(item, listItem);

        });
    }

    /**
     * This reorders the list so that selected items are at the top. This should keep the items in the original sort order.
     */
    private repositionSelected(): void {
        if (!this.value)
            return;

        const selected: ListItem[] = this.selectorList.filter(x => this.value.some(y => y === x.id));
        const nonSelected: ListItem[] = this.selectorList.filter(x => !this.value.some(y => y === x.id));

        this.selectorList = [...selected, ...nonSelected];
    }

    /**
     * @ignore
     */
    private filterByText(filter: string | null): void {
        for (let i: number = 0; i < this.selectorList.length; i++) {
            this.selectorList[i].filtered = this.selectorList[i].text &&
                this.selectorList[i].text.toLowerCase().includes((filter || '').toLowerCase());
        }
    }
}
