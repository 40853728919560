import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'jn-snack-bar-info',
    templateUrl: './snack-bar-info.component.html',
    styleUrls: ['./snack-bar-info.component.scss']
})
export class SnackBarInfoComponent implements OnInit {
    constructor( @Inject(MAT_SNACK_BAR_DATA) public readonly data: string,
                private readonly matSnackBarService: MatSnackBar) { }

    public ngOnInit(): void {
    }

    public close(): void {
        this.matSnackBarService.dismiss();
    }
}