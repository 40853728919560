import { PaymentViewModel } from './payment-view.model';
/**
 * Existing CreditCard model
 */
export class CreatePaymentModel {
    public redirectUrl: string;
    public errorMessage: string;
    public paymentViewModels: PaymentViewModel[];

    constructor(options: any = {}) {
        this.redirectUrl = options.redirectUrl;
        this.paymentViewModels = options.paymentViewModels;
        this.errorMessage = options.errorMessage;
    }
}