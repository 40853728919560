import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ToolTipComponent } from './components/tool-tip/tool-tip.component';

@NgModule({
    declarations: [ToolTipComponent],
    imports: [CommonModule, MatIconModule],
    providers: [],
    exports: [ToolTipComponent]
})
export class ToolTipModule {}
