import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpOptions } from "@jeunesse/angular";
import { Observable } from "rxjs";
import { AuthHttpService } from "../../authentication/src/auth-http.service";
import { UrlHelperService } from "../../common/src/url-helper.service";
import { ReportParams } from "./interfaces/report-params.interface";


@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private readonly urlHelper: UrlHelperService,
    private readonly authHttp: AuthHttpService){}


  public getDistributorReport(parameters: ReportParams): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('reportName', parameters.reportName);
    queryParams = queryParams.append('mainFK', parameters.mainFk.toString());
    queryParams = queryParams.append('mainOrdersFK', parameters.mainOrdersFk ? parameters.mainOrdersFk.toString() : '');
    queryParams = queryParams.append('paymentMethod', parameters.paymentMethod);
    return this.authHttp.get<any>(this.urlHelper.toReportsApi('/v1/server-reports/dist-report-pdf'), { params: queryParams, responseType: 'blob' as 'json' }).pipe();
  }
}``
