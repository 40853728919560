import { Injectable } from "@angular/core";
import { AuthHttpService, UrlHelperService } from "@jeunesse/angular";
import { Observable } from "rxjs";
import { CardInfo, PaymentInfo, PaymentOption, PrepaidOrderInfo, PrepaidSimpleViewModel, PrepaidsQueryModel, SmartDeliveryItemFormModel, SmartDeliveryItemModel, SmartDeliverySettingsFormModel, SmartDeliverySettingsModel } from "../public-api";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { MainCartItem } from "@jeunesse/slide-cart";
import { ShippingMethodsViewModel } from "../../checkout/public-api";


@Injectable({
  providedIn: 'root'
})
export class SmartDeliveryApiService {
  constructor(private readonly authHttp: AuthHttpService,
    private readonly urlHelper: UrlHelperService) {

  }

  /**
     * Create smart delivery settings.
     * @return the setting model.
     */
  public createSettings(): Observable<SmartDeliverySettingsModel> {
    return this.authHttp.post<SmartDeliverySettingsModel>(this.urlHelper.toCartApi(`/v1/smartdelivery`)).pipe(
      map((data: SmartDeliverySettingsModel) => {
        return new SmartDeliverySettingsModel(data);
      })
    );
  }

  /**
   * Returns current smart delivery settings.
   * @return the setting model.
   */
  public getSettings(): Observable<SmartDeliverySettingsModel> {
    return this.authHttp.get<SmartDeliverySettingsModel>(this.urlHelper.toCartApi(`/v1/smartdelivery`)).pipe(
      map((data: SmartDeliverySettingsModel) => {
        return new SmartDeliverySettingsModel(data);
      })
    );
  }

  /**
   * Returns current smart delivery cards.
   * @return the list of card models.
   */
  public getCards(): Observable<CardInfo[]> {
    return this.authHttp.get<CardInfo[]>(this.urlHelper.toProfileApi(`/v1/payment`)).pipe(
      map((data: CardInfo[]) => {
        return data.map((card: CardInfo) => new CardInfo(card));
      })
    );
  }

  /**
   * Update settings
   * @param model settings model
   * @return status.
   */
  public updateSettings(model: SmartDeliverySettingsFormModel): Observable<SmartDeliverySettingsModel> {
    return this.authHttp.put(this.urlHelper.toCartApi(`/v1/smartdelivery`), model);
  }

  /**
   * Returns current smart delivery items.
   * @return  the item models.
   */
  public getItems(): Observable<SmartDeliveryItemModel[]> {
    return this.authHttp.get<SmartDeliveryItemModel[]>(this.urlHelper.toCartApi(`/v1/smartdelivery/items`)).pipe(
      map((data: SmartDeliveryItemModel[]) => {
        return data.map((item: SmartDeliveryItemModel) => new SmartDeliveryItemModel(item));
      })
    );
  }

  /**
   * Update item's quantity.
   * @param itempk id of smartdeliveryitem
   * @param qty new item quantity
   * @return the view model.
   */
  public updateItem(itempk: number, model: SmartDeliveryItemFormModel): Observable<SmartDeliveryItemModel> {
    return this.authHttp.put<SmartDeliveryItemModel>(this.urlHelper.toCartApi(`/v1/smartdelivery/items/${itempk}`), model).pipe(
      map((data: SmartDeliveryItemModel) => {
        return new SmartDeliveryItemModel(data);
      })
    );
  }

  /**
   * Add item.
   * @return the view model.
   */
  public addItem(model: SmartDeliveryItemFormModel): Observable<SmartDeliveryItemModel> {
    return this.authHttp.post<SmartDeliveryItemModel>(this.urlHelper.toCartApi(`/v1/smartdelivery/items`), model).pipe(
      map((data: SmartDeliveryItemModel) => {
        return new SmartDeliveryItemModel(data);
      })
    );
  }

  /**
   * Remove item
   * @param itempk id of smartdeliveryitem
   * @return the view model.
   */
  public removeItem(itempk: number): Observable<void> {
    return this.authHttp.delete<void>(this.urlHelper.toCartApi(`/v1/smartdelivery/items/${itempk}`));
  }

  /**
   * Add Kids Donation
   * @return the view model.
   */
  public addDonation(): Observable<SmartDeliveryItemModel> {
    return this.authHttp.put<SmartDeliveryItemModel>(this.urlHelper.toCartApi(`/v1/smartdelivery/items/addkidsdonation`), null).pipe(
      map((data: SmartDeliveryItemModel) => {
        return new SmartDeliveryItemModel(data);
      })
    );
  }

  /**
   * Used to query a list of shipping options for SmartDelivery.
   */
  public getShippingOptions(): Observable<ShippingMethodsViewModel> {
    let params = new HttpParams();
    params = params.append('isSDProfile', 'true')

    return this.authHttp
      .get<ShippingMethodsViewModel>(this.urlHelper.toCartApi('/v2/shipping/shipping/options'), { params: params })
      .pipe(
        map((resp: ShippingMethodsViewModel) => {
          return new ShippingMethodsViewModel(resp);
        })
      );
  }

  /**
   * Returns available payment options.
   * @return the PaymentOption model.
   */
  public getPaymentOptions(): Observable<PaymentOption[]> {
    return this.authHttp.get<PaymentOption[]>(this.urlHelper.toCartApi(`/v1/smartdelivery/payment`)).pipe(
      map((data: PaymentOption[]) => {
        return data.map((option: PaymentOption) => new PaymentOption(option));
      })
    );
  }

  public createSmartDeliveryOrder(): Observable<PrepaidOrderInfo> {
    return this.authHttp.post(this.urlHelper.toCartApi('/v1/orders/create-autoship-order'));
  }

  /**
   * Get Orders, filtered by mainPK, orderNumber etc from API.
   * @param prepaidQuery An object of OrderQuery interface that will be converted to url parameters
   */
  public getPrepaids(prepaidQuery?: PrepaidsQueryModel): Observable<PrepaidSimpleViewModel> {
    prepaidQuery = prepaidQuery || {};

    return this.authHttp
      .get<PrepaidSimpleViewModel>(this.urlHelper.toCartApi(`/v1/smartdelivery/prepaid`), { params: prepaidQuery })
      .pipe(
        map((data: PrepaidSimpleViewModel) => {
          return new PrepaidSimpleViewModel(data);
        })
      );
  }

  public createPrepaidOrder(items: MainCartItem[]): Observable<PrepaidOrderInfo> {
    return this.authHttp.post<PrepaidOrderInfo>(this.urlHelper.toCartApi('/v1/orders/create-prepaid-order'), items).pipe(
      map((data: PrepaidOrderInfo) => {
        return data;
      })
    );
  }

  // public getMainUserDetails(siteUrl: string): Observable<MainDistributorDetailsModel> {
  //     return this.authHttp.get<MainDistributorDetailsModel>(this.urlHelper.toProfileApi(`/v1/dashboards/main-details/${siteUrl}`)).pipe(
  //         map((res: MainDistributorDetailsModel) => {
  //             return new MainDistributorDetailsModel(res);
  //         })
  //     );
  // }

  public getPaymentInfo(): Observable<PaymentInfo[]> {
    return this.authHttp.get<PaymentInfo[]>(this.urlHelper.toCartApi(`/v1/orders/0/payments/info`)).pipe(
      map((data: PaymentInfo[]) => {
        return data.sort((a, b) => a.priority - b.priority);
      })
    );
  }
}
