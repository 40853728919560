import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WebStorageService } from '../../../common/src/web-storage.service';

@Component({
    templateUrl: './loginRedirect.component.html',
    styleUrls: ['./loginRedirect.component.scss']
})
export class LoginRedirectComponent {
    constructor(
        private readonly dialogRef: MatDialogRef<LoginRedirectComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly webStorageService: WebStorageService) { }

    public close(): void {
        this.dialogRef.close();
    }

    public jOfficeRedirection(): void {
        this.webStorageService.setCookie('LiToken', this.data.liToken, this.data.expDate);
        this.webStorageService.setCookie('LiUser', this.data.liUser, this.data.expDate);
        window.location.href = this.data.url;
    }
}