import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepSiteService } from './services/rep-site.service';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    declarations: [],
  imports: [
      MatIconModule,
      FlexLayoutModule,
      MatExpansionModule,
      MatFormFieldModule,
      CommonModule
    ],
    exports: [],
    providers: []
})
export class RepsiteModule {
  public static forRoot(): ModuleWithProviders<RepsiteModule> {
    return {
      ngModule: RepsiteModule,
      providers: [
        RepSiteService,
        {provide: MAT_DIALOG_DATA, useValue: {}},
        {provide: MatDialogRef, useValue: {}}
      ]
    };
  }
}
