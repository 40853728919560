import { LocaleService } from '@jeunesse/angular';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
    ProductQueryModel,
    ProductModel,
    BestSellerModel,
    BrandViewModel,
    BestSellersQuery,
    ProductDescriptionModel,
    CAPHeaderModel
} from '../models/public-api';
import { BehaviorSubject } from 'rxjs';
import { ProductApiService } from './product-api.service';
import { ProductStateService } from './product-state.service';

@Injectable({ providedIn: 'root' })
/**
 * Service for interacting with Fontana product api calls.
 */
export class ProductService {
    protected addToCartSubject$: BehaviorSubject<ProductModel> = new BehaviorSubject<ProductModel>(new ProductModel(null));
    public addToCartObservable$: Observable<ProductModel> = this.addToCartSubject$.asObservable();

    constructor(
        private productApiService: ProductApiService,
        private productStateService: ProductStateService,
        private readonly localService: LocaleService
    ) {
        this.productApiService.getSpotlightBrands();

    }

    public getAllProducts$(): Observable<any> {
        return this.productStateService.getProductsState$();
    }

    public getProducts$(): Observable<ProductModel[]> {
        return this.productStateService.getProducts$();
    }

    public getSpotlightProducts$(): Observable<BrandViewModel[]> {
        return this.productStateService.getSpotlightProducts$();
    }

    public getBestSellerProducts$(): Observable<BestSellerModel[]> {
        return this.productStateService.getBestSellerProducts$();
    }

    /**
     * Returns a list of active products.
     * @param query Query params to filter on
     */
    public products(query: ProductQueryModel): Observable<ProductModel[]> {
        let httpParams: HttpParams = new HttpParams();
        Object.keys(query).forEach((key) => {
            httpParams = httpParams.append(key, query[key]);
        });
        return this.productApiService.getAllProducts(httpParams).pipe(
            map((res: ProductModel[]) => {
                let products: ProductModel[] = (res || []) as ProductModel[];
                return products;
            })
        );
    }

    /**
     * Returns a list of products designated as best sellers.
     * @param query Query params to filter on
     */
    public getBestSellers(query: BestSellersQuery): void {
        let httpParams: HttpParams = new HttpParams();
        Object.keys(query).forEach((key) => {
            httpParams = httpParams.append(key, query[key]);
        });
        this.productApiService.getBestSellers(httpParams).subscribe((res: BestSellerModel[]) => {
            let products: BestSellerModel[] = (res || []) as BestSellerModel[];
            this.productStateService.setBestSellerProducts(products);
        });
    }

    /**
     * Adds a product to the user's cart.
     */
    public addProductToCart(product: ProductModel): void {
        this.addToCartSubject$.next(product);
    }

    public getProductBySKU(query: {SKU: string, culture: string}): Observable<ProductDescriptionModel> {

        return this.productApiService.getProductBySKU(query);
    }

    public getCAPHeadersWithCVRanges(country: string, langId: string): Observable<CAPHeaderModel[]> {
        return this.productApiService.getCAPHeadersWithCVRanges(country, langId);
    }
}
