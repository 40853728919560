/**
 * member model
 */
export class MemberViewModel {
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public nameSuffix: string;
    public signupDate: Date;
    public smartDeliveryDate: Date;
    public accountStatus: string;
    public username: string;
    public sponsorUsername: string;
    public userId: number;
    public countryName: string;
    public courtesyTitle: string;
    public homePhone: string;
    public secondPhone: string;
    public faxNumber: string;
    public emailAddress: string;
    public dateOfBirth: Date;
    public language: string;
    public languageFk: number;
    public receiveEmails: boolean;
    public receiveSms: boolean;
    public smsInEnglish: boolean;
    public accptSmsCharges: boolean;
    public accptSmsNotReq: boolean;
    public mainTypeFk: number;
    public lockPlacementLra: string;
    public showContactInfo: string;
    public anniversaryDate: Date;
    public isCorporate: string;
    public companyIndividual: string;
    public company: string;
    public taxIdNumber: string;
    public coAppName: string;
    public displayName: string;
    public taxExempt: boolean;
    public w9OnFile: boolean;
    public t4A1099Exempt: boolean;
    public allowInsidePlacement: boolean;
    public insidePlacementLevel: number;
    public insideTopMainFk: number;
    public insideSignupSponsorSide: string;
    public sponsorType: string;
    public canSponsorAffiliate: boolean;
    public countryFk: string;
    public mainPk: number;
    public sponsorFK: number;

    constructor(options: any = {}) {
        this.firstName = options.firstName;
        this.middleName = options.middleName;
        this.lastName = options.lastName;
        this.nameSuffix = options.suffix;
        this.signupDate = options.dateCreated;
        this.smartDeliveryDate = options.autoShipOn;
        this.accountStatus = options.statusName;
        this.username = options.siteUrl;
        this.sponsorUsername = options.sponsorSiteUrl;
        this.userId = options.userId;
        this.countryName = options.countryName;
        this.courtesyTitle = options.courtesyTitle;
        this.homePhone = options.phone;
        this.secondPhone = options.secondPhone;
        this.faxNumber = options.faxNumber;
        this.emailAddress = options.email;
        this.dateOfBirth = (new Date(options.dateOfBirth).getFullYear() === 1900 || new Date(options.dateOfBirth).getFullYear() === 1800) ? null : options.dateOfBirth;
        this.language = options.languageName;
        this.languageFk = options.languageFK;
        this.receiveEmails = options.willReceiveEmail;
        this.receiveSms = options.willReceiveSms;
        this.smsInEnglish = options.smsInEnglish;
        this.accptSmsCharges = options.accptSmsCharges;
        this.accptSmsNotReq = options.accptSmsNotReq;
        this.mainTypeFk = options.mainTypeFk;
        this.lockPlacementLra = options.lockPlacementLra;
        this.showContactInfo = options.showContactInfo;
        this.anniversaryDate = options.anniversaryDate;
        this.isCorporate = options.isCorporate;
        this.companyIndividual = options.isCorporate ? 'Company' : 'Individual';
        this.company = options.company;
        this.taxIdNumber = options.taxIdNumber;
        this.coAppName = options.coAppName;
        this.displayName = options.displayName;
        this.taxExempt = options.taxExempt;
        this.w9OnFile = options.w9OnFile;
        this.t4A1099Exempt = options.t4A1099Exempt;
        this.allowInsidePlacement = options.allowInsidePlacement;
        this.insidePlacementLevel = options.insidePlacementLevel;
        this.insideTopMainFk = options.insideTopMainFk;
        this.insideSignupSponsorSide = options.insideSignupSponsorSide;
        this.sponsorType = options.sponsorType;
        this.canSponsorAffiliate = options.canSponsorAffiliate;
        this.mainPk = options.mainPk;
        this.countryFk = options.countryFk;
        this.sponsorFK = options.sponsorFK;
    }
}