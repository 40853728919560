export class PrepaidFormModel {
    public userId: number;
    public orderNumber: number;
    public statusOrder: string;
    public startDate: Date;
    public endDate: Date;
    public nextProcessDate: Date;
    public monthsCount: number;
    public totalBV: number;
    public monthlyBV: number;

    constructor(options: any = {}) {
        this.userId = options.userId;
        this.orderNumber = options.orderNumber;
        this.statusOrder = options.statusOrder;
        this.startDate = options.startDate;
        this.endDate = options.endDate;
        this.nextProcessDate = options.nextProcessDate;
        this.monthsCount = options.monthsCount;
        this.totalBV = options.totalBV;
        this.monthlyBV = options.monthlyBV;
    }
}