// tslint:disable: typedef
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { EventMemberModel } from '../..';
import * as EventAgendasPostActions from '../actions/event-post-agendas.actions';
import { createReducer, on } from '@ngrx/store';

export interface AgendasPostState extends EntityState<EventMemberModel> {
    selectedId: number | null;
    error: any;
  }

function sortById(a1: EventMemberModel, a2: EventMemberModel) {
    return parseInt(a1.id, 0) - parseInt(a2.id, 0);
}
export const adapter: EntityAdapter<EventMemberModel> = createEntityAdapter<EventMemberModel>({
    sortComparer: sortById
});

const initialState = adapter.getInitialState({});

export const eventAgendasPostReducer = createReducer(initialState,
    on(EventAgendasPostActions.addPostTickets, (state: AgendasPostState, {agendasPosts}) => {
        return adapter.addMany(agendasPosts, state);
    }),
    on(EventAgendasPostActions.addPostTicket, (state: AgendasPostState, {agendaPost}) => {
        if (state.ids[0] === 0) {
            return state;
        }
        return adapter.addOne(agendaPost, state);
    }),
    on(EventAgendasPostActions.updatePostTicket, (state, { update }) => {
        return adapter.updateOne(update, state);
    }),
    on(EventAgendasPostActions.updatePostTickets, (state, { updates }) => {
        return adapter.updateMany(updates, state);
    }),
    on(EventAgendasPostActions.clearTickets, state => {
        return adapter.removeAll({ ...state, selectAgendaIds: null });
    }),
    on(EventAgendasPostActions.deleteTicket, (state, { id }) => {
        return adapter.removeOne(id, state);
      }),
    on(EventAgendasPostActions.deleteTickets, (state, { ids }) => {
    return adapter.removeMany(ids, state);
    })
);
export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();

// select the array of agenda ids
export const selectAgendaIds = selectIds;

// select the dictionary of agenda entities
export const selectAgendaPostEntities = selectEntities;

// select the array of Agendas
export const selectAllAgendas = selectAll;

// select the total Agenda count
export const selectAgendaTotal = selectTotal;

