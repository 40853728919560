import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListSelectorComponent } from '../list-selector.component';
import { forwardRef } from '@angular/core';

/**
 * @ignore
 */
export const LIST_SELECTOR_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line
    useExisting: forwardRef(() => ListSelectorComponent),
    multi: true
};
