import { Pipe, PipeTransform } from '@angular/core';
import { MenuAlertModel } from '../../../shared/models/menuAlert.model';

@Pipe({
    name: 'showAlert',
    pure: true
  })
export class ShowAlertPipe implements PipeTransform {
    public transform(value: MenuAlertModel[], pageName: string): boolean {
      return value.find((alert: MenuAlertModel) => alert.pageName === pageName) ? true : false;
    }
}
