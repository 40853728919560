export class ExternalDynamicFormQueryModel {
    public countryCode?: string;
    public formType?: string;
    public promoId?: number;
    public isGuest?: boolean;

    constructor(options: any = {}) {
        this.countryCode = options.countryCode;
        this.formType = options.formType;
        this.promoId = options.promoId;
        this.isGuest = options.isGuest;
    }
}
