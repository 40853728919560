import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AuthenticationModule } from '../../authentication/src/authentication.module';
import { CommonModule } from '../../common/src/common.module';

import { CmsSessionService } from './cms-session.service';
import { TranslationDirective } from './translation.directive';
import { TranslationImageDirective } from './translation-image.directive';
import { TranslationVideoDirective } from './translation-video.directive';
import { TranslationResolver } from './translation.resolver';
import { TranslationImageService } from './translation-image.service';
import { TranslationImageResolver } from './translation-image.resolver';
import { TranslationVideoService } from './translation-video.service';
import { TranslationVideoResolver } from './translation-video.resolver';
import { EditTranslationModalComponent } from './edit-translation/edit-translation-modal.component';
import { QuillModule } from 'ngx-quill';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { CmsService } from './cms.service';
import { LocaleService } from './locale.service';


@NgModule({
    imports: [
        HttpClientModule,
        RouterModule,
        AuthenticationModule,
        CommonModule,
        FormsModule,
        QuillModule.forRoot(),
        MatDialogModule
    ],
    exports: [
        TranslationDirective,
        TranslationImageDirective,
        TranslationVideoDirective,
        EditTranslationModalComponent
    ],
    declarations: [
        TranslationDirective,
        TranslationImageDirective,
        TranslationVideoDirective,
        EditTranslationModalComponent
    ],
    entryComponents: [
        EditTranslationModalComponent
    ],
    providers: [
    ]
})
export class CmsModule {
    /**
     * This is required so the same provider is injected into the load on demand modules.
     *
     * More Details: http://angularfirst.com/the-ngmodule-forroot-convention/
     */
    public static forRoot(): ModuleWithProviders<CmsModule> {
        return {
            ngModule: CmsModule,
            providers: [
                CmsService,
                TranslationResolver,
                TranslationImageService,
                TranslationImageResolver,
                TranslationVideoService,
                TranslationVideoResolver,
                EditTranslationModalComponent,
                MatDialogModule,
                CmsSessionService
            ]
        };
    }
}
