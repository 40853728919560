import { shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CustomsPaymentMessage, CustomsPaymentMessageQuery, PaymentProcess, PaymentResource, PaymentStatus } from '../models/public-api';
import { AuthHttpService, AuthService } from '@jeunesse/angular';
import { UrlHelperService } from '@jeunesse/angular';
import { Observable, of } from 'rxjs';

/**
 * Class for making http requests only for payments
 */
@Injectable({
    providedIn: 'root'
})
export class PaymentApiService {
    private _paymentStatusQuery$ = new Map<number, Observable<PaymentStatus[]>>();
    constructor(private readonly authHttp: AuthHttpService,
        private readonly urlHelper: UrlHelperService,
        private readonly authService: AuthService) { }

    public addPaymentToOrder(orderNumber: number, model: PaymentProcess): Observable<PaymentResource> {
        if (this.authService.isLoggedIn) {
            return this.authHttp.post<PaymentResource>(this.urlHelper.toCartApi(`/v1/orders/${orderNumber}/payments`), model).pipe();
        } else {
            return of(undefined);
        }
    }

    public updatePayment(orderNumber: number, model: PaymentProcess): Observable<PaymentResource> {
        let items = {
            paidStatusFK: model.paidStatusFK,
            paymentMethodTypePK: model.paymentMethodTypePK
        }
        return this.authHttp.put<PaymentResource>(this.urlHelper.toCartApi(`v1/orders/${orderNumber}/payments/${model.paymentMethodTypePK}`), { 'params': items }).pipe();
    }

    public getPaymentStatus(orderNumber: number): Observable<PaymentStatus[]> {
        if(!this._paymentStatusQuery$.get(orderNumber)) {
            this._paymentStatusQuery$.set(
                orderNumber,
                this.authHttp.get<PaymentStatus[]>(this.urlHelper.toCartApi(`v1/orders/${orderNumber}/payments`)).pipe(shareReplay())
            )
        }
        return this._paymentStatusQuery$.get(orderNumber)
    }

    public updatePaymentDetails(model, memberModel, billingEqualToShipping) {
        var models = {
            ccfname: memberModel.firstName,
            cclname: memberModel.lastName,
            ccExpireMonth: model.expireMonth || model.ccExpireMonth,
            ccExpireYear: model.expireYear || model.ccExpireYear,
            ccBillingAddr1: memberModel.address1,
            ccBillingAddr2: memberModel.address2,
            ccBillingAddr3: memberModel.address3,
            ccBillingCity: memberModel.city,
            ccBillingState: memberModel.state,
            ccBillingZip: memberModel.postalCode,
            ccBillingPhone: memberModel.phoneNumber,
            ccBillingEmail: memberModel.email,
            ccBillingCountry: memberModel.country,
            sequencePK: model.sequencePk,
            useBillingAddress: !billingEqualToShipping
        }
        return this.authHttp.put<any>(this.urlHelper.toProfileApi(`v1/payment`), models).pipe();
    }

    public getCustomsPaymentMessages(params: CustomsPaymentMessageQuery): Observable<CustomsPaymentMessage[]> {
        return this.authHttp.get<CustomsPaymentMessage[]>(this.urlHelper.toCartApi('v1/payment/customs-warning'), { params }).pipe();
    }
}
