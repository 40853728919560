import { LoyaltyPointsModel, SmartDeliveryModel, SmartDeliveryService } from '@jeunesse/cart-lib';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AuthService,
  LocaleService,
  LoggerInterface,
  LoggerService,
  LoggerTypeEnum,
  TranslationService,
  WebStorageService,
} from '@jeunesse/angular';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CartItemsRemovedDialogComponent } from '../components/cart-items-removed-dialog/cart-items-removed-dialog.component';
import {
  MainCart,
  MainCartItem,
  MainCartItemsValidate,
  ShoppingCart,
  ShoppingCartItem,
  ValidateCartQueryModel,
  ValidateCartViewModel,
} from '../models/public-api';
import { ConfigModalComponent } from '../products/components/configuration/config-modal.component';
import { SmartDeliveryConfirmationComponent } from '../products/components/smartdelivery/sd-confirmation.component';
import {
  ProductConfigModel,
  ProductModel,
  SmartDeliveryConfirmationDialogDataModel,
  SmartDeliveryConfirmationModel,
  SmartDeliveryItemFormModel,
  SmartDeliveryItemModel,
} from '../products/models/public-api';
import { MainCartApiService } from './main-cart-api.service';
import { MainCartItemApiService } from './main-cart-item-api.service';
import { MainCartStateService } from './main-cart-state.service';
import { SubSink } from 'subsink';
import { Direction } from '@angular/cdk/bidi';

@Injectable({ providedIn: 'root' })
export class MainCartService {
  private shoppingCart: ShoppingCart;
  private isRedemption: boolean;
  private translations: any = {
    smartDeliveryWarn: 'Could not add smart delivery item!',
    loyaltyNotActive: 'Sorry, your loyalty program is not active!',
    loyaltyNotEnoughPoints: 'Sorry, you do not have enough points for this product!',
    onlyOnePackage: 'You can only add one package of the same type at a time!',
  };
  private smartDeliveryService: any;
  private wholesaleCartMainTypes: number[] = [1, 22, 3, 6, 7, 11, 17];
  private customerServiceMainTypes: number[] = [3, 6, 7, 11, 17];
  private defaultCorpSiteUrl: string =
    this.webStorageService.getCookie('Country') && this.webStorageService.getCookie('Country') === 'BR' ? 'jhqbr' : 'jhqsocial';
  private directionality: Direction = 'ltr';
  private country: string = '';
  private culture: string = '';
  constructor(
    private readonly mainCartApiService: MainCartApiService,
    private readonly mainCartItemApiService: MainCartItemApiService,
    private readonly mainCartStateService: MainCartStateService,
    private readonly webStorageService: WebStorageService,
    private readonly loggerService: LoggerService,
    private readonly authService: AuthService,
    private readonly translationService: TranslationService,
    protected readonly dialog: MatDialog,
    private readonly localeService: LocaleService
  ) {
    this.mainCartStateService.getMainCart$().subscribe((cart: ShoppingCart) => {
      this.shoppingCart = cart;
    });
    this.localeService.directionalityObservable$.subscribe((directionality: Direction) => (this.directionality = directionality));
    this.getTranslations();
    const params = new URLSearchParams(window.location.search);
    if (params.get('culture')) {
      this.culture = params.get('culture');
      this.webStorageService.setCookie('Culture', '"' + this.culture + '"');
    } else {
      let cultureCookie: string = this.webStorageService.getCookie('Culture');
      this.culture = cultureCookie ? cultureCookie.replace(/"/g, '') : 'en-US';
    }
    if (params.get('country')) {
      this.country = params.get('country');
      this.webStorageService.setCookie('Country', '"' + this.country + '"');
    } else {
      let countryCookie: string = this.webStorageService.getCookie('Country');
      this.country = countryCookie ? countryCookie.replace(/"/g, '') : 'US';
    }
  }

  public init(cartKey: string, smartDeliveryService: any, referrerSiteUrl: string, validateReferrer: boolean = false): Observable<ShoppingCart> {
    this.smartDeliveryService = smartDeliveryService;
    let cartKeyCookie: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) { cartKey = cartKeyCookie; }
    if (cartKeyCookie && cartKey !== cartKeyCookie) {
      this.webStorageService.setCookie('cartKey', cartKey);
    }
    if (!referrerSiteUrl) {
      referrerSiteUrl = this.defaultCorpSiteUrl;
    }
    // already have a cart, get the cart info
    if (cartKey) {
      return this.mainCartApiService.patchMainCart(cartKey, {
          mainFk: this.authService.isLoggedIn ? this.authService.getMainId() : null,
          referrerSiteUrl,
          countryCode: this.country || 'US',
          culture: this.culture || 'en-US',
        }).pipe(map((cart) => {
            // Check if cart has a mainfk set but user is not logged in create new cart
            if (cart.mainFK && !this.authService.isLoggedIn) {
              this.webStorageService.removeCookie('cartKey');
              this.createNewCart(referrerSiteUrl, validateReferrer)
                .pipe(map((newCart: ShoppingCart) => {
                return newCart;
              }));
            } else {
              this.mainCartStateService.setMainCart(cart);
              return cart;
            }
          }),
          catchError(() => {
            this.webStorageService.removeCookie('cartKey');
            // there is no cart, need to create a new one
            return this.createNewCart(referrerSiteUrl, validateReferrer);
          }));
    } else {
      // there is no cart, need to create a new one
      this.webStorageService.removeCookie('cartKey');
      return this.createNewCart(referrerSiteUrl, validateReferrer);
    }


  }

  public setDisableButton(): void {
    this.mainCartStateService.setDisableButton();
  }

  public getButtonSubs(): SubSink {
    return this.mainCartStateService.getButtonSubs();
  }

  public getButtonState$(): Observable<boolean> {
    return this.mainCartStateService.getButtonState$();
  }

  public getMainCart$(): Observable<ShoppingCart> {
    return this.mainCartStateService.getMainCart$();
  }

  public getMainCartItems$(): Observable<ShoppingCartItem[]> {
    return this.mainCartStateService.getMainCartItems$();
  }

  public getShoppingCart(): ShoppingCart {
    return this.shoppingCart;
  }

  public updateCart(cart: MainCart, removeItems: boolean = false): Observable<boolean> {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) {
      return of(false);
    }
    return this.mainCartApiService.patchMainCart(cartKey, cart).pipe(
      map((resp) => {
        if (removeItems) {
          // Show Remove Cart Item Dialog
          const dialogRef: MatDialogRef<CartItemsRemovedDialogComponent> = this.dialog.open(CartItemsRemovedDialogComponent, {
            direction: this.directionality,
          });

          dialogRef.afterClosed().subscribe(() => {
            this.removeCartItems(false);
          });
        } else {
          this.mainCartStateService.setMainCart(resp);
        }
        return true;
      })
    );
  }

  public validateCart(validateModel: ValidateCartQueryModel): Observable<ValidateCartViewModel> {
    if (!validateModel.cartKey) {
      return of(null);
    }
    return this.mainCartApiService.validateCartCheckout(validateModel.cartKey, validateModel).pipe(
      map((res: ValidateCartViewModel) => {
        if (res.orderPaid) {
          this.mainCartApiService.getMainCart(validateModel.cartKey).subscribe((cart) => {
            this.mainCartStateService.setMainCart(cart);
          });
        }
        return res;
      })
    );
  }

  public validateCartItems(cartKey: string): Observable<MainCartItemsValidate[]> {
    return this.mainCartApiService.validateCartItems(cartKey).pipe();
  }

  public getMainCartItems(toggleCart: boolean): Observable<ShoppingCartItem[]> {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    return this.mainCartItemApiService.getItems(cartKey).pipe(map((items) => {
      this.mainCartStateService.addCartItems(items, toggleCart);
      return items;
    }));
  }

  public addToCart(
    product: ProductModel,
    smartDeliveryInfo: SmartDeliveryModel,
    addToSmartDelivery: boolean = false,
    loyaltyPointsInfo: LoyaltyPointsModel = undefined,
    toggleCart: boolean,
    onShop: boolean = true
  ): void {
    product.quantity = product.quantity ? product.quantity : 1;
    let loyaltyPointChecker: boolean = true;
    // Check for loyalty redemption points
    if (loyaltyPointsInfo && this.shoppingCart.cartType === 'LR') {
      const cartItems = this.shoppingCart.cartItems;
      let totalPoints: number = 0;
      if (cartItems && cartItems.length > 0) {
        totalPoints = product.quantity * product.points;
        cartItems.forEach((item) => {
          totalPoints = totalPoints + item.quantity * item.points;
        });
      } else if (product) {
        totalPoints = product.quantity * product.points;
      }
      // Add the selected product points to the cart total points.
      if (loyaltyPointsInfo.active !== true && loyaltyPointsInfo.canRedeemPoints !== true) {
        loyaltyPointChecker = false;
        const log: LoggerInterface = { logType: LoggerTypeEnum.WARN, message: this.translations.loyaltyNotActive };
        this.loggerService.log(log);
        this.mainCartStateService.getButtonState$();
      } else if (loyaltyPointsInfo.loyaltyPointsBalance < totalPoints) {
        loyaltyPointChecker = false;
        const log: LoggerInterface = { logType: LoggerTypeEnum.WARN, message: this.translations.loyaltyNotEnoughPoints };
        this.loggerService.log(log);
        this.mainCartStateService.getButtonState$();
      }
    }
    if (loyaltyPointChecker) {
      // Check if we need to block allowing SD due to Import Cart Mismatch
      let blockSDAdd: boolean = false;
      if (this.shoppingCart.allowImportCart && smartDeliveryInfo && smartDeliveryInfo.smartDeliveryItems.length > 0) {
        let hasImportSD: boolean = false;
        if (smartDeliveryInfo) {
          hasImportSD = smartDeliveryInfo.isImportOrder;
        }
        if (this.shoppingCart.cartType === 'I' && !hasImportSD) {
          blockSDAdd = true;
        } else if (this.shoppingCart.cartType !== 'I' && hasImportSD) {
          blockSDAdd = true;
        }
      }

      if (!addToSmartDelivery) {
        if ((product.isConfigurable || (product.isPackage && product.configurations.length > 0)) && !product.selectedConfig) {
          // Show Configuration Dialog
          const dialogRef: MatDialogRef<ConfigModalComponent> = this.dialog.open(ConfigModalComponent, {
            data: product,
            minWidth: 350,
            direction: this.directionality,
          });

          dialogRef.afterClosed().subscribe((res: ProductConfigModel[]) => {
            if (res) {
              product.selectedConfig = res;
              this.distinguishCartItem(product, toggleCart);
              product.selectedConfig = null;
            } else {
              this.mainCartStateService.undoAddToCartButton();
            }
          });
        } else {
          this.distinguishCartItem(product, toggleCart);
        }
      } else if (addToSmartDelivery && blockSDAdd) {
        this.openSmartDeliveryConfirmationDialog({ succeed: false, notAllowImportCart: true });
      } else {
        if ((product.isConfigurable || (product.isPackage && product.configurations.length > 0)) && !product.selectedConfig) {
          // Show Configuration Dialog
          product.addToSmartDelivery = true;
          product.selectedSDConfig = new Array<ProductConfigModel>();
          product.configurations.forEach((config: ProductConfigModel) => {
            if (
              smartDeliveryInfo &&
              smartDeliveryInfo.smartDeliveryItems.find((x: SmartDeliveryItemModel) => x.productPK === config.productPK)
            ) {
              product.selectedSDConfig.push(config);
            }
          });

          const dialogRef: MatDialogRef<ConfigModalComponent> = this.dialog.open(ConfigModalComponent, {
            data: product,
            minWidth: 350,
            direction: this.directionality,
          });

          dialogRef.afterClosed().subscribe((res: ProductConfigModel[]) => {
            if (res) {
              product.selectedConfig = res;
              this.addItemToSD(product, smartDeliveryInfo);
              product.selectedConfig = null;
            }
            product.addToSmartDelivery = false;
          });
        } else {
          this.addItemToSD(product, smartDeliveryInfo);
        }
        if (onShop) {
          this.openSmartDeliveryConfirmationDialog({
            succeed: smartDeliveryInfo.active && smartDeliveryInfo.isPaymentSetup,
            notAllowImportCart: false,
          });
        }
      }
    }
  }

  public addCartItems(items: MainCartItem[], toggleCart: boolean): void {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) {
      return;
    }
    this.mainCartItemApiService.addItems(cartKey, items).subscribe((items) => {
      this.reloadCart(cartKey, toggleCart);
    });
  }

  public updateCartItem(id: number, item: MainCartItem, toggleCart: boolean): void {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) {
      return;
    }
    this.mainCartItemApiService.updateItem(cartKey, id, item).subscribe((cartItem) => {
      this.reloadCart(cartKey);
    });
  }

  public removeCartItem(id: number): void {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) {
      return;
    }
    this.mainCartItemApiService.removeItem(cartKey, id).subscribe(() => {
      this.reloadCart(cartKey, false);
    });
  }

  public removeCartItems(toggleCart: boolean = true): void {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) {
      return;
    }
    this.mainCartItemApiService.removeItems(cartKey).subscribe(() => {
      this.reloadCart(cartKey, toggleCart);
    });
  }

  public addAffiliateItems(cartType: string, countryCode: string, items: string[]): Observable<any> {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) {
      return of(false);
    }
    return this.mainCartItemApiService.addAffiliateItems(cartKey, cartType, countryCode, items).pipe();
  }

  public addQuickCheckoutItems(cartType: string, countryCode: string, items: string[]): Observable<any> {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) {
      return of(false);
    }
    return this.mainCartItemApiService.addQuickCheckoutItems(cartKey, cartType, countryCode, items).pipe();
  }

  public clearCart(repsiteUrl?: string, validateReferrer: boolean = false, showDialog: boolean = false): Observable<boolean> {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    if (!cartKey) {
      return this.init('', this.smartDeliveryService, repsiteUrl, validateReferrer).pipe(map(() => { return true; }));
    }
    if (showDialog) {
      // Show Remove Cart Item Dialog
      const dialogRef: MatDialogRef<CartItemsRemovedDialogComponent> = this.dialog.open(CartItemsRemovedDialogComponent, {
        direction: this.directionality,
      });

      dialogRef.afterClosed().subscribe(() => {
        return this.mainCartApiService.deleteMainCart(cartKey).pipe(() => {
            this.webStorageService.removeCookie('cartKey');
          return this.init('', this.smartDeliveryService, repsiteUrl, validateReferrer).pipe(map(() => { return true; }));
          },
          error => {
            this.webStorageService.removeCookie('cartKey');
            return this.init('', this.smartDeliveryService, repsiteUrl, validateReferrer).pipe(map(() => { return true; }));
          });
      });
    } else {
      return this.mainCartApiService.deleteMainCart(cartKey).pipe(() => {
          this.webStorageService.removeCookie('cartKey');
        return this.init('', this.smartDeliveryService, repsiteUrl, validateReferrer).pipe(map(() => { return true; }));
        },
        error => {
          this.webStorageService.removeCookie('cartKey');
          return this.init('', this.smartDeliveryService, repsiteUrl, validateReferrer).pipe(map(() => { return true; }));
        }
      );
    }
  }

  public checkIntValue(product: ProductModel): number {
    if (!product.maxLimit || product.maxLimit < 1) {
      product.maxLimit = 25;
    }
    if (product.quantity > product.maxLimit) {
      product.quantity = product.maxLimit;
    } else if (product.quantity < 1) {
      product.quantity = null;
    } else {
      product.quantity = Math.floor(product.quantity);
    }
    return product.quantity;
  }

  public get isStoreInRedemptionMode(): boolean {
    if (this.shoppingCart) {
      const sessionStatus: string = this.webStorageService.getSession('loyalty-redemption');
      if (!this.authService.isLoggedIn && sessionStatus) {
        this.webStorageService.removeSession('loyalty-redemption');
        const model: MainCart = {
          cartType: 'R',
          mainFk: this.shoppingCart.mainFK,
          mainReferrerFk: this.shoppingCart.mainReferrerFK,
          campaignCode: this.shoppingCart.campaignCode,
          culture: this.shoppingCart.culture,
          mainOrderTypeFk: this.shoppingCart.mainOrderTypeFK,
          priceListFk: this.shoppingCart.priceListFK,
          salesCampaignFk: this.shoppingCart.salesCampaignFK,
        };
        this.updateCart(model);
        return false;
      }
      if (sessionStatus !== 'true' && this.shoppingCart && this.shoppingCart.cartType === 'LR') {
        const model: MainCart = {
          cartType: 'R',
          mainFk: this.shoppingCart.mainFK,
          mainReferrerFk: this.shoppingCart.mainReferrerFK,
          campaignCode: this.shoppingCart.campaignCode,
          culture: this.shoppingCart.culture,
          mainOrderTypeFk: this.shoppingCart.mainOrderTypeFK,
          priceListFk: this.shoppingCart.priceListFK,
          salesCampaignFk: this.shoppingCart.salesCampaignFK,
        };
        this.shoppingCart.cartType = 'R';
        this.updateCart(model);
      } else if (sessionStatus && this.shoppingCart && this.shoppingCart.cartType === 'R') {
        const model: MainCart = {
          cartType: 'LR',
          mainFk: this.shoppingCart.mainFK,
          mainReferrerFk: this.shoppingCart.mainReferrerFK,
          campaignCode: this.shoppingCart.campaignCode,
          culture: this.shoppingCart.culture,
          mainOrderTypeFk: this.shoppingCart.mainOrderTypeFK,
          priceListFk: this.shoppingCart.priceListFK,
          salesCampaignFk: this.shoppingCart.salesCampaignFK,
        };
        this.shoppingCart.cartType = 'LR';
        this.updateCart(model);
      }
      this.isRedemption = sessionStatus === 'true';
      return sessionStatus === 'true';
    } else {
      return this.webStorageService.getSession('loyalty-redemption') === 'true';
    }
  }

  public clearCartObject(reload: boolean = false): void {
    this.shoppingCart = undefined;
    if (reload) {
      this.init('', this.smartDeliveryService, this.defaultCorpSiteUrl).subscribe();
    }
  }

  public isLoggedIn(): Observable<boolean> {
    return this.authService.tokenChanged;
  }

  public clearRepInfo(): Observable<any> {
    const cartKey: string = this.webStorageService.getCookie('cartKey');
    return this.mainCartApiService.clearRepInfo(cartKey);
  }

  public openSmartDeliveryConfirmationDialog(confirmationModel: SmartDeliveryConfirmationModel): void {
    let dialogData = new SmartDeliveryConfirmationDialogDataModel();

    dialogData.title = (() => {
      switch (true) {
        case !!confirmationModel.nextSmartDeliveryDate:
          return '';
        case confirmationModel.succeed && !confirmationModel.notAllowImportCart:
          return this.translationService.translate(
            'shop',
            'sdConfirmationModalHeader',
            'Success! You added an item to your SmartDelivery.'
          );
        case !confirmationModel.succeed && !confirmationModel.notAllowImportCart:
          return this.translationService.translate('shop', 'activateYourSdAccount', 'Activate your smart delivery!');
        case confirmationModel.notAllowImportCart:
          return this.translationService.translate(
            'smartdelivery',
            'mix-order',
            'You may only add either regular cart products OR import cart products to your SmartDelivery order.'
          );
        default:
          return '';
      }
    })();

    dialogData.contentLine1 = (() => {
      switch (true) {
        case !!confirmationModel.nextSmartDeliveryDate:
          return this.translationService
            .translate(
              'shop',
              'alreadyIncludedInSmartDelivery',
              'This item is currently included in your SmartDelivery and scheduled to ship monthly on the {dayOfMonth} day of each month.'
            )
            .replace('{dayOfMonth}', new Date(confirmationModel.nextSmartDeliveryDate).getDate().toString());
        case !confirmationModel.succeed && !confirmationModel.notAllowImportCart:
          return (
            this.translationService.translate(
              'shop',
              'plsSetUpAccount',
              'Please set up your smart delivery payment method for your Account.'
            ) +
            '<br><br>' +
            this.translationService.translate(
              'shop',
              'plsActivateYourSdAccount',
              'If you already have your payment method set up, please go to your smart delivery settings to activate your smart delivery.'
            )
          );
        default:
          return '';
      }
    })();

    dialogData.contentLine2 = (() => {
      switch (true) {
        case (confirmationModel.succeed || confirmationModel.notAllowImportCart) && !confirmationModel.nextSmartDeliveryDate:
          return this.translationService.translate('shop', 'sdConfirmationModalDesc', 'What would you like to do now? a?');
        default:
          return '';
      }
    })();

    this.dialog.open(SmartDeliveryConfirmationComponent, { data: dialogData, direction: this.directionality });
  }

  public provideNewCart(referrer: string): void {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    this.mainCartApiService.deleteMainCart(cartKey).subscribe(() => {
      this.createNewCart(referrer, false).subscribe();
    });
  }

  private distinguishCartItem(product: ProductModel, toggleCart: boolean): void {
    let cartKey: string = this.webStorageService.getCookie('cartKey');
    let productConfig = product.selectedConfig;
    if (product && product.productPK > 0) {
      if (productConfig) {
        if (!product.isPackage) {
          const itemInCart: ShoppingCartItem = this.shoppingCart.cartItems.filter(
            (x) => x.productFk === product.productPK && x.configFk === productConfig[0].productPK
          )[0];

          if (itemInCart) {
            this.updateItemInCart(product, itemInCart, cartKey, toggleCart);
          } else {
            this.addConfiguredItemToCart(product, productConfig, cartKey, toggleCart);
          }
        } else {
          this.addPackage(product, cartKey, toggleCart);
        }
      } else {
        const itemInCart: ShoppingCartItem = this.shoppingCart.cartItems.find((x) => x.productFk === product.productPK);
        if (itemInCart) {
          this.updateItemInCart(product, itemInCart, cartKey, toggleCart);
        } else {
          this.addSimpleItemToCart(product, cartKey, toggleCart);
        }
      }
    }
  }

  private addPackage(product: ProductModel, cartKey: string, toggleCart: boolean): void {
    const items: MainCartItem[] = [];
    product.selectedConfig.forEach((config) => {
      let item: MainCartItem = {
        productFk: config.configurableProductPK,
        configFk: config.productPK === 0 ? undefined : config.productPK,
        packageFk: config.packageFK,
        quantity: product.quantity,
        setupForAs: product.setupForAs,
      };
      items.push(item);
    });
    this.mainCartItemApiService
      .addItems(cartKey, items)
      .pipe(
        tap(
          () => {},
          (error) => {
            this.mainCartStateService.isProductAdded.next(false);
            console.error(error);
          }
        )
      )
      .subscribe(() => {
        this.reloadCart(cartKey, toggleCart);
      });
  }

  private updateItemInCart(product: ProductModel, itemInCart: ShoppingCartItem, cartKey: string, toggleCart: boolean): void {
    itemInCart.quantity += product.quantity;

    let mainCartItemModel: MainCartItem = {
      mainCartItemsPk: itemInCart.id,
      mainCartFk: cartKey,
      productFk: itemInCart.productFk,
      setupForAs: product.setupForAs,
      quantity: itemInCart.quantity ? itemInCart.quantity : 1,
      priceListFk: product.priceListFk,
      extraFee: 0,
      discount: 0,
      configFk: itemInCart.configFk,
    };
    this.mainCartItemApiService
      .updateItem(cartKey, mainCartItemModel.mainCartItemsPk, mainCartItemModel)
      .pipe(
        tap(
          () => {},
          (error) => {
            this.mainCartStateService.isProductAdded.next(false);
            console.error(error);
          }
        )
      )
      .subscribe(() => {
        this.reloadCart(cartKey, toggleCart);
      });
  }

  private addConfiguredItemToCart(product: ProductModel, productConfig: ProductConfigModel[], cartKey: string, toggleCart: boolean): void {
    let mainCartItemModel: MainCartItem = {
      mainCartItemsPk: 0,
      mainCartFk: cartKey,
      productFk: product.productPK,
      setupForAs: product.setupForAs,
      quantity: product.quantity ? product.quantity : 1,
      priceListFk: product.priceListFk,
      extraFee: 0,
      discount: 0,
      configFk: productConfig[0].productPK,
    };

    this.mainCartItemApiService
      .addItem(cartKey, mainCartItemModel)
      .pipe(
        tap(
          () => {},
          (error) => {
            this.mainCartStateService.isProductAdded.next(false);
            console.error(error);
          }
        )
      )
      .subscribe(() => {
        this.reloadCart(cartKey, toggleCart);
      });
  }

  private addSimpleItemToCart(product: ProductModel, cartKey: string, toggleCart: boolean): void {
    let mainCartItemModel: MainCartItem = {
      mainCartItemsPk: 0,
      mainCartFk: cartKey,
      productFk: product.productPK,
      setupForAs: product.setupForAs,
      quantity: product.quantity ? product.quantity : 1,
      priceListFk: product.priceListFk,
      extraFee: 0,
      discount: 0,
      configFk: null,
    };

    this.mainCartItemApiService
      .addItem(cartKey, mainCartItemModel)
      .pipe(
        tap(
          () => {},
          (error) => {
            this.mainCartStateService.isProductAdded.next(false);
            console.error(error);
          }
        )
      )
      .subscribe(() => {
        this.reloadCart(cartKey, toggleCart);
      });
  }

  private createNewCart(referrerSiteUrl: string, validateReferrer: boolean): Observable<ShoppingCart> {
    let mainOrderTypeFK: number = 4;
    let loyaltySessionKey: string = this.webStorageService.getCookie('loyalty-redemption');
    let loyaltySession: string = loyaltySessionKey.replace(/"/g, '');
    let priceListFK: number = null;
    let mainFk: number;
    let cartType: string = loyaltySession ? 'LR' : 'R';
    if (this.authService.isLoggedIn) {
      let mainTypeId = this.authService.getMainTypeId();
      if (this.wholesaleCartMainTypes.includes(mainTypeId)) {
        cartType = 'W';
        // csUsers should be under corporate site
        if (this.customerServiceMainTypes.includes(mainTypeId)) {
          referrerSiteUrl = this.defaultCorpSiteUrl;
        } else {
          referrerSiteUrl = this.authService.getSiteUrl();
        }
      }
      mainFk = this.authService.getMainId();
    }

    let cartModel: MainCart = {
      mainFk: mainFk,
      cartType: cartType,
      countryCode: this.country || 'US',
      culture: this.culture || 'en-US',
      mainOrderTypeFk: mainOrderTypeFK,
      campaignCode: '',
      priceListFk: priceListFK,
      salesCampaignFk: null,
      gaCode: 'UA-127356256-2',
      facebookCode: '2163744457273898',
      luckyOrange: '130308',
      referrerSiteUrl: referrerSiteUrl,
      validateReferrer: validateReferrer,
    };
    return this.mainCartApiService.addMainCart(cartModel).pipe(map((cart: ShoppingCart) => {
      this.webStorageService.setCookie('cartKey', cart.cartKey);
      this.mainCartStateService.setMainCart(cart);
      return cart;
    }));
  }

  private reloadCart(cartKey: string, toggleCart: boolean = false): void {
    if (cartKey) {
      this.mainCartApiService.getMainCart(cartKey).subscribe((cart) => {
        this.mainCartStateService.setMainCart(cart, toggleCart);
      });
    }
  }

  private addItemToSD(product: ProductModel, smartDeliveryInfo: SmartDeliveryModel): void {
    let model: SmartDeliveryItemFormModel = {
      quantity: product.quantity,
      productFk: product.autoshipProductPk,
      agreedToTerms: smartDeliveryInfo.agreedToTerms,
      isImportItem: this.shoppingCart.cartType === 'I',
    };

    if ((product.isConfigurable || (product.isPackage && product.configurations.length > 0)) && product.selectedConfig) {
      const productPk: number =
        product.selectedConfig[0].packageFK === 0 ? product.selectedConfig[0].productPK : product.selectedConfig[0].packageFK;
      model.productFk = productPk;
      model.selectedConfig = [];

      product.selectedConfig.forEach((config) => {
        model.selectedConfig.push({
          configFk: config.productPK,
          productFk: config.configurableProductPK,
        });
      });
    }

    this.smartDeliveryService.addItem(model);
  }

  private getTranslations(): void {
    this.translationService.getTranslationCacheObservable().subscribe(() => {
      this.setTranslations();
    });
  }

  private setTranslations(): void {
    this.translations = {
      smartDeliveryWarn: this.translationService.translate('shop', 'smartDeliveryWarn', 'Could not add smart delivery item!'),
      loyaltyNotActive: this.translationService.translate('shop', 'loyalty-not-active', 'Sorry, your loyalty program is not active!'),
      loyaltyNotEnoughPoints: this.translationService.translate(
        'shop',
        'loyalty-not-enough-points',
        'Sorry, you do not have enough points for this product!'
      ),
      onlyOnePackage: this.translationService.translate(
        'jfrontGlobal',
        'onlyOnePackage',
        'You can only add one package of the same type at a time!'
      ),
    };
  }
}
