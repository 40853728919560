import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderService } from '../services/order.service';
import { DiscountResponse } from './interfaces/discount-response.interface';

@Component({
    selector: 'jn-discount-component',
    templateUrl: './discount.component.html',
    styleUrls: ['./discount.component.scss']
})
export class DiscountComponent {
    @Input() cartKey?: string;
    @Input() mainOrdersFk?: number;
    @Input() show: boolean;
    @Input() discountCode: string;
    @Output() isSubmitting: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    @Output() submitComplete: EventEmitter<DiscountResponse> = new EventEmitter<DiscountResponse>(null);
    submitting: boolean;

    constructor(private readonly orderService: OrderService){}

    public applyDiscount(): void {
        this.submitting = true;
        this.isSubmitting.emit(this.submitting);
        this.orderService.applyDiscount(this.discountCode, this.mainOrdersFk).subscribe(() => {
            this.submitting = false;
            this.isSubmitting.emit(this.submitting);
            this.submitComplete.emit({
                success: true,
                errorMsg: ''
            })
        },
        (error) => {
            this.submitting = false;
            this.isSubmitting.emit(this.submitting);
            this.submitComplete.emit({
                success: false,
                errorMsg: error
            })
        });
    }
}
