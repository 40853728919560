import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SmartDeliveryItemModel, SmartDeliverySettingsModel } from "../public-api";

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SmartDeliveryStateService {
  private smartDeliveryInfoObservable$: BehaviorSubject<SmartDeliverySettingsModel> = new BehaviorSubject<SmartDeliverySettingsModel>(new SmartDeliverySettingsModel({}));
  private smartDeliveryItemsObservable$: BehaviorSubject<SmartDeliveryItemModel[]> = new BehaviorSubject<SmartDeliveryItemModel[]>([]);
  private itemsState: SmartDeliveryItemModel[] = [] ;
  constructor(){}

  public getSmartDeliveryInfo$(): Observable<SmartDeliverySettingsModel> {
    return this.smartDeliveryInfoObservable$.asObservable();
  }

  public getSmartDeliveryItems$(): Observable<SmartDeliveryItemModel[]> {
    return this.smartDeliveryItemsObservable$.asObservable();
  }

  public setSmartDeliveryInfo(settings: SmartDeliverySettingsModel): void {
    this.smartDeliveryInfoObservable$.next(new SmartDeliverySettingsModel(settings));
  }

  public setSmartDeliveryItems(items: SmartDeliveryItemModel[]): void {
    this.itemsState = _.clone(items);
    this.smartDeliveryItemsObservable$.next(this.itemsState);
  }

  public updateSmartDeliveryItem(item: SmartDeliveryItemModel): void {
    if (this.itemsState.some(x => x.productPK === item.productPK)) {
      this.itemsState = this.itemsState.map(x => x.productPK === item.productPK ? item : x);
    } else {
      this.itemsState.push(item);
      this.itemsState = _.clone(this.itemsState);
    }
    this.smartDeliveryItemsObservable$.next(this.itemsState);
  }

  public removeSmartDeliveryItem(autoshipOrderItemsPK: number): void {
    this.itemsState = this.itemsState.filter(x => x.autoshipOrderItemsPK !== autoshipOrderItemsPK);
    this.smartDeliveryItemsObservable$.next(this.itemsState);
  }
}
