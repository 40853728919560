import { Injectable, ValueProvider } from "@angular/core";
import { IAppSettings } from "./models/app-settings.interface";
import { ClientInfo } from './models/client-info.interface';

/**
 * @ignore
 */
declare const AppSettings: IAppSettings;

@Injectable({
  providedIn: 'root'
})
export class ClientInfoService {
  private clientInfo: ClientInfo;
  constructor() {
    this.loadClientInfo();
  }

  public getClientInfo(): ClientInfo {
    return this.clientInfo;
  }

  // ToDo: Load from a db/api
  private loadClientInfo(): void {
    const client: string = AppSettings.client || 'T01';
    this.clientInfo = {
      key: client,
      name: (client === 'T02') ? 'SuperbSibs' : 'Jeunesse',
      logoUrl: `../../assets/images/${client}.png`,
      s3BackOfficeFolderName: (client === 'T02') ? 'Backoffice' : 'JOffice',
      s3FrontFolderName: (client === 'T02') ? 'Front' : 'JFront',
      s3BucketName: (client === 'T02') ? 'media-t02' : 'media.jeunesseglobal.com',
      s3Url: (client === 'T02') ? 'https://d2lo9zp6ftxpmj.cloudfront.net' : 'https://d2dqksvx8j00ig.cloudfront.net',
    };
  }
}
