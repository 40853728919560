import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderState } from './loader-state.interface';
import { LoaderService } from './loader.service';
import { SubSink } from 'subsink';

@Component({
    selector: 'jn-loader',
    templateUrl: './loader.component.html',
    // styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit, OnDestroy {
    /**
     * Show/hide loader
     */
    public show: boolean;

    private subs = new SubSink();

    constructor(private readonly loaderService: LoaderService) { }

    public ngOnInit(): void {
        this.subs.sink = this.loaderService.loaderState.subscribe((state: LoaderState) => {
            this.show = state.show;
        });
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
