import { Injectable } from '@angular/core';
import { AuthHttpService, AuthService, UrlHelperService } from '@jeunesse/angular';
import { Observable, of } from 'rxjs';

import { CategoryModel } from '../../products/models/category/category.model';
import { CategoryService } from '../../products/public-api';
import { MenuAlertModel } from '../../shared/public-api';
import { JetMenuItem } from '../models/jet-menu-item.model';

/**
 * This class is the in between for components and updating the payment state and api service.
 */
@Injectable({
    providedIn: 'root',
})
export class MenuApiService {
    constructor(
        private readonly authService: AuthService,
        private readonly authHttp: AuthHttpService,
        private readonly urlHelper: UrlHelperService,
        private readonly categoryService: CategoryService
    ) {}
    /**
     * Returns the JET menu items for the current application
     */
    public getBackofficeMenu(): Observable<JetMenuItem> {
        if (this.authService.isLoggedIn) {
            return this.authHttp.get<JetMenuItem>(this.urlHelper.toSecurityApi(`/v1/menu/backoffice`)).pipe();
        }
        return of(new JetMenuItem({ menuItems: [] }));
    }

    public getUserMenu(): Observable<JetMenuItem[]> {
        if (this.authService.isLoggedIn) {
            return this.authHttp.get<JetMenuItem[]>(this.urlHelper.toSecurityApi(`/v1/menu/user`)).pipe();
        }
        return of([]);
    }

    public getCategoryMenus(country: string, culture: string, cartType: string, hardReload: boolean = false): Observable<CategoryModel[]> {
        return this.categoryService.getCategories(country, culture, cartType, hardReload);
    }

    public getMenuAlert(): Observable<MenuAlertModel[]> {
        return this.authHttp.get<MenuAlertModel[]>(this.urlHelper.toProfileApi('/v1/member/alertCheck'));
    }

    public getMenuUnreadCounts(): Observable<MenuAlertModel[]> {
        return this.authHttp.get<MenuAlertModel[]>(this.urlHelper.toProfileApi('/v1/member/unread-counts'));
    }

    public checkIsBonusCreditsGoingToExpire(daysCount: number): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toProfileApi(`/v1/member/bonus-credits-going-to-expire/${daysCount}`));
    }
}
