import { AuthHttpService, AuthService } from '@jeunesse/angular';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { WebStorageService, UrlHelperService, SeoService, LoggerService, LoggerInterface, LoggerTypeEnum } from '@jeunesse/angular';
import { ProductModel, ProductConfigModel, ProductPriceDisplayModel } from '../../products/models/public-api';
import { ProductService } from '../../products/services/product.service';
import { LoyaltyPointsModel, SmartDeliveryConfirmationModel, SmartDeliveryItemFormModel, SmartDeliveryService } from '../../member/public-api';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { ShoppingCart, ShoppingCartItem } from '@jeunesse/slide-cart';
import { CartFormModel } from '../models/public-api';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { ProductComponent } from '../../products/components/product/product.component';
import { TranslationService, LocaleService, CmsSessionService } from '@jeunesse/angular';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigModalComponent } from '../../products/components/configuration/config-modal.component';
import { SmartDeliveryConfirmationComponent } from '../../products/components/smartdelivery/sd-confirmation.component';
import { Direction } from '@angular/cdk/bidi';

@Injectable({
    providedIn: 'root'
})
/**
 * Class for working with the Jeunesse Side-Cart Module
 */
export class ShoppingCartService {
    protected shoppingCartSubject$ = new BehaviorSubject<ShoppingCart>(null);
    public shoppingCartObservable$: Observable<ShoppingCart> = this.shoppingCartSubject$.asObservable();
    protected cartUpdateSubject$ = new BehaviorSubject<boolean>(true);
    public cartUpdateObservable$: Observable<boolean> = this.cartUpdateSubject$.asObservable();
    public shoppingCart: ShoppingCart;
    public loyaltyPointsInfo: LoyaltyPointsModel;
    protected textEntered: boolean;
    public readonly productComponent: ProductComponent;
    protected productTranslationSubject$: BehaviorSubject<ProductModel> = new BehaviorSubject<ProductModel>(new ProductModel(null));
    public getTranslationsObservable$: Observable<ProductModel> = this.productTranslationSubject$.asObservable();
    public translations: any = {
        qty: '',
        smartDeliveryWarn: '',
        loyaltyNotActive: '',
        loyaltyNotEnoughPoints: '',
        onlyOnePackage: ''
    };
    private cart: any;
    private productLimit: number = 25;
    private directionality: Direction = 'ltr';

    constructor(
        private readonly route: ActivatedRoute,
        private readonly webStorageService: WebStorageService,
        private readonly authHttpService: AuthHttpService,
        private readonly urlHelper: UrlHelperService,
        private readonly authService: AuthService,
        private readonly productService: ProductService,
        protected readonly seoService: SeoService,
        protected readonly smartDeliveryService: SmartDeliveryService,
        protected readonly loggerService: LoggerService,
        protected readonly translationService: TranslationService,
        protected readonly localeService: LocaleService,
        protected readonly cmsSessionService: CmsSessionService,
        protected readonly dialog: MatDialog
    ) {
        this.productService.addToCartObservable$.subscribe((product: ProductModel) => {
            this.addProductToCart(product, product.selectedConfig);
        });
        this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
        this.getTranslations();
    }

    /**
     * Initializes the service.
     */
    public async init(): Promise<any> {
        const target: HTMLElement = document.body;
        const userTrackingCodes: any = {
            gaCode: 'UA-127356256-2',
            facebookCode: '2163744457273898',
            luckyOrange: '130308'
        };
        //const sidecart: any = new scm.SideCart({
        //    appSettings: AppSettings,
        //    redirect: false,
        //    userTrackingCodes: userTrackingCodes
        //});
        //return sidecart.init(target).then(res => {
        //    let response: SideCart = res as SideCart;
        //    this.cart = response._el;
        //    return this.cart;
        //});
    }

    /**
     * Adds or updates a product in the user's cart.
     * @param product Product model item
     */
    public add(
        product: ProductModel,
        productConfig: ProductConfigModel[],
        lp: LoyaltyPointsModel
    ): string {
        product.quantity = this.checkItemQuantity(product.quantity || 1, product.maxLimit);

        // Check if we are redeeming an item for loyaltyPoints.
        if (this.isStoreInRedemptionMode) {
            if (!lp.active || !lp.canRedeemPoints) {
                return 'loyaltyNotActive';
            }
            const pointsNeeded: number = product.points * product.quantity;
            if (pointsNeeded > lp.loyaltyPointsBalance) {
                return 'loyaltyNotEnoughPoints';
            }
            // Check if the user has enough points to add item.
            // Get Cart
            if (this.cart && this.cart.products) {
                let pointsInCart: number = 0;
                this.cart.products.forEach((item: ShoppingCartItem) => {
                    pointsInCart += item.points * item.quantity;
                });
                if (pointsInCart + pointsNeeded > lp.loyaltyPointsBalance) {
                    return 'loyaltyNotEnoughPoints';
                }
                this.addProductToCart(product, productConfig);
                this.clearCartObject(true);
                return '';
            }
        } else {
            this.addProductToCart(product, productConfig);
            this.clearCartObject(true);
            return '';
        }
    }

    /**
     * Updates the main cart info for the user.
     * @param model Cart model item
     */
    public async updateCart(model: CartFormModel, clearItems: boolean = false): Promise<any> {
        return this.cart.service.updateCart(
            model.mainFk,
            model.cartType,
            model.mainReferrerFk,
            model.campaignCode,
            model.culture,
            model.mainOrderTypeFk,
            model.priceListFk,
            model.salesCampaignFk,
            model.referrerSiteUrl,
            model.customerReferralId
        );
        // .then(cart => {
        //     // Get updated cart
        //     this.clearCartObject();
        //     if (clearItems) {
        //         this.clearCartItems().then(() => {});
        //     }
        // });
    }

    /**
     * Returns a list of products in the cart.
     */
    public get(): any {
        return this.cart.products;
    }

    /**
     * Hides the side-cart panel.
     */
    public hideCart(): void {
        if (this.cart) {
            this.cart.hide = true;
        }
    }

    /**
     * Shows the side-cart panel.
     */
    public showCart(): void {
        if (this.cart) {
            this.cart.hide = false;
        }
    }

    /**
     * Opens the side-cart panel.
     */
    public openCart(): void {
        if (this.cart) {
            this.cart.open = true;
        }
    }

    /**
     * Deletes and clears the existing shopping cart.
     */
    public clearCart(retry: number = 3): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.cart
                .clear()
                .then(success => {
                    resolve(success);
                })
                .catch(error => {
                    if (retry === 0) return reject(error);
                    this.clearCart(retry - 1)
                        .then(resolve)
                        .catch(reject);
                });
        });
    }

    /**
     * Legacy support for old cart parameters passed by 3rd party vendors.
     */
    public checkQueryStringForCart(): void {
        this.route.queryParams.subscribe((params: Params) => {
            let cart: string = params['cart'];
            if (cart && cart.length > 0) {
                const items: string[] = cart.split('|');
                for (let item of items) {
                    const pk: number = +item.split(':')[0];
                    const qty: number = +item.split(':')[1];

                    // this.productService.product(pk).subscribe((p) => {
                    //     if (qty > 0) {
                    //         p.quantity = qty;
                    //         this.add(p, null);
                    //     }
                    // });
                }
            }
        });
    }

    /**
     * Checks the query string (cartKey) and loads a users cart.
     *  not used!?!
     */
    public checkQueryStringForCartKey(): Observable<string> {
        return this.route.queryParams.pipe(map((params: Params) => {
            let cartKey: string = params['cartKey'];
            if (cartKey && cartKey.length > 0) {
                // Set cookie
                this.webStorageService.setCookie('cartKey', cartKey);
                // Reload Cart
                this.clearCartObject(true);
                return cartKey;
            }
        }));
    }

    /**
     * Checks that the item quantity is not invalid, too high, or too low
     * @param qty - the quantity to check
     * @param limit - the quantity limit for the product
     */
    public checkItemQuantity(qty, limit): number {
        if (isNaN(qty)) {
            return 1;
        }
        if (limit && limit > 0 && limit < this.productLimit) {
            this.productLimit = limit;
        }
        if (qty > this.productLimit) {
            return this.productLimit;
        } else if (qty < 1) {
            return 1;
        } else {
            return Math.floor(qty);
        }
    }

    /**
     * Used to patch a shopping cart object.
     * @param shoppingCart Shopping Cart Object
     */
    public patchShoppingCart(shoppingCart: any): Promise<ShoppingCart> {
        if (this.cart.service) {
            return this.cart.service.patchCart(shoppingCart).then((cart) => {
                if (cart && cart.cartItems) {
                    this.shoppingCartSubject$.next(cart);
                }
            });
        } else {
            const cartKey: string = this.webStorageService.getCookie('cartKey');
            return this.authHttpService.patch<ShoppingCart>(this.urlHelper.toCartApi(`/v1/shopping/${cartKey}`), shoppingCart)
                .pipe(
                    map((cart: ShoppingCart) => {
                if (cart && cart.cartItems) {
                    this.shoppingCartSubject$.next(cart);
                }
                return cart;
            })).toPromise();
        }
    }

    /**
     * Gets the shopping cart object.
     */
    public getShoppingCart(): Observable<ShoppingCart> {
        const cartKey: string = this.webStorageService.getCookie('cartKey');
        return this.authHttpService
            .get<ShoppingCart>(this.urlHelper.toCartApi(`/v1/shopping/${cartKey}`))
            .pipe(
                map((res: ShoppingCart) => {
                    if (!res.culture) {
                        let cultureCookie: string = decodeURIComponent(this.webStorageService.getCookie('Culture')).replace('"', '').replace('"', '');
                        if (cultureCookie === '' || cultureCookie === undefined) {
                            res.culture = 'en-US';
                        } else {
                            res.culture = cultureCookie;
                        }
                    }
                    this.shoppingCart = res;
                    this.shoppingCartSubject$.next(this.shoppingCart);
                    return res;
                })
            );
    }

    /**
     * Used to remove the rep info from the current cart item.
     */
    public clearRepInfo(): Observable<any> {
        const cartKey: string = this.webStorageService.getCookie('cartKey');
        return this.authHttpService
            .delete<any>(this.urlHelper.toCartApi(`/v1/shopping/${cartKey}/clear-referrer`))
            .pipe();
    }

    /**
     * Checks if the site is in a loyalty redemption mode.
     */
    public get isStoreInRedemptionMode(): boolean {
        if (this.shoppingCart) {
            if (!this.authService.isLoggedIn) {
                this.webStorageService.removeSession('loyalty-redemption');
                const model: CartFormModel = {
                    cartType: 'R',
                    mainFk: this.shoppingCart.mainFK,
                    mainReferrerFk: this.shoppingCart.mainReferrerFK,
                    campaignCode: this.shoppingCart.campaignCode,
                    culture: this.shoppingCart.culture,
                    mainOrderTypeFk: this.shoppingCart.mainOrderTypeFK,
                    priceListFk: this.shoppingCart.priceListFK,
                    salesCampaignFk: this.shoppingCart.salesCampaignFK
                };
                this.updateCart(model);
                return false;
            }
            const sessionStatus: boolean =
                this.webStorageService.getSession('loyalty-redemption') === 'true';
            if (!sessionStatus && (this.shoppingCart && this.shoppingCart.cartType === 'LR')) {
                const model: CartFormModel = {
                    cartType: 'R',
                    mainFk: this.shoppingCart.mainFK,
                    mainReferrerFk: this.shoppingCart.mainReferrerFK,
                    campaignCode: this.shoppingCart.campaignCode,
                    culture: this.shoppingCart.culture,
                    mainOrderTypeFk: this.shoppingCart.mainOrderTypeFK,
                    priceListFk: this.shoppingCart.priceListFK,
                    salesCampaignFk: this.shoppingCart.salesCampaignFK
                };
                this.shoppingCart.cartType = 'R';
                this.updateCart(model, true);
            } else if (sessionStatus && (this.shoppingCart && this.shoppingCart.cartType === 'R')) {
                const model: CartFormModel = {
                    cartType: 'LR',
                    mainFk: this.shoppingCart.mainFK,
                    mainReferrerFk: this.shoppingCart.mainReferrerFK,
                    campaignCode: this.shoppingCart.campaignCode,
                    culture: this.shoppingCart.culture,
                    mainOrderTypeFk: this.shoppingCart.mainOrderTypeFK,
                    priceListFk: this.shoppingCart.priceListFK,
                    salesCampaignFk: this.shoppingCart.salesCampaignFK
                };
                this.shoppingCart.cartType = 'LR';
                this.updateCart(model, true);
            }
            return sessionStatus;
        } else {
            return this.webStorageService.getSession('loyalty-redemption') === 'true';
        }
    }

    /**
     * Clears the cart object
     * @param reload Indicate if the cart should reload
     */
    public clearCartObject(reload: boolean = false): void {
        this.shoppingCart = undefined;
        if (reload) {
            this.getShoppingCart().subscribe();
        }
    }

    /**
     * Clears the items from the cart
     */
    public async clearCartItems(): Promise<any> {
        const cartKey: string = this.webStorageService.getCookie('cartKey');
        if (this.cart.service) {
            return this.cart.service.removeAllItems(cartKey).then(success => {
                if (success) {
                    // Reload
                    return this.cart.service.getCartDetails(cartKey).then(cart => {
                        return this.cart.service.get(cartKey).then(products => {
                            if (this.cart.refs.list.props) {
                                this.cart.refs.list.props.products = products;
                            }
                            this.cart.refs.list.render(products);
                            this.cart.refresh();
                            this.clearCartObject(true);
                        });
                    });
                }
            });
        } else {
            return new Promise((resolve) => {
                resolve({});
            });
        }
    }

    /**
     * Returns the correct cart type based on the logged in user.
     */
    public getCartType(): string {
        let cartType: string = 'R';
        if (this.authService.isLoggedIn) {
            const supportedMainTypes: number[] = [1, 22, 3, 6];
            const mainTypeId: number = this.authService.getMainTypeId();
            if (supportedMainTypes.includes(mainTypeId)) {
                cartType = 'W';
            }
        }
        if (this.webStorageService.getSession('loyalty-redemption') === 'true') {
            cartType = 'LR';
        }
        return cartType;
    }

    //
    // Begin: Calls used by the new signup project
    // ToDo: This should be removed.
    public removeProductsFromCart(productPk: number): Observable<void> {
        let cartKey: string = this.webStorageService.getCookie('cartKey');
        return this.authHttpService.delete<void>(this.urlHelper.toCartApi(`/v1/shopping/${cartKey}/items/${productPk}`));
    }

    public removeListOFProductsFromCart(mainCartItems: any): Observable<any> {
        let cartKey: string = this.webStorageService.getCookie('cartKey');
        return this.authHttpService.delete<any>(this.urlHelper.toCartApi(`/v1/shopping/${cartKey}/items/list?${mainCartItems}`));
    }

    public createCart(cart: any): Observable<any> {
        return this.authHttpService.post<any>(this.urlHelper.toCartApi('/v1/shopping/'), cart)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }

    public addItem(cartKey: string, item: any): Observable<any> {
        return this.authHttpService.post<any>(this.urlHelper.toCartApi(`/v1/shopping/${cartKey}/items`), item).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public applyDiscount(discountCode: string, mainOrdersPK: number): Observable<any> {
        const cartKey: string = this.webStorageService.getCookie('cartKey');
        const cartDiscountFormModel: any = { DiscountCode: discountCode, MainOrdersPK: mainOrdersPK };
        if (cartKey) {
            return this.authHttpService.put<any>(this.urlHelper.toCartApi(`/v1/shopping/${cartKey}/apply-discount`), cartDiscountFormModel).pipe();
        } else {
            return of(undefined);
        }
    }

    public addItems(products: any[]): void {
        const signupPack: ProductModel = this.cart.products.filter(x => x.product && x.product.productMenu === 'Signup packages')[0];
        if (signupPack) {
            this.cartUpdateSubject$.next(true);
            return;
        }
        this.cart.add(products).then(x => { this.cartUpdateSubject$.next(true); });
    }
    public undoAddToCartButton(result: boolean): void {
        this.cartUpdateSubject$.next(result);
    }

    public addCAPItem(cartKey: string, item: any): Observable<any> {
        return this.authHttpService.post<any>(this.urlHelper.toCartApi(`/v1/shopping/${cartKey}/items/list`), item).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    /**
     * Returns a product display model to use on the page display.
     * @param product Product Model
     */
    public getPricingDisplay(product: ProductModel, cartType: string): ProductPriceDisplayModel {
        const priceDisplay: ProductPriceDisplayModel = new ProductPriceDisplayModel();
        // Get Pricing
        priceDisplay.currencySymbol = product.pricing[0].currencySymbol;

            priceDisplay.price = this.getPricing(product, cartType);
            priceDisplay.smartDeliveryPrice = this.getPricing(product, 'W');
        return priceDisplay;
    }

    /**
     * Adds a product to the user's cart.
     */
    public addToCart(product: ProductModel, smartDeliveryInfo: any, addToSmartDelivery: boolean = false,
        loyaltyPointsInfo: any = undefined): void {
        product.quantity = product.quantity ? product.quantity : 1;

        // Check for loyalty redemption points
        if (loyaltyPointsInfo && this.getCartType() === 'LR') {
            const cartItems = this.get();
            let totalPoints: number = 0;
            if (cartItems) {
                cartItems.forEach((item) => {
                    totalPoints = totalPoints + (item.quantity * item.product.points);
                });
            }
            // Add the selected product points to the cart total points.
            totalPoints = totalPoints + product.points;
            if (loyaltyPointsInfo.active !== true && loyaltyPointsInfo.canRedeemPoints !== true) {
                const log: LoggerInterface = { logType: LoggerTypeEnum.WARN, message: this.translations.loyaltyNotActive };
                this.loggerService.log(log);
                return;
            } else if (loyaltyPointsInfo.loyaltyPointsBalance < totalPoints) {
                const log: LoggerInterface = { logType: LoggerTypeEnum.WARN, message: this.translations.loyaltyNotEnoughPoints };
                this.loggerService.log(log);
                return;
            }
        }


        if (!addToSmartDelivery) {
            this.sendGAInfo(product, 'add to cart');
            if ((product.isConfigurable || (product.isPackage && product.configurations.length > 0)) && !product.selectedConfig) {
                // Show Configuration Dialog
                const dialogRef: MatDialogRef<ConfigModalComponent> = this.dialog.open(ConfigModalComponent, {
                    data: product,
                    direction: this.directionality
                });

                dialogRef.afterClosed().subscribe((res: ProductConfigModel[]) => {
                    if (res) {
                        product.selectedConfig = res;
                        this.productService.addProductToCart(product);
                        product.selectedConfig = null;
                    }
                    else {
                        this.undoAddToCartButton(true);
                    }
                });
            } else {
                this.productService.addProductToCart(product);
            }
        } else {
            // Check SD Profile if Active add otherwise show popup.
            if (smartDeliveryInfo.active && smartDeliveryInfo.isPaymentSetup) {
                if ((product.isConfigurable || (product.isPackage && product.configurations.length > 0)) && !product.selectedConfig) {
                    // Show Configuration Dialog
                    product.addToSmartDelivery = true;
                    product.selectedSDConfig = new Array<ProductConfigModel>();
                    product.configurations.forEach((config: ProductConfigModel) => {
                        if (smartDeliveryInfo.smartDeliveryItems.filter(x => x.productPK === config.productPK)[0]) {
                            product.selectedSDConfig.push(config);
                        }
                    });

                    const dialogRef: MatDialogRef<ConfigModalComponent> = this.dialog.open(ConfigModalComponent, {
                        data: product,
                        direction: this.directionality
                    });

                    dialogRef.afterClosed().subscribe((res: ProductConfigModel[]) => {
                        if (res) {
                            product.selectedConfig = res;
                            this.addItemToSD(product, smartDeliveryInfo);
                            product.selectedConfig = null;
                        }
                        product.addToSmartDelivery = false;
                    });
                } else {
                    this.addItemToSD(product, smartDeliveryInfo);
                }
            } else {
                let data: SmartDeliveryConfirmationModel = {
                    value: false,
                    notAllowImportCart: true
                };
                const dialogRef: any = this.dialog.open(SmartDeliveryConfirmationComponent, { data, direction: this.directionality });
                dialogRef.afterClosed().subscribe();
            }
        }
    }

    /**
     * Checks if item is on Smart Delivery
     */
    public isItemInSD(product: ProductModel, smartDeliveryInfo: any): boolean {
        if (!smartDeliveryInfo.isPaymentSetup) return false;

        if (product.isConfigurable) {
            let hasItem: boolean = false;
            product.configurations.forEach((config: ProductConfigModel) => {
                if (smartDeliveryInfo.smartDeliveryItems.filter(x => x.productPK === config.productPK)[0]) {
                    hasItem = true;
                }
            });
            return hasItem;
        }
        return smartDeliveryInfo.smartDeliveryItems.filter(x => x.productPK === product.autoshipProductPk)[0] ? true : false;
    }

    /**
     * Sends Google Analytic track clicking information.
     */
    public sendGAInfo(product: ProductModel, action: string): void {
        this.seoService.trackClick(product.name, action);
    }

    /**
     * Remove slide cart for JeunesseKid donation
     */
    public removeJKDonation(maincartItemsPK): any {
        return this.cart.remove(maincartItemsPK).then(x => { return x });
    }

    private waitForCart(condition: any, callback: any): void {
        if (!condition) {
            window.setTimeout(this.waitForCart.bind(null, condition, callback), 100);
        } else {
            callback();
        }
    }

    private addProductToCart(product: ProductModel, productConfig: ProductConfigModel[]): void {
        if (product && product.productPK > 0) {
            if (productConfig) {
                // Add Configurable Product
                if (!product.isPackage) {
                    const itemInCart: ProductModel = this.cart.products.filter(
                        x =>
                            x.productFk === product.productPK
                            &&
                            x.configPK === productConfig[0].productPK
                    )[0];
                    if (itemInCart) {
                        itemInCart.quantity += product.quantity;
                        itemInCart.quantity = this.checkItemQuantity(
                            itemInCart.quantity,
                            this.productLimit
                        );
                        itemInCart.setupForAs = product.setupForAs;
                        this.cart.update(itemInCart).then(x => { this.cartUpdateSubject$.next(true); });
                    } else {
                        const item: any = {
                            productFK: product.productPK,
                            configFK: productConfig[0].productPK,
                            quantity: product.quantity,
                            setupForAs: product.setupForAs
                        };
                        this.cart.add(item).then(x => { this.cartUpdateSubject$.next(true); });
                    }
                } else {
                    const itemInCart: any = this.cart.products.filter(x => x.packageFk === product.selectedConfig[0].packageFK)[0];

                    if (itemInCart) {
                        // itemInCart.quantity += product.quantity;
                        // itemInCart.quantity = this.checkItemQuantity(
                        //     itemInCart.quantity,
                        //     this.productLimit
                        // );
                        // itemInCart.setupForAs = product.setupForAs;
                        // this.cart.update(itemInCart).then(x => { this.cartUpdateSubject$.next(true); });
                        const logInfo: LoggerInterface = { logType: LoggerTypeEnum.WARN, message: this.translations.onlyOnePackage };
                        this.loggerService.log(logInfo);
                    } else {
                        const items: any = [];
                        _.forEach(product.selectedConfig, ((config, key) => {
                            let item: any = {
                                productFK: config.configurableProductPK,
                                configFK: (config.productPK === 0) ? undefined : config.productPK,
                                packageFK: config.packageFK,
                                quantity: product.quantity,
                                setupForAs: product.setupForAs
                            };
                            items.push(item);
                        }));
                        this.cart.add(items).then(x => { this.cartUpdateSubject$.next(true); });
                    }
                }
            } else {
                const itemInCart: ProductModel = this.cart.products.filter(
                    x => x.productFk === product.productPK
                )[0];
                if (itemInCart) {
                    itemInCart.quantity += product.quantity;
                    itemInCart.quantity = this.checkItemQuantity(
                        itemInCart.quantity,
                        this.productLimit
                    );
                    itemInCart.setupForAs = product.setupForAs;
                    this.cart.update(itemInCart).then(x => { this.cartUpdateSubject$.next(true); });
                } else {
                    if (product.productMenu === 'Signup packages') {
                        const signupPack: ProductModel = this.cart.products.filter(x => x.product &&
                            (x.product.productMenu === 'Signup packages'
                                || x.isPaCItem === true))[0];
                        if (signupPack) {
                            this.cartUpdateSubject$.next(true);
                            return;
                        }

                    }

                    const item: any = {
                        productFK: product.productPK,
                        quantity: product.quantity,
                        setupForAs: product.setupForAs
                    }
                    this.cart.add(item).then(x => {
                        if (x) {
                            this.cartUpdateSubject$.next(true);
                        }
                    });
                }
            }
        }
    }

    private sendQuantityChange(product: ProductModel, quantity: number, updatedQty: number): void {
        const qty: number = Number(updatedQty);
        if (!this.textEntered) {
            if (qty > quantity) {
                this.sendGAInfo(product, 'increase qty');
            } else if (qty < quantity) {
                this.sendGAInfo(product, 'decrease qty');
            }
        } else {
            this.sendGAInfo(product, 'qty - text entered');
        }
        this.textEntered = false;
    }

    private getPricing(product: ProductModel, priceType: string): string {
        const item: any = product.pricing.filter(x => x.priceType === priceType)[0];
        if (item) {
            return `${item.formattedPrice}`;
        }
        return '';
    }

    private addItemToSD(product: ProductModel, smartDeliveryInfo: any): void {
        let model: SmartDeliveryItemFormModel = { quantity: product.quantity, productFk: product.autoshipProductPk, agreedToTerms: smartDeliveryInfo.agreedToTerms };

        if ((product.isConfigurable || (product.isPackage && product.configurations.length > 0)) && product.selectedConfig) {
            const productPk: number = product.selectedConfig[0].packageFK === 0 ? product.selectedConfig[0].productPK : product.selectedConfig[0].packageFK;
            model.productFk = productPk;
            model.selectedConfig = [];

            product.selectedConfig.forEach(config => {
                model.selectedConfig.push({
                    configFk: config.productPK,
                    productFk: config.configurableProductPK
                });
            });
        }


        this.smartDeliveryService.addItem(model);
        // Show smart delivery confirmation dialog
        let data: SmartDeliveryConfirmationModel = {
            value: true,
            notAllowImportCart: false
        };
        const dialogRef: any = this.dialog.open(SmartDeliveryConfirmationComponent, { data, direction: this.directionality });
        dialogRef.afterClosed().subscribe();
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        // Load Translations
        this.translations = {
            qty: this.translationService.translate('shoppingCart', 'qty', 'Qty'),
            smartDeliveryWarn: this.translationService.translate('shop', 'smartDeliveryWarn', 'Could not add smart delivery item!'),
            loyaltyNotActive: this.translationService.translate('shop', 'loyalty-not-active', 'Sorry, your loyalty program is not active!'),
            loyaltyNotEnoughPoints: this.translationService.translate('shop', 'loyalty-not-enough-points', 'Sorry, you do not have enough points for this product!'),
            onlyOnePackage: this.translationService.translate('jfrontGlobal', 'onlyOnePackage', 'You can only add one package of the same type at a time!')
        };

        this.productTranslationSubject$.next(this.translations);
    }
}
