import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';

import { AuthService } from '../../authentication/src/auth.service';

import { SecurityService } from './security.service';


/**
 * Loads the policies for the page into memory. Will fail if missing the 'data' property on the route.
 */
@Injectable()
export class SecurityResolver implements Resolve<boolean> {
    constructor(
        private readonly authService: AuthService,
        private readonly securityService: SecurityService) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        if (!route.data['policy']) {
            console.error('SecurityResolver was used without a policy defined.');

            return of(false);
        }

        // We can only load policies if the user is logged in.
        if (this.authService.isLoggedIn) {
            return this.securityService.loadPolicies(route.data['policy']);
        }

        return of(true);
    }
}