
/**
 * referrer model
 */
export class ReferrerModel {
    public country: string;
    public languageFk: number;
    public culture: string;
    public flow: string;
    public sponsorType: string;
    public firstName: string;
    public lastName: string;
    public statusFK: string;
    public mainFK?: number;


    constructor(options: any = {}) {
        this.mainFK = options.mainFK;
        this.country = options.country;
        this.languageFk = options.languageFk;
        this.culture = options.culture;
        this.flow = options.flow;
        this.sponsorType = options.sponsorType;
        this.firstName = options.firstName;
        this.lastName = options.lastName;
        this.statusFK = options.statusFK;
    }
}