import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ESignatureViewModel } from "../../interfaces/eSignature-view.interface";
import { ESignaturePostModel } from "../../public-api";
import { MainUsersService } from "../../services/main-user.service";
import { CmsSessionService, CompanyCountry, TranslationService } from "../../../../cms";

@Component({
  selector: 'jn-eSignature-modal',
  templateUrl: './eSignature-modal.component.html',
  styleUrls: ['./eSignature-modal.component.scss']
})
export class ESignatureModalComponant implements OnInit {
  public info: ESignatureViewModel;
  public eSignatureForm: FormGroup;
  public error: string;
  public passwordHint: string = ''; // #46315
  constructor(private readonly dialogRef: MatDialogRef<ESignatureModalComponant>,
    private readonly cmsSessionService: CmsSessionService,
    private readonly translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: ESignatureViewModel,
    private readonly formBuilder: FormBuilder,
    private readonly mainUsersService: MainUsersService) {
      this.info = data;
      this.eSignatureForm = this.formBuilder.group({
        reqDefKey: ['', [Validators.required]],
        password: ['', [Validators.required]]
      })
    }

    public ngOnInit(): void {
      this.passwordHint = this.translationService.translate('user-account', 'error-current-password', 'Please enter your current password.'); // #46315
      this.cmsSessionService.GetCountryInfo()
        .subscribe((companyCountry: CompanyCountry) => {
          if (companyCountry && companyCountry.securityQuestionsRequired) {
            this.passwordHint = this.translationService.translate('user-account', 'emailedpassword', 'Your password has been emailed to you.');
          }
      });
    }

    public closeDialog(): void {
      this.dialogRef.close();
    }

    public onFormSubmit(): void {
      this.error = '';
      if (!this.eSignatureForm.valid) return;
      var postModel: ESignaturePostModel = {
        mainPK: this.data.mainPK,
        country: this.data.country,
        userKey: this.eSignatureForm.get('password').value,
        reqDefKey: this.eSignatureForm.get('reqDefKey').value
      };
      this.mainUsersService.setESignatureInfo(postModel)
      .subscribe((resp: ESignatureViewModel) => {
        this.dialogRef.close(resp);
      }, error =>  this.error = error);
    }
}
