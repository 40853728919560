// tslint:disable: typedef
import { createAction, props, union } from '@ngrx/store';
import { Agenda, AgendaQueryModel } from '../..';

export const loadEventAgenda = createAction('[Agenda] Load Event Agenda', props<{ agendaQuery: AgendaQueryModel }>());
export const loadEventAgendaSuccess = createAction('[Agenda] Load Event Agenda Success', props<{ eventAgenda: any }>());
export const loadEventAgendaError = createAction('[Agenda] Load Event AgendaError', props<{ error: any }>());
export const updateAgendasInfo = createAction('[Agenda] manually update agendaState', props<{ data: Agenda[]; hasLoaded?: boolean }>());

const actions = union({
    loadEventAgenda,
    loadEventAgendaSuccess,
    loadEventAgendaError,
    updateAgendasInfo
});

export type EventAgendaActions = typeof actions;
