import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment = require('moment');

import { AuthService } from '../../../authentication/src/auth.service';
import { TranslationService } from '../../../cms/src/translation.service';
import { UrlHelperService, IAppSettings } from '../../../common/src/public-api';
import { ApiNameEnum } from '../../../common/src/enums/public-api';
import { DynamicFieldsService } from '../dynamic-fields.service';
import {
    DocumentFormModel,
    DocumentViewModel,
    FieldValuesModel,
    ExternalDynamicFieldsOptionsModel,
    ExternalDynamicFormQueryModel,
} from '../public-api';

declare const AppSettings: IAppSettings;

@Component({
    selector: 'jn-external-dynamic-fields',
    templateUrl: './external-dynamic-fields.component.html',
    styleUrls: ['./external-dynamic-fields.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExternalDynamicFieldsComponent implements OnInit {
    @Input() public cartKey: string;
    @Input() public formType: string;
    @Input() public formGroup: FormGroup = new FormGroup({});
    @Input() public floatLabel: string = 'always';
    @Input() public snackBarService: any;
    @Input() public isCorporate: boolean = false;
    @Input() public mainTypeFk: number = 1;
    @Input() public flow: string = 'standard';
    @Input() public isGuest: boolean = false;
    @Input() public guestNumber: number;
    @Input() public apiName: ApiNameEnum = ApiNameEnum.PROFILE;

    @Input()
    set country(value: string) {
        this._country = value;
        if (this.formOptions === null) {
            this.getFormInformation();
        }
    }
    get country(): string {
        return this._country;
    }

    @Input()
    set promoId(value: number) {
        this._promoId = value;
        if (this.formOptions === null) {
            this.getFormInformation();
        }
    }
    get promoId(): number {
        return this._promoId;
    }

    @Input()
    set formOptions(value: ExternalDynamicFieldsOptionsModel) {
        this._formOptions = value;
        this.getFormInformation();
    }
    get formOptions(): ExternalDynamicFieldsOptionsModel {
        return this._formOptions;
    }

    @Output() formTemplateEmit = new EventEmitter<any>();

    @Output() valuesWereSet = new EventEmitter<any>();

    public formTemplate: any;
    public signupKey: string;

    public virtualScrollThreshold: number = 250;
    @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

    public processingOptions: boolean = false;
    public documentFieldSize: number;
    public hasDocuments: boolean = false;
    public documents: DocumentViewModel[];
    public fieldsWithDocuments: string[] = new Array<string>();
    public allowedDocuments: string = '.jpg,.docx,.doc,.pdf,.png,.gif,.txt';
    public allowedDocumentsText: string = 'jpg,docx,doc,pdf,png,gif,txt';
    public initialRange: ListRange = { start: 0, end: 6 } as ListRange;
    public processingRequestCount: number = 0;
    public isFormDisabled: boolean = false;

    private _country: string;
    private _promoId: number;
    private _formOptions: ExternalDynamicFieldsOptionsModel = null;
    private _apiNameEnum: typeof ApiNameEnum = ApiNameEnum;

    constructor(
        private readonly dynamicFieldsService: DynamicFieldsService,
        private readonly translationService: TranslationService,
        private readonly authService: AuthService,
        private readonly urlHelperService: UrlHelperService,
    ) {}

    public ngOnInit(): void {}

    public getFormInformation(): void {
        let fieldsToRetrieve: string[] = new Array<string>();
        let inputsToPopulate: any[] = new Array<any>();

        if (!this.allNeededValuesExist) return;

        const formsQuery: ExternalDynamicFormQueryModel = {
            countryCode: this.country,
            formType: this.formType,
            promoId: this.promoId,
            isGuest: this.isGuest
        };

        this.dynamicFieldsService.getForms(formsQuery, this.apiName).subscribe((forms) => {
            if (!forms) return;

            const formTemplate: any = JSON.parse(forms.schemaData);
            if (!formTemplate.inputGroups || !formTemplate.inputGroups.length) return;

            if (this.formOptions && this.formOptions.inputGroupName) {
                formTemplate.inputGroups = [
                    formTemplate.inputGroups.find(
                        (group) =>
                            group.name === this.formOptions.inputGroupName &&
                            (group[this.formOptions.filterFieldName] == this.formOptions.filterFieldValue ||
                                !group[this.formOptions.filterFieldName])
                    )
                ];
            }

            this.formTemplate = formTemplate.inputGroups[0] ? formTemplate : null;

            if (!this.formTemplate || this.formTemplate.inputs) return;

            this.formGroup = new FormGroup({});

            this.formTemplate.inputGroups.forEach((inputGroup) => {
                if (!inputGroup) return;

                if (this.shouldExistForUser(inputGroup.name)) {
                    inputGroup.inputs.forEach((input) => {
                        if (this.shouldExistForUser(input.name)) {
                            input.shouldExistForUser = true;
                            let validatorsToAdd: any = [];
                            input.editable = true;
                            input.required = false;
                            this.setValidators(input, validatorsToAdd);
                            this.formGroup.addControl(input.name, new FormControl('', validatorsToAdd));
                            if (input.type === 'select') this.optionsSearch(input);
                            if (input.type === 'date') this.setDates(input);
                            if (input.type === 'document') {
                                this.fieldsWithDocuments.push(input.name);
                                this.hasDocuments = true;
                            } else if (
                                this.formTemplate.rules &&
                                this.formTemplate.rules.filter((rule) => rule.type === 'static-text').length > 0 &&
                                this.formTemplate.rules
                                    .filter((rule) => rule.type === 'static-text')[0]
                                    .fields.filter((excludedField) => excludedField === input.name).length > 0
                            ) {
                                input.isStaticText = true;
                                input.fieldValue =
                                    input.translationContent.placeholder &&
                                    this.translationService.translate(
                                    input.translationContent.placeholder.state,
                                    input.translationContent.placeholder.key,
                                    input.translationContent.placeholder.defaultText
                                );
                                this.formGroup.controls[input.name].setValue(input.fieldValue);
                            } else {
                                fieldsToRetrieve.push(input.name);
                            }
                            inputsToPopulate.push(input);

                            this.checkRules(input);
                        } else {
                            inputGroup.inputs = inputGroup.inputs.filter((x) => x !== input);
                        }
                    });
                } else this.formTemplate.inputGroups = this.formTemplate.inputGroups.filter((x) => x !== inputGroup);
            });

            if (this.hasDocuments) {
                let mainPk = this.authService.isLoggedIn ? this.authService.getMainId() : 0;
                this.dynamicFieldsService.getNeededDocStatus(mainPk, this.signupKey).subscribe((documents) => {
                    this.documents = documents;
                    this.formTemplate.inputGroups.forEach((inputGroup) => {
                        inputGroup.inputs.forEach((input) => {
                            if (input.type === 'document') {
                                let currentDoc = documents.filter((x) => x.documentName === input.name)[0];
                                input.status = currentDoc.docStatus;
                                input.comment = currentDoc.lastComment;
                                inputGroup.status = input.status;
                                inputGroup.comment = input.comment;
                                if (currentDoc.docStatus || currentDoc.required !== 'R') {
                                    this.formGroup.get(input.name).setValue(currentDoc.docStatus ? currentDoc.docStatus : 'not required');
                                    input.skipControl = true;
                                } else if (this.formGroup.controls[input.name])
                                    this.formGroup.controls[input.name].setValidators(Validators.required);
                            }
                        });
                    });
                });
            }

            if (fieldsToRetrieve.length > 0) {
                this.processingRequestCount++;
                this.dynamicFieldsService
                    .getDynamicFieldData(fieldsToRetrieve, this.signupKey, this.country, this.formType, this.cartKey, this.promoId, this.isGuest, this.guestNumber, this.apiName)
                    .subscribe((data) => {
                        inputsToPopulate.forEach((input) => {
                            if (data && data.filter((x) => x.fieldName === input.name && input.type !== 'document').length > 0) {
                                let value: string = data.filter((x) => x.fieldName === input.name && input.type !== 'document')[0]
                                    .fieldValue;
                                if ((!value || value === '') && input.defaultOutput && input.defaultOutput !== '') {
                                    value = input.defaultOutput;
                                    input.isUsingDefault = true;
                                }
                                if (input.type === 'checkbox' && value === '') value = '0';
                                if (input.boolResponse && value) value = value === '1' ? input.boolResponse[1] : input.boolResponse[0];
                                if (input.isKey && value) value = this.translationService.translate('dynamicFields', value, value);
                                if (input.removeNonAlphaNumeric && value) {
                                    // removing "none" is left here for now for backword compatibility.
                                    if (value.toLowerCase().includes('none')) value = '';
                                    value = value.replace(/\W/g, '');
                                }
                                if (input.removeNoneOnFile && value && value.toLowerCase().includes('none')) {
                                    value = '';
                                }
                                if (input.type === 'date' && value && value !== '') {
                                    // 14 hours is the max absolute value diff from UTC for time zones. Will keep localization from changing to previous date.
                                    value = moment(value).add(14, 'hours').format('YYYY-MM-DDThh:mm:ss');
                                }
                                input.fieldValue = value;
                                if (!this.formGroup.get(input.name) && input.shouldExistForUser) {
                                    let validatorsToAdd: any = [];
                                    this.setValidators(input, validatorsToAdd);
                                    this.formGroup.addControl(input.name, new FormControl('', validatorsToAdd));
                                }
                                this.formGroup.controls[input.name].setValue(input.fieldValue);
                                if (
                                    input.lockedIfPopulated &&
                                    input.fieldValue !== '' &&
                                    input.fieldValue &&
                                    // !this.isSignup &&
                                    !input.isUsingDefault
                                ) {
                                    input.editable = false;
                                    if (input.type === 'select' && input.options) {
                                        let selectedLabel = input.options.find((option) => option.id === input.fieldValue).label;
                                        input.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                                    }

                                    this.formGroup.removeControl(input.name);
                                }
                                if (
                                    input.type === 'select' &&
                                    input.options &&
                                    // input.options.length >= this.virtualScrollThreshold &&
                                    !input.placeHolder &&
                                    input.fieldValue
                                ) {
                                    if (moment(value.substring(0, 10), 'YYYY-MM-DD', true).isValid()) {
                                        input.fieldValue = value.substring(0, 10);
                                        this.formGroup.get(input.name).setValue(value.substring(0, 10));
                                    }

                                    let selectedOption = input.options.find((option) => option.id === input.fieldValue);
                                    let selectedLabel = selectedOption && selectedOption.label;
                                    if (selectedLabel) {
                                        input.placeHolder = this.translationService.translate(
                                            'dynamicFields',
                                            selectedLabel,
                                            selectedLabel
                                        );
                                    }

                                    this.updateSelectOptions(input);
                                }
                            }
                        });
                        this.checkAfterDataRules(inputsToPopulate);
                        this.formTemplateEmit.emit(this.formTemplate);
                    })
                    .add(() => this.processingRequestCount--);
            } else {
                this.formTemplateEmit.emit(this.formTemplate);
            }


        });
    }

    public validateId(inputName: string) {
        if (this.formTemplate && this.formTemplate.inputGroups) {
            this.formTemplate.inputGroups.forEach((inputGroup) => {
                inputGroup.inputs.forEach((input) => {
                    if (!this.formGroup.get(inputName)) {
                        const validatorsToAdd: any = [];
                        this.setValidators(input, validatorsToAdd);
                        this.formGroup.addControl(input.name, new FormControl('', validatorsToAdd));
                    }
                    if (input.name === inputName && input.complexValidation) {
                        if (
                            !this.formGroup.get(inputName).hasError('pattern') &&
                            this.formGroup.get(inputName).value !== null &&
                            this.formGroup.get(inputName).value !== '' &&
                            this.formGroup.get(inputName).valid
                        ) {
                            const fieldValue: FieldValuesModel[] = new Array();
                            let inputValue: string = this.formGroup.get(inputName).value;

                            // temporary solution to use complexValidation depends on other properties
                            const valueSeparator: string = '&_&';

                            if (input.complexValidationDependentFields && input.complexValidationDependentFields.length) {
                                input.complexValidationDependentFields.forEach((dfName: string) => {
                                    inputValue += `${valueSeparator}${this.formGroup.get(dfName).value}`;
                                });
                            }
                            // temporary solution to use complexValidation depends on other properties end

                            fieldValue.push({
                                field: inputName,
                                value: inputValue
                            });

                            this.processingRequestCount++;
                            this.dynamicFieldsService
                                .validateDynamicFieldData(fieldValue, this.country, this.formType, this.signupKey, this.promoId, this.isGuest)
                                .subscribe((data: any) => {
                                    if (data.length > 0) {
                                        input.complexFailText = this.translationService.translate(
                                            'dynamicFields',
                                            data[0].fieldValue,
                                            'Validation Error'
                                        );
                                        this.formGroup.get(inputName).setErrors({ complexFail: true });
                                    }
                                })
                                .add(() => this.processingRequestCount--);
                        }
                    }
                });
            });
        }
    }

    public shouldExistForUser(inputName: any): boolean {
        let shouldExist = true;
        let test = this.mainTypeFk;
        if (this.formTemplate && this.formTemplate.rules) {
            this.formTemplate.rules.forEach((rule) => {
                if (
                    rule.type === 'distributor-only' &&
                    this.mainTypeFk !== 1 &&
                    rule.fields.filter((excludedField) => excludedField === inputName).length > 0
                ) {
                    shouldExist = false;
                }
                if (
                    rule.type === 'affiliate-only' &&
                    this.mainTypeFk !== 22 &&
                    shouldExist &&
                    rule.fields.filter((excludedField) => excludedField === inputName).length > 0
                ) {
                    shouldExist = false;
                }
                if (
                    shouldExist &&
                    rule.type === 'individual-only' &&
                    this.isCorporate &&
                    rule.fields.filter((excludedField) => excludedField === inputName).length > 0
                ) {
                    shouldExist = false;
                }
                if (
                    shouldExist &&
                    rule.type === 'business-only' &&
                    !this.isCorporate &&
                    rule.fields.filter((excludedField) => excludedField === inputName).length > 0
                ) {
                    shouldExist = false;
                }
                if (
                    shouldExist &&
                    rule.type === 'nvpro-only' &&
                    this.flow !== 'nvpro' &&
                    rule.fields.filter((excludedField) => excludedField === inputName).length > 0
                ) {
                    shouldExist = false;
                }
            });
        }
        return shouldExist;
    }

    public checkRules(input: any) {
        if (this.formTemplate && this.formTemplate.rules) {
            this.formTemplate.rules.forEach((rule) => {
                if (rule.type === 'read-only' && rule.fields.filter((field) => field === input.name).length > 0) {
                    input.readonly = true;
                }
                if (rule.type === 'hidden-control' && rule.fields.filter((field) => field === input.name).length > 0) {
                    input.hidden = true;
                }
                if (rule.type === 'static-text' && rule.fields.filter((field) => field === input.name).length > 0) {
                    input.readonly = false;
                    input.editable = false;
                }
            });
        }
    }

    public checkAfterDataRules(inputs: any) {
        if (this.formTemplate && this.formTemplate.rules) {
            this.formTemplate.rules.forEach((rule) => {
                if (rule.type === 'show-if-source-empty') {
                    rule.groups.forEach((group) => {
                        const sourceInput = inputs.filter((input) => input.name === group.source)[0];
                        const targetInput = inputs.filter((input) => input.name === group.target)[0];
                        if (sourceInput) {
                            const sourceInputValue = sourceInput.fieldValue;
                            if (sourceInputValue === null || sourceInputValue === '') {
                                if (
                                    (targetInput.fieldValue === null || targetInput.fieldValue === '') &&
                                    targetInput.translationContent.placeholder.state &&
                                    targetInput.translationContent.placeholder.key
                                )
                                    targetInput.fieldValue = this.translationService.translate(
                                        targetInput.translationContent.placeholder.state,
                                        targetInput.translationContent.placeholder.key,
                                        targetInput.translationContent.placeholder.defaultText
                                    );
                            } else {
                                targetInput.hidden = true;
                            }
                        }
                    });
                } else if (rule.type === 'bind-to-target-value') {
                    rule.groups.forEach((group) => {
                        this.bindToTargetValue(inputs, group);
                        if (group.targets) {
                            group.targets.forEach((target) => {
                                let currentInput = inputs.filter((input) => input.name === target.name)[0];
                                if (currentInput && this.formGroup.contains(target.name)) {
                                    this.formGroup.get(target.name).valueChanges.subscribe((data) => {
                                        this.bindToTargetValue(inputs, group);
                                    });
                                }
                            });
                        } else {
                            let currentInput = inputs.filter((input) => input.name === group.target)[0];
                            if (currentInput && this.formGroup.contains(group.target)) {
                                this.formGroup.get(group.target).valueChanges.subscribe((data) => {
                                    this.bindToTargetValue(inputs, group);
                                });
                            }
                        }
                    });
                } else if (rule.type === 'lock-if-target-value') {
                    rule.groups.forEach((group) => {
                        this.lockIfTargetValue(inputs, group);
                        let currentInput = inputs.filter((input) => input.name === group.target)[0];
                        if (currentInput && this.formGroup.contains(group.target)) {
                            this.formGroup.get(group.target).valueChanges.subscribe((data) => {
                                this.lockIfTargetValue(inputs, group);
                            });
                        }
                    });
                } else if (rule.type === 'hide-if-target-value') {
                    rule.groups.forEach((group) => {
                        this.hideIfTargetValue(inputs, group);
                        let currentInput = inputs.filter((input) => input.name === group.target)[0];
                        if (currentInput && this.formGroup.contains(group.target)) {
                            this.formGroup.get(group.target).valueChanges.subscribe((data) => {
                                this.hideIfTargetValue(inputs, group);
                            });
                        }
                    });
                } else if (rule.type === 'mutuallyExclusive') {
                    rule.groups.forEach((group) => {
                        group.fields.forEach((field) => {
                            const input = inputs.filter((input) => input.name === field)[0];
                            if (input) {
                                input.mutuallyExclusive = [];
                                group.fields.forEach((field2) => {
                                    if (field !== field2) input.mutuallyExclusive.push(field2);
                                });
                            }
                        });
                    });
                } else if (rule.type === 'lock-if-source-populated') {
                    rule.groups.forEach((group) => {
                        const sourceInputValue = inputs.filter((input) => input.name === group.source)[0].fieldValue;
                        if (sourceInputValue !== null && sourceInputValue !== '') {
                            const targetInput = inputs.filter((input) => input.name === group.target)[0];
                            targetInput.editable = false;
                            this.formGroup.removeControl(targetInput.name);
                            if (targetInput.type === 'select' && targetInput.options) {
                                const selectedLabel = targetInput.options.filter((option) => option.id === targetInput.fieldValue)[0].label;
                                targetInput.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                            }
                        }
                    });
                } else if (rule.type === 'show-if-empty') {
                    rule.fields.forEach((field) => {
                        const inputField = inputs.filter((input) => input.name === field)[0];
                        if (inputField && inputField.fieldValue !== null && inputField.fieldValue !== '') {
                            this.formGroup.removeControl(inputField.name);
                            inputField.hidden = true;
                        }
                    });
                } else if (rule.type === 'hide-if-empty') {
                    rule.fields.forEach((field) => {
                        const inputField = inputs.filter((input) => input.name === field)[0];
                        if (inputField && (inputField.fieldValue === null || inputField.fieldValue === '')) {
                            inputField.hidden = true;
                        }
                    });
                }
            });
        }
        if (this.formTemplate && this.formTemplate.inputGroups) {
            this.formTemplate.inputGroups.forEach((inputGroup) => {
                if (inputGroup.inputs.filter((input) => input.hidden === false || input.hidden === undefined).length === 0) {
                    inputGroup.hidden = true;
                }
            });
        }
    }

    public onDocumentChange(event: any, formInput: any): void {
        const fileList: FileList = event.target.files;
        if (fileList.length === 1) {
            formInput.uploading = true;

            let fileNameArr: string[] = fileList[0].name.split('.');
            let extension: string = `.${fileNameArr[fileNameArr.length - 1].toLowerCase()}`;

            if (this.allowedDocuments.indexOf(extension) === -1) {
                if (this.snackBarService)
                    this.snackBarService.warn(
                        this.translationService
                            .translate('dynamicFields', 'fileTypesWarning', 'Only {0} file types allowed')
                            .replace('{0}', this.allowedDocumentsText)
                    );
                else
                    alert(
                        this.translationService
                            .translate('dynamicFields', 'fileTypesWarning', 'Only {0} file types allowed')
                            .replace('{0}', this.allowedDocumentsText)
                    );

                formInput.uploading = false;
                return;
            }

            let document: DocumentViewModel = this.documents.filter((x) => x.documentName === formInput.name)[0];

            const uploadDoc: DocumentFormModel = {
                fileName: document.documentName,
                fileType: extension,
                fileSize: 1,
                mainPk: this.authService.isLoggedIn ? this.authService.getMainId() : 0,
                signupDocumentsPK: document.signupDocumentsPk,
                userDocumentsPk: document.userDocumentsFk,
                isCorporate: false,
                classification: 'Signup',
                signupKey: this.signupKey
            };

            this.dynamicFieldsService.upload(uploadDoc, fileList[0]).subscribe((data: any) => {
                formInput.uploading = false;
                formInput.status = data.docStatus;
                this.formTemplate.inputGroups.forEach((inputGroup) => {
                    if (inputGroup.inputs.filter((input) => input.name === formInput.name).length > 0) inputGroup.status = data.docStatus;
                });
                this.formGroup.get(formInput.name).setValue(data.docStatus);
                this.documents.filter((x) => x.documentName === formInput.name)[0].userDocumentsFk = data.userDocumentsPk;
            });
        }
    }

    public lockIfTargetValue(inputs, group): void {
        const input = inputs.filter((input) => input.name === group.field)[0];
        let currentFormValue;
        let targetInput = inputs.filter((input) => input.name === group.target)[0];
        if (!targetInput) return;
        if (!targetInput.editable) currentFormValue = targetInput.fieldValue;
        if (this.formGroup.contains(targetInput.name))
            currentFormValue = this.formGroup.contains(group.target) ? this.formGroup.get(group.target).value : null;
        if (!((currentFormValue !== null && group.targetValue === 'hasValue') || currentFormValue === group.targetValue)) {
            input.editable = true;
            if (input.type === 'select' && input.options && input.fieldValue && input.fieldValue !== '' && input.fieldValue) {
                const selectedLabel = input.options.find(
                    (option) => option.id === input.fieldValue || option.label === input.fieldValue
                ).label;
                input.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
            }
        } else {
            input.editable = false;
            if (input.type === 'select' && input.options && input.fieldValue && input.fieldValue !== '' && input.fieldValue) {
                const selectedLabel = input.options.find((option) => option.id === input.fieldValue).label;
                input.fieldValue = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
            }
        }
    }

    public hideIfTargetValue(inputs, group): void {
        const input = inputs.filter((input) => input.name === group.field)[0];
        let currentFormValue;
        let hideField = false;
        let targetInput = inputs.filter((input) => input.name === group.target)[0];
        if (!targetInput) return;
        if (!targetInput.editable) currentFormValue = targetInput.fieldValue;
        if (this.formGroup.contains(targetInput.name))
            currentFormValue = this.formGroup.contains(group.target) ? this.formGroup.get(group.target).value : null;
        if ( currentFormValue === group.targetValue)
            hideField = true;

        if (!hideField) {
            input.required = false;
            let validatorsToAdd: any = [];
            this.setValidators(input, validatorsToAdd);
            if (input.editable && !input.skipControl) {
                this.formGroup.get(input.name).setValidators(validatorsToAdd);
                input.skipSave = false;
            }
            input.hidden = false;
            this.formTemplate.inputGroups.forEach((inputGroup) => {
                if (inputGroup.inputs.filter((x) => x.hidden === false || x.hidden === undefined).length > 0) {
                    inputGroup.hidden = false;
                } else {
                    inputGroup.hidden = true;
                }
            });
        } else {
            if (input.editable) {
                this.formGroup.get(input.name).setValidators(null);
                this.formGroup.get(input.name).updateValueAndValidity();
                input.skipSave = true;
            }
            input.hidden = true;

        }
    }

    public bindToTargetValue(inputs, group): void {
        let currentFormValue;
        let hideField = false;
        if (group.targets) {
            group.targets.forEach((target) => {
                let targetInput = inputs.filter((input) => input.name === target.name)[0];
                if (!targetInput) return;
                if (!targetInput.editable) currentFormValue = targetInput.fieldValue;
                if (this.formGroup.contains(targetInput.name))
                    currentFormValue = this.formGroup.contains(targetInput.name) ? this.formGroup.get(targetInput.name).value : null;
                if (!((currentFormValue !== null && targetInput.fieldValue === 'hasValue') || currentFormValue === target.value))
                    hideField = true;
            });
        } else {
            let targetInput = inputs.filter((input) => input.name === group.target)[0];
            if (!targetInput) return;
            if (!targetInput.editable) currentFormValue = targetInput.fieldValue;
            if (this.formGroup.contains(targetInput.name))
                currentFormValue = this.formGroup.contains(group.target) ? this.formGroup.get(group.target).value : null;
            if (!((currentFormValue !== null && group.targetValue === 'hasValue') || currentFormValue === group.targetValue))
                hideField = true;
        }
        const input = inputs.filter((input) => input.name === group.field)[0];
        if (!hideField) {
            input.required = false;
            let validatorsToAdd: any = [];
            this.setValidators(input, validatorsToAdd);
            if (input.editable && !input.skipControl) {
                this.formGroup.get(input.name).setValidators(validatorsToAdd);
                input.skipSave = false;
            }
            input.hidden = false;
            this.formTemplate.inputGroups.forEach((inputGroup) => {
                if (inputGroup.inputs.filter((x) => x.hidden === false || x.hidden === undefined).length > 0) {
                    inputGroup.hidden = false;
                } else {
                    inputGroup.hidden = true;
                }
            });
        } else {
            if (input.editable) {
                this.formGroup.get(input.name).setValidators(null);
                this.formGroup.get(input.name).updateValueAndValidity();
                input.skipSave = true;
            }
            input.hidden = true;
            if (input.type === 'document') {
                this.formTemplate.inputGroups.forEach((inputGroup) => {
                    inputGroup.inputs.forEach((mainInput) => {
                        if (input.type === 'document' && input.name === mainInput.name) {
                            inputGroup.hidden = true;
                        }
                    });
                });
            }
        }
    }

    public mutuallyExclusive(selectedValue, input) {
        if (input.mutuallyExclusive)
            input.mutuallyExclusive.forEach((exclusive) => {
                if (input.options.filter((option) => option.id === selectedValue)[0].label === this.formGroup.get(exclusive).value)
                    this.formGroup.controls[exclusive].setValue(null);
            });

        if (input.dataSourceOptions && input.dataSourceOptions.relatedInputName) {
            const currentInputGroupIndex: number = this.formTemplate.inputGroups.findIndex((g) =>
                g.inputs.find((x) => x.name === input.dataSourceOptions.relatedInputName)
            );

            if (currentInputGroupIndex > -1)
                this.optionsSearch(
                    this.formTemplate.inputGroups[currentInputGroupIndex].inputs.find(
                        (x) => x.name === input.dataSourceOptions.relatedInputName
                    ),
                    [{ field: input.name, value: selectedValue }]
                );
        }
    }

    public openedChange($event: boolean, formInput: any) {
        if ($event) {
            const index = formInput.options.findIndex((option) => option.id === this.formGroup.controls[formInput.name].value);
            if (this.formGroup.controls[formInput.name].value) {
                const selectedLabel = formInput.options.filter((option) => option.id === this.formGroup.controls[formInput.name].value)[0]
                    .label;
                formInput.placeHolder = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
            }

            this.cdkVirtualScrollViewport.scrollToIndex(index);
            this.cdkVirtualScrollViewport.checkViewportSize();
        }
    }

    public setDynamicFieldsValues(skipDefaultUpdate = false): void {
        if (!this.formTemplate) return;
        if (!this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        if (skipDefaultUpdate) {
            this.valuesWereSet.emit();
        } else {
            this.processingRequestCount++;
            this._updateUserInfo();
        }
    }

    public disableDynamicFields(): void {
        this.formGroup.disable();
        this.isFormDisabled = true;
    }

    public onRadioGroupValueChanged(e: any, formInputName: string): void {
        this.formGroup.get(formInputName).clearValidators();
        this.formGroup.get(formInputName).updateValueAndValidity();
    }

    public getLinkUrl(url: string): string {
        const siteUrl = url.substring(
            url.indexOf('{') + 1,
            url.lastIndexOf('}')
        );
        const newUrl = url.replace(`{${siteUrl}}`, '');

        return siteUrl ? this.urlHelperService.buildUrl(AppSettings[siteUrl], newUrl) : url;
    }

    private updateSelectOptions(parentInput: any): void {
        if (parentInput.dataSourceOptions && parentInput.dataSourceOptions.relatedInputName) {
            const currentInputGroupIndex: number = this.formTemplate.inputGroups.findIndex((g) =>
                g.inputs.find((x) => x.name === parentInput.dataSourceOptions.relatedInputName)
            );

            if (currentInputGroupIndex > -1)
                this.optionsSearch(
                    this.formTemplate.inputGroups[currentInputGroupIndex].inputs.find(
                        (x) => x.name === parentInput.dataSourceOptions.relatedInputName
                    ),
                    [{ field: parentInput.name, value: this.formGroup.controls[parentInput.name].value }]
                );
        }
    }

    private _updateUserInfo(): void {
        this.dynamicFieldsService
            .updateUserInfo(this.formTemplate, this.formGroup, '', this.country, +this.formType, this.cartKey, null, this.promoId, this.isGuest)
            .subscribe(() => this.valuesWereSet.emit())
            .add(() => this.processingRequestCount--);
    }

    private optionsSearch(input: any, fieldValues?: any[]) {
        if (input.optionsSearch) {
            this.processingRequestCount++;
            const formsQuery: ExternalDynamicFormQueryModel = {
                countryCode: this.country,
                formType: this.formType,
                promoId: this.promoId,
                isGuest: this.isGuest,
            };
            this.dynamicFieldsService
                .optionsSearch(input.name, formsQuery, fieldValues, this.apiName)
                .subscribe((optionsArray) => {
                    input.options = optionsArray;
                    if (!input.placeHolder && input.fieldValue) {
                        let currentInput = input.options.filter((option) => option.id === input.fieldValue)[0];
                        let selectedLabel = currentInput && currentInput.label;
                        input.placeHolder = this.translationService.translate('dynamicFields', selectedLabel, selectedLabel);
                        if (!input.editable) input.fieldValue = input.placeHolder;
                    }

                    if (!input.options.find((option) => option.id === this.formGroup.get(input.name).value)) {
                        this.formGroup.get(input.name).setValue(null);
                    }
                })
                .add(() => this.processingRequestCount--);
        }
    }

    private setValidators(input, validatorsToAdd) {
        input.validators.forEach((validator) => {
            if (validator.type === 'required' && input.type !== 'document') {
                input.required = true;
                validatorsToAdd.push(Validators.required);
            }
            if (validator.type === 'pattern') {
                validatorsToAdd.push(Validators.pattern(validator.regexPattern));
            }
            if (validator.type === 'email') {
                validatorsToAdd.push(Validators.email);
            }
        });
    }

    private setDates(input) {
        if (input.minYears !== null) {
            input.minDate = new Date();
            input.minDate.setFullYear(input.minDate.getFullYear() - input.minYears);
            input.minDate.setHours(0, 0, 0, 0);
        }
        if (input.maxYears !== null) {
            input.maxDate = new Date();
            input.maxDate.setFullYear(input.maxDate.getFullYear() - input.maxYears);
            input.maxDate.setHours(0, 0, 0, 0);
        }

        this.formGroup.get(input.name).valueChanges.subscribe((selectedDate: Date) => {
            if (input.maxDate && selectedDate && selectedDate > input.maxDate) this.formGroup.get(input.name).setErrors({ maxDate: true });
            else if (input.minDate && selectedDate && selectedDate < input.minDate)
                this.formGroup.get(input.name).setErrors({ minDate: true });
        });
    }

    private get allNeededValuesExist(): boolean {
        switch (this.apiName) {
            case this._apiNameEnum.PROFILE:
                return Boolean(this.country && this.formType);
            case this._apiNameEnum.EVENT:
                return Boolean(this.promoId);
            default:
                return false;
        }
    }
}
