import { Injectable } from '@angular/core';
import { SettingsService } from '../../common/src/settings.service';

/**
 * This handles anything related to storage for the client.
 */
@Injectable()
export class WebStorageService {
    private isFakeLocal: boolean = false;
    private isFakeSession: boolean = false;
    private fakeLocal: Map<string, string> = new Map<string, string>();
    private fakeSession: Map<string, string> = new Map<string, string>();

     constructor(private readonly settings: SettingsService) {
        try {
            window.localStorage.setItem('__test__', 'test');
            window.sessionStorage.setItem('__stest__', 'test');
            window.localStorage.removeItem('__test__');
            window.sessionStorage.removeItem('__stest__');
        }
        catch (e) {
            this.isFakeLocal = true;
            this.isFakeSession = true;

            console.warn('Local or Session Storage is not supported or disabled. Switching to Fake Local Storage.');
        }
    }

    /**
     * Stores or updates a string under local storage. If local storage is disabled or exceeds the max size the function will
     * switch to storing the data in memory and move any current data stored under local storage. Most browser have a max local
     * storage of 10MB per domain and protocol. The max size is shared between local and session storage. The data is compressed
     * before it's saved to local storage.
     * @param key A `string` containing the name of the key you want to create/update.
     * @param value A `string` containing the value you want to give the key you are creating/updating.
     */
     public setLocal(key: string, value: string): void {
        if (this.isFakeLocal) {
            this.fakeLocal.set(key, value);
        }
        else {
            try {
                window.localStorage.setItem(key, this.compress(value));
            }
            catch (e) {
                this.isFakeLocal = true;

                if (window.localStorage && window.localStorage.length > 0) {
                    for (let key2 in window.localStorage) {
                        if (window.localStorage.hasOwnProperty(key2)) {
                            this.fakeLocal.set(key2, this.decompress(window.localStorage.getItem(key2)));
                        }
                    }

                    window.localStorage.clear();
                }

                this.fakeLocal.set(key, value);

                console.warn('Local Storage is out of space. Switching to Fake Local Storage.');
            }
        }
    }

    /**
     * Return a value from local storage.
     * @param key A `string` containing the name of the key you want to retrieve the value of.
     * @returns A `string` containing the value of the key. If the key does not exist, an empty string is returned.
     */
     public getLocal(key: string): string {
        if (this.isFakeLocal) {
                return this.fakeLocal.get(key) || '';
        }
        else {
            const value: string = window.localStorage.getItem(key);
            return value ? this.decompress(value) : '';
          }
     }

    /**
     * Will remove that key from local storage if it exists. If there is no item associated with the given key, this method will do nothing.
     * @param key A `string` containing the name of the key you want to remove.
     */
     public removeLocal(key: string): void {
        if (this.isFakeLocal) {
                this.fakeLocal.delete(key);
        }
        else {
            window.localStorage.removeItem(key);
          }
    }


    /**
     * Stores or updates a string under session storage. If local storage is disabled or exceeds the max size the function will
     * switch to storing the data in memory and move any current data stored under session storage. Most browser have a max local
     * storage of 10MB per domain and protocol. The max size is shared between local and session storage. The data is compressed
     * before it's saved to session storage.
     * @param key A `string` containing the name of the key you want to create/update.
     * @param value A `string` containing the value you want to give the key you are creating/updating.
     * @param compress a `boolean` to compress the string. Defaults to true.
     */
    public setSession(key: string, value: string, compress: boolean = true): void {
        if (this.isFakeSession) {
            this.fakeSession.set(key, value);
        }
        else {
            try {
                window.sessionStorage.setItem(key, (compress) ? this.compress(value) : value);
            }
            catch (e) {
                this.isFakeSession = true;

                if (window.sessionStorage && window.sessionStorage.length > 0) {
                    for (let key2 in window.sessionStorage) {
                        if (window.sessionStorage.hasOwnProperty(key2)) {
                            this.fakeLocal.set(key2, this.decompress(window.sessionStorage.getItem(key2)));
                        }
                    }

                    window.sessionStorage.clear();
                }

                this.fakeLocal.set(key, value);

                console.warn('Session Storage is out of space. Switching to Fake Local Storage.');
            }
        }
    }

    /**
     * Return a value from session storage.
     * @param key A `string` containing the name of the key you want to retrieve the value of.
     * @param decompress A `boolean` to decompress a compressed string value. Defaults to true.
     * @returns A `string` containing the value of the key. If the key does not exist, an empty string is returned.
     */
    public getSession(key: string, decompress: boolean = true): string {
        if (this.isFakeSession) {
            return this.fakeSession.get(key) || '';
        }
        else {
            let value: string = window.sessionStorage.getItem(key);
            value = (decompress) ? this.decompress(value || '') : value;
            return value ? value : '';
        }
    }

    /**
     * Will remove that key from session storage if it exists. If there is no item associated with the given key, this method will do nothing.
     * @param key A `string` containing the name of the key you want to remove.
     */
    public removeSession(key: string): void {
        if (this.isFakeSession) {
            this.fakeSession.delete(key);
        }
        else {
            window.sessionStorage.removeItem(key);
        }
    }

    /**
     * Creates a new cookie or updates if the cookie already exists.
     * @param key The name of the cookie.
     * @param value The value of the cookie.
     * @param expires Define when the cookie will be removed.
     *                Value can be a Number which will be interpreted as days from time of creation or a Date instance.
     *                If omitted, the cookie will be set to expire in 20 years.
     */
    public setCookie(key: string, value: string, expires: Date | number = 7300): void {
        if (typeof expires === 'number') {
            const date: Date = new Date();
            date.setMilliseconds(date.getMilliseconds() + expires * 864e+5);
            expires = date;
        }

        document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/;domain=${this.settings.cookieDomain}`;
     }

    /**
     * Returns a single cookie with the specified name, or an empty string if the cookie doesn't exist.
     * @param key The name of the cookie.
     * @returns The value of the cookie.
     */
    public getCookie(key: string): string {
        const result: RegExpExecArray = new RegExp(`(?:^|; )${encodeURIComponent(key)}=([^;]*)`).exec(document.cookie);
        return result ? decodeURIComponent(result[1]) : '';
    }

    /**
     * Deletes a single cookie by name.
     * @param key The name of the cookie.
     */
    public removeCookie(key: string): void {
        this.setCookie(key, '', -1);
    }

    /**
     * Deletes all cookies.
     */
    public removeAllCookies(): void {
        const cookies = document.cookie.split("; ");

        for (let c = 0; c < cookies.length; c++) {
            const d = window.location.hostname.split(".");

            while (d.length > 0) {
                const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                const p = location.pathname.split('/');
                document.cookie = cookieBase + '/';

                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                };

                d.shift();
            }
        }
    }

    /* tslint:disable:no-bitwise */
    /**
     * Compresses to UTF16 characters using an algorithm based on LZ compression.
     * @param uncompressed The string to compress.
     * @returns The string compressed as UTF16 characters.
     */
    public compress(uncompressed: string): string {
        const bitsPerChar: number = 15;
        const p: (x: number, y: number) => number = Math.pow;
        const f: (...codes: number[]) => string = String.fromCharCode;
        const getCharFromInt: (a: number) => string = (a) => { return f(a + 32); };

        let i: number;
        let ii: number;
        let value: number;
        let contextDictionary: {} = {};
        let contextDictionaryToCreate: {} = {};
        let contextC: string = '';
        let contextWc: string = '';
        let contextW: string = '';
        let contextEnlargeIn: number = 2; // Compensate for the first entry which should not count
        let contextDictSize: number = 3;
        let contextNumBits: number = 2;
        let contextData: any[] = [];
        let contextDataVal: number = 0;
        let contextDataPosition: number = 0;

        for (ii = 0; ii < uncompressed.length; ii += 1) {
            contextC = uncompressed.charAt(ii);
            if (!Object.prototype.hasOwnProperty.call(contextDictionary, contextC)) {
                contextDictionary[contextC] = contextDictSize++;
                contextDictionaryToCreate[contextC] = true;
            }

            contextWc = contextW + contextC;
            if (Object.prototype.hasOwnProperty.call(contextDictionary, contextWc)) {
                contextW = contextWc;
            }
            else {
                if (Object.prototype.hasOwnProperty.call(contextDictionaryToCreate, contextW)) {
                    if (contextW.charCodeAt(0) < 256) {
                        for (i = 0; i < contextNumBits; i++) {
                            contextDataVal = (contextDataVal << 1);
                            if (contextDataPosition === bitsPerChar - 1) {
                                contextDataPosition = 0;
                                contextData.push(getCharFromInt(contextDataVal));
                                contextDataVal = 0;
                            }
                            else {
                                contextDataPosition++;
                            }
                        }
                        value = contextW.charCodeAt(0);
                        for (i = 0; i < 8; i++) {
                            contextDataVal = (contextDataVal << 1) | (value & 1);
                            if (contextDataPosition === bitsPerChar - 1) {
                                contextDataPosition = 0;
                                contextData.push(getCharFromInt(contextDataVal));
                                contextDataVal = 0;
                            }
                            else {
                                contextDataPosition++;
                            }
                            value = value >> 1;
                        }
                    }
                    else {
                        value = 1;
                        for (i = 0; i < contextNumBits; i++) {
                            contextDataVal = (contextDataVal << 1) | value;
                            if (contextDataPosition === bitsPerChar - 1) {
                                contextDataPosition = 0;
                                contextData.push(getCharFromInt(contextDataVal));
                                contextDataVal = 0;
                            }
                            else {
                                contextDataPosition++;
                            }
                            value = 0;
                        }
                        value = contextW.charCodeAt(0);
                        for (i = 0; i < 16; i++) {
                            contextDataVal = (contextDataVal << 1) | (value & 1);
                            if (contextDataPosition === bitsPerChar - 1) {
                                contextDataPosition = 0;
                                contextData.push(getCharFromInt(contextDataVal));
                                contextDataVal = 0;
                            }
                            else {
                                contextDataPosition++;
                            }
                            value = value >> 1;
                        }
                    }
                    contextEnlargeIn--;
                    if (contextEnlargeIn === 0) {
                        contextEnlargeIn = p(2, contextNumBits);
                        contextNumBits++;
                    }
                    delete contextDictionaryToCreate[contextW];
                }
                else {
                    value = contextDictionary[contextW];
                    for (i = 0; i < contextNumBits; i++) {
                        contextDataVal = (contextDataVal << 1) | (value & 1);
                        if (contextDataPosition === bitsPerChar - 1) {
                            contextDataPosition = 0;
                            contextData.push(getCharFromInt(contextDataVal));
                            contextDataVal = 0;
                        }
                        else {
                            contextDataPosition++;
                        }
                        value = value >> 1;
                    }


                }
                contextEnlargeIn--;
                if (contextEnlargeIn === 0) {
                    contextEnlargeIn = p(2, contextNumBits);
                    contextNumBits++;
                }
                // Add wc to the dictionary.
                contextDictionary[contextWc] = contextDictSize++;
                contextW = String(contextC);
            }
        }

        // Output the code for w.
        if (contextW !== '') {
            if (Object.prototype.hasOwnProperty.call(contextDictionaryToCreate, contextW)) {
                if (contextW.charCodeAt(0) < 256) {
                    for (i = 0; i < contextNumBits; i++) {
                        contextDataVal = (contextDataVal << 1);
                        if (contextDataPosition === bitsPerChar - 1) {
                            contextDataPosition = 0;
                            contextData.push(getCharFromInt(contextDataVal));
                            contextDataVal = 0;
                        }
                        else {
                            contextDataPosition++;
                        }
                    }
                    value = contextW.charCodeAt(0);
                    for (i = 0; i < 8; i++) {
                        contextDataVal = (contextDataVal << 1) | (value & 1);
                        if (contextDataPosition === bitsPerChar - 1) {
                            contextDataPosition = 0;
                            contextData.push(getCharFromInt(contextDataVal));
                            contextDataVal = 0;
                        }
                        else {
                            contextDataPosition++;
                        }
                        value = value >> 1;
                    }
                }
                else {
                    value = 1;
                    for (i = 0; i < contextNumBits; i++) {
                        contextDataVal = (contextDataVal << 1) | value;
                        if (contextDataPosition === bitsPerChar - 1) {
                            contextDataPosition = 0;
                            contextData.push(getCharFromInt(contextDataVal));
                            contextDataVal = 0;
                        }
                        else {
                            contextDataPosition++;
                        }
                        value = 0;
                    }
                    value = contextW.charCodeAt(0);
                    for (i = 0; i < 16; i++) {
                        contextDataVal = (contextDataVal << 1) | (value & 1);
                        if (contextDataPosition === bitsPerChar - 1) {
                            contextDataPosition = 0;
                            contextData.push(getCharFromInt(contextDataVal));
                            contextDataVal = 0;
                        }
                        else {
                            contextDataPosition++;
                        }
                        value = value >> 1;
                    }
                }
                contextEnlargeIn--;
                if (contextEnlargeIn === 0) {
                    contextEnlargeIn = p(2, contextNumBits);
                    contextNumBits++;
                }
                delete contextDictionaryToCreate[contextW];
            }
            else {
                value = contextDictionary[contextW];
                for (i = 0; i < contextNumBits; i++) {
                    contextDataVal = (contextDataVal << 1) | (value & 1);
                    if (contextDataPosition === bitsPerChar - 1) {
                        contextDataPosition = 0;
                        contextData.push(getCharFromInt(contextDataVal));
                        contextDataVal = 0;
                    }
                    else {
                        contextDataPosition++;
                    }
                    value = value >> 1;
                }


            }
            contextEnlargeIn--;
            if (contextEnlargeIn === 0) {
                contextEnlargeIn = p(2, contextNumBits);
                contextNumBits++;
            }
        }

        // Mark the end of the stream
        value = 2;
        for (i = 0; i < contextNumBits; i++) {
            contextDataVal = (contextDataVal << 1) | (value & 1);
            if (contextDataPosition === bitsPerChar - 1) {
                contextDataPosition = 0;
                contextData.push(getCharFromInt(contextDataVal));
                contextDataVal = 0;
            }
            else {
                contextDataPosition++;
            }
            value = value >> 1;
        }

        // Flush the last char
        while (true) {
            contextDataVal = (contextDataVal << 1);
            if (contextDataPosition === bitsPerChar - 1) {
                contextData.push(getCharFromInt(contextDataVal));
                break;
            }
            else
                contextDataPosition++;
        }

        return contextData.join('');
    }

    /**
     * Decompresses from UTF16 characters using an algorithm based on LZ compression.
     * @param compressed The string to decompress.
     * @returns The uncompressed string.
     */
    public decompress(compressed: string): string {

        const resetValue: number = 16384;
        const length: number = compressed.length;
        const p: (x: number, y: number) => number = Math.pow;
        const f: (...codes: number[]) => string = String.fromCharCode;
        const getNextValue: (index: number) => number = (index) => { return compressed.charCodeAt(index) - 32; };

        let dictionary: any[] = [];
        let next: number;
        let enlargeIn: number = 4;
        let dictSize: number = 4;
        let numBits: number = 3;
        let entry: string = '';
        let result: any[] = [];
        let i: number;
        let w: any;
        let c: any;
        let bits: number;
        let resb: number;
        let maxpower: number;
        let power: number;
        let data: { val: number; position: number; index: number } = { val: getNextValue(0), position: resetValue, index: 1 };

        for (i = 0; i < 3; i += 1) {
            dictionary[i] = i;
        }

        bits = 0;
        maxpower = p(2, 2);
        power = 1;
        while (power !== maxpower) {
            resb = data.val & data.position;
            data.position >>= 1;
            if (data.position === 0) {
                data.position = resetValue;
                data.val = getNextValue(data.index++);
            }
            bits |= (resb > 0 ? 1 : 0) * power;
            power <<= 1;
        }

        switch (next = bits) {
            case 0:
                bits = 0;
                maxpower = p(2, 8);
                power = 1;
                while (power !== maxpower) {
                    resb = data.val & data.position;
                    data.position >>= 1;
                    if (data.position === 0) {
                        data.position = resetValue;
                        data.val = getNextValue(data.index++);
                    }
                    bits |= (resb > 0 ? 1 : 0) * power;
                    power <<= 1;
                }
                c = f(bits);
                break;
            case 1:
                bits = 0;
                maxpower = p(2, 16);
                power = 1;
                while (power !== maxpower) {
                    resb = data.val & data.position;
                    data.position >>= 1;
                    if (data.position === 0) {
                        data.position = resetValue;
                        data.val = getNextValue(data.index++);
                    }
                    bits |= (resb > 0 ? 1 : 0) * power;
                    power <<= 1;
                }
                c = f(bits);
                break;
            case 2:
                return '';
            default:
                break;
        }
        dictionary[3] = c;
        w = c;
        result.push(c);
        while (true) {
            if (data.index > length) {
                return '';
            }

            bits = 0;
            maxpower = p(2, numBits);
            power = 1;
            while (power !== maxpower) {
                resb = data.val & data.position;
                data.position >>= 1;
                if (data.position === 0) {
                    data.position = resetValue;
                    data.val = getNextValue(data.index++);
                }
                bits |= (resb > 0 ? 1 : 0) * power;
                power <<= 1;
            }

            switch (c = bits) {
                case 0:
                    bits = 0;
                    maxpower = p(2, 8);
                    power = 1;
                    while (power !== maxpower) {
                        resb = data.val & data.position;
                        data.position >>= 1;
                        if (data.position === 0) {
                            data.position = resetValue;
                            data.val = getNextValue(data.index++);
                        }
                        bits |= (resb > 0 ? 1 : 0) * power;
                        power <<= 1;
                    }

                    dictionary[dictSize++] = f(bits);
                    c = dictSize - 1;
                    enlargeIn--;
                    break;
                case 1:
                    bits = 0;
                    maxpower = p(2, 16);
                    power = 1;
                    while (power !== maxpower) {
                        resb = data.val & data.position;
                        data.position >>= 1;
                        if (data.position === 0) {
                            data.position = resetValue;
                            data.val = getNextValue(data.index++);
                        }
                        bits |= (resb > 0 ? 1 : 0) * power;
                        power <<= 1;
                    }
                    dictionary[dictSize++] = f(bits);
                    c = dictSize - 1;
                    enlargeIn--;
                    break;
                case 2:
                    return result.join('');
                default:
                    break;
            }

            if (enlargeIn === 0) {
                enlargeIn = p(2, numBits);
                numBits++;
            }

            if (dictionary[c]) {
                entry = dictionary[c];
            }
            else {
                if (c === dictSize) {
                    entry = w + w.charAt(0);
                }
                else {
                    return null;
                }
            }
            result.push(entry);

            // Add w+entry[0] to the dictionary.
            dictionary[dictSize++] = w + entry.charAt(0);
            enlargeIn--;

            w = entry;

            if (enlargeIn === 0) {
                enlargeIn = p(2, numBits);
                numBits++;
            }

        }
    }

}
