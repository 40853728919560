import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { LocaleService } from "@jeunesse/angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ISiteSettings } from "./shared/helpers/site-settings.interface";

declare const SiteSettings: ISiteSettings;

@Injectable({
  providedIn: 'root'
})
export class LocaleGuard implements CanActivate {
  constructor(private readonly localeService: LocaleService,
    private readonly router: Router) {

  }

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    const siteUrl: string = route.paramMap.get('siteUrl') ?? SiteSettings.defaultSiteUrl;
    const locale: string = route.paramMap.get('locale');
    return this.localeService.isCultureSupported(locale).pipe(map((resp: string) => {
      this.localeService.setCultureCookie(resp);
      const countryCode: string = resp.split('-')[1];
      this.localeService.setCountryCode(countryCode);
      if (locale.toLocaleLowerCase() !== resp.toLocaleLowerCase()) {
        return this.router.parseUrl(`/${resp}/${siteUrl}`);
      }
      return true;
    }));
  }
}
