import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { AuthService, ClientInfo, ClientInfoService } from '@jeunesse/angular';
import { ProductConfigModel, ProductModel, ProductPriceModel } from '../../models/public-api';
import { Observable } from 'rxjs';
import * as lodash from 'lodash';
import { SubSink } from 'subsink';
import { SmartDeliveryModel } from '../../../member/models/smart-delivery.model';
import { RepSiteInfo } from '../../../repsite/public-api';
import { MainCartService, ShoppingCart } from '@jeunesse/slide-cart';
import { Location } from '@angular/common';
import { ProductTranslationService } from '../../../products/services/public-api';
import { ProductSeoService } from '../../../products/services/public-api';
import { SmartDeliveryService } from '../../../member/public-api';

const _ = lodash;

@Component({
    selector: 'jn-product-detail',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit {
    @Input() public product$: Observable<ProductModel>;
    @Input() public addToCart$: Observable<boolean>;
    @Input() public repInfo$: Observable<RepSiteInfo>;
    @Input() public smartDeliveryInfo: any;
    @Input() public loyaltyPointsInfo: any;
    @Output()
    public onLoyaltyPointsClick = new EventEmitter();
    public product: ProductModel;
    public isLoggedIn: boolean;
    public isDistributor: boolean = false;
    public isAffiliate: boolean = false;

    public currencySymbol: string;
    public quantity: number;
    //public price: string;
    public priceWarning: string;
    //public smartDeliveryPrice: string;
    public showSDButton: boolean;
    public showInSDButton: boolean;
    public showButtonFiller: boolean;
    public isRedemptionMode: boolean;
    public translations: any = {};
    public isOnSmartDelivery: boolean;
    public cartType: string;
    public selectedImg: string;
    public config: ProductConfigModel;
    public showNVShadeMatch: boolean;
    public canAddToCart: boolean = true;
    public disableSelection: boolean = false;
    public selectedConfigs: Array<ProductConfigModel> = new Array<ProductConfigModel>();
    public groupedConfigurations: any;
    //public showAffiliatePricing: boolean = true;
    public isComingSoon: boolean = false;
    public rep: RepSiteInfo;
    public cart: ShoppingCart;
    public showCv: boolean;

    public pricePIW: number;
    public saveAmount: number;
    public profitAmount: number;
    public clientInfo: ClientInfo;
    private subs = new SubSink();

    constructor(
        private readonly authService: AuthService,
        private readonly mainCartService: MainCartService,
        private readonly smartDeliveryService: SmartDeliveryService,
        private readonly location: Location,
        private readonly productTranslationService: ProductTranslationService,
        private readonly productSeoService: ProductSeoService,
        private readonly clientInfoService: ClientInfoService) { }

    public ngOnInit(): void {
        this.subs.sink = this.mainCartService.getMainCart$().subscribe((cart) => {
            this.cart = cart;
            this.cartType = cart.cartType;
        });
        this.clientInfo = this.clientInfoService.getClientInfo();
        this.isLoggedIn = this.authService.isLoggedIn;
        this.subs.sink = this.authService.tokenChanged.subscribe((changed: boolean) => {
            if (changed) {
                this.isLoggedIn = this.authService.isLoggedIn;
            }
        });

        // Show Wholesale Pricing
        // If logged in user is affiliate or distributor
        // If logged in user is customer with and an affiliate or distributor referrer

        this.quantity = 0;
        this.subs.sink = this.repInfo$.subscribe((repInfo: RepSiteInfo) => {
            this.rep = repInfo;
        });

        this.subs.sink = this.productTranslationService.getTranslationsObservable$.subscribe((translations) => {
            this.translations = translations;
        });

        this.subs.sink = this.product$.subscribe((p: ProductModel) => {
            this.product = new ProductModel(p);
            this.isAffiliate = this.product.mainType === 22;
            this.isDistributor = [1, 3, 6, 13].includes(this.product.mainType);
            this.isRedemptionMode = this.product.priceType === 'LR';
            this.isOnSmartDelivery = this.product.activeSmartDelivery;
            if (this.product && this.product.productPK > 0) {
                // if (!this.product.isVolumeBasedRSB && (!this.isDistributor && !this.isAffiliate)) {
                //     this.showAffiliatePricing = false;
                // }

                this.groupConfiguration(this.product.configurations);
                if (this.product.configurations.some( x => x.productPK === 0 ))
                    this.disableSelection = true;

                // this.price = this.product.pricing.filter(x => x.priceType === this.product.priceType)[0].formattedPrice;
                this.priceWarning = this.product.pricing.filter(x => x.priceType === this.product.priceType)[0].priceWarning;
                // this.smartDeliveryPrice = this.product.pricing.filter(x => x.priceType === 'W')[0].formattedPrice;
                this.currencySymbol = this.product.pricing[0].currencySymbol;
                this.isComingSoon = this.product.isComingSoon;

                this.calculateAdditionalAmounts();
                // Subscribe to SmartDeliveryProfile Info
                this.subs.sink = this.smartDeliveryService.getSettings$()
                    .subscribe((smartDeliveryModel: SmartDeliveryModel) => {
                        let alreadyInSd: boolean = false;
                        if (smartDeliveryModel && smartDeliveryModel.smartDeliveryItems) {
                            if (this.product.isConfigurable) {
                                this.selectedConfigs.forEach((config: ProductConfigModel) => {
                                    if (smartDeliveryModel.smartDeliveryItems.filter(x => x.productPK === config.productPK)[0]) {
                                        alreadyInSd = true;
                                    }
                                });

                            } else if (this.product.configurations.length === 0) {
                                alreadyInSd = smartDeliveryModel.smartDeliveryItems.filter(x => x.productPK === this.product.autoshipProductPk)[0] ? true : false;
                            }
                        }
                        if (this.authService.isLoggedIn && this.product.offerSDOnShop && (this.product.isProductAvailableOnAutoship && this.product.autoshipProductPk > 0)) {
                            this.showSDButton = !alreadyInSd;
                            this.showInSDButton = alreadyInSd;
                        } else {
                            this.showSDButton = false;
                            this.showInSDButton = false;
                        }
                    });
            }
        });

        this.subs.sink = this.addToCart$.subscribe((updateComplete: boolean) => {
            this.canAddToCart = updateComplete;
        });
    }

    public groupConfiguration(allConfigs): void {
        let groupedConfigs: any =  _(allConfigs)
                                    .groupBy(item => item.configurableProductPK)
                                    .sortBy(group => allConfigs.indexOf(group[0]))
                                    .value();
        this.groupedConfigurations = _.map(groupedConfigs, (configs, key) => {
            return configs;
        });
    }

    public addToCart(addToSmartDelivery?: boolean): void {
        if (addToSmartDelivery === void 0) { addToSmartDelivery = false; }
        this.product.quantity = this.product.quantity ? this.product.quantity : 1;
        if (!addToSmartDelivery) {
            this.canAddToCart = false;
        }

        if (this.selectedConfigs && this.selectedConfigs.length > 0) {
            let lastModel: Array<ProductConfigModel> = new Array<ProductConfigModel>();
            _.forEach(this.selectedConfigs, ((config, key) => {
                let model: ProductConfigModel = new ProductConfigModel(null);
                model.packageFK = config.packageFK;
                model.configurableProductPK = config.configurableProductPK;
                model.productPK = config.productPK;
                model.productCode = config.productCode;
                model.productName = config.productName;
                model.description = config.description;
                model.image = config.image;
                lastModel.push(model);
            }));
            this.product.selectedConfig = lastModel;
        }

        this.mainCartService.addToCart(this.product, this.smartDeliveryInfo, addToSmartDelivery, this.loyaltyPointsInfo, true);
    }

    public checkIntValue(): void {
        this.quantity = this.mainCartService.checkIntValue(this.product);
    }

    public onLoading(config: ProductConfigModel): void {
        this.config = config;
    }

    /**
     * Sets the configuration product option when an item is selected.
     * @param config
     */
    public onSelected(config: ProductConfigModel): void {
        _.remove(this.selectedConfigs, c => {
            return c.configurableProductPK === config.configurableProductPK;
        });
        this.selectedConfigs.push(config);
        this.isComingSoon = this.product.isComingSoon || this.selectedConfigs.some( x => x.isComingSoon);

        if (this.smartDeliveryInfo && this.smartDeliveryInfo.active && this.product.isConfigurable) {
            this.selectedConfigs.forEach((config: ProductConfigModel) => {
                if (!this.product.offerSDOnShop) {
                    this.showInSDButton = false;
                    this.showSDButton = false;
                } else if(this.smartDeliveryInfo.smartDeliveryItems.some(x => x.productPK === config.productPK)) {
                    this.showInSDButton = true;
                    this.showSDButton = false;
                } else {
                    this.showInSDButton = false;
                    this.showSDButton = true;
                }
            });
        }
    }

    /**
     * Fires when a user uses the easy match shade selection.
     * @param matchName NV foundation match string
     */
    public easyMatchFound(matchName: string): void {
        const config: ProductConfigModel = this.product.configurations.filter(x => x.attribute1 === matchName)[0];
        this.config = config;
    }

    public checkSD(): void {
        this.product.setupForAs = !this.product.setupForAs;

        if (this.product.setupForAs) {
            this.productSeoService.sendGAInfo(this.product, 'select - recurring subscription');
        } else {
            this.productSeoService.sendGAInfo(this.product, 'deselect - recurring subscription');
        }
    }

    public sendAdditionalInfo(name: string): void {
        this.productSeoService.sendGAInfo(this.product, 'open - dropdown - ' + name);
    }
    public goBack(): void {
        this.location.back();
    }
    public emitOpenLpInfoDialog(): void {
        this.onLoyaltyPointsClick.emit();
    }

    public getProductPrice(pricing: ProductPriceModel[], priceType: string): string {
        const price: ProductPriceModel = pricing.filter(x => x.priceType === priceType)[0];
        if (price) {
            return `${price.currencySymbol}${price.formattedPrice}`;
        }
        return '';
    }

    private calculateAdditionalAmounts(): void {
        const price: ProductPriceModel = this.product.pricing ? this.product.pricing.filter(p => p.priceType === this.product.priceType)[0] : null;
        const pricePIW: ProductPriceModel = this.product.pricing ? this.product.pricing.find(p => p.priceType === 'PIW') : null;
        const pricePIR: ProductPriceModel = this.product.pricing ? this.product.pricing.find(p => p.priceType === 'PIR') : null;
        if (pricePIW) {
            this.pricePIW = pricePIW.price;
            this.saveAmount = this.pricePIW - price.price;
        }

        if (pricePIR) {
            this.profitAmount = -(price.price - pricePIR.price);
        }
    }
}
