import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { eventReducer } from './state/reducers/index';
import { AgendasInfoEffects } from './public-api';
import { RadioButtonComponent } from './field-components/radioButton.component';
import { CheckBoxComponent } from './field-components/checkBox.component';
import { DateComponent } from './field-components/date.component';
import { InputComponent } from './field-components/input.component';
import { SelectComponent } from './field-components/select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DynamicFormComponent } from './field-components/dynamic-form.component';
import { DynamicFieldDirective } from './field-components/dynamicField.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CmsModule } from '@jeunesse/angular';

@NgModule({
    declarations: [
        CheckBoxComponent,
        DateComponent,
        InputComponent,
        SelectComponent,
        RadioButtonComponent,
        DynamicFieldDirective,
        DynamicFormComponent, InputComponent, SelectComponent, DateComponent, RadioButtonComponent, CheckBoxComponent, DynamicFormComponent
    ],
    imports: [
        CommonModule,
        StoreModule.forFeature('event', eventReducer),
        EffectsModule.forFeature([AgendasInfoEffects]),
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatRadioModule,
        FlexLayoutModule,
        CmsModule
    ],
    entryComponents: [InputComponent, SelectComponent, DateComponent, RadioButtonComponent, CheckBoxComponent, DynamicFormComponent],
    exports: [DynamicFormComponent, InputComponent, SelectComponent, DateComponent, RadioButtonComponent, CheckBoxComponent, DynamicFormComponent]

})
export class EventsModule {}
