import { Injectable } from '@angular/core';

import { AddressModel } from '../models/address.model';

@Injectable({
    providedIn: 'root',
})
export class AddressLibStateService {
    public states: any[] = [];
    private currentAddressState: AddressModel[] = new Array();

    constructor() {}

    public getAddressState(formIteration: number = 0): AddressModel {
        return this.currentAddressState[formIteration];
    }

    // the provided property had been updated, so update the addressState and notify observers
    public setProperty(propertyName: string, propertyValue: any, formIteration: number = 0): string {
        let currentValue: any = this.currentAddressState[propertyName];
        if (propertyValue !== currentValue) {
            if (propertyName === 'state' && propertyValue) {
                propertyValue = this.checkStateValue(propertyValue, formIteration);
            }

            this.currentAddressState[formIteration][propertyName] = propertyValue;
        }
        return propertyValue;
    }

    public clearAddressState(formIteration: number = 0): void {
        this.currentAddressState[formIteration] = new AddressModel();
    }

    public checkStateValue(value: any, formIteration: number = 0): string {
        if (!value) {
            value = this.currentAddressState[formIteration].state;
        }
        let state = this.states.length === 1 ? this.states[0].value : value;
        if (this.states.filter((x) => x.value === state).length > 0) {
            return this.states.filter((x) => x.value === state)[0].value;
        } else {
            return null;
        }
    }
}
