import { Injectable } from '@angular/core';

import { UrlHelperService, AuthHttpService } from '@jeunesse/angular/authentication';
import { OfficesQuery } from '../models/offices-query.interface';
import { HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomerService {
    private _officesQuery = new Map<string, Observable<any>>();
    constructor(private readonly urlHelper: UrlHelperService, private readonly authHttp: AuthHttpService) {}

    /**
     * Use to pull a list of office/customer service information
     * API: https://cms-api.jeunesseglobalbuild.com/api/v1/offices
     */
    public GetOffices(model: OfficesQuery): Observable<any> {
        var params = new HttpParams();
        if (model.countryCode) {
            params = params.append('countryCode', model.countryCode);
        }
        params = params.append('isAdmin', model.isAdmin ? 'true' : 'false');

        const query: string = params.toString();

        if (!this._officesQuery.get(query)) {
            this._officesQuery.set(
                query,
                this.authHttp.get(this.urlHelper.toCmsApi('/v1/offices'), { params }).pipe(
                    map(
                        (offices: any[]) => {
                            if (offices && offices.length > 0) {
                                return offices[0];
                            } else {
                                return of(null);
                            }
                        },
                        (error: any) => {
                            console.error(error);
                            return of(null);
                        }
                    ),
                    shareReplay()
                )
            );
        }

        return this._officesQuery.get(query);
    }
}
