import { ActivatedRoute, Params } from '@angular/router';
// import { RepSiteInfo } from '../repsite/public-api';
// import { CartFormModel, ShoppingCartService } from '../shopping-cart/public-api';

export class BaseComponent {
    // private readonly repSiteInfo: RepSiteInfo;
    public siteUrl: string;
    constructor(protected readonly activatedRoute: ActivatedRoute) {
        this.activatedRoute.params.subscribe((params: Params) => {
            if (params.siteUrl) {
                this.siteUrl = params.siteUrl;
            }
        });
        // this.repSiteInfo = this.activatedRoute.snapshot.data.repInfo;
        // // Check to see if we have a valid rep site.
        // if (this.repSiteInfo.mainPk === 0 || (this.repSiteInfo.mainPk > 0 && !this.repSiteInfo.isValid)) {
        //     alert('Invalid Rep');
        // } else if (this.repSiteInfo.mainPk > 0 && this.repSiteInfo.isValid) {
        //     // Update Shopping Cart
        //     let cartForm: CartFormModel = new CartFormModel();
        //     cartForm.mainReferrerFk = this.repSiteInfo.mainPk;
        //     cartForm.referrerSiteUrl = this.repSiteInfo.alias;
        //     this.shoppingCartService.patchShoppingCart(cartForm);
        // }
    }
}
