import { Component, OnInit, Input, Output, EventEmitter, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { ManualPayment } from '../../models/manual-payment.model';
//import { ManualPaymentType } from '../../models/manual-payment-type.model';
import { PaymentInfo } from '../../models/payment-info.model';
import { Observable, Subscription } from 'rxjs';
import { ManualPaymentService } from '../../services/manual-payment.service';

@Component({
  selector: 'jn-manual-payment-confirmation',
  templateUrl: './manual-payment-confirmation.component.html',
  styleUrls: ['./manual-payment-confirmation.component.scss']
})
export class ManualPaymentConfirmationComponent implements OnInit {

    @Input() public orderNumber: number;
    @Input() public display: string = "messages";
    @Input() public paymentMethodTypePK: number;
    public subscriptions: Subscription = new Subscription();
    public messages: PaymentInfo[] = [];
    public infos: PaymentInfo[] = [];
    public payTypes: PaymentInfo[] = [];

    constructor(public manualPaymentService: ManualPaymentService) {

    }

    ngOnInit() {
        this.showInfo();   
    }
    // garbage collection
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    ngOnChanges(changes: SimpleChanges) {
        this.showInfo();
    }
    private showInfo() {
        if (+this.orderNumber > 0) {
            this.subscriptions.add(this.manualPaymentService.getOrderPaymentInfo(+this.orderNumber).subscribe((data) => {
                this.messages = data.filter(info => +info.merchantManualTypeFK == 0);
                this.infos = data.filter(info => +info.merchantManualTypeFK != 0 && info.key != 'PayType' && info.value);
                this.payTypes = data.filter(info => info.key == 'PayType');
            }));
        }
    }
}
