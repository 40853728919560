import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { filter, first, concat, flatMap, toArray } from 'rxjs/operators';

import { Breadcrumb } from './models/public-api';

/**
 * Gets the breadcrumbs that are set on the route. Sends update on route change.
 *
 * A resolver with a base class of {@link BreadcrumbsBaseResolver} will need to be used for getting
 * the breadcrumbs. The resolver {@link BreadcrumbsResolver} already supports translation from CMS.
 *
 * Example usage:
 * ```ts
 * public ngOnInit(): void {
 *     this.breadcrumbsService.crumbs.subscribe((data: Breadcrumb[]) => {
 *         this.breadcrumbs = data;
 *     });
 * }
 * ```
 */
@Injectable()
export class BreadcrumbsService {

    private breadcrumbsSubject: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

    constructor(
        private readonly router: Router) {

        this.router.events
            .pipe(filter((x) => x instanceof NavigationEnd))
            .subscribe(() => {
                const currentRoot: ActivatedRouteSnapshot = router.routerState.snapshot.root;
                this.resolveCrumbs(currentRoot)
                    .pipe(flatMap((x) => x), toArray())
                    .subscribe((x) => {
                    this.breadcrumbsSubject.next(x);
                });
            });
    }

    /**
     * Returns the observable for the {@link Breadcrumb[]}.
     * @returns The observable.
     */
    public get crumbs(): Observable<Breadcrumb[]> {
        return this.breadcrumbsSubject;
    }

    private resolveCrumbs(route: ActivatedRouteSnapshot): Observable<Breadcrumb[]> {
        let crumbs: Observable<Breadcrumb[]>;
        const data: Breadcrumb[] = route.data && route.data.breadcrumbs;

        if (data) {
            crumbs = of(data).pipe(first());
        }
        else {
            crumbs = of([]);
        }

        if (route.firstChild) {
            crumbs = crumbs.pipe(concat(this.resolveCrumbs(route.firstChild)));
        }

        return crumbs;
    }
}
