import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ActivatedRoute, Router, Params, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';

import {
    CategoryService, CategoryModel,
    LoyaltyPointsModel,
    OrderService,
    ProductStateService,
    MenuStateService,
    ProductFilterComponent,
    ProductSorterComponent,
    MemberService, SideMenuService
} from '@jeunesse/cart-lib';

import { TranslationService, LocaleService, WebStorageService, AuthService, ApplicationType, HeaderNotificationComponent } from '@jeunesse/angular/cms';
import { SeoService, LoggerService, LoggerTypeEnum } from '@jeunesse/angular/common';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { LoyaltyPointsInfoDialogComponent } from './loyalty-points-info-dialog/loyalty-points-info-dialog.component';
import { SnackBarService } from '../../shared/snack-bar/snack-bar.service';
import { SubSink } from 'subsink';
import { MainCartService } from '@jeunesse/slide-cart/main-cart-service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { startWith, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DialogExitImportCartComponent} from '@jeunesse/slide-cart';
import { DistUpgradeModalComponent } from './dist-upgrade/dist-upgrade-modal.component';
import { Direction } from '@angular/cdk/bidi';

@Component({
    templateUrl: './catalog.component.html',
    styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit, OnDestroy {
    public mobileQuery: MediaQueryList;
    public sortOptions: any;
    public sortBy: string;
    public loyaltyPointsInfo: LoyaltyPointsModel;
    public isInRedemptionMode: boolean;
    public pageTitle: string;
    public brandTitle: string;
    public brand: any;
    public textStyle: string;
    public cartType: string;
    public culture: string;
    public countryCode: string;
    public application: ApplicationType = ApplicationType.Shop;
    public category: CategoryModel;
    public layoutForm: FormGroup;
    public translations: any = {
        sort: ''
    };
    public searchTerm: any;
    public searchLists: [];
    public isCreateAPac: boolean = false;
    public siteUrl: string;
    public viewType: string;
    public isShowSearchBar: boolean = false;
    public isMenuOpen: boolean = false;
    public showTopMenu: boolean = false;
    public isProductDescription: boolean = false;
    public allowImportCart = false;
    public mainType: number;
    public importCart: boolean = false;
    public itemCount: number = 0;
    public cart: any = {};
    public allowRetailSignup: boolean;
    public hasRetailStarterKit: boolean;
    @ViewChild('headerNotification', {static: false}) headerNotificationComponent: HeaderNotificationComponent;
    private _mobileQueryListener: () => void;
    private directionality: Direction = 'ltr';
    private subs = new SubSink();

    constructor(
        public readonly dialog: MatDialog,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly media: MediaMatcher,
        private readonly route: ActivatedRoute,
        private readonly memberService: MemberService,
        private readonly router: Router,
        private readonly mainCartService: MainCartService,
        private readonly categoryService: CategoryService,
        private readonly seoService: SeoService,
        private readonly translationService: TranslationService,
        private readonly localeService: LocaleService,
        private readonly loggerService: LoggerService,
        private readonly snackBarService: SnackBarService,
        private readonly formBuilder: FormBuilder,
        private readonly webStorage: WebStorageService,
        private readonly productStateService: ProductStateService,
        private readonly menuStateService: MenuStateService,
        private readonly authService: AuthService,
        private readonly orderService: OrderService,
        public readonly sideMenuService: SideMenuService
    ) {
        this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.isInRedemptionMode = this.mainCartService.isStoreInRedemptionMode;

        this.subs.sink = this.route.params.subscribe(params => {
            if (params.siteUrl) {
                this.siteUrl = params.siteUrl;
            }
        });
        this.subs.sink = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isCreateAPac = false;
                let path: string = event.url.split('?')[0];
                if (path.includes('/catalog/create-a-pack')) {
                    this.isCreateAPac = true;
                }
                if (path.includes('/products/')) {
                    this.isProductDescription = !this.isProductDescription;
                } else {
                    this.isProductDescription = false;
                }
            }
        });
        // Initial viewType to apply a active-view class
        this.subs.sink = this.productStateService.getProductsDisplayState$().subscribe((viewType: string) => {
            this.viewType = viewType;
        });
        if (this.authService.isLoggedIn) {
            this.setupAuthenticatedUser();
        }
    }

    public ngOnInit(): void {
        this.culture = this.getSetKey() || this.localeService.getLocaleCookie();
        this.countryCode = this.localeService.getCountryCode();
        this.subs.sink = this.route.queryParams.subscribe((params: Params) => {
            this.getTranslations(params);
        });
        // Load the user's loyalty points
        this.subs.sink = this.memberService.getLoyaltyPointsInfo().subscribe((lp: LoyaltyPointsModel) => {
            this.loyaltyPointsInfo = lp;
        });

        this.subs.sink = this.loggerService.loggerObservable$.subscribe((log) => {
            if (log) {
                if (log.logType === LoggerTypeEnum.ERROR) {
                    this.snackBarService.warn(log.message);
                } else if (log.logType === LoggerTypeEnum.INFO) {
                    this.snackBarService.info(log.message);
                }
            }
        });
        this.subs.sink = this.mainCartService.getMainCart$().subscribe((cart) => {
            this.cart = cart;
            this.cartType = cart.cartType;
            this.allowImportCart = cart.allowImportCart;
            this.importCart = cart.cartType === 'I';
            this.itemCount = cart.quantity;
            this.allowRetailSignup = cart.allowRetailSignup;
            this.hasRetailStarterKit = cart.hasRetailStarterKit;
            this.countryCode = cart.countryCode;
            // Refresh notification control
            if (this.headerNotificationComponent) {
                this.headerNotificationComponent.countryCode = cart.countryCode;
                this.headerNotificationComponent.culture = cart.culture;
                this.headerNotificationComponent.loadMessages();
            }
        });
        this.layoutForm = this.formBuilder.group({
            myControl: ''
        });
        this.subs.sink = this.localeService.countryCodeObservable$.subscribe((res) => {
            if (this.authService.isLoggedIn) {
                this.setupAuthenticatedUser();
            }
        });
        this.subs.sink = this.authService.tokenChanged.subscribe((loggedIn: boolean) => {
            if (loggedIn) {
                this.setupAuthenticatedUser();
                const mainType: number = this.authService.getMainTypeId();
                if ([1, 22, 3, 6].includes(mainType)) {
                    this.productStateService.setProductsDisplayState$('row');
                } else {
                    this.productStateService.setProductsDisplayState$('column');
                }
                this.culture = this.authService.getCulture();
            }
        });
        //this.menuStateService.getOpenCloseMenu().subscribe((data) => {
        //    this.isMenuOpen = data[0];
        //});
        this.menuStateService.getCategoryMenuOpenState$().subscribe((state: boolean) => {
            this.isMenuOpen = state;
        });
        this.sideMenuService.toggleEvent.subscribe(_ => {
            this.isMenuOpen = false;
        });
        this.subs.sink = this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    public openLpInfoDialog(): void {
        this.dialog.open(LoyaltyPointsInfoDialogComponent, { direction: this.directionality});
    }

    public exitRedeemShop(): void {
        window.sessionStorage.clear();
        this.subs.sink = this.mainCartService.clearCart().subscribe(resp => {
            location.reload();
        });
    }

    public sendGAInfo(category: string, action: string, label?: string): void {
        label = this.router.url + label;
        this.seoService.trackClick(label, action, category);
    }

    public sendSortInfo(sort: string): void {
        this.seoService.trackClick(sort, 'sort', 'sidenav');
    }

    public onSearch(): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                searchTerm: this.searchTerm,
                m: (this.isCreateAPac ? '53' : null)
            },
            queryParamsHandling: null
          });
        this.searchTerm = '';
    }
    // Change Display Type
    public changeDisplayType(viewType: string): void {
        this.viewType = viewType;
        this.productStateService.setProductsDisplayState$(this.viewType);
    }

    public sortChange(sortBy: string): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                sort: sortBy
            },
            queryParamsHandling: 'merge'
          });
    }
    public showSearcher(): void {
        this.isShowSearchBar = !this.isShowSearchBar;
    }

    public onShopMenuClick(): void {
        this.menuStateService.toggleCategoryMenuOpenState();
    }

    public onCartClick(openCart: boolean): void {
        this.menuStateService.showHideCartState(openCart);
    }

    public openFilter(): void {
        this.dialog.open(ProductFilterComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            data: {ActivatedRoute: this.route},
            direction: this.directionality
          });
    }

    public openSorter(): void {
        const dialogRef: MatDialogRef<ProductSorterComponent, any> = this.dialog.open(ProductSorterComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            autoFocus: false,
            data: {sortOptions: this.sortOptions, sortBy: this.sortBy},
            direction: this.directionality
          });

        dialogRef.afterClosed().subscribe(result => {
            if (result.data !== this.sortBy) {
                this.sortChange(result.data);
            }
        });
    }
    public triggerProductsSearch(): void {
        this.subs.sink = this.layoutForm.get('myControl')
        .valueChanges.pipe(
            startWith(''),
            // Start filtering after 2 characters
            filter((text: string) => text.length > 1),
            // Wait for pause
            debounceTime(400),
            // Only if the value has changed
            distinctUntilChanged()
        ).subscribe((x) => {
            const model: any = { culture: this.culture, cartType: this.cartType , value: x };
            this.subs.sink = this.orderService.searchForProducts(model).subscribe((x) => {
                this.searchLists = x;
            });
        });
    }
    public onProductSelect(product): void {
        this.router.navigate(['/', this.siteUrl, 'catalog', 'products', product.productFK]);
        this.searchTerm = '';
    }

    public toggleImportCart(): void {
        if (this.importCart) {
            // Show Exit Import Dialog
            const config: MatDialogConfig = {
                data: {
                    exitImportCart: true
                },
                direction: this.directionality
            };
            const dialogRef: MatDialogRef<DialogExitImportCartComponent> = this.dialog.open(DialogExitImportCartComponent, config);

            this.subs.sink = dialogRef.afterClosed().subscribe((res: any) => {
                if (res) {
                    // exit import cart
                    this.subs.sink = this.mainCartService.clearCart().subscribe(() => {
                        this.importCart = false;
                        this.router.navigate([this.siteUrl, 'catalog', 'products']);
                    });
                }
            });
        } else {
            const config: MatDialogConfig = {
                data: {
                    exitImportCart: false
                },
                direction: this.directionality
            };
            const dialogRef: MatDialogRef<DialogExitImportCartComponent> = this.dialog.open(DialogExitImportCartComponent, config);

            this.subs.sink = dialogRef.afterClosed().subscribe((res: any) => {
                if (res) {
                    // remove the cart items
                    this.mainCartService.removeCartItems(false);
                    // update cart to be import cart
                    let cartUpdate: any = {cartType: 'I'};
                    this.mainCartService.updateCart(cartUpdate).subscribe();
                    this.router.navigate([this.siteUrl, 'catalog', 'products']);
                }
            });
        }
    }

    public returnToShop(): void {
        this.mainCartService.updateCart({ 'isRetailSignup': false }).subscribe();
    }

    public upgradeToDistClick(): void {
        if (this.cart && this.cart.offerLoyaltyProgram) {
            const dialogRef = this.dialog.open(DistUpgradeModalComponent,
                {
                    hasBackdrop: true,
                    disableClose: false,
                    autoFocus: true,
                    direction: this.directionality
                });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.router.navigate(['/', 'retail-signup']);
                }
            });
        } else {
            this.router.navigate(['/','retail-signup']);
        }
    }

    private getTranslations(params: Params): void {
        this.setTranslations(params);
        this.subs.sink = this.translationService.getTranslationCacheObservable().subscribe(() => {
            this.setTranslations(params);
        });
    }

    private setTranslations(params: Params): void {
        // Load Translations
        this.sortOptions = [
            { value: 'default', text: this.translationService.translate('shop', 'defaultSort', 'Default Sort') },
            { value: 'asc', text: this.translationService.translate('shop', 'asc', 'A-Z') },
            { value: 'desc', text: this.translationService.translate('shop', 'desc', 'Z-A') },
            { value: 'low', text: this.translationService.translate('shop', 'low', 'Price: Low to High') },
            { value: 'high', text: this.translationService.translate('shop', 'high', 'Price: High to Low') }
        ];
        if (!this.isCreateAPac) {
            this.sortOptions.push({ value: 'smartDelivery', text: this.translationService.translate('shop', 'smartDelivery', 'Smart Delivery')});
        }

        this.translations.sort = this.translationService.translate('shop', 'sort', 'Sort');
        this.translations.search = this.translationService.translate('shop', 'search', 'Search');

        this.pageTitle = '';
        this.brandTitle = '';
        this.category = null;
        this.brand = null;
        this.sortBy = params['sort'] ? params['sort'] : 'default';
        const categoryId: number = params['c'] ? Number(params['c']) : 0;
        const brandId: number = params['b'] ? Number(params['b']) : 0;

        /**
         * catergory breadcrumb
         */
        if (categoryId) {
            this.category = this.categoryService.getCategory(categoryId);
            if (this.category) {
                this.pageTitle = this.translationService.translate('categories', this.category.name, this.category.name);
                this.textStyle = 'category-style';
            }
        }

        /**
         * Category + brand breadcrumb
         */
        if (categoryId && brandId) {
            this.brand = this.categoryService.getBrand(brandId, categoryId);
            if (this.brand) {
                this.brandTitle = ' / ' + this.brand.name;
                this.textStyle = 'brand-style';
            }
        }
    }

    private getSetKey(): string {
        let culture: string = this.webStorage.getCookie('SetCulture').replace(/"/g, '');
        if (culture) {
            this.webStorage.setCookie('Culture', JSON.stringify(culture));
        }
        return culture;
    }

    private setupAuthenticatedUser(): void {
        this.mainType = this.authService.getMainTypeId();
        this.showTopMenu = [1, 22, 21, 3, 6, 2].includes(this.authService.getMainTypeId());
    }
}
