import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { SnackBarService } from './shared/snack-bar/snack-bar.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private hideErrors: string[] = ['ExpressionChangedAfterItHasBeenCheckedError', 'Error: {"isTrusted":true}', 'Error: {"message":"Unable to get the Offices"}'];

    constructor(private readonly snackBarService: SnackBarService, private zone: NgZone) {}

    private hasHide(message: any): boolean {
        let msg : string = message ? message.toString(): '';
        return (this.hideErrors.some(error => msg.indexOf(error) > -1));
    }

    public handleError(error): void {
        this.zone.run(() => {
            console.error('Error', error);
            if(error && !this.hasHide(error)) this.snackBarService.warn(error);
        });
    }
}
