export class SingleDocument {
    public fileName: string;
    public fileSize: number;
    public fileType: string;
    public mainPk: number;
    public signupDocumentsPK: number;
    public userDocumentsPk: number;
    public isCorporate: boolean;
    public classification: string;
    public signupKey: string;
    public expDate?: Date | string;
    public noExpiry?: boolean;

    constructor(options: any = {}) {
        this.fileName = options.fileName;
        this.fileSize = options.fileSize;
        this.fileType = options.fileType;
        this.mainPk = options.mainPk;
        this.signupDocumentsPK = options.signupDocumentsPK;
        this.userDocumentsPk = options.userDocumentsPk;
        this.isCorporate = options.isCorporate;
        this.classification = options.classification;
        this.signupKey = '';
        this.expDate = options.expDate;
        this.noExpiry = options.noExpiry;
    }
}
