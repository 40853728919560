import { Injectable } from '@angular/core';
import { of, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, shareReplay } from 'rxjs/operators';

import { AuthHttpService } from '../../authentication/src/auth-http.service';
import { AuthService } from '../../authentication/src/auth.service';
import { SettingsService } from '../../common/src/settings.service';
import { CmsSessionService } from './cms-session.service';
import { UrlHelperService } from '../../common/src/url-helper.service';
import { WebStorageService } from '../../common/src/web-storage.service';
import { TranslationViewModel, TranslationFormModel } from './models/public-api';
import { AddTranslation } from './interfaces/public-api';
import { LocaleService } from './locale.service';
import { CmsViewModel } from './models/cms-view.model';

/**
 * This is used to load translations and return translations.
 */
@Injectable({ providedIn: 'root' })
export class TranslationService {
    private readonly defaultCulture: string = 'en-US';
    private culture: string = this.defaultCulture;
    private numGetTranslationsCalls: number = 0;
    private numReturnTranslationsCalls: number = 0;
    private translationCache: TranslationViewModel[] = [];
    private highlighted: boolean = false;
    private highlightSubject = new Subject<any>();
    private _cmsSessionSubscription: Subscription;
    public get cmsSessionSubscription(): Subscription {
        return this._cmsSessionSubscription;
    }
    public set cmsSessionSubscription(value: Subscription) {
        this._cmsSessionSubscription = value;
    }

    private sessionSubject = new Subject<any>();
    private translationCacheSubject = new Subject<any>();

    private _setTranslationQuery$ = new Map<string ,Subscription>();

    constructor(
        private readonly urlHelper: UrlHelperService,
        private readonly authHttp: AuthHttpService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService,
        private readonly webStorageService: WebStorageService,
        private readonly settings: SettingsService,
        private readonly authService: AuthService
    ) {
        this.culture = this.cmsSessionService.getCulture();
        this.cmsSessionSubscription = this.cmsSessionService.getSessionObservable().subscribe((session: any) => {
            this.loadCmsSession(session);
        });
    }

    public setTranslationCulture(culture: string): void {
        this.culture = culture;
    }

    public resetTranslations(clearQueryCache: boolean = false): void {
        this.translationCache = [];
        this.translationCacheSubject.next(this.translationCache);
        let session: any = this.cmsSessionService.getSession();
        if (clearQueryCache) {
            this._setTranslationQuery$ = new Map<string, Subscription>();
        }
        this.loadCmsSession(session);
    }

    /**
     * Loads translations into cache.
     * @param states The states to load into cache.
     * @param culture Overrides culture from cookie or auth token.
     */
    public loadTranslations(states: string | string[], culture?: string): Observable<boolean> {
        this.numGetTranslationsCalls++;
        let cultureName: string = culture || this.cmsSessionService.getCulture();
        let groups: string[] = states instanceof Array ? states : [states];

        // Remove states if we already have them.
        groups = groups.filter((value: string) => {
            return !this.translationCache.some((value2: TranslationViewModel) => {
                return value === value2.state && cultureName === value2.culture;
            });
        });

        // Skip API call if there are no states.
        if (groups.length === 0) {
            return of(true);
        }

        for (let i: number = 0; i < groups.length; i++) {
            groups[i] = encodeURIComponent(groups[i]);
        }

        this._setTranslations(cultureName, groups);

        return of(true);
    }

    private _setTranslations(cultureName: string, groups: string[]): void {
        const query: string = JSON.stringify({cultureName, groups});

         if(!this._setTranslationQuery$.get(query)) {
            this._setTranslationQuery$.set(
                query,
                this.localeService
                .getSupportedCultureForShop(cultureName)
                .pipe(
                    switchMap((culture: string) => {
                        this.culture = culture;

                        return this.authHttp.get<CmsViewModel>(this.urlHelper.toCmsApi(`/v1/cms/${culture}`), { params: { states: groups } });
                    }),
                    shareReplay()
                )
                .subscribe(
                    (results: CmsViewModel) => {
                        this.translationCache.push(...results.translations);
                        this.translationCacheSubject.next(this.translationCache);
                        this.loadCmsSession(this.cmsSessionService.getSession());
                        this.numReturnTranslationsCalls++;
                    },
                    () => console.error('Translations were not set!')
                )
           )
        }
    }

    /**
     * Returns the translation text based on the current culture. This can't be called within another service without making
     * a call to [loadTranslations]{@link TranslationService#loadTranslations} first.
     * @param state The state of the translation.
     * @param name The name of the translation.
     * @param content Default content.
     */
    public translate(state: string, name: string, content: string = '', advancedSearch: boolean = false): string {
        let translation: TranslationViewModel;

        if (advancedSearch) {
            let country: string;
            let mainTypeFk: string;
            if (this.authService.isLoggedIn) {
                country = this.authService.getCountry();
                mainTypeFk = this.authService.getMainTypeId().toString();
            } else {
                country =
                    this.webStorageService.getCookie('Country') === ''
                        ? 'US'
                        : this.webStorageService.getCookie('Country').replace(/"/g, '');
                mainTypeFk =
                    this.webStorageService.getCookie('MainType') === ''
                        ? '1'
                        : this.webStorageService.getCookie('MainType').replace(/"/g, '');
            }

            let translationArray: TranslationViewModel[] = this.translationCache.filter(
                (translation) => translation.name === `${name}-${country}-${mainTypeFk}` && translation.state === state
            );

            if (translationArray.length === 0)
                translationArray = this.translationCache.filter(
                    (translation) => translation.name === `${name}-${country}` && translation.state === state
                );

            if (translationArray.length > 0) {
                if (translationArray.find((translation) => translation.culture === this.culture))
                    translation = translationArray.find((translation) => translation.culture === this.culture);
                else translation = translationArray.find((translation) => translation.culture === this.defaultCulture);
            }
        }

        // Find language specific translation
        if (!translation)
            translation = this.translationCache.find(
                (translation) => translation.name === `${name}` && translation.state === state && translation.culture === this.culture
            );

        if (translation) {
            this.checkModuleConnected(
                translation.state,
                translation.name,
                translation.content,
                this.culture,
                translation.modules
            ).subscribe();

            if (translation.content) {
                return translation.content;
            } else {
                return content;
            }
        } else {
            // If translation wasn't found, find the english translation
            let usTranslation: TranslationViewModel = this.translationCache.find(
                (translation) => translation.name === name && translation.state === state && translation.culture === this.defaultCulture
            );
            if (usTranslation) {
                this.checkModuleConnected(
                    usTranslation.state,
                    usTranslation.name,
                    usTranslation.content,
                    this.culture,
                    usTranslation.modules
                ).subscribe();

                if (usTranslation.content) {
                    return usTranslation.content;
                } else {
                    return content;
                }
            } else {
                if (
                    this.cmsSessionService.isTranslator() &&
                    this.culture === this.defaultCulture &&
                    this.numReturnTranslationsCalls >= this.numGetTranslationsCalls
                ) {
                    if (content && content.length > 0 && this.translationCache.length > 0) {
                        this.addTranslation(state, name, content, this.culture).subscribe();
                        console.warn(`Adding New Translation: State: ${state} Name: ${name}`);
                        return content;
                    }
                    return content;
                } else {
                    // translation was not in the cache, just return the default content
                    return content;
                }
            }
        }
    }

    public findTranslation(state: string, name: string): TranslationViewModel {
        return this.translationCache.find((translation) => translation.name === name && translation.state === state);
    }

    public getStateTranslation(state: string): TranslationViewModel[] {
        return this.translationCache.filter((translation) => translation.state === state);
    }

    public loadCmsSession(session: any): void {
        if (session.translations) {
            session.translations.forEach((element) => {
                if (element.culture === this.culture) {
                    let index: number = this.translationCache.findIndex(
                        (translation) => translation.name === element.name && translation.state === element.state
                    );
                    if (index >= 0) {
                        this.translationCache.splice(index, 1);
                    }
                    this.translationCache.push(element);
                }
            });

            this.sessionSubject.next(session);
        }
    }

    public saveTranslation(model: TranslationFormModel): Observable<any> {
        model.culture = this.culture;
        model.module = this.settings.siteName;
        let options: any = { headers: { Culture: model.culture } };
        return this.authHttp.post<any>(this.urlHelper.toCmsApi('/v1/cms/translation/save'), model, options).pipe(
            map((data: any) => {
                if (data) {
                    let index: number = this.translationCache.findIndex(
                        (translation) => translation.name === data.name && translation.state === data.state
                    );
                    this.translationCache[index] = data;
                    return true;
                } else {
                    return false;
                }
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    public toggleHighlight(): void {
        this.highlighted = !this.highlighted;
        this.highlightSubject.next(this.highlighted);
    }

    public checkHighlight(): boolean {
        return this.highlighted;
    }

    public isTranslator(): boolean {
        return this.cmsSessionService.isTranslator();
    }

    public stringFormat(format: string, ...replacements: any[]): string {
        var args = replacements;
        return format.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    }

    public getHighlightedObservable(): Observable<any> {
        return this.highlightSubject.asObservable();
    }

    public getSessionObservable(): Observable<any> {
        return this.sessionSubject.asObservable();
    }

    public getTranslationCacheObservable(): Observable<any> {
        return this.translationCacheSubject.asObservable();
    }

    private checkModuleConnected(state: string, name: string, content: string, culture: string, modules: string[]): Observable<boolean> {
        if (this.cmsSessionService.isTranslator() && culture === this.defaultCulture) {
            let siteName: string = this.settings.siteName;
            if (modules.indexOf(siteName) < 0) {
                const model: AddTranslation = {
                    state: state,
                    name: name,
                    content: content,
                    cssClasses: '',
                    culture: culture,
                    applyToAll: false,
                    module: siteName,
                };

                return this.authHttp.post<any>(this.urlHelper.toCmsApi(`/v1/cms/translation/connectModule`), model).pipe(
                    map((data: any) => {
                        if (data) {
                            return true;
                        } else {
                            console.warn(`Unable to connect translations: State: ${state} Name: ${name}`);
                            return false;
                        }
                    }),
                    catchError(() => {
                        return of(false);
                    })
                );
            }
        }

        return of(false);
    }

    private addTranslation(state: string, name: string, content: string, culture: string): Observable<boolean> {
        const model: AddTranslation = {
            state: state,
            name: name,
            content: content,
            cssClasses: '',
            culture: culture,
            applyToAll: false,
            module: this.settings.siteName,
        };
        // Return API call.
        return this.authHttp.post<any>(this.urlHelper.toCmsApi(`/v1/cms/translation/create`), model).pipe(
            map((data: any) => {
                if (data) {
                    this.translationCache.push(data);
                    return true;
                } else {
                    return false;
                }
            }),
            catchError(() => {
                return of(false);
            })
        );
    }
}
