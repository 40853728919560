import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsSessionService } from '../../cms/src/cms-session.service';
import { TranslationService } from '../../cms/src/translation.service';
import { LocaleService } from '../../cms/src/locale.service';

import { Breadcrumb, BreadcrumbRoute } from './models/public-api';
import { BreadcrumbsBaseResolver } from './breadcrumbs-base.resolver';

/**
 * Gets the text for the breadcrumbs. Uses the CMS translation service.
 *
 * Example usage:
 * ```ts
 * {
 *     path: '',
 *     component: HomeComponent,
 *     data: {
 *         breadcrumb: {
 *             state: 'examples',
 *             name: 'home'
 *         }
 *     },
 *     resolve: {
 *         breadcrumbs: BreadcrumbsResolver
 *     }
 * }
 * ```
 */
@Injectable()
export class BreadcrumbsResolver extends BreadcrumbsBaseResolver {
    constructor(private readonly translationService: TranslationService,
        private readonly localeService: LocaleService,
        private readonly cmsSessionService: CmsSessionService) {
        super();
    }

    /**
     * Gets the current {@link Breadcrumb|Breadcrumb[]} object already translated.
     * @param route Active Route Snapshot.
     */
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> {

        const data: BreadcrumbRoute = route.routeConfig && route.routeConfig.data && route.routeConfig.data.breadcrumb;
        const path: string = this.getFullPath(route);

        if (!data) {
            throw new Error(`Trying to use Breadcrumbs without a BreadcrumbRoute object set: ${path}`);
        }

        if (!data.state && !data.name) {
            console.warn(`Missing BreadcrumbRoute Translation State or Name: ${path}`);
        }

        this.translationService.loadTranslations(data.state).pipe(
            map(() => {
                let currentCulture: string = this.cmsSessionService.getCulture();
                this.localeService.isCultureSupported(currentCulture, this.cmsSessionService.getCmsCountry(currentCulture)).subscribe((returnedCulture) => {
                    this.translationService.setTranslationCulture(returnedCulture);
                    let translationText: string = this.translationService.translate(data.state, data.name, data.name);
                    return [
                        {
                            text: translationText,
                            path: path
                        }
                    ];
                });
            }));

        return of([{ text: data.name, path: path }]);
    }
}
