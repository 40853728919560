import { AnimationTriggerMetadata, trigger, style, state, transition, animate, group } from '@angular/animations';

/**
 * Animations used throughout the site.
 */
export const ANIMATIONS: {
    readonly transformOverlay: AnimationTriggerMetadata;
    readonly transformMenu: AnimationTriggerMetadata;
    readonly transformWidth: AnimationTriggerMetadata;
} = {
    /**
     * Used in overlays.
     */
    transformOverlay: trigger('transformOverlay', [
        state('void, leave', style({ opacity: 0, transform: 'scale(0.8)' })),
        state('enter', style({ transform: 'none' })),
        transition('* => enter', animate('120ms cubic-bezier(0, 0, 0.2, 1)',
            style({ transform: 'none', opacity: 1 }))),
        transition('* => void, * => leave',
            animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 })))
    ]),
    /**
     * Used in expanding menu.
     */
    transformMenu: trigger('transformMenu', [
        transition('void => *', [
            style({ height: '0', opacity: 0 }),
            group([
                animate(300, style({ height: '*' })),
                animate('400ms ease-in-out', style({ 'opacity': '1' }))
            ])
        ]),
        transition('* => void', [
            style({ height: '*', opacity: 1 }),
            group([
                animate(300, style({ height: 0 })),
                animate('200ms ease-in-out', style({ 'opacity': '0' }))
            ])
        ])
    ]),
    /**
     * Used for expanding content.
     */
    transformWidth: trigger('transformWidth', [
        transition('void => *', [
            style({ width: '0', opacity: 0 }),
            group([
                animate(300, style({ width: '*' })),
                animate('400ms ease-in-out', style({ 'opacity': '1' }))
            ])
        ]),
        transition('* => void', [
            style({ width: '*', opacity: 1 }),
            group([
                animate(300, style({ width: 0 })),
                animate('200ms ease-in-out', style({ 'opacity': '0' }))
            ])
        ])
    ])
};