import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { SubSink } from 'subsink';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { RemoveCartItemDialogComponent } from '../remove-cart-item-dialog/remove-cart-item-dialog.component';
import { ShoppingCartItem } from '../../models/public-api';
import { Direction } from '@angular/cdk/bidi';
import { ClientInfo, ClientInfoService, LocaleService } from '@jeunesse/angular';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'jn-cart-item',
    templateUrl: './slide-cart-item.component.html',
    styleUrls: ['./slide-cart-item.component.scss']
})
export class SlideCartItemComponent implements OnInit {
    @Input() public item: ShoppingCartItem;
    @Input() public cartType: string;
    @Input() public isRedemptionMode: boolean;
    @Input() public mainType: number;
    @Input() public cartHasAutoShipItems: boolean;
    @Input() public hasLoyaltyProgram: boolean;
    @Input() public isAffiliateOn: boolean;
    @Input() public isLoggedIn: boolean;
    @Input() public isGuestAccount: boolean;
    @Input() public isCorporate: boolean;
    @Input() public loyaltyPointsInfo: any;
    @Output() public updatedItem = new EventEmitter<ShoppingCartItem>();
    public childItems: any[] = [];
    public showPoints: boolean;
    public productLimit: number = 25;
    public clientInfo: ClientInfo;
    public qtyChange$ = new Subject<string>();
    private subs = new SubSink();
    private directionality: Direction = 'ltr';

    constructor(private readonly dialog: MatDialog,
        private readonly localeService: LocaleService,
        private readonly clientInfoService: ClientInfoService) { }

    public ngOnInit(): void {
        this.clientInfo = this.clientInfoService.getClientInfo();
        this.showPoints = (!this.isGuestAccount && this.hasLoyaltyProgram && !this.isRedemptionMode && this.mainType && ![1, 22, 3, 6].includes(this.mainType));
        this.productLimit = this.item.maxLimit;
        this.item.childItems.forEach(element => {
            if (element) {
                let selectedItem = this.item.childItems.filter((x) => x.productFk === element.productFk)[0];
                if (selectedItem) {
                    this.childItems.push({name: selectedItem.name, imageUrl: selectedItem.imageUrl, qty: selectedItem.quantity});
                } else {
                    this.childItems.push({name: element.name, imageUrl: element.imageUrl, qty: element.quantity});
                }
            }
        });
        this.qtyChange$.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe(value => {
                this.updateQuantity(+value);
            });
        this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
    }

    public updateQuantity(qty: number): void {
        if (!isNaN(qty)) {
            // Do not allow quantity > 1 for zero price items
            if (this.item.unitPrice === 0 && !this.isRedemptionMode) {
                qty = 1;
            } else if (this.isRedemptionMode && ![1, 3, 6].includes(this.mainType) && this.loyaltyPointsInfo) {
                if (this.loyaltyPointsInfo.loyaltyPointsBalance < (this.item.quantity * this.item.points)) {
                    qty = 1;
                }
            } else {
                if (this.item.maxLimit && this.item.maxLimit > 0 && this.item.maxLimit < this.productLimit) {
                    this.productLimit = this.item.maxLimit;
                }
                if (qty > this.productLimit) {
                    qty = Math.floor(this.productLimit);
                } else {
                    qty = Math.floor(qty);
                }
            }
            this.item.quantity = qty;
            this.updateItem();
        }
    }

    public checkSD(): void {
        this.updateItem();
    }

    public removeItem(): void {
        // Show Remove Cart Item Dialog
        const dialogRef: MatDialogRef<RemoveCartItemDialogComponent> = this.dialog.open(RemoveCartItemDialogComponent, { direction: this.directionality });

        this.subs.sink = dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {
                this.item.quantity = 0;
                this.updateItem();
            }
        });
    }

    /**
     * Send the updated item info back to the parent
     */
    private updateItem(): void {
        this.updatedItem.emit(this.item);
    }
}
