export class MerchantInstallmentFormModel {
    public mainOrdersFK: number;
    public splitAvailableAndChecked: boolean;
    public countryCode: string;
    public creditCardType: string;
    public mainOrderTypeFK: number;
    public amountToPay: number;
    public noOfInstallments: number;
    public creditCardNumber: string;
    public creditCardFK: number;

    constructor(options: any = {}){
        this.mainOrdersFK = options.mainOrdersFK;
        this.splitAvailableAndChecked = options.splitAvailableAndChecked;
        this.countryCode = options.countryCode;
        this.creditCardType = options.creditCardType;
        this.mainOrderTypeFK = options.mainOrderTypeFK;
        this.amountToPay = options.amountToPay;
        this.noOfInstallments = options.noOfInstallments;
        this.creditCardNumber = options.creditCardNumber;
        this.creditCardFK = options.creditCardFK;
    }
}