import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductConfigModel } from '../../models/product/product-config.model';
import { ProductModel } from '../../models/product/product.model';
import { SeoService } from '@jeunesse/angular';

@Component({
    selector: 'jn-product-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
    @Input() public product: ProductModel;
    @Input() public configurations: ProductConfigModel[];
    @Output() public selected: EventEmitter<ProductConfigModel> = new EventEmitter();
    @Output() public loading: EventEmitter<ProductConfigModel> = new EventEmitter();
    @Input() public selectedConfig: ProductConfigModel;
    public show: boolean;
    public showNVShadeMatch: boolean;
    public groupedConfiguration: any;
    public showShadePicker: boolean = false;
    constructor(private seoService: SeoService) {}

    public ngOnInit(): void {
        this.select(this.configurations[0]);

        if (this.configurations[0].productName.includes('NV')) {
            this.showShadePicker = true;
        }
    }

    /**
     * Fires when a specific product configuration is selected.
     * @param config ProductConfigModel item
     */
    public select(config: ProductConfigModel): void {
        this.selectedConfig = config;
        if (this.selectedConfig.productName.includes('NV')) {
            this.seoService.trackClick(this.selectedConfig.productName, 'select - ' + this.selectedConfig.attribute1);
        } else {
            this.seoService.trackClick(this.selectedConfig.productName, 'select - ' + this.selectedConfig.productName);
        }
        // BB timeout here 'cause instant emit could cause "Expression has changed after it was checked"
        setTimeout(() => {
            this.selected.emit(config);
            }, 10);
    }

    /**
     * Toggles to show options.
     */
    public showOptions(): void {
        this.show = !this.show;
    }

    public sendGAInfo(label: string, action: string): void {
        this.seoService.trackClick(label, action);
    }

    /**
     * Is NV foundation specific fires when an easy match shade selection if fired.
     * @param matchName Match name
     */
    public easyMatchFound(matchName: string): void {
        let ezConfig = this.configurations.filter(x => x.attribute1 === matchName);
        if(ezConfig && ezConfig.length && ezConfig.length > 0) {
            this.select(ezConfig[0]);
        }
    }
}
