import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { JPAddressLookupViewModel } from "../../models/jp-address-lookup-view.interface";
import { DynamicAddressApiService } from "../../services/dynamic-address-api.service";

@Component({
  templateUrl: './jp-postcode-lookup-dialog.component.html',
  styleUrls: ['./jp-postcode-lookup-dialog.component.scss']
})
export class JpPostcodeLookupDialogComponent implements OnInit, OnDestroy {
  searchResults?: JPAddressLookupViewModel[];
  displayedColumns: string[] = ['postalCode', 'address1', 'address2', 'address3'];
  private readonly searchSubject = new Subject<string | undefined>();
  private subscriptions?: Subscription;
  constructor(public dialogRef: MatDialogRef<JpPostcodeLookupDialogComponent>,
    private readonly dynamicAddressApiService: DynamicAddressApiService) {}

  ngOnInit(): void {
      this.subscriptions =
        this.searchSubject.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap((searchQuery) => this.dynamicAddressApiService.lookupJPAddressByPostalCode({ postalCode: searchQuery }))
        )
        .subscribe((results) => (this.searchResults = results));
  }

  ngOnDestroy(): void {
      this.subscriptions?.unsubscribe();
  }

  selectedRow(row: JPAddressLookupViewModel) {
    this.dialogRef.close(row);
  }

  onClose() {
    this.dialogRef.close(null);
  }

  onSearchQuery(event: Event): void {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchSubject.next(searchQuery?.trim());
  }
}
