import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { ESignatureModalComponant } from "./components/eSignature/eSignature-modal.component";
import { ESignaturePromptComponent } from "./components/eSignature/eSignature-prompt.component";
import { MainUsersService } from "./services/main-user.service";
import { SponsorPlacementComponent } from "./components/sponsor-placement/sponsor-placement.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    ESignaturePromptComponent,
    ESignatureModalComponant,
    SponsorPlacementComponent
  ],
  declarations: [
    ESignaturePromptComponent,
    ESignatureModalComponant,
    SponsorPlacementComponent
  ],
  providers: [
    MainUsersService
  ],
  entryComponents: [
    ESignatureModalComponant
  ]
})
export class MainUsersModule { }
