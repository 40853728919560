import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UrlHelperService } from '../../common/src/url-helper.service';
import { SettingsService } from '../../common/src/settings.service';
import { AuthHttpService } from '../../authentication/src/auth-http.service';

import { PolicyModel, MenuItemModel, PolicyBoolValueEnum, PolicyLevelValueEnum } from './models/public-api';
import { PolicyStateService } from './policy-state.service';

/**
 * This is used to load polices and check against those polices.
 *
 * The policy's code path includes the fragment and not the value.
 */
@Injectable()
export class SecurityService {
    constructor(
        private readonly urlHelper: UrlHelperService,
        private readonly authHttp: AuthHttpService,
        private readonly policyStateService: PolicyStateService,
        private readonly settings: SettingsService
    ) {}

    /**
     * Loads all policies under a path into the security service.
     * @param codePath Full path to the policy.
     */
    public loadPolicies(codePath: string): Observable<boolean> {
        codePath = this.formatCodePath(codePath);
        const policiesCached: PolicyModel[] = this.policyStateService.getPolicyValues();
        // If we find any policy with the path we assume we loaded all policies below.
        if (this.policyStateService.policyExists(codePath)) {
            // policy was found. stop loading.
            return of(true);
        }
        const policyPath: string = codePath.endsWith('\\*') ? codePath : `${codePath}\\*`;
        // proceed to retrive from api
        return this.authHttp.get<PolicyModel[]>(this.urlHelper.toSecurityApi('/v1/policies'), { params: { path: policyPath } }).pipe(
            map((policies: PolicyModel[]) => {
                this.policyStateService.appendPolicies(policies);
                //console.log(this.policyStateService.appendPolicies(policies) + ' policies added.');
                return true;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    /**
     * Returns the policy value of a given code path.
     * The policy needs to be loaded into the cache using [loadPolicies]{@link SecurityService#loadPolicies} before this method can be used.
     * @param codePath The full path to the policy or the last part of the code path.
     * @param activatedRoute The current ActivatedRouteSnapshot used for the path to the policy. This is required if only the last part of the code path is used.
     * @returns Return the Enum based on the policy code path. You must know the type of Enum the code path will return. Returns null if no policy is found.
     */
    public policy(codePath: string, activatedRoute?: ActivatedRouteSnapshot): PolicyBoolValueEnum | PolicyLevelValueEnum | null {
        let formattedPath: string;

        // Add path to the policy code path.
        if (activatedRoute) {
            if (!activatedRoute.data['policy']) {
                console.error(`The policy '${codePath}' was checked without a 'policy' defined on the route.`);
                return null;
            }

            formattedPath = this.formatCodePath(activatedRoute.data['policy'], codePath);
        } else {
            formattedPath = this.formatCodePath(codePath);
        }

        const policy: PolicyModel = this.policyStateService.getPolicy(formattedPath);

        if (!policy) {
            console.warn(`Policy Not Found: ${formattedPath}`);
            return null;
        }

        return policy.value;
    }

    /**
     * Returns the dynamic menu.
     */
    // Sidorov. added manualQuery so we can implicitly get backoffice menu if needed
    public getMenu(manualQuery?: string): Observable<MenuItemModel[]> {
        let url: string;
        if (manualQuery) {
            url = manualQuery;
        } else {
            url = encodeURI(this.settings.claim);
        }
        return this.authHttp.get<any>(this.urlHelper.toSecurityApi(`/v1/menu/${url}`)).pipe(
            map((res: any) => {
                return (res.menuItems || []) as MenuItemModel[];
            })
        );
    }

    /**
     * Invalidates the current menu cache in the API.
     */
    public invalidateMenu(): Observable<void> {
        return this.authHttp.get<any>(this.urlHelper.toSecurityApi(`/v1/menu/invalidate`)).pipe();
    }

    /**
     * Return a formated code path for a policy.
     * @param codePath Code path to the policy. Allows a list of code paths to be combined to form a full code path.
     */
    private formatCodePath(...codePath: string[]): string {
        let formattedPath: string = '';

        for (let i: number = 0; i < codePath.length; i++) {
            formattedPath += formattedPath ? '\\' : '';
            formattedPath += codePath[i]
                .toUpperCase()
                .replace(/\//g, '\\')
                .replace(/^\\|\\$/g, '');
        }

        if (this.settings.claim && formattedPath.indexOf(this.settings.claim + '\\') === -1) {
            formattedPath = this.settings.claim + '\\' + formattedPath;
        }

        return formattedPath;
    }
}
