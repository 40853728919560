import { DataSource } from '@angular/cdk/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import {
    of,
    BehaviorSubject,
    Observable,
    Subscription,
    Subject,
    merge
} from 'rxjs';

import { TableQuery, TableData } from './models/public-api';


/**
 * Data source that accepts a Observable and includes native support of sorting (using MatSort)
 * and pagination (using MatPaginator) on server side.
 */
export class TableDataSource<T> extends DataSource<T> {
    /**
     * If the data is being loaded.
     */
    public get isLoading(): boolean { return this._loading; }


    /**
     * Event for when the {@link TableQuery} object changes.
     */
    public readonly queryChange: Subject<TableQuery> = new Subject<TableQuery>();

    /**
     * Instance of the MatSort directive used by the table to control its sorting. Sort changes
     * emitted by the MatSort will trigger an update to the table's rendered data.
     */
    public get sort(): MatSort | null { return this._sort; }

    public set sort(sort: MatSort | null) {
        this._sort = sort;
        this.updateChangeSubscription();
    }


    /**
     * Instance of the MatPaginator component used by the table to control what page of the data is
     * displayed. Page changes emitted by the MatPaginator will trigger an update to the
     * table's rendered data.
     *
     * Note that the data source uses the paginator's properties to calculate which page of data
     * should be displayed. If the paginator receives its properties as template inputs,
     * e.g. `[pageLength]=100` or `[pageIndex]=1`, then be sure that the paginator's view has been
     * initialized before assigning it to this data source.
     */
    public get paginator(): MatPaginator | null { return this._paginator; }

    public set paginator(paginator: MatPaginator | null) {
        this._paginator = paginator;
        this.updateChangeSubscription();
    }


    private _loading: boolean;
    private _sort: MatSort | null;
    private _paginator: MatPaginator | null;

    /**
     * Do not emit change events when true.
     */
    private pauseEvents: boolean = true;
    private currentDataFunction: () => Observable<TableData<T>>;
    private currentQuery: TableQuery;

    private readonly dataSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

    /**
     * Subscription to the changes that should trigger an update to the table's rendered rows, such
     * as refresh, sorting, pagination, or base data changes.
     */
    private renderChangesSubscription: Subscription = Subscription.EMPTY;

    constructor(
        // tslint:disable-next-line:no-unused-variable
        private readonly matSort?: MatSort | null,
        // tslint:disable-next-line:no-unused-variable
        private readonly matPaginator?: MatPaginator | null) {
        super();

        this._sort = matSort;
        this._paginator = matPaginator;

        this.updateChangeSubscription();
    }

    /**
     * Sets the data in the table by the passed in Observable.
     * @param data The data that will be added to the table.
     */
    public data(data: () => Observable<TableData<T>>, query?: TableQuery): void {
        this.currentDataFunction = data;
        this.currentQuery = query;

        this.pauseEvents = false;
        this._loading = true;

        if (query) {
            if (this.paginator) {
                this.paginator.pageSize = +query.limit || this.paginator.pageSize || 1;
                this.paginator.pageIndex = Math.round(+query.offset / (this.paginator.pageSize || 1));
            }

            if (this.sort && query.orderBy) {
                const direction: string = query.orderBy[0];
                const column: string = direction === '-' || direction === '+' ? query.orderBy.slice(1) : query.orderBy;

                this.sort.direction = direction === '-' ? 'desc' : 'asc';
                this.sort.active = column;
            }
        }

        data().subscribe((tableData: TableData<T>) => {
            this._loading = false;

            if (this.paginator) {
                this.paginator.length = tableData.totalCount;
            }

            return this.dataSubject.next(tableData.data);
        }, () => {
            this._loading = false;
        });
    }

    /**
     * Connect to data source.
     */
    public connect(): Observable<T[]> {
        return this.dataSubject.asObservable();
    }

    /**
     * Disconnect to data source.
     */
    public disconnect(): void {
        this.dataSubject.complete();
    }

    /**
     * Refresh the current {@link TableData<T>}.
     */
    public refresh(): void {
        if (!this.currentDataFunction) {
            throw new Error('You cannot call refresh() before calling data()!');
        }

        this.data(this.currentDataFunction, this.currentQuery);
    }

    private updateChangeSubscription(): void {
        // Sorting and/or pagination should be watched if MatSort and/or MatPaginator are provided.
        // The events should emit whenever the component emits a change or initializes, or if no
        // component is provided, a stream with just a null event should be provided.

        const sortChange: Observable<Sort | null> = this._sort ?
            this._sort.sortChange : of(null);
        const pageChange: Observable<PageEvent | null> = this._paginator ?
            this._paginator.page : of(null);

        // TODO: Use this when updated to Material v6.
        // const sortChange: Observable<Sort | null> = this._sort ?
        //    merge<Sort>(this._sort.sortChange, this._sort.initialized) :
        //    Observable.of(null);
        // const pageChange: Observable<PageEvent | null> = this._paginator ?
        //    merge<PageEvent>(this._paginator.page, this._paginator.initialized) :
        //    Observable.of(null);

        // Watched for paged data changes and send the result to the table to render.
        this.renderChangesSubscription.unsubscribe();
        this.renderChangesSubscription = merge(sortChange, pageChange).subscribe(() => {
            if (!this.pauseEvents) {
                this.queryChange.next(Object.assign(this.orderData(), this.pageData()));
            }
        });
    }

    private orderData(): TableQuery {
        const data: TableQuery = {};

        if (!this.sort) { return data; }

        if (this.sort.direction && this.sort.active) {
            data.orderBy = this.sort.direction === 'desc' ? `-${this.sort.active}` : this.sort.active;
        }

        return data;
    }

    private pageData(): TableQuery {
        const data: TableQuery = {};

        if (!this.paginator) { return data; }

        data.offset = (this.paginator.pageIndex * (this.paginator.pageSize || 1)).toString();
        data.limit = (this.paginator.pageSize || 1).toString();

        return data;
    }
}
