import { Injectable } from '@angular/core';
import { AuthHttpService, UrlHelperService } from '@jeunesse/angular';
import {
    AgendaQueryModel,
    EventQueryModel,
    Agenda,
    EventInfo,
    EventMemberModel,
    EventEmailPostModel,
    PromoItem,
    PromoDocumentsInfo
} from '..';
import { UserTicketModel } from '../models/user-ticket.model';
import { EmailInviteResponse } from '../models/email-invite-repsonse.model';
import { Observable } from 'rxjs';
import { LoadingEventsService } from './loading-events.service';
import { map, tap } from 'rxjs/operators';
import { Dictionary } from '@ngrx/entity';
import { EventCalendarModel } from '../models/EventCmsView.model';
import { EventMemberLayoutAPIModel } from '../models/event-member-form-layout.model';
import { GroupMemberInfoModel } from '../models/group-member-info.model';
import { DistributorLookupModel } from '../models/distributor-lookup.model';

@Injectable({
    providedIn: 'root'
})
export class EventsApiService {
    constructor(
        private readonly authHttpService: AuthHttpService,
        private readonly urlHelperService: UrlHelperService,
        private readonly loadingEventsService: LoadingEventsService
    ) {}

    public getAgendasInfo(params: AgendaQueryModel): Observable<Agenda[]> {
        this.loadingEventsService.changeLoadingState('getAgendasInfo', true);
        const qs: string = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        return this.authHttpService.get<Agenda[]>(this.urlHelperService.toEventApi(`/v1/event/agendas?${qs}`)).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('getAgendasInfo', false);
            })
        );
    }

    public getEventsInfo(params: EventQueryModel): Observable<EventInfo[]> {
        this.loadingEventsService.changeLoadingState('getEventsInfo', true);
        const qs: string = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        return this.authHttpService.get<EventInfo[]>(this.urlHelperService.toEventApi(`/v1/event?${qs}`)).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('getEventsInfo', false);
            })
        );
    }

    public postEventMemberInfo(body: Dictionary<EventMemberModel>): Observable<number> {
        this.loadingEventsService.changeLoadingState('postEventMemberInfo', true);
        return this.authHttpService.post<number>(this.urlHelperService.toEventApi(`/v1/event/registration`), body).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('postEventMemberInfo', false);
            })
        );
    }

    public getUserTickets(eventMemberPk?: number): Observable<UserTicketModel[]> {
        this.loadingEventsService.changeLoadingState('getUserTickets', true);
        let APIurl = "";
        if (eventMemberPk !== undefined)
            APIurl = this.urlHelperService.toEventApi(`/v1/event/user-events?eventMemberID=${eventMemberPk}`);
        else
            APIurl = this.urlHelperService.toEventApi("/v1/event/user-events?eventMemberID=0");
        return this.authHttpService.get<UserTicketModel[]>(APIurl).pipe(
            map((data) => {
                return data.map((x) => new UserTicketModel(x));
            }),
            tap(() => {
                this.loadingEventsService.changeLoadingState('getUserTickets', false);
            })
        );
    }

    public getUserQualifiedPromos(): Observable<PromoItem[]> {
        this.loadingEventsService.changeLoadingState('getQualifiedPromos', true);
        return this.authHttpService.get<PromoItem[]>(this.urlHelperService.toEventApi('/v1/promo/qualified-promos')).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('getQualifiedPromos', false);
            })
        );
    }

    public getCalendarInfo(startDate: Date | string, endDate: Date | string): Observable<EventCalendarModel[]> {
        this.loadingEventsService.changeLoadingState('getCalendarInfo', true);
        let APIurl = this.urlHelperService.toEventApi(`/v1/event/cms/${startDate}/${endDate}`);
        return this.authHttpService.get<EventCalendarModel[]>(APIurl).pipe(
            map((data) => {
                return data.map((event) => new EventCalendarModel(event));
            }),
            tap(() => {
                this.loadingEventsService.changeLoadingState('getCalendarInfo', false);
            })
        );
    }

    public getEventMemberLayouts(eventId: number | string): Observable<EventMemberLayoutAPIModel> {
        this.loadingEventsService.changeLoadingState('getEventMemberLayouts', true);
        return this.authHttpService.get<EventMemberLayoutAPIModel>(this.urlHelperService.toEventApi(`/v1/event/layouts?eventId=${eventId}`)).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('getEventMemberLayouts', false);
            }),
            map((data) => {
                return new EventMemberLayoutAPIModel(data);
            })
        );
    }
    public getGroupMemberInfo(eventMemberPk: number | string): Observable<GroupMemberInfoModel[]> {
        this.loadingEventsService.changeLoadingState('getGroupMemberInfo', true);
        return this.authHttpService
            .get<GroupMemberInfoModel[]>(this.urlHelperService.toEventApi(`/v1/event/getGroupMemberInfo?eventMemberPk=${eventMemberPk}`))
            .pipe(
                map((data) => data.map((x) => new GroupMemberInfoModel(x))),
                tap(() => {
                    this.loadingEventsService.changeLoadingState('getGroupMemberInfo', false);
                })
            );
    }
    public sendGroupEmails(body: EventEmailPostModel): Observable<GroupMemberInfoModel[]> {
        this.loadingEventsService.changeLoadingState('sendGroupEmails', true);
        return this.authHttpService.post<GroupMemberInfoModel[]>(this.urlHelperService.toEventApi(`v1/event/SendGroupEmails`), body).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('sendGroupEmails', false);
            })
        );
    }

    public sendEmailInviteResponse(paramsObj: EmailInviteResponse) : Observable<boolean> {
        this.loadingEventsService.changeLoadingState('sendEmailInviteResponse', true);
        return this.authHttpService.post<boolean>(this.urlHelperService.toEventApi('v1/event/inviteResponse?eventMemberId=' +  paramsObj.memberId + '&' + 'accepted=' + paramsObj.accepted)).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('sendEmailInviteResponse', false)
            })
        );
    }

    public updateGroupMemberInfo(body: GroupMemberInfoModel[]): Observable<GroupMemberInfoModel[]> {
        this.loadingEventsService.changeLoadingState('updateGroupMemberInfo', true);
        return this.authHttpService.post<GroupMemberInfoModel[]>(this.urlHelperService.toEventApi(`v1/event/updateGroupMemberInfo`), body).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('updateGroupMemberInfo', false);
            })
        );
    }

    public setUpCart(body: { eventMemberId: number, referrerSiteUrl: string}): Observable<{checkoutUrl: string}> {
        this.loadingEventsService.changeLoadingState('setUpCart', true);
        return this.authHttpService.post<{checkoutUrl: string}>(this.urlHelperService.toEventApi(`v1/event/registration/setup-cart`), body).pipe(
            tap(() => {
                this.loadingEventsService.changeLoadingState('setUpCart', false);
            })
        );
    }

    public distributorLookup(siteURL: string): Observable<DistributorLookupModel> {
        return this.authHttpService.get<DistributorLookupModel>(this.urlHelperService.toEventApi(`v1/event/distributorLookup?siteURL=${siteURL}`)).pipe(
            map((data) => {
                if (data)
                    return new DistributorLookupModel(data);
                else
                {
                    // let distEmpty: DistributorLookupModel;
                    // distEmpty.mainPK = -1;
                    return new DistributorLookupModel({mainPK: -1})
                }
            })
        );
    }

    public downloadCheckinPDF(eventMemberPK: number | string, isGroup: boolean): Observable<any> {
        this.loadingEventsService.changeLoadingState('updateGroupMemberInfo', true);
        return this.authHttpService.get<any>(this.urlHelperService.toEventApi(`/v1/event/get-qr-code-info?eventmemberID=${eventMemberPK}&group=${isGroup}`)).pipe(
             tap(() => {
                 this.loadingEventsService.changeLoadingState('updateGroupMemberInfo', false);
             })
         );
    }

    public getPromoDocuments(token: string): Observable<PromoDocumentsInfo> {
        this.loadingEventsService.changeLoadingState('getPromoDocuments', true);
        return this.authHttpService.get<PromoDocumentsInfo>(this.urlHelperService.toEventApi(`/v1/promo/documents?pt=${token}`)).pipe(
             tap(() => {
                 this.loadingEventsService.changeLoadingState('getPromoDocuments', false);
             })
         );
    }
}
