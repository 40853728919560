import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocaleService, WebStorageService } from '@jeunesse/angular';
import { MainCartService } from '@jeunesse/slide-cart';
import { Observable, Subject, Subscription } from 'rxjs';
import { SubSink } from 'subsink';

import { FormatCurrency } from '../../../checkout/models/format-currency.model';
import { OrderService } from '../../../checkout/public-api';
import { SmartDeliveryModel } from '../../../member/public-api';
import { ShoppingCartService } from '../../../shopping-cart/public-api';
import { CAPHeaderModel, ProductConfigModel, ProductModel } from '../../models/public-api';
import { ProductService, ProductStateService } from '../../services/public-api';
import { ConfigModalComponent } from '../configuration/config-modal.component';
import { Direction } from '@angular/cdk/bidi';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'jn-create-a-pack',
	templateUrl: './create-a-pack.component.html',
	styleUrls: ['./create-a-pack.component.scss'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [style({ height: 0, opacity: 0 }), animate('0.5s ease-out', style({ height: '*', opacity: 1 }))]),
			transition(':leave', [style({ height: '*', opacity: 1 }), animate('0.5s ease-in', style({ height: 0, opacity: 0 }))]),
		]),
	],
})
export class CreateAPackComponent implements OnInit, OnDestroy {
	@Input() public products: ProductModel[];
	@Input() public onShop: boolean;
	@Input() public onPrepaid: boolean = false;
	@Input() public onSmartDelivery: boolean = false;
	@Input() public submitting: boolean = false;
	@Input() public showImportTab: boolean = false;
	@Input() public isCreateAPac: boolean = false;
	@Input() public cartTabs: string[] = ['LOCAL CART', 'IMPORT CART'];
	@Input() public cartItem: any;
	@Input() public minCV: number;

	@Output() public productUpdate: EventEmitter<any> = new EventEmitter<any>();
	@Output() public productDeleted: EventEmitter<any> = new EventEmitter<any>();
	@Output() public buttonSavePushed: EventEmitter<any> = new EventEmitter<any>();
	@Output() public tabChanged: EventEmitter<string> = new EventEmitter<string>();

	public cartItems: any[] = [];
	public viewType: string;
	public quantity: number;
	public itemDialogRef: MatDialogRef<any>;
	public smartDeliveryInfo: SmartDeliveryModel = new SmartDeliveryModel();
	public productUpdatedObservable$: Observable<boolean>;
	public showSelector = true;
	public isOpenMenu = false;
	public mediaSubscription: Subscription;
    public countryCookie: string;
    public countryCode: string;
    public langId: string;
    public CAPHeaders: CAPHeaderModel[]
	public formattedTotal: string;
	public cartInfo$: Observable<any>;
	public formattedProductTotal: string;
	public qtyChange$ = new Subject<ProductModel>();
	private directionality: Direction = 'ltr';
	private subs: SubSink = new SubSink();

	constructor(
		private readonly router: Router,
		private dialog: MatDialog,
		private readonly shoppingCartService: ShoppingCartService,
		private readonly mainCartService: MainCartService,
		private readonly media: MediaObserver,
		private readonly productStateService: ProductStateService,
		private readonly productService: ProductService,
		private readonly webStorageService: WebStorageService,
		private readonly orderService: OrderService,
		private readonly localeService: LocaleService
	) {
		this.countryCookie = this.webStorageService.getCookie('Country') ? this.webStorageService.getCookie('Country') : 'US';
		this.countryCookie = this.countryCookie.replace(/"/g, '');
        this.langId = this.webStorageService.getCookie('LangId')
	}

	public ngOnInit(): void {
		this.cartInfo$ = this.mainCartService.getMainCart$();
        this.subs.sink = this.cartInfo$.subscribe();
        this.countryCode = this.localeService.getCountryCode();

		this.observeLayout();
		if (!this.onPrepaid && !this.onSmartDelivery) {
			this.addExistingProductToCart();
		}
		this.productUpdatedObservable$ = this.shoppingCartService.cartUpdateObservable$;
		// Subscription for monitor viewType Sidorov 3/16/2020
		this.subs.sink = this.productStateService.getProductsDisplayState$().subscribe((viewType: string) => {
			this.viewType = viewType;
		});
		this.subs.sink = this.localeService.directionalityObservable$.subscribe(
			(directionality: Direction) => (this.directionality = directionality)
		);
		this.qtyChange$.pipe(debounceTime(1000)).subscribe((value: ProductModel) => {
			this.checkIntValue(value);
		});

		this.productService.getCAPHeadersWithCVRanges(this.countryCookie, this.langId || '1').subscribe((items) => {
            this.CAPHeaders = items;
		});
	}

	public ngOnDestroy(): void {
		this.mediaSubscription.unsubscribe();
		this.subs.unsubscribe();
	}

	// to display the existing create a pack products
	public addExistingProductToCart(): void {
		this.shoppingCartService.getShoppingCart().subscribe((data) => {
			if (data.cartItems.length > 0) {
				let shoppingCartItems: any = data.cartItems.filter((item) => item.id === -1);
				if (shoppingCartItems[0] && shoppingCartItems[0].childItems && shoppingCartItems[0].childItems.length > 0) {
					shoppingCartItems[0].childItems.forEach((cartItem) => {
						cartItem.product.quantity = cartItem.quantity;
						this.cartItems.push(Object.assign({}, cartItem.product));
					});
					this.getTotal();
				}

				this.checkProductsInfo(this.cartItems);
			}
		});
	}

	// calculate the cv total
	public getTotalCV(): number {
		let cvTotal: number = 0;
		if (this.cartItems && this.cartItems.length > 0) {
			this.cartItems.forEach((product) => {
				cvTotal += product.quantity * product.bv;
			});
		}
		return cvTotal;
	}

	// calculate months for prepaid
	public getPrepaidMonth(): number {
		let cvTotal: number = 0;
		let minmonthReq: number = this.minCV;
		if (this.cartItems && this.cartItems.length > 0) {
			this.cartItems.forEach((product) => {
				cvTotal += product.quantity * product.bv;
				if (product.doNotSplitPackBV && product.bv > minmonthReq) minmonthReq = product.bv;
			});
		}
		return parseInt((cvTotal / minmonthReq).toString());
	}

	// calculate the products total
	public getTotal(): void {
		let totalAmount: number = 0;
		if (this.cartItems && this.cartItems.length > 0) {
			this.cartItems.forEach((product) => {
				totalAmount += product.quantity * product.pricing[0].price;
			});
		}
		this.orderService.formatCurrency(totalAmount, this.countryCookie.toString()).subscribe((result: FormatCurrency) => {
			this.formattedTotal = result.formattedValue;
		});
	}

	public updateItem(productObj: ProductModel): void {
		// KLK - Task: 46882
		if (productObj.quantity > productObj.maxLimit) {
			productObj.quantity = productObj.maxLimit;
		}
		let product: ProductModel = Object.assign({}, productObj);
		if (this.cartItems && this.cartItems.some((x) => x.pricing[0].currencySymbol !== product.pricing[0].currencySymbol)) {
			this.productUpdate.emit(null);
			product.quantity = 0;
			this.getTotal();
			return;
		}

		if ((product.isConfigurable || (product.isPackage && product.configurations.length > 0)) && !product.selectedConfig) {
			// Show Configuration Dialog
			const dialogRef: MatDialogRef<ConfigModalComponent> = this.dialog.open(ConfigModalComponent, {
				data: product,
				direction: this.directionality,
			});

			dialogRef.afterClosed().subscribe((res: ProductConfigModel[]) => {
				if (res) {
					product.selectedConfig = res.sort((a, b) => {
						if (a.productName && b.productName) {
							const nameA: string = a.productName.trim().toLowerCase(),
								nameB: string = b.productName.trim().toLowerCase();
							if (nameA < nameB) return -1;
							if (nameA > nameB) return 1;
						}
						return 0;
					});
					this.addProductToCart(product);
					this.productUpdate.emit(product);
				}
			});
		} else {
			this.addProductToCart(product);
			this.productUpdate.emit(product);
		}
	}

	public productQtyChanged(product: ProductModel): void {
		console.log('Product Qty Change:', product.quantity, product);
	}

	public checkIntValue(product: ProductModel): void {
        product.quantity = this.mainCartService.checkIntValue(product);
        product.linebv = product.quantity * product.bv;
		this.subs.sink = this.orderService
			.formatCurrency(product.quantity * product.pricing[0].price, this.countryCookie)
			.subscribe((result: FormatCurrency) => {
                product.lineTotal = result.formattedValue;
			});
		this.productUpdate.emit(product);
		if (product.quantity <= 0) {
			this.deleteProduct(product);
		}
		this.getTotal();
	}

	public goBack(): void {
		this.router.navigate(['/profile/package']);
	}

	public onButtonSavePushed(): void {
		this.buttonSavePushed.emit(this.cartItems);
	}

	public deleteProduct(product: ProductModel): void {
		// don't delete, needed for trigger change detection in category-product-item
		this.products = this.products.slice();
		product.quantity = null;
		const index: number = this.cartItems.findIndex((item) => item.productPK === product.productPK);
		this.cartItems.splice(index, 1);
		this.getTotal();
		this.productDeleted.emit(product);
	}

	public toggleBottomMenu(): void {
		this.isOpenMenu = !this.isOpenMenu;
	}

	public observeLayout(): void {
		this.mediaSubscription = this.media.media$.subscribe(() => {
			if (this.media.isActive('lt-sm')) {
				this.viewType = 'row';
				this.productStateService.setProductsDisplayState$(this.viewType);
				this.showSelector = false;
			}
			if (this.media.isActive('gt-xs')) {
				this.showSelector = true;
			}
		});
	}

	public onTabChange(event: any): void {
		this.products = null;
		if (event.index === 1) {
			this.tabChanged.emit('import');
		} else {
			this.tabChanged.emit('local');
		}
	}

	public clearItems(): void {
		this.cartItems = [];
	}

	private compareConfigurations(larr1: ProductConfigModel[], larr2: ProductConfigModel[]): boolean {
		let arr1 = larr1.slice();
		let arr2 = larr2.slice();
		if (!arr1 && !arr2) {
			return true;
		}
		arr1.sort((a, b) => a.productPK - b.productPK);
		arr2.sort((a, b) => a.productPK - b.productPK);
		for (let i: number = 0; i < arr1.length; i++) {
			if (arr1[i].productPK !== arr2[i].productPK) {
				return false;
			}
		}
		return true;
	}

	private getCartIndex(cart: ProductModel[], product: ProductModel): number {
		if (!product.selectedConfig) {
			return cart.findIndex((item) => item.productPK === product.productPK);
		}

		for (let i: number = 0; i < cart.length; i++) {
			if (
				cart[i].selectedConfig &&
				cart[i].productPK === product.productPK &&
				this.compareConfigurations(cart[i].selectedConfig, product.selectedConfig)
			) {
				return i;
			}
		}

		return -1;
	}

	public addProductToCart(product: ProductModel): void {
		// KLK - Task: 46882
		if (product.quantity > product.maxLimit) {
			product.quantity = product.maxLimit;
		}
		if (this.getCartIndex(this.cartItems, product) < 0) {
			this.subs.sink = this.orderService
				.formatCurrency(product.quantity * product.pricing[0].price, this.countryCookie)
				.subscribe((result: FormatCurrency) => {
                    product.lineTotal = result.formattedValue;
                    product.linebv = product.quantity * product.bv;
					this.cartItems.push(product);
					this.getTotal();
				});
		} else {
			this.subs.sink = this.orderService
				.formatCurrency(product.quantity * product.pricing[0].price, this.countryCookie)
				.subscribe((result: FormatCurrency) => {
                    product.lineTotal = result.formattedValue;
                    product.linebv = product.quantity * product.bv;
					this.cartItems = this.cartItems.map((i) => (i.productPK === product.productPK ? product : i));
					this.getTotal();
				});
		}
	}

	private checkProductsInfo(cartItems: any[]): void {
		cartItems.forEach((product) => {
			this.checkIntValue(product);
		});
	}
}
