import { Injectable } from '@angular/core';
import { UrlHelperService } from '../../common/src/url-helper.service';
import { AuthHttpService } from '../../authentication/src/auth-http.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Country } from './models/country.model';
import { CompanyCountry } from './public-api';


@Injectable()
export class CmsService {

    constructor(private readonly urlHelper: UrlHelperService, private readonly authHttp: AuthHttpService) {
    }

    public GetAllCountries(): Observable<Array<Country>> {
        return this.authHttp.get<Array<Country>>(this.urlHelper.toCmsApi('/v1/application/locale-info')).pipe(map((t: any) => {
            const ary: Array<Country> = new Array<Country>();
            t.forEach(element => {
                ary.push(new Country(element));
            });
            return ary;
        }));
    }

    public GetCountryInfo(countryCode: string) : Observable<CompanyCountry> {
        return this.authHttp.get<CompanyCountry>(this.urlHelper.toCmsApi(`/v1/application/country-info/${countryCode}`)).pipe();
    }
}
