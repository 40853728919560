export class PAOrder {

    public mainOrdersFK: number;
    public processedMainOrdersFK: number;
    public prepaidStatus: string;
    public endDate: Date;
    public monthlyBV: number;
    public toReverse: boolean;

    public get isProcessed(): boolean {
        return this.prepaidStatus === 'PROCESSED';
    }

    constructor(options: any = {}) {
        this.mainOrdersFK = options.mainOrdersFK;
        this.processedMainOrdersFK = options.processedMainOrdersFK;
        this.prepaidStatus = options.prepaidStatus;
        this.endDate = options.endDate;
        this.monthlyBV = options.monthlyBV;
        this.toReverse = this.isProcessed;
    }
}