import { SmartDeliveryItemModel } from "./smartdelivery-item.model";

/**
 * Smart delivery return model.
 */
export class SmartDeliveryModel {
    public active: boolean;
    public isPaymentSetup: boolean;
    public agreedToTerms: boolean;
    public isPickupOffered: boolean;
    public isPickup: boolean;
    public askForKidsDonation: boolean;
    public nextDeliveryDate: Date;
    public startedDate: Date;
    public itemsTotal: string;
    public shipMethod: number;
    public signatureRequired: boolean;
    public askForWalletUse: boolean;
    public walletFirst: boolean;
    public isValidPhotoID: boolean;
    public askForPersonalUse: boolean;
    public noPersonalUseOnly: boolean;
    public isImportOrder: boolean;
    public isImportAllowed: boolean;
    public paymentType: string;
    public manualPayment: boolean;
    public showAbandonedOrderWarning: boolean;
    public minCV: number;
    public smartDeliveryItems: SmartDeliveryItemModel[] = [];

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
