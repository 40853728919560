import { Injectable } from '@angular/core';
import { AuthHttpService, UrlHelperService } from '@jeunesse/angular';
import { Observable, of } from 'rxjs';
import { ProductModel, BestSellerModel, BrandViewModel, ProductDescriptionModel, CAPHeaderModel, DeepLinkQuery, DeepLinkModel } from '../models/public-api';
import { HttpParams } from '@angular/common/http';
import { SmartDeliveryItemFormModel, SmartDeliveryItemModel } from '../../member/public-api';

@Injectable({
    providedIn: 'root',
})
export class ProductApiService {
    constructor(private readonly authHttpService: AuthHttpService, private readonly urlHelperService: UrlHelperService) {}

    public getAllProducts(httpParams: HttpParams): Observable<ProductModel[]> {
        return this.authHttpService.get<ProductModel[]>(this.urlHelperService.toCartApi('/v2/products'), { params: httpParams });
    }

    /**
     * Reuturns a list of products designated as best sellers.
     * @param query Query params to filter on
     */
    public getBestSellers(httpParams: HttpParams): Observable<BestSellerModel[]> {
        return this.authHttpService.get<BestSellerModel[]>(this.urlHelperService.toCartApi('/v2/products/best-sellers'), {
            params: httpParams,
        });
    }

    /**
     * Returns a list of brands that have been setup to spotlight.
     */
    public getSpotlightBrands(): Observable<BrandViewModel[]> {
        return this.authHttpService.get<BrandViewModel[]>(this.urlHelperService.toCartApi('/v2/products/brand/spotlight'));
    }

    /**
     * Adds a product to a logged in user's smart delivery.
     * @param model SmartDeliveryItemFormModel model
     */
    public addProductToSmartDelivery(model: SmartDeliveryItemFormModel): Observable<SmartDeliveryItemModel> {
        return this.authHttpService.post<SmartDeliveryItemModel>(this.urlHelperService.toCartApi('/v1/smartdelivery/items'), model).pipe();
    }

    public getProductBySKU(model: { SKU: string; culture: string }) {
        return this.authHttpService.get<ProductDescriptionModel>(this.urlHelperService.toCartApi('/v1/product/regulatory-details'), { params: model });
    }

    public getCAPHeadersWithCVRanges(country: string, langId: string): Observable<CAPHeaderModel[]> {
        return this.authHttpService.get<any>(this.urlHelperService.toCartApi(`/v2/products/cap-headers/${country}/${langId}`));
    }

    public generateDeepLinkString(queryModel: DeepLinkQuery): Observable<DeepLinkModel> { // KLK - Task: 46724
        queryModel.domain = queryModel.domain.replace('my', 'global').replace('china', 'global');
        return this.authHttpService.post<DeepLinkModel>(this.urlHelperService.toProfileApi(`/v2/member/create-deep-link`), queryModel).pipe();
    }
}
