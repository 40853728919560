export class LookupAddressViewModel {
    public description: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public country: string;
    public placeId: string;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}