import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { MainCartService } from '../../services/main-cart.service';
import { MainCartApiService } from '../../services/main-cart-api.service';
import { ShoppingCart, ShoppingCartItem, MainCartItem, MainCartItemsValidate, CheckQualifiedCampaignResponse } from '../../models/public-api';
import { Router } from '@angular/router';
import { LocaleService, SettingsService } from '@jeunesse/angular';
import { SubSink } from 'subsink';
import { QualifiedCampaignService } from '../../services/qualified-campaign.service';
import { Direction } from '@angular/cdk/bidi';


@Component({
    selector: 'jn-slide-cart',
    templateUrl: './slide-cart.component.html',
    styleUrls: ['./slide-cart.component.scss']
})
export class SlideCartComponent implements OnInit, OnDestroy {

    @Input() public shopUrl: string;
    @Input() public cartOpen: boolean;
    @Input() public mainType: number;
    @Input() public isGuestAccount: boolean;
    @Input() public repInfo: any;
    @Input() public cartType: any;
    @Input() public loyaltyPointsInfo: any;
    @Output() public cartToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    public cartInfo$: Observable<any>;
    public cartItemsInfo$: Observable<any>;
    public hasItemsInCart: boolean = false;
    public cart: ShoppingCart;
    public currencySymbol: string;
    public countryCode: string;
    public isLoggedIn: boolean;
    public isRedemption: boolean = false;
    public validationMessages: MainCartItemsValidate[];
    public allowContinue: boolean;
    public validationMsg: string = '';
    public isCorporate: boolean = false;
    public qualifiedCampaignInfo: CheckQualifiedCampaignResponse;
    public directionality: Direction = 'ltr';
    private subs = new SubSink();

    constructor(private readonly mainCartService: MainCartService,
        private readonly mainCartApiService: MainCartApiService,
        private readonly router: Router,
        private readonly settings: SettingsService,
        private readonly qualifiedCampaignService: QualifiedCampaignService,
        private readonly localeService: LocaleService) { }

    public ngOnInit(): void {
        this.mainCartService.isLoggedIn().subscribe(loggedIn => {
            this.isLoggedIn = loggedIn;
        });
        this.cartInfo$ = this.mainCartService.getMainCart$();
        this.cartItemsInfo$ = this.mainCartService.getMainCartItems$();
        this.isCorporate = this.repInfo && this.repInfo.corporate;
        this.subs.sink = this.cartInfo$.subscribe((cart: any) => {
            this.cart = cart;
            if (cart.toggleCart) {
                this.cartToggle.emit(cart.toggleCart);
            }
            if (this.cart.cartItems) {
                this.hasItemsInCart = this.cart.cartItems.length > 0;
            }

            this.currencySymbol = this.cart.currencySymbol;
            this.countryCode = this.cart.countryCode;
            this.isRedemption = this.cart.cartType === 'LR';
            if (cart.cartKey) {
                this.subs.sink = this.mainCartService.validateCartItems(cart.cartKey).subscribe((msgs: MainCartItemsValidate[]) => {
                    this.validationMessages = msgs;
                    this.allowContinue = msgs && msgs.filter(x => x.allowCheckout === false).length === 0;
                    this.validationMsg = '';
                    msgs.forEach((msg: MainCartItemsValidate) => {
                        if (msg) {
                            this.validationMsg += (msg.message + '<br/><br/>');
                        }
                    })
                });
            }
        });
        this.subs.sink = this.qualifiedCampaignService.getCampaignInfo$()
            .subscribe((campaignInfo: CheckQualifiedCampaignResponse) => {
            this.qualifiedCampaignInfo = campaignInfo;
        });
        this.subs.sink = this.localeService.directionalityObservable$.subscribe((directionality: Direction) => this.directionality = directionality);
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public get shoppingCart(): ShoppingCart {
        return this.cart;
    }

    public onUpdated(updatedItem: ShoppingCartItem): void {
        let model: MainCartItem = {
            mainCartFk: this.cart.cartKey,
            mainCartItemsPk: updatedItem.id,
            productFk: updatedItem.productFk,
            setupForAs: updatedItem.setupForAs,
            discount: updatedItem.discount,
            quantity: updatedItem.quantity,
            extraFee: updatedItem.extraFee,
            configFk: updatedItem.configFk,
            // configItems: updatedItem.childItems,
            priceListFk: updatedItem.priceListFk
        };
        if (updatedItem.quantity === 0) {
            this.mainCartService.removeCartItem(model.mainCartItemsPk);
        }
        else {
            this.mainCartService.updateCartItem(model.mainCartItemsPk, model, false);
        }
    }

    public goToCheckout(): void {
        this.cartToggle.emit(false);
        if (this.cart.cartItems && this.cart.cartItems.length > 0) {
            if (this.allowContinue) {
                if (this.settings.siteName === 'Shop') {
                    if (this.qualifiedCampaignInfo && this.qualifiedCampaignInfo.isQualified) {
                        this.router.navigate(['checkout', 'discounted-products'], { queryParams: { cartKey: this.cart.cartKey } });
                    } else {
                        this.router.navigate(['/checkout/shipping' ], { queryParams: { cartKey: this.cart.cartKey } });
                    }
                } else {
                    window.location.replace(this.shopUrl + '/checkout/shipping?cartKey=' + this.cart.cartKey);
                }
            }
        } else {
            this.hasItemsInCart = false;
        }
    }

    public closeCart(): void {
        this.cartToggle.emit(false);
    }

    public continueShopping(): void {
        this.closeCart();
        if (!this.router.url.includes('catalog')) {
            if (this.settings.siteName === 'Shop') {
                this.router.navigate(['/']);
            } else {
                window.location.replace(this.shopUrl);
            }
        }
    }
}
