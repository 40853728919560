import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthHttpService } from '../../authentication/src/auth-http.service';
import { AuthService } from '../../authentication/src/auth.service';
import { UrlHelperService } from '../../common/src/url-helper.service';
import { WebStorageService } from '../../common/src/web-storage.service';
import { CompanyCountry } from './interfaces/company-country.interface';
import { CmsService } from '../../cms/src/cms.service';


/**
 * This is used to load translations and return translations.
 */
@Injectable({ providedIn: 'root' })
export class CmsSessionService {
    private session: any = {};
    private sessionSubject = new Subject<any>();
    private companyCountryInfo$: Subject<CompanyCountry> = new Subject<CompanyCountry>();
    constructor(
        private readonly urlHelper: UrlHelperService,
        private readonly authHttp: AuthHttpService,
        private readonly auth: AuthService,
        private readonly webStorageService: WebStorageService,
        private readonly cmsService: CmsService) {
        this.loadCmsSession().subscribe(() => {
            this.sessionSubject.next(this.session);
        });
    }

    public isTranslator(): boolean {
        return this.auth.isLoggedIn && (this.auth.hasClaim('CMS Translator') || this.auth.hasClaim('CMS Admin'));
    }

    public getSession(): void {
        return this.session;
    }

    public loadCmsSession(): Observable<any> {
        if (this.isTranslator()) {
            return this.authHttp.get<any>(this.urlHelper.toCmsApi(`/v1/cms/session`)).pipe(
                map((data: any) => {
                    if (data.session) {
                        this.session = data.session;
                        return true;
                    } else {
                        return false;
                    }
                }),
                catchError(() => {
                    return of(false);
                }));
        }
        return of(false);
    }

    public getSessionObservable(): Observable<any> {
        return this.sessionSubject.asObservable();
    }

    public getCulture(): string {
        // if the user is a translator, the culture cookie takes precendent over the culture set in the token
        if (this.isTranslator()) {
            return this.webStorageService.getCookie('Culture').replace(/"/g, '') || (this.auth.isLoggedIn ? this.auth.getCulture() : 'en-US');
        } else {
            return this.auth.isLoggedIn ? this.auth.getCulture() : this.webStorageService.getCookie('Culture').replace(/"/g, '') || 'en-US';
        }
    }

    /**
     * Get the country based on the culture provided
     * @param culture
     */
    public getCmsCountry(culture: string): string {
        if (this.auth.isLoggedIn && !this.auth.hasClaim('CMS Admin') && !this.auth.hasClaim('CMS Translator')) {
            culture = this.auth.getCulture();
        }
        if (!culture) {
            culture = 'en-US';
        }
        let cultureSplit: string[] = culture.split('-');
        if (cultureSplit.length > 1) {
            let country: string = cultureSplit[1];
            country = country.replace('"', '');
            if (country) {
                return country;
            }
        }
        return 'US';
    }

    /**
     * Observable for CompanyCountry updates
     * @returns Observable<CompanyCountry>
     */
    public GetCountryInfo(): Observable<CompanyCountry> {
        return this.companyCountryInfo$.asObservable();
    }

    /**
     *
     * @param countryCode Country
     */
    public LoadContryInfo(countryCode: string): void {
        this.cmsService.GetCountryInfo(countryCode).subscribe((info: CompanyCountry) => {
            if (info) this.companyCountryInfo$.next(info);
        });
    }

    public LoadContryInfoAsync(countryCode: string): Observable<CompanyCountry> {
        return this.cmsService.GetCountryInfo(countryCode).pipe(map((info: CompanyCountry) => {
            if (info) this.companyCountryInfo$.next(info);
            return info;
        }));
    }
}
