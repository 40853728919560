import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { CategoryModel } from '../../../products/models/category/category.model';

import { CategoryOverlayComponent } from './category-overlay.component';
import { CategoryOverlayConfig } from './category-overlay-config.interface';
import { CategoryOverlayRef } from './category-overlay-ref';
import { CATEGORY_OVERLAY_DATA } from './category-overlay.tokens';


/** Default settings of overlay. */
const defaultConfig: CategoryOverlayConfig = {
    hasBackdrop: true,
    panelClass: 'jn-category-overlay',
    data: new CategoryModel()
};

/** Default set of positions for the overlay. Follows the behavior of a dropdown. */
const defaultPositionList: ConnectedPosition[] = [
    {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
    },
    {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
    },
    {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom'
    },
    {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
    }
];


/**
 * Controls the overlay of the category brands.
 */
@Injectable()
export class CategoryOverlayService {
    constructor(
        private readonly injector: Injector,
        private readonly overlay: Overlay) {
    }

    /**
     * Opens the overlay.
     * @param config Config options to override the defaults.
     */
    public open(config: CategoryOverlayConfig = {}): CategoryOverlayRef {
        const dialogConfig: CategoryOverlayConfig = { ...defaultConfig, ...config };

        // Returns an OverlayRef which is a PortalHost
        const overlayRef: OverlayRef = this.createOverlay(dialogConfig);

        const overlayComponent: CategoryOverlayComponent = this.attachDialogContainer(overlayRef, dialogConfig);

        // Instantiate remote control
        const categoryOverlayRef: CategoryOverlayRef = new CategoryOverlayRef(overlayRef, overlayComponent);

        // Set remote to component
        overlayComponent.categoryOverlayRef = categoryOverlayRef;

        overlayRef.backdropClick().subscribe(_ => categoryOverlayRef.close());

        return categoryOverlayRef;
    }

    private createOverlay(config: CategoryOverlayConfig): OverlayRef {
        // Returns an OverlayConfig
        const overlayConfig: OverlayConfig = this.getOverlayConfig(config);

        // Returns an OverlayRef
        return this.overlay.create(overlayConfig);
    }

    private getOverlayConfig(config: CategoryOverlayConfig): OverlayConfig {
        let positionStrategy: PositionStrategy;

        if (config.flexibleConnectedTo) {
            positionStrategy = this.overlay.position()
                .flexibleConnectedTo(config.flexibleConnectedTo.origin.elementRef)
                .withPositions(config.flexibleConnectedTo.positions || defaultPositionList)
                .withFlexibleDimensions(config.flexibleConnectedTo.dimensions)
                .withPush(config.flexibleConnectedTo.push)
                .withGrowAfterOpen(config.flexibleConnectedTo.growAfterOpen)
                .withViewportMargin(config.flexibleConnectedTo.viewportMargin || 0)
                .withLockedPosition(config.flexibleConnectedTo.lockPosition);
        }
        else {
            positionStrategy = this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically();
        }

        const overlayConfig: OverlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }

    private createInjector(config: CategoryOverlayConfig): PortalInjector {
        // Instantiate new WeakMap for our custom injection tokens
        const injectionTokens: WeakMap<object, any> = new WeakMap();

        // Set custom injection tokens
        injectionTokens.set(CATEGORY_OVERLAY_DATA, config.data);

        // Instantiate new PortalInjector
        return new PortalInjector(this.injector, injectionTokens);
    }

    private attachDialogContainer(overlayRef: OverlayRef, config: CategoryOverlayConfig): CategoryOverlayComponent {
        const injector: PortalInjector = this.createInjector(config);

        const containerPortal: ComponentPortal<CategoryOverlayComponent> = new ComponentPortal(CategoryOverlayComponent, null, injector);
        const containerRef: ComponentRef<CategoryOverlayComponent> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }
}
