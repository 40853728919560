import { Observable, BehaviorSubject } from 'rxjs';

/**
 * Generic observable store pattern.
 * Use it to extend a class be sure to call super on constructor
 */
export class Store<T> {
    /**
     * Observable state of the store to subscribe to changes.
     */
    public state$: Observable<T>;
    private _state$: BehaviorSubject<T>;

    protected constructor (initialState: T) {
        this._state$ = new BehaviorSubject(initialState);
        this.state$ = this._state$.asObservable();
    }

    /**
     * Returns the current state of the object.
     */
    get state(): T {
        return this._state$.getValue();
    }

    /**
     * Used to set or update the store's state.
     * @param nextState New object to update the store's state.
     */
    public setState(nextState: T): void {
        this._state$.next(nextState);
    }
}