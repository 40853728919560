import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params, ParamMap } from '@angular/router';
import { Observable, of } from 'rxjs';

import {
    ProductModel,
    ProductService,
    BrandModel,
    CategoryModel,
    ProductQueryModel,
    ProductStateService,
    MemberService,
    SmartDeliveryService,
} from '@jeunesse/cart-lib';
import { SmartDeliveryModel, LoyaltyPointsModel } from '@jeunesse/cart-lib';
import { AuthService, CmsSessionService, TranslationService } from '@jeunesse/angular/authentication';
import { SeoService } from '@jeunesse/angular/common';
import { LocaleService } from '@jeunesse/angular/cms';
import { LoaderService } from '@jeunesse/cart-lib';
import { SubSink } from 'subsink';
import { MainCartService, ShoppingCartItem } from '@jeunesse/slide-cart';
import { MediaObserver } from '@angular/flex-layout';
import { PageEvent } from '@angular/material/paginator';
import { Direction } from '@angular/cdk/bidi';

@Component({
    selector: 'jn-categories',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CategoryComponent implements OnInit, OnDestroy {
    public products: ProductModel[] = [];
    public category: CategoryModel = new CategoryModel();
    public brand: BrandModel = new BrandModel();
    public product: ProductModel;
    public promoProduct: ProductModel[] = [];
    public categoryId: number;
    public brandId: number;
    public menuId: string = '';
    public sortBy: string;
    public sortOptions: any;
    public searchTerm: string = '';
    public isLoggedIn: boolean;
    public isSearch: boolean;
    public isBrand: boolean;
    public loading: boolean;
    public hasLoaded: boolean = false;
    public isCategory: boolean;
    public isMenu: boolean;
    public smartDeliveryInfo: SmartDeliveryModel = new SmartDeliveryModel();
    public loyaltyPointsInfo: LoyaltyPointsModel;
    public pageTitle: string;
    public cartType: string;
    public countryCode: string;
    public productUpdatedObservable$: Observable<boolean>;
    public translations: any = {
        sort: '',
    };
    public tInitialParams: Params;
    public viewType: string;
    public queryParams: Params;
    public isCreateAPac: boolean = false;
    public allProducts: ProductModel[] = [];
    public lengthOfPaginator: number = 0;
    public filteredProducts: ProductModel[] = [];
    public displayProduct: ProductModel[] = [];
    public fromPage = 0;
    public toPage = 10;
    public pageSize = 10;
    public searchedProduct: ProductModel[] = [];
    public previousPageIndex: number;
    public pageIndex: number = 0;
    public defaultProduct: ProductModel[] = [];
    public shoppingCartItems: ShoppingCartItem[] = [];
    public isRtl: boolean = false;
    public directionality: Direction = 'ltr';
    private siteUrl: string;
    private hasRetailStarterKit: boolean;
    private subs = new SubSink();

    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly productService: ProductService,
        private readonly authService: AuthService,
        private readonly memberService: MemberService,
        private readonly smartDeliveryService: SmartDeliveryService,
        private readonly seoService: SeoService,
        private readonly router: Router,
        private readonly loaderService: LoaderService,
        private readonly localeService: LocaleService,
        private readonly mainCartService: MainCartService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly translationService: TranslationService,
        public readonly media: MediaObserver,
        private readonly productStateService: ProductStateService,
        private spy: ChangeDetectorRef
    ) {
        if (this.authService.isLoggedIn) {
            if (!this.cmsSessionService.isTranslator()) {
                this.isLoggedIn = true;
            }
        }
        this.subs.sink = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isCreateAPac = false;
                let path = event.url.split('?')[0];
                if (path === '/catalog/create-a-pack') {
                    this.isCreateAPac = true;
                }
            }
        });
    }

    public ngOnInit(): void {
        this.brand = undefined;
        this.category = undefined;
        this.pageTitle = '';
        this.countryCode = this.localeService.getCountryCode();
        // queryParams for filters
        this.tInitialParams = this.activatedRoute.snapshot.queryParams;
        this.paramParser(this.tInitialParams);

        // Checking if we already load products
        this.subs.sink = this.productStateService.getProducts$().subscribe((res) => {
            this.defaultProduct = res.slice();
            this.allProducts = res.slice();
        });
        this.subs.sink = this.mainCartService.getMainCart$().subscribe((cart) => {
            let shouldReloadProducts =
                this.siteUrl !== cart.referrerSiteUrl || this.cartType !== cart.cartType || this.countryCode !== cart.countryCode;
            this.siteUrl = cart.referrerSiteUrl;
            this.cartType = cart.cartType;
            this.hasRetailStarterKit = cart.hasRetailStarterKit;
            this.countryCode = cart.countryCode;
            this.loadProducts(shouldReloadProducts);
        });
        // Subscription to sort the product in case filter, sorting or search params changed
        this.subs.sink = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            if (params.has('sort')) {
                this.sortChange(params.get('sort'));
            } else {
                this.sortChange('default');
            }
            if (params.has('searchTerm')) {
                this.searchTerm = params.has('searchTerm') ? params.get('searchTerm') : '';
                this.filterBySearchTerms(this.searchTerm);
            } else if (params.has('c')) {
                this.categoryId = +params.get('c');
                this.menuId = null;
                this.filterByCategoryId(this.categoryId);
            } else {
                const query: string = params.has('m') ? params.get('m') : '';
                this.categoryId = 0;
                this.menuId = query;
                this.filterByMenuId(query.split(','));
            }
        });

        // Listen to display state change (from grid to list view)
        this.subs.sink = this.productStateService.getProductsDisplayState$().subscribe((viewType: string) => {
            this.viewType = viewType;
        });

        // Get SmartDelivery Info
        this.subs.sink = this.smartDeliveryService.getSettings$().subscribe((res: SmartDeliveryModel) => {
            this.smartDeliveryInfo = res;
        });
        // Load the user's loyalty points
        this.subs.sink = this.memberService.getLoyaltyPointsInfo().subscribe((lp: LoyaltyPointsModel) => {
            this.loyaltyPointsInfo = lp;
        });

        this.subs.sink = this.localeService.directionalityObservable$.subscribe((direction: Direction) => {
            this.directionality = direction;
            this.isRtl = direction === 'rtl';
            this.spy.detectChanges();
        });

        this.productUpdatedObservable$ = of(!!this.mainCartService.getMainCart$());
        this.seoService.sendFBPixelPageView('shop-category', this.router.url);
        this.seoService.sendSiftPageView();

        this.setTranslations();
        this.listDisplayType();
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
    public paramParser(params: Params): void {
        this.categoryId = params['c'] ? Number(params['c']) : 0;
        this.brandId = params['b'] ? Number(params['b']) : 0;
        this.sortBy = params['sort'] ? params['sort'] : 'default';
        this.menuId = params['m'] ? params['m'] : '';
        this.searchTerm = params['searchTerm'] ? params['searchTerm'] : '';
        this.hasLoaded = false;
    }

    private getPricingIndex(product: ProductModel): number {
        const isRedemption: boolean = this.mainCartService.isStoreInRedemptionMode;
        let cartPricingIndex = 0;
        if (
            this.defaultProduct.length > 0 &&
            product.pricing.length > 0 &&
            product.pricing.findIndex((x) => x.priceType === product.priceType) > -1
        ) {
            cartPricingIndex = product.pricing.findIndex((x) => x.priceType === product.priceType);
        }
        cartPricingIndex = isRedemption ? 2 : cartPricingIndex;
        return cartPricingIndex;
    }

    public sortChange(sortBy: string): void {
        switch (sortBy) {
            case 'default':
                this.allProducts = this.defaultProduct.slice();
                break;
            case 'low':
                this.allProducts = this.defaultProduct
                    .slice()
                    .sort((a, b) => a.pricing[this.getPricingIndex(a)].price - b.pricing[this.getPricingIndex(b)].price);
                break;
            case 'high':
                this.allProducts = this.defaultProduct
                    .slice()
                    .sort((a, b) => b.pricing[this.getPricingIndex(b)].price - a.pricing[this.getPricingIndex(a)].price);
                break;
            case 'asc':
                this.allProducts = this.defaultProduct.slice().sort((a, b) => {
                    if (a.name && b.name) {
                        const nameA: string = a.name.toLowerCase(),
                            nameB: string = b.name.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                    }
                    return 0;
                });
                break;
            case 'desc':
                this.allProducts = this.defaultProduct.slice().sort((a, b) => {
                    if (a.name && b.name) {
                        const nameA: string = a.name.toLowerCase(),
                            nameB: string = b.name.toLowerCase();
                        if (nameA > nameB) return -1;
                        if (nameA < nameB) return 1;
                    }
                    return 0;
                });
                break;
            case 'smartDelivery':
                this.allProducts = this.defaultProduct.filter((product) => {
                    return product.isProductAvailableOnAutoship && this.authService.isLoggedIn;
                });
                break;
            default:
                this.allProducts = this.defaultProduct.slice().sort((a, b) => {
                    if (a.name && b.name) {
                        const nameA: string = a.name.toLowerCase(),
                            nameB: string = b.name.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                    }
                    return 0;
                });
                break;
        }
    }

    public loadProducts(doesCartTypeChanged: boolean = false): void {
        this.loaderService.show();
        this.loading = true;
        if (!this.hasRetailStarterKit) {
            this.cartType = this.authService.isLoggedIn ? this.cartType : 'R';
            const inRedemption: boolean = this.mainCartService.isStoreInRedemptionMode;
            const qry: ProductQueryModel = {
                cartType: inRedemption ? 'LR' : this.cartType ? this.cartType : 'R',
                countryCode: this.countryCode ? this.countryCode : this.localeService.getCountryCode(),
                categoryId: null,
                eventId: null,
                culture: this.localeService.getLocaleCookie(),
                brandId: null,
                take: null,
                skip: 0,
                searchTerm: '',
                includePromos: false,
                isRedemption: inRedemption,
                isCart: true,
                menuId: null,
                repSiteUrl: this.siteUrl || 'jhqsocial',
            };
            // Check if we did load product already and cartType doesn't get changed
            if (this.allProducts.length > 1 && !doesCartTypeChanged) {
                this.filterInitialProductsLoad();
            } else {
                if (
                    !this.authService.isLoggedIn ||
                    qry.cartType !== 'R' ||
                    (qry.cartType === 'R' &&
                        this.authService.isLoggedIn &&
                        (this.authService.getMainTypeId() === 2 || this.authService.getMainTypeId() === 21))
                ) {
                    this.subs.sink = this.productService.products(qry).subscribe((res) => {
                        this.allProducts = res;
                        this.productStateService.setProducts(this.allProducts.slice());
                        this.filterInitialProductsLoad();
                    });
                }
            }
        } else {
            const qry: ProductQueryModel = {
                cartType: 'W',
                countryCode: this.countryCode ? this.countryCode : this.localeService.getCountryCode(),
                categoryId: null,
                eventId: null,
                culture: this.localeService.getLocaleCookie(),
                brandId: null,
                take: null,
                skip: 0,
                searchTerm: '',
                includePromos: false,
                isRedemption: false,
                isCart: false,
                menuId: null,
                mainPk: this.authService.isLoggedIn ? this.authService.getMainId() : null,
                isSignupEligible: true,
                repSiteUrl: this.siteUrl || 'jhqsocial',
                isStarterKit: true,
            };
            this.subs.sink = this.productService.products(qry).subscribe((res) => {
                this.allProducts = res;
                this.productStateService.setProducts(this.allProducts.slice());
                this.filterInitialProductsLoad();
            });
        }
    }

    // Filtering Initial Products array if we have any queryParams
    public filterInitialProductsLoad(): void {
        this.loaderService.hide();
        if (this.sortBy && this.sortBy.length > 0) {
            this.sortChange(this.sortBy);
        }
        if (this.searchTerm) {
            this.filterBySearchTerms(this.searchTerm);
        } else if (this.categoryId > 0) {
            this.filterByCategoryId(this.categoryId);
        } else {
            this.filterByMenuId(this.menuId.split(','));
        }
        this.hasLoaded = true;
        this.loading = false;
    }

    public filterByCategoryId(categoryId: number): void {
        if (!categoryId) {
            this.filteredProducts = this.allProducts;
        } else {
            this.filteredProducts = this.allProducts.filter((product) => product.categoryId === categoryId);
        }
        this.lengthOfPaginator = this.filteredProducts.length;
        this.pageIndex = 0;
        this.pagination({ previousPageIndex: this.previousPageIndex, pageIndex: this.pageIndex, pageSize: this.pageSize, length: this.lengthOfPaginator });
    }

    public filterByMenuId(menuIdArray: string[]): void {
        if (menuIdArray[0] === '' && menuIdArray.length === 1) {
            this.filteredProducts = this.allProducts;
        } else {
            this.filteredProducts = this.allProducts.filter((product) => menuIdArray.includes(product.productMenuId.toString()));
        }
        this.lengthOfPaginator = this.filteredProducts.length;
        this.pagination({ previousPageIndex: this.previousPageIndex, pageIndex: this.pageIndex, pageSize: this.pageSize, length: this.lengthOfPaginator });
    }

    public filterBySearchTerms(searchBy: string): void {
        if (searchBy.toLowerCase() === 'mind') {
            searchBy = 'm1nd';
        }
        if (searchBy.toLowerCase() === 'naara') {
            searchBy = 'naära';
        }
        this.filteredProducts = this.allProducts.filter((product) => product.name.toLowerCase().includes(searchBy.toLowerCase()));
        this.lengthOfPaginator = this.filteredProducts.length;
        this.pageIndex = 0;
        this.pagination({ previousPageIndex: this.previousPageIndex, pageIndex: this.pageIndex, pageSize: 10, length: this.lengthOfPaginator });
    }

    public pagination(event: PageEvent): void {
        if ((event.pageSize !== this.pageSize || event.pageIndex !== this.pageIndex) && HTMLElement.prototype.scroll) {
            document.querySelector('.mat-sidenav-content').scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
        if ((event.pageSize !== this.pageSize || event.pageIndex !== this.pageIndex) && !HTMLElement.prototype.scroll) {
            document.querySelector('.mat-sidenav-content').scrollTop = 0;
        }
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.previousPageIndex = event.previousPageIndex;
        this.fromPage = event.pageIndex * event.pageSize;
        this.toPage = (event.pageIndex + 1) * event.pageSize;
        this.displayProduct = this.filteredProducts.slice(this.fromPage, this.toPage);
    }

    private listDisplayType(): void {
        this.subs.sink = this.media.media$.subscribe(() => {
            if (this.media.isActive('lt-sm')) {
                this.viewType = 'row';
            }
        });
    }
    private setTranslations(): void {
        this.sortOptions = [
            { value: 'default', text: this.translationService.translate('shop', 'Default Sort', 'A-Z') },
            { value: 'asc', text: this.translationService.translate('shop', 'asc', 'A-Z') },
            { value: 'desc', text: this.translationService.translate('shop', 'desc', 'Z-A') },
            { value: 'low', text: this.translationService.translate('shop', 'low', 'Price: Low to High') },
            { value: 'high', text: this.translationService.translate('shop', 'high', 'Price: High to Low') },
        ];
    }
}
