
/**
 * Model to request documents.
 */

export class DocumentRequest {
    public languageFk: number;
    public mainTypeFk: number;
    public usage: string;
    public country: string;

    constructor(options: any = {}) {
        this.country = options.countryCode;
        this.mainTypeFk = options.mainTypeFk;
        this.usage = options.usage;
        this.languageFk = options.language;
    }
}
