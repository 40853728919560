import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';

import { OrderService } from '../../../../checkout/services/public-api';
import { OrderModel } from '../../../models/order-model';
import { FormatCurrency } from './../../../models/format-currency.model';

@Component({
    selector: 'jn-cart-total',
    templateUrl: './cart-total.component.html',
    styleUrls: ['./cart-total.component.scss'],
})
export class CartTotalComponent implements OnInit, OnDestroy {
    @Input() public orderModelSubject$: Subject<OrderModel> = new Subject<OrderModel>();
    @Input() public isShippable: boolean;
    @Input() public isRedemptionMode: boolean;
    @Input() public mainType: number;
    @Input() public isLoggedIn: number;
    @Input() public countryCode: string;
    @Input() public showShippingTaxCountries: string[];

    public showShippingTaxInfo: boolean;
    public orderModel: OrderModel;
    private subSink = new SubSink();
    constructor(private orderService: OrderService) {}

    public ngOnInit(): void {
        this.subSink.sink = this.orderModelSubject$.subscribe((orderModel: OrderModel) => {
            this.orderModel = orderModel;
            // Display breakdown of taxes by product and shipping
            if (this.showShippingTaxCountries && this.showShippingTaxCountries.length > 0) {
                this.showShippingTaxInfo = this.showShippingTaxCountries.includes(this.orderModel.countryCode);
            }
            if (this.orderModel.mainOrderTypeFk === 28) {
                if (!this.orderModel.mainOrdersFk) {
                    this.orderModel.total = 0;
                }
                this.orderModel.discount = 0;
                this.orderModel.subTotal = 0;
            }
            if (this.orderModel.formattedSubTotal == null || this.orderModel.subTotal !== this.orderModel.lastSubTotalValue) {
                this.orderModel.lastSubTotalValue = this.orderModel.subTotal;
                this.subSink.sink = this.orderService
                    .formatCurrency(this.orderModel.subTotal, this.orderModel.currencyCode)
                    .subscribe((formattedSubtotal: FormatCurrency) => {
                        this.orderModel.formattedSubTotal = formattedSubtotal.formattedValue;
                        if (this.orderModel.formattedTotal == null || this.orderModel.total !== this.orderModel.lastTotalValue) {
                            if (this.orderModel.total && this.orderModel.total != this.orderModel.subTotal) {
                                this.orderModel.lastTotalValue = this.orderModel.total;
                                this.subSink.sink = this.orderService
                                    .formatCurrency(this.orderModel.total, this.orderModel.currencyCode)
                                    .subscribe((formattedTotal: FormatCurrency) => {
                                        this.orderModel.formattedTotal = formattedTotal.formattedValue;
                                    });
                            } else {
                                this.orderModel.lastTotalValue = this.orderModel.lastSubTotalValue;
                                this.orderModel.formattedTotal = formattedSubtotal.formattedValue;
                            }
                        }
                    });
            }
            if (this.orderModel.formattedDiscount == null || this.orderModel.discount !== this.orderModel.lastDiscountValue) {
                this.orderModel.lastDiscountValue = this.orderModel.discount;
                this.subSink.sink = this.orderService
                    .formatCurrency(this.orderModel.discount, this.orderModel.currencyCode)
                    .subscribe((formattedDiscount: FormatCurrency) => {
                        this.orderModel.formattedDiscount = formattedDiscount.formattedValue;
                    });
            }
        });
    }

    public ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
