import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import * as EventAgendaActions from '../actions/event-agenda.actions';
import { EventsApiService } from '../../services/events-api.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgendasInfoEffects {
    public loadAgendasInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventAgendaActions.loadEventAgenda),
            exhaustMap((action) =>
                this.eventsApiService.getAgendasInfo(action.agendaQuery).pipe(
                    map((agendaInfo) => EventAgendaActions.updateAgendasInfo({ data: agendaInfo, hasLoaded: true })),
                    catchError((error) => of(EventAgendaActions.loadEventAgendaError(error)))
                )
            )
        )
    );

    constructor(private readonly actions$: Actions, private readonly eventsApiService: EventsApiService) {}
}
