import { Directive, ElementRef, Input, OnInit, Renderer2, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslationService } from './translation.service';
import { LocaleService } from './locale.service';
import { CmsSessionService } from './cms-session.service';
import { EditTranslationModalComponent } from './edit-translation/edit-translation-modal.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslationViewModel } from './models/public-api';

/**
 * Return the translation within HTML using the state
 * and translation name.
 */
@Directive({ selector: '[cmsTranslation]' })
export class TranslationDirective implements OnInit, OnDestroy {

    /**
     * The state of the translation.
     */
    @Input()
    public state: string;

    /**
     * The name of the translation.
     */
    @Input()
    public cmsTranslation: string;

    /**
     * The default text of the translation.
     * If not provided, the directive will attempt to find the
     * default in the inner html
     */
    @Input()
    public defaultText: string = '';

    @Input()
    public advancedSearch: boolean = false;


    private toggleSubscription: Subscription;
    private sessionSubscription: Subscription;
    private translationText: string = '';
    private translationCacheSubscription: Subscription;

    constructor(private readonly el: ElementRef,
                private readonly translationService: TranslationService,
                private readonly localeService: LocaleService,
                private readonly cmsSessionService: CmsSessionService,
                private readonly dialog: MatDialog,
                private readonly renderer: Renderer2) {
    }

    public ngOnInit(): void {
        this.renderer.addClass(this.el.nativeElement, 'cms');
        this.renderer.addClass(this.el.nativeElement, 'cms-translation');

        if (this.state && this.cmsTranslation) {
            this.getTranslation();
        }

        if (this.translationService.checkHighlight()) {
            this.renderer.addClass(this.el.nativeElement, 'cms-highlight');
        }

        this.toggleSubscription = this.translationService.getHighlightedObservable().subscribe((highlighted: boolean) => {
            if (highlighted) {
                this.renderer.addClass(this.el.nativeElement, 'cms-highlight');
            }
            else {
                this.renderer.removeClass(this.el.nativeElement, 'cms-highlight');
            }
        });

        this.sessionSubscription = this.translationService.getSessionObservable().subscribe((session: any) => {
            this.getTranslation();
        });

        this.translationCacheSubscription = this.translationService.getTranslationCacheObservable().subscribe((cache: any) => {
            this.getTranslation();
        });
    }

    public ngOnDestroy(): void {
        if (this.sessionSubscription) {
            this.sessionSubscription.unsubscribe();
        }
        if (this.toggleSubscription) {
            this.toggleSubscription.unsubscribe();
        }
        if (this.translationCacheSubscription) {
            this.translationCacheSubscription.unsubscribe();
        }
    }

    @HostListener('contextmenu') public onRightClick(): any {
        if (this.translationService.isTranslator()) {
            this.checkSetTranslation();
            let model: TranslationViewModel = this.translationService.findTranslation(this.state, this.cmsTranslation);
            if (model) {
                const dialogRef: MatDialogRef<EditTranslationModalComponent> = this.dialog.open(EditTranslationModalComponent, {
                    data: model
                });

                dialogRef.afterClosed().subscribe((res: any) => {
                    if (res) {
                        this.translationService.saveTranslation(res).subscribe((result) => {
                            if (result) {
                                this.translationText = res.content;
                                this.setInnerContent(res.content);
                            }
                        });
                    }
                });
                return false;
            }
        }
    }

    private getTranslation(): void {
        if (this.state && this.cmsTranslation) {
            if (!this.defaultText) { // if no defaultText, attempt to find it in the html
                if (this.el.nativeElement.tagName === 'INPUT' || this.el.nativeElement.tagName === 'MAT-SELECT') {
                        if (this.el.nativeElement.nextSibling && this.el.nativeElement.nextSibling.nodeType !== 8) {
                            let label: HTMLElement = this.el.nativeElement.nextSibling.querySelector('mat-label');
                            if (label) {
                                this.defaultText = label.innerHTML;
                            }
                        } else {
                            let label: HTMLElement = this.el.nativeElement.parentElement.lastElementChild.querySelector('mat-label');
                            if (label) {
                                this.defaultText = label.innerHTML;
                            }
                        }
                }
                else {
                    this.defaultText = this.el.nativeElement.innerHTML;
                }
            }

            let cultureName: string = this.cmsSessionService.getCulture();
            this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
                this.translationService.setTranslationCulture(culture);
                let nonEmptyDefault = this.defaultText.trim();
                if (!this.translationText && nonEmptyDefault) {
                    this.translationText = this.defaultText;
                }
                this.translationText = this.translationService.translate(this.state, this.cmsTranslation, this.translationText, this.advancedSearch);
                if (this.translationText) {
                    this.setInnerContent(this.translationText);
                }
            });
        }
        else {
            console.warn(`The 'cmsTranslation' directive is being used but is missing 'state' or 'cmsTranslation' input.`);
            console.log('cmsTranslation: ' + this.cmsTranslation);
        }
    }

    private checkSetTranslation(): void {
        let elCms: string = this.el.nativeElement.getAttribute('cmsTranslation');
        if (!this.cmsTranslation || this.cmsTranslation === '') {
            this.cmsTranslation = elCms;
        }
        if (elCms && this.cmsTranslation !== elCms) {
            this.cmsTranslation = elCms;
        }

        let elState: string = this.el.nativeElement.getAttribute('state');
        if (!this.state || this.state === '') {
            this.state = elState;
        }
        if (elState && this.state !== elState) {
            this.state = elState;
        }
    }

    private setInnerContent(content: string): void {
        if (content) {
            if (this.el.nativeElement.tagName === 'INPUT' || this.el.nativeElement.tagName === 'MAT-SELECT') {
                if (this.el.nativeElement.nextSibling && this.el.nativeElement.nextSibling.nodeType !== 8 && this.el.nativeElement.nextSibling.nodeType !== 1) {
                    let label: HTMLElement = this.el.nativeElement.nextSibling.querySelector('mat-label');
                    if (label) {
                        label.innerText = content;
                    }
                } else {
                    try {
                        let label: HTMLElement = this.el.nativeElement.parentElement.parentElement.parentElement.getElementsByTagName('mat-label')[0];
                        if (label) {
                            label.innerText = content;
                        }
                    } catch {
                        let label: HTMLElement = this.el.nativeElement.parentElement.lastChild.getElementsByTagName('mat-label')[0];
                        if (label) {
                            label.innerText = content;
                        }
                    }
                }
            }
            else {
                this.el.nativeElement.innerHTML = content;
            }
        }
    }
}
