import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'jn-product-sorter',
    templateUrl: './product-sorter.component.html',
    styleUrls: ['./product-sorter.component.scss']
})
export class ProductSorterComponent implements OnInit, OnDestroy {
    public sortOptions: any;
    public sortBy: string;
    constructor (
        public dialogRef: MatDialogRef<ProductSorterComponent>,
        private readonly router: Router,
        private route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    public ngOnInit(): void {
        this.sortOptions = this.data.sortOptions;
        this.sortBy = this.data.sortBy;
    }
    public ngOnDestroy(): void {

    }

    public sortChange(sortBy: string): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                sort: sortBy
            },
            queryParamsHandling: 'merge'
          });
    }
    public closeSorter(): void {
        this.dialogRef.close({data: this.sortBy});
    }
    public onRadioChange(event: MatRadioChange): void {
        this.sortBy = event.value;
    }
}