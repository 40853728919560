import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService, UrlHelperService } from '@jeunesse/angular';

import { SmartDeliveryConfirmationDialogDataModel } from '../../models/public-api';

@Component({
    selector: 'jn-sd-confirmation',
    templateUrl: './sd-confirmation.component.html',
    styleUrls: ['./sd-confirmation.component.scss'],
})
export class SmartDeliveryConfirmationComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SmartDeliveryConfirmationDialogDataModel,
        private readonly urlHelperService: UrlHelperService,
        private readonly settings: SettingsService
    ) {}

    public ngOnInit(): void {}

    public goToSmartDeliveryPage(): void {
        window.location.href = this.urlHelperService.buildUrl(this.settings.jOffice, '/smartdelivery');
    }
}
