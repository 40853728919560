/**
 * Additional Product Info Model
 */
export class ProductAdditionalInfo {
    public name: string;
    public content: string;

    constructor(obj: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}