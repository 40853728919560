import { Component, OnInit, Input } from '@angular/core';

import { CartSummaryItem } from '../../../models/public-api';

@Component({
    selector: 'jn-smartdelivery-item',
    templateUrl: './smartdelivery-item.component.html',
    styleUrls: ['./smartdelivery-item.component.scss']
})
export class SmartdeliveryItemComponent implements OnInit {
    @Input() public item: CartSummaryItem;
    constructor() { }

    public ngOnInit(): void {
    }
}
