import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '../../../cms/src/translation.service';
import { ClientInfo } from '../../../common/src/models/client-info.interface';
import { ClientInfoService } from '../../../common';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-bday-message',
    templateUrl: './bday-message.component.html',
    styleUrls: ['./bday-message.component.scss']
})
export class BdayMessageComponent implements OnInit {
    public translations: any = {
        title: '',
        message: '',
        close: ''
    };
    public clientInfo: ClientInfo;

    constructor(private readonly translationService: TranslationService,
        private readonly dialog: MatDialog,
        private readonly clientInfoService: ClientInfoService) {}

    public ngOnInit(): void {
        this.getTranslations();
        this.clientInfo = this.clientInfoService.getClientInfo();
    }

    public closeMessage(): void {
        this.dialog.closeAll();
    }

    private getTranslations(): void {
        this.translations.title = this.translationService.translate('joffice-notification-global', 'BDcodeMsgModalTitle', 'Happy Birthday!');
        this.translations.message = this.translationService.translate('joffice-notification-global', 'BDcodeMsgModalbody', null);
        this.translations.close = this.translationService.translate('joffice-notification-global', 'BDcodeMsgModalclose', 'Close');
    }
}
