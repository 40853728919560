import { ProductConfigModel } from './product-config.model';
import { ProductPriceModel } from './product-price.model';
import { ProductAdditionalInfo } from './product-additional-info';
import { ProductDocumentModel } from './product-document-model';

/**
 * Represents a product model returned from the cart-api.
 */
export class ProductModel {
    public description?: string;
    public categoryId?: number;
    public imageUrls? = [];
    public productPK: number;
    public productCode?: string;
    public brandId?: number;
    public name?: string;
    public imageUrl?: string;
    public priceWarning?: string;
    public isProductAvailableOnAutoship?: boolean;
    public autoshipProductPk?: number;
    public isComingSoon?: boolean;
    public comingSoonMessage?: string;
    public isPackage?: boolean;
    public isConfigurable?: boolean;
    public productMenu?: string;
    public configurations?: ProductConfigModel[];
    public pricing?: ProductPriceModel[];
    public additionalInfo?: ProductAdditionalInfo[];
    public documents?: ProductDocumentModel[];
    public maxLimit?: number;
    public points?: number;
    public isShippable?: boolean;
    public isStarterKit?: boolean;
    public childItems?: any;
    public seqNo?: number;
    // Parameters not returned from api model.
    public quantity: number;
    public setupForAs?: boolean;
    public selectedConfig?: ProductConfigModel[];
    public addToSmartDelivery?: boolean;
    public customChildItems?: any;
    public bv?: number;
    public selectedSDConfig?: ProductConfigModel[];
    public isVolumeBasedRSB?: boolean;
    public activeSmartDelivery?: boolean;
    public priceType?: string;
    public isRedemption?: boolean;
    public mainType?: number;
    public doNotSplitPackBV?: boolean;
    public productMenuId?: number;
    public isSignupFlow?: boolean = false;
    public priceListFk?: number;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        } else {
            this.quantity = 0;
            this.imageUrls = [];
            this.pricing = [];
            this.additionalInfo = [];
            this.configurations = [];
            this.documents = [];
            this.addToSmartDelivery = false;
        }
    }
}
