import { MenuItem } from './menu-item.model';
export class JetMenuItem {
    id?: number;
    name?: string;
    content?: string;
    parentId? : number;
    sortId?: number;
    action?: string;
    submenu?: JetMenuItem[];
    icon?: string;
    isOpened?: boolean;
    isWebsiteLink?: boolean;
    translationKey?: string;
    inNewTab?: boolean;
    menuItems?: MenuItem[];
    constructor(options: any = {}) {
        this.id = options.id;
        this.name = options.name;
        this.content = options.content;
        this.parentId = options.parentId;
        this.sortId = options.sortId;
        this.action = options.action;
        this.submenu = options.submenu;
        this.icon = options.icon;
        this.isOpened = options.isOpened;
        this.isWebsiteLink = options.isWebsiteLink;
        this.translationKey = options.translationKey;
        this.inNewTab = options.inNewTab;
        this.menuItems = options.menuItems;
    }

}
