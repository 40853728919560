import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberOnly',
})

export class NumberOnly implements PipeTransform {
    public transform(val: any): string {
        if (val) {
            return val.replace(/\D/g, '');
        }
    }
}