export class Get3DSTokenUrlModel {
    public source: string;
    public paymentMethodCode: string;
    public oncode: string;
    public token: string;
    public useOrder: string;

    constructor(options: any = {}) {
        this.paymentMethodCode = options.paymentMethodCode;
        this.oncode = options.oncode;
        this.token = options.token;
        this.useOrder = options.useOrder;
    }
}