import { Component, Input, OnInit } from "@angular/core";
import { MainUsersService } from "../../services/main-user.service";
import { SubSink } from "subsink";
import { SponsorPlacementView } from "../../interfaces/sponsor-placement-view.interface";
import { Observable } from "rxjs";


@Component({
  selector: 'jn-sponsor-placement',
  templateUrl: './sponsor-placement.component.html'
})
export class SponsorPlacementComponent implements OnInit {
  @Input() mainFk?: number;
  @Input() languageFk?: number;
  viewModel: Observable<SponsorPlacementView>;
  private subs: SubSink = new SubSink();
  constructor(private readonly mainUsersService: MainUsersService) {}

  ngOnInit(): void {
    this.viewModel = this.mainUsersService.getSponsorPlacementView({ mainFk: this.mainFk, languageFk: this.languageFk});
  }
}
