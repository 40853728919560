import { MarketingRegion } from './marketing-region.model';
import { Language } from './language.model';

/**
 * Model to represent the County table in back office.
 */
export class Country {
    public countryName: string;
    public code2: string;
    public imageUrl: string;
    public imageUrlSm: string;
    public imageUrlLg: string;
    public isJfrontAvailable: boolean;
    public marketingRegion: MarketingRegion;
    public zone: number;
    public languages: Array<Language>;

    constructor(obj: any) {
        this.countryName = obj.countryName;
        this.code2 = obj.code2;
        this.imageUrl = obj.imageUrl;
        this.imageUrlSm = obj.imageUrlSm;
        this.imageUrlLg = obj.imageUrlLg;
        this.isJfrontAvailable = obj.isJfrontAvailable;
        this.marketingRegion = new MarketingRegion(obj.marketingRegion);
        this.zone = obj.zone;
        this.languages = this.processLanguages(obj.languages);
    }

    /**
     * Loads the language array for the object.
     * @param obj
     */
    public processLanguages(obj: any): Array<Language> {
        let retAry: Array<Language> = new Array<Language>();

        obj && obj.forEach(element => {
           let l: Language = new Language(element);
           retAry.push(l);
        });

        return retAry;
    }
}