import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { WebStorageService } from '../../common/src/web-storage.service';
import { AuthService } from './auth.service';
import { AuthHttpService } from './auth-http.service';
import { UsersService } from './users.service';
import { LoginRedirectComponent } from './components/loginRedirect.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
    ],
    declarations: [
        LoginRedirectComponent
    ],
    entryComponents: [
        LoginRedirectComponent
    ]
})
export class AuthenticationModule {
    /**
     * This is required so the same provider is injected into the load on demand modules.
     *
     * More Details: http://angularfirst.com/the-ngmodule-forroot-convention/
     */
    public static forRoot(): ModuleWithProviders<AuthenticationModule> {
        return {
            ngModule: AuthenticationModule,
            providers: [
                UsersService,
                AuthService,
                AuthHttpService,
                WebStorageService,
                {provide: MAT_DIALOG_DATA, useValue: {}},
                {provide: MatDialogRef, useValue: {}}
            ]
        };
    }
}
