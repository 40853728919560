import { Subscription } from 'rxjs/internal/Subscription';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthHttpService } from '../../authentication/src/auth-http.service';
import { AuthService } from '../../authentication/src/auth.service';
import { UrlHelperService } from '../../common/src/url-helper.service';
import { WebStorageService } from '../../common/src/web-storage.service';
import { ApplicationNotification } from './models/application-notification.interface';
import { DiscountModel } from './models/discounts.model';
import { ApplicationNotificationQuery } from './public-api';

@Injectable({ providedIn: 'root' })
export class ContentHeaderNotificationService {
    private _applicationNotification: BehaviorSubject<ApplicationNotification[]> = new BehaviorSubject<ApplicationNotification[]>([]);
    private _notificationQuery = new Map<string, Subscription>();
    constructor(
        private authHttp: AuthHttpService,
        private urlHelper: UrlHelperService,
        private readonly auth: AuthService,
        private readonly cookieService: WebStorageService
    ) {}

    public getWarningDetails(): Observable<boolean> {
        return this.authHttp.get<any>(this.urlHelper.toSecurityApi('/v1/status')).pipe(
            map((message: boolean) => {
                return message;
            })
        );
    }

    public getDiscountDetails(): Observable<DiscountModel.Response> {
        let query: DiscountModel.Query = {
            discountType: '',
            discountTypes: '',
            mainId: this.auth.getMainId().toString(),
            mainSiteUrl: '',
            startDate: '',
            endDate: '',
            offset: '',
            limit: '',
            orderBy: '',
            status: 'ACTIVE',
        };
        return this.authHttp.get<DiscountModel.Response>(this.urlHelper.toReportsApi('/v1/reports/discount'), { params: query }).pipe(
            map((response: DiscountModel.Response) => {
                return response;
            })
        );
    }

    public arePointsArchivingNow(): Observable<boolean> {
        return this.authHttp.get<boolean>(this.urlHelper.toSecurityApi('/v1/points-archiving-running'));
    }

    public getApplicationNotificationsQuery(model: ApplicationNotificationQuery): void {
        const query: string = model.toString();
        if(!this._notificationQuery.get(query)) {
            this._notificationQuery.set(
                query,
                this.authHttp
                .get<ApplicationNotification[]>(this.urlHelper.toSecurityApi('/v1/general-notifications'), { params: model })
                .subscribe((response: ApplicationNotification[]) => {
                    this._applicationNotification.next(response);
                })
            )
        }

        this._notificationQuery.get(query);
    }

    public getApplicationNotifications(): Observable<ApplicationNotification[]> {
        return this._applicationNotification.asObservable();
    }

    public clearSession(): void {
        this.cookieService.removeCookie('CheckNotification');
        this.cookieService.removeCookie('showDiscount');
        this.cookieService.removeCookie('discountMessages');
        this.cookieService.removeCookie('showWarning');
        this.cookieService.removeCookie('notificationId');
        this.cookieService.removeCookie('showPointsArchivingMsg');
    }
}
