import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationService } from '../../../cms/src/translation.service';
import { DiscountModel } from '../models/discounts.model';
import { ClientInfo } from '../../../common/src/models/client-info.interface';
import { ClientInfoService } from '../../../common';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-discount-message',
    templateUrl: './discount-message.component.html',
    styleUrls: ['./discount-message.component.scss']
})
export class DiscountMessageComponent implements OnInit {
    public translations: any = {
        title: '',
        message: '',
        close: ''
    };
    public promoCode: string = '';
    public promoType: number = 5;
    public promoExpire: Date;
    public clientInfo: ClientInfo;

    constructor(private readonly translationService: TranslationService
        , private readonly dialog: MatDialog
        , private readonly config: MatDialogConfig
        , private readonly clientInfoService: ClientInfoService
        , @Inject(MAT_DIALOG_DATA) public data: DiscountModel.Discount) {
        this.promoCode = data.discountCode;
        this.promoType = data.mainDiscountTypeFK;
        this.promoExpire = data.dateExpired;
    }

    public ngOnInit(): void {
        this.getTranslations();
        this.clientInfo = this.clientInfoService.getClientInfo();
    }

    public closeMessage(): void {
        this.dialog.closeAll();
    }

    private getTranslations(): void {
        switch (this.data.mainDiscountTypeFK) {
            case 2:
                this.translations.title = this.translationService.translate('joffice-notification-global', this.data.translationKey + 'Title', 'Event Discount!');
                this.translations.message = this.translationService.translate('joffice-notification-global', this.data.translationKey, null);
                if (this.translations.message)this.translations.message = this.translations.message.replace('[[discountcode]]', this.data.discountCode);
                break;
            case 5:
                this.translations.title = this.translationService.translate('joffice-notification-global', 'BDcodeMsgModalTitle', 'Happy Birthday!');
                this.translations.message = this.translationService.translate('joffice-notification-global', 'BDcodeMsgModalbody', null);
                break;
            default:
                this.translations.title = this.translationService.translate('joffice-notification-global', this.data.translationKey + 'Title', 'Discount');
                this.translations.message = this.translationService.translate('joffice-notification-global', this.data.translationKey + 'Body', null);
                break;
        }

        this.translations.close = this.translationService.translate('joffice-notification-global', 'BDcodeMsgModalclose', 'Close');
    }
}
