export class EventQueryModel {
    public eventId?: string;
    public eventUrl?: string;
    public startDate?: string;
    public endDate?: string;
    public archived?: boolean;
    public languageFk?: string;
    public registrationOpen?: boolean;

    constructor(options: any) {
        this.eventId = options.eventId ? options.eventId : '';
        this.eventUrl = options.eventUrl ? options.eventUrl : '';
        this.startDate = options.startDate ? options.startDate : '';
        this.endDate = options.endDate ? options.endDate : '';
        this.archived = options.archived ? options.archived : '';
        this.languageFk = options.languageFk ? options.languageFk : '';
        this.registrationOpen = options.registrationOpen ? options.registrationOpen : '';
    }
}