import { Injectable } from '@angular/core';
import { PaymentApiService } from './payment-api.service';
import { PaymentStateService } from './payment-state.service';
import { PaymentProcess, PaymentResource, PaymentStatus } from '../models/public-api';
import { Observable, Subject } from 'rxjs';

/**
 * This class is the inbetween for components and updating the payment state and api service.
 */
@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private paymentStatusState$ = new Subject<PaymentStatus>();

    constructor(private readonly paymentApiService: PaymentApiService,
        private readonly paymentStateService: PaymentStateService) {
    }

    /**
     * Adds a new payment method to the order.
     * @param orderNumber Order number
     * @param payment Payment method to add to the order
     */
    public addPaymentToOrder(orderNumber: number, payment: PaymentProcess): Observable<PaymentResource> {
        return this.paymentApiService.addPaymentToOrder(orderNumber, payment);
    }

    /**
     * Able to Manually update order.
     * @param orderNumber Order number
     * @param payment Payment method to add to the order
     */
    public updatePayment(orderNumber: number, payment: PaymentProcess): void {
        this.paymentApiService.updatePayment(orderNumber, payment).subscribe((paymentResource: PaymentResource) => {
            this.paymentStateService.updatePaymentResource(paymentResource);
        },
        (error: any) => {
            console.log(error);
        });
    }


    /**
     *
     * @param orderNumber Order number
     */
    public getPaymentStatus(orderNumber: number): Observable<PaymentStatus[]> {
        return this.paymentApiService.getPaymentStatus(orderNumber);
    }

    /**
     *Update saved CC details
     *@param savedCreditCard
     */
    public updatePaymentDetails(model: any, memberModel: any, billingEqualToShipping: any) {
        return this.paymentApiService.updatePaymentDetails(model, memberModel, billingEqualToShipping)
    }
}
