import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '../../../authentication';
import { WebStorageService } from '../../../common';
import { DocumentsByCountryLanguage } from '../models';
import { AgreeToTermsService } from '../services';

@Component({
    selector: 'lib-agree-to-terms',
    templateUrl: './agree-to-terms.component.html',
    styleUrls: ['./agree-to-terms.component.scss']
})
export class AgreeToTermsComponent implements OnInit, OnDestroy {
    public isAgreementChecked: boolean = false;
    public documents: DocumentsByCountryLanguage[] = [];
    /**
     * ADDED SHARED CONTAINER FOR ALL SUBSCRIPTIONS
     */
    public subscriptions: Subscription = new Subscription();
    public documents$: Observable<DocumentsByCountryLanguage[]>;

    @Input() public country: string;
    @Input() public languageFk: number;
    @Input() public usage: string = 'sign up';
    @Input() public subUsage: string = 'agreement';
    @Input() public mainTypeFk: number;
    @Input() public from: string = 'terms';
    @Input() public purpose: string = 'wcAgree';
    @Input() public onSubmit$: Subject<boolean>;

    @Output() public emitAgreement = new EventEmitter<boolean>();

    constructor(
        private readonly agreeToTermsService: AgreeToTermsService,
        private readonly authService: AuthService,
        private readonly webStorageService: WebStorageService
    ) {}

    public ngOnInit(): void {
        /**
         * INITIALIZE ALL SUBSCRIPTIONS WHEN COMPONENT IS INIT
         */
        this.initializeSubscriptions();
    }

    /**
     * @description REMOVED ALL SUBSCRIPTIONS
     * @author Alex Togo
     * @date 07/12/2020
     * @memberof UploadsTabComponent
     */
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * @description INITIALIZE ALL SUBSCRIPTIONS WHEN COMPONENT IS INIT
     * @author Alex Togo
     * @date 07/12/2020
     * @memberof UploadsTabComponent
     */
    public initializeSubscriptions(): void {
        this.documents$ = this.agreeToTermsService.getDocumentsByCountryLanguage(
            this.country,
            this.languageFk,
            this.usage,
            this.subUsage,
            this.mainTypeFk ?? 1
        );

        this.subscriptions.add(
            /**
             * READ DATA FROM URL OF LOADING PAGE
             */
            this.documents$.subscribe((data) => {
                this.documents = data;
            })
        );

        this.subscriptions.add(
            this.onSubmit$.subscribe((_data) => {
                this.submitChanges();
            })
        );
    }

    public agreeOnChange(): void {
        this.emitAgreement.emit(this.isAgreementChecked);
    }

    /**
     * emitted Response from lib-agree-to-terms component
     *
     * @author Alex Togo
     * @date 07/12/2020
     * @param {boolean} input
     * @memberof AccountComponent
     */
    private submitChanges(): void {
        if (!this.isAgreementChecked) {
            return;
        }

        if (this.authService.isLoggedIn) {
            if (!this.mainTypeFk) {
                this.mainTypeFk = this.authService.getMainTypeId();
            }
            this.agreeToTermsService.sendDistributorAgreementToDB(this.from, this.purpose, this.mainTypeFk).subscribe();
        }

        if (!this.authService.isLoggedIn && this.webStorageService.getCookie('signupKey')) {
            this.agreeToTermsService
                .logAgreedToDocuments(this.webStorageService.getCookie('signupKey'), this.usage, this.subUsage, this.mainTypeFk)
                .subscribe();
        }
    }
}
