export class MarketingModel {
    public skypeAccount: string;
    public twitterAccount: string;
    public facebookUrl: string;
    public facebookPixelId: string;
    public facebookDomainKey: string;
    public googleAnalyticsUa: string;
    public retailAdWordsId: string;
    public retailAdWordsLabel: string;
    public wholesaleAdWordsId: string;
    public wholesaleAdWordsLabel: string;
    public successOnDemandEnrolled: boolean;
    public mainPk: number;
    public phrase: string;
    public bio: string;

    constructor(options: any = {}) {
        this.skypeAccount = options.skypeAccount;
        this.twitterAccount = options.twitterAccount;
        this.facebookUrl = options.facebookUrl;
        this.facebookPixelId = options.facebookPixelId;
        this.facebookDomainKey = options.facebookDomainKey;
        this.googleAnalyticsUa = options.googleAnalyticsUa;
        this.retailAdWordsId = options.retailAdWordsId;
        this.retailAdWordsLabel = options.retailAdWordsLabel;
        this.wholesaleAdWordsId = options.wholesaleAdWordsId;
        this.wholesaleAdWordsLabel = options.wholesaleAdWordsLabel;
        this.successOnDemandEnrolled = options.successOnDemandEnrolled;
        this.mainPk = options.mainPk;
        this.phrase = options.phrase;
        this.bio = options.bio;
    }
}
