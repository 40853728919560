import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { MainCartItem } from '@jeunesse/slide-cart';
import { CardInfo, PaymentInfo, PaymentOption, PrepaidOrderInfo, PrepaidSimpleViewModel, PrepaidsQueryModel, SmartDeliveryItemFormModel, SmartDeliveryItemModel, SmartDeliverySettingsFormModel, SmartDeliverySettingsModel } from '../models/public-api';
import { SmartDeliveryApiService } from './smartdelivery-api.service';
import { SmartDeliveryStateService } from './smartdelivery-state.service';
import { ShippingMethodsViewModel } from '../../checkout/public-api';
import { LoggerService, LoggerTypeEnum, TranslationService, LocaleService, CmsSessionService } from '@jeunesse/angular/common';

@Injectable({
    providedIn: 'root'
})
export class SmartDeliveryService {
    private translations: any = {};
    constructor(private readonly smartDeliveryApiService: SmartDeliveryApiService,
        private readonly smartDeliveryStateService: SmartDeliveryStateService,
        private readonly loggerService: LoggerService,
        private readonly translationService: TranslationService,
        private readonly localeService: LocaleService,
        private readonly cmsSessionService: CmsSessionService,) {
            this.getTranslations();
        }
    /**
     * Settings
     */
    public getSettings$(): Observable<SmartDeliverySettingsModel> {
        return this.smartDeliveryStateService.getSmartDeliveryInfo$();
    }

    public loadSmartDeliveryInfo(includeItems: boolean): Observable<SmartDeliverySettingsModel> {
        if (includeItems) {
            return this.smartDeliveryApiService.getSettings()
            .pipe(mergeMap((settings: SmartDeliverySettingsModel) => this.smartDeliveryApiService.getItems()
            .pipe(map((items: SmartDeliveryItemModel[]) => {
                if (!settings) { settings = new SmartDeliverySettingsModel({}); }
                if (!items) { items = []; }
                settings.smartDeliveryItems = items;
                this.smartDeliveryStateService.setSmartDeliveryItems(items);
                this.smartDeliveryStateService.setSmartDeliveryInfo(settings);
                return settings;
            }))));
        } else {
            return this.smartDeliveryApiService.getSettings()
                .pipe(map((settings: SmartDeliverySettingsModel) => {
                    if (!settings) { settings = new SmartDeliverySettingsModel({}); }
                    this.smartDeliveryStateService.setSmartDeliveryInfo(settings);
                    return settings;
                }));
        }
    }

    public createSettings(): void {
        this.smartDeliveryApiService.createSettings()
            .pipe(map((settings: SmartDeliverySettingsModel) => {
                this.smartDeliveryStateService.setSmartDeliveryInfo(settings);
            },
            () => {
                this.loggerService.log({ logType: LoggerTypeEnum.ERROR, message: this.translations.settingsUpdated });
            })).subscribe();
    }

    public updateSettings(model: SmartDeliverySettingsFormModel): void {
        this.smartDeliveryApiService.updateSettings(model)
            .pipe(map((settings: SmartDeliverySettingsModel) => {
                this.smartDeliveryStateService.setSmartDeliveryInfo(settings);
            })).subscribe();
    }


    /**
     * Items
     */
    public getItems$(): Observable<SmartDeliveryItemModel[]> {
        return this.smartDeliveryStateService.getSmartDeliveryItems$();
    }

    public loadSmartDeliveryItems(): void {
        this.smartDeliveryApiService.getItems()
            .pipe(map((items: SmartDeliveryItemModel[]) => {
                if (!items) { items = []; }
                this.smartDeliveryStateService.setSmartDeliveryItems(items);
            })).subscribe();
    }

    public updateItem(autoshipOrderItemsPK: number, model: SmartDeliveryItemFormModel): void {
        this.smartDeliveryApiService.updateItem(autoshipOrderItemsPK, model)
            .pipe(map((item: SmartDeliveryItemModel) => {
                this.smartDeliveryStateService.updateSmartDeliveryItem(item);
                this.loggerService.log({ logType: LoggerTypeEnum.INFO, message: this.translations.productUpdated });
                if (item.validationMessages && item.validationMessages.length > 0) {
                    this.loggerService.log({ logType: LoggerTypeEnum.INFO, message: item.validationMessages.toString() });
                }
            })).subscribe();
    }

    public addItem(model: SmartDeliveryItemFormModel): void {
        this.smartDeliveryApiService.addItem(model)
            .pipe(mergeMap((item: SmartDeliveryItemModel) => this.smartDeliveryApiService.getItems()
            .pipe(map((items: SmartDeliveryItemModel[]) => {
                this.smartDeliveryStateService.setSmartDeliveryItems(items);
                this.loggerService.log({ logType: LoggerTypeEnum.INFO, message: this.translations.productAdded });
                if (item.validationMessages && item.validationMessages.length > 0) {
                    this.loggerService.log({ logType: LoggerTypeEnum.INFO, message: item.validationMessages.toString() });
                }
            })))).subscribe();
    }

    public removeItem(autoshipOrderItemsPK: number): void {
        this.smartDeliveryApiService.removeItem(autoshipOrderItemsPK)
            .pipe(map(() => {
                this.smartDeliveryStateService.removeSmartDeliveryItem(autoshipOrderItemsPK);
                this.loggerService.log({ logType: LoggerTypeEnum.INFO, message: this.translations.productRemoved });
            })).subscribe();
    }

    public addDonation(): void {
        this.smartDeliveryApiService.addDonation()
            .pipe(map((item: SmartDeliveryItemModel) => {
               this.smartDeliveryStateService.updateSmartDeliveryItem(item);
        })).subscribe();
    }

    /**
     * Shipping
     */

    public getShippingOptions(): Observable<ShippingMethodsViewModel> {
        return this.smartDeliveryApiService.getShippingOptions();
    }

    /**
     * Payment
     */

    public getCards(): Observable<CardInfo[]> {
        return this.smartDeliveryApiService.getCards();
    }

    public getPaymentOptions(): Observable<PaymentOption[]> {
        return this.smartDeliveryApiService.getPaymentOptions();
    }

    public getPaymentInfo(): Observable<PaymentInfo[]> {
        return this.smartDeliveryApiService.getPaymentInfo();
    }


    /**
     * Order
     */

    public createSmartDeliveryOrder(): Observable<PrepaidOrderInfo> {
        return this.smartDeliveryApiService.createSmartDeliveryOrder();
    }

    public getPrepaids(prepaidQuery?: PrepaidsQueryModel): Observable<PrepaidSimpleViewModel> {
        return this.smartDeliveryApiService.getPrepaids(prepaidQuery);
    }

    public createPrepaidOrder(items: MainCartItem[]): Observable<PrepaidOrderInfo> {
        return this.smartDeliveryApiService.createPrepaidOrder(items);
    }

    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe(culture => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        this.translations.productRemoved = this.translationService.translate('smartdelivery', 'product-removed', 'The product has been removed');
        this.translations.productUpdated = this.translationService.translate('smartdelivery', 'product-updated', 'The quantity of product updated');
        this.translations.productAdded = this.translationService.translate('smartdelivery', 'product-added', 'The product has been added');
        this.translations.settingsUpdated = this.translationService.translate('smartdelivery', 'settings-updated', 'Settings updated');
        this.translations.settingsFailed = this.translationService.translate('smartdelivery', 'settings-failed', 'There was a problem updating your settings. Please refresh the page and try again.');
    }
}
