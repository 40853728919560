import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@jeunesse/angular';
import { CmsModule } from '@jeunesse/angular/cms';
import { LoaderModule, MenuLibModule, ToolTipModule } from '@jeunesse/cart-lib';
import { MemberService } from '@jeunesse/cart-lib/member-service';
import { SlideCartModule } from '@jeunesse/slide-cart';
import { CmsFooterComponent } from './cms-footer.component';
import { HeaderComponent } from './header.component';
import { FooterComponent } from './footer.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MatSidenavModule,
        MatAutocompleteModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatDividerModule,
        MatTabsModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        OverlayModule,
        FlexLayoutModule,
        MatSnackBarModule,
        LoaderModule,
        NotificationsModule,
        CmsModule,
        MatSelectModule,
        ToolTipModule,
        SlideCartModule.forRoot(),
        MenuLibModule.forRoot(),
    ],
    exports: [HeaderComponent, FooterComponent],
    declarations: [CmsFooterComponent, HeaderComponent, FooterComponent],
    providers: [MemberService],
    entryComponents: [],
})
export class LayoutModule {}
