/**
 * member model
 */
export class PasswordFormModel {
    public oldPassword: string;
    public newPassword: string;

    constructor(options: any = {}) {
        this.oldPassword = options.oldPassword;
        this.newPassword = options.newPassword;
    }
}