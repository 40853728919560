import { Injectable, OnDestroy } from '@angular/core';
import { PaymentProcess, PaymentResource, PaymentErrorModel } from '../models/public-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { findIndex } from 'rxjs/operators';
/**
 * Payment state service used to keep state for payments on the application that the components would subscribe too.
 */
@Injectable({
    providedIn: 'root'
})
export class PaymentStateService {
    private paymentResourceState$ = new BehaviorSubject<PaymentResource[]>([]);

    protected tokenSubject = new Subject<number>();
    public tokenState: Observable<number> = this.tokenSubject.asObservable();
    protected errorSubject = new Subject<PaymentErrorModel>();
    public errorState: Observable<PaymentErrorModel> = this.errorSubject.asObservable();

    public getPaymentResources(): Observable<PaymentResource[]> {
        return this.paymentResourceState$.asObservable();
    }

    public getSavedSessionPaymentResource(sessionSplitPayment: PaymentResource[]): void {
        this.paymentResourceState$.next(sessionSplitPayment);
    }

    public addPaymentResource(paymentResource: PaymentResource): any {
        // treat review as paid amount (pending approval) - do not show toast
        if (paymentResource) {
            if ((paymentResource.paymentMethodTypePK === 2 || paymentResource.paymentMethodTypePK === 59) &&
            ((paymentResource.responseText == 'review' && paymentResource.amountProcessed > 0))) {
                paymentResource.amountPaid = paymentResource.amountProcessed;
            }
            if (paymentResource.balance > 0) {
                let errorModel: PaymentErrorModel = this.createErrorModel(paymentResource);
                if ((paymentResource.paymentMethodTypePK === 2 || paymentResource.paymentMethodTypePK === 59) && !paymentResource.amountPaid) {
                    this.errorSubject.next(errorModel);
                    return;
                } else if (!paymentResource.amountProcessed) {
                    this.errorSubject.next(errorModel);
                    return;
                }
            }
            let payments: PaymentResource[] = this.paymentResourceState$.getValue();
            if (paymentResource.paymentMethodTypePK === 29 && !paymentResource.isAppliedAsPaymentMethod) {
                this.tokenSubject.next(paymentResource.amountProcessed);
                paymentResource.checkBonusToken = true;
            } else {
                paymentResource.checkBonusToken = false;
            }
            this.paymentResourceState$.next([...payments, paymentResource]);
        }
    }

    public updatePaymentResource(paymentResource: PaymentResource): void {
        const payments: PaymentResource[] = this.paymentResourceState$.getValue();
        this.paymentResourceState$.next([...payments, paymentResource]);
    }

    public clearPaymentResource(): any {
        this.paymentResourceState$.next([]);
    }

    public clearTokenSubject(): any {
        this.tokenSubject.next(0);
    }

    public clearErrorSubject(): any {
        this.errorSubject.next(new PaymentErrorModel({ state: '', message: '', name: '' }));
    }

    public createErrorModel(paymentResource: PaymentResource): PaymentErrorModel {
        let errorModel: PaymentErrorModel = new PaymentErrorModel({
            message: paymentResource.validationMessage || paymentResource.responseText,
            state: paymentResource.cmsErrorTranslationName,
            name: paymentResource.validationMessage || paymentResource.responseText,
            hasTranslation: paymentResource.hasErrorTranslation
        });
        return errorModel;
    }
}
