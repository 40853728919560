/**
 * Authentication Token Data.
 */
export class TokenModel {
    /**
     * Issued At
     */
    public createdDate: Date;
    /**
     * Country
     */
    public country: string;
    /**
     *    Culture
     */
    public culture: string;
    /**
     *  Language Id
     */
    public langId: number;
    /**
     * Main Id
     */
    public mainId: number;
    /**
     * MainType Id
     */
    public mainTypeId: number;
    /**
     * Token Expires Date
     */
    public expireDate: Date;
    /**
     * Session Id
     */
    public sessionId: number;
    /**
     * SiteUrl
     */
    public siteUrl: string;
    /**
     * FirstName
     */
    public firstName: string;
    /**
     * LastName
     */
    public lastName: string;
    /**
     * Email
     */
    public email: string;
    /**
     * Phone
     */
    public phone: string;
    /**
     * Account Status
     */
    public status: string;
    /**
     * Old Back Office Token
     */
    public oldToken: string;
    /**
     * Claims
     */
    public claims: string[];
    /**
     * First login after signup
     */
    public showWelcome: boolean;

    public walletUserName: string;

    public aliasLogin: boolean;

    constructor(options: any) {
        this.createdDate = new Date(options.iat * 1000);
        this.country = options.cty;
        this.culture = options.clt;
        this.langId = Number(options.lid);
        this.mainId = Number(options.cid);
        this.mainTypeId = Number(options.cut);
        this.expireDate = new Date(options.exp * 1000);
        this.sessionId = Number(options.sid);
        this.siteUrl = options.usn;
        this.firstName = options.fnm;
        this.lastName = options.lnm;
        this.email = options.eml;
        this.phone = options.phn;
        this.status = options.sts;
        this.oldToken = options.tkn;
        this.claims = options.clm.split(',');
        this.showWelcome = options.swv === 'True';
        this.walletUserName = options.wlt;
        this.aliasLogin = options.alg === 'True';
    }
}
