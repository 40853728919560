import { UserSimpleModel } from './user-simple.model';

export class UserSimpleViewModel {
    public totalCount: number;
    public users: UserSimpleModel[];

    constructor(options: any = {}) {
        this.totalCount = options.totalCount;
        this.users = (options.users && options.users.map(value => new UserSimpleModel(value)));
    }
}