
export class CartFormModel {
    public mainFk?: number;
    public cartType?: string;
    public mainReferrerFk?: number;
    public culture: string;
    public mainOrderTypeFk: number;
    public campaignCode: string;
    public priceListFk?: number;
    public salesCampaignFk?: number;
    public referrerSiteUrl?: string;
    public customerReferralId?: string;


    constructor() {
        this.culture = 'en-US';
        this.mainOrderTypeFk = 4;
    }
}