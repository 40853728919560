import { Component, Inject, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { Subscription, timer } from 'rxjs';
import { SeoService } from '@jeunesse/angular';
import { ANIMATIONS } from '../../components/animations';

import { MenuItem } from '../../models/menu-item.model';

import { MENU_OVERLAY_DATA } from './menu-overlay.tokens';
import { MenuOverlayRef } from './menu-overlay-ref';
import { Router } from '@angular/router';
import { MenuService } from '../../services/menu.service';

/**
 * This is for showing the categories of products in an overlay.
 */
@Component({
    templateUrl: './menu-overlay.component.html',
    styleUrls: ['./menu-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ANIMATIONS.transformOverlay],
    host: {
        '[@transformOverlay]': 'animationState',
        '(@transformOverlay.start)': 'onAnimationStart($event)',
        '(@transformOverlay.done)': 'onAnimationDone($event)'
    }
})
export class MenuOverlayComponent {
    /**
     * This is the remote for controlling the overlay.
     * This needs to be set after the overlay has been created.
     */
    public menuOverlayRef: MenuOverlayRef;
    /**
     * Changed state of the animations.
     */
    public animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

    /**
     * The current state of the animation.
     */
    public animationState: 'void' | 'enter' | 'leave' = 'enter';

    private timer: Subscription;

    constructor(
        private readonly router: Router,
        private readonly seoService: SeoService,
        @Inject(MENU_OVERLAY_DATA) public readonly data: MenuItem,
        private readonly ref: ChangeDetectorRef,
        private readonly menuService: MenuService
    ) { }

    public isExternal(link: string): boolean {
        return this.menuService.isExternal(link);
    }

    public toExternalUrl(url: string): string {
        return this.menuService.toExternalUrl(url);
    }

    public convertLinkToRoute(url: string): string {
        return this.menuService.convertLinkToRoute(url);
    }

    public buildQueryParams(url: string) {
        return this.menuService.addQueryParamsToRoute(url);
    }

    /**
     * This is called when the animation starts.
     * @param event The event.
     */
    public onAnimationStart(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * This is called when the animation ends.
     * @param event The event.
     */
    public onAnimationDone(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    /**
     * Start the exist animation.
     */
    public startExitAnimation(): void {
        this.animationState = 'leave';

        this.ref.markForCheck();
    }

    public sendGAInfo(category: string, action: string, label?: string): void {
        if (label === undefined) {
            label = this.router.url + ' - ' + this.data.title;
        }
        this.seoService.trackClick(label, action, category);
    }

    public closeMe(): void {
        if (this.menuOverlayRef) {
            this.timer = timer(180).subscribe(_ => {
                if (this.menuOverlayRef) this.menuOverlayRef.close();
            });
        }
    }

    @HostListener('mouseout')
    public hide() {
        this.closeMe();
    }

    @HostListener('mouseover')
    public show() {
        if (this.timer) {
            this.timer.unsubscribe();
        }
    }

    @HostListener('body:click', ['$event'])
    public handleKeydown(event: MouseEvent): void {
        this.closeMe();
    }
}
