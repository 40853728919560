import { BrandModel } from '../brand/brand.model';
import { TranslationService } from '@jeunesse/angular';
import { MenuItem, MenuLayoutType } from '../../../menu/models/public-api';

/**
 * Category model
 */
export class CategoryModel {

    public id: number;
    public name: string;
    public description: string;
    public imageUrl: string;
    public imageKey: string;
    public bannerImageUrl: string;
    public seqNo: number;
    public isBranded: boolean;
    public brands: BrandModel[];
    public hasSubMenu: boolean;
    public showInTopMenu: boolean;
    public cmsName: string;
    public translationKey: string;
    public menus: any = [];
    public bannerImageKey: string;
    public isOpen?: boolean = false;
    public menuItems?: MenuItem[];
    public title?: string;
    public navigationLink? : string;
    public isExternalLink?: boolean
    public subItemLayout?: MenuLayoutType;
    public subItems?: MenuItem[];
    public inNewTab: boolean;

    constructor(options: any = {}, translationService: TranslationService = null) {
        this.id = options.id;
        this.cmsName = options.name;
        if (translationService) {
            this.name = translationService.translate('categories', this.cmsName, options.name);
        } else {
            this.name = options.name;
        }
        this.description = options.description;
        this.imageUrl = options.imageUrl;
        this.imageKey = options.imageKey;
        this.bannerImageUrl = options.bannerImageUrl;
        this.isBranded = options.isBranded;
        this.seqNo = options.seqNo;
        this.bannerImageKey = options.bannerImageKey;
        this.translationKey = options.translationKey;
        this.brands = (options.brands && options.brands.map(value => new BrandModel(value))) || [];
        if (options.hasSubMenu) this.hasSubMenu = options.hasSubMenu;
        if (options.showInTopMenu) this.showInTopMenu = options.showInTopMenu;
        if (options.menus) this.menus = options.menus;

        this.navigationLink = options.navigationLink;
        this.isExternalLink = options.isExternalLink;
        this.subItemLayout = options.subItemLayout;
    }
}
