export class Language {
    public id: number;
    public langName: string;
    public cultureName: string;
    public isRTL: boolean;
    public primaryLanguageFK: number;
    public translationName: string;
    public default: boolean;
    public active: boolean;
    public cmsActive: boolean;
    public language?: string;

    constructor(obj: any) {
        if (obj !== null || obj  !== undefined) {
            this.id = obj.id;
            this.langName = obj.langName || obj.language;
            this.cultureName = obj.cultureName;
            this.isRTL = obj.isRTL;
            this.primaryLanguageFK = obj.primaryLanguageFK;
            this.translationName = obj.translationName;
            this.default = obj.default;
            this.active = obj.active;
            this.cmsActive = obj.cmsActive;
            this.language = obj.language;
        }
    }
}
