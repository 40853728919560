import { Injectable } from '@angular/core';
import { SettingsService } from '@jeunesse/angular';

/**
 * This class is for the url inspection and transformation.
 * It is the same code in menuservice except it does not assume all shop links to be external.
 * We may want to import this in menuservice to eliminate code duplication.
 */
@Injectable({
    providedIn: 'root'
})

export class URLService {

    private preProdEnvs: string[] = ['dev', 'build', 'qa', 'stage'];

    constructor(private readonly settings: SettingsService) {}

    /**
     * use this on jeunnes URLS to transform your link and keep you in the environment your in.
     * */
    public toEnvLink(url: string): string {
        let localEnv = this.getEnv();
        let envs: string[] = this.preProdEnvs.filter(env => env === localEnv);
        if (envs.length == 0) {
            // prod goes here
            return url;
        }
        let env = envs[0];
        if (env === 'build') {
            if (url.toLowerCase().indexOf('dev.com') > -1 || url.toLowerCase().indexOf('build.com') > -1) {
                url = url.replace('dev.com', '.com');
                url = url.replace('build.com', '.com');
            }
        }
        if (url.toLowerCase().indexOf(env + '.com') === -1) {
            if (env === 'build' && (url.toLowerCase().indexOf('joffice2.') > -1 || url.toLowerCase().indexOf('shop.') > -1)) {
                let port: number = 4000;
                if (url.toLowerCase().indexOf('shop.') > -1) {
                    port = 4001;
                }
                url = url.replace('https://', 'http://');
                //console.warn('url=' + url + ', translated=' + url.replace('.com', env + '.com:' + port));
                return url.replace('.com', env + '.com:' + port);
            }
            else {
                //console.warn('url=' + url + ', translated=' + url.replace('.com', env + '.com'));
                return url.replace('.com', env + '.com');
            }
        }
        else {
            return url;
        }
    }

    /**
     * will return current environment
     * */
    public getEnv(): string {
        if (location.hostname.toLowerCase().indexOf('dev.com') > -1) {
            return 'dev';
        }
        if (location.hostname.toLowerCase().indexOf('build.com') > -1) {
            return 'build';
        }
        if (location.hostname.toLowerCase().indexOf('qa.com') > -1) {
            return 'qa';
        }
        if (location.hostname.toLowerCase().indexOf('stage.com') > -1) {
            return 'stage';
        }
        return 'prod';
    }



    /**
    * Checks if link is outside of joffice2 or shop.
    * @param url The URL to check.
    * @return true if it is an http link.
    */
    public isExternal(link: string): boolean {
        if (link === '#') {
            return false;
        }
        let linkParts: string[] = link.split('?');
        let url: string = linkParts[0];
        /*
        look for local urls
        */
        if (url.indexOf('joffice2.jeunesseglobal') != -1 && this.settings.siteName == 'JOffice2') {
            return false;
        }
        if (url.indexOf('shop.jeunesseglobal') != -1 && this.settings.siteName == 'Shop') {
            return false;
        }
        if (url.indexOf('.') != -1 || this.hasHttp(url)) {
            return true;
        }
        return false;
    }

    /**
    * Checks if it is an http link.
    * @param url The URL to check.
    * @return true if it is an http link.
    */
    public hasHttp(url: string): boolean {
        if (url.indexOf('http:') > -1 || url.indexOf('https:') > -1 || url.indexOf('http/') > -1 || url.indexOf('https/') > -1) {
            return true;
        }
        return false;
    }

    /**
     * Converts the URL to a route.
     * @param url The URL to convert.
     * @return Local path.
     */
    public convertLinkToRoute(url: string): string {
        const parseUrl: RegExp = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;

        const result: RegExpExecArray = parseUrl.exec(url);
        if (result == null) {
            return url;
        }
        if (result.length > 4) {
            return `/${result[5]}`;
        }
        else {
            return url;
        }
    }
    /**
    * Converts the URL to a jback link.
    * @param url The URL to convert.
    * @return new jback url.
    */
    public toClassicUrl(url: string): string {
        return this.settings.classicSiteUrl + url;
    }

    /**
    * Converts the URL to a jback link as needed.
    * @param url The URL to convert.
    * @return new jback url.
    */
    public toExternalUrl(url: string): string {
        if (!this.hasHttp(url)) {
            let linkParts: string[] = url.split('?');
            let link: string = linkParts[0];
            if (link.indexOf('.') != -1) {
                return this.toClassicUrl(url);
            }
            else {
                return this.settings.jOffice + url;
            }
        }
        else {
            return url;
        }
    }

    /**
    * Checks if link is local.
    * @param url The URL to check.
    * @return true if link is local.
    */
    public isLocal(url: string): boolean {
        let here = location.pathname;
        if (url.length < 1 || here.length < 2) { return false; }
        if (url.indexOf(here) > -1) {
            return true;
        }
        return false;
    }

}
