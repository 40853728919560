export class UserSimpleModel {
    public mainPK: number;
    public firstName: string;
    public lastName: string;
    public siteUrl: string;
    public phone: string;
    public email: string;

    constructor(options: any = {}) {
        this.mainPK = options.mainPK;
        this.firstName = options.firstName;
        this.lastName = options.lastName;
        this.siteUrl = options.siteUrl;
        this.phone = options.phone;
        this.email = options.email;
    }
}