import { PAOrder } from './paorder.model';

export class PrepaidSimpleModel {
    public id: number;
    public mainFK: number;
    public orderNumber: number;
    public prepaidStatus: string;
    public startDate: Date;
    public endDate: Date;
    public nextProcessDate: Date;
    public createdDate: Date;
    public monthsCount: number;
    public totalBV: number;
    public monthlyBV: number;
    public subPA: PAOrder;

    constructor(options: any = {}) {
        this.orderNumber = options.mainOrdersFK;
        this.id = options.id;
        this.mainFK = options.mainFK;
        this.prepaidStatus = options.prepaidStatus;
        this.startDate = options.startDate;
        this.endDate = options.endDate;
        this.nextProcessDate = options.nextProcessDate;
        this.createdDate = options.createdDate;
        this.monthsCount = options.noOfPrepaidMonths;
        this.totalBV = options.totalBV;
        this.monthlyBV = options.monthBv;
        this.subPA = (options.subPA && options.subPA.map(value => new PAOrder(value))) || [];
    }
}