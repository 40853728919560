import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paypal-payment-method',
  templateUrl: './paypal-payment-method.component.html',
  styleUrls: ['./paypal-payment-method.component.scss']
})
export class PaypalPaymentMethodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
