export class BonusTokenOrderLimit {
    public allowedPercent: number;
    public allowedAmount: number;
    public hasLimitReached: boolean;
    public bonusCreditAmount: number;
}

export class WalletOrderLimit {
    public allowedPercent: number;
    public hasLimitReached: boolean;
    public walletAmount: number;
}