import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'jn-snack-bar-warn',
  templateUrl: './snack-bar-warn.component.html',
  styleUrls: ['./snack-bar-warn.component.scss']
})
export class SnackBarWarnComponent implements OnInit {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public readonly data: string,
                private readonly matSnackBarService: MatSnackBar) { }

    public ngOnInit(): void {}

    public close(): void {
        this.matSnackBarService.dismiss();
    }

}
