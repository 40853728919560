import { Component, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../../../authentication";
import { ReportParams } from "../interfaces/report-params.interface";
import { ReportsService } from "../reports-service";

@Component({
  selector: 'jn-report-download',
  templateUrl: './report-download.component.html',
  styleUrls: ['./report-download.component.scss']
})
export class ReportDownloadComponent  implements OnDestroy {
  @Input() public reportParams: ReportParams;
  @Input() public buttonText: string;
  @Input() public message: string;
  @Input() public fileName: string;
  @Input() public fileExt: string = 'pdf';
  submitting: boolean;
  private subscription: Subscription = new Subscription();
  constructor(private readonly reportsService: ReportsService,
    private readonly authService: AuthService) {}

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  public printForm(): void {
    this.submitting = true;
    this.subscription.add(this.reportsService.getDistributorReport(this.reportParams)
      .subscribe((data) => {
        //new Blob([data], { type: 'application/pdf' });
        const downloadLink = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadLink;
        link.download = `${this.fileName}.${this.fileExt}`;
        link.click();
        this.submitting = false;
      }, error => { this.submitting = false; }));
  }
}
