export class DocumentsVideoModel {
    public title: string;
    public description: string;
    public documentLink: string;
    public category: string;
    public fileName?: string;

    constructor(options: any = {}) {
        this.title = options.title;
        this.description = options.description;
        this.documentLink = options.documentLink;
        this.category = options.category;
        this.fileName = options.fileName;
    }
}
