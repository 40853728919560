import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MerchantParamBase } from '../../models/merchant-param-model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'merchant-param',
    templateUrl: './merchant-dynamic-form-param.component.html'
})
export class MerchantDynamicFormParamComponent {
    constructor(public sanitizer: DomSanitizer) {
    }
    @Input() param: MerchantParamBase<string>;
    @Input() form: FormGroup;
    public value: boolean;
    get isValid() { return this.form.controls[this.param.key].valid; }

    private getSafeUrl(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}