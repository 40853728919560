import { PaymentMethodViewModel } from "./public-api";

export class PaymentMethodsViewModel {
    public message: string;
    public isSaveCard: boolean;
    public methods: PaymentMethodViewModel[];
    constructor(options: any= {}) {
        this.message = options.message;
        this.isSaveCard = options.isSaveCard;
        this.methods = options.methods;
    }
}