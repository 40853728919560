// tslint:disable: typedef
import { createAction, props, union } from '@ngrx/store';
import { EventMemberModel } from '../..';
import { Update } from '@ngrx/entity';

export const addPostTickets = createAction(
    '[Agenda] Add tickets',
    props<{agendasPosts: EventMemberModel[]}>()
);
export const addPostTicket = createAction(
    '[Agenda] Add ticket',
    props<{ agendaPost: EventMemberModel }>()
);
export const updatePostTicket = createAction(
    '[Agenda] Update ticket',
    props<{ update: Update<EventMemberModel> }>()
);
export const updatePostTickets = createAction(
    '[Agenda] Update tickets',
    props<{ updates: Update<EventMemberModel>[] }>()
);
export const clearTickets = createAction(
    '[Agenda] Clear tickets'
);
export const deleteTicket = createAction(
    '[Agenda] Delete ticket',
     props<{ id: string }>()
);
export const deleteTickets = createAction(
    '[Agenda] Delete tickets',
     props<{ ids: string[]}>()
);

const actions = union({
    addPostTickets,
    addPostTicket,
    updatePostTicket,
    clearTickets
});

export type EventAgendasPostActions = typeof actions;