import { Injectable } from '@angular/core';
import { AuthHttpService, UrlHelperService } from '@jeunesse/angular';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AddressLookup } from '../models/address-lookup.interface';
import { AddressValidationQueryModel } from '../models/address-query.model';
import { AddressValidationViewModel } from '../models/adress-validation-view.model';
import { DataSource } from '../models/data-source.interface';
import { FormModel } from '../models/form.model';
import { LookupAddressViewModel } from '../models/lookup-address-view.model';
import { JPAddressLookupInterface } from '../models/jp-address-lookup.interface';
import { JPAddressLookupViewModel } from '../models/jp-address-lookup-view.interface';

/**
 * Dynamic address service for making api calls.
 */
@Injectable({
    providedIn: 'root',
})
export class DynamicAddressApiService {
    private _formQuery$ = new Map<string, Observable<FormModel>>();
    private _dataSourceQuery$ = new Map<string, Observable<DataSource[]>>();
    constructor(private readonly authHttpService: AuthHttpService, private readonly urlHelperService: UrlHelperService) {}

    /**
     * Api call to get the form data.
     */
    public getAddressFormForCountry(model: any): Observable<FormModel> {
        const query = JSON.stringify(model);

        if(!this._formQuery$.get(query)) {
            this._formQuery$.set(
                query,
                this.authHttpService.get<FormModel>(this.urlHelperService.toProfileApi(`/v1/form`), { params: model })
                .pipe(shareReplay())
            )
        }

        return this._formQuery$.get(query)
    }

    /**
     * Api call to validate the provided input.
     */
    public validateFormInput(countryCode: string, model: any): Observable<any> {
        return this.authHttpService.post<any>(this.urlHelperService.toProfileApi(`/v1/form/validate/${countryCode}`), model);
    }

    /**
     * Api call to get the list of selection data.
     */
    public getDataSourceValues(model: any): Observable<DataSource[]> {
        const query: string = JSON.stringify(model);

        if(!this._dataSourceQuery$.get(query)) {
            this._dataSourceQuery$.set(
                query,
                this.authHttpService.post<DataSource[]>(this.urlHelperService.toProfileApi(`/v1/form/data-source`), model)
                .pipe(shareReplay())
            )
        }
        return this._dataSourceQuery$.get(query)
    }

    /**
     * Api call to get pre-filled input data based on provided input value
     */
    public getInputValues(model: any): Observable<any> {
        return this.authHttpService.post<any>(this.urlHelperService.toProfileApi(`/v1/form/input-value`), model);
    }

    /**
     * Used to lookup possible address matches for the users location.
     * @param model Address lookup interface
     */
    public lookupAddress(model: AddressLookup): Observable<LookupAddressViewModel[]> {
        const params = {
            addressString: model.addressString,
            country: model.country,
            latitude: model.latitude,
            longitude: model.longitude,
        };
        return this.authHttpService.get<LookupAddressViewModel[]>(this.urlHelperService.toProfileApi(`/v1/address/auto-complete`), {
            params: params,
        });
    }

    /**
     * Used to return a possible match for an address based on the street address.
     * @param address1 Street Address
     */
    public validateAddress(placeid: string): Observable<LookupAddressViewModel> {
        return this.authHttpService
            .post<LookupAddressViewModel>(this.urlHelperService.toProfileApi(`/v1/address/validate?placeid=${placeid}`))
            .pipe(
                map((resp: LookupAddressViewModel) => {
                    return new LookupAddressViewModel(resp);
                })
            );
    }

    /**
     * Validate address by dynamic library
     */
    public addressValidate(addressToValidate: AddressValidationQueryModel): Observable<AddressValidationViewModel> {
        const params: any = {
            description: addressToValidate.description,
            address1: addressToValidate.address1,
            city: addressToValidate.city,
            state: addressToValidate.state,
            zipCode: addressToValidate.zipCode,
            country: addressToValidate.country,
            placeId: addressToValidate.placeId,
            isAutoComplete: addressToValidate.isAutoComplete,
            selectedShippingMethod: addressToValidate.selectedShippingMethod,
        };
        return this.authHttpService.get<AddressValidationViewModel>(this.urlHelperService.toProfileApi(`/v1/address/validate`), {
            params: params,
        });
    }

    /**
     * Used to pull a list of address suggestions for JP Addresses based on postal code.
     */
    public lookupJPAddressByPostalCode(jpAddressLookup: JPAddressLookupInterface): Observable<JPAddressLookupViewModel[]> {
        if (!jpAddressLookup || !jpAddressLookup.postalCode) {
            return of([]);
        }
        const params: any = { postalCode: jpAddressLookup.postalCode };
        return this.authHttpService.get<JPAddressLookupViewModel[]>(this.urlHelperService.toProfileApi('/v1/localization/jp-address-lookup'), { params: params }).pipe();
    }
}
