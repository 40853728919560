export class ValidatorModel {
    public name: string;
    public validator: any;
    public message: string;
}
export class FieldStyleModel {
    public mobile: {[key: string]: string};
    public desktop: {[key: string]: string};
    public order: number;
    constructor(obj: any = {}) {
        this.mobile = obj.mobile;
        this.desktop = obj.desktop;
    }
}
export class FieldConfigModel {
    public cmsTranslation?: string;
    public name?: string;
    public inputType?: string;
    public options?: string[];
    public collections?: any;
    public type?: string;
    public value?: any;
    public required?: any;
    public style?: FieldStyleModel;
    public index?: number;

    constructor(obj: any = {}) {
        this.cmsTranslation = obj.cmsTranslation;
        this.name = obj.name;
        this.inputType = obj.inputType;
        this.options = obj.options;
        this.type = obj.type;
        this.value = obj.value;
        this.required = obj.required;
        this.style = new FieldStyleModel(obj.style);
    }
}
