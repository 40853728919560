import { NgModule } from '@angular/core';
import { DialogVideoComponent } from './dialog-video.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsModule } from '../../cms/src/cms.module';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        CmsModule
    ],
    exports: [
        DialogVideoComponent
    ],
    declarations: [
        DialogVideoComponent
    ],
    entryComponents: [DialogVideoComponent],
    providers: [
    ]
})
export class DialogVideoModule {
}
