import { Component, OnInit } from '@angular/core';
import { TranslationService, WebStorageService } from '@jeunesse/angular';
import { SubSink } from 'subsink';

import { OrderService } from '../../../../checkout/services/order.service';
import { PaymentResource, PaymentStateService } from '../../../../payment/public-api';
import { ManualPaymentService } from '../../../../payment/services/manual-payment.service';
import { OrderModel } from '../../../public-api';
import { FormatCurrency } from './../../../models/format-currency.model';

@Component({
    selector: 'app-split-payment-details',
    templateUrl: './split-payment-details.component.html',
    styleUrls: ['./split-payment-details.component.scss'],
})
export class SplitPaymentDetailsComponent implements OnInit {
    public title: string = 'Split Payment Details';
    public theTotal: any = '$' + 0;
    public paymentAmounts: Array<PaymentResource>;
    public remainingBalance: number;
    public orderModel: OrderModel;
    private subs = new SubSink();
    constructor(
        private paymentStateService: PaymentStateService,
        private webStorageService: WebStorageService,
        private readonly manualPaymentService: ManualPaymentService,
        private readonly translationService: TranslationService,
        private orderService: OrderService
    ) {}

    public ngOnInit(): any {
        this.subs.sink = this.orderService.getOrderModel().subscribe((orderModel: OrderModel) => {
            this.orderModel = orderModel;
            this.remainingBalance = this.orderModel.splitRemainingBalance || this.orderModel.total;
            this.orderService
                .formatCurrency(this.remainingBalance, this.orderModel.countryCode)
                .subscribe((result: FormatCurrency) => (this.orderModel.formattedRemainingBalance = result.formattedValue));
        });

        this.title = this.translationService.translate('checkout', 'splitPaymentTitle', 'Split Payment Details');

        this.orderService.splitOrderTotalState.subscribe((res) => {
            this.remainingBalance = res;
            this.orderService
                .formatCurrency(this.remainingBalance, this.orderModel.countryCode)
                .subscribe((result: FormatCurrency) => (this.orderModel.formattedRemainingBalance = result.formattedValue));
        });

        this.paymentStateService.getPaymentResources().subscribe((result) => {
            if (result.length > 0) {
                this.addPaymentName(result);
            }
        });
    }

    public addPaymentName(result): void {
        result.forEach((res) => {
            switch (res.paymentMethodTypePK) {
                case 2:
                case 59:
                case 92:
                case 98:
                    res.name = 'Credit Card';
                    break;
                case 3:
                    res.name = 'Wallet';
                    break;
                case 32:
                    res.name = 'Paypal';
                    break;
                case 29:
                    res.name = 'Bonus Credit Token';
                    break;
                case 8:
                    res.name = 'Signup Token';
                    break;
                default:
                    if (this.manualPaymentService.isManual(+res.paymentMethodTypePK)) {
                        res.name = this.manualPaymentService.paymentTypeToManualType(+res.paymentMethodTypePK).paymentMethodName;
                    }
                    break;
            }
        });
        this.paymentAmounts = result;
        this.paymentAmounts.filter((paymentAmount) => {
            this.orderService.formatCurrency(paymentAmount.amountProcessed, paymentAmount.currencyCode).subscribe((result: FormatCurrency) => {
                paymentAmount.formattedAmountProcessed = result.formattedValue;
            });
        });
        if (result && result.length > 0) {
            this.remainingBalance = result[result.length - 1].balance;
            this.orderModel.splitRemainingBalance = this.remainingBalance;
            this.orderService
                .formatCurrency(this.orderModel.splitRemainingBalance, this.orderModel.countryCode)
                .subscribe((result: FormatCurrency) => (this.orderModel.formattedRemainingBalance = result.formattedValue));
        }
        result.forEach((res) => {
            if (res.paymentMethodTypePK === 29 && !res.isAppliedAsPaymentMethod) {
                result.splice(res, 1);
            }
        });
        if (result.length > 0) {
            this.orderModel.lockPage = true;
        }
        this.orderService.setOrderModel(this.orderModel);
        this.webStorageService.setSession('splitPayments', JSON.stringify(this.paymentAmounts));
    }
}
