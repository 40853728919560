import { CountryLanguageModel } from './country-language';

export class CountryCultureModel {
    public code2: string;
    public countryGroupsFK: number;
    public currencyCode: string;
    public hasImportCart: boolean;
    public isAffiliateOn: boolean;
    public isVolumeBasedRSB: boolean;
    public languages: CountryLanguageModel[];
    public name: string;
    public offerAS: boolean;
    public offerJKidsDonation: boolean;
    public offerLoyaltyProgram: boolean;
    public offerPreferredCust: boolean;
    public offerShopMobileApp: boolean;
    public zone: number;
    constructor(options: any = {}) {
        this.code2 = options.code2;
        this.countryGroupsFK = options.countryGroupsFK;
        this.currencyCode = options.currencyCode;
        this.hasImportCart = options.hasImportCart;
        this.isAffiliateOn = options.isAffiliateOn;
        this.isVolumeBasedRSB = options.isVolumeBasedRSB;
        this.languages = options.languages;
        this.name = options.name;
        this.offerAS = options.offerAS;
        this.offerJKidsDonation = options.offerJKidsDonation;
        this.offerLoyaltyProgram = options.offerLoyaltyProgram;
        this.offerPreferredCust = options.offerPreferredCust;
        this.offerShopMobileApp = options.offerShopMobileApp;
    }
}
