/**
 * Generic Key/Value Model
 */
export class KeyValueModel {
    public key: any;
    public value: any;

    constructor(options: any = {}) {
        this.key = options.key;
        this.value = options.value;
    }
}