export class DialogVideoModel {
    public title: string;
    public description: string;
    public documentLink: string;
    public category: string;

    public okButtonText: string;
    public cancelButtonText: string;
    public videoStatus: string;
    public fileName: string;
    public continue: string;
    public learnMore: string;

    constructor(options: any = {}) {
        this.title = options.title;
        this.description = options.description;
        this.documentLink = options.documentLink;
        this.category = options.category;

        this.okButtonText = options.okButtonText;
        this.cancelButtonText = options.cancelButtonText;
        this.videoStatus = options.videoStatus;
        this.fileName = options.fileName;
        this.continue = options.continue;
        this.learnMore = options.learnMore;
    }
}
