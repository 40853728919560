import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ListSelectorComponent } from './list-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatRippleModule,
        MatFormFieldModule,
        MatListModule,
        FlexLayoutModule
     ],
     declarations: [ListSelectorComponent],
     exports: [ListSelectorComponent]
})
export class ListSelectorModule {}
