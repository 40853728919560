import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';

import { CategoryModel } from '../../../products/models/category/category.model';
import { MenuService } from '../../services/menu.service';
import { SubSink } from 'subsink';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'jn-product-filter',
    templateUrl: './product-filter.component.html',
    styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent implements OnInit, OnDestroy {
    public categoryItems: CategoryModel[] = [];
    public brands: any = [];
    public arrayOfMenuId: number[] = [];
    public initialFilter: string [] = [];
    private subs = new SubSink();

    constructor(
        private readonly menuService: MenuService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly dialogRef: MatDialogRef<ProductFilterComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    public ngOnInit(): void {
        this.subs.sink = this.menuService.getCategoryMenu().subscribe(cats => {
            this.categoryItems = cats.sort((a, b) => {
                return b.seqNo + a.seqNo;
            });
            this.getBrandMenu();
            this.categoryItems = this.categoryItems.filter((categoryItem) => {
                return !(categoryItem.cmsName === 'Wellness') && !(categoryItem.cmsName === 'Beauty');
            });
            this.autoMenuOpener();
        });
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.initialFilter = (params.has('m') ? params.get('m') : '').split(',');
            this.autoMenuOpener();
        });
    }
    /**
     * On execution we check if category menu dropdown have to be open
     *
     * @author Vadim Sidorov
     * @date 2020-08-21
     * @memberof ProductFilterComponent
     */
    public autoMenuOpener(): void {
        if (this.initialFilter.some((el) => !isNaN(parseInt(el, 10)))) {
            this.brands.forEach(brand => {
                if (this.initialFilter.includes(brand.id.toString())) {
                    this.brands.isOpen = true;
                }
            });
            this.categoryItems.forEach((item) => {
                item.menus.forEach(menu => {
                    if (this.initialFilter.includes(menu.id.toString())) {
                        item.isOpen = true;
                    }
                });
            });
        }
    }

    public onCategoryClick(categoryItem: CategoryModel): void {
        if (categoryItem.hasSubMenu)
            return;

        this.initialFilter = [];
        this.router.navigate([], {
                relativeTo: this.data.ActivatedRoute || this.activatedRoute,
                queryParams: {
                    m: null,
                    c: categoryItem.id,
                    searchTerm: null
                },
                queryParamsHandling: 'merge'
            });
    }

    public onCheckBoxClick(event: MatCheckbox, subItem: any, index: any): void {
        switch (true) {
            case event.checked && subItem.id !== 53:
                if (this.initialFilter.includes('53')) {
                    this.initialFilter.splice(this.initialFilter.indexOf('53'), 1);
                }
                this.initialFilter.push(subItem.id.toString());
                this.router.navigate(['products'], {
                    relativeTo: this.data.ActivatedRoute || this.activatedRoute,
                    queryParams: {
                        m: this.initialFilter.toString(),
                        c: null,
                        searchTerm: null
                    },
                    queryParamsHandling: 'merge'
                  });
                break;
            case !event.checked && subItem.id !== 53:
                this.initialFilter.splice(this.initialFilter.indexOf(subItem.id.toString()), 1);
                this.router.navigate([], {
                    relativeTo: this.data.ActivatedRoute || this.activatedRoute,
                    queryParams: {
                        m: this.initialFilter.toString(),
                        c: null,
                        searchTerm: null
                    },
                    queryParamsHandling: 'merge'
                  });
                break;
            case event.checked && subItem.id === 53:
                this.initialFilter = [];
                this.initialFilter.push(subItem.id.toString());
                this.router.navigate(['create-a-pack'], {
                    relativeTo: this.data.ActivatedRoute || this.activatedRoute,
                    queryParams: {m: '53'},
                    replaceUrl: true
                  });
                break;
            case !event.checked && subItem.id === 53:
                this.initialFilter.splice(this.initialFilter.indexOf(subItem.id.toString()), 1);
                this.router.navigate(['products'], {
                    relativeTo: this.data.ActivatedRoute || this.activatedRoute
                  });
                break;
            default:
                break;
        }
    }
    public getBrandMenu(): void {
        this.brands = [];
        this.categoryItems.forEach((singleMenuItem) => {
            if (singleMenuItem.cmsName === 'Beauty' || singleMenuItem.cmsName === 'Wellness') {
                this.brands.push(...singleMenuItem.menus);
            }
        });
    }
    public clearFilter(): void {
        this.initialFilter = this.initialFilter.filter(menuId => menuId === '53');
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                m: this.initialFilter.toString(),
                searchTerm: null
            },
            queryParamsHandling: 'merge'
          });
    }
    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
    public closeFilter(): void {
        this.dialogRef.close();
    }
}

