import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { SubSink } from 'subsink';
import { MemberService, Wallet } from '../../../member/public-api';
import { OrderService } from '../../../checkout/services/order.service';
import { TranslationService, CmsSessionService, LocaleService } from '@jeunesse/angular';
import { PaymentStateService } from '../../services/payment-state.service';


import * as _ from 'lodash';
import { OrderModel, WalletOrderLimit } from '../../../checkout/public-api';

@Component({
    selector: 'app-wallet-split',
    templateUrl: './wallet-split.component.html',
    styleUrls: ['./wallet-split.component.scss']
})
export class WalletSplitComponent implements OnInit {
    public title: string = 'WALLET STATUS';
    public remain: string = 'Remaining Order Total';
    public theTotal: any = "$" + 0;
    public walletInfo: Wallet;
    public remainingBalance: any;
    public overBalance: boolean;
    public isSplitPayment: boolean;
    public orderModel: any;
    public allowDiscount: boolean = true;
    public limitModel: WalletOrderLimit;
    public walletForm: FormGroup;
    public showNote: boolean = false;
    public limitReached: boolean = false;
    public translations: any = {
        limitPrompt: '',
        limitReached: ''
    };
    private subs = new SubSink();


    @Output() walletAmount: EventEmitter<any> = new EventEmitter();

    constructor(private memberService: MemberService,
        private paymentStateService: PaymentStateService,
        private orderService: OrderService,
        private readonly translationService: TranslationService,
        private readonly cmsSessionService: CmsSessionService,
        private readonly localeService: LocaleService,
        private formBuilder: FormBuilder,
        private currencyPipe: CurrencyPipe) {
    }

    public breakDown: any[] = [];

    ngOnInit(): void {
        this.subs.sink = this.orderService.getOrderModel().subscribe((orderModel: OrderModel) => {
            this.orderModel = orderModel;
        })
        this.isSplitPayment = this.orderModel.splitPayment;
        this.walletForm = this.formBuilder.group({
            walletInput: [this.remainingBalance ? this.remainingBalance.toFixed(2) : this.orderModel.usdTotal]
        });
        this.memberService.walletInfoState.subscribe((result: Wallet) => {
            this.walletInfo = result;
            if (this.walletInfo) {
                this.subs.sink = this.orderService.getWalletOrderLimit(this.orderModel.mainOrdersFk).subscribe((limitModel: WalletOrderLimit) => {
                    this.limitModel = limitModel;
                    if (this.limitModel.allowedPercent > 0)
                        this.showNote = true;
                    this.limitReached = this.limitModel.hasLimitReached;
                    this.getTranslations();

                    this.walletForm.get('walletInput').valueChanges.subscribe((res) => {
                        if (res > this.walletInfo.balance || 0 || (this.limitModel && this.limitModel.allowedPercent > 0 && res > this.limitModel.walletAmount)) {
                            this.overBalance = true;
                            this.walletAmount.emit({ amount: res, valid: false });
                        } else {
                            this.overBalance = false;
                            this.walletAmount.emit({ amount: res, valid: true });
                        }
                    });
                    let amount: number = this.orderModel.usdTotal - (this.allowDiscount ? this.orderModel.walletDiscount: 0);
                    if (this.remainingBalance) {
                        amount = this.remainingBalance;
                    }
                    if (this.limitModel.allowedPercent > 0) {
                        amount = this.limitModel.walletAmount;
                    }
                    if (this.walletInfo.balance < amount) {
                        amount = this.walletInfo.balance
                    }
                    this.walletForm.get('walletInput').patchValue(amount.toFixed(2));

                    this.breakDown = [];
                    this.breakDown.push(
                        {
                            'showing': '<span>Pending Paycard Load</span>',
                            'price': ((this.walletInfo && this.walletInfo.pendingPaycard) ? this.currencyPipe.transform(this.walletInfo.pendingPaycard, 'USD') : 0),
                            'cmsTranslation': 'pendingPaycardLoad',
                            'state': 'checkout'
                        },
                        {
                            'showing': 'Pending EFT',
                            'price': ((this.walletInfo && this.walletInfo.pendingEFT) ? this.currencyPipe.transform(this.walletInfo.pendingEFT, 'USD') : 0),
                            'cmsTranslation': 'pendingEft',
                            'state': 'checkout'
                        },
                        {
                            'showing': 'Pending funds request',
                            'price': ((this.walletInfo && this.walletInfo.pendingRequest) ? this.currencyPipe.transform(this.walletInfo.pendingRequest, 'USD') : 0),
                            'cmsTranslation': 'pendingRequest',
                            'state': 'checkout'
                        },
                        {
                            'showing': 'Funds Available',
                            'price': ((this.walletInfo && this.walletInfo.balance) ? this.currencyPipe.transform(this.walletInfo.balance, 'USD') : 0),
                            'cmsTranslation': 'fundsAvailable',
                            'state': 'checkout'
                        },
                        {
                            'showing': 'Remaining Order Total',
                            'price': (this.remainingBalance ? this.currencyPipe.transform(this.remainingBalance, 'USD') : this.currencyPipe.transform(this.orderModel.usdTotal - this.orderModel.walletDiscount, 'USD')),
                            'cmsTranslation': 'remainTotal',
                            'state': 'checkout'
                        },

                    )
                    if (this.orderModel.walletDiscount > 0)
                        this.breakDown.push(
                            {
                                'showing': 'Wallet Payment Discount',
                                'price': this.currencyPipe.transform(this.orderModel.walletDiscount, 'USD'),
                                'cmsTranslation': 'walletPaymentDiscount',
                                'state': 'checkout'
                            }
                        )
                });
            }
        });
        this.paymentStateService.getPaymentResources().subscribe((payments) => {
            if (payments && payments.length > 0) {
                this.allowDiscount = false;
                this.remainingBalance = (payments[payments.length - 1].balance) / this.orderModel.currencyRate;
            }
        });
    }
    public ngOnDestory(): void {
        this.subs.unsubscribe();
    }
    private getTranslations(): void {
        let cultureName: string = this.cmsSessionService.getCulture();
        this.localeService.isCultureSupported(cultureName, this.cmsSessionService.getCmsCountry(cultureName)).subscribe((culture) => {
            this.translationService.setTranslationCulture(culture);
            this.setTranslations();

            this.translationService.getTranslationCacheObservable().subscribe(() => {
                this.setTranslations();
            });
        });
    }

    private setTranslations(): void {
        let limitReachedFormat: string = this.translationService.translate('payment', 'WalletLimitReached',
            'A limit of {0}% of payments in Wallet has been reached.')
        this.translations.limitReached = this.translationService.stringFormat(limitReachedFormat, this.limitModel.allowedPercent);
        let limitPromptFormat: string = this.translationService.translate('checkout', 'WalletLimitTotal',
            'Attention! For Bonus Credit, Wallet payment, and Signup Token payments there is a {0}% limit of the order total.')
        this.translations.limitPrompt = this.translationService.stringFormat(limitPromptFormat, this.limitModel.allowedPercent);
    }
}
