export class EventsTranslationCmsModel {
    public eventsTranslationID: number;
    public eventID: number;
    public languageID: number;
    public name: string;
    public description: string;

    constructor(obj: any) {
        this.eventsTranslationID = obj.Events_TranslationID;
        this.eventID = obj.eventID;
        this.languageID = obj.languageID;
        this.name = obj.name;
        this.description = obj.description;
    }
}
export class AddressModel {
    public line1: string;
    public line2: string;
    public city: string;
    public state: string;
    public postalCode: string;
    public latitude: string;
    public longitude: string;

    constructor(obj: any) {
        this.line1 = obj.line1;
        this.line2 = obj.line2;
        this.city = obj.city;
        this.state = obj.state;
        this.postalCode = obj.postalCode;
        this.latitude = obj.latitude;
        this.longitude = obj.longitude;
    }
}
export class EventDisplayModel {
    public eventID: number;
    public showAgenda: boolean;
    public showSpeakers: boolean;
    public showHotels: boolean;
    public showPayment: boolean;
    public showEarlyRegistration: boolean;

    constructor(obj: any) {
        this.eventID = obj.EventID;
        this.showAgenda = obj.ShowAgenda;
        this.showSpeakers = obj.ShowSpeakers;
        this.showHotels = obj.ShowHotels;
        this.showPayment = obj.ShowPayment;
        this.showEarlyRegistration = obj.ShowEarlyRegistration;
    }
}
export class EventCalendarModel {
    public translation: EventsTranslationCmsModel;
    public id: number;
    public allowRegistration: boolean;
    public name: string;
    public summary: string;
    public locationName: string;
    public address: AddressModel;
    public description: string;
    public url: string;
    public start: Date;
    public end: Date;
    public timeZone: number;
    public timeZoneOffset: number;
    public timeZoneDescription: string;
    public countryID: number;
    public code2: string;
    public categoryId: number;
    public isArchived: boolean;
    public adminPin: string;
    public taxable: boolean;
    public styleId: number;
    public style: string;
    public bannerImage: string;
    public backgroundImage: string;
    public display: EventDisplayModel;
    public promoReportsID?: number;
    public promoName: string;
    public promoIsSapphireOnly?: boolean;
    constructor(obj: any) {
        this.translation = new EventsTranslationCmsModel(obj.translation);
        this.id = obj.id;
        this.allowRegistration = obj.allowRegistration;
        this.name = obj.name;
        this.summary = obj.summary;
        this.locationName = obj.locationName;
        this.address = new AddressModel(obj.address);
        this.description = obj.description;
        this.url = obj.url;
        this.start = obj.start;
        this.end = obj.end;
        this.timeZone = obj.timeZone;
        this.timeZoneOffset = obj.timeZoneOffset;
        this.timeZoneDescription = obj.timeZoneDescription;
        this.countryID = obj.countryID;
        this.code2 = obj.code2;
        this.categoryId = obj.categoryId;
        this.isArchived = obj.isArchived;
        this.adminPin = obj.adminPin;
        this.taxable = obj.taxable;
        this.styleId = obj.styleId;
        this.style = obj.style;
        this.bannerImage = obj.bannerImage;
        this.backgroundImage = obj.backgroundImage;
        this.display = new EventDisplayModel(obj.display);
        this.promoReportsID = obj.promoReportsID;
        this.promoName = obj.promoName;
        this.promoIsSapphireOnly = obj.promoIsSapphireOnly;
    }
}

