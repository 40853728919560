import { PrepaidSimpleModel } from './prepaid-simple.model';
/**
 * Model to display list of orders.
 */
export class PrepaidSimpleViewModel {
    public totalCount: number;
    public prepaids: PrepaidSimpleModel[];

    constructor(options: any = {}) {
        this.totalCount = options.totalCount;
        this.prepaids = (options.orders && options.orders.map(value => new PrepaidSimpleModel(value))) || [];
    };
}