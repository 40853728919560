import { IconDataModel } from '../models/icon-data.interface';

export const FONTAWESOMEICONLIST: IconDataModel[] = [
  {
     fontSet: 'fa',
     id: 'glass',
     filter: ['martini', 'drink', 'bar', 'alcohol', 'liquor']
  },
  {
     fontSet: 'fa',
     id: 'music',
     filter: ['note', 'sound']
  },
  {
     fontSet: 'fa',
     id: 'search',
     filter: ['magnify', 'zoom', 'enlarge', 'bigger']
  },
  {
     fontSet: 'fa',
     id: 'envelope-o',
     filter: ['email', 'support', 'e-mail', 'letter', 'mail', 'notification']
  },
  {
     fontSet: 'fa',
     id: 'heart',
     filter: ['love', 'like', 'favorite', 'web', 'medical']
  },
  {
     fontSet: 'fa',
     id: 'star',
     filter: ['award', 'achievement', 'night', 'rating', 'score', 'favorite']
  },
  {
     fontSet: 'fa',
     id: 'star-o',
     filter: ['award', 'achievement', 'night', 'rating', 'score', 'favorite']
  },
  {
     fontSet: 'fa',
     id: 'user',
     filter: ['person', 'man', 'head', 'profile']
  },
  {
     fontSet: 'fa',
     id: 'film',
     filter: ['movie']
  },
  {
     fontSet: 'fa',
     id: 'th-large',
     filter: ['blocks', 'squares', 'boxes', 'grid', 'editor']
  },
  {
     fontSet: 'fa',
     id: 'th',
     filter: ['blocks', 'squares', 'boxes', 'grid', 'editor']
  },
  {
     fontSet: 'fa',
     id: 'th-list',
     filter: [
        'ul',
        'ol',
        'checklist',
        'finished',
        'completed',
        'done',
        'todo',
        'editor'
     ]
  },
  {
     fontSet: 'fa',
     id: 'check',
     filter: [
        'checkmark',
        'done',
        'todo',
        'agree',
        'accept',
        'confirm',
        'tick',
        'ok'
     ]
  },
  {
     fontSet: 'fa',
     id: 'times',
     filter: ['close', 'exit', 'x', 'cross', 'remove', 'close']
  },
  {
     fontSet: 'fa',
     id: 'search-plus',
     filter: ['magnify', 'zoom', 'enlarge', 'bigger']
  },
  {
     fontSet: 'fa',
     id: 'search-minus',
     filter: ['magnify', 'minify', 'zoom', 'smaller']
  },
  {
     fontSet: 'fa',
     id: 'power-off',
     filter: ['on', 'power']
  },
  {
     fontSet: 'fa',
     id: 'signal',
     filter: ['graph', 'bars']
  },
  {
     fontSet: 'fa',
     id: 'cog',
     filter: ['settings', 'gear', 'application', 'Spinner']
  },
  {
     fontSet: 'fa',
     id: 'trash-o',
     filter: ['garbage', 'delete', 'remove', 'trash', 'hide']
  },
  {
     fontSet: 'fa',
     id: 'home',
     filter: ['main', 'house']
  },
  {
     fontSet: 'fa',
     id: 'file-o',
     filter: ['new', 'page', 'pdf', 'document', 'text', 'file']
  },
  {
     fontSet: 'fa',
     id: 'clock-o',
     filter: ['watch', 'timer', 'late', 'timestamp']
  },
  {
     fontSet: 'fa',
     id: 'road',
     filter: ['street']
  },
  {
     fontSet: 'fa',
     id: 'download',
     filter: ['import']
  },
  {
     fontSet: 'fa',
     id: 'arrow-circle-o-down',
     filter: ['download', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'arrow-circle-o-up',
     filter: ['directional']
  },
  {
     fontSet: 'fa',
     id: 'inbox'
  },
  {
     fontSet: 'fa',
     id: 'play-circle-o',
     filter: ['video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'repeat',
     filter: ['redo', 'forward', 'rotate-right', 'text']
  },
  {
     fontSet: 'fa',
     id: 'refresh',
     filter: ['reload', 'sync', 'application', 'Spinner']
  },
  {
     fontSet: 'fa',
     id: 'list-alt',
     filter: [
        'ul',
        'ol',
        'checklist',
        'finished',
        'completed',
        'done',
        'todo',
        'text'
     ]
  },
  {
     fontSet: 'fa',
     id: 'lock',
     filter: ['protect', 'admin', 'security']
  },
  {
     fontSet: 'fa',
     id: 'flag',
     filter: ['report', 'notification', 'notify']
  },
  {
     fontSet: 'fa',
     id: 'headphones',
     filter: ['sound', 'listen', 'music', 'audio']
  },
  {
     fontSet: 'fa',
     id: 'volume-off',
     filter: ['audio', 'mute', 'sound', 'music']
  },
  {
     fontSet: 'fa',
     id: 'volume-down',
     filter: ['audio', 'lower', 'quieter', 'sound', 'music']
  },
  {
     fontSet: 'fa',
     id: 'volume-up',
     filter: ['audio', 'higher', 'louder', 'sound', 'music']
  },
  {
     fontSet: 'fa',
     id: 'qrcode',
     filter: ['scan']
  },
  {
     fontSet: 'fa',
     id: 'barcode',
     filter: ['scan']
  },
  {
     fontSet: 'fa',
     id: 'tag',
     filter: ['label']
  },
  {
     fontSet: 'fa',
     id: 'tags',
     filter: ['labels']
  },
  {
     fontSet: 'fa',
     id: 'book',
     filter: ['read', 'documentation']
  },
  {
     fontSet: 'fa',
     id: 'bookmark',
     filter: ['save']
  },
  {
     fontSet: 'fa',
     id: 'print',
     filter: ['application']
  },
  {
     fontSet: 'fa',
     id: 'camera',
     filter: ['photo', 'picture', 'record']
  },
  {
     fontSet: 'fa',
     id: 'font',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'bold',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'italic',
     filter: ['italics', 'text']
  },
  {
     fontSet: 'fa',
     id: 'text-height',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'text-width',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'align-left',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'align-center',
     filter: ['middle', 'text']
  },
  {
     fontSet: 'fa',
     id: 'align-right',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'align-justify',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'list',
     filter: [
        'ul',
        'ol',
        'checklist',
        'finished',
        'completed',
        'done',
        'todo',
        'text'
     ]
  },
  {
     fontSet: 'fa',
     id: 'outdent',
     filter: ['dedent', 'text']
  },
  {
     fontSet: 'fa',
     id: 'indent',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'video-camera',
     filter: ['film', 'movie', 'record']
  },
  {
     fontSet: 'fa',
     id: 'picture-o',
     filter: ['photo', 'image']
  },
  {
     fontSet: 'fa',
     id: 'pencil',
     filter: ['write', 'edit', 'update']
  },
  {
     fontSet: 'fa',
     id: 'map-marker',
     filter: [
        'map',
        'pin',
        'location',
        'coordinates',
        'localize',
        'address',
        'travel',
        'where',
        'place'
     ]
  },
  {
     fontSet: 'fa',
     id: 'adjust',
     filter: ['contrast']
  },
  {
     fontSet: 'fa',
     id: 'tint',
     filter: ['raindrop', 'waterdrop', 'drop', 'droplet']
  },
  {
     fontSet: 'fa',
     id: 'pencil-square-o',
     filter: ['write', 'edit', 'update', 'edit']
  },
  {
     fontSet: 'fa',
     id: 'share-square-o',
     filter: ['social', 'send', 'arrow']
  },
  {
     fontSet: 'fa',
     id: 'check-square-o',
     filter: [
        'todo',
        'done',
        'agree',
        'accept',
        'confirm',
        'ok',
        'application',
        'form'
     ]
  },
  {
     fontSet: 'fa',
     id: 'arrows',
     filter: ['move', 'reorder', 'resize', 'application', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'step-backward',
     filter: [
        'rewind',
        'previous',
        'beginning',
        'start',
        'first',
        'video',
        'player'
     ]
  },
  {
     fontSet: 'fa',
     id: 'fast-backward',
     filter: [
        'rewind',
        'previous',
        'beginning',
        'start',
        'first',
        'video',
        'player'
     ]
  },
  {
     fontSet: 'fa',
     id: 'backward',
     filter: ['rewind', 'previous', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'play',
     filter: ['start', 'playing', 'music', 'sound', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'pause',
     filter: ['wait', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'stop',
     filter: ['block', 'box', 'square', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'forward',
     filter: ['forward', 'next', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'fast-forward',
     filter: ['next', 'end', 'last', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'step-forward',
     filter: ['next', 'end', 'last', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'eject',
     filter: ['video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'chevron-left',
     filter: ['bracket', 'previous', 'back', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'chevron-right',
     filter: ['bracket', 'next', 'forward', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'plus-circle',
     filter: ['add', 'new', 'create', 'expand']
  },
  {
     fontSet: 'fa',
     id: 'minus-circle',
     filter: ['delete', 'remove', 'trash', 'hide']
  },
  {
     fontSet: 'fa',
     id: 'times-circle',
     filter: ['close', 'exit', 'x']
  },
  {
     fontSet: 'fa',
     id: 'check-circle',
     filter: ['todo', 'done', 'agree', 'accept', 'confirm', 'ok']
  },
  {
     fontSet: 'fa',
     id: 'question-circle',
     filter: ['help', 'information', 'unknown', 'support']
  },
  {
     fontSet: 'fa',
     id: 'info-circle',
     filter: ['help', 'information', 'more', 'details']
  },
  {
     fontSet: 'fa',
     id: 'crosshairs',
     filter: ['picker']
  },
  {
     fontSet: 'fa',
     id: 'times-circle-o',
     filter: ['close', 'exit', 'x']
  },
  {
     fontSet: 'fa',
     id: 'check-circle-o',
     filter: ['todo', 'done', 'agree', 'accept', 'confirm', 'ok']
  },
  {
     fontSet: 'fa',
     id: 'ban',
     filter: [
        'delete',
        'remove',
        'trash',
        'hide',
        'block',
        'stop',
        'abort',
        'cancel'
     ]
  },
  {
     fontSet: 'fa',
     id: 'arrow-left',
     filter: ['previous', 'back', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'arrow-right',
     filter: ['next', 'forward', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'arrow-up',
     filter: ['directional']
  },
  {
     fontSet: 'fa',
     id: 'arrow-down',
     filter: ['download', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'share',
     filter: ['mail-forward']
  },
  {
     fontSet: 'fa',
     id: 'expand',
     filter: ['enlarge', 'bigger', 'resize', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'compress',
     filter: [
        'collapse',
        'combine',
        'contract',
        'merge',
        'smaller',
        'video',
        'player'
     ]
  },
  {
     fontSet: 'fa',
     id: 'plus',
     filter: ['add', 'new', 'create', 'expand']
  },
  {
     fontSet: 'fa',
     id: 'minus',
     filter: ['hide', 'minify', 'delete', 'remove', 'trash', 'hide', 'collapse']
  },
  {
     fontSet: 'fa',
     id: 'asterisk',
     filter: ['details']
  },
  {
     fontSet: 'fa',
     id: 'exclamation-circle',
     filter: ['warning', 'error', 'problem', 'notification', 'alert']
  },
  {
     fontSet: 'fa',
     id: 'gift',
     filter: ['present']
  },
  {
     fontSet: 'fa',
     id: 'leaf',
     filter: ['eco', 'nature', 'plant']
  },
  {
     fontSet: 'fa',
     id: 'fire',
     filter: ['flame', 'hot', 'popular']
  },
  {
     fontSet: 'fa',
     id: 'eye',
     filter: ['show', 'visible', 'views']
  },
  {
     fontSet: 'fa',
     id: 'eye-slash',
     filter: ['toggle', 'show', 'hide', 'visible', 'visiblity', 'views']
  },
  {
     fontSet: 'fa',
     id: 'exclamation-triangle',
     filter: ['warning', 'error', 'problem', 'notification', 'alert']
  },
  {
     fontSet: 'fa',
     id: 'plane',
     filter: [
        'travel',
        'trip',
        'location',
        'destination',
        'airplane',
        'fly',
        'mode',
        'application',
        'Transportation'
     ]
  },
  {
     fontSet: 'fa',
     id: 'calendar',
     filter: ['date', 'time', 'when', 'event']
  },
  {
     fontSet: 'fa',
     id: 'random',
     filter: ['sort', 'shuffle', 'application', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'comment',
     filter: [
        'speech',
        'notification',
        'note',
        'chat',
        'bubble',
        'feedback',
        'message',
        'texting',
        'sms',
        'conversation'
     ]
  },
  {
     fontSet: 'fa',
     id: 'magnet'
  },
  {
     fontSet: 'fa',
     id: 'chevron-up',
     filter: ['directional']
  },
  {
     fontSet: 'fa',
     id: 'chevron-down',
     filter: ['directional']
  },
  {
     fontSet: 'fa',
     id: 'retweet',
     filter: ['refresh', 'reload', 'share']
  },
  {
     fontSet: 'fa',
     id: 'shopping-cart',
     filter: ['checkout', 'buy', 'purchase', 'payment']
  },
  {
     fontSet: 'fa',
     id: 'folder',
     filter: ['application']
  },
  {
     fontSet: 'fa',
     id: 'folder-open',
     filter: ['application']
  },
  {
     fontSet: 'fa',
     id: 'arrows-v',
     filter: ['resize', 'application', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'arrows-h',
     filter: ['resize', 'application', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'bar-chart',
     filter: ['bar-chart-o', 'graph', 'analytics', 'application', 'Chart']
  },
  {
     fontSet: 'fa',
     id: 'twitter-square',
     filter: ['tweet', 'social network', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'facebook-square',
     filter: ['social network', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'camera-retro',
     filter: ['photo', 'picture', 'record']
  },
  {
     fontSet: 'fa',
     id: 'key',
     filter: ['unlock', 'password']
  },
  {
     fontSet: 'fa',
     id: 'cogs',
     filter: ['gears', 'settings']
  },
  {
     fontSet: 'fa',
     id: 'comments',
     filter: [
        'speech',
        'notification',
        'note',
        'chat',
        'bubble',
        'feedback',
        'message',
        'texting',
        'sms',
        'conversation'
     ]
  },
  {
     fontSet: 'fa',
     id: 'thumbs-o-up',
     filter: [
        'like',
        'approve',
        'favorite',
        'agree',
        'hand',
        'application',
        'Hand'
     ]
  },
  {
     fontSet: 'fa',
     id: 'thumbs-o-down',
     filter: ['dislike', 'disapprove', 'disagree', 'hand', 'application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'star-half',
     filter: ['award', 'achievement', 'rating', 'score']
  },
  {
     fontSet: 'fa',
     id: 'heart-o',
     filter: ['love', 'like', 'favorite', 'application', 'Medical']
  },
  {
     fontSet: 'fa',
     id: 'sign-out',
     filter: ['log out', 'logout', 'leave', 'exit', 'arrow']
  },
  {
     fontSet: 'fa',
     id: 'linkedin-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'thumb-tack',
     filter: ['marker', 'pin', 'location', 'coordinates']
  },
  {
     fontSet: 'fa',
     id: 'external-link',
     filter: ['open', 'new']
  },
  {
     fontSet: 'fa',
     id: 'sign-in',
     filter: [
        'enter',
        'join',
        'log in',
        'login',
        'sign up',
        'sign in',
        'signin',
        'signup',
        'arrow'
     ]
  },
  {
     fontSet: 'fa',
     id: 'trophy',
     filter: ['award', 'achievement', 'cup', 'winner', 'game']
  },
  {
     fontSet: 'fa',
     id: 'github-square',
     filter: ['octocat', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'upload',
     filter: ['import']
  },
  {
     fontSet: 'fa',
     id: 'lemon-o',
     filter: ['food']
  },
  {
     fontSet: 'fa',
     id: 'phone',
     filter: ['call', 'voice', 'number', 'support', 'earphone', 'telephone']
  },
  {
     fontSet: 'fa',
     id: 'square-o',
     filter: ['block', 'square', 'box', 'application', 'form']
  },
  {
     fontSet: 'fa',
     id: 'bookmark-o',
     filter: ['save']
  },
  {
     fontSet: 'fa',
     id: 'phone-square',
     filter: ['call', 'voice', 'number', 'support', 'telephone']
  },
  {
     fontSet: 'fa',
     id: 'twitter',
     filter: ['tweet', 'social network', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'facebook',
     filter: ['facebook-f', 'social network', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'github',
     filter: ['octocat', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'unlock',
     filter: ['protect', 'admin', 'password', 'lock']
  },
  {
     fontSet: 'fa',
     id: 'credit-card',
     filter: [
        'money',
        'buy',
        'debit',
        'checkout',
        'purchase',
        'payment',
        'application',
        'Payment'
     ]
  },
  {
     fontSet: 'fa',
     id: 'rss',
     filter: ['blog', 'feed']
  },
  {
     fontSet: 'fa',
     id: 'hdd-o',
     filter: ['harddrive', 'hard drive', 'storage', 'save']
  },
  {
     fontSet: 'fa',
     id: 'bullhorn',
     filter: ['announcement', 'share', 'broadcast', 'louder', 'megaphone']
  },
  {
     fontSet: 'fa',
     id: 'bell',
     filter: ['alert', 'reminder', 'notification']
  },
  {
     fontSet: 'fa',
     id: 'certificate',
     filter: ['badge', 'star']
  },
  {
     fontSet: 'fa',
     id: 'hand-o-right',
     filter: [
        'point',
        'right',
        'next',
        'forward',
        'finger',
        'directional',
        'Hand'
     ]
  },
  {
     fontSet: 'fa',
     id: 'hand-o-left',
     filter: [
        'point',
        'left',
        'previous',
        'back',
        'finger',
        'directional',
        'Hand'
     ]
  },
  {
     fontSet: 'fa',
     id: 'hand-o-up',
     filter: ['point', 'finger', 'directional', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'hand-o-down',
     filter: ['point', 'finger', 'directional', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'arrow-circle-left',
     filter: ['previous', 'back', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'arrow-circle-right',
     filter: ['next', 'forward', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'arrow-circle-up',
     filter: ['directional']
  },
  {
     fontSet: 'fa',
     id: 'arrow-circle-down',
     filter: ['download', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'globe',
     filter: [
        'world',
        'planet',
        'map',
        'place',
        'travel',
        'earth',
        'global',
        'translate',
        'all',
        'language',
        'localize',
        'location',
        'coordinates',
        'country'
     ]
  },
  {
     fontSet: 'fa',
     id: 'wrench',
     filter: ['settings', 'fix', 'update', 'spanner']
  },
  {
     fontSet: 'fa',
     id: 'tasks',
     filter: ['progress', 'loading', 'downloading', 'downloads', 'settings']
  },
  {
     fontSet: 'fa',
     id: 'filter',
     filter: ['funnel', 'options']
  },
  {
     fontSet: 'fa',
     id: 'briefcase',
     filter: ['work', 'business', 'office', 'luggage', 'bag']
  },
  {
     fontSet: 'fa',
     id: 'arrows-alt',
     filter: [
        'expand',
        'enlarge',
        'fullscreen',
        'bigger',
        'move',
        'reorder',
        'resize',
        'arrow',
        'video',
        'player',
        'directional'
     ]
  },
  {
     fontSet: 'fa',
     id: 'users',
     filter: ['people', 'profiles', 'persons', 'group']
  },
  {
     fontSet: 'fa',
     id: 'link',
     filter: ['chain', 'text']
  },
  {
     fontSet: 'fa',
     id: 'cloud',
     filter: ['save']
  },
  {
     fontSet: 'fa',
     id: 'flask',
     filter: ['science', 'beaker', 'experimental', 'labs']
  },
  {
     fontSet: 'fa',
     id: 'scissors',
     filter: ['cut', 'text']
  },
  {
     fontSet: 'fa',
     id: 'files-o',
     filter: ['duplicate', 'clone', 'copy', 'text']
  },
  {
     fontSet: 'fa',
     id: 'paperclip',
     filter: ['attachment', 'text']
  },
  {
     fontSet: 'fa',
     id: 'floppy-o',
     filter: ['save', 'text']
  },
  {
     fontSet: 'fa',
     id: 'square',
     filter: ['block', 'box', 'application', 'form']
  },
  {
     fontSet: 'fa',
     id: 'bars',
     filter: [
        'navicon',
        'reorder',
        'menu',
        'drag',
        'reorder',
        'settings',
        'list',
        'ul',
        'ol',
        'checklist',
        'todo',
        'list',
        'hamburger'
     ]
  },
  {
     fontSet: 'fa',
     id: 'list-ul',
     filter: ['ul', 'ol', 'checklist', 'todo', 'list', 'text']
  },
  {
     fontSet: 'fa',
     id: 'list-ol',
     filter: ['ul', 'ol', 'checklist', 'list', 'todo', 'list', 'numbers', 'text']
  },
  {
     fontSet: 'fa',
     id: 'strikethrough',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'underline',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'table',
     filter: ['data', 'excel', 'spreadsheet', 'text']
  },
  {
     fontSet: 'fa',
     id: 'magic',
     filter: ['wizard', 'automatic', 'autocomplete']
  },
  {
     fontSet: 'fa',
     id: 'truck',
     filter: ['shipping', 'application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'pinterest',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'pinterest-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'google-plus-square',
     filter: ['social network', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'google-plus',
     filter: ['social network', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'money',
     filter: [
        'cash',
        'money',
        'buy',
        'checkout',
        'purchase',
        'payment',
        'application',
        'Currency'
     ]
  },
  {
     fontSet: 'fa',
     id: 'caret-down',
     filter: [
        'more',
        'dropdown',
        'menu',
        'triangle down',
        'arrow',
        'directional'
     ]
  },
  {
     fontSet: 'fa',
     id: 'caret-up',
     filter: ['triangle up', 'arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'caret-left',
     filter: ['previous', 'back', 'triangle left', 'arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'caret-right',
     filter: ['next', 'forward', 'triangle right', 'arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'columns',
     filter: ['split', 'panes', 'text']
  },
  {
     fontSet: 'fa',
     id: 'sort',
     filter: ['order', 'unsorted']
  },
  {
     fontSet: 'fa',
     id: 'sort-desc',
     filter: ['dropdown', 'more', 'menu', 'arrow', 'sort-down']
  },
  {
     fontSet: 'fa',
     id: 'sort-asc',
     filter: ['sort-up', 'arrow']
  },
  {
     fontSet: 'fa',
     id: 'envelope',
     filter: ['email', 'e-mail', 'letter', 'support', 'mail', 'notification']
  },
  {
     fontSet: 'fa',
     id: 'linkedin',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'undo',
     filter: ['back', 'rotate-left', 'text']
  },
  {
     fontSet: 'fa',
     id: 'gavel',
    filter: ['judge', 'lawyer', 'opinion', 'legal']
  },
  {
     fontSet: 'fa',
     id: 'tachometer',
    filter: ['speedometer', 'fast', 'dashboard', 'transportation']
  },
  {
     fontSet: 'fa',
     id: 'comment-o',
     filter: [
        'speech',
        'notification',
        'note',
        'chat',
        'bubble',
        'feedback',
        'message',
        'texting',
        'sms',
        'conversation'
     ]
  },
  {
     fontSet: 'fa',
     id: 'comments-o',
     filter: [
        'speech',
        'notification',
        'note',
        'chat',
        'bubble',
        'feedback',
        'message',
        'texting',
        'sms',
        'conversation'
     ]
  },
  {
     fontSet: 'fa',
     id: 'bolt',
     filter: ['lightning', 'weather', 'flash']
  },
  {
     fontSet: 'fa',
     id: 'sitemap',
     filter: ['directory', 'hierarchy', 'organization']
  },
  {
     fontSet: 'fa',
     id: 'umbrella'
  },
  {
     fontSet: 'fa',
     id: 'clipboard',
     filter: ['copy', 'paste', 'text']
  },
  {
     fontSet: 'fa',
     id: 'lightbulb-o',
     filter: ['idea', 'inspiration']
  },
  {
     fontSet: 'fa',
     id: 'exchange',
     filter: ['transfer', 'arrows', 'arrow', 'application', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'cloud-download',
     filter: ['import']
  },
  {
     fontSet: 'fa',
     id: 'cloud-upload',
     filter: ['import']
  },
  {
     fontSet: 'fa',
     id: 'user-md',
     filter: ['doctor', 'profile', 'medical', 'nurse', 'Medical']
  },
  {
     fontSet: 'fa',
     id: 'stethoscope',
     filter: ['Medical']
  },
  {
     fontSet: 'fa',
     id: 'suitcase',
     filter: ['trip', 'luggage', 'travel', 'move', 'baggage']
  },
  {
     fontSet: 'fa',
     id: 'bell-o',
     filter: ['alert', 'reminder', 'notification']
  },
  {
     fontSet: 'fa',
     id: 'coffee',
     filter: ['morning', 'mug', 'breakfast', 'tea', 'drink', 'cafe']
  },
  {
     fontSet: 'fa',
     id: 'cutlery',
     filter: ['food', 'restaurant', 'spoon', 'knife', 'dinner', 'eat']
  },
  {
     fontSet: 'fa',
     id: 'file-text-o',
     filter: ['new', 'page', 'pdf', 'document', 'text', 'file']
  },
  {
     fontSet: 'fa',
     id: 'building-o',
     filter: ['work', 'business', 'apartment', 'office', 'company']
  },
  {
     fontSet: 'fa',
     id: 'hospital-o',
     filter: ['building', 'Medical']
  },
  {
     fontSet: 'fa',
     id: 'ambulance',
     filter: ['vehicle', 'support', 'help', 'Medical', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'medkit',
     filter: ['first aid', 'firstaid', 'help', 'support', 'health', 'Medical']
  },
  {
     fontSet: 'fa',
     id: 'fighter-jet',
     filter: [
        'fly',
        'plane',
        'airplane',
        'quick',
        'fast',
        'travel',
        'application',
        'Transportation'
     ]
  },
  {
     fontSet: 'fa',
     id: 'beer',
     filter: ['alcohol', 'stein', 'drink', 'mug', 'bar', 'liquor']
  },
  {
     fontSet: 'fa',
     id: 'h-square',
     filter: ['hospital', 'hotel', 'Medical']
  },
  {
     fontSet: 'fa',
     id: 'plus-square',
     filter: ['add', 'new', 'create', 'expand', 'Medical', 'application', 'form']
  },
  {
     fontSet: 'fa',
     id: 'angle-double-left',
     filter: ['laquo', 'quote', 'previous', 'back', 'arrows', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'angle-double-right',
     filter: ['raquo', 'quote', 'next', 'forward', 'arrows', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'angle-double-up',
     filter: ['arrows', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'angle-double-down',
     filter: ['arrows', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'angle-left',
     filter: ['previous', 'back', 'arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'angle-right',
     filter: ['next', 'forward', 'arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'angle-up',
     filter: ['arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'angle-down',
     filter: ['arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'desktop',
     filter: ['monitor', 'screen', 'desktop', 'computer', 'demo', 'device']
  },
  {
     fontSet: 'fa',
     id: 'laptop',
     filter: ['demo', 'computer', 'device']
  },
  {
     fontSet: 'fa',
     id: 'tablet',
     filter: ['ipad', 'device']
  },
  {
     fontSet: 'fa',
     id: 'mobile',
     filter: [
        'cell phone',
        'cellphone',
        'text',
        'call',
        'iphone',
        'number',
        'telephone',
        'mobile-phone'
     ]
  },
  {
     fontSet: 'fa',
     id: 'circle-o',
     filter: ['application', 'form']
  },
  {
     fontSet: 'fa',
     id: 'quote-left',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'quote-right',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'spinner',
     filter: ['loading', 'progress', 'application', 'Spinner']
  },
  {
     fontSet: 'fa',
     id: 'circle',
     filter: ['dot', 'notification', 'application', 'form']
  },
  {
     fontSet: 'fa',
     id: 'reply',
     filter: ['mail-reply']
  },
  {
     fontSet: 'fa',
     id: 'github-alt',
     filter: ['octocat', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'folder-o',
     filter: ['text', 'application']
  },
  {
     fontSet: 'fa',
     id: 'folder-open-o',
     filter: ['text', 'application']
  },
  {
     fontSet: 'fa',
     id: 'smile-o',
     filter: ['face', 'emoticon', 'happy', 'approve', 'satisfied', 'rating']
  },
  {
     fontSet: 'fa',
     id: 'frown-o',
     filter: ['face', 'emoticon', 'sad', 'disapprove', 'rating']
  },
  {
     fontSet: 'fa',
     id: 'meh-o',
     filter: ['face', 'emoticon', 'rating', 'neutral']
  },
  {
     fontSet: 'fa',
     id: 'gamepad',
     filter: ['controller']
  },
  {
     fontSet: 'fa',
     id: 'keyboard-o',
     filter: ['type', 'input']
  },
  {
     fontSet: 'fa',
     id: 'flag-o',
     filter: ['report', 'notification']
  },
  {
     fontSet: 'fa',
     id: 'flag-checkered',
     filter: ['report', 'notification', 'notify']
  },
  {
     fontSet: 'fa',
     id: 'terminal',
     filter: ['command', 'prompt', 'code']
  },
  {
     fontSet: 'fa',
     id: 'code',
     filter: ['html', 'brackets']
  },
  {
     fontSet: 'fa',
     id: 'reply-all',
     filter: ['mail-reply-all']
  },
  {
     fontSet: 'fa',
     id: 'star-half-o',
     filter: [
        'award',
        'achievement',
        'rating',
        'score',
        'star-half-empty',
        'star-half-full'
     ]
  },
  {
     fontSet: 'fa',
     id: 'location-arrow',
     filter: ['map', 'coordinates', 'location', 'address', 'place', 'where']
  },
  {
     fontSet: 'fa',
     id: 'crop'
  },
  {
     fontSet: 'fa',
     id: 'code-fork',
     filter: [
        'git',
        'fork',
        'vcs',
        'svn',
        'github',
        'rebase',
        'version',
        'merge'
     ]
  },
  {
     fontSet: 'fa',
     id: 'chain-broken',
     filter: ['remove', 'unlink', 'text']
  },
  {
     fontSet: 'fa',
     id: 'question',
     filter: ['help', 'information', 'unknown', 'support']
  },
  {
     fontSet: 'fa',
     id: 'info',
     filter: ['help', 'information', 'more', 'details']
  },
  {
     fontSet: 'fa',
     id: 'exclamation',
     filter: ['warning', 'error', 'problem', 'notification', 'notify', 'alert']
  },
  {
     fontSet: 'fa',
     id: 'superscript',
     filter: ['exponential', 'text']
  },
  {
     fontSet: 'fa',
     id: 'subscript',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'eraser',
     filter: ['remove', 'delete', 'text', 'application']
  },
  {
     fontSet: 'fa',
     id: 'puzzle-piece',
     filter: ['addon', 'add-on', 'section']
  },
  {
     fontSet: 'fa',
     id: 'microphone',
     filter: ['record', 'voice', 'sound']
  },
  {
     fontSet: 'fa',
     id: 'microphone-slash',
     filter: ['record', 'voice', 'sound', 'mute']
  },
  {
     fontSet: 'fa',
     id: 'shield',
     filter: ['award', 'achievement', 'security', 'winner']
  },
  {
     fontSet: 'fa',
     id: 'calendar-o',
     filter: ['date', 'time', 'when', 'event']
  },
  {
     fontSet: 'fa',
     id: 'fire-extinguisher'
  },
  {
     fontSet: 'fa',
     id: 'rocket',
     filter: ['app', 'application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'maxcdn',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'chevron-circle-left',
     filter: ['previous', 'back', 'arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'chevron-circle-right',
     filter: ['next', 'forward', 'arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'chevron-circle-up',
     filter: ['arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'chevron-circle-down',
     filter: ['more', 'dropdown', 'menu', 'arrow', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'html5',
    filter: ['code', 'html5', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'css3',
     filter: ['code', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'anchor',
     filter: ['link']
  },
  {
     fontSet: 'fa',
     id: 'unlock-alt',
     filter: ['protect', 'admin', 'password', 'lock']
  },
  {
     fontSet: 'fa',
     id: 'bullseye',
     filter: ['target']
  },
  {
     fontSet: 'fa',
     id: 'ellipsis-h',

     filter: ['dots']
  },
  {
     fontSet: 'fa',
     id: 'ellipsis-v',
     filter: ['dots']
  },
  {
     fontSet: 'fa',
     id: 'rss-square',
     filter: ['feed', 'blog']
  },
  {
     fontSet: 'fa',
     id: 'play-circle',
     filter: ['start', 'playing', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'ticket',
     filter: ['movie', 'pass', 'support']
  },
  {
     fontSet: 'fa',
     id: 'minus-square',
     filter: [
        'hide',
        'minify',
        'delete',
        'remove',
        'trash',
        'hide',
        'collapse',
        'application',
        'form'
     ]
  },
  {
     fontSet: 'fa',
     id: 'minus-square-o',
     filter: [
        'hide',
        'minify',
        'delete',
        'remove',
        'trash',
        'hide',
        'collapse',
        'application',
        'form'
     ]
  },
  {
     fontSet: 'fa',
     id: 'level-up',
     filter: ['arrow']
  },
  {
     fontSet: 'fa',
     id: 'level-down',
     filter: ['arrow']
  },
  {
     fontSet: 'fa',
     id: 'check-square',
     filter: [
        'checkmark',
        'done',
        'todo',
        'agree',
        'accept',
        'confirm',
        'ok',
        'application',
        'form'
     ]
  },
  {
     fontSet: 'fa',
     id: 'pencil-square',
     filter: ['write', 'edit', 'update']
  },
  {
     fontSet: 'fa',
     id: 'external-link-square',
     filter: ['open', 'new']
  },
  {
     fontSet: 'fa',
     id: 'share-square',
     filter: ['social', 'send']
  },
  {
     fontSet: 'fa',
     id: 'compass',
     filter: ['safari', 'directory', 'menu', 'location']
  },
  {
     fontSet: 'fa',
     id: 'caret-square-o-down',
     filter: [
        'toggle-down',
        'more',
        'dropdown',
        'menu',
        'application',
        'directional'
     ]
  },
  {
     fontSet: 'fa',
     id: 'caret-square-o-up',
     filter: ['toggle-up', 'application', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'caret-square-o-right',
     filter: ['next', 'forward', 'toggle-right', 'application', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'eur',
     filter: ['euro', 'Currency']
  },
  {
     fontSet: 'fa',
     id: 'gbp',
     filter: ['Currency']
  },
  {
     fontSet: 'fa',
     id: 'usd',
     filter: ['dollar', 'Currency']
  },
  {
     fontSet: 'fa',
     id: 'inr',
     filter: ['rupee', 'Currency']
  },
  {
     fontSet: 'fa',
     id: 'jpy',
     filter: ['cny', 'rmb', 'yen', 'Currency']
  },
  {
     fontSet: 'fa',
     id: 'rub',
     filter: ['ruble', 'rouble', 'Currency']
  },
  {
     fontSet: 'fa',
     id: 'krw',
     filter: ['won', 'Currency']
  },
  {
     fontSet: 'fa',
     id: 'btc',
     filter: ['bitcoin', 'Currency', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'file',
     filter: ['new', 'page', 'pdf', 'document', 'text', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-text',
     filter: ['new', 'page', 'pdf', 'document', 'text', 'file']
  },
  {
     fontSet: 'fa',
     id: 'sort-alpha-asc',
     filter: ['numbers']
  },
  {
     fontSet: 'fa',
     id: 'sort-alpha-desc',
     filter: ['numbers']
  },
  {
     fontSet: 'fa',
     id: 'sort-amount-asc',
     filter: ['numbers']
  },
  {
     fontSet: 'fa',
     id: 'sort-amount-desc',
     filter: ['numbers']
  },
  {
     fontSet: 'fa',
     id: 'sort-numeric-asc',
     filter: ['numbers']
  },
  {
     fontSet: 'fa',
     id: 'sort-numeric-desc',
     filter: ['numbers']
  },
  {
     fontSet: 'fa',
     id: 'thumbs-up',
     filter: [
        'like',
        'favorite',
        'approve',
        'agree',
        'hand',
        'application',
        'Hand'
     ]
  },
  {
     fontSet: 'fa',
     id: 'thumbs-down',
     filter: ['dislike', 'disapprove', 'disagree', 'hand', 'application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'youtube-square',
     filter: ['video', 'film', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'youtube',
     filter: ['video', 'film', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'xing',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'xing-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'youtube-play',
     filter: ['start', 'playing', 'brand', 'video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'dropbox',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'stack-overflow',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'instagram',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'flickr',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'adn',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'bitbucket',
     filter: ['git', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'bitbucket-square',
     filter: ['git', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'tumblr',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'tumblr-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'long-arrow-down',
     filter: ['directional']
  },
  {
     fontSet: 'fa',
     id: 'long-arrow-up',
     filter: ['directional']
  },
  {
     fontSet: 'fa',
     id: 'long-arrow-left',
     filter: ['previous', 'back', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'long-arrow-right',
     filter: ['directional']
  },
  {
     fontSet: 'fa',
     id: 'apple',
     filter: ['osx', 'food', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'windows',
     filter: ['microsoft', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'android',
     filter: ['robot', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'linux',
     filter: ['tux', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'dribbble',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'skype',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'foursquare',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'trello',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'female',
     filter: ['woman', 'user', 'person', 'profile']
  },
  {
     fontSet: 'fa',
     id: 'male',
     filter: ['man', 'user', 'person', 'profile']
  },
  {
     fontSet: 'fa',
     id: 'gratipay',
     filter: ['gittip', 'heart', 'like', 'favorite', 'love', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'sun-o',
     filter: ['weather', 'contrast', 'lighter', 'brighten', 'day']
  },
  {
     fontSet: 'fa',
     id: 'moon-o',
     filter: ['night', 'darker', 'contrast']
  },
  {
     fontSet: 'fa',
     id: 'archive',
     filter: ['box', 'storage']
  },
  {
     fontSet: 'fa',
     id: 'bug',
     filter: ['report', 'insect']
  },
  {
     fontSet: 'fa',
     id: 'vk',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'weibo',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'renren',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'pagelines',
     filter: ['leaf', 'leaves', 'tree', 'plant', 'eco', 'nature', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'stack-exchange',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'arrow-circle-o-right',
     filter: ['next', 'forward', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'arrow-circle-o-left',
     filter: ['previous', 'back', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'caret-square-o-left',
     filter: ['previous', 'back', 'toggle-left', 'application', 'directional']
  },
  {
     fontSet: 'fa',
     id: 'dot-circle-o',
     filter: ['target', 'bullseye', 'notification', 'application', 'form']
  },
  {
     fontSet: 'fa',
     id: 'wheelchair',
     filter: [
        'handicap',
        'person',
        'application',
        'Medical',
        'Transportation',
        'accessibility'
     ]
  },
  {
     fontSet: 'fa',
     id: 'vimeo-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'try',
     filter: ['turkish-lira', 'Currency']
  },
  {
     fontSet: 'fa',
     id: 'plus-square-o',
     filter: ['add', 'new', 'create', 'expand', 'application', 'form']
  },
  {
     fontSet: 'fa',
     id: 'space-shuttle',
     filter: ['application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'slack',
     filter: ['hashtag', 'anchor', 'hash', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'envelope-square',
     filter: ['email', 'letter']
  },
  {
     fontSet: 'fa',
     id: 'wordpress',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'openid',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'university',
     filter: ['institution', 'bank']
  },
  {
     fontSet: 'fa',
     id: 'graduation-cap',
     filter: ['mortar-board', 'learning', 'school', 'student']
  },
  {
     fontSet: 'fa',
     id: 'yahoo',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'google',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'reddit',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'reddit-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'stumbleupon-circle',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'stumbleupon',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'delicious',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'digg',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'pied-piper-pp',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'pied-piper-alt',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'drupal',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'joomla',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'language'
  },
  {
     fontSet: 'fa',
     id: 'fax',
     filter: ['work', 'business', 'phone', 'office', 'company']
  },
  {
     fontSet: 'fa',
     id: 'building',
     filter: ['work', 'business', 'apartment', 'office', 'company']
  },
  {
     fontSet: 'fa',
     id: 'child',
     filter: ['human', 'people']
  },
  {
     fontSet: 'fa',
     id: 'paw',
     filter: ['pet']
  },
  {
     fontSet: 'fa',
     id: 'spoon'
  },
  {
     fontSet: 'fa',
     id: 'cube'
  },
  {
     fontSet: 'fa',
     id: 'cubes'
  },
  {
     fontSet: 'fa',
     id: 'behance',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'behance-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'steam',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'steam-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'recycle'
  },
  {
     fontSet: 'fa',
     id: 'car',
     filter: ['automobile', 'vehicle', 'application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'taxi',
     filter: ['cab', 'vehicle', 'application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'tree'
  },
  {
     fontSet: 'fa',
     id: 'spotify',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'deviantart',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'soundcloud',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'database'
  },
  {
     fontSet: 'fa',
     id: 'file-pdf-o',
     filter: ['application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-word-o',
     filter: ['application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-excel-o',
     filter: ['application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-powerpoint-o',
     filter: ['application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-image-o',
     filter: ['file-photo-o', 'file-picture-o', 'application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-archive-o',
     filter: ['file-zip-o', 'application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-audio-o',
     filter: ['file-sound-o', 'application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-video-o',
     filter: ['file-movie-o', 'application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'file-code-o',
     filter: ['application', 'file']
  },
  {
     fontSet: 'fa',
     id: 'vine',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'codepen',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'jsfiddle',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'life-ring',
     filter: ['life-bouy', 'life-buoy', 'life-saver', 'support']
  },
  {
     fontSet: 'fa',
     id: 'circle-o-notch',
     filter: ['application', 'Spinner']
  },
  {
     fontSet: 'fa',
     id: 'rebel',
     filter: ['ra', 'resistance', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'empire',
     filter: ['ge', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'git-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'git',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'hacker-news',
     filter: ['y-combinator-square', 'yc-square', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'tencent-weibo',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'qq',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'weixin',
     filter: ['wechat', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'paper-plane',
     filter: ['send']
  },
  {
     fontSet: 'fa',
     id: 'paper-plane-o',
     filter: ['send-o']
  },
  {
     fontSet: 'fa',
     id: 'history'
  },
  {
     fontSet: 'fa',
     id: 'circle-thin'
  },
  {
     fontSet: 'fa',
     id: 'header',
     filter: ['heading', 'text']
  },
  {
     fontSet: 'fa',
     id: 'paragraph',
     filter: ['text']
  },
  {
     fontSet: 'fa',
     id: 'sliders',
     filter: ['settings']
  },
  {
     fontSet: 'fa',
     id: 'share-alt',
     filter: ['application', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'share-alt-square',
     filter: ['application', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'bomb'
  },
  {
     fontSet: 'fa',
     id: 'futbol-o',
     filter: ['soccer-ball-o']
  },
  {
     fontSet: 'fa',
     id: 'tty',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'binoculars'
  },
  {
     fontSet: 'fa',
     id: 'plug',
     filter: ['power', 'connect']
  },
  {
     fontSet: 'fa',
     id: 'slideshare',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'twitch',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'yelp',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'newspaper-o',
     filter: ['press']
  },
  {
     fontSet: 'fa',
     id: 'wifi'
  },
  {
     fontSet: 'fa',
     id: 'calculator'
  },
  {
     fontSet: 'fa',
     id: 'paypal',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'google-wallet',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'cc-visa',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'cc-mastercard',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'cc-discover',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'cc-amex',
     filter: ['amex', 'brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'cc-paypal',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'cc-stripe',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'bell-slash'
  },
  {
     fontSet: 'fa',
     id: 'bell-slash-o'
  },
  {
     fontSet: 'fa',
     id: 'trash',
     filter: ['garbage', 'delete', 'remove', 'hide']
  },
  {
     fontSet: 'fa',
     id: 'copyright'
  },
  {
     fontSet: 'fa',
     id: 'at'
  },
  {
     fontSet: 'fa',
     id: 'eyedropper'
  },
  {
     fontSet: 'fa',
     id: 'paint-brush'
  },
  {
     fontSet: 'fa',
     id: 'birthday-cake'
  },
  {
     fontSet: 'fa',
     id: 'area-chart',
     filter: ['graph', 'analytics', 'application', 'Chart']
  },
  {
     fontSet: 'fa',
     id: 'pie-chart',
     filter: ['graph', 'analytics', 'application', 'Chart']
  },
  {
     fontSet: 'fa',
     id: 'line-chart',

     filter: ['graph', 'analytics', 'application', 'Chart']
  },
  {
     fontSet: 'fa',
     id: 'lastfm',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'lastfm-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'toggle-off'
  },
  {
     fontSet: 'fa',
     id: 'toggle-on'
  },
  {
     fontSet: 'fa',
     id: 'bicycle',
     filter: ['vehicle', 'bike', 'application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'bus',
     filter: ['vehicle', 'application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'ioxhost',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'angellist',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'cc',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'ils',
     filter: ['shekel', 'sheqel', 'Currency']
  },
  {
     fontSet: 'fa',
     id: 'meanpath',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'buysellads',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'connectdevelop',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'dashcube',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'forumbee',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'leanpub',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'sellsy',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'shirtsinbulk',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'simplybuilt',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'skyatlas',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'cart-plus',
     filter: ['add', 'shopping']
  },
  {
     fontSet: 'fa',
     id: 'cart-arrow-down',
     filter: ['shopping']
  },
  {
     fontSet: 'fa',
     id: 'diamond',
     filter: ['gem', 'gemstone']
  },
  {
     fontSet: 'fa',
     id: 'ship',
     filter: ['boat', 'sea', 'application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'user-secret',
     filter: ['whisper', 'spy', 'incognito', 'privacy']
  },
  {
     fontSet: 'fa',
     id: 'motorcycle',
     filter: ['vehicle', 'bike', 'application', 'Transportation']
  },
  {
     fontSet: 'fa',
     id: 'street-view',
     filter: ['map']
  },
  {
     fontSet: 'fa',
     id: 'heartbeat',
     filter: ['ekg', 'application', 'Medical']
  },
  {
     fontSet: 'fa',
     id: 'venus',
     filter: ['female', 'Gender']
  },
  {
     fontSet: 'fa',
     id: 'mars',
     filter: ['male', 'Gender']
  },
  {
     fontSet: 'fa',
     id: 'mercury',
     filter: ['transgender', 'Gender']
  },
  {
     fontSet: 'fa',
     id: 'transgender',
     filter: ['intersex', 'Gender']
  },
  {
     fontSet: 'fa',
     id: 'transgender-alt',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'venus-double',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'mars-double',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'venus-mars',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'mars-stroke',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'mars-stroke-v',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'mars-stroke-h',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'neuter',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'genderless',
     filter: ['Gender']
  },
  {
     fontSet: 'fa',
     id: 'facebook-official',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'pinterest-p',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'whatsapp',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'server'
  },
  {
     fontSet: 'fa',
     id: 'user-plus',
     filter: ['sign up', 'signup']
  },
  {
     fontSet: 'fa',
     id: 'user-times'
  },
  {
     fontSet: 'fa',
     id: 'bed',
     filter: ['travel', 'hotel']
  },
  {
     fontSet: 'fa',
     id: 'viacoin',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'train',
     filter: ['Transportation']
  },
  {
     fontSet: 'fa',
     id: 'subway',
     filter: ['Transportation']
  },
  {
     fontSet: 'fa',
     id: 'medium',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'y-combinator',
     filter: ['yc', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'optin-monster',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'opencart',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'expeditedssl',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'battery-full',
     filter: ['battery-4', 'battery', 'power']
  },
  {
     fontSet: 'fa',
     id: 'battery-three-quarters',
     filter: ['battery-3', 'power']
  },
  {
     fontSet: 'fa',
     id: 'battery-half',
     filter: ['battery-2', 'power']
  },
  {
     fontSet: 'fa',
     id: 'battery-quarter',
     filter: ['battery-1', 'power']
  },
  {
     fontSet: 'fa',
     id: 'battery-empty',
     filter: ['battery-0', 'power']
  },
  {
     fontSet: 'fa',
     id: 'mouse-pointer'
  },
  {
     fontSet: 'fa',
     id: 'i-cursor'
  },
  {
     fontSet: 'fa',
     id: 'object-group'
  },
  {
     fontSet: 'fa',
     id: 'object-ungroup'
  },
  {
     fontSet: 'fa',
     id: 'sticky-note'
  },
  {
     fontSet: 'fa',
     id: 'sticky-note-o'
  },
  {
     fontSet: 'fa',
     id: 'cc-jcb',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'cc-diners-club',
     filter: ['brand', 'Payment']
  },
  {
     fontSet: 'fa',
     id: 'clone',
     filter: ['copy']
  },
  {
     fontSet: 'fa',
     id: 'balance-scale'
  },
  {
     fontSet: 'fa',
     id: 'hourglass-o'
  },
  {
     fontSet: 'fa',
     id: 'hourglass-start',
     filter: ['hourglass-1']
  },
  {
     fontSet: 'fa',
     id: 'hourglass-half',
     filter: ['hourglass-2']
  },
  {
     fontSet: 'fa',
     id: 'hourglass-end',
     filter: ['hourglass-3']
  },
  {
     fontSet: 'fa',
     id: 'hourglass'
  },
  {
     fontSet: 'fa',
     id: 'hand-rock-o',
     filter: ['hand-grab-o', 'application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'hand-paper-o',
     filter: ['hand-stop-o', 'stop', 'application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'hand-scissors-o',
     filter: ['application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'hand-lizard-o',
     filter: ['application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'hand-spock-o',
     filter: ['application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'hand-pointer-o',
     filter: ['application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'hand-peace-o',
     filter: ['application', 'Hand']
  },
  {
     fontSet: 'fa',
     id: 'trademark'
  },
  {
     fontSet: 'fa',
     id: 'registered'
  },
  {
     fontSet: 'fa',
     id: 'creative-commons'
  },
  {
     fontSet: 'fa',
     id: 'gg',
     filter: ['Currency', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'gg-circle',
     filter: ['Currency', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'tripadvisor',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'odnoklassniki',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'odnoklassniki-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'get-pocket',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'wikipedia-w',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'safari',
     filter: ['browser', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'chrome',
     filter: ['browser', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'firefox',
     filter: ['browser', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'opera',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'internet-explorer',
     filter: ['browser', 'ie', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'television',
     filter: ['tv', 'display', 'computer', 'monitor']
  },
  {
     fontSet: 'fa',
     id: 'contao',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: '500px',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'amazon',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'calendar-plus-o'
  },
  {
     fontSet: 'fa',
     id: 'calendar-minus-o'
  },
  {
     fontSet: 'fa',
     id: 'calendar-times-o'
  },
  {
     fontSet: 'fa',
     id: 'calendar-check-o',
     filter: ['ok']
  },
  {
     fontSet: 'fa',
     id: 'industry',
     filter: ['factory']
  },
  {
     fontSet: 'fa',
     id: 'map-pin'
  },
  {
     fontSet: 'fa',
     id: 'map-signs'
  },
  {
     fontSet: 'fa',
     id: 'map-o'
  },
  {
     fontSet: 'fa',
     id: 'map'
  },
  {
     fontSet: 'fa',
     id: 'commenting',
     filter: [
        'speech',
        'notification',
        'note',
        'chat',
        'bubble',
        'feedback',
        'message',
        'texting',
        'sms',
        'conversation'
     ]
  },
  {
     fontSet: 'fa',
     id: 'commenting-o',
     filter: [
        'speech',
        'notification',
        'note',
        'chat',
        'bubble',
        'feedback',
        'message',
        'texting',
        'sms',
        'conversation'
     ]
  },
  {
     fontSet: 'fa',
     id: 'houzz',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'vimeo',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'black-tie',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'fonticons',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'reddit-alien',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'edge',
     filter: ['browser', 'ie', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'credit-card-alt',
     filter: [
        'money',
        'buy',
        'debit',
        'checkout',
        'purchase',
        'payment',
        'credit card',
        'Payment',
        'application'
     ]
  },
  {
     fontSet: 'fa',
     id: 'codiepie',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'modx',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'fort-awesome',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'usb',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'product-hunt',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'mixcloud',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'scribd',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'pause-circle',
     filter: ['video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'pause-circle-o',
     filter: ['video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'stop-circle',
     filter: ['video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'stop-circle-o',
     filter: ['video', 'player']
  },
  {
     fontSet: 'fa',
     id: 'shopping-bag'
  },
  {
     fontSet: 'fa',
     id: 'shopping-basket'
  },
  {
     fontSet: 'fa',
     id: 'hashtag'
  },
  {
     fontSet: 'fa',
     id: 'bluetooth',
     filter: ['application', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'bluetooth-b',
     filter: ['application', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'percent'
  },
  {
     fontSet: 'fa',
     id: 'gitlab',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'wpbeginner',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'wpforms',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'envira',
     filter: ['leaf', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'universal-access',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'wheelchair-alt',
     filter: [
        'handicap',
        'person',
        'application',
        'Medical',
        'Transportation',
        'accessibility'
     ]
  },
  {
     fontSet: 'fa',
     id: 'question-circle-o',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'blind',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'audio-description',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'volume-control-phone',
     filter: ['telephone', 'application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'braille',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'assistive-listening-systems',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'american-sign-language-interpreting',
     filter: ['asl-interpreting', 'application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'deaf',
     filter: ['deafness', 'hard-of-hearing', 'application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'glide',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'glide-g',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'sign-language',
     filter: ['signing', 'application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'low-vision',
     filter: ['application', 'accessibility']
  },
  {
     fontSet: 'fa',
     id: 'viadeo',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'viadeo-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'snapchat',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'snapchat-ghost',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'snapchat-square',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'pied-piper',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'first-order',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'yoast',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'themeisle',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'google-plus-official',
     filter: ['google-plus-circle', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'font-awesome',
     filter: ['fa', 'brand']
  },
  {
     fontSet: 'fa',
     id: 'handshake-o'
  },
  {
     fontSet: 'fa',
     id: 'envelope-open'
  },
  {
     fontSet: 'fa',
     id: 'envelope-open-o'
  },
  {
     fontSet: 'fa',
     id: 'linode',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'address-book'
  },
  {
     fontSet: 'fa',
     id: 'address-book-o'
  },
  {
     fontSet: 'fa',
     id: 'address-card',
     filter: ['vcard']
  },
  {
     fontSet: 'fa',
     id: 'address-card-o',
     filter: ['vcard-o']
  },
  {
     fontSet: 'fa',
     id: 'user-circle'
  },
  {
     fontSet: 'fa',
     id: 'user-circle-o'
  },
  {
     fontSet: 'fa',
     id: 'user-o'
  },
  {
     fontSet: 'fa',
     id: 'id-badge'
  },
  {
     fontSet: 'fa',
     id: 'id-card',
     filter: ['drivers-license']
  },
  {
     fontSet: 'fa',
     id: 'id-card-o',
     filter: ['drivers-license-o']
  },
  {
     fontSet: 'fa',
     id: 'quora',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'free-code-camp',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'telegram',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'thermometer-full',
     filter: ['thermometer-4', 'thermometer']
  },
  {
     fontSet: 'fa',
     id: 'thermometer-three-quarters',
     filter: ['thermometer-3']
  },
  {
     fontSet: 'fa',
     id: 'thermometer-half',
     filter: ['thermometer-2']
  },
  {
     fontSet: 'fa',
     id: 'thermometer-quarter',
     filter: ['thermometer-1']
  },
  {
     fontSet: 'fa',
     id: 'thermometer-empty',
     filter: ['thermometer-0']
  },
  {
     fontSet: 'fa',
     id: 'shower'
  },
  {
     fontSet: 'fa',
     id: 'bath',
     filter: ['bathtub', 's15']
  },
  {
     fontSet: 'fa',
     id: 'podcast'
  },
  {
     fontSet: 'fa',
     id: 'window-maximize'
  },
  {
     fontSet: 'fa',
     id: 'window-minimize'
  },
  {
     fontSet: 'fa',
     id: 'window-restore'
  },
  {
     fontSet: 'fa',
     id: 'window-close',
     filter: ['times-rectangle']
  },
  {
     fontSet: 'fa',
     id: 'window-close-o',
     filter: ['times-rectangle-o']
  },
  {
     fontSet: 'fa',
     id: 'bandcamp',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'grav',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'etsy',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'imdb',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'ravelry',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'eercast',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'microchip'
  },
  {
     fontSet: 'fa',
     id: 'snowflake-o'
  },
  {
     fontSet: 'fa',
     id: 'superpowers',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'wpexplorer',
     filter: ['brand']
  },
  {
     fontSet: 'fa',
     id: 'meetup',
     filter: ['brand']
  }
];
