import { PolicyBoolValueEnum } from './policy-bool-value.enum';
import { PolicyLevelValueEnum } from './policy-level-value.enum';

export class PolicyModel {
    public id: number;
    public parentId?: number;
    public name: string;
    public code: string;
    public definition: string;
    public value: PolicyBoolValueEnum | PolicyLevelValueEnum;
}