import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class EventInfoEffects {

    // public loadEventInfo$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(EventInfoActions.loadEventInfo),
    //         exhaustMap(action =>
    //             this.eventsApiService.getEventsInfo(action.eventUrl).pipe(
    //                 map((eventInfo) => EventInfoActions.updateEventInfo(eventInfo)),
    //                 catchError((error) => of(EventInfoActions.loadEventInfoError(error)))
    //             ))
    //     )
    // );

    constructor() { }
}