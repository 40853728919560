
/**
 * Model to display Countries.
 */

export class DocumentsByCountryLanguage {
    public title: string;
    public documentLink: string;
    public category: string;

    constructor(options: any = {}) {
        this.title = options.title;
        this.documentLink = options.documentLink;
        this.category = options.category;
    }
}

