import { ActivatedRouteSnapshot, Resolve, UrlSegment, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Breadcrumb, BreadcrumbRoute } from './models/public-api';

/**
 * Base Resolver class used for the breadcrumbs. This class can be used to extend other resolvers.
 *
 * Example usage:
 * ```ts
 * {
 *     path: '',
 *     component: HomeComponent,
 *     data: {
 *         name: 'Home'
 *     },
 *     resolve: {
 *         breadcrumbs: BreadcrumbsBaseResolver
 *     }
 * }
 * ```
 */
export class BreadcrumbsBaseResolver implements Resolve<Breadcrumb[]> {
    /**
     * Gets the current breadcrumb object.
     * @param route Active Route Snapshot.
     */
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> {

        const data: BreadcrumbRoute = route.routeConfig.data && route.routeConfig.data.breadcrumbs;
        const path: string = this.getFullPath(route);

        return of([{
                text: data.name,
                path: path
            }]);
    }

    /**
     * Returns the full router path for the current route.
     * @param route Active Route Snapshot.
     */
    public getFullPath(route: ActivatedRouteSnapshot): string {
        const relativePath: (segments: UrlSegment[]) => string =
            (segments: UrlSegment[]) => segments.reduce((a, v) => a += `/${v.path}`, '');
        const fullPath: (routes: ActivatedRouteSnapshot[]) => string =
            (routes: ActivatedRouteSnapshot[]) => routes.reduce((a, v) => a += relativePath(v.url), '');

        return fullPath(route.pathFromRoot);
    }
}
