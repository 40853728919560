import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { TranslationImageService } from './translation-image.service';

/**
 * This does not work at this time.
 */
@Injectable()
export class TranslationImageResolver implements Resolve<any> {
    constructor(private readonly translationService: TranslationImageService) {
     }

     public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

          return this.translationService.loadImages(route.data['states']);
     }
}
