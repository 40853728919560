import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { LoginOverlayComponent } from './login-overlay.component';
import { LoginOverlayService } from './login-overlay.service';
import { ShowAlertPipe } from './menu-alert.pipe';
import { CmsModule } from '@jeunesse/angular';



/**
 * Displays the login screen as an overlay.
 */
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatDividerModule,
        MatInputModule,
        MatIconModule,
        OverlayModule,
        PortalModule,
        FlexLayoutModule,
        CmsModule
    ],
    exports: [
    ],
    declarations: [
        LoginOverlayComponent, ShowAlertPipe
    ],
    providers: [
        LoginOverlayService
    ],
    entryComponents: [
        LoginOverlayComponent
    ]
})

export class LoginOverlayModule {

}